import { memo } from "react";
import { ToggleButton } from "components";
import * as hooks from "./diagram-view-mode-toggle.hooks";
import * as consts from "./diagram-view-mode-toggle.consts";
import "./diagram-view-mode-toggle.scss";

export const DiagramViewModeToggle = memo(() => {
  const isVolumetric = hooks.useIsVolumetric();
  const handleChange = hooks.useHandleChange();

  return (
    <div className={consts.baseClass}>
      <ToggleButton isFilling value={String(Number(isVolumetric))} buttons={consts.buttons} onChange={handleChange} />
    </div>
  );
});
