import { useState } from "react";

type HookReturnType<T = {}> = {
  state: T;
  dispatch: (state: Partial<T>) => void;
};

export const useLocalState = <T>(initialState: T): HookReturnType<T> => {
  const [state, setState] = useState<T>(initialState);

  const dispatch = (state: Partial<T>) => {
    setState((prev) => ({ ...prev, ...state }));
  };

  return {
    state,
    dispatch,
  };
};
