import React from "react";
import cn from "classnames";
import * as consts from "./application-settings-popup.consts";
import { Props } from "./application-settings-popup.types";
import "./application-settings-popup.scss";

export const ApplicationSettingsPopup = React.memo<React.PropsWithChildren<Props>>((props) => {
  const className = cn(consts.baseClass, { [consts.visibleClass]: props.isVisible });
  return <div className={className}>{props.children}</div>;
});
