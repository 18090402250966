import React from "react";
import cn from "classnames";
import { CloseButton, Loader } from "components";
import { useStoreContext } from "store";
import { TravelHeatmapTypes } from "types";
import * as hooks from "./travel-heatmap-card.hooks";
import * as consts from "./travel-heatmap-card.consts";
import "./travel-heatmap-card.scss";

export const TravelHeatmapCard = React.memo(() => {
  const [isSectorsListOpen, setIsSectorsListOpen] = React.useState(false);
  const { store } = useStoreContext();
  const isActive = store.travelHeatmap.hooks.useIsActive();
  const from = store.travelHeatmap.hooks.usePrettyFrom();
  const to = store.travelHeatmap.hooks.usePrettyTo();
  const directionModeTitle = store.travelHeatmap.hooks.useDirectionModeTitle();
  const transportTypeTitle = store.travelHeatmap.hooks.useTransportTypeTitle();
  const directionMode = store.travelHeatmap.hooks.useDirectionMode();
  const activeItem = store.travelHeatmap.hooks.useActiveItem();
  const hoverItem = store.travelHeatmap.hooks.useHoverItem();
  const sectorsInfo = store.travelHeatmap.hooks.useSectorsInformation();
  const isLoading = store.travelHeatmap.hooks.useIsLoading();
  const panelClassName = cn(consts.panelBaseClass, {
    [`${consts.panelBaseClass}-open`]: isSectorsListOpen || activeItem.length <= 5,
  });

  const handleAccordionClick = () => {
    setIsSectorsListOpen((prev) => !prev);
  };

  const handleItemInteractionChange = (item: TravelHeatmapTypes.H3IndexFeature | null) => () =>
    store.travelHeatmap.events.handleListHoverItemChange(item);

  const { daysCanvasRef, hoursCanvasRef } = hooks.useGraphic();

  if (!isActive || !activeItem.length) return null;

  return (
    <div className={consts.baseClass}>
      <div className={consts.headerClass}>
        <span className={consts.headerHeadingClass}>Анализ поездок</span>
        <CloseButton onClick={store.travelHeatmap.events.handleCardCloseClick} />
      </div>
      <div className={consts.contentClass}>
        <span className={consts.contentHeadingClass}>
          {directionModeTitle} поездок на {transportTypeTitle} {directionMode.label}{" "}
          {activeItem.length > 1
            ? directionMode.value === TravelHeatmapTypes.DirectionModeEnum.from
              ? "выбранных секторов"
              : "выбранных секторах"
            : `«${sectorsInfo[activeItem[0].h3Index]?.address ?? ""}»`}
        </span>
        <span className={consts.periodClass}>
          за период c <span className={consts.periodDateClass}>{from}</span> по{" "}
          <span className={consts.periodDateClass}>{to}</span>
        </span>
        <h2 className={consts.countClass}>{activeItem.reduce((acc, item) => acc + (item.properties.Value ?? 0), 0)}</h2>

        {activeItem.length === 1 && (
          <span className={consts.panelSector}>
            {sectorsInfo[activeItem[0].h3Index]?.address && sectorsInfo[activeItem[0].h3Index]?.address}
          </span>
        )}
        {activeItem.length > 1 && (
          <>
            <button className={consts.accordionClass} onClick={handleAccordionClick}>
              <span>Выделенные сектора:</span>
              <span className={consts.accordionIndicatorClass}>
                {isSectorsListOpen || activeItem.length <= 5 ? "−" : "+"}
              </span>
            </button>
            <div className={panelClassName}>
              {activeItem.map((item) => {
                return (
                  <span
                    key={item.h3Index}
                    className={`${consts.panelSector} ${
                      item.h3Index === hoverItem?.h3Index && !isLoading ? `${consts.panelSector}-selected` : ""
                    }`}
                    onMouseEnter={handleItemInteractionChange(item)}
                    onMouseLeave={handleItemInteractionChange(null)}>
                    {sectorsInfo[item.h3Index]?.address && `${sectorsInfo[item.h3Index]?.address};`}
                  </span>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className={consts.separatorClass} />
      <div className={consts.contentClass}>
        <div className={consts.graphicHeader}>
          <span className={consts.graphicHeaderHeading}>Поездок в среднем по дням</span>
        </div>
        <canvas ref={daysCanvasRef} />
        <div className={consts.graphicHeader}>
          <span className={consts.graphicHeaderHeading}>Поездок в среднем за сутки</span>
        </div>
        <canvas ref={hoursCanvasRef} />
      </div>
      {isLoading && (
        <div className={consts.loaderClass}>
          <Loader isLarge />
        </div>
      )}
    </div>
  );
});
