import mapboxgl from "mapbox-gl";
import { TransportHubsTypes } from "types";
import { Layer } from "./transport-hubs.layer";
import { Popup } from "./transport-hubs.popup";

type Options = {
  isVisible: boolean;
};

const initialOptions: Options = { isVisible: false };

export class TransportHubs {
  private layer?: Layer;
  private popup?: Popup;
  private data: TransportHubsTypes.FeatureCollection["features"] = [];

  constructor(private readonly map: mapboxgl.Map, private options: Options = initialOptions) {
    this.layer = new Layer(this.map);
    this.popup = new Popup(this.map);
    this.layer.setVisibility(this.options.isVisible);
    this.map.on("style.load", this.handleStyleLoad);
  }

  private handleStyleLoad = () => {
    this.layer?.destroy();
    this.layer = new Layer(this.map);
    this.layer.setData(this.data);
    this.layer.setVisibility(this.options.isVisible);
  };

  public readonly setVisibility = (value: boolean) => {
    this.options.isVisible = value;
    this.layer?.setVisibility(this.options.isVisible);
  };

  public readonly setData = (data: TransportHubsTypes.FeatureCollection) => {
    this.data = data.features;
    this.layer?.setData(this.data);
  };

  public readonly destroy = () => {
    this.layer?.destroy();
    this.map.off("style.load", this.handleStyleLoad);
  };
}
