import { Middleware, MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { EVENTS, on } from "observer";
import { CHANGE_SELECTED_DAY, ES_UPDATE_SELECTED } from "old-store/view/action-types";
import { routerSlice } from "features/ctrl-route/store/slice";
import { TIME_STATUS } from "features/ctrl-route/types";
import { TRAFFIC_UPDATE_TYPE } from "old-store/traffic/action-types";
import { updateStateInUrl } from "utils/update-state-in-url";
import { telemetrySlice } from "./slice";
import { RnisTelemetryController } from "../controllers/RnisTelemetryController";
import { APP_ENV } from "app-env";
import { TelemetryController } from "../controllers/TelemetryController";

let _store: MiddlewareAPI<any, GState>;

let controller: RnisTelemetryController | TelemetryController;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  if (Boolean(APP_ENV?.REACT_APP_IS_TELEMETRY_RNIS)) {
    controller = new RnisTelemetryController(_map, _store);
  } else {
    controller = new TelemetryController(_map, _store);
  }
});

export const telemetryMiddleware: Middleware<GState> = (store) => (next) => async (action) => {
  next(action);

  if (!_store) _store = store;

  if (telemetrySlice.actions.setIsActive.match(action)) {
    controller?.update();
    updateStateInUrl("telemetry", action.payload);
  }

  if (action.type === ES_UPDATE_SELECTED) controller?.update();

  if (
    [CHANGE_SELECTED_DAY, TRAFFIC_UPDATE_TYPE].includes(action.type) &&
    !Boolean(APP_ENV?.REACT_APP_IS_TELEMETRY_RNIS)
  ) {
    controller?.updateLayersAfterChangeHistoryDate();
  }

  if (action.type === "UPDATE_VIEW") controller?.update();

  if (routerSlice.actions.setTimeStatus.match(action))
    if (action.payload !== TIME_STATUS.last) {
      store.dispatch(telemetrySlice.actions.setIsActive(false));
    }

  if (action.type === TRAFFIC_UPDATE_TYPE)
    if (action.payload === "forecast") {
      // @ts-ignore
      store.dispatch(telemetrySlice.actions.setIsActive(false));
    }
};
