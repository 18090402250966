/**
 * Возвращает состояние активности компонента из url
 * @param key - ключ компонента
 */
export function getInitialStateFromUrl(key: string) {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const isActive = searchParams.get(key);
  return !!Number(isActive);
}
