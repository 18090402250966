import { memo } from "react";
import { Checkbox } from "components";

import "./route-metro-ctrl.scss";

type Props = {
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const baseClass = "route-metro-ctrl";

export const RouteMetroCtrl = memo<Props>((props) => {
  const { isChecked, onChange } = props;

  return (
    <div className={baseClass}>
      <Checkbox label="С учётом метро" checked={isChecked} value="metro" onChange={onChange} />
    </div>
  );
});
