import { location } from "./location";
import { meta } from "./meta";
import { router } from "./router";
import { tiles } from "./tiles";
import { truckGraph } from "./truck-graph";

export const RouterAPI = {
  meta,
  router,
  location,
  tiles,
  truckGraph,
};
