/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import mapboxgl from "mapbox-gl";
import {
  TELEMETRY_BIND_POINT_ID,
  TELEMETRY_BIND_UNMATCHED_POINT_ID,
  TELEMETRY_POINTS_ID,
} from "map-helpers/order-layers";
import moment from "moment";

const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  className: "dtm-popup",
});

let pointsPopupTimeout;

/**
 * Convert bearing to degrees
 * @param {Number} bearing - bearing
 * @returns {Number} - degrees
 */
function bearingToDegrees(bearing) {
  if (bearing < 90) return 90 - bearing;

  return 90 + (360 - bearing);
}

const externalSystems = {};
externalSystems[1] = { Name: "ДЖКХ" };
externalSystems[2] = { Name: "Коммерческие перевозчики" };
externalSystems[3] = { Name: "Эвакуаторы АМПП" };
externalSystems[4] = { Name: "Инспекторы и техники АМПП" };
externalSystems[5] = { Name: "Инспекторы МАДИ" };
externalSystems[6] = { Name: "Транспорт ЦОДД" };
externalSystems[7] = { Name: "МГТ" };
externalSystems[8] = { Name: "Инспекторы ГКУ ОП" };
externalSystems[9] = { Name: "Аэроэкспресс" };
externalSystems[11] = { Name: "ЦППК" };
externalSystems[12] = { Name: "Скорая помощь" };
externalSystems[13] = { Name: "Транспорт ФИФА" };
externalSystems[14] = { Name: "Такси" };
externalSystems[15] = { Name: "Каршеринг" };
externalSystems[16] = { Name: "Спецтранспорт МГТ" };
externalSystems[17] = { Name: "Общественный транспорт Московской области" };
externalSystems[100] = { Name: "Грузовой транспорт" };

const sourceArrowImage =
  "data: image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8dGl0bGU+cm91dGUtODwvdGl0bGU+CjxwYXRoIG9wYWNpdHk9IjAuODciIGZpbGw9IiMwMDAiIGQ9Ik0xNS45NzkgNS4zMzNjLTAuMzYxIDAuMDA2LTAuNjg1IDAuMTU0LTAuOTIyIDAuMzkxbC01LjMzMyA1LjMzM2MtMC4yNTMgMC4yNDMtMC40MSAwLjU4NC0wLjQxIDAuOTYyIDAgMC43MzYgMC41OTcgMS4zMzMgMS4zMzMgMS4zMzMgMC4zNzggMCAwLjcxOS0wLjE1NyAwLjk2MS0wLjQwOWwwLTAgNC4zOTEtNC4zOTEgNC4zOTEgNC4zOTFjMC4yNDMgMC4yNTMgMC41ODQgMC40MSAwLjk2MiAwLjQxIDAuNzM2IDAgMS4zMzMtMC41OTcgMS4zMzMtMS4zMzMgMC0wLjM3OC0wLjE1Ny0wLjcxOS0wLjQxLTAuOTYxbC0wLTAtNS4zMzMtNS4zMzNjLTAuMjQxLTAuMjQxLTAuNTc1LTAuMzkxLTAuOTQzLTAuMzkxLTAuMDA3IDAtMC4wMTQgMC0wLjAyMSAwaDAuMDAxeiI+PC9wYXRoPgo8cGF0aCBvcGFjaXR5PSIwLjg3IiBmaWxsPSIjMDAwIiBkPSJNMTUuOTc5IDUuMzE1Yy0wLjcyNyAwLjAxMi0xLjMxMyAwLjYwNC0xLjMxMyAxLjMzMyAwIDAuMDA2IDAgMC4wMTMgMCAwLjAxOXYtMC4wMDEgMjBjLTAgMC4wMDYtMCAwLjAxMi0wIDAuMDE5IDAgMC43MzYgMC41OTcgMS4zMzMgMS4zMzMgMS4zMzNzMS4zMzMtMC41OTcgMS4zMzMtMS4zMzNjMC0wLjAwNy0wLTAuMDEzLTAtMC4wMjB2MC4wMDEtMjBjMC0wLjAwNSAwLTAuMDEyIDAtMC4wMTggMC0wLjczNi0wLjU5Ny0xLjMzMy0xLjMzMy0xLjMzMy0wLjAwNyAwLTAuMDE1IDAtMC4wMjIgMGgwLjAwMXoiPjwvcGF0aD4KPC9zdmc+Cg==";

/**
 * Create HTML content for popup
 * @param {Object} props - properties of graph edge
 * @returns {InnerHTML} - HTML content
 */
function getPopupHTML(props) {
  if (props.esid) {
    const dirDeg = bearingToDegrees(props.bearing);
    const popupHTML = `
    <p>${moment(props.time).format(moment.HTML5_FMT.TIME_SECONDS)}</p>
    <p><strong>Тип</strong>: ${externalSystems[props.esid].Name}</p>
    <p><strong>ИД ТС</strong>: ${props.unitid ?? "-"}</p>
    <p><strong>ИД терминала</strong>: ${props.tmid ?? "-"}</p>
    <p><strong>Азимут</strong>: <img src="${sourceArrowImage}" style="vertical-align:middle;
    transform: rotate(-${dirDeg + 270}deg); width: 20px;"/> ${props.bearing}° (∠ ${dirDeg}°)</p>
    <p><strong>Скорость</strong>: ${props.speed} км/ч</p>`;
    return popupHTML;
  }
}

/**
 * Show popup on graph edge
 * @param {Object} map - map
 * @param {Event} e - event
 * @param {Object} props - properties graph edge
 */
function showPopup(map, e, props) {
  const popupHTML = getPopupHTML(props);
  popup.setLngLat(e.lngLat).setHTML(popupHTML).addTo(map);
}

/**
 * Remove popup
 * */
function removePopup() {
  popup.remove();
}

/**
 * Call when mouse enter area of points
 * @param {Object} map - map
 * @param {Event} e - event
 * @param {Object} features - event features from map
 */
function onMouseEnter(map, e, features) {
  const props = features[0].properties;
  if (typeof pointsPopupTimeout === "number") clearTimeout(pointsPopupTimeout);

  removePopup(map);
  pointsPopupTimeout = setTimeout(() => {
    showPopup(map, e, props);
  }, 300);
}

/**
 * Call when mouse leave area of points
 * @param {Object} map - map
 */
function onMouseLeave(map) {
  if (typeof pointsPopupTimeout === "number") clearTimeout(pointsPopupTimeout);

  removePopup(map);
}

// Map for unsubscribe event listener
let _map;

/**
 * Mouse move handler
 * @param {Event} e - event
 */
function handleMoveMouse(e) {
  const width = 2;
  const height = 2;

  const layers = [];
  [TELEMETRY_POINTS_ID, TELEMETRY_BIND_POINT_ID, TELEMETRY_BIND_UNMATCHED_POINT_ID].forEach((el) => {
    if (_map.getLayer(el)) layers.push(el);
  });

  const zoom = _map.getZoom();
  if (zoom > 11) {
    const features = _map.queryRenderedFeatures(
      [
        [e.point.x - width / 2, e.point.y - height / 2],
        [e.point.x + width / 2, e.point.y + height / 2],
      ],
      { layers }
    );

    if (features.length > 0) onMouseEnter(_map, e, features);
    else onMouseLeave(_map);
  } else onMouseLeave(_map);
}

/**
 * Show points popup
 * @param {Object} map - map
 * @param {Boolean} points - flag
 */
export function togglePointsPopup(map, points) {
  if (points) {
    _map = map;
    map.on("mousemove", handleMoveMouse);
  } else {
    map.off("mousemove", handleMoveMouse);
    removePopup();
  }
}
