import React from "react";
import { HistoryTravelPopup } from "./history-travel-popup";
import { useSelector } from "react-redux";
import { GState } from "../../documentations";

export const HistoryTravel = () => {
  const isActive = useSelector((state: GState) => state.view.time);
  if (isActive) {
    return <HistoryTravelPopup />;
  }
  return null;
};
