import axios from "axios";
import { APP_ENV } from "app-env";
import * as TYPES from "./incident-api-types";

const incidentApi = axios.create({
  baseURL: APP_ENV.REACT_APP_DTM_INCIDENT_API,
});

/**
 * Возвращяет список событий в соответствии с заданным фильтром и сортировкой
 * @param model - модель
 */

const getIncidentList = async <T>(model: TYPES.IncidentInputModel) => {
  const params = new URLSearchParams();
  const source = axios.CancelToken.source();

  if (model.CreateFrom) params.set("CreateFrom", model.CreateFrom);
  if (model.DateWhenActive) params.set("DateWhenActive", String(model.DateWhenActive));
  if (model.Type && model.Type.id) params.set("Type", String(model.Type.id));
  if (model.Status?.id) params.set("Status.Id", String(model.Status.id));
  if (model.PageSize) params.set("PageSize", String(model.PageSize));

  const request = incidentApi.get<T>("Incident", {
    params,
    cancelToken: source.token,
    headers: { Authorization: `Bearer ${model.token}` },
  });

  return {
    request,
    cancelToken: source,
  };
};

const IncidentAPI = {
  getIncidentList,
};

export default IncidentAPI;
