import React, { useEffect, useMemo, useState } from "react";
import { FilterButtonWithCount } from "components";
import { useDispatch, useSelector } from "react-redux";
import { loadStatisticById } from "../../../../store/slice";
import moment from "moment";
import { GState } from "documentations";
import { overSpeedEnum } from "../speedcam-last-fixation/help";

export const getInterval = (timeLength: number) => {
  if (timeLength === 24) return 60;
  if (timeLength === 12) return 30;
  if (timeLength === 1) return 2;
  return 60;
};

type LineType = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    fill: boolean;
    borderColor: string;
  }[];
};

export const useGetStatistic = (camId: number) => {
  const dispatch = useDispatch();

  const currentMode = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.currentMode);
  const dirtyViolations = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.violations);
  const dirtyFixations = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.fixations);
  const filter = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.filter);

  const countFixations = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.countFixations);
  const countViolations = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.countViolations);

  const [timeLength, setTimeLength] = useState<24 | 12 | 1>(24);

  const currentCount = useMemo(
    () => (currentMode === "fixations" ? countFixations : currentMode === "violations" ? countViolations : undefined),
    [currentMode, countFixations, countViolations]
  );

  const filterButtons = useMemo(
    () => (
      <>
        <FilterButtonWithCount
          text={"За час"}
          count={timeLength === 1 ? currentCount : undefined}
          isActive={timeLength === 1}
          onClick={() => setTimeLength(1)}
        />
        <FilterButtonWithCount
          text={"За сегодня"}
          count={timeLength === 12 ? currentCount : undefined}
          isActive={timeLength === 12}
          onClick={() => setTimeLength(12)}
        />
        <FilterButtonWithCount
          text={"За сутки"}
          count={timeLength === 24 ? currentCount : undefined}
          isActive={timeLength === 24}
          onClick={() => setTimeLength(24)}
        />
      </>
    ),
    [timeLength, currentCount]
  );

  const [from, to] = useMemo(() => {
    const from =
      timeLength === 12
        ? moment().format("YYYY-MM-DDT00:00:00")
        : moment().subtract(timeLength, "hours").format("YYYY-MM-DDTHH:00:00");
    return [from, moment().format("YYYY-MM-DDTHH:00:00")];
  }, [timeLength]);

  useEffect(() => {
    const getFilters = (filterValue: typeof filter): { violations?: string; kinds?: 0 | 2 } | undefined => {
      if (filterValue === overSpeedEnum.less20) {
        return {
          kinds: 0,
        };
      }
      if (filterValue === overSpeedEnum.more20) {
        return {
          violations: "overspeed20",
        };
      }
      if (filterValue === overSpeedEnum.more40) {
        return {
          violations: "overspeed40",
        };
      }
      if (filterValue === overSpeedEnum.more60) {
        return {
          violations: "overspeed60",
        };
      }
      if (filterValue === overSpeedEnum.more80) {
        return {
          violations: "overspeed80",
        };
      }
      if (filterValue === "withViolations") {
        return {
          kinds: 2,
        };
      }
    };
    dispatch(
      loadStatisticById({ from, to, intervalMinute: getInterval(timeLength), whCamId: camId, ...getFilters(filter) })
    );
  }, [from, to, camId, timeLength, filter]);

  const fixations: LineType = useMemo(() => {
    const labels = dirtyFixations?.map((i) => moment(i.date).format("HH:mm")) || [];
    const data = dirtyFixations?.map((i) => i.count) || [];
    return {
      labels: labels,
      datasets: [
        {
          label: "Количество фиксаций",
          data,
          fill: false,
          borderColor: "#67B0FF",
          backgroundColor: "#67B0FF",
        },
      ],
    };
  }, [dirtyFixations, currentMode]);

  const violations: LineType = useMemo(() => {
    const labels = dirtyViolations?.map((i) => moment(i.date).format("HH:mm")) || [];
    const data = dirtyViolations?.map((i) => i.count) || [];

    return {
      labels,
      datasets: [
        {
          label: "Количество нарушений",
          data,
          fill: false,
          borderColor: "#FF6158",
          backgroundColor: "#FF6158",
        },
      ],
    };
  }, [dirtyViolations, currentMode]);

  return {
    filterButtons,
    fixations,
    violations,
    currentMode,
    timeLength,
  };
};
