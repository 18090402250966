/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EVENTS } from "observer/events";
import { on } from "observer";
import IconsProperties from "../../map-helpers/properties/icons-properties";
import { meteostationUpdateIsActive } from "../../old-store/global/actions";
import { METEOSTATION_ID, WEATHER_ID } from "../../map-helpers/order-layers";
import { createMeteostationControl } from "./create-meteostation-control";
import "./ctrl-meteostation.scss";
import { APP_ENV } from "app-env";

let map;

on(EVENTS.INIT_MAP, (_map) => {
  map = _map;
});

class CtrlMeteostation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.iconProperties = new IconsProperties();
    this.ctrlMeteostation = undefined;
  }

  componentDidUpdate(prevProps) {
    const { mapInit, isActive } = this.props;
    if (mapInit !== prevProps.mapInit && mapInit) {
      this.addLayer();
    }

    if (isActive !== prevProps.isActive) {
      this.ctrlMeteostation.eventChangeFlag();
    }
  }

  addLayer = () => {
    this.ctrlMeteostation = createMeteostationControl({
      containerMeteostation: this.containerMeteostation,
      map,
      access_token: this.props.access_token,
      apiDomain: APP_ENV.REACT_APP_DTM_WAREHOUSE_API,
    });

    map.addControl(this.ctrlMeteostation);
    this.updateLayer();
  };

  setProperty = () => {
    const iconSize = this.iconProperties.getBigIconSize();

    map.setLayoutProperty(METEOSTATION_ID, "icon-size", iconSize);

    map.setLayoutProperty(METEOSTATION_ID, "icon-allow-overlap", true);

    const weatherLayer = map.getLayer(WEATHER_ID);

    if (weatherLayer) map.moveLayer(METEOSTATION_ID, WEATHER_ID);
  };

  updateLayer() {
    const layer = map.getLayer(METEOSTATION_ID);
    let timeInterval;

    if (layer) {
      this.setProperty();
    } else {
      timeInterval = setInterval(() => {
        const checkLayer = map.getLayer(METEOSTATION_ID);
        if (checkLayer) {
          clearInterval(timeInterval);
          this.setProperty();
        }
      }, 300);
    }
  }

  onClick = (event) => {
    const { updateIsActive, isActive } = this.props;
    updateIsActive(!isActive);
    event.stopPropagation();
  };

  render() {
    return (
      <div
        title="Метеостанции"
        className="ctrl-meteostation"
        onClick={this.onClick}
        ref={(node) => {
          this.containerMeteostation = node;
        }}
      />
    );
  }
}

CtrlMeteostation.propTypes = {
  mapInit: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  access_token: PropTypes.string.isRequired,
  updateIsActive: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isActive: state.global.meteostation,
    access_token: state.oidc.user.access_token,
    mapInit: state.router.mapIsLoaded,
  }),
  (dispatch) => ({
    updateIsActive: (data) => dispatch(meteostationUpdateIsActive(data)),
  })
)(CtrlMeteostation);
