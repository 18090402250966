import getBeforeId from "../order-layers";
import Layer from "./layer";

export default class RouteIntermediatePointLayer extends Layer {
  constructor(map, layerId, key) {
    super(map);
    this.map = map;
    this.layerId = layerId;
    this.key = key;
  }

  update(coordinates) {
    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: { id: this.key },
        },
      ],
    };

    const source = this.map.getSource(this.layerId);

    if (!source) {
      this.map.addSource(this.layerId, {
        type: "geojson",
        data: geojson,
      });
    } else {
      source.setData(geojson);
    }

    const beforeId = getBeforeId(this.layerId, this.map);

    const layer = this.map.getLayer(this.layerId);

    if (!layer) {
      this.map.addLayer(
        {
          id: this.layerId,
          type: "circle",
          source: this.layerId,
          layout: {},
          paint: {
            "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 4, 12, 7, 18, 11],
            "circle-color": "#000",
            "circle-opacity": 0.3,
            "circle-stroke-width": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 2, 12, 3, 18, 4],
            "circle-stroke-color": "#fff",
          },
        },
        beforeId
      );
    }
  }
}
