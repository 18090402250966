import { Middleware, MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { updateStateInUrl } from "utils/update-state-in-url";
import { CameraController } from "../controller";
import { cameraSlice } from "./slice";

let _store: MiddlewareAPI<any, GState>;
let controller: CameraController;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  controller = new CameraController(map, _store);
});

export const cameraMiddleware: Middleware<GState> = (store) => (next) => async (action) => {
  next(action);

  if (!_store) _store = store;

  if (cameraSlice.actions.setIsActive.match(action)) {
    controller.update();
    updateStateInUrl("camera", action.payload);
  }
};
