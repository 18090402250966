import * as THREE from "three";
// @ts-ignore
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { Group } from "./group";
import { CellsCache } from "./cells-cache";
import * as utils from "./diagram-3d.utils";
import * as consts from "./diagram-3d.consts";
import { DiagramDataRows, DiagramDataRow } from "./diagram-3d.types";

type GenerateRowGeometry = {
  row: DiagramDataRow;
  rowIndex: number;
  rows: DiagramDataRows;
  geometries: ReturnType<typeof utils.emptyGeometriesByColorsFactory>;
};

const defaultMaxFilter = 100000;

export class SegmentsRows extends Group {
  public cache: CellsCache = new CellsCache();
  private materials = utils.createMaterialsByColors(this.opacity);
  protected appearance?: DiagramAppearance;
  protected unitsCountFilter: [number, number] | null = null;

  private get minFilter() {
    return this.unitsCountFilter?.[0] ?? 0;
  }

  private get maxFilter() {
    return this.unitsCountFilter?.[1] ?? defaultMaxFilter;
  }

  private mergeAndAddGeometries = (geometries: ReturnType<typeof utils.emptyGeometriesByColorsFactory>) => {
    Object.keys(geometries).forEach((key) => {
      const colorGeometries = geometries[key];
      const material = this.materials[Number(key)];
      if (!colorGeometries.length || !material) return;
      const geometry = mergeBufferGeometries(colorGeometries);
      const mesh = new THREE.Mesh(geometry, material);

      mesh.name = "cell";
      this.world.add(mesh);
    });
  };

  private getColorByTraffic = (speed: number, freeRowSpeed: number) => {
    if (!this.appearance || !Array.isArray(this.appearance)) return -1;

    const cellPercentage = speed / freeRowSpeed;

    if (isNaN(cellPercentage)) return -1;

    const getDelta = (value: number) => Math.abs(value / 100 - cellPercentage);

    const appearanceType = this.appearance.reduce(
      (acc, setting, index) => {
        const isNotValidIteration =
          !Array.isArray(this.appearance) || index === 0 || index === this.appearance.length - 1;
        if (isNotValidIteration) return acc;
        const delta = getDelta(setting.value);
        if (acc.delta <= delta) return acc;
        return {
          delta,
          setting,
        };
      },
      {
        delta: getDelta(this.appearance[0].value),
        setting: this.appearance[0],
      }
    );

    return appearanceType.setting.key;
  };

  private generateRowGeometry = (args: GenerateRowGeometry) => {
    if (!this.sizes) return;

    const { rows, row, rowIndex, geometries } = args;
    const substrateStartY = this.sizes.height / 2;
    const segmentHeight = utils.getSegmentHeight(this.sizes.height, rows.length);
    const y = substrateStartY - segmentHeight / 2 - segmentHeight * rowIndex;

    if (!row.cells) return this;

    const segmentWidth = utils.getSegmentWidth(this.sizes.width);
    const startX = -this.sizes.width / 2 + segmentWidth / 2;

    let lineCounter = 0;
    const cellsArray = Object.values(row.cells);
    const cellKeys = Object.keys(row.cells);

    for (let cellIndex = 0; cellIndex < cellsArray.length; cellIndex++) {
      const cell = cellsArray[cellIndex];
      if (cell.unitsCount >= this.minFilter && cell.unitsCount <= this.maxFilter) {
        const boxDepth = utils.getSegmentDepth(cell.unitsCount);
        const gridLineWidth = lineCounter === 0 && cellIndex !== 0 ? consts.gridLineWidth : 0;
        const x = startX + segmentWidth * cellIndex + gridLineWidth;
        const z = boxDepth / 2;

        if (cell.unitsCount) {
          const geometry = new THREE.BoxGeometry(segmentWidth, segmentHeight, boxDepth);
          geometry.translate(x, y, z);
          const key = this.getColorByTraffic(cell.speed, row.freeFlowSpeed);
          geometries[key].push(geometry);
        }

        if (lineCounter === 3) {
          lineCounter = 0;
        } else {
          lineCounter++;
        }

        this.cache.add({
          x,
          y,
          z,
          rowIndex,
          columnIndex: cellIndex,
          box: {
            startX: x - segmentWidth / 2,
            startY: y + segmentHeight / 2,
            endX: x + segmentWidth / 2,
            endY: y - segmentHeight / 2,
          },
          cell: {
            ...cell,
            timeStamp: cellKeys[cellIndex],
          },
        });
      }
    }
  };

  public readonly calculate = (rows: DiagramDataRows) => {
    if (!this.sizes) return this;
    const geometries = utils.emptyGeometriesByColorsFactory();
    this.cache.clear();

    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      this.generateRowGeometry({
        rows,
        row: rows[rowIndex],
        rowIndex,
        geometries,
      });
    }

    this.mergeAndAddGeometries(geometries);

    return this;
  };

  public readonly setAppearance = (appearance: SegmentsRows["appearance"]) => {
    this.appearance = appearance;
  };

  public readonly setUnitsCountFilter = (unitsCountFilter: SegmentsRows["unitsCountFilter"]) => {
    this.unitsCountFilter = unitsCountFilter;
  };

  public readonly setOpacity = (opacity: number) => {
    this.opacity = opacity;

    for (let childrenIndex = 0; childrenIndex < this.world.children.length; childrenIndex++) {
      const child = this.world.children[childrenIndex];
      if (child instanceof THREE.Mesh) {
        child.material.opacity = this.opacity;
      }
    }

    return this;
  };
}
