export enum IconSVGName {
  bus = "bus",
  camera = "camera",
  cargo = "cargo",
  city = "city",
  clock = "clock",
  detector = "detector",
  dline = "dline",
  dtp = "dtp",
  magistral = "magistral",
  route = "route",
  speedcam = "speedcam",
  station = "station",
  telemetry = "telemetry",
  tls = "tls",
  weather = "weather",
  flooding = "flooding",
  blocking_plan = "blocking_plan",
  blocking_temporary = "blocking_temporary",
  correlationCompareIcon = "correlationCompareIcon", // #5878
  pedestrian = "pedestrian",
  transport_hubs = "transport_hubs",
  travel_heatmap = "travel_heatmap",
  zone = "zone",
  settings = "settings",
  history = "history",
}
