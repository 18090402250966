import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";
import getBeforeId, { TELEMETRY_BIND_MATCHED } from "map-helpers/order-layers";

export class TelemetryMatchedLayer extends BaseLayer.Abstract<GeoJSON.LineString, GeoJSON.GeoJsonProperties> {
  private layerConfig: mapboxgl.LineLayer = {
    id: TELEMETRY_BIND_MATCHED,
    type: "line",
    source: TELEMETRY_BIND_MATCHED,
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#007efc",
      "line-width": 2,
    },
  };

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: TELEMETRY_BIND_MATCHED,
      beforeId: getBeforeId(TELEMETRY_BIND_MATCHED, map),
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }
}
