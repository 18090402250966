import moment from "moment";
import React from "react";
import { SelectorKey, Option } from "../types";

type DateChangeFunc = (date: string) => string;
type Dates = { next: DateChangeFunc; prev: DateChangeFunc } & { [key: string]: DateChangeFunc };

const optionsSource: Option[] = [
  {
    value: SelectorKey.TODAY,
    label: "сегодня",
  },
  {
    value: SelectorKey.YESTERDAY,
    label: "вчера",
  },
  {
    value: SelectorKey.BEFORE_YESTERDAY,
    label: "позавчера",
  },
  {
    value: SelectorKey.SPLW,
    label: "АППН",
  },
  {
    value: SelectorKey.SPLM,
    label: "АППМ",
  },
  {
    value: SelectorKey.SPLY,
    label: "АППГ",
  },
];

const searchOption = (value: SelectorKey) => {
  return optionsSource.find((opt) => opt.value === value);
};

const searchOptionByLabel = (value: string) => {
  return optionsSource.find((opt) => opt.label === value);
};

const parse = () => {
  return optionsSource.map((option) => {
    return (
      <option key={`opt_${option.value}`} value={option.value}>
        {option.label}
      </option>
    );
  });
};

export const options = {
  get: parse,
  search: {
    value: searchOption,
    label: searchOptionByLabel,
  },
};

const setNormalStyle = (select: HTMLSelectElement) => {
  select.setAttribute("size", "1");
  select.style.marginTop = "0px";
  select.style.border = "none";
  select.style.textAlignLast = "center";
};

const setTallStyle = (select: HTMLSelectElement) => {
  select.setAttribute("size", options.get().length.toString());
  select.style.marginTop = "80px";
  select.style.textAlignLast = "left";
  select.style.border = "0.5px solid #000000";
};

export const select = {
  style: {
    normal: setNormalStyle,
    tall: setTallStyle,
  },
};

const nextDate = (date: string) => moment(date).add(1, "day").toISOString(true);
const prevDate = (date: string) => moment(date).subtract(1, "day").toISOString(false);
const getToday = () => moment().toISOString(true);
const getYesterday = () => prevDate(getToday());
const getBeforeYesterday = () => prevDate(prevDate(getToday()));
const getSamePeriodLastWeek = (date: string) => moment(date).subtract(1, "week").toISOString(false);
const getSamePeriodLastMonth = (date: string) => moment(date).subtract(1, "month").toISOString(false);
const getSamePeriodLastYear = (date: string) => moment(date).subtract(1, "year").toISOString(false);

export const dates: Dates = {
  next: nextDate,
  prev: prevDate,
  [SelectorKey.TODAY]: getToday,
  [SelectorKey.YESTERDAY]: getYesterday,
  [SelectorKey.BEFORE_YESTERDAY]: getBeforeYesterday,
  [SelectorKey.SPLW as string]: getSamePeriodLastWeek,
  [SelectorKey.SPLM as string]: getSamePeriodLastMonth,
  [SelectorKey.SPLY as string]: getSamePeriodLastYear,
};
