import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "../../../../store";

export const useIsVolumetric = () => useSelector((state: GState) => state.sectorAnalysis.isVolumetric);

export const useHandleChange = () => {
  const isUnitsCountDiagram = useIsVolumetric();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (id: string) => {
      if (!id) return;
      dispatch(sectorAnalysisSlice.actions.setIsVolumetric(!!parseInt(id)));
    },
    [isUnitsCountDiagram, dispatch]
  );

  return handleChange;
};
