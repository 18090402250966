import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { compareArrays } from "../../utils/compare-two-arrays";
import FilterItem from "./filter-item/filter-item";
import "./filter-system.scss";
import _ from "lodash";
import { LoadingStyledComponent } from "./assets/LoadingStyledComponent";

export const className = "filter-system";

/** Состояние фильтра перед включением режима НГПТ */
let previousFilter;
class FilterExternal extends React.Component {
  constructor(props) {
    super(props);

    this._isMount = false;

    this.state = {
      isShowPopup: false,
      localSelectedItems: [],
    };
  }

  setLocalSelectedItems = (localSelectedItems) => {
    this.setState((prevState) => ({ ...prevState, localSelectedItems }));
  };

  componentDidMount() {
    this._isMount = true;
    this.setLocalSelectedItems(this.props.selected);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.props;
    const { transportTypeDictionary: items } = this.props;

    const [updateLocalSelected, localSelectedItems] = [this.setLocalSelectedItems, this.state.localSelectedItems];

    if (!_.isEqual(prevProps.selected, this.props.selected)) {
      updateLocalSelected(this.props.selected);
    }

    if (prevProps.filter !== filter) {
      if (filter === "ptr" || filter === "trans") {
        previousFilter = localSelectedItems;

        updateLocalSelected([2, 7, 17]);
      } else if (prevProps.filter === "ptr" || prevProps.filter === "trans") {
        if (previousFilter) {
          updateLocalSelected(previousFilter);
        } else {
          updateLocalSelected(items.map((el) => el.id));
        }
      }
    }

    if (prevState.isShowPopup !== this.state.isShowPopup && !this.state.isShowPopup) {
      if (!_.isEqual(this.props.selected, localSelectedItems)) {
        this.props.updateSelected(localSelectedItems);
      }
    }

    if (prevProps.isShowPopup !== this.props.isShowPopup) {
      this.setState({ isShowPopup: this.props.isShowPopup });
    }

    if (prevState.isShowPopup !== this.state.isShowPopup) {
      this.props.setIsShowPopup(this.state.isShowPopup);
    }
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  /** Обработка клика на пункт меню */
  handleChange = (e, item) => {
    /** Prevent twice fire on click */
    if (e.target.type === "checkbox") return null;

    const { filter } = this.props;

    const [updateLocalSelected, localSelectedItems] = [this.setLocalSelectedItems, this.state.localSelectedItems];

    const { transportTypeDictionary: items } = this.props;

    let newSelected = [...localSelectedItems];

    const checked = localSelectedItems.includes(item);

    const allItems = items.map((el) => el.id);

    if (e.target.className === "route-checkbox-checkmark") {
      /** При клике на чекбокс, переключается только текущий элемент */
      if (compareArrays([item], [...localSelectedItems])) newSelected = allItems.filter((el) => el !== item);
      else if (checked) newSelected = newSelected.filter((el) => el !== item);
      else newSelected.push(item);
    } else {
      /** Если это единственный включенный элемент, то он отключается, а остальные включаются */
      if (compareArrays([item], [...localSelectedItems])) newSelected = allItems.filter((el) => el !== item);
      else newSelected = [item];
      /** При клике на строку, выделяется текущий элемент, остальные отключаются */
    }

    /** При включенном фильтре транспорта, все пункты заблокированы */
    if (filter !== "ptr" && filter !== "trans") {
      updateLocalSelected(newSelected);
    }
  };

  /** Обработка клика на иконку в header */
  handleClick = (e, item) => {
    if (this.props.disabled) return;

    const { filter } = this.props;

    const [updateLocalSelected, localSelectedItems] = [this.setLocalSelectedItems, this.state.localSelectedItems];

    const { transportTypeDictionary: items } = this.props;
    let newSelected = [...localSelectedItems];
    const checked = localSelectedItems.includes(item);
    const allItems = items.map((el) => el.id);

    if (compareArrays([item], [...localSelectedItems])) newSelected = allItems.filter((el) => el !== item);
    else if (checked) newSelected = newSelected.filter((el) => el !== item);
    else newSelected.push(item);

    /** При включенном фильтре транспорта, все пункты заблокированы */
    if (filter !== "ptr" && filter !== "trans") {
      updateLocalSelected(newSelected);
    }
  };

  /** Обработка клика на кнопку "Все" */
  setCheckedAll = (e) => {
    const { transportTypeDictionary: items } = this.props;

    const { filter } = this.props;

    const [updateLocalSelected, localSelected] = [this.setLocalSelectedItems, this.state.localSelectedItems];

    /** Prevent twice fire on click */
    const disabled = filter === "ptr" || filter === "trans";

    if (e.target.type === "checkbox" || disabled) return null;

    const isAllChecked = this.getAllChecked();

    /** Если все пункты выбраны, то кнопка заблокирована */
    if (isAllChecked) return null;

    let allArray = [];

    const allItems = items.map((el) => el.id);

    if (e.target.className === "route-checkbox-checkmark") {
      /** При клике на чекбокс, включаются все элементы */
      allArray = allItems;
    } else {
      /** При клике на строку, список инвертируется */
      allArray = allItems.filter((el) => !localSelected.includes(el));
    }

    updateLocalSelected(allArray);
  };

  /** Возвращает выбраны все пункты меню или нет */
  getAllChecked = () => {
    const { transportTypeDictionary: items } = this.props;
    const { localSelectedItems } = this.state;

    if (!items) return true;

    const allArray = items.map((el) => el.id);

    return compareArrays(allArray, [...localSelectedItems]);
  };

  /** Возвращает заблокирована кнопка "Все" или нет */
  getAllDisabled = () => {
    const { filter } = this.props;

    if (filter === "ptr" || filter === "trans") return true;

    if (this.getAllChecked()) return true;

    return false;
  };

  setPopupVisibility = (isShowPopup) => () => {
    if (this.props.disabled) return;
    if (!this._isMount) return;
    this.setState({ isShowPopup });
  };

  render() {
    const { isShowPopup, localSelectedItems } = this.state;
    const { transportTypeDictionary: items, toggleTitle = "Все типы транспорта" } = this.props;

    const { filter, rowHeight, disabled: isGlobalDisabled } = this.props;
    const disabled = filter === "ptr" || filter === "trans";
    const popupHeight = isShowPopup ? (items?.length + 1) * rowHeight + 20 : 0;
    const classNames = cn(`${className}`, { [`${className}-content_disabled`]: isGlobalDisabled });

    if (!items) {
      return (
        <div className={classNames} style={{ overflow: "hidden" }}>
          <LoadingStyledComponent />
        </div>
      );
    }

    return (
      <div
        className={classNames}
        onMouseEnter={this.setPopupVisibility(true)}
        onMouseLeave={this.setPopupVisibility(false)}>
        <div className={`${className}-popup`} style={{ height: popupHeight }}>
          <div>
            <FilterItem
              name={toggleTitle}
              value={null}
              handleClick={this.setCheckedAll}
              isActive={this.getAllChecked()}
              disabled={this.getAllDisabled()}
              rowHeight={rowHeight}
            />
            {items.map((el) => {
              return (
                <FilterItem
                  name={el.name}
                  value={el.id}
                  key={el.id}
                  handleClick={(e) => !isGlobalDisabled && this.handleChange(e, el.id)}
                  isActive={localSelectedItems.includes(el.id)}
                  disabled={disabled}
                  rowHeight={rowHeight}
                  detectorMode={this.props.detectorMode}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

FilterExternal.propTypes = {
  selected: PropTypes.instanceOf(Array),
  updateSelected: PropTypes.func,
  filter: PropTypes.string,
  rowHeight: PropTypes.number,
  disabled: PropTypes.bool,
  detectorMode: PropTypes.bool,
};

export default FilterExternal;
