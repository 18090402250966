import React from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { RouteCosting } from "api/router/model/router";
import { useRouterCosting, useIsDisabled } from "../../../hooks";
import * as ACTIONS from "features/ctrl-route/store/slice";
import "./route-button-add.scss";

const className = "route-button-add";

const RouteButtonAdd: React.FC = () => {
  const costing = useRouterCosting();
  const isDisabled = useIsDisabled();
  const dispatch = useDispatch();
  const containerClassName = cn(className, { [`${className}_disabled`]: isDisabled });

  const handleClick = () => {
    dispatch(ACTIONS.addPoint());
  };

  if (RouteCosting.Template === costing) return <div className={className} />;

  return (
    <button disabled={isDisabled} onClick={handleClick} className={containerClassName} type="button">
      <i className="dtm-add-icon" />
      <span className={`${className}-label`}>Добавить</span>
    </button>
  );
};

export default RouteButtonAdd;
