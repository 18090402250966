import { GeojsonLayer } from "map-helpers/new-layer/geojson-layer";
import getBeforeId from "map-helpers/order-layers";
import { Map } from "mapbox-gl";
import TrafficProperties from "../../../map-helpers/properties/traffic-properties";

export class RoadDetectorLayer extends GeojsonLayer {
  private properties: TrafficProperties;

  constructor(map: Map, id: string, id1: string) {
    super(map, id, id1);
    this.properties = new TrafficProperties();
  }
  protected addLayer() {
    const layer: mapboxgl.LineLayer = {
      id: this.layerId,
      type: "line",
      source: this.sourceId,
      // @ts-ignore
      layout: this.properties.getLayout(),
      // @ts-ignore
      paint: {
        ...this.properties.getPaint(),
        "line-width": 3,
        // @ts-ignore
        "line-offset": this.properties.getLineOffsetByForward(),
      },
    };

    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  protected addSource(data: GeoJSON.FeatureCollection) {
    this.map.addSource(this.sourceId, {
      type: "geojson",
      data,
    });
  }
}
