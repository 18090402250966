import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENTS, on } from "observer";
import { NavigationGroupeLayers } from "components";
import GraphEdgesLayer from "map-helpers/layers/graph-edges-layer";
import { graphUpdateIsActiveAC } from "old-store/view/action-creators";
import * as consts from "./ctrl-graph.consts";
import mapboxgl from "mapbox-gl";
import { GState } from "documentations";
import { useStoreContext } from "store";
import { toggleGraphCard } from "./graph-card/graph-card-helper";
import { useGraphCard } from "./graph-card";

import "./styles/ctrl-graph.scss";

let map: mapboxgl.Map;
let layer: GraphEdgesLayer;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

export const CtrlGraph = () => {
  const dispatch = useDispatch();
  const { setIsShowCard, setEdgeInfo } = useGraphCard();
  const { store } = useStoreContext();

  const dateOfGraph = store.graphCompare.hooks.useGraphVersions()[0].date || "";

  const isActive = useSelector((state: GState) => state.view.graph);
  const mapInit = useSelector((state: GState) => state.router.mapIsLoaded);

  const changeIsActive = (value: boolean) => dispatch(graphUpdateIsActiveAC(value));

  const addLayer = () => {
    if (isActive && map) {
      layer?.add();
      toggleGraphCard(map, true, setIsShowCard, setEdgeInfo);
    }
  };

  const removeLayer = () => {
    layer?.remove();
    toggleGraphCard(map, false, setIsShowCard, setEdgeInfo);
  };

  useEffect(() => {
    if (mapInit) {
      layer = new GraphEdgesLayer(map);
      map.on("style.load", () => addLayer());
    }
  }, [mapInit, map]);

  useEffect(() => {
    if (mapInit) {
      if (isActive) {
        addLayer();
      } else {
        removeLayer();
      }
    }
  }, [isActive, mapInit]);

  useEffect(() => {
    layer && isActive && layer.updateDate({ date: dateOfGraph });
  }, [dateOfGraph, isActive]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    changeIsActive(!isActive);
  };

  return (
    <div className="ctrl-graph">
      <NavigationGroupeLayers.Item
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isActive={isActive}
        onClick={handleClick}
      />
    </div>
  );
};
