import React from "react";
import { hooks } from "./hooks";
import { reducer } from "./store.reducer";
import { initialize } from "./store.initialize";
import { initialState } from "./store.constants";

export const useStore = (id: string) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const initializeState = hooks.useInitializeState(dispatch);
  const handleCreateAttribute = hooks.useCreateAttribute(dispatch);
  const handleDeleteAttribute = hooks.useDeleteAttribute(id, dispatch);
  const handleSubmitSave = hooks.useSubmitSave(id, dispatch);

  const actions = {
    handleCreateAttribute,
    handleDeleteAttribute,
    handleSubmitSave,
  };

  React.useEffect(() => {
    initializeState(initialize(id));
  }, [id, initializeState]);

  return { state, actions };
};
