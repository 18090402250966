import React from "react";
import { createEvent } from "effector";
import { TravelHeatmapTypes } from "types";
import { State } from "./travel-heatmap.types";

const mapLoaded = createEvent();
const restoreActiveItems = createEvent();
const setColorPrice = createEvent<number>();
const setIsDisabled = createEvent<State["isDisabled"]>();
const setWeekdays = createEvent<State["weekdays"]>();
const setIsLoading = createEvent<State["isLoading"]>();
const handleTravelHeatmapIsActiveChange = createEvent<State>();
const handleHoverItemChange = createEvent<State["hoverItem"]>();
const handleActiveItemChange = createEvent<State["activeItem"]>();
const handleListHoverItemChange = createEvent<State["hoverItem"]>();
const setMinMaxFilter = createEvent<TravelHeatmapTypes.MinMaxFilter>();
const addSectorInfo = createEvent<TravelHeatmapTypes.SectorInformation>();
const handleTimeRangeChange = createEvent<{ min: number; max: number }>();
const handleCardCloseClick = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handlePeriodChange = createEvent<TravelHeatmapTypes.PeriodChangePayload>();
const handleDirectionModeChange = createEvent<TravelHeatmapTypes.SelectOption>();
const handleTransportTypeChange = createEvent<TravelHeatmapTypes.SelectOption>();
const handleTravelHeatmapClick = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handleMissingStatistic = createEvent<TravelHeatmapTypes.LoadSectorInfoPayload>();
const handleIsCorrespondenceMatrixChange = createEvent<React.ChangeEvent<HTMLInputElement>>();
const handleFilterChange = createEvent<TravelHeatmapTypes.FilterParams>();

export const events = {
  mapLoaded,
  setWeekdays,
  setIsLoading,
  setColorPrice,
  addSectorInfo,
  setIsDisabled,
  setMinMaxFilter,
  restoreActiveItems,
  handlePeriodChange,
  handleFilterChange,
  handleCardCloseClick,
  handleTimeRangeChange,
  handleHoverItemChange,
  handleMissingStatistic,
  handleActiveItemChange,
  handleTravelHeatmapClick,
  handleDirectionModeChange,
  handleListHoverItemChange,
  handleTransportTypeChange,
  handleTravelHeatmapIsActiveChange,
  handleIsCorrespondenceMatrixChange,
};

export type Events = typeof events;
