import React, { useCallback } from "react";
import { IconSVGName, SingleButton } from "../../components";
import { timeUpdateIsActiveAC } from "../../old-store/view/action-creators";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GState } from "../../documentations";
import { useGetHistoryTravelCtrl } from "../view-data/time-travel-hook";

export const HistoryTravelButton = () => {
  useGetHistoryTravelCtrl();
  const dispatch = useDispatch();

  const { isActive, routeIsActive: disabled } = useSelector(
    (state: GState) => ({
      isActive: state.view.time,
      routeIsActive: state.router.isActive,
    }),
    shallowEqual
  );

  const handleClick = useCallback(() => {
    dispatch(timeUpdateIsActiveAC(!isActive));
  }, [dispatch, isActive]);

  return (
    <div className="view-data">
      <SingleButton
        isActive={isActive}
        onClick={handleClick}
        type="round"
        title=""
        iconSvg={IconSVGName.history}
        disabled={disabled}
        buttonGroupItem
      />
    </div>
  );
};
