import React, { memo } from "react";
import { Checkbox } from "components";
import { CheckboxGroup } from "../checkbox-group";
import { useValues, useCallbacks } from "./average-checkboxes.hooks";

export const AverageCheckboxes = memo(() => {
  const { isShowAverageSpeed, isShowAverageTime } = useValues();
  const { handleIsShowAverageSpeedChange, handleIsShowAverageTimeChange } = useCallbacks();

  return (
    <CheckboxGroup>
      <Checkbox
        value=""
        label="Средняя скорость на участке"
        title="Средняя скорость на участке"
        checked={isShowAverageSpeed}
        onChange={handleIsShowAverageSpeedChange}
      />
      <Checkbox
        value=""
        label="Среднее время в пути"
        title="Среднее время в пути"
        checked={isShowAverageTime}
        onChange={handleIsShowAverageTimeChange}
      />
    </CheckboxGroup>
  );
});
