import { TravelHeatmapTypes } from "types";

export enum Events {
  activeItemChange = "@dtm.map.travel-heatmap.active-item.change",
  hoverItemChange = "@dtm.map.travel-heatmap.hover-item.change",
  missingSectorInfo = "@dtm.map.travel-heatmap.sector-info.missing",
}

export type Handlers = {
  [Events.activeItemChange]: (item: TravelHeatmapTypes.H3IndexFeature[]) => void;
  [Events.hoverItemChange]: (item: TravelHeatmapTypes.H3IndexFeature | null) => void;
  [Events.missingSectorInfo]: (h3Index: string, coordinates: number[] | [number, number]) => void;
};
