import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { TrafficIndexIcon } from "components";
import { IDZPrediction } from "./components/idz-prediction";
import { trafficFetchScoreAlternative } from "old-store/traffic/action-creators";
import { TrafficData, TrafficPeriod } from "api/traffic/model/traffic";
import "./ctrl-idz.scss";
import { GState } from "../../documentations";

const IDZComponent = (props: any) => {
  const { alternative } = props;
  const { es, from, to, routeTimeType, type, routeIsActive, trafficFetchScoreAlternative } = props;

  const fetchAlternative = useCallback(() => {
    if (routeIsActive && routeTimeType === "last") {
      const input = { es, period: TrafficPeriod.Last, data: TrafficData.Historical };
      trafficFetchScoreAlternative(input);
    } else if (!routeIsActive && type === "last") {
      const input = { es, period: TrafficPeriod.Last, data: TrafficData.Historical };
      trafficFetchScoreAlternative(input);
    } else if (from && to) {
      if (moment().valueOf() < moment(from).valueOf()) {
        const input = { es, data: TrafficData.Forecast, from, to };
        trafficFetchScoreAlternative(input);
      } else {
        const input = { es, data: TrafficData.Historical, from, to };
        trafficFetchScoreAlternative(input);
      }
    }
  }, [es, from, to, routeTimeType, type, routeIsActive, trafficFetchScoreAlternative]);

  useEffect(() => {
    fetchAlternative();
  }, [fetchAlternative, es, from, to, routeTimeType, type, routeIsActive, trafficFetchScoreAlternative]);

  const { score, dynamicsMessage, dynamicsId } = useSelector((state: GState) => state.roadDetector.detectorIDZ);
  const isRoadDetectorMode = useSelector((state: GState) => state.roadDetector.isRoadDetectorMode);

  return (
    <div className="ctrl-idz">
      <TrafficIndexIcon score={isRoadDetectorMode ? score : alternative?.score} />
      <IDZPrediction
        dynamicsID={isRoadDetectorMode ? dynamicsId : alternative?.dynamicsID}
        dynamicsMessage={isRoadDetectorMode ? dynamicsMessage : alternative?.dynamicsMessage}
      />
    </div>
  );
};

export const CtrlIDZ = connect(
  (state: any) => {
    return {
      alternative: state.traffic.alternative,
      es: state.view.es,
      from: state.view.from,
      to: state.view.to,
      routeTimeType: state.router.timeStatus,
      type: state.traffic.type,
      routeIsActive: state.router.isActive,
    };
  },
  (dispatch) => ({
    trafficFetchScoreAlternative: (data: any) => dispatch(trafficFetchScoreAlternative(data) as any),
  }),
  null,
  { pure: true }
)(IDZComponent);
