import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "components";
import { GState } from "documentations";
import * as ACTIONS from "features/ctrl-route/store/slice";

import "./index.scss";

const className = "route-header";

export const RouteHeader: React.FC = () => {
  const dispatch = useDispatch();

  const { isManeuversShow } = useSelector((state: GState) => ({
    isManeuversShow: state.router.isManeuversShow,
  }));

  const handleClickCloseButton = () => {
    dispatch(ACTIONS.setIsActive(false));
  };

  const handleClickArrowButton = () => {
    dispatch(ACTIONS.setIsShowManeuvers(false));
  };

  if (isManeuversShow)
    return (
      <div className={className}>
        <button
          className={`${className}-button-title ${className}-button`}
          type="button"
          onClick={handleClickArrowButton}>
          <i className="dtm-back-arrow-icon " />
          Назад
        </button>
        <CloseButton onClick={handleClickCloseButton} />
      </div>
    );

  return (
    <div className={className}>
      <span className={`${className}-title`}>Маршрут</span>
      <CloseButton onClick={handleClickCloseButton} />
    </div>
  );
};
