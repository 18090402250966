export default class IconsProperties {
  getIconSize() {
    return {
      base: 1,
      stops: [
        [8, 0.1],
        [22, 1.5],
      ],
    };
  }

  getBigIconSize() {
    return {
      base: 1,
      stops: [
        [8, 0.2],
        [22, 1.5],
      ],
    };
  }
}
