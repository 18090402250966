import React, { useCallback } from "react";
import { APP_ENV } from "app-env";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import * as ACTIONS from "features/ctrl-route/store/slice";
import { toggleIsActive } from "old-store/isochrone/isochrone-slice";
import { containerClassName } from "../..";
import {
  RouteCompareWithCtrl,
  RouteIsochroneCtrl,
  RouteMetroCtrl,
  RouteSimulatedDtp,
  RouteTrafficCtrl,
} from "./components";

const useIsochroneState = () =>
  useSelector(
    (state: GState) => ({
      checked: state.isochrone.isActive,
      isFetching: state.isochrone.isFetching,
      isDisabled: state.router.isDisabled || state.isochrone.isDisabled,
    }),
    shallowEqual
  );

const useIsDisabled = () =>
  useSelector((state: GState) => state.router.isDisabled || state.router.useTrafficLayersIsDisabled);
const useIsTrafficLayers = () => useSelector((state: GState) => state.router.useTrafficLayers);
const useCosting = () => useSelector((state: GState) => state.router.costing, shallowEqual);
const useIsSimulatedDtp = () => useSelector((state: GState) => state.router.isSimulatedDtp);
const useIsSimulatedDtpDisabled = () =>
  useSelector((state: GState) => !state.router.routeVariants || state.router.isCompareWithYandex);
const useIsCompareWithYandex = () => useSelector((state: GState) => state.router.isCompareWithYandex);
const useIsRouteWithMetro = () => useSelector((state: GState) => state.router.isRouteWithMetro);
const useIsDisabledYandex = () => useSelector((state: GState) => state.router.routeIsLoading);

export const RouteOptions = () => {
  const { checked, isFetching, isDisabled } = useIsochroneState();
  const isRouteTrafficDisabled = useIsDisabled();
  const costing = useCosting();
  const isSimulatedDtp = useIsSimulatedDtp();
  const isCompareWithYandex = useIsCompareWithYandex();
  const isSimulatedDtpDisabled = useIsSimulatedDtpDisabled();
  const isTrafficLayers = useIsTrafficLayers();
  const isRouteWithMetro = useIsRouteWithMetro();
  const isDisabledYandex = useIsDisabledYandex();
  const dispatch = useDispatch();

  const handleIsochroneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) return;
      dispatch(toggleIsActive(e?.target.checked));
    },
    [isDisabled, dispatch]
  );

  const handleRouteTrafficChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isRouteTrafficDisabled) return;
      dispatch(ACTIONS.setUseTrafficLayers(e.target.checked));
    },
    [isRouteTrafficDisabled, dispatch]
  );

  const handleIsSimulatedDtpChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(ACTIONS.setIsSimulatedDtp(e.target.checked));
    },
    [dispatch]
  );

  const handleIsCompareWithYandexChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(ACTIONS.setIsCompareWithYandex(e.target.checked));
    },
    [dispatch]
  );

  const handleIsRouteWithMetroChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(ACTIONS.setIsRouteWithMetro(e.target.checked));
    },
    [dispatch]
  );
  const isMsc = APP_ENV.REACT_APP_DTM_REGION?.toLowerCase() === "Москва".toLowerCase();

  return (
    <div className={`${containerClassName}-controls`}>
      {isMsc && (
        <RouteCompareWithCtrl
          isChecked={isCompareWithYandex}
          onChange={handleIsCompareWithYandexChange}
          isDisabled={isDisabledYandex}
        />
      )}
      <RouteIsochroneCtrl
        checked={checked}
        isLoading={isFetching}
        isDisabled={isDisabled}
        onChange={handleIsochroneChange}
      />
      <RouteTrafficCtrl
        checked={isTrafficLayers}
        isDisabled={isRouteTrafficDisabled || isCompareWithYandex}
        onChange={handleRouteTrafficChange}
      />
      {costing === "Multimodal" && isMsc && (
        <RouteMetroCtrl isChecked={isRouteWithMetro} onChange={handleIsRouteWithMetroChange} />
      )}
      {costing === "Auto" && (
        <RouteSimulatedDtp
          isChecked={isSimulatedDtp}
          isDisabled={isSimulatedDtpDisabled}
          onChange={handleIsSimulatedDtpChange}
        />
      )}
    </div>
  );
};
