import { CachedCell } from "./cells-cache";

export enum Events {
  cellHover = "cell.hover",
  cursorLeave = "cursor.leave",
}

export type Handlers = {
  [Events.cellHover]: (point: { x: number; y: number }, cell: CachedCell) => void;
  [Events.cursorLeave]: () => void;
};
