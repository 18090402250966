import React, { memo } from "react";
import { AverageCheckboxes, TrafficScoreCheckboxes, UnitsCountCheckboxes } from "./components";

import "./graph-settings.scss";

const baseClass = "graph-settings";
const headingClass = `${baseClass}__heading`;
const checkboxesClass = `${baseClass}__checkboxes`;

type Props = {
  isUnitsCountDiagram: boolean;
};

export const GraphSettings = memo<Props>(({ isUnitsCountDiagram }) => {
  return (
    <div className={baseClass}>
      <h3 className={headingClass}>Параметры на графике зависимости от времени суток</h3>
      <div className={checkboxesClass}>
        {!isUnitsCountDiagram && (
          <>
            <AverageCheckboxes key="average-checkboxes" />
            <hr key="average-checkboxes-hr" />
            <TrafficScoreCheckboxes key="traffic-score-checkboxes" />
            {/* <hr />
              <TrafficIndexCheckboxes /> */}
          </>
        )}
        {isUnitsCountDiagram && <UnitsCountCheckboxes />}
      </div>
    </div>
  );
});
