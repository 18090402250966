import { memo, useMemo } from "react";
// @ts-ignore
import prettyNum from "pretty-num";
import { useStoreContext } from "store";
import { Title } from "../title";
import { Value } from "../value";
import "./statistics.scss";

const baseClass = "zone-statistics-card-statistics";
const statisticsRowClass = `${baseClass}-row`;

export const Statistics = memo(() => {
  const { store } = useStoreContext();
  const statistics = store.zoneStatistics.hooks.useStatistics();
  const averageSpeed = useMemo(
    () => prettyNum(statistics?.avgSpeed ?? 0, { precision: 2, decimalSeparator: "," }),
    [statistics?.avgSpeed]
  );
  const unitsCount = useMemo(
    () => prettyNum(statistics?.unitsCount ?? 0, { thousandsSeparator: " " }),
    [statistics?.unitsCount]
  );

  return (
    <div className={baseClass}>
      <div className={statisticsRowClass}>
        <Title>Средняя скорость</Title>
        <Value>{averageSpeed} км/час</Value>
      </div>
      <div className={statisticsRowClass}>
        <Title>Количество ТС</Title>
        <Value>{unitsCount}</Value>
      </div>
    </div>
  );
});
