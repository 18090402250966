import React, { memo, useMemo } from "react";
import cn from "classnames";
import { CloseButton } from "components";

import "./tooltip.scss";

type TooltipProps = {
  label: string;
  isCloseIcon?: boolean;
  onClose?: () => void;
};

const baseClass = "app-tooltip";

export const Tooltip = memo(({ label, isCloseIcon = true, onClose }: TooltipProps) => {
  const containerClass = useMemo(() => cn(baseClass, { [`${baseClass}_centered`]: !isCloseIcon }), [isCloseIcon]);

  return (
    <div className={containerClass}>
      <label className="app-tooltip__label">{label}</label>
      {isCloseIcon && <CloseButton className="app-tooltip__btn" onClick={onClose} />}
    </div>
  );
});
