import { CSSProperties } from "react";

const handleStyle: CSSProperties = {
  width: "20px",
  height: "20px",
  border: "4px solid #ffffff",
  boxShadow: "0 0 6px rgba(0, 0, 0, 0.161)",
  backgroundColor: "#000000",
};

export const baseClass = "segments-units-count-slider";
export const labelClass = `${baseClass}__label`;
export const labelFontClass = `${labelClass}-font`;
export const rangeStep = 1;
export const intersectionDelta = 10;
export const trackStyle = [{ backgroundColor: "#F0F0F0" }];
export const handlesStyle = [handleStyle, handleStyle, handleStyle, handleStyle];
