import mapboxgl from "mapbox-gl";
import { CORRELATION_FORECAST_ID } from "../order-layers";
import Popup from "./popup";

const options = {
  layerId: CORRELATION_FORECAST_ID,
};
export default class CorrelationPopup extends Popup {
  constructor(map) {
    super(map, options);
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "dtm-popup",
      maxWidth: "450px",
    });
  }

  getPopupHTML(props) {
    const { historicalSpeed, forecastSpeed } = props;
    let message = "";
    const difference = Math.abs(historicalSpeed - forecastSpeed).toFixed(1);
    const ratio = Math.abs((1 - historicalSpeed / forecastSpeed) * 100).toFixed(1);

    if (ratio < 5 || difference < 1) message = "Фактическая средняя скорость близка к обычной для этого времени и дня";
    else if (historicalSpeed - forecastSpeed > 0)
      message = `Фактическая средняя скорость на <strong> ${difference} </strong> км/ч (<strong>${ratio}</strong>%) больше обычной для этого времени и дня`;
    else if (historicalSpeed - forecastSpeed < 0)
      message = `Фактическая средняя скорость на <strong> ${difference} </strong> км/ч (<strong>${ratio}</strong>%) меньше обычной для этого времени и дня`;

    const popupHTML = `
    <p><strong>${historicalSpeed.toFixed(1)} </strong> км/ч фактическая средняя скорость</p>
    <p><strong>${forecastSpeed.toFixed(1)} </strong> км/ч обычная средняя скорость для этого времени и дня</p>
    <hr />
    <p>${message}</p>
    `;
    return popupHTML;
  }

  show = (lngLat, props) => {
    this.popup.setLngLat(lngLat).setHTML(this.getPopupHTML(props)).addTo(this.map);
  };

  hide = () => {
    this.popup.remove();
  };
}
