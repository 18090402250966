import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { loadDetectorIDZ, loadDetectorScore, loadLastDetectorData, roadDetectorSlice } from "../../store/slice";
import { loadVCParamSelector } from "../../store/selectors";
import { DetectorButton } from "assets";
import { trafficModeToggleAC, trafficUpdateActiveAC } from "old-store/traffic/action-creators";
import moment from "moment";

export const RoadDetectorButton = (props: any) => {
  const dispatch = useDispatch();

  const {
    isRoadDetectorMode,
    isTrafficActive,
    type,
    to: storeTo,
    from: storeFrom,
  } = useSelector((state: GState) => ({
    isRoadDetectorMode: state.roadDetector.isRoadDetectorMode,
    isTrafficActive: state.traffic.isActive,
    type: state.traffic.type,
    from: state.view.from,
    to: state.view.to,
  }));

  const vc = useSelector(loadVCParamSelector);

  useEffect(() => {
    if (isRoadDetectorMode) {
      let params: Parameters<typeof loadDetectorScore>[0] = {};
      if (vc) params.vc = vc;

      if (type === "last") {
        params = { ...params, period: "last", data: "historical" };
        dispatch(loadDetectorScore(params));
        dispatch(loadDetectorIDZ(params));
      } else {
        const [from, to] = [storeFrom, storeTo].map((x) => moment(x).format("YYYY-MM-DDTHH:mm"));
        params = { ...params, data: type, from, to };
        dispatch(loadDetectorScore(params));
        dispatch(loadDetectorIDZ(params));
      }
    }
  }, [isRoadDetectorMode, type, vc, storeFrom, storeTo]);

  useEffect(() => {
    dispatch(loadLastDetectorData());
  }, [dispatch]);

  const handleClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      if (!isRoadDetectorMode) {
        dispatch(roadDetectorSlice.actions.toggleIsRoadDetectorMode());
        if (isTrafficActive) {
          dispatch(roadDetectorSlice.actions.setIsActive(true));
          dispatch(trafficUpdateActiveAC(false));
        }
        dispatch(trafficModeToggleAC());
      }
    },
    [dispatch, isRoadDetectorMode, isTrafficActive]
  );

  return (
    <DetectorButton
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      color={isRoadDetectorMode ? "#7CBF3A" : "#747474"}
      onClick={handleClick}
    />
  );
};
