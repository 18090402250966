import React from "react";
import { TooltipValue, TooltipValueAlignItem } from "./features";
import * as consts from "./travel-heatmap-legend.consts";
import { useData } from "./travel-heatmap-legend.hooks.controller";
import { useToolTip } from "./travel-heatmap-legend.hooks.tooltip";
import "./travel-heatmap-legend.scss";

export const TravelHeatmapLegend = React.memo(() => {
  const { minValue, maxValue } = useToolTip();
  const data = useData();
  const { containerRef, isActive, maxTrips, minColorKey, maxColorKey, minTranslation, maxTranslation } = data;
  const { handleMinMouseDown, handleMaxMousedown } = data;

  if (!isActive || maxTrips === 0) return null;

  return (
    <div className={consts.baseClass}>
      <span>0</span>
      <div ref={containerRef} className={consts.colorsClass}>
        {consts.colors.map((color, index) => {
          return <div key={index} className={`${consts.colorizedBlockClass} ${consts.colorizedBlockClass}-${color}`} />;
        })}
        <div
          className={`${consts.handleClass} ${consts.colorizedBlockClass}-${minColorKey}`}
          style={{ transform: `translateX(${minTranslation}px)` }}
          onMouseDown={handleMinMouseDown}
        />
        <TooltipValue alignItem={TooltipValueAlignItem.Min} caption={minValue} shiftInPx={minTranslation} />
        <div
          className={`${consts.handleClass} ${consts.colorizedBlockClass}-${maxColorKey}`}
          style={{ transform: `translateX(${maxTranslation}px)` }}
          onMouseDown={handleMaxMousedown}
        />
        <TooltipValue alignItem={TooltipValueAlignItem.Max} caption={maxValue} shiftInPx={maxTranslation} />
      </div>
      <span>{maxTrips}</span>
    </div>
  );
});
