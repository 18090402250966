const getDiffBetweenTwoNumbers = (numberArr: Array<number | undefined>) => {
  const [one, two] = numberArr as number[];
  if (numberArr.includes(0)) {
    return 0;
  }
  return (((two - one) / one) * 100).toFixed(2);
};

const getParsDiff = (diff: number | null) => (diff === 0 ? null : diff && diff < 0 ? `${diff}%` : `+${diff}%`);

export const helpers = {
  getDiffBetweenTwoNumbers,
  getParsDiff,
};
