import { IStore } from "../store.types";
import { store } from "./zone-statistics";
import { Events, events } from "./zone-statistics.events";
import { Hooks, hooks } from "./zone-statistics.hooks";
import { Effects, effects } from "./zone-statistics.effects";
import { State } from "./zone-statistics.types";

export class Store implements IStore<State, Events, Hooks, Effects> {
  public readonly store = store;
  public readonly events = events;
  public readonly hooks = hooks;
  public readonly effects = effects;

  public readonly destroy = () => {
    console.info("destroy");
  };
}

export const zoneStatisticsStore = new Store();
