import * as consts from "./consts";

type SetDPI = {
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
};

type DrawRectangle = {
  x: number;
  y: number;
  w: number;
  h: number;
  color: string;
  alpha?: number;
};

type DrawLineRectangle = Omit<DrawRectangle, "x" | "y" | "w" | "h"> & {
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  stroke?: string;
};

export const getXY = (canvas: HTMLCanvasElement, e: MouseEvent) => {
  const rect = canvas.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  return { x, y };
};

export const getRetinaRatio = (ctx: CanvasRenderingContext2D) => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const backingStoreRatio = [
    //@ts-ignore
    ctx.webkitBackingStorePixelRatio,
    //@ts-ignore
    ctx.mozBackingStorePixelRatio,
    //@ts-ignore
    ctx.msBackingStorePixelRatio,
    //@ts-ignore
    ctx.oBackingStorePixelRatio,
    //@ts-ignore
    ctx.backingStorePixelRatio,
    1,
  ].reduce((a, b) => {
    return a || b;
  });

  return devicePixelRatio / backingStoreRatio;
};

export const setDPI = ({ canvas, width, height }: SetDPI) => {
  const ctx = canvas.getContext("2d");
  if (ctx) {
    const ratio = getRetinaRatio(ctx);
    const scaledWidth = width * ratio;
    const scaledHeight = height * ratio;
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
    ctx.scale(ratio, ratio);
  }
  return ctx!;
};

export const clipByBodyWidth = (ctx: CanvasRenderingContext2D | undefined) => {
  const region = new Path2D();
  region.rect(consts.bodyLeft, 0, consts.bodyWidth, 525);
  ctx?.clip(region);
};

export const drawRectangle = (
  ctx: CanvasRenderingContext2D | undefined,
  { x, y, w, h, color, alpha }: DrawRectangle
) => {
  if (!ctx) return;

  ctx.save();
  if (alpha) ctx.globalAlpha = alpha;
  clipByBodyWidth(ctx);
  ctx.fillStyle = color;
  ctx.fillRect(x, y, w, h);
  ctx.globalAlpha = 1;
  ctx.restore();
};

export const drawLineRect = (
  ctx: CanvasRenderingContext2D | undefined,
  { coordinates, color, alpha, stroke }: DrawLineRectangle
) => {
  if (!ctx) return;

  const { x1, x2, y1, y2 } = coordinates;

  ctx.save();
  if (alpha) ctx.globalAlpha = alpha;
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y1);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x1, y2);
  ctx.lineTo(x1, y1);
  ctx.fill();
  if (stroke) {
    ctx.globalAlpha = 1;
    ctx.strokeStyle = stroke;
    ctx.lineWidth = 0.5;
    ctx.stroke();
  }
  ctx.closePath();
};

export const setSettings = (canvas: HTMLCanvasElement, canvasHeight: number) => {
  canvas.width = consts.canvasWidth;
  canvas.height = canvasHeight;
  canvas.style.height = `${524}px`;
  canvas.style.position = "absolute";
  canvas.style.top = "0px";
  canvas.style.left = "0px";
};
