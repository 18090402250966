import { AnalysisDiagramData } from "api/router/model/analysisResponse";
import { LimitsKeys, UnitsCountDiagramParams, UnitsCountDiagramParamsLimits } from "features/sector-analysis/types";

export const unitsCountColorMultiplicators = [1, 2, 3, 4];
/** Units count diagram colors */
export const unitsCountColor = {
  [-1]: "#c7c7c7",
  0: "#FFD4DC",
  1: "#FF7F97",
  2: "#D889FF",
  3: "#B051E0",
  4: "#8338A9",
};

/**
 * Method allows to calculate maximum value of units count
 * @param {AnalysisDiagramData | null} data diagram data
 * @returns {number} max value of units count
 */
export const calculateUnitsCountMax = (data: AnalysisDiagramData | null) => {
  return data?.rows.reduce((acc, row) => {
    const rowUnitCounts = Object.values(row.cells ?? [0]).map(({ unitsCount }) => unitsCount);
    return Math.max(acc, ...rowUnitCounts);
  }, 0);
};

export const limitsFactory = (colorPrice: number) =>
  unitsCountColorMultiplicators.reduce((acc, key) => {
    return { ...acc, [key]: key * colorPrice };
  }, {} as UnitsCountDiagramParamsLimits);

/**
 * Method allows to calculate units count diagram params
 * @param {AnalysisDiagramData | null} data
 * @returns {UnitsCountDiagramParams}s
 */
export const calculateUnitsCountDiagramParams = (data: AnalysisDiagramData | null): UnitsCountDiagramParams => {
  const max = isNaN(Number(calculateUnitsCountMax(data)))
    ? data?.maxUnitsPerCell ?? 0
    : calculateUnitsCountMax(data) ?? 0;
  const colorPrice = Math.round(max / (Object.values(unitsCountColor).length - 1));
  return {
    max,
    colorPrice,
    limits: limitsFactory(colorPrice),
  };
};

/**
 * Method allows to get color for units count value with deltas[]
 * @param {number} colorPrice
 * @param {number} unitsCount
 * @returns {string} color
 */
export const getColoByUnitsCount = (
  params: UnitsCountDiagramParams | null,
  unitsCount: number | null,
  debug = false
) => {
  if (!params || typeof unitsCount !== "number" || unitsCount < 0) return unitsCountColor["-1"];

  const { max, limits } = params;

  if (unitsCount >= max) return unitsCountColor[4];

  const limitsArr = [0, ...Object.values(limits), max];
  const colorKey = limitsArr.reduce((acc, limit, index, list) => {
    let minLimit = limit;
    let maxLimit = max;

    if (index === list.length - 1) return acc;

    maxLimit = list[index + 1];

    if (unitsCount >= minLimit && unitsCount < maxLimit) return index;

    return acc;
  }, 1);

  const color = unitsCountColor[colorKey as LimitsKeys];

  if (!color) return unitsCountColor["-1"];

  return color;
};
