import React from "react";
import "./route-difference-time.scss";
import { formatTimeDuration } from "utils/format-time-duration";

export function roundUp(number, precision) {
  return Math.floor(number / precision) * precision;
}

function getTime(differenceTime) {
  const seconds = roundUp(differenceTime, 60);
  // const seconds = differenceTime;
  if (differenceTime >= 3600) {
    const time = formatTimeDuration(Math.abs(seconds));
    return `-${time}`;
  }
  if (differenceTime > 0) {
    const time = formatTimeDuration(Math.abs(seconds));
    return `-${time}`;
  }
  if (differenceTime === 0) {
    return "То же время";
  }
  if (differenceTime < 0 && differenceTime > -3600) {
    const time = formatTimeDuration(Math.abs(seconds));
    return `+${time}`;
  }

  if (differenceTime <= -3600) {
    const time = formatTimeDuration(Math.abs(seconds));
    return `+${time}`;
  }
}

const className = "route-difference-time";
const RouteDifferenceTime = ({ differenceTime }) => {
  const time = getTime(differenceTime);
  return (
    <div className={`${className}  ${differenceTime > 0 ? `${className}-more` : `${className}-less`}`}>{time}</div>
  );
};

export default RouteDifferenceTime;
