import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../documentations";
import { ForecastFetch, WeatherForecast, WeatherSelectOption, WeatherState } from "./types";
import { createFeatures } from "./utils/processing-map-features";
import { createWeatherOptions } from "./utils/create-weather-options";

export const initialState: WeatherState = {
  isActive: false,
  isFetching: false,
  data: {
    type: "FeatureCollection",
    features: createFeatures(),
  },
  dataIndex: 0,
  error: null,
  forecast: {
    fact: null,
    hour3: null,
    hour36: null,
    day5: null,
  },
  timeWeatherOptions: createWeatherOptions(),
  selectedOption: {
    label: "до 3 часов",
    value: 0,
  },
  detailedIsOpen: false,
};

export const weatherSlice = createSlice({
  name: "weather",
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    endFetching(state) {
      state.isFetching = false;
    },
    fetchingFailed(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    toggleIsActive(state, { payload }: PayloadAction<boolean>) {
      state.isActive = payload;
    },
    setData(state, { payload }: PayloadAction<GeoJSON.FeatureCollection>) {
      state.data = payload;
    },
    updateDataIndex(state) {
      state.dataIndex += 1;
    },
    setForecast(state, { payload }: PayloadAction<WeatherForecast>) {
      state.forecast[payload.type] = payload.forecast;
    },
    setdetailedIsOpen(state, { payload }: PayloadAction<boolean>) {
      state.detailedIsOpen = payload;
    },
    setSelectedOption(state, { payload }: PayloadAction<WeatherSelectOption>) {
      state.selectedOption = payload;
    },
  },
});

export const {
  startFetching,
  fetchingFailed,
  toggleIsActive,
  setData,
  endFetching,
  updateDataIndex,
  setForecast,
  setdetailedIsOpen,
  setSelectedOption,
} = weatherSlice.actions;

export const weatherFetchForecast =
  ({ to }: ForecastFetch): AppThunk =>
  async (dispatch: Dispatch, getState) => {
    // try {
    //     const now = moment().valueOf();
    //     const calcTo = moment(to).valueOf();
    //     let dateTo = to;
    //     /** Для будущего времени используем последний прогноз на текущий момент */
    //     if (now <= calcTo) dateTo = moment().toISOString();
    //     const state = getState();
    //     const previousData = state.weather.data;
    //     dispatch(startFetching());
    //     /**
    //      * Запрос фактической погоды на выбранное время
    //      */
    //     const timeFactFrom = moment(dateTo).subtract(7, 'hour').toISOString();
    //     const factWeather = await WeatherAPI.getForecast(6, timeFactFrom, to);
    //     dispatch(setForecast({ type: 'fact', forecast: factWeather }));
    //     /**
    //      * Запрос прогноза погоды на 3х часовой период
    //      */
    //     const time3HFrom = moment(dateTo).subtract(7, 'hour').toISOString();
    //     const forecastWeather3H = await WeatherAPI.getForecast(4, time3HFrom, to);
    //     /** В прогнозах нет разделения на округа, поэтому фильтруем по Москве */
    //     const filteredForecast3H = forecastWeather3H.filter((el) => el.spatialTypeId === 3);
    //     dispatch(setForecast({ type: 'hour3', forecast: filteredForecast3H }));
    //     /**
    //      * Запрос прогноза погоды на 36 часов
    //      */
    //     const time36HFrom = moment(dateTo).subtract(30, 'hour').toISOString();
    //     const forecastWeather36H = await WeatherAPI.getForecast(1, time36HFrom, to);
    //     dispatch(setForecast({ type: 'hour36', forecast: forecastWeather36H }));
    //     /**
    //      * Запрос прогноза погоды на 5 дней
    //      */
    //     const time5DTo = moment(dateTo).subtract(1, 'day').toISOString();
    //     const time5DFrom = moment(dateTo).subtract(8, 'day').toISOString();
    //     const forecastWeather5D = await WeatherAPI.getForecast(5, time5DFrom, time5DTo);
    //     /** В прогнозах нет разделения на округа, поэтому фильтруем по Москве */
    //     const filteredForecast5D = forecastWeather5D.filter((el) => el.spatialTypeId === 3);
    //     dispatch(setForecast({ type: 'day5', forecast: filteredForecast5D }));
    //     /** Обработка данных отображаемых на карте */
    //     let data = prepareFactData(factWeather, previousData);
    //     if (now < calcTo) {
    //         const range3Hours = moment().add(3, 'hour').valueOf();
    //         const range36Hours = moment().add(36, 'hour').valueOf();
    //         const range5days = moment().add(5, 'day').valueOf();
    //         if (calcTo <= range3Hours) {
    //             const forecast = forecastWeather3H.find((el) => checkRangeDate(el.fromDate, el.toDate, to));
    //             data = prepareForecastData(forecast, previousData);
    //         } else if (calcTo >= range3Hours && calcTo <= range36Hours) {
    //             const forecast = forecastWeather36H.find((el) => checkRangeDate(el.fromDate, el.toDate, to));
    //             data = prepareForecastData(forecast, previousData);
    //         } else if (calcTo >= range36Hours && calcTo <= range5days) {
    //             const forecast = forecastWeather5D.find((el) => checkRangeDate(el.fromDate, el.toDate, to));
    //             data = prepareForecastData(forecast, previousData);
    //         } else dispatch(toggleIsActive(false));
    //     }
    //     dispatch(setData(data));
    //     dispatch(endFetching());
    //     dispatch(updateDataIndex());
    // } catch (err) {
    //     dispatch(endFetching());
    //     dispatch(fetchingFailed(err.toString()));
    // }
  };
