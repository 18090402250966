import * as turf from "@turf/turf";
import { decodeShape } from "utils/shape-to-geojson";
import { RouteResponse, TravelType } from "../types";

export function getBusRouteGeometry(variant: RouteResponse): GeoJSON.FeatureCollection {
  const features: GeoJSON.Feature[] = [];
  const leg = variant.original.trip.legs[0];
  const coordinates = decodeShape(leg.shape);
  leg.maneuvers.forEach((el) => {
    const { end_shape_index, begin_shape_index, travel_type } = el;
    const color = travel_type === TravelType.Bus ? "blue" : travel_type === TravelType.Metro ? "red" : "gray";
    const coords = coordinates.slice(begin_shape_index, end_shape_index + 1);
    if (coords.length >= 2) {
      const line = turf.lineString(coords, { ...el, color }) as GeoJSON.Feature;
      features.push(line);
    }
  });
  const data: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features,
  };
  return data;
}
