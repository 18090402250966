import React, { memo } from "react";

import "./sector-analysis-appearance-button.scss";

const baseClass = "sector-analysis-appearance-button";
const titleClass = `${baseClass}__title`;

export interface Props {
  title: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const SectorAnalysisAppearanceButton: React.FC<Props> = memo(({ title, disabled, onClick }) => {
  return (
    <button disabled={disabled} className={baseClass} onClick={onClick}>
      <span className={titleClass}>{title}</span>
    </button>
  );
});
