import mapboxgl from "mapbox-gl";
import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { Feature } from "./travel-heatmap.types";

import "./travel-heatmap.popup.scss";
import moment from "moment";

export class TravelHeatmapPopup {
  private popup: mapboxgl.Popup;
  private from: string = "";
  private to: string = "";
  private directionMode: TravelHeatmapTypes.DirectionMode = "from";
  private transportType: TravelHeatmapTypes.TransportType = "taxi";
  private timeout?: number;

  constructor(private map: mapboxgl.Map) {
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "travel-heatmap-popup",
      maxWidth: "500px",
    });

    this.map.on("mouseout", this.handleMouseout);
    document.addEventListener("mouseleave", this.handleMouseout);
  }

  private handleMouseout = () => {
    window.clearTimeout(this.timeout);
    this.remove();
  };

  private getHTML = (properties: Feature["properties"], sectorInfo: TravelHeatmapTypes.SectorInformation) => {
    //  - ID элемента;
    //  - Название (для района или округа);
    //  - Период расчёта
    //  - Кол-во фактов начала/окончания (с учётом выбранного ракурса поездки) поездки по выбранному типу ТС за период;
    //  - Среднее за сутки кол-во фактов начала/окончания (с учётом выбранного ракурса поездки) поездки по выбранному типу ТС за период.

    const direction = this.directionMode === "from" ? "из" : "в";
    const { address, adminAreaString } = sectorInfo;

    return `
      <div class="travel-heatmap-popup__content">
        <div>
        <span class="travel-heatmap-popup__content__key">
            ${shared.travelHeatmap.directionModeMapping[this.directionMode]} поездок на ${
      shared.travelHeatmap.transportTypeMapping[this.transportType]
    } ${direction} «${address}»: <span class="travel-heatmap-popup__content__value">${properties.Value}</span>
        </span>
        </div>
        <div>
        <span class="travel-heatmap-popup__content__key">
          В среднем за день: <span class="travel-heatmap-popup__content__value">${properties.Value}</span>
        </span>
        </div>
        <div>
        <span class="travel-heatmap-popup__content__key">
          За период: <span class="travel-heatmap-popup__content__value">${moment(this.from).format(
            "dd.MM.yyyy"
          )} - ${moment(this.to).format("d.MM.yyyy")}</span>
        </span>
        </div>
        <div>
        <span class="travel-heatmap-popup__content__key">
          Округ: <span class="travel-heatmap-popup__content__value">${adminAreaString}</span>
        </span>
        </div>
      </div>
    `;
  };

  public readonly show = (
    feature: Feature,
    coordinates: mapboxgl.LngLatLike,
    sectorInfo: TravelHeatmapTypes.SectorInformation
  ) => {
    this.popup.setLngLat(coordinates).setHTML(this.getHTML(feature.properties, sectorInfo)).addTo(this.map);
  };

  public readonly remove = () => {
    this.popup.remove();
  };

  public readonly setDirectionMode = (directionMode: TravelHeatmapTypes.DirectionMode) => {
    this.directionMode = directionMode;
  };

  public readonly setTransportType = (transportType: TravelHeatmapTypes.TransportType) => {
    this.transportType = transportType;
  };

  public readonly setFrom = (from: string) => {
    this.from = from;
  };

  public readonly setTo = (to: string) => {
    this.to = to;
  };

  public readonly destroy = () => {
    this.map.off("mouseout", this.handleMouseout);
    document.removeEventListener("mouseleave", this.handleMouseout);
    this.remove();
  };
}
