import React, { useCallback } from "react";
import { APP_ENV } from "app-env";
import { batch, useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { SpeedcamButton, speedcamSlice } from "features/ctrl-speedcam";
import { CameraButton, cameraSlice } from "features/ctrl-camera";
import { DetectorButton, detectorSlice } from "features/ctrl-detector";
import { NavigationGroupeLayers } from "components";
import * as consts from "./equipments-group.consts";
import "./equipments-group.scss";
import { NoneDirectionDetectorButton } from "../../features/ctrl-detector/componets/none-direction-detector-button";

const baseClass = "equipments-group";

export const EquipmentsGroup = () => {
  const dispatch = useDispatch();
  const { speedcamIsActive, cameraIsActive, detectorIsActive, isActiveNoneDirectionDetector } = useSelector(
    (state: GState) => ({
      speedcamIsActive: state.speedcam.isActive,
      cameraIsActive: state.camera.isActive,
      detectorIsActive: state.detector.isActiveDetector,
      isActiveNoneDirectionDetector: state.detector.isActiveNoneDirectionDetector,
    })
  );
  const isActive = speedcamIsActive || cameraIsActive || detectorIsActive || isActiveNoneDirectionDetector;
  const handleGroupClick = useCallback(() => {
    batch(() => {
      dispatch(speedcamSlice.actions.setIsActive(false));
      dispatch(cameraSlice.actions.setIsActive(false));
      dispatch(detectorSlice.actions.setIsActiveDetector(false));
      dispatch(detectorSlice.actions.setIsActiveNoneDirectionDetector(false));
    });
  }, [dispatch]);

  return (
    <div className={baseClass}>
      <NavigationGroupeLayers
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isActive={isActive}
        onClick={handleGroupClick}>
        {APP_ENV.REACT_APP_CONFIGURATION?.layers?.equipments?.speedCameras?.visibility === "on" && <SpeedcamButton />}
        {APP_ENV.REACT_APP_CONFIGURATION?.layers?.equipments?.cameras?.visibility === "on" && <CameraButton />}
        {APP_ENV.REACT_APP_CONFIGURATION?.layers?.equipments?.detectors?.visibility === "on" && <DetectorButton />}
        {APP_ENV.REACT_APP_CONFIGURATION?.layers?.equipments?.detectors?.visibility === "on" && (
          <NoneDirectionDetectorButton />
        )}
      </NavigationGroupeLayers>
    </div>
  );
};
