import * as turf from "@turf/turf";
import { FullPosition, RnisFullPosition } from "api/telemetry/model/models";

export function getBindConnectData(points: any, positions: FullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  for (const [i, position] of positions.entries()) {
    const point = points[i];

    if (point && position) {
      const feature = turf.lineString([
        [position.longitude, position.latitude],
        [point.lon, point.lat],
      ]);

      features.push(feature);
    }
  }

  return features;
}
export function getRnisBindConnectData(points: any, positions: RnisFullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  for (const [i, position] of positions.entries()) {
    const point = points[i];

    if (point && position) {
      const feature = turf.lineString([
        [position.lng, position.lat],
        [point.lon, point.lat],
      ]);

      features.push(feature);
    }
  }

  return features;
}
