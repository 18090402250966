import { memo } from "react";
import { useStoreContext } from "store";
import { NavigationGroupeLayers } from "components";
import * as consts from "./pedestrian-graph.consts";

export const PedestrianGraph = memo(() => {
  const { store } = useStoreContext();
  const isVisible = store.pedestrianGraph.hooks.useIsVisible();
  const isDisabled = store.pedestrianGraph.hooks.useIsDisabled();

  return (
    <div className="ctrl-graph">
      <NavigationGroupeLayers.Item
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isDisabled={isDisabled}
        isActive={isVisible}
        onClick={store.pedestrianGraph.events.handleIsVisibleClick}
      />
    </div>
  );
});
