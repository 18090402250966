import { icons } from "map-helpers/assets/map-svg-icons";
import getBeforeId from "map-helpers/order-layers";

export enum Events {
  click = "click",
  addLayer = "addLayer",
  removeLayer = "removeLayer",
  mouseenter = "mouseenter",
  mouseleave = "mouseleave",
}

type CallbackEvent = (ev: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => void;

export class MapLayer {
  private listeners: Map<string, Array<Function>> = new Map();
  private baseIconSize = 1;
  private baseCircleSize = 16;
  private baseStrokeSize = 12;

  constructor(protected map: mapboxgl.Map, private _layerId: string, private _sourceId: string) {
    this.on("addLayer", this.addListeners);
    this.on("removeLayer", this.removeListeners);
  }

  public remove() {
    this.removeLayer();
    this.removeSource();
    this.dispatch(Events.removeLayer);
  }

  public get layerId() {
    return this._layerId;
  }

  public get sourceId() {
    return this._sourceId;
  }

  public get activeLayerId() {
    return `${this._layerId}-active`;
  }

  public get hoverLayerId() {
    return `${this._layerId}-hover`;
  }

  public on(event: keyof typeof Events, listener: CallbackEvent) {
    const listeners = this.listeners.get(event);
    if (listeners) this.listeners.set(event, [listener, ...listeners]);
    else this.listeners.set(event, [listener]);
  }

  public off(event: keyof typeof Events, listener: CallbackEvent) {
    const listeners = this.listeners.get(event);
    if (!listeners) return;
    this.listeners.set(
      event,
      listeners.filter((_listener) => _listener !== listener)
    );
  }

  public setFilterOnActiveLayer(filter: mapboxgl.Expression) {
    if (this.getActiveLayer()) this.map.setFilter(this.activeLayerId, filter);
  }

  public setFilterOnHoverLayer(filter: mapboxgl.Expression) {
    if (this.getActiveLayer()) this.map.setFilter(this.hoverLayerId, filter);
  }

  protected dispatch(event: Events, ...data: any) {
    const listeners = this.listeners.get(event);
    if (listeners) listeners.forEach((listener) => listener(...data));
  }

  private addListeners = () => {
    this.map.on("click", this.layerId, this.handleClickListener);
    this.map.on("mouseenter", this.layerId, this.handleMouseEnterListener);
    this.map.on("mouseleave", this.layerId, this.handleMouseLeaveListener);
    this.map.on("mouseout", this.handleMouseLeaveListener);
  };

  private removeListeners = () => {
    this.map.off("click", this.layerId, this.handleClickListener);
    this.map.off("mouseenter", this.layerId, this.handleMouseEnterListener);
    this.map.off("mouseleave", this.layerId, this.handleMouseLeaveListener);
    this.map.off("mouseout", this.handleMouseLeaveListener);
  };

  private handleClickListener = (e: mapboxgl.MapMouseEvent) => {
    const features = this.map.queryRenderedFeatures(e.point);
    const feature = features[0];
    if (!feature || feature.layer.id !== this.layerId) return;
    this.dispatch(Events.click, e, features);
  };

  private handleMouseEnterListener = (e: mapboxgl.MapMouseEvent) => {
    const features = this.map.queryRenderedFeatures(e.point);
    this.dispatch(Events.mouseenter, e, features);
  };

  private handleMouseLeaveListener = (e: mapboxgl.MapMouseEvent) => {
    const features = this.map.queryRenderedFeatures(e.point);
    this.dispatch(Events.mouseleave, e, features);
  };

  protected getLayer() {
    return this.map.getLayer(this.layerId);
  }

  private getActiveLayer() {
    return this.map.getLayer(this.activeLayerId);
  }

  private getHoverLayer() {
    return this.map.getLayer(this.hoverLayerId);
  }

  private getSource() {
    return this.map.getSource(this.sourceId);
  }

  private removeSource() {
    if (this.getSource()) this.map.removeSource(this.sourceId);
  }

  private removeLayer() {
    if (this.getLayer()) this.map.removeLayer(this.layerId);

    if (this.getActiveLayer()) this.map.removeLayer(this.activeLayerId);

    if (this.getHoverLayer()) this.map.removeLayer(this.hoverLayerId);
  }

  public static getZoomLinearExpression(base: number | undefined = 1): mapboxgl.Expression {
    return ["interpolate", ["linear"], ["zoom"], 8, base * 0.1, 22, base * 1.5];
  }

  protected getDefaultIconSize(): mapboxgl.Expression {
    return MapLayer.getZoomLinearExpression(this.baseIconSize);
  }

  protected getDefaultCircleRadius(): mapboxgl.Expression {
    return MapLayer.getZoomLinearExpression(this.baseCircleSize);
  }

  protected getDefaultCircleStroke(): mapboxgl.Expression {
    return MapLayer.getZoomLinearExpression(this.baseStrokeSize);
  }

  protected getBeforeId() {
    return getBeforeId(this.layerId, this.map);
  }

  public static loadImage = (map: mapboxgl.Map, key: keyof typeof icons) => {
    const svg = icons[key];
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const src = URL.createObjectURL(blob);
    const newImage = new Image();
    newImage.setAttribute("src", src);
    newImage.onload = () => {
      const checkImage = map.hasImage(key);
      if (!checkImage) map.addImage(key, newImage);
    };
  };
}
