import { memo } from "react";
import { RouteInfo } from "./route-variant-bus.hooks";
import { MetroIcon, BusFrontIcon, SeparatorIcon } from "assets";

const baseClass = "route-variant-bus";

type Props = RouteInfo & { isSeparator: boolean };

export const RouteInfoIcon = memo<Props>((props) => {
  const { routeType, routeNum, routeColor, isSeparator } = props;

  if (routeType === "metro") {
    return (
      <div className={`${baseClass}__bus-route-container`}>
        <div className={`${baseClass}__bus-route`}>
          <MetroIcon fill={routeColor ?? "gray"} width="18" height="18" />
        </div>
        {isSeparator && <SeparatorIcon />}
      </div>
    );
  }

  return (
    <div className={`${baseClass}__bus-route-container`}>
      <div className={`${baseClass}__bus-route`}>
        <BusFrontIcon fill="#000000" opacity="0.5" width="18" height="18" />
        <span className={`${baseClass}__route-number`}>{routeNum}</span>
      </div>
      {isSeparator && <SeparatorIcon />}
    </div>
  );
});
