import mapboxgl from "mapbox-gl";
import { TransportHubsTypes } from "types";
import { BaseLayer } from "../../base-layer";
import { Layers } from "../../layers";

export class Layer extends BaseLayer<GeoJSON.Point, TransportHubsTypes.FeatureProperties> {
  public readonly id = Layers.Identifiers.TRANSPORT_HUBS;

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: Layers.Identifiers.TRANSPORT_HUBS,
    });

    const layerConfig: mapboxgl.SymbolLayer = {
      id: Layers.Identifiers.TRANSPORT_HUBS,
      type: "symbol",
      source: Layers.Identifiers.TRANSPORT_HUBS,
      layout: {
        "icon-image": Layers.Identifiers.TRANSPORT_HUBS_ICON,
        "icon-size": BaseLayer.getZoomLinearExpression(1.2),
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
      },
    };

    this.setLayer(layerConfig);
    this.addSource();
    this.addLayer();
  }
}
