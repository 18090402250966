import { Map } from "mapbox-gl";
import { point as createPointFeature } from "@turf/turf";
import moment from "moment";
import IncidentAPI from "api/incident/incident-api";
import { TemporaryBlockingLayer } from "../map-layer";
import { CancelTokenSource } from "axios";
import { TEMPORARY_BLOCKING_LAYER_ID } from "map-helpers/order-layers";
import { CityEventsPopup } from "map-helpers/popups/city-events-popup";
import { Incident, IncidentInputModel } from "api/incident/incident-api-types";

export class TemporaryBlockingController {
  private readonly map: Map;
  private readonly layer: TemporaryBlockingLayer;
  private incidents: Incident[] = [];
  private cancelToken?: CancelTokenSource = undefined;
  private isActive: boolean = false;
  private timeout?: number;
  private popup: CityEventsPopup;
  private token = "";

  constructor(map: Map, isActive: boolean, token: string) {
    this.map = map;
    this.isActive = isActive;
    this.layer = new TemporaryBlockingLayer(this.map, TEMPORARY_BLOCKING_LAYER_ID);
    this.popup = new CityEventsPopup({
      map: this.map,
      layerId: TEMPORARY_BLOCKING_LAYER_ID,
      iconUrl: "/img/blocking/temporary.svg",
    });
    this.popup.add();
    this.token = token;
  }

  private parseItem = (item: Incident) => {
    return createPointFeature([item.lng, item.lat], item);
  };

  private updateLayer = async (visibility: boolean) => {
    this.layer.setVisibility(visibility);
    this.cancelToken?.cancel();

    if (this.incidents.length === 0) {
      this.incidents = await this.getData();
    }

    this.layer.setData(this.incidents.map(this.parseItem) as GeoJSON.Feature<GeoJSON.Point, Incident>[]);
  };

  private getData = async () => {
    if (this.timeout && typeof this.timeout === "number") window.clearTimeout(this.timeout);

    const queryModel: IncidentInputModel = {
      DateWhenActive: moment().format("YYYY-MM-DD"),
      Type: {
        id: 36,
      },
      Status: {
        id: 10,
      },
      PageSize: -1,
      token: this.token,
    };

    const { request, cancelToken } = await IncidentAPI.getIncidentList<{ items: Incident[] }>(queryModel);
    const { data } = await request;
    this.cancelToken = cancelToken;

    this.timeout = window.setTimeout(async () => {
      this.incidents = await this.getData();
    }, 50000);

    return data?.items || [];
  };

  update = async (isActive: boolean) => {
    if (this.isActive === isActive) return;
    this.isActive = isActive;
    if (!this.map.getLayer(this.layer.layerId)) {
      this.layer.add();
    }
    this.updateLayer(isActive);
  };

  destroy = () => {
    this.layer.destroy();
  };
}
