import { IStore } from "../store.types";
import { store } from "./application-settings";
import { samplesFactory } from "./application-settings.samples";
import { subscribesFactory } from "./application-settings.subscribes";
import { deliveriesFactory } from "./application-settings.delivery";
import { Events, events } from "./application-settings.events";
import { Hooks, hooks } from "./application-settings.hooks";
import { State } from "./application-settings.types";

export class Store implements IStore<State, Events, Hooks> {
  public readonly store = store;
  public readonly events = events;
  public readonly hooks = hooks;

  constructor() {
    samplesFactory();
    subscribesFactory();
    deliveriesFactory();
  }

  public readonly destroy = () => {};
}

export const applicationSettingsStore = new Store();
