import getBeforeId, { GRAPH_SOURCE_ID, GRAPH_VERTICES_HIGHLIGHT_ID } from "../order-layers";
import Layer from "./layer";

const layerId = GRAPH_VERTICES_HIGHLIGHT_ID;

export default class GraphVerticesHighlightLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  addLayer() {
    const layer = {
      "id": layerId,
      "type": "circle",
      "source": GRAPH_SOURCE_ID,
      "source-layer": "nodes",
      "minzoom": 14,
      "paint": {
        "circle-color": "#7dfc4b",
        "circle-opacity": 1,
        "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 14, 4, 18, 7],
      },
      "filter": ["==", "edgeID", "none"],
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }
}
