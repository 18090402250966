import React from "react";
import { AdministrativeDivisionTypes } from "types";
import * as Componets from "components";
import * as hooks from "./popup.layout.hooks";

export const PopupLayout = React.memo<AdministrativeDivisionTypes.Statistic>((props) => {
  const { contactsList } = hooks.useGetContactsList(props);
  const { objectData } = hooks.useGetObjectData(props);
  const { iconsSrc } = hooks.useGetIconSrc(props);
  const { trafficIndex } = hooks.useGetTrafficIndex(props);
  const { date } = hooks.useGetDate();

  return (
    <Componets.DetailsAdministrativeDivision
      administrativeDivisionName={props.fullName}
      administrativeDivisionIcon={iconsSrc}
      date={date}
      trafficScore={props.trafficScore}
      trafficIndex={trafficIndex}
      roadsLength={props.roadsLength}
      contactsList={contactsList}
      objectData={objectData}
      mainHighways={props.roads}
    />
  );
});
