import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import moment from "moment";

type Props = {
  interval: number;
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
    }[];
  };
};

export const StatisticChart: React.FC<Props> = ({ data, interval }) => {
  const options: ChartOptions = {
    animation: { animateRotate: false, animateScale: false },
    legend: {
      display: true,
      position: "top",
      align: "start",

      labels: {
        fontColor: "rgba(3, 40, 76, 0.66)",
        fontSize: 10,
        boxWidth: 10,
        padding: 20,
      },
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItems: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
          const datasetIndex = tooltipItems[0].index;
          const label = String(data.labels?.[datasetIndex || 0]) || "";
          return `${moment("2000 " + label)
            .subtract(interval, "minutes")
            .format("HH:mm")} - ${label}`;
        },

        label: function (tooltipItem, data) {
          const label = "Количество";
          const value = tooltipItem.yLabel;
          return label + ": " + value;
        },
      },
    },
    scales: {
      yAxes: [
        {
          position: "right",
          ticks: {
            callback: function (value, index) {
              return index % 4 === 0 ? value : "";
            },
            stepSize: 100, // Пропустить каждое второе значение
            labelOffset: 10,
            fontSize: 10,
            padding: -15,
          },
          gridLines: {
            tickMarkLength: 37,
          },
        },
      ],

      xAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              if (values.length > 15) {
                return index % 4 === 0 ? value : "";
              }
              return value;
            },
            maxRotation: 0,
            labelOffset: 16,
            fontSize: 10,
            padding: -15,
          },
          gridLines: {
            tickMarkLength: 24,
            borderDash: [5, 5],
          },
        },
      ],
    },
  };
  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};
