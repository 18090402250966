import React from "react";
import { NavigationGroupeLayers } from "components";
import { useStoreContext } from "store";
import * as consts from "./bus-stop-button.consts";

export const BusStopButton = React.memo(() => {
  const { store } = useStoreContext();
  const isActive = store.busStops.hooks.useIsVisible();
  const isDisabled = store.busStops.hooks.useIsDisabled();

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isDisabled={isDisabled}
      isActive={isActive}
      onClick={store.busStops.events.handleIsVisibleClick}
    />
  );
});
