import React from "react";
import { ContactInfo, DataSection, GeneralInfo, HighwaysList } from "./components";
import { Props } from "./body.types";

import "./body.scss";

const bodyContainer = `details-administrative-division__body-container`;

export const Body = (props: Props) => {
  return (
    <div className={bodyContainer}>
      <GeneralInfo
        date={props.date}
        trafficScore={props.trafficScore}
        trafficIndex={props.trafficIndex}
        roadsLength={props.roadsLength}
      />
      <ContactInfo contactsList={props.contactsList} />
      <DataSection
        title={"Данные об объектах"}
        description={"Объекты"}
        data={props.objectData}
        data-cypress={"object-data"}
      />
      {props.eventData && (
        <DataSection
          title={"Данные о событиях"}
          description={"События"}
          data={props.eventData}
          data-cypress={"event-data"}
        />
      )}
      <HighwaysList mainHighways={props.mainHighways} />
    </div>
  );
};
