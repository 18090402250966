import { CSSProperties } from "react";

export const baseClass = "units-count-appearance-slider";
export const labelClass = `${baseClass}__label`;
export const labelFontClass = `${labelClass}-font`;
export const intersectionDelta = 15;
export const handleStyle: CSSProperties = {
  width: "20px",
  height: "20px",
  border: "4px solid #ffffff",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.161)",
};
