import { LngLat } from "mapbox-gl";
import { Forecast } from "../../../api/weather/weather-api-types";
import { WEATHER_MAP_IMAGE_KEY } from "../../../map-helpers/assets/map-svg-icons";

interface DistrictProp {
  LngLat: LngLat;
  id: number;
  external_cod: number;
}
const district = new Map<string, DistrictProp>();

district.set("ВАО", {
  LngLat: new LngLat(55.78771, 37.775631),
  id: 14,
  external_cod: 11,
});

district.set("ЮВАО", {
  LngLat: new LngLat(55.692019, 37.754583),
  id: 6,
  external_cod: 2,
});
district.set("ЮАО", {
  LngLat: new LngLat(55.632695, 37.646807),
  id: 15,
  external_cod: 12,
});
district.set("ЮЗАО", {
  LngLat: new LngLat(55.643531, 37.534622),
  id: 12,
  external_cod: 9,
});
district.set("ЗАО", {
  LngLat: new LngLat(55.71363, 37.457298),
  id: 9,
  external_cod: 6,
});
district.set("СЗАО", {
  LngLat: new LngLat(55.816528, 37.436503),
  id: 11,
  external_cod: 8,
});
district.set("САО", {
  LngLat: new LngLat(55.857627, 37.501196),
  id: 13,
  external_cod: 10,
});
district.set("СВАО", {
  LngLat: new LngLat(55.863894, 37.620923),
  id: 10,
  external_cod: 7,
});
district.set("ЦАО", {
  LngLat: new LngLat(55.752131, 37.617802),
  id: 7,
  external_cod: 4,
});
district.set("ЗелАО", {
  LngLat: new LngLat(55.987583, 37.19425),
  id: 8,
  external_cod: 5,
});

/** Создает начальные данные для отображения слоя */
export function createFeatures(): Array<GeoJSON.Feature> {
  const features: Array<GeoJSON.Feature> = [];
  district.forEach((el, name_district) => {
    features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [el.LngLat.lat, el.LngLat.lng],
      },
      properties: {
        name: name_district,
        ...el,
        icon: WEATHER_MAP_IMAGE_KEY,
      },
    });
  });
  return features;
}

/**
 * Добавляет температуру в свойства слоя в режиме прогноза
 * @param forecast - прогноз
 * @param data - предыдущие значения
 */
export function prepareForecastData(
  forecast: Forecast | undefined,
  data: GeoJSON.FeatureCollection
): GeoJSON.FeatureCollection {
  const newData: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: data.features.map((feature) => {
      const temperature = Math.round(forecast ? forecast.temperatureFrom : 0);
      const icon =
        forecast && forecast.temperatureFrom
          ? `weather-green-dot-d${temperature <= 0 ? temperature : `+${temperature}`}`
          : WEATHER_MAP_IMAGE_KEY;
      return {
        ...feature,
        properties: {
          ...feature.properties,
          icon,
        },
      };
    }),
  };
  return newData;
}

/**
 * Добавляет Фактическую температуру в свойства слоя
 * @param result - Массив данных по округам
 * @param data - предыдущие значения
 */
export function prepareFactData(result: Array<Forecast>, data: GeoJSON.FeatureCollection): GeoJSON.FeatureCollection {
  const newData: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: data.features.map((feature) => {
      const weatherForeCast = result.find(
        ({ spatialTypeId }: { spatialTypeId: number }) =>
          // @ts-ignore
          spatialTypeId === feature.properties.id
      );
      const temperature = Math.round(weatherForeCast ? weatherForeCast.temperatureFrom : 0);

      const icon =
        weatherForeCast && weatherForeCast.temperatureFrom
          ? `weather-green-dot-d${temperature <= 0 ? temperature : `+${temperature}`}`
          : WEATHER_MAP_IMAGE_KEY;

      return {
        ...feature,
        properties: {
          ...feature.properties,
          icon,
        },
      };
    }),
  };
  return newData;
}
