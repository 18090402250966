import { createEvent } from "effector";
import { State } from "./zone-statistics.types";

const setZone = createEvent<State["zone"]>();
const setEsFromRedux = createEvent<State["es"]>();
const handleIsActiveChange = createEvent<State>();
const setDateRange = createEvent<State["dateRange"]>();
const setTimeRange = createEvent<State["timeRange"]>();
const setIsLoading = createEvent<State["isLoading"]>();
const setIsDisabled = createEvent<State["isDisabled"]>();
const handleZoneStatisticsClick = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handleZoneStatisticsCloseClick = createEvent<React.MouseEvent<HTMLButtonElement>>();

export const events = {
  setZone,
  setIsLoading,
  setDateRange,
  setTimeRange,
  setIsDisabled,
  setEsFromRedux,
  handleIsActiveChange,
  handleZoneStatisticsClick,
  handleZoneStatisticsCloseClick,
};

export type Events = typeof events;
