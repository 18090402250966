import React, { memo } from "react";
import cn from "classnames";
import "./toggle-button.scss";

const baseClass = "toggle-button";
const buttonClass = `${baseClass}__button`;

export type ToggleButtonType = {
  id: string;
  icon?: JSX.Element;
  text: string;
};

type Props = {
  value: string;
  isFilling?: boolean;
  buttons: [ToggleButtonType, ToggleButtonType];
  onChange: (id: string) => void;
};

const getButtonClass = (props: Props, button: ToggleButtonType) => {
  const { value, isFilling } = props;
  const { id } = button;
  const isSelected = value === id;
  return cn(buttonClass, {
    [`${buttonClass}_fill`]: isFilling,
    [`${buttonClass}_selected`]: isSelected,
    [`${buttonClass}_selected_fill`]: isSelected && isFilling,
  });
};

export const ToggleButton = memo<Props>((props) => {
  const { buttons, onChange } = props;
  const handleClick = (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChange(id);
  };

  return (
    <div className={baseClass}>
      {buttons.map((button) => {
        const { id, icon, text } = button;
        return (
          <button key={id} onClick={handleClick(id)} className={getButtonClass(props, button)}>
            {icon}
            {text}
          </button>
        );
      })}
    </div>
  );
});
