import React from "react";
import { useStoreContext } from "store";
import * as consts from "./travel-heatmap-legend.consts";
import type { MouseDownMode } from "./travel-heatmap-legend.types";

export const useData = () => {
  const [mouseDownMode, setMouseDownMode] = React.useState<MouseDownMode | null>(null);
  const [minTranslation, setMinTranslation] = React.useState(-10);
  const [maxTranslation, setMaxTranslation] = React.useState(consts.maxTranslationX);
  const minColorKey = React.useMemo(() => {
    const key = Math.ceil((minTranslation + 10) / consts.price);
    if (key <= 0) return "1";
    return key.toString();
  }, [minTranslation]);
  const maxColorKey = React.useMemo(() => {
    const key = Math.ceil((maxTranslation + 10) / consts.price);
    if (!consts.colors.includes(key)) return Math.max(...consts.colors).toString();
    if (key <= 0) return "1";
    return key.toString();
  }, [maxTranslation]);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const { store } = useStoreContext();
  const isActive = store.travelHeatmap.hooks.useIsActive();
  const maxTrips = store.travelHeatmap.hooks.useMaxTrips();

  const handleMinMouseDown = () => setMouseDownMode("min");

  const handleMaxMousedown = () => setMouseDownMode("max");

  const handleMouseMove = React.useCallback(
    (e: any) => {
      if (!isActive || !e.target || !containerRef.current) return;

      const { clientX } = e;
      const rect = containerRef.current.getBoundingClientRect();
      const x = Math.round(clientX - rect.left);

      if (!mouseDownMode) return;
      if (mouseDownMode === "min") {
        return setMinTranslation((prev: number) => {
          const delta = x - prev - 10;
          let newValue = prev + delta;
          if (newValue <= -10 || newValue >= consts.maxTranslationX || newValue >= maxTranslation) return prev;

          const maxTripsByHandle = Math.round((maxTranslation * maxTrips) / consts.maxTranslationX) + 10;
          let minTripsByHandle = Math.round((newValue * maxTrips) / consts.maxTranslationX) + 10;

          if (minTripsByHandle < 1) minTripsByHandle = 0;
          if (newValue < 1) newValue = -10;

          store.travelHeatmap.events.setMinMaxFilter([minTripsByHandle, maxTripsByHandle]);

          return newValue;
        });
      }

      setMaxTranslation((prev: number) => {
        const delta = x - prev - 10;
        let newValue = prev + delta;
        if (newValue <= 0 || newValue <= minTranslation) return prev;
        if (newValue >= consts.maxTranslationX + 10) newValue = consts.maxTranslationX;

        let maxTripsByHandle = Math.round((newValue * maxTrips) / consts.maxTranslationX) + 10;
        const minTripsByHandle = Math.round(((minTranslation + 10) * maxTrips) / consts.maxTranslationX);

        if (maxTripsByHandle > maxTrips) maxTripsByHandle = maxTrips;

        store.travelHeatmap.events.setMinMaxFilter([minTripsByHandle, maxTripsByHandle]);

        return newValue;
      });
    },
    [mouseDownMode, minTranslation, maxTranslation, maxTrips, store.travelHeatmap.events]
  );

  const handleMouseup = React.useCallback(() => {
    if (!isActive || !mouseDownMode) return;
    setMouseDownMode(null);
  }, [mouseDownMode]);

  React.useEffect(() => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseup);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseup);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseup);
    };
  }, [mouseDownMode]);

  return {
    containerRef,
    isActive,
    maxTrips,
    minColorKey,
    maxColorKey,
    minTranslation,
    maxTranslation: maxTranslation > consts.maxTranslationX ? consts.maxTranslationX : maxTranslation,
    handleMinMouseDown,
    handleMaxMousedown,
  };
};
