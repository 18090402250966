import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAlternativeScoreParamsSplit, getCityFlowParamsSplit } from "api/detector-data/detector-data";
import { FlowParamsType, HistoryTravelState } from "../types";
import moment from "moment";

export const initialState: HistoryTravelState = {
  detectorRoadFlowParams: [],
  detectorScores: [],
  trafficFlowParams: [],
};

const NAME = "history-travel-data";

export const historyTravelSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setDetectorScores(state, action: PayloadAction<Pick<FlowParamsType, "scoreMSG" | "score" | "time">[]>) {
      state.detectorScores = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCityFlowParams.pending, (state) => {
      state.detectorRoadFlowParams = [];
    });
    builder.addCase(loadCityFlowParams.fulfilled, (state, action) => {
      state.detectorRoadFlowParams = action.payload;
      state.detectorScores.forEach((item) => {
        state.detectorRoadFlowParams = state.detectorRoadFlowParams.map((x) => {
          if (x.time === item.time) {
            return { ...x, score: item.score, scoreMSG: item.scoreMSG };
          }
          return x;
        });
      });
    });
    builder.addCase(loadAlternativeScoreParams.fulfilled, (state, action) => {
      state.detectorRoadFlowParams = state.detectorRoadFlowParams?.map((item, index) => {
        const { score, time, dynamicsMessage } = action.payload[index];
        if (item.time === time) {
          return { ...item, alternativeScore: score, alternativeScoreMSG: dynamicsMessage };
        }
        return item;
      });
    });
  },
});

const getFromTo = (selectedTime: string, intervalMinutes = 60) => {
  const [from, to] = [
    moment(selectedTime).subtract(intervalMinutes * 2, "minutes"),
    moment(selectedTime).add(intervalMinutes * 2, "minutes"),
  ]?.map((m) => m.format("YYYY-MM-DDTHH:mm:ss"));
  return [from, to];
};

export const loadCityFlowParams = createAsyncThunk(
  `${NAME}/loadCityFlowParams`,
  async ({ selectedTime }: { selectedTime: string }) => {
    const [from, to] = getFromTo(selectedTime);
    try {
      const res = await getCityFlowParamsSplit({ data: "merged", split: "60", from, to });
      return res.data[0].flowParams;
    } catch (error) {
      throw error;
    }
  }
);

export const loadAlternativeScoreParams = createAsyncThunk(
  `${NAME}/loadAlternativeScoreParams`,
  async ({ selectedTime }: { selectedTime: string }) => {
    const [from, to] = getFromTo(selectedTime);
    try {
      const res = await getAlternativeScoreParamsSplit({ data: "merged", split: "60", from, to });
      return res.data[0].scores;
    } catch (error) {
      throw error;
    }
  }
);
