/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import { GState } from "documentations";
import * as ACTIONS from "features/ctrl-route/store/slice";
import { useIsDisabled } from "../../../hooks";
import { RouteInputRow } from "./route-input-row/route-input-row";

const RoutePoints: React.FC = () => {
  const isDisabled = useIsDisabled();
  const dispatch = useDispatch();

  const { points } = useSelector((state: GState) => ({ points: state.router.points }));
  const mainPoints = points.filter((el) => el.isMain);
  const showExchange = mainPoints.length === 2 && (mainPoints[0].coor || mainPoints[1].coor);

  const onDragEnd = (result: DropResult) => {
    dispatch(ACTIONS.onDragEnd(result));
  };

  const handleClickExchange = () => {
    dispatch(ACTIONS.exchangePoints());
  };

  const handleClearButton = (key: string) => {
    dispatch(ACTIONS.clearPoint(key));
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {mainPoints.map((item, index) => {
                return (
                  <Draggable key={item.key} draggableId={item.key} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} className="ctrl-route-drag-container">
                        <RouteInputRow
                          index={index}
                          isDisabled={isDisabled}
                          item={item}
                          provided={provided}
                          deleteAddressPoint={() => handleClearButton(item.key)}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showExchange && (
        <button type="button" className="route-main-button-exchange" onClick={handleClickExchange}>
          <i className="dtm-exchange-icon" />
        </button>
      )}
    </>
  );
};

export default RoutePoints;
