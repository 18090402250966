import styled from "styled-components";
import { APP_ENV } from "app-env";

const cdn = APP_ENV.REACT_APP_DTM_MEDIA.split("integration")[0];

export const RouteTimeVariantsItemContainer = styled.div`
  .route-time-variants-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 15px;
    border-left: 4px solid transparent;
    font-weight: 500;
    color: var(--text-color-default);

    &-bold {
      color: var(--text-color-hover);
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--background-color-hover);
    }

    &-time {
      font-size: 10px;
      color: inherit;
      font-weight: inherit;
    }

    &-to {
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;

      &-time {
        min-width: 50px;
      }
    }

    &-distribution {
      width: 100%;
      padding: 0 10px;
    }

    &-active {
      border-left: 4px solid var(--green-color);
      background-color: var(--background-color-hover);
    }

    &-stub {
      width: 20px;
      height: 10px;
      background: url(${cdn + "image/loader.gif"});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px;
    }
  }
`;
