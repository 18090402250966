import { createStore, sample } from "effector";
import { events } from "./transport-hubs.events";
import { effects } from "./transport-hubs.effects";
import * as reducers from "./transport-hubs.reducers";
import { State } from "./transport-hubs.types";

const initialState: State = {
  isDisabled: true,
  isVisible: false,
};

export const store = createStore<State>(initialState);

store.on(events.initializeIsVisible, reducers.setIsVisible);
store.on(events.setIsDisabled, reducers.handleSetIsDisabled);
store.on(events.restoreIsVisible, reducers.setDefaultIsVisible);
store.on(events.handleIsVisibleClick, reducers.setNextIsVisible);
store.on(effects.loadTransportHubs.doneData, reducers.setFeatureCollection);

sample({
  source: events.initializeIsVisible,
  target: effects.loadTransportHubs,
});

sample({
  source: store,
  clock: events.restoreIsVisible,
  target: events.handleIsVisibleChange,
});

sample({
  source: store,
  clock: events.handleIsVisibleClick,
  fn: (source, clock) => {
    clock.stopPropagation();
    if (!source.isVisible || source.featureCollection) return source;
    effects.loadTransportHubs();
    return source;
  },
  target: events.handleIsVisibleChange,
});
