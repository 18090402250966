import { BaseLayer } from "map-helpers";
import getBeforeId, { TELEMETRY_POINTS_ID } from "map-helpers/order-layers";
import TrafficProperties from "map-helpers/properties/traffic-properties";

export class TelemetryPointsLayer extends BaseLayer.Abstract<GeoJSON.Point, GeoJSON.GeoJsonProperties> {
  private properties: TrafficProperties = new TrafficProperties();
  private trafficColors = this.properties.getTrafficColors();
  private layerConfig: mapboxgl.CircleLayer = {
    "id": TELEMETRY_POINTS_ID,
    "type": "circle",
    "source": TELEMETRY_POINTS_ID,
    "source-layer": "points",
    "paint": {
      "circle-color": [
        "case",
        ["==", ["get", "speed"], 0],
        "#b9b0b0",
        ["==", ["get", "traffic"], 100],
        this.trafficColors[100],
        ["==", ["get", "traffic"], 4],
        this.trafficColors[4],
        ["==", ["get", "traffic"], 3],
        this.trafficColors[3],
        ["==", ["get", "traffic"], 2],
        this.trafficColors[2],
        ["==", ["get", "traffic"], 1],
        this.trafficColors[1],
        "#FFFFFF",
      ],
      "circle-opacity": ["case", ["==", ["get", "speed"], 0], 0.4, 1],
      "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 1, 12, 2, 18, 4],
    },
    "layout": {
      "circle-sort-key": ["case", ["==", ["get", "speed"], 0], 1, 10],
    },
  };

  constructor(map: mapboxgl.Map, tileUrl: string) {
    super(map, {
      id: TELEMETRY_POINTS_ID,
      sourceType: "tile",
      tileUrl,
      beforeId: getBeforeId(TELEMETRY_POINTS_ID, map),
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }
}
