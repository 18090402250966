import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialStateFromUrl } from "utils/get-initial-state-from-url";
import { TlsState } from "../types";

export const initialState: TlsState = {
  isActive: getInitialStateFromUrl("tls"),
};

export const tlsSlice = createSlice({
  name: "tls",
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
  },
});
