import * as THREE from "three";
import { Parent, ThreeObject } from "./three-object";

type Options = {
  radius: number;
  height: number;
  color?: number;
  opacity?: number;
};

const segments = 25;

export class Cylinder extends ThreeObject<THREE.CylinderGeometry> {
  constructor(parent: Parent, options: Options) {
    super(parent);

    const { radius, height, color = 0x000000, opacity = 1 } = options;

    this.geometry = new THREE.CylinderGeometry(radius, radius, height, segments);
    this.material = new THREE.MeshPhongMaterial({
      transparent: true,
      opacity,
      color,
    });
    this.mesh = new THREE.Mesh(this.geometry, this.material);
  }
}
