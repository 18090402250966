import mapboxgl from "mapbox-gl";
import { MapController } from "map-controller";
import * as observer from "observer";
import { UiStore } from "store";
import { Broker } from "./broker";

// @note убрать, когда полностью перейдем на новую архитектуру
export class ObserverBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleMapLoad = (map?: mapboxgl.Map) => {
    observer.dispatch(observer.EVENTS.INIT_MAP, map);
  };

  private handleMapRemove = () => {
    observer.dispatch(observer.EVENTS.REMOVE_MAP);
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    this.mapController.on(this.mapController.events.remove, this.handleMapRemove);
  };
}
