import React from "react";
import cn from "classnames";
import { Item } from "./units";
import * as consts from "./navigation-groupe-layers.consts";
import type { Props } from "./navigation-groupe-layers.types";

import "./navigation-groupe-layers.scss";

export const NavigationGroupeLayers = (props: Props) => {
  const { title, children, isActive, onClick } = props;
  const [showButtons, setShowButtons] = React.useState(false);
  const handleEnter = () => setShowButtons(true);
  const handleLeave = () => setShowButtons(false);
  const iconClassName = React.useMemo(
    () => cn(consts.iconBaseClassName, isActive && consts.iconActiveClassName),
    [isActive]
  );

  const childrenCount = React.useMemo(() => {
    return (
      React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return null;
        return child;
      })?.filter((el) => !!el).length ?? 0
    );
  }, [children]);

  const width = React.useMemo(
    () => (showButtons ? childrenCount * consts.buttonWidth : 0),
    [showButtons, childrenCount]
  );
  const url = props.isActive ? props.iconActiveUrl : props.iconNormalUrl;

  return (
    <div
      title={title}
      className={consts.baseClass}
      onClick={onClick}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}>
      <div className={consts.itemsWrapperClassName} style={{ width }}>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) return null;
          return <div className={consts.childClassName}>{child}</div>;
        })}
      </div>
      <div className={iconClassName}>
        <img src={url} width={24} height={24} alt={title} />
      </div>
    </div>
  );
};

NavigationGroupeLayers.Item = Item;
