import { useEffect } from "react";
import { useAccessToken } from "@megapolis/react-auth";
import { shared } from "shared";
import { StoreContext, storeContextValue } from "./store.context";

export const Store: React.FC = ({ children }) => {
  const accessToken = useAccessToken();

  useEffect(() => {
    shared.global.accessToken.value = accessToken;
  }, [accessToken]);

  return <StoreContext.Provider value={storeContextValue}>{children}</StoreContext.Provider>;
};
