import React, { memo, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { formatLengthRoute } from "utils/format-length-route";
import { getTimeRoute } from "utils/route-helpers";
import { TrafficIndexIcon } from "components";
import TrafficScore from "../../../../../../ctrl-traffic/components/traffic-score";
import RouteTimeTooltip from "../../../common-components/route-time-tooltip/route-time-tooltip";
import RouteDestinationTime from "../../../common-components/route-destination-time/route-destination-time";
import RouteTrafficDistribution from "../../../common-components/route-traffic-distribution/route-traffic-distribution";
import RouteDifferenceTime from "../../../common-components/route-difference-time/route-difference-time";

import "./route-variants-item.scss";

type RouteVariantsAttributes = {
  compare_time: number;
  length: number;
  max_lat: number;
  max_lon: number;
  min_lat: number;
  min_lon: number;
  time: number;
};

type RouteVariantsProps = {
  index: number;
  attributes: RouteVariantsAttributes;
  handleToggleRoute: (event: any) => void;
  isActive: boolean;
  score: number;
  scoreIdz: number;
  variant: any;
  width: number;
  differenceTime: any;
  handleClickManeuvers: (event: any) => void;
  maneuvers: any;
  timeVariant?: any;
  selectedTime: any;
  isDisabled: boolean;
};

const className = "route-variants-item";

export const RouteVariantsItem: React.FC<RouteVariantsProps> = memo((props) => {
  const { maneuvers, variant, differenceTime, timeVariant, score, scoreIdz, attributes, width } = props;
  const { isActive, isDisabled } = props;
  const { handleClickManeuvers, handleToggleRoute } = props;
  const [isTooltip, setIsTooltip] = useState(false);
  const timeRoute = useMemo(() => getTimeRoute(variant), [variant]);
  const lengthRoute = useMemo(() => formatLengthRoute(attributes.length), [attributes]);
  const containerClassName = useMemo(
    () =>
      cn(className, {
        [`${className}-active`]: isActive,
      }),
    [isActive]
  );

  const showTooltip = useCallback((value: boolean) => {
    setIsTooltip(value);
  }, []);

  const handleSelectManeuvers = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isDisabled) return;
      handleClickManeuvers(e);
    },
    [isDisabled, handleClickManeuvers]
  );

  const handleSelectRoute = useCallback(
    (e) => {
      if (isDisabled) return;
      handleToggleRoute(e);
    },
    [isDisabled, handleToggleRoute]
  );

  const handleMouseEnter = () => {
    showTooltip(true);
  };

  const handleMouseLeave = () => {
    showTooltip(false);
  };

  return (
    <div className={containerClassName} onClick={handleSelectRoute} role="button">
      <div className={`${className}-row`}>
        <div className={`${className}-left`}>
          <i className="dtm-car-icon" />
        </div>
        <div className={`${className}-center`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <TrafficScore score={score} apply titleScore="" />
          <TrafficIndexIcon customClass={`${className}-route-idz`} score={scoreIdz} type="route" />
          <div className={`${className}-route-time ${className}-score-${score}`}>{timeRoute.withTraffic}</div>
          {isTooltip && !maneuvers && <RouteTimeTooltip timeWitoutTraffic={timeRoute.withoutTraffic} />}
        </div>
        <div className={`${className}-right`}>
          <div className={`${className}-destination-time`}>
            <RouteDestinationTime variant={variant} />
          </div>
        </div>
      </div>
      <div className={`${className}-row`}>
        <RouteTrafficDistribution variant={variant} width={width} type="time" />
      </div>
      <div className={`${className}-row`}>
        <div className={`${className}-distance`}>{lengthRoute}</div>
        {!isActive && !timeVariant && (
          <div className={`${className}-difference`}>
            <RouteDifferenceTime differenceTime={differenceTime} />
          </div>
        )}
      </div>
      {isActive && !maneuvers && (
        <div className={`${className}-row`}>
          <button className={`${className}-maneuvers`} type="button" onClick={handleSelectManeuvers}>
            Посмотреть по маневрам
          </button>
        </div>
      )}
    </div>
  );
});
