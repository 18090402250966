import { Attribute } from "./store.types";

export const initialState: Attribute[] | null = null;

export const constants = {
  INITIALIZE: "INITIALIZE",
  CREATE: "CREATE",
  DELETE: "DELETE",
  CHANGE_LABEL: "CHANGE_LABEL",
  CHANGE_VALUE: "CHANGE_VALUE",
  SET_EDITABLE: "SET_EDITABLE",
  SUBMIT_SAVE: "SUBMIT_SAVE",
};
