import { createSelector } from "@reduxjs/toolkit";
import { GState } from "documentations";

const roadDetectorSelector = (state: GState) => state.roadDetector;
const roadDetectorFiltersSelector = (state: GState) => roadDetectorSelector(state).roadDetectorFilters;
const activeFilterSelectors = (state: GState) => roadDetectorSelector(state).activeFiltersIds;

export const loadVCParamSelector = createSelector(
  [roadDetectorFiltersSelector, activeFilterSelectors],
  (filters, selected) => {
    return filters.length === selected.length ? undefined : selected;
  }
);
