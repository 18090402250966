import { APP_ENV } from "app-env";
import { AdministrativeDivisionTypes } from "types";
import { safeFetchJson } from "../utils";
import { getRoadsPath, getStatisticsPath, paths } from "./utils";

const baseURL = APP_ENV.REACT_APP_DTM_TRAFFIC_API;
const districtCountBaseUrl = "getcounts?fields=districtId&withDecoding=true&filter=deleted%20eq%20false";
const districtAssetTlsCountUrl = `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assettls/${districtCountBaseUrl}`;
const districtAssetRoadSignCountUrl = `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assetroadsign/${districtCountBaseUrl}`;
const districtAssetSpeedCamCountUrl = `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assetspeedcam/${districtCountBaseUrl}`;

export class AdministrativeDivisionAPI {
  private roadsParser = (response: { [key: string]: number }): AdministrativeDivisionTypes.Roads => {
    const info = Object.entries(response).reduce(
      (acc, [key, value]) => {
        acc.roads.push(key);
        acc.roadsLength += value;
        return acc;
      },
      { roads: [] as string[], roadsLength: 0 }
    );

    info.roadsLength = Math.round(info.roadsLength * 100) / 100;

    return info;
  };

  public readonly getDistricts = async () => {
    return safeFetchJson(`${baseURL}${paths.districts}`);
  };

  public readonly getDistrictsZones = async () => {
    return safeFetchJson(`${baseURL}${paths.districtsZonesPath}`);
  };

  public readonly getStatistics = async (osmId: number) => {
    return safeFetchJson(`${baseURL}${getStatisticsPath(osmId)}`);
  };

  public readonly getRoads = async (osmId: number) => {
    return safeFetchJson(`${baseURL}${getRoadsPath(osmId)}`).then(this.roadsParser);
  };

  public readonly getDistrictAssetTlsCount = async () => {
    return safeFetchJson(districtAssetTlsCountUrl);
  };

  public readonly getDistrictAssetRoadSignCount = async () => {
    return safeFetchJson(districtAssetRoadSignCountUrl);
  };

  public readonly getDistrictAssetSpeedCamCount = async () => {
    return safeFetchJson(districtAssetSpeedCamCountUrl);
  };
}
