import { createEvent } from "effector";
import { State } from "./graph-card.types";

const handleIsVisibleChange = createEvent<State["isVisible"]>();
const handleIsEditModeChange = createEvent<State["isEditMode"]>();
const handleSetCardData = createEvent<State["cardData"][0]>();
const handleSetEdgeInfo = createEvent<State["edgeInfo"]>();

export const events = {
  handleIsVisibleChange,
  handleIsEditModeChange,
  handleSetCardData,
  handleSetEdgeInfo,
};

export type Events = typeof events;
