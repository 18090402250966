import { MapLayer, Events } from "./map-layer";

export abstract class GeojsonLayer extends MapLayer {
  protected abstract addSource(data: GeoJSON.FeatureCollection): void;

  protected abstract addLayer(): void;

  public add(data: GeoJSON.FeatureCollection) {
    this.remove();
    this.addSource(data);
    this.addLayer();
    this.dispatch(Events.addLayer);
  }

  public update(data: GeoJSON.FeatureCollection) {
    this.add(data);
  }
}
