import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";

export class RouteDiagramFlatLayer extends BaseLayer.Abstract<GeoJSON.Polygon, { unitsCount: number }> {
  private config: mapboxgl.FillLayer = {
    id: "2d",
    type: "fill",
    source: "2d",
    layout: {},
    paint: {
      "fill-color": "#007EFC",
      "fill-opacity": 0.5,
    },
  };

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: "2d",
    });

    this.setLayer(this.config);
    this.addSource();
    this.addLayer();
  }
}
