import * as Types from "../schemes.types";
import * as GraphConsts from "../../../utils/consts";

export class Points {
  private segmentHeight = 0;

  constructor(private ctx: CanvasRenderingContext2D, private options: Types.Options) {
    this.calculateSegmentHeight();
  }

  private calculateSegmentHeight = () => {
    this.segmentHeight = Math.abs(GraphConsts.graphBodyBottom - GraphConsts.graphBodyTop) / 9; // @note body height;
  };

  public updateScores = (scores: Types.Options["scores"]) => {
    this.options.scores = scores;
    this.draw();
  };

  public readonly draw = (scale: number = 1, offset: number = 0) => {
    const radius = 3;
    const startY = GraphConsts.graphBodyBottom;
    // 7 это стандартная ширина 15ти минутного интервала на графике при scale = 1
    const segmentWidth = 7 * scale;

    const drawPoint = (score: number, index: number) => {
      this.ctx.fillStyle = this.options.getColor(score);
      this.ctx.strokeStyle = "#ffffff";

      const startX = segmentWidth / 2 + segmentWidth * index + offset;
      const startY = -this.segmentHeight * score;

      this.ctx.beginPath();
      this.ctx.arc(startX, startY, radius, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.closePath();
    };

    this.ctx.save();
    this.ctx.globalAlpha = 1;
    this.ctx.translate(GraphConsts.bodyLeft, startY);

    Object.keys(this.options.scores)
      .sort((a, b) => {
        return new Date(a).valueOf() - new Date(b).valueOf();
      })
      .forEach((key, index) => {
        const score = this.options.scores[key].score;
        drawPoint(score, index);
      });

    this.ctx.restore();
  };

  public readonly destroy = () => {};
}
