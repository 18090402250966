import { memo } from "react";
import { CityTrafficIcon, RouteTrafficIcon, RouteTimeIcon, RouteSpeedIcon, UnitsCountIcon } from "assets";
import { LegendItem } from "./legend-item";

import "./graph-legend.scss";

type Props = {
  isCityTraffic: boolean;
  isRouteTraffic: boolean;
  isAverageTime: boolean;
  isAverageSpeed: boolean;
  isUnitsCountDiagram: boolean;
  isUnitsCountGraph: boolean;
};

const baseClass = "graph-legend";
const cityTrafficIcon = <CityTrafficIcon />;
const routeTrafficIcon = <RouteTrafficIcon />;
const routeTimeIcon = <RouteTimeIcon />;
const routeSpeedIcon = <RouteSpeedIcon />;
const unitsCountIcon = <UnitsCountIcon />;

export const GraphLegend = memo<Props>((props) => {
  return (
    <div className={baseClass}>
      {!props.isUnitsCountDiagram && props.isRouteTraffic && (
        <LegendItem icon={routeTrafficIcon} title="Балл пробок по маршруту" />
      )}
      {!props.isUnitsCountDiagram && props.isCityTraffic && (
        <LegendItem icon={cityTrafficIcon} title="Балл пробок по городу" />
      )}
      {!props.isUnitsCountDiagram && props.isAverageTime && (
        <LegendItem icon={routeTimeIcon} title="Среднее время пути (мин)" />
      )}
      {!props.isUnitsCountDiagram && props.isAverageSpeed && (
        <LegendItem icon={routeSpeedIcon} title="Средняя скорость на участке (км/ч)" />
      )}
      {props.isUnitsCountDiagram && props.isUnitsCountGraph && (
        <LegendItem icon={unitsCountIcon} title="Количество ТС на участке" />
      )}
    </div>
  );
});
