export type ValuesTuple = [number, number, number, number, number, number];

export const prepareDiagramAppearance = (values: ValuesTuple): DiagramAppearance => [
  {
    key: 4,
    value: 0,
  },
  {
    key: 3,
    label: "3",
    value: values[1],
  },
  {
    key: 2,
    label: "2",
    value: values[2],
  },
  {
    key: 1,
    label: "1",
    value: values[3],
  },
  {
    key: 100,
    label: "100",
    value: values[4],
  },
  {
    key: 999,
    value: 150,
  },
];
