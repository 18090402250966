import React, { useEffect, useState } from "react";
import { CloseButton } from "components";
import { CaptionBlock, Label, ModalCopy, Value } from "./caption.styled";

type CaptionPropsType = {
  label: string;
  value: string;
};

export const Caption = (props: CaptionPropsType) => {
  const [isShowModalCopy, setIsShowModalCopy] = useState(false);

  const copyValue = () => {
    navigator.clipboard.writeText(props.value);
    setIsShowModalCopy(true);
  };

  let timer: NodeJS.Timeout;
  useEffect(() => {
    if (isShowModalCopy) timer = setTimeout(() => setIsShowModalCopy(false), 1500);
    else clearTimeout(timer);
    return () => clearTimeout(timer);
  }, [isShowModalCopy]);

  return (
    <>
      <CaptionBlock>
        <Label>{props.label}</Label>
        <Value onClick={copyValue}>{props.value}</Value>
      </CaptionBlock>
      {isShowModalCopy && (
        <ModalCopy>
          <span>Данные скопированы в буфер обмена</span>
          <CloseButton onClick={() => setIsShowModalCopy(false)} />
        </ModalCopy>
      )}
    </>
  );
};
