import { memo, useState, useRef, useCallback, useMemo } from "react";
import cn from "classnames";
import { Checkbox } from "../checkbox";

import "./weekday-selector.scss";

type Props = {
  selected: number[];
  onChange: (selected: number[]) => void;
};

const baseClass = "weekday-selector";
const headingClass = `${baseClass}__heading`;
const popupClass = `${baseClass}__popup`;

const weekdays = [
  {
    key: 1,
    title: "Понедельник",
    shortTitle: "Пн",
  },
  {
    key: 2,
    title: "Вторник",
    shortTitle: "Вт",
  },
  {
    key: 3,
    title: "Среда",
    shortTitle: "Ср",
  },
  {
    key: 4,
    title: "Четверг",
    shortTitle: "Чт",
  },
  {
    key: 5,
    title: "Пятница",
    shortTitle: "Пт",
  },
  {
    key: 6,
    title: "Суббота",
    shortTitle: "Сб",
  },
  {
    key: 7,
    title: "Воскресенье",
    shortTitle: "Вс",
  },
];

const searchPredicate = (searchKey: number) => (key: number) => key === searchKey;

export const WeekdaySelector = memo<Props>(({ selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [_selected, setSelected] = useState(selected);
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const buttonClass = cn(baseClass, { [`${baseClass}_open`]: isOpen });
  const activeWeekdays = useMemo(
    () => weekdays.filter(({ key }) => _selected.some((_key) => _key === key)),
    [_selected]
  );
  const title = useMemo(
    () =>
      activeWeekdays.reduce((acc, item, index) => {
        if (index === activeWeekdays.length - 1) return acc + item.shortTitle;
        return acc + `${item.shortTitle}, `;
      }, ""),
    [activeWeekdays]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (popupRef.current?.contains(e.target as Node)) return;
      if (isOpen) {
        onChange(_selected);
      }
      setIsOpen((prev) => !prev);
    },
    [popupRef.current, isOpen, _selected]
  );

  // const handleOutsideClick = useCallback((e: MouseEvent) => {
  // if (containerRef.current?.contains(e.target as Node)) return;
  // setIsOpen(false);
  // }, [_selected, containerRef.current]);

  const handleChange = (key: number) => () =>
    setSelected((selected): number[] => {
      if (selected.includes(key)) {
        if (selected.length > 1) return selected.filter((item) => item !== key);
        return selected;
      }
      return [...selected, key];
    });

  // useEffect(() => {
  //   document.removeEventListener("click", handleOutsideClick);
  //   document.addEventListener("click", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   }
  // }, [_selected, handleOutsideClick]);

  return (
    <button ref={containerRef} className={buttonClass} onClick={handleClick}>
      <div className={headingClass}>{title}</div>
      {isOpen && (
        <div ref={popupRef} className={popupClass}>
          {weekdays.map((weekday) => {
            return (
              <Checkbox
                key={weekday.key}
                checked={_selected.some(searchPredicate(weekday.key))}
                value={weekday.title}
                label={weekday.title}
                title={weekday.title}
                onChange={handleChange(weekday.key)}
              />
            );
          })}
        </div>
      )}
    </button>
  );
});
