import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialStateFromUrl } from "utils/get-initial-state-from-url";
import { DetectorData, DetectorState } from "../types";
import { getDetectorDataById } from "api/detector-data";
import { DetectorDataApiResponseType } from "api/detector-data/detector-data.types";
import moment from "moment";

const NAME = "detector";
export const initialState: DetectorState = {
  isActiveDetector: getInitialStateFromUrl(NAME),
  isActiveNoneDirectionDetector: false,
  openedDetectors: {},
};

export const detectorSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsActiveDetector(state, action: PayloadAction<boolean>) {
      state.isActiveDetector = action.payload;
    },
    setIsActiveNoneDirectionDetector(state, action: PayloadAction<boolean>) {
      state.isActiveNoneDirectionDetector = action.payload;
    },

    setDirection(state, action: PayloadAction<{ detectorId: string; direction: DetectorData["direction"] }>) {
      const { detectorId, direction } = action.payload;
      state.openedDetectors[detectorId].direction = direction;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLastDetectorData.pending, (state: DetectorState, action) => {
      const { ...tileData } = action.meta.arg;
      state.openedDetectors[tileData.id] = {
        isLoad: true,
        direction: "forward",
        tileData,
      };
    });
    builder.addCase(loadLastDayDetectorData.pending, (state: DetectorState, action) => {
      const { ...tileData } = action.meta.arg;
      state.openedDetectors[tileData.id] = {
        ...state.openedDetectors[tileData.id],
        isLoad: true,
      };
    });
    builder.addCase(loadLastDetectorData.rejected, (state: DetectorState, action) => {
      const { ...tileData } = action.meta.arg;
      state.openedDetectors[tileData.id] = {
        ...state.openedDetectors[tileData.id],
        isLoad: false,
      };
    });
    builder.addCase(loadLastDayDetectorData.rejected, (state: DetectorState, action) => {
      const { ...tileData } = action.meta.arg;
      state.openedDetectors[tileData.id] = {
        ...state.openedDetectors[tileData.id],
        isLoad: false,
      };
    });

    builder.addCase(loadLastDetectorData.fulfilled, (state: DetectorState, action) => {
      const { detectorId, forward, backward } = action.payload;
      const { id } = action.meta.arg;
      state.openedDetectors[id].isLoad = false;
      if (forward) {
        state.openedDetectors[detectorId].forward = {
          ...state.openedDetectors[detectorId].forward,
          persistentEdgeId: forward.persistentEdgeId,
          flowData: {
            avgDataAllLines: {
              avgDensity: Number(forward.traffic[0].avgDensity.toFixed(0)),
              avgOcc: forward.traffic[0].avgOcc,
              avgSpeed: forward.traffic[0].avgSpeed,
              avgVolume: forward.traffic[0].avgVolume,
              avgUtilization: forward.traffic[0].avgUtilization,
              avgFreewaySpeed: forward.traffic[0].avgFreewaySpeed,
              totalUnitsCount: forward.traffic[0].totalUnitsCount,
              to: forward.traffic[0].to,
              from: forward.traffic[0].from,
            },
            lines: forward.traffic[0].lanes,
          },
        };
      }
      if (backward) {
        state.openedDetectors[detectorId].backward = {
          ...state.openedDetectors[detectorId].backward,
          persistentEdgeId: backward.persistentEdgeId,
          flowData: {
            avgDataAllLines: {
              avgDensity: Number(backward.traffic[0].avgDensity.toFixed(0)),
              avgOcc: backward.traffic[0].avgOcc,
              avgSpeed: backward.traffic[0].avgSpeed,
              avgVolume: backward.traffic[0].avgVolume,
              avgUtilization: backward.traffic[0].avgUtilization,
              avgFreewaySpeed: backward.traffic[0].avgFreewaySpeed,
              totalUnitsCount: backward.traffic[0].totalUnitsCount,
              to: backward.traffic[0].to,
              from: backward.traffic[0].from,
            },
            lines: backward.traffic[0].lanes,
          },
        };
      }
    });

    builder.addCase(loadLastDayDetectorData.fulfilled, (state: DetectorState, action) => {
      const { detectorId, forward, backward } = action.payload;
      const { id } = action.meta.arg;
      state.openedDetectors[id].isLoad = false;
      if (forward) {
        state.openedDetectors[detectorId].forward = {
          ...state.openedDetectors[detectorId].forward,
          persistentEdgeId: forward.persistentEdgeId,
          flowDataLastDayStat: forward.traffic.map((t) => ({
            avgDataAllLines: {
              avgDensity: Number(t.avgDensity.toFixed(0)),
              avgOcc: t.avgOcc,
              avgSpeed: t.avgSpeed,
              avgVolume: t.avgVolume,
              avgUtilization: t.avgUtilization,
              avgFreewaySpeed: t.avgFreewaySpeed,
              totalUnitsCount: t.totalUnitsCount,
              to: t.to,
              from: t.from,
            },
            lines: t.lanes,
          })),
        };
      }

      if (backward) {
        state.openedDetectors[detectorId].backward = {
          ...state.openedDetectors[detectorId].backward,
          persistentEdgeId: backward.persistentEdgeId,
          flowDataLastDayStat: backward.traffic.map((t) => ({
            avgDataAllLines: {
              avgDensity: Number(t.avgDensity.toFixed(0)),
              avgOcc: t.avgOcc,
              avgSpeed: t.avgSpeed,
              avgVolume: t.avgVolume,
              avgUtilization: t.avgUtilization,
              avgFreewaySpeed: t.avgFreewaySpeed,
              totalUnitsCount: t.totalUnitsCount,
              to: t.to,
              from: t.from,
            },
            lines: t.lanes,
          })),
        };
      }
    });
  },
});

export const loadLastDetectorData = createAsyncThunk<DetectorDataApiResponseType["data"], DetectorData["tileData"]>(
  `${NAME}/loadLastDetectorData`,
  async (tileData) => {
    try {
      const data = await getDetectorDataById({
        detectorId: tileData.id,
        last: true,
      });
      return {
        ...data.data,
        ...tileData,
      };
    } catch (error) {
      throw error;
    }
  }
);
export const loadLastDayDetectorData = createAsyncThunk<DetectorDataApiResponseType["data"], DetectorData["tileData"]>(
  `${NAME}/loadLastDayDetectorData`,
  async (tileData) => {
    try {
      return {
        ...(
          await getDetectorDataById({
            detectorId: tileData.id,
            last: false,
            split: 30,
            to: moment().format("YYYY-MM-DDTHH:00:00"),
            from: moment().subtract(24, "hours").format("YYYY-MM-DDTHH:00:00"),
          })
        ).data,
        ...tileData,
      };
    } catch (error) {
      throw error;
    }
  }
);
