import { sample, createEffect } from "effector";
import Cookies from "js-cookie";
import { shared } from "shared";
import { store } from "../application-settings";
import { events } from "../application-settings.events";
import { State } from "../application-settings.types";

const whriteCookie = createEffect((state: State) => {
  Cookies.set("telemetry-is-empty-pieces", String(state.telematics.isEmptyPieces), shared.global.cookieOptions);
});

export const whriteTelematicsIsEmptyPiecesoCookieSampleFactory = () => {
  sample({
    source: store,
    clock: events.handleTelematicsIsEmptyPieces,
    target: whriteCookie,
  });
};
