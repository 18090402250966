import React, { useRef } from "react";
import cn from "classnames";
import { options, hooks } from "./utils";
import { Mode, Option } from "./types";
import { ReactComponent as Icon } from "assets/icons/fill-triangle.svg";
import "./index.scss";

export const baseClass = "one-day-selector";

export type State = {
  mode: Mode;
  selected?: Option;
};

export type Props = {
  date: string;
  onChange: (from: string, to: string) => void;
  disabled?: boolean;
};

export type StateSetter = (value: React.SetStateAction<State>) => void;

export const OneDaySelector: React.FC<Props> = ({ date, onChange, disabled = false }) => {
  const [state, setState] = hooks.useState();
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const label = hooks.useLabel(date);
  const callbacks = hooks.useCallbacks({ state, date, selectRef, onChange, setState });
  const containerClass = cn(`${baseClass}__container`, { [`${baseClass}__container_disabled`]: disabled });
  const nextButtonClassName = cn(`${baseClass}__button`, `${baseClass}__button_right`, {
    [`${baseClass}__button_disabled`]: label.isToday(),
  });

  return (
    <div className={containerClass}>
      <button
        disabled={disabled}
        className={`${baseClass}__button ${baseClass}__button_left`}
        onClick={callbacks.handlePrev}
        data-testid="prev-button">
        <Icon />
      </button>
      {state.mode === Mode.BUTTON ? (
        <button
          disabled={disabled}
          className={`${baseClass}__info ${baseClass}__btn`}
          data-testid="label"
          onClick={callbacks.handleClick}>
          {label.getLabel()}
        </button>
      ) : (
        <div className={`${baseClass}__select-wrapper`}>
          <select
            disabled={disabled}
            ref={selectRef}
            className={`${baseClass}__info ${baseClass}__select-wrapper__select`}
            data-testid="label"
            onChange={callbacks.handleChange}
            value={state.selected?.value}>
            {options.get()}
          </select>
        </div>
      )}
      <button
        data-testid="next-button"
        className={nextButtonClassName}
        disabled={disabled && label.isToday()}
        onClick={callbacks.handleNext}>
        <Icon />
      </button>
    </div>
  );
};
