import { RouteResponse } from "features/ctrl-route/types";
import { roundUp } from "./round-up";
import { formatTimeDuration } from "./format-time-duration";

interface TimeRoute {
  withTraffic?: string;
  withoutTraffic?: string;
}

export function getTimeRoute(route: RouteResponse) {
  const timeRoute: TimeRoute = {};

  const { time, compare_time } = route.original.trip.summary;

  const secondsTime = roundUp(time, 60);

  const secondsCompareTime = roundUp(compare_time, 60);

  if (secondsTime > secondsCompareTime) {
    timeRoute.withTraffic = formatTimeDuration(secondsTime);
    timeRoute.withoutTraffic = formatTimeDuration(secondsCompareTime);
  } else {
    timeRoute.withTraffic = formatTimeDuration(secondsCompareTime);
    timeRoute.withoutTraffic = formatTimeDuration(secondsTime);
  }

  return timeRoute;
}
