import { getRouterInstance, getTrafficInstance } from "api";
import * as TYPES from "./model/models";

const metaUrl = "/meta";

export const meta = {
  /** Тип трафика */
  externalsystems: async () => {
    const path = `${metaUrl}/externalsystems`;
    const { data } = await getTrafficInstance().get<Array<TYPES.ExternalSystemResponse>>(path);
    return data;
  },
  /** Получить типы дорог */
  roadclasses: async () => {
    const path = `${metaUrl}/roadclasses`;
    const { data } = await getRouterInstance().get<Array<TYPES.RoadClassResponse>>(path);
    return data;
  },
  /** Получить происшествия, использующиеся для роутинга, на данный момент */
  incidents: async () => {
    const path = `${metaUrl}/incidents`;
    const { data } = await getRouterInstance().get<Array<TYPES.IncidentsResponse>>(path);
    return data;
  },
};
