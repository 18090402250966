import * as map from "./map";
import * as global from "./global";
import * as travelHeatmap from "./travel-heatmap";
import * as unitsCountDiagram from "./units-count-diagram";

export const shared = {
  map,
  global,
  travelHeatmap,
  unitsCountDiagram,
};

export * from "./url-params";
