import { createEvent } from "effector";
import { BusStopsTypes } from "types";
import { State } from "./bus-stops.types";

const restoreIsVisible = createEvent();
const setEsFromRedux = createEvent<State["es"]>();
const handleIsVisibleChange = createEvent<State>();
const initializeIsVisible = createEvent<boolean>();
const setToDateFromRedux = createEvent<State["to"]>();
const setIsDisabled = createEvent<State["isDisabled"]>();
const setFromDateFromRedux = createEvent<State["from"]>();
const setTrafficTypeFromRedux = createEvent<State["trafficType"]>();
const handleIsVisibleClick = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handleMissingBusStopInfo = createEvent<BusStopsTypes.FeatureProperties>();

export const events = {
  setIsDisabled,
  setEsFromRedux,
  restoreIsVisible,
  setToDateFromRedux,
  initializeIsVisible,
  handleIsVisibleClick,
  setFromDateFromRedux,
  handleIsVisibleChange,
  setTrafficTypeFromRedux,
  handleMissingBusStopInfo,
};

export type Events = typeof events;
