import React from "react";
import styled, { css } from "styled-components";

type Props = {
  text: string;
  onClick: () => void;
  isActive: boolean;
  count?: number;
  disable?: boolean;
};

export const FilterButtonWithCount = (props: Props) => {
  return (
    <CustomButton disabled={props.disable} isActive={props.isActive} onClick={props.onClick}>
      <span>{props.text}</span>
      <Count>{props.count}</Count>
    </CustomButton>
  );
};

const CustomButton = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  letter-spacing: 0.05em;
  padding: 8px;
  background: rgba(3, 40, 76, 0);

  &:active {
    background: rgba(3, 40, 76, 0.1);
    ${(props) =>
      props.disabled &&
      css`
        background: rgba(3, 40, 76, 0);
      `}
  }

  ${(props) =>
    props.isActive &&
    css`
      background: rgba(3, 40, 76, 0.1);
    `}

  border: 1px solid rgba(3, 40, 76, 0.1);
  border-radius: 6px;
`;

const Count = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: rgba(3, 40, 76, 0.66);
`;
