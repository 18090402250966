import * as THREE from "three";
import * as consts from "./diagram-3d.consts";

export const getBetweenStepCount = (stepLength: number) => {
  const stepIndex = consts.stepTicks.findIndex((el) => el === stepLength);
  const previousStep = consts.stepTicks[stepIndex - 1];
  const minusTwoStep = consts.stepTicks[stepIndex - 2];
  if (minusTwoStep) return stepLength / minusTwoStep;
  if (previousStep) return stepLength / previousStep;
  return 2;
};

export const getAxisStepByTotalLength = (totalLength: number) => {
  if (totalLength >= 110000) return consts.stepTicks[8];
  if (totalLength < 110000 && totalLength >= 55000) return consts.stepTicks[7];
  if (totalLength < 55000 && totalLength >= 22000) return consts.stepTicks[6];
  if (totalLength < 22000 && totalLength >= 11000) return consts.stepTicks[5];
  if (totalLength < 11000 && totalLength >= 5500) return consts.stepTicks[4];
  if (totalLength < 5500 && totalLength >= 2200) return consts.stepTicks[3];
  if (totalLength < 2200 && totalLength >= 1100) return consts.stepTicks[2];
  if (totalLength < 1100 && totalLength >= 550) return consts.stepTicks[1];
  return consts.stepTicks[0];
};

export const emptyGeometriesByColorsFactory = (): { [key: string]: THREE.BoxGeometry[] } => {
  return Object.keys(consts.trafficColors).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
};

export const createColorMaterial = (color: THREE.ColorRepresentation, opacity: number) =>
  new THREE.MeshPhongMaterial({
    side: THREE.DoubleSide,
    transparent: true,
    opacity,
    color,
  });

export const createMaterialsByColors = (opacity = 0.7): { [key: string]: THREE.MeshPhongMaterial } =>
  Object.keys(consts.trafficColors).reduce((acc, key) => {
    return {
      ...acc,
      [key]: createColorMaterial(consts.trafficColors[Number(key)], opacity),
    };
  }, {});

export const getSegmentWidth = (width: number) => {
  return width / consts.hoursCount / 4;
};

export const getSegmentHeight = (height: number, rowsCount: number) => {
  return height / rowsCount;
};

export const getSegmentDepth = (unitsCount?: number) => {
  return (unitsCount ?? 1) * consts.unitsCountDepthFactor;
};
