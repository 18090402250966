import { useStore } from "effector-react";
import { store } from "./graph-card";
import { transformCardDataForEdgeInfo } from "./helper";

const useIsVisible = () => useStore(store).isVisible;
const useIsEditMode = () => useStore(store).isEditMode;
const useCardData = () => useStore(store).cardData;
const useGetEdgeInfo = () => useStore(store).edgeInfo;
const useGetTransformEdgeInfo = () => transformCardDataForEdgeInfo(useStore(store).edgeInfo, "Сведения о ребре");

export const hooks = {
  useIsVisible,
  useIsEditMode,
  useCardData,
  useGetEdgeInfo,
  useGetTransformEdgeInfo,
};

export type Hooks = typeof hooks;
