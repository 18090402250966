import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { Broker } from "./broker";

export class PedestrianGraphBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleUiIsVisibleChange = (store: GState.PedestrianGraphState) => {
    this.mapController.pedestrianGraph?.setVisibility?.(store.isVisible);
  };

  private handleMapLoad = () => {
    this.uiStore.pedestrianGraph.events.setIsDisabled(false);
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    this.uiStore.pedestrianGraph.events.handleIsVisibleChange.watch(this.handleUiIsVisibleChange);
  };
}
