import mapboxgl from "mapbox-gl";
import { v4 } from "uuid";
import * as geometric from "geometric";
import { Tool, ToolOptions, AbstractCollection } from "./tool";

export type EditPoint = {
  id: string;
  coordinates: number[];
};

export type PolygonFigure = {
  id: string;
  type: "Polygon" | "MultiPolygon";
  points: EditPoint[];
};

export type PolygonCollection = AbstractCollection<PolygonFigure>;

type HalfPoint = {
  start: EditPoint;
  end: EditPoint;
  coordinates: number[];
};

const toGeometricPolygon = (coordinates: number[][]) => {
  return coordinates as geometric.Polygon;
};

export class PolygonTool extends Tool<PolygonFigure> {
  private isMousedown = false;
  private isCreating = false;
  private selectedFigure: PolygonFigure | null = null;
  private selectedPoint: PolygonFigure["points"][0] | null = null;
  private creatingFigure: PolygonFigure | null = null;
  private creatingHoverPoint: PolygonFigure["points"][0] | null = null;
  private creatingIsOnFirstHover = false;
  private halfPoints: HalfPoint[] = [];
  private pointToDelete: PolygonFigure["points"][0] | null = null;

  constructor(options: ToolOptions) {
    super(options);
    this.subscribe();
  }

  private createPoint = (coordinates: number[]): EditPoint => {
    return {
      id: v4(),
      coordinates,
    };
  };

  private handleCreatingClick = (event: mapboxgl.MapMouseEvent) => {
    const pointUnder = this.getPointUnder(this.creatingFigure, event.point);
    const length = this.creatingFigure?.points.length ?? 0;
    if (length !== 0 && length >= 2 && pointUnder?.id === this.creatingFigure?.points[0]?.id) {
      if (!this.creatingFigure || length <= 2) return;
      this.collection[this.creatingFigure.id] = this.creatingFigure;
      const points = this.collection[this.creatingFigure.id].points;
      const closingCoordinates = [...points[0].coordinates];
      this.collection[this.creatingFigure.id].points.push({
        id: v4(),
        coordinates: closingCoordinates,
      });
      this.selectedFigure = this.collection[this.creatingFigure.id];
      this.isCreating = false;
      this.isMousedown = false;
      this.creatingIsOnFirstHover = false;
      this.creatingFigure = null;
      this.creatingHoverPoint = null;
      return this.onChange(this.collection);
    }
    if (pointUnder && pointUnder.id !== this.creatingFigure?.points[0]?.id) return;
    this.creatingFigure?.points.push(this.createPoint(event.lngLat.toArray() as [number, number]));
    this.render();
  };

  private handleClick = (event: mapboxgl.MapMouseEvent) => {
    if (!this.isEditable || !this.isVisible) return;

    // @note событие click при создании полигона
    if (this.isCreating) {
      return this.handleCreatingClick(event);
    }

    const polygonUnder = this.getPolygonUnder(event.lngLat);
    const pointUnder = this.getPointUnder(this.selectedFigure, event.point);

    if (!pointUnder && polygonUnder?.id !== this.selectedFigure?.id) {
      this.selectedFigure = polygonUnder;
      this.render();
    }

    if (!this.selectedFigure) return;

    this.pointToDelete = this.getPointUnder(this.selectedFigure, event.point) ?? null;
  };

  private handleMousedown = (event: mapboxgl.MapMouseEvent) => {
    if (!this.isEditable || !this.isVisible) return;
    if (this.isCreating) return;

    const polygon = this.getPolygonUnder(event.lngLat);
    const point = this.getPointUnder(this.selectedFigure, event.point);
    const halfPoint = this.halfPoints.find(({ coordinates }) => this.isInPoint(event.point)(coordinates));

    if (!point && halfPoint && this.selectedFigure) {
      const { start } = halfPoint;
      const newPoint = this.createPoint(halfPoint.coordinates);
      const indexToInsert = this.selectedFigure?.points.findIndex(({ id }) => id === start.id);

      if (indexToInsert < 0) return;

      this.selectedFigure.points.splice(indexToInsert + 1, 0, newPoint);
      this.selectedPoint = newPoint;
      this.isMousedown = true;
      this.map.dragPan.disable();
      return this.render();
    }

    if (!point && this.selectedFigure?.id !== polygon?.id) {
      this.selectedFigure = polygon;
      this.render();
    }

    if (point && this.selectedFigure) {
      this.selectedPoint = point;
      this.isMousedown = true;
      this.map.dragPan.disable();
      return this.render();
    }

    if (this.selectedFigure) {
      this.isMousedown = true;
      this.map.dragPan.disable();
      return this.render();
    }
  };

  private handleDblclick = () => {
    if (!this.isEditable || !this.isVisible) return;
    if (!this.selectedFigure || !this.pointToDelete) return;

    const indexToDelete = this.selectedFigure.points.findIndex(({ id }) => id === this.pointToDelete?.id);
    if (indexToDelete < 0) return;

    if (this.selectedFigure.points.length === 2) {
      delete this.collection[this.selectedFigure.id];
      this.pointToDelete = null;
      this.selectedPoint = null;
      this.selectedFigure = null;
      this.onChange(this.collection);
      return this.render();
    }

    if (indexToDelete === 0) {
      this.selectedFigure.points.splice(indexToDelete, 1);
      this.selectedFigure.points.splice(this.selectedFigure.points.length - 1, 1);
      this.selectedFigure.points.push({
        id: v4(),
        coordinates: [...this.selectedFigure.points[0].coordinates],
      });

      this.pointToDelete = null;
      this.onChange(this.collection);
      return this.render();
    }

    this.selectedFigure.points.splice(indexToDelete, 1);
    this.pointToDelete = null;
    this.onChange(this.collection);
    this.render();
  };

  private handleMouseup = () => {
    if (!this.isEditable || !this.isVisible) return;
    if (this.isCreating || !this.selectedFigure || !this.isMousedown) return;

    this.isMousedown = false;
    this.selectedPoint = null;
    this.map.dragPan.enable();
    this.onChange(this.collection);
  };

  /** Метод для перемещения полигона */
  private movePolygon = (point: mapboxgl.Point) => {
    if (!this.selectedFigure) return;

    const coordinates = this.getFigureCoordinates(this.selectedFigure);

    const centroid = geometric.polygonCentroid(toGeometricPolygon(coordinates));
    if (!this.ctx) return;

    const centroidPoint = this.map.project(centroid);
    const deltaX = point.x - centroidPoint.x;
    const deltaY = point.y - centroidPoint.y;

    this.selectedFigure.points = this.selectedFigure.points.map((point) => {
      const mapped = this.map.project(point.coordinates as [number, number]);
      mapped.x += deltaX;
      mapped.y += deltaY;
      const coordinates = this.map.unproject(mapped).toArray();
      return { ...point, coordinates };
    });

    this.render();
  };

  private movePoint = (lngLat: mapboxgl.LngLat) => {
    if (!this.selectedPoint || !this.selectedFigure) return;

    const indexToUpdate = this.selectedFigure.points.findIndex(({ id }) => id === this.selectedPoint?.id);
    if (typeof indexToUpdate !== "number") return;

    const coordinates = lngLat.toArray();
    this.selectedFigure.points[indexToUpdate].coordinates = coordinates;

    if (indexToUpdate === 0) {
      this.selectedFigure.points[this.selectedFigure.points.length - 1].coordinates = coordinates;
    }

    this.render();
  };

  private handleCreatingMouseMove = (event: mapboxgl.MapMouseEvent) => {
    const pointUnder = this.getPointUnder(this.creatingFigure, event.point);
    this.creatingHoverPoint = {
      id: "",
      coordinates: event.lngLat.toArray(),
    };

    this.creatingIsOnFirstHover =
      (this.creatingFigure?.points.length ?? 0) > 2 && pointUnder?.id === this.creatingFigure?.points[0]?.id;

    this.render();
  };

  private handleMouseMove = (event: mapboxgl.MapMouseEvent) => {
    if (!this.isEditable || !this.isVisible) return;

    // @note событие mousemove при создании полигона
    if (this.isCreating) {
      return this.handleCreatingMouseMove(event);
    }

    if (!this.isMousedown) return;

    // @note перемещение точек полигона
    if (this.selectedPoint && this.selectedFigure) {
      return this.movePoint(event.lngLat);
    }

    // @note перемещение полигона
    if (this.selectedFigure) {
      return this.movePolygon(event.point);
    }
  };

  private getFigureCoordinates = (figure: PolygonFigure) => {
    return figure.points.map(({ coordinates }) => coordinates);
  };

  private getPolygonUnder = (eventCoordinates: mapboxgl.LngLat) => {
    const point = eventCoordinates.toArray() as geometric.Point;
    return (
      this.arrCollection.find((figure) => {
        const coordinates = this.getFigureCoordinates(figure);
        const polygon = toGeometricPolygon(coordinates);
        return geometric.pointInPolygon(point, polygon);
      }) ?? null
    );
  };

  private isInPoint = (eventPoint: mapboxgl.Point) => (coordinates: number[]) => {
    const { x: eventX, y: eventY } = eventPoint;
    const { x: pointX, y: pointY } = this.map.project(coordinates as mapboxgl.LngLatLike);

    const half = this.editButtonSize * 2;
    const leftSide = pointX - half;
    const rightSide = pointX + half;
    const topSide = pointY - half;
    const bottomSide = pointY + half;
    const isInX = eventX >= leftSide && eventX <= rightSide;
    const isInY = eventY >= topSide && eventY <= bottomSide;

    return isInX && isInY;
  };

  private getPointUnder = (figure: PolygonFigure | null, eventPoint: mapboxgl.Point): EditPoint | undefined => {
    if (!figure) return;
    const point = figure.points.find(({ coordinates }, index) => {
      if (index === (figure?.points.length ?? 0) - 1) return false;
      return this.isInPoint(eventPoint)(coordinates);
    });

    if (!point) return;

    return {
      id: point.id,
      coordinates: point.coordinates,
    };
  };

  protected subscribe = () => {
    this.map.on("click", this.handleClick);
    this.map.on("dblclick", this.handleDblclick);
    this.map.on("mousedown", this.handleMousedown);
    this.map.on("mouseup", this.handleMouseup);
    this.map.on("mousemove", this.handleMouseMove);
    this.map.doubleClickZoom.disable();
  };

  protected unsubscribe = () => {
    this.map.off("click", this.handleClick);
    this.map.off("dblclick", this.handleDblclick);
    this.map.off("mousedown", this.handleMousedown);
    this.map.off("mouseup", this.handleMouseup);
    this.map.off("mousemove", this.handleMouseMove);
    this.map.doubleClickZoom.enable();
  };

  private renderHalfPoints = () => {
    if (!this.selectedFigure) return;

    const lastIndex = this.selectedFigure.points.length - 1;
    this.halfPoints = this.selectedFigure.points.reduce<HalfPoint[]>((acc, item, index) => {
      if (lastIndex === index) return acc;
      if (!this.selectedFigure?.points[index + 1]) return acc;

      const start = item;
      const end = this.selectedFigure.points[index + 1];
      const coordinates = geometric.lineMidpoint([
        start.coordinates as geometric.Point,
        end.coordinates as geometric.Point,
      ]);

      const halfPoint: HalfPoint = {
        start,
        coordinates,
        end,
      };

      const newAcc = [...acc, halfPoint];

      if (!this.ctx) return newAcc;

      const { x, y } = this.map.project(halfPoint.coordinates as mapboxgl.LngLatLike);

      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.globalAlpha = 1;
      this.ctx.lineWidth = 1;
      this.ctx.fillStyle = this.settings.defaultStrokeColor;
      this.ctx.strokeStyle = this.settings.baseColor;
      this.ctx.arc(x, y, this.editButtonSize / 2, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.closePath();
      this.ctx.restore();

      return newAcc;
    }, []);
  };

  public render = (clear = true) => {
    if (!this.isVisible) return;
    if (clear) {
      this.clear();
    }

    this.arrCollection.forEach(({ points }) => {
      this.drawPolygon(points.map(({ coordinates }) => coordinates));
    });

    if (this.isEditable && this.selectedFigure) {
      this.selectedFigure.points.forEach(({ coordinates }, index) => {
        if (index === (this.selectedFigure?.points.length ?? 0) - 1) return;
        this.drawEditPoint(this.map.project(coordinates as mapboxgl.LngLatLike));
      });

      this.renderHalfPoints();
    }

    if (this.isCreating && this.creatingFigure) {
      let coordinates = this.creatingFigure.points.map(({ coordinates }) => coordinates);
      const hoverCoordinates = this.creatingHoverPoint?.coordinates;

      if (!this.creatingIsOnFirstHover && hoverCoordinates) {
        coordinates.push(hoverCoordinates);
      }

      this.drawPolygon(coordinates);
      coordinates.forEach((lngLat) => this.drawEditPoint(this.map.project(lngLat as mapboxgl.LngLatLike)));
    }
  };

  public setData = (data: PolygonCollection) => {
    this.collection = data;
    this.selectedFigure = this.arrCollection.find(({ id }) => id === this.selectedFigure?.id) ?? null;
    this.render();
  };

  public create = () => {
    this.isCreating = true;
    this.selectedPoint = null;
    this.selectedFigure = null;
    this.creatingFigure = {
      id: v4(),
      type: "Polygon",
      points: [],
    };
    this.render();
  };
}
