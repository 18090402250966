import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { sectorAnalysisSlice } from "features/sector-analysis/store";
import { GState } from "documentations";
import * as consts from "./segments-units-count-slider.consts";

export const useMinMaxFilterValues = () =>
  useSelector((state: GState) => state.sectorAnalysis.minMaxUnitsCount3DFilter, shallowEqual);

export const useMinMax = () =>
  useSelector(
    (state: GState) => [
      state.sectorAnalysis.current.diagram?.minUnitsPerCell ?? 0,
      state.sectorAnalysis.current.diagram?.maxUnitsPerCell ?? 0,
    ],
    shallowEqual
  );

export const useValues = () => {
  const minMaxFilter = useMinMaxFilterValues();
  const minMax = useMinMax();

  return React.useMemo(() => [minMax[0], ...minMaxFilter, minMax[1]], [minMax, minMaxFilter]);
};

export const useHandleChange = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (values: number[]) => {
      const [, min, max] = values;
      dispatch(sectorAnalysisSlice.actions.setMinMaxUnitsCount3DFilter([min, max]));
    },
    [dispatch]
  );
};

export const useLabels = (values: number[]) => {
  return React.useMemo(
    () =>
      values.reduce((acc, value, index) => {
        const emptyLabel = { ...acc };
        const defaultLabel = { ...acc, [value]: <div className={consts.labelFontClass}>{value}</div> };

        if (index === 0 || index === values.length - 1) return defaultLabel;
        if (Math.abs(values[0] - value) <= consts.intersectionDelta) return emptyLabel;
        if (Math.abs(values[index + 1] - value) <= consts.intersectionDelta) return emptyLabel;

        return defaultLabel;
      }, {}),
    [values]
  );
};
