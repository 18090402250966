import * as turf from "@turf/turf";
import TrafficProperties from "map-helpers/properties/traffic-properties";

function getWidth(length, coordinates) {
  const line = turf.lineString(coordinates);
  const elemLength = turf.length(line);
  const ratio = elemLength / length;
  const width = ratio * 100;
  return width;
}

export function getLengthStyle(el, variant) {
  const length = variant.length;
  const { coordinates } = el.geometry;
  const { traffic } = el.properties;
  const width = getWidth(length, coordinates);
  const properties = new TrafficProperties();
  const colors = properties.getTrafficColors();

  const backgroundColor = colors[traffic];
  const style = {
    height: "4px",
    backgroundColor,
    width,
  };
  return style;
}
