import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { UrlParams } from "shared";
import { AdministrativeDivisionTypes } from "types";
import { Broker } from "./broker";

export class UrlParamsBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);

    this.subscribe();
  }

  private initializeTransportHubsVisibility = () => {
    const value = Boolean(Number(UrlParams._.get("transportHubs")));
    this.mapController.transportHubs?.setVisibility(value);
    this.uiStore.transportHubs.events.initializeIsVisible(value);
  };

  private initializePedestrianGraphVisibility = () => {
    const value = Boolean(Number(UrlParams._.get("pedestrianGraph")));
    this.mapController.pedestrianGraph?.setVisibility(value);
    this.uiStore.pedestrianGraph.events.initializeIsVisible(value);
  };

  private initializeAdministrativeDivisionActiveType = () => {
    const value = UrlParams._.get("administrativeDivision");
    if (!value) return;

    this.uiStore.administrativeDivision.events.initializeActiveType(value as AdministrativeDivisionTypes.Type);
    this.mapController.administrativeDivision?.setVisibility(value as AdministrativeDivisionTypes.Type);
  };

  private initializeBusStopsVisibility = () => {
    const value = Boolean(Number(UrlParams._.get("busStops")));
    this.mapController.busStops?.setVisibility(value);
    this.uiStore.busStops.events.initializeIsVisible(value);
  };

  private handleMapControllerLoad = () => {
    this.initializeBusStopsVisibility();
    this.initializeTransportHubsVisibility();
    this.initializePedestrianGraphVisibility();
    this.initializeAdministrativeDivisionActiveType();
  };

  private handleTransportHubsVisibilityChange = (state: GState.TransportHubsState) => {
    if (state.isVisible) {
      return UrlParams._.set("transportHubs", String(Number(state.isVisible)));
    }

    UrlParams._.delete("transportHubs");
  };

  private handlePedestrianGraphVisibilityChange = (state: GState.PedestrianGraphState) => {
    if (state.isVisible) {
      return UrlParams._.set("pedestrianGraph", String(Number(state.isVisible)));
    }

    UrlParams._.delete("pedestrianGraph");
  };

  private handleAdministrativeDivisionActiveTypeChange = (activeType: AdministrativeDivisionTypes.Type) => {
    if (activeType === AdministrativeDivisionTypes.Type.unset) {
      return UrlParams._.delete("administrativeDivision");
    }

    UrlParams._.set("administrativeDivision", activeType);
  };

  private handleBusStopsVisibilityChange = (state: GState.BusStopsState) => {
    if (state.isVisible) {
      return UrlParams._.set("busStops", String(Number(state.isVisible)));
    }

    UrlParams._.delete("busStops");
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapControllerLoad);
    this.uiStore.transportHubs.events.handleIsVisibleChange.watch(this.handleTransportHubsVisibilityChange);
    this.uiStore.pedestrianGraph.events.handleIsVisibleChange.watch(this.handlePedestrianGraphVisibilityChange);
    this.uiStore.administrativeDivision.events.handleActiveTypeChange.watch(
      this.handleAdministrativeDivisionActiveTypeChange
    );
    this.uiStore.busStops.events.handleIsVisibleChange.watch(this.handleBusStopsVisibilityChange);
  };
}
