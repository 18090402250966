import { VectorTileLayer } from "map-helpers/new-layer/vector-tile-layer";
import { ND_DETECTOR_MAP_IMAGE_KEY } from "map-helpers/assets/map-svg-icons";
import getBeforeId from "map-helpers/order-layers";

export class NoneDirectionDetectorLayer extends VectorTileLayer {
  private opacity = 1;

  protected addLayer() {
    const layer: mapboxgl.SymbolLayer = {
      "id": this.layerId,
      "type": "symbol",
      "source": this.sourceId,
      "source-layer": "detector",
      "layout": {
        "icon-image": ND_DETECTOR_MAP_IMAGE_KEY,
        "icon-size": this.getDefaultIconSize(),
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
      },
      "paint": {
        "icon-opacity": this.opacity,
      },
    };
    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  protected addSource(url: string) {
    this.map.addSource(this.sourceId, {
      type: "vector",
      tiles: [url],
    });
  }

  protected addActiveLayer() {
    const layer: mapboxgl.AnyLayer = {
      "id": this.activeLayerId,
      "type": "circle",
      "source": this.sourceId,
      "source-layer": "nd-detector",
      "paint": {
        "circle-stroke-color": "blue",
        "circle-stroke-opacity": 0.5,
        "circle-stroke-width": this.getDefaultCircleStroke(),
        "circle-radius": this.getDefaultCircleRadius(),
        "circle-opacity": 0,
        "circle-pitch-alignment": "map",
      },
      "filter": ["boolean", false],
    };
    const beforeId = getBeforeId(this.activeLayerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  protected addHoverLayer() {
    const layer: mapboxgl.AnyLayer = {
      "id": this.hoverLayerId,
      "type": "circle",
      "source": this.sourceId,
      "source-layer": "nd-detector",
      "paint": {
        "circle-stroke-color": "cyan",
        "circle-stroke-opacity": 0.7,
        "circle-stroke-width": this.getDefaultCircleStroke(),
        "circle-radius": this.getDefaultCircleRadius(),
        "circle-opacity": 0,
        "circle-pitch-alignment": "map",
      },
      "filter": ["boolean", false],
    };
    const beforeId = getBeforeId(this.hoverLayerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  public setOpacity = (isVisible: boolean) => {
    this.opacity = isVisible ? 1 : 0;
    const layer = this.map.getLayer(this.layerId);
    if (!layer) return new Error();
    this.map.setPaintProperty(this.layerId, "icon-opacity", this.opacity);
  };

  public setUrl = (url: string) => {
    const source = this.map.getSource(this.sourceId) as mapboxgl.VectorSourceImpl;
    if (!source) return;
    source.tiles = [url];

    this.remove();
    this.add(url);
  };
}
