import mapboxgl from "mapbox-gl";
import { shared } from "shared";

const size = 2;
const defaultStyle = shared.map.defaultStyle.style;

export namespace Utils {
  export const getStyleById = (id?: string) => {
    return shared.map.mapStyles?.find((el) => el.id === id)?.style ?? defaultStyle;
  };

  export const getFeatureBoxNegative = (point: mapboxgl.Point): mapboxgl.PointLike => [
    point.x - size / 2,
    point.y - size / 2,
  ];

  export const getFeatureBoxPositive = (point: mapboxgl.Point): mapboxgl.PointLike => [
    point.x + size / 2,
    point.y + size / 2,
  ];

  type FeatureBox = [mapboxgl.PointLike, mapboxgl.PointLike];

  export const getFeatureBox = (point: mapboxgl.Point): FeatureBox => {
    return [getFeatureBoxNegative(point), getFeatureBoxPositive(point)];
  };
}
