import * as React from "react";
import { formatTemperature } from "../utils/format-temperature";

interface Props {
  /** Заголовок */
  title: string;
  /** Тип погоды */
  weatherType?: number | null;
  /** Массив изображений */
  imagesWeather: Array<string>;
  /** Прогноз или нет */
  isForecast: boolean;
  /** Начальный диапазон температуры */
  temperatureFrom?: number | null;
  /** Конечный диапазон температуры */
  temperatureTo?: number | null;
  /** Атмосферное давление */
  pressure?: number | null;
  /** Направление ветра */
  windDirection?: number | null;
  /** Скорость ветра */
  avgWindSpeed?: number | null;
  /** Порывы ветра */
  windGusts?: number | null;
}

/** Строка с изображением данных погоды */
const WeatherRow: React.FC<Props> = ({
  title,
  weatherType,
  imagesWeather,
  isForecast,
  temperatureFrom,
  temperatureTo,
  pressure,
  windDirection,
  avgWindSpeed,
  windGusts,
}) => {
  const temperature = formatTemperature(temperatureFrom, temperatureTo, isForecast);
  return (
    <>
      <div className="cwp-data-fact-header">
        <div className="cwp-data-fact-header-text">{title}</div>
        <div>{weatherType && <img src={imagesWeather[weatherType]} alt="Тип погоды" />}</div>
      </div>
      <div className="cwp-header-data">
        <div>{temperature && <span>{temperature}</span>}</div>
        <div>
          {pressure} {pressure ? "мм" : ""}
        </div>
        <div>
          {!windDirection || windDirection === 0 || windDirection === 990 ? null : (
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{
                transform: `rotate(-${windDirection + 45}deg)`,
                verticalAlign: "middle",
              }}>
              <g opacity="0.87">
                <g transform="translate(4.001 6)">
                  <path
                    d="M13.725.053a.5.5,0,0,0-.448,0h0l-13,6.5a.5.5,0,0,0,.134.939L5.57,8.43l.938,5.159a.5.5,0,0,0,.42.405A.488.488,0,0,0,7,14a.5.5,0,0,0,.447-.276l6.5-13A.5.5,0,0,0,13.725.053Zm-6.5,11.882L6.492,7.91a.5.5,0,0,0-.4-.4L2.066,6.776,12.382,1.618Z"
                    transform="translate(-0.001 0)"
                  />
                </g>
              </g>
            </svg>
          )}
          {avgWindSpeed} {avgWindSpeed ? "м/с" : ""}
        </div>
        <div>
          {windGusts} {windGusts ? "м/с" : ""}
        </div>
      </div>
    </>
  );
};

WeatherRow.defaultProps = {
  weatherType: null,
  temperatureFrom: null,
  temperatureTo: null,
  pressure: null,
  windDirection: null,
  avgWindSpeed: null,
  windGusts: null,
};

export default WeatherRow;
