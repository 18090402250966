import React, { CSSProperties, useMemo } from "react";
import { SliderLabel } from "../components/sector-analysis-appearance-slider/slider-label";

export const getPercentageLabel = (percentage: number) => `${percentage}%`;
export const getSpeedLabel = (speed: number) => `${speed} км/ч`;

export const speedLabelStyles: CSSProperties = {
  fontWeight: "normal",
  fontSize: "10px",
  lineHeight: "12px",
  opacity: 0.54,
};

export const getOffsetX = (percentage: number) => (percentage * 100) / 150;

export const usePercentageLabels = (values: DiagramAppearance) =>
  useMemo(() => {
    const offsets: number[] = [];
    const labels = values
      .filter(({ label }) => !!label)
      .map(({ key, value }, index) => {
        const offset = getOffsetX(value);
        const nextItem = values[index + 2];
        const defaultLabel = <SliderLabel title={getPercentageLabel(value)} key={key} offsetX={offset} />;

        if (nextItem && Math.abs(nextItem.value - value) <= 10) return null;

        offsets.push(offset);
        return defaultLabel;
      });
    return {
      offsets,
      labels,
    };
  }, [values]);

export const useSpeedLabels = (values: DiagramAppearance, freeFlowSpeed: number) =>
  useMemo(
    () =>
      values
        .filter(({ label }) => !!label)
        .map(({ key, value }, index) => {
          const title = getSpeedLabel(Math.floor((value * freeFlowSpeed) / 150));
          const nextItem = values[index + 2];
          const defaultLabel = (
            <SliderLabel labelStyle={speedLabelStyles} title={title} key={key} offsetX={getOffsetX(value)} />
          );

          if (nextItem && Math.abs(nextItem.value - value) <= 10) return null;

          return defaultLabel;
        }),
    [values, freeFlowSpeed]
  );
