export enum Mode {
  BUTTON = "BUTTON",
  SELECT = "SELECT",
}

export enum SelectorKey {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  BEFORE_YESTERDAY = "BEFORE_YESTERDAY",
  SPLW = "SPLW",
  SPLM = "SPLM",
  SPLY = "SPLY",
}

export type Option = { value: SelectorKey; label: string };
