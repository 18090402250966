import React, { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { SectorAnalysisAppearanceMenu } from "features/sector-analysis/components/sector-analysis-appearance-menu";
import {
  defaultSettings,
  useGetAppearance,
  useSectorAnalyseContext,
  ValuesTuple,
} from "features/sector-analysis/components/sector-analysis/context";

const useIsAppearanceMenu = () => useSelector((store: GState) => store.sectorAnalysis.isAppearanceMenu);
const useTotal = () => useSelector((store: GState) => store.sectorAnalysis.current.total, shallowEqual);
const useIsShowLegend = () => useSelector((store: GState) => store.sectorAnalysis.isShowLegend);
const useIsShowEvents = () => useSelector((store: GState) => store.sectorAnalysis.isShowEvents);
const useIsUnitsCountDiagram = () => useSelector((store: GState) => store.sectorAnalysis.isUnitsCountDiagram);
const useIsVolumetric = () => useSelector((store: GState) => store.sectorAnalysis.isVolumetric);

const useOptions = () => {
  const dispatch = useDispatch();
  const { settingSpeedColor } = useSectorAnalyseContext();
  const appearance = useGetAppearance();
  const total = useTotal();
  const isShowLegend = useIsShowLegend();
  const isShowEvents = useIsShowEvents();
  const isUnitsCountDiagram = useIsUnitsCountDiagram();
  const isVolumetric = useIsVolumetric();

  const isRestoreAppearanceDisabled = useMemo(
    () =>
      !appearance?.reduce((acc, item, index) => {
        if (acc) return acc;
        if (appearance[index].value !== defaultSettings.diagramAppearance[index].value) {
          acc = true;
        }
        return acc;
      }, false),
    [appearance, defaultSettings.diagramAppearance]
  );

  const settings = useMemo(
    () => ({ total, appearance, isShowLegend, isShowEvents, isUnitsCountDiagram, isVolumetric }),
    [total, appearance, isShowLegend, isShowEvents, isUnitsCountDiagram, isVolumetric]
  );

  const handleIsShowEventChange = useCallback(() => {
    dispatch(sectorAnalysisSlice.actions.setIsShowEvents(!isShowEvents));
  }, [isShowEvents, dispatch]);

  const handleIsLegendChange = useCallback(
    (value: boolean) => dispatch(sectorAnalysisSlice.actions.setIsShowLegend(value)),
    [dispatch]
  );

  const handleRangeChange = useCallback(
    (value: number[]) => {
      new Promise(() => {
        settingSpeedColor.update(value as ValuesTuple);
      });
    },
    [settingSpeedColor]
  );

  const handleRestoreAppearance = useCallback(() => {
    settingSpeedColor.reset();
  }, [dispatch]);

  return {
    isRestoreAppearanceDisabled,
    settings,
    handleRangeChange,
    handleIsLegendChange,
    handleRestoreAppearance,
    handleIsShowEventChange,
  };
};

export const SettingPopup = () => {
  const isAppearanceMenu = useIsAppearanceMenu();
  const options = useOptions();

  if (!isAppearanceMenu) return null;

  return (
    <div className="sector-analysis-appearance-tooltip">
      <SectorAnalysisAppearanceMenu {...options} />
    </div>
  );
};
