import { createGlobalStyle } from "styled-components";
import { APP_ENV } from "../app-env";

const cdn = APP_ENV.REACT_APP_DTM_MEDIA.split("integration")[0];

export const IconsGlobalStyled = createGlobalStyle`
  @font-face {
    font-family: "dtm";
    src: url(${cdn + "dtm-web/fonts/icomoon.eot?8nkym2"});
    src: url(${cdn + "dtm-web/fonts/icomoon.eot?8nkym2#iefix"}) format("embedded-opentype"),
      url(${cdn + "dtm-web/fonts/icomoon.ttf?8nkym2"}) format("truetype"),
      url(${cdn + "dtm-web/fonts/icomoon.woff?8nkym2"}) format("woff"),
      url(${cdn + "dtm-web/fonts/icomoon.svg?8nkym2#icomoon"}) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  i[class*="dtm-"][class*="-icon"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "dtm" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .dtm-drag-n-drop-icon::before {
    content: "\\e900";
  }

  .dtm-blocking-icon::before {
    content: "\\e901";
  }

  .dtm-camera-icon::before {
    content: "\\e902";
  }

  .dtm-close-icon::before {
    content: "\\e903";
  }

  .dtm-dtpmap-icon::before {
    content: "\\e904";
  }

  .dtm-graph-icon::before {
    content: "\\e905";
  }

  .dtm-layers-icon::before {
    content: "\\e906";
  }

  .dtm-minus-icon::before {
    content: "\\e907";
  }

  .dtm-plus-icon::before {
    content: "\\e908";
  }

  .dtm-route-icon::before {
    content: "\\e909";
  }

  .dtm-ruler-icon::before {
    content: "\\e90a";
  }

  .dtm-statistics-icon::before {
    content: "\\e90c";
  }

  .dtm-tls-icon::before {
    content: "\\e90d";
  }

  .dtm-arrow-down-icon::before {
    content: "\\e90e";
  }

  .dtm-checkbox-icon::before {
    content: "\\e90f";
  }

  .dtm-time-icon::before {
    content: "\\e910";
  }

  .dtm-exchange-icon::before {
    content: "\\e911";
  }

  .dtm-back-arrow-icon::before {
    content: "\\e912";
  }

  .dtm-location-icon::before {
    content: "\\e913";
  }

  .dtm-bus-side-icon::before {
    content: "\\e914";
  }

  .dtm-reset-icon::before {
    content: "\\e919";
  }

  .dtm-arrow-left-icon::before {
    content: "\\e91a";
  }

  .dtm-arrow-right-icon::before {
    content: "\\e91b";
  }

  .dtm-tls2-icon::before {
    content: "\\e91c";
  }

  .dtm-favorite-icon::before {
    content: "\\e94f";
  }

  .dtm-favorite-apply-icon::before {
    content: "\\e98d";
  }

  .dtm-add-icon::before {
    content: "\\e98e";
  }

  .dtm-edit-icon::before {
    content: "\\e98f";
  }

  .dtm-car-icon::before {
    content: "\\e990";
  }

  .dtm-last-address-icon::before {
    content: "\\e991";
  }

  .dtm-correlation-icon::before {
    content: "\\e915";
  }

  .dtm-points-icon::before {
    content: "\\e916";
  }

  .dtm-headmap-icon::before {
    content: "\\e917";
  }

  .dtm-ngpt-icon::before {
    content: "\\e918";
  }

  .dtm-highway-icon::before {
    content: "\\e91e";
  }

  .dtm-dedicated-icon::before {
    content: "\\e91f";
  }
`;
