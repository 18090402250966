export namespace BusStopsTypes {
  // А = автобус
  // Э = электробус
  // Тм = трамвай
  // Тб = троллейбус
  export type PopupRoute = {
    id?: number;
    name: string;
    num?: string;
    color: string;
    transportType: "А" | "Э" | "Тм" | "Тб";
  };

  export type PopupProps = {
    id?: number;
    name?: string;
    time?: number;
    boardId?: number;
    routes?: PopupRoute[];
  };

  export type FeatureProperties = {
    id: number;
    angle: number;
    name: string;
    time: number;
  };
}
