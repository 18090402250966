import axios, { Canceler } from "axios";
import * as TYPES from "./model/models";
import { APP_ENV } from "app-env";

const { CancelToken } = axios;

const telemetryApi = axios.create({
  baseURL: `${APP_ENV.REACT_APP_DTM_TRAFFIC_API}telemetry/`,
});
let positionsCancel: Canceler | null = null;

export const telemetry = {
  /** Получить трек из позиций в виде GeoJSON; Запрос кешируется на день, если период не пересекается с текущей датой(находится в прошлом), и на минуту, если пересекается. */
  track: async (model: TYPES.TelemetryTrackInput, token: string): Promise<GeoJSON.FeatureCollection> => {
    const { from, to, es, u } = model;

    const params = new URLSearchParams();
    params.set("from", String(from));
    params.set("to", String(to));
    params.set("es", String(es));
    params.set("u", String(u));

    const { data } = await telemetryApi.get("track", {
      headers: { Authorization: `bearer ${token}` },
      params,
    });

    return data;
  },
  /** Получить позиции, ограничение на получение - максимально 2 000 000 позиций; Запрос кешируется на день, если период не пересекается с текущей датой(находится в прошлом), и на минуту, если пересекается. */
  positions: async (model: TYPES.TelemetryTrackInput, token: string): Promise<Array<TYPES.FullPosition>> => {
    const { from, to, es, u } = model;

    if (positionsCancel) positionsCancel();

    const params = new URLSearchParams();
    params.set("from", String(from));
    params.set("to", String(to));
    params.set("es", String(es));
    params.set("u", String(u));

    const { data } = await telemetryApi.get("positions", {
      headers: { Authorization: `bearer ${token}` },
      params,
      cancelToken: new CancelToken((c) => {
        positionsCancel = c;
      }),
    });

    return data;
  },
  /** Получить позиции, ограничение на получение - максимально 2 000 000 позиций; Запрос кешируется на день, если период не пересекается с текущей датой(находится в прошлом), и на минуту, если пересекается. */
  rnisPositions: async (
    model: TYPES.RnisTelemetryTrackInput,
    token: string
  ): Promise<Array<TYPES.RnisFullPosition>> => {
    const { from, to, om_id } = model;

    if (positionsCancel) positionsCancel();

    const params = new URLSearchParams();
    params.set("dateStart", String(from));
    params.set("dateEnd", String(to));
    params.set("vehicleId", String(om_id));

    const { data } = await telemetryApi.get(
      `${APP_ENV.REACT_APP_DOMAIN_API}rnis/external/v2/teledata/vehiclepositions`,
      {
        headers: { Authorization: `bearer ${token}` },
        params,
        cancelToken: new CancelToken((c) => {
          positionsCancel = c;
        }),
      }
    );

    return data;
  },
};
