import React from "react";
import styled from "styled-components";
import { useStoreContext } from "store";
import { CaptionGroup } from "./caption-group";

export const GraphCardBody = () => {
  const {
    store: {
      graphCard: { hooks },
    },
  } = useStoreContext();

  const edgeInfo = hooks.useGetTransformEdgeInfo();

  return (
    <CardBodyBlock>
      <CaptionGroup groupLabel={edgeInfo.groupLabel} items={edgeInfo.items} />
    </CardBodyBlock>
  );
};

const CardBodyBlock = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 23px;
`;
