import { Middleware, MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { updateStateInUrl } from "utils/update-state-in-url";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { DtpController } from "../controller";
import { dtpSlice } from "./slice";

let _store: MiddlewareAPI<any, GState>;
let controller: DtpController;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  controller = new DtpController(map, _store);
});

export const dtpMiddleware: Middleware<GState> = (store) => (next) => async (action) => {
  next(action);

  if (!_store) _store = store;

  if (dtpSlice.actions.setIsActive.match(action)) {
    controller.update();
    updateStateInUrl("dtp", action.payload);
  }
};
