import * as effector from "effector";
import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { IStore } from "../store.types";
import { Events, events } from "./travel-heatmap.events";
import { Hooks, hooks } from "./travel-heatmap.hooks";
import { Effects, effects } from "./travel-heatmap.effects";
import * as reducers from "./travel-heatmap.reducers";
import * as utils from "./travel-heatmap.utils";
import { State } from "./travel-heatmap.types";
import { APP_ENV } from "app-env";

const defaultParams = TravelHeatmapTypes.FilterParams.getDefault();
const initialState: State = {
  isDisabled: true,
  isActive: false,
  activeItem: [],
  hoverItem: null,
  colorPrice: 0,
  isCorrespondenceMatrix: false,
  from: defaultParams.dateStart,
  to: defaultParams.dateEnd,
  directionMode: shared.travelHeatmap.directionModeOptions[0],
  transportType: shared.travelHeatmap.transportTypesOptions[0],
  sectorsInformation: {},
  isLoading: false,
  maxTrips: 0,
  minMaxFilter: [0, 1000000000],
  weekdays: defaultParams.weekdays,
  timeStart: 0,
  timeEnd: 24,
  graphicData: {
    days: [],
    hours: [],
  },
};

export class Store implements IStore<State, Events, Hooks, Effects> {
  public readonly store = effector.createStore(initialState);
  public readonly events = events;
  public readonly hooks = hooks;
  public readonly effects = effects;

  public readonly destroy = () => {
    console.info("destroy");
  };
}

export const travelHeatmapStore = new Store();

const { store } = travelHeatmapStore;

store.on(events.mapLoaded, reducers.handleMapLoaded);
store.on(events.setWeekdays, reducers.handleSetWeekdays);
store.on(events.setIsLoading, reducers.handleSetIsLoading);
store.on(events.setIsDisabled, reducers.handleSetIsDisabled);
store.on(events.addSectorInfo, reducers.handleAddSectorInfo);
store.on(events.setColorPrice, reducers.handleSetColorPrice);
store.on(events.setMinMaxFilter, reducers.handleSetMinMaxFilter);
store.on(events.handlePeriodChange, reducers.handlePeriodChange);
store.on(events.handleHoverItemChange, reducers.handleSetHoverItem);
store.on(events.handleTimeRangeChange, reducers.handleSetTimeRange);
store.on(events.handleCardCloseClick, reducers.handleCardCloseClick);
store.on(events.handleActiveItemChange, reducers.handleSetActiveItem);
store.on(events.restoreActiveItems, reducers.handleRestoreActiveItems);
store.on(events.handleListHoverItemChange, reducers.handleSetHoverItem);
store.on(effects.loadGraphicsData.doneData, reducers.handleGraphicsData);
store.on(events.handleTravelHeatmapClick, reducers.handleSetNextIsActive);
store.on(effects.loadStatisticMax.doneData, reducers.handleSetStatisticMax);
store.on(events.handleDirectionModeChange, reducers.handleDirectionModeChange);
store.on(events.handleTransportTypeChange, reducers.handleTransportTypeChange);
store.on(effects.loadCorrespondenceMax.doneData, reducers.handleSetStatisticMax);
store.on(events.handleIsCorrespondenceMatrixChange, reducers.handleSetNextIsCorrespondenceMatrix);

events.handleMissingStatistic.watch(effects.loadMissingSectorInfo);

const isShowTravelHeatmap = !Boolean(APP_ENV.REACT_APP_CONFIGURATION?.rnisServices?.analytics === "hidden");

if (isShowTravelHeatmap) {
  effector.sample({
    source: store,
    clock: events.mapLoaded,
    fn: utils.createRequestStatisticPayload,
    target: effects.loadStatisticMax,
  });
}

effector.sample({
  source: store,
  clock: events.handleTravelHeatmapClick,
  target: events.handleTravelHeatmapIsActiveChange,
});

effector.sample({
  source: store,
  clock: events.handleActiveItemChange,
  fn: utils.createRequestStatisticPayload,
  target: effects.loadGraphicsData,
});

effector.sample({
  clock: effects.loadGraphicsData.pending,
  target: events.setIsLoading,
});

effector.sample({
  clock: effects.loadStatisticMax.pending,
  target: events.setIsLoading,
});

effector.sample({
  clock: effects.loadCorrespondenceMax.pending,
  target: events.setIsLoading,
});

effector.sample({
  source: store,
  clock: events.handleActiveItemChange,
  fn: async (source: State) => {
    if (!source.activeItem.length) return;
    const lastItem = source.activeItem[source.activeItem.length - 1];
    if (!lastItem) return;
    const information = source.sectorsInformation[lastItem.h3Index];
    if (information) return;

    events.setIsLoading(true);
    const coordinates = shared.travelHeatmap.getCentroid(lastItem.h3Index);

    const response = await effects.loadSectorInfo({
      h3Index: lastItem.h3Index,
      coordinates,
    });

    if (response instanceof Error) return;
    events.addSectorInfo(response);
  },
});

/** Логика фильрации и перезапрашивания запросов */

let timeRangeLoadTimeout: number;
const handleLoadMax = effector.createEvent<boolean>();
const loadDefaultMax = effector.createEvent();
const loadCorrespondenceMax = effector.createEvent();
const loadStatisticsMaxWithDelayEffect = effector.createEffect(() => {
  window.clearTimeout(timeRangeLoadTimeout);
  return new Promise((resolve) => {
    timeRangeLoadTimeout = window.setTimeout(() => resolve(1), 500);
  });
});

effector.split({
  source: handleLoadMax,
  match: {
    default: (value: boolean) => !value,
    correspondence: (value: boolean) => value,
  },
  cases: {
    default: loadDefaultMax,
    correspondence: loadCorrespondenceMax,
  },
});

effector.sample({
  source: store,
  clock: loadDefaultMax,
  fn: utils.getLoadPayload,
  target: effects.loadStatisticMax,
});

effector.sample({
  source: store,
  clock: loadCorrespondenceMax,
  fn: utils.getLoadPayload,
  target: effects.loadCorrespondenceMax,
});

effector.sample({
  source: store,
  clock: events.handleTravelHeatmapClick,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

effector.sample({
  source: store,
  clock: events.setWeekdays,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

effector.sample({
  source: store,
  clock: events.handleTimeRangeChange,
  target: loadStatisticsMaxWithDelayEffect,
});

effector.sample({
  source: store,
  clock: loadStatisticsMaxWithDelayEffect.doneData,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

effector.sample({
  source: store,
  clock: events.handleTransportTypeChange,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

effector.sample({
  source: store,
  clock: events.handleDirectionModeChange,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

effector.sample({
  source: store,
  clock: events.handlePeriodChange,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

effector.sample({
  source: store,
  clock: events.handleIsCorrespondenceMatrixChange,
  fn: utils.getIsCorrespondence,
  target: handleLoadMax,
});

/** ------------------Семпл для передачи цены деления в карту------------------------ */

effector.sample({
  source: store,
  clock: effects.loadStatisticMax.doneData,
  fn: (source) => {
    return Math.round(source.maxTrips / (Object.values(shared.travelHeatmap.colorPalette).length - 1));
  },
  target: events.setColorPrice,
});

/** -------------------Сэмплы для обновления урлов в тайлах------------------------ */

effector.sample({
  source: store,
  clock: loadDefaultMax,
  fn: utils.prepareFilterPrams,
  target: events.handleFilterChange,
});

effector.sample({
  source: store,
  clock: loadCorrespondenceMax,
  fn: utils.prepareFilterPrams,
  target: events.handleFilterChange,
});

/** -------------------------------------------- */
