import { useStore } from "effector-react";
import { store } from "./transport-hubs";

const useFull = () => useStore(store);
const useIsVisible = () => useStore(store).isVisible;
const useIsDisabled = () => useStore(store).isDisabled;

export const hooks = {
  useFull,
  useIsVisible,
  useIsDisabled,
};

export type Hooks = typeof hooks;
