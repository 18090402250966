import getBeforeId, { GRAPH_EDGES_HIGHLIGHT_ID, GRAPH_SOURCE_ID } from "../order-layers";
import Layer from "./layer";

const layerId = GRAPH_EDGES_HIGHLIGHT_ID;

export default class GraphEdgesHighlightLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  addLayer() {
    const layer = {
      "id": GRAPH_EDGES_HIGHLIGHT_ID,
      "type": "line",
      "source": GRAPH_SOURCE_ID,
      "source-layer": "edges",
      "minzoom": 15,
      "layout": {
        "line-join": "round",
        "line-cap": "round",
      },
      "paint": {
        "line-color": [
          "match",
          ["get", "classification"],
          0,
          "#ff8282",
          1,
          "#ff80b0",
          2,
          "#ff80df",
          3,
          "#f080ff",
          4,
          "#bb80ff",
          5,
          "#bb80ff",
          6,
          "#8c82ff",
          7,
          "#82daff",
          "#82daff",
        ],
        "line-gap-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          5,
          ["match", ["get", "oneWay"], 0, 0.75, 1, 1.5, 0],
          12,
          ["match", ["get", "oneWay"], 0, 1.5, 1, 3, 0],
          18,
          ["match", ["get", "oneWay"], 0, 2, 1, 4, 0],
        ],
        "line-offset": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          5,
          ["match", ["get", "oneWay"], 0, 0.75, 1, 0, 0],
          12,
          ["match", ["get", "oneWay"], 0, 1.5, 1, 0, 0],
          18,
          ["match", ["get", "oneWay"], 0, 3, 1, 0, 0],
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          5,
          ["match", ["get", "oneWay"], 0, 1.125, 1, 1.8, 0],
          12,
          ["match", ["get", "oneWay"], 0, 1.83, 1, 3.6, 0],
          18,
          ["match", ["get", "oneWay"], 0, 2.4, 1, 4.8, 0],
        ],
        "line-opacity": 0.8,
      },
      "filter": ["==", "edgeID", "none"],
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }
}
