import { AnalysisDiagramOptions, AnalysisType, DiagramEvent, UnitsCountDiagramParams } from "../../../types";
import { AnalysisGraphController } from "./graph";
import { DiagramController } from "./diagram";
import { Dispatcher } from "./dispatcher";

type Parents = {
  graph: HTMLDivElement | null;
  diagram: HTMLDivElement | null;
};

export class AnalysisDiagramController {
  private options?: AnalysisDiagramOptions;
  private graphParent?: HTMLDivElement | null;
  private graphCanvas?: HTMLCanvasElement;
  private isGraphInit = false;
  private graph?: AnalysisGraphController;
  private diagramParent?: HTMLDivElement | null;
  private diagramCanvas?: HTMLCanvasElement;
  private isActiveHeatMap?: boolean;
  private isDiagramInit = false;
  public diagram?: DiagramController;
  private dispatcher?: Dispatcher;

  constructor({ graph, diagram }: Parents, options: AnalysisDiagramOptions) {
    this.graphParent = graph;
    this.diagramParent = diagram;
    this.options = options;
    this.dispatcher = new Dispatcher();

    this.createComponents();
  }

  private handleZoomX = (scaleX: number, offsetX: number) => {
    this.graph?.updateOffset(offsetX);
    this.graph?.updateScale(scaleX);
  };

  private addCanvas = (parent?: HTMLElement | null, canvas?: HTMLCanvasElement) => {
    if (parent && canvas) parent.appendChild(canvas);
  };

  private createGraph = () => {
    if (!this.options) return;
    if (!this.graphCanvas) this.graphCanvas = document.createElement("canvas");

    if (this.graphParent) {
      this.graphParent.innerHTML = "";
    }

    this.addCanvas(this.graphParent, this.graphCanvas);

    this.graph = new AnalysisGraphController(this.graphCanvas, this.options);
    this.graph.draw();
    this.isGraphInit = true;
  };

  private createDiagram = () => {
    if (!this.options) return;
    if (!this.diagramCanvas) this.diagramCanvas = document.createElement("canvas");

    this.addCanvas(this.diagramParent, this.diagramCanvas);

    this.diagram = new DiagramController(this.diagramCanvas, this.options);

    if (this.isActiveHeatMap) {
      this.diagram.setIsActiveHeatMap(this.isActiveHeatMap, true);
    }

    this.diagram.draw();
    this.isDiagramInit = true;
  };

  private destroyGraph = () => {
    this.graph?.destroy();
    this.graphCanvas?.remove();
    this.isGraphInit = false;
  };

  public readonly destroyDiagram = () => {
    this.diagram?.destroy();
    this.diagramCanvas?.remove();
    this.isDiagramInit = false;
  };

  private createComponents = () => {
    if (this.isGraphInit) this.destroyGraph();
    this.createGraph();

    if (this.isDiagramInit) this.destroyDiagram();
    this.createDiagram();
  };

  private handleSyncCrosshair = (x: number) => {
    this.graph?.draw();
    this.graph?.drawCrosshair(x);
  };

  public readonly setOptions = (options: AnalysisDiagramOptions, isCompare: boolean) => {
    this.createComponents();
    this.dispatcher?.on(`${DiagramEvent.ZOOMING_X}${this.options?.type}`, this.handleZoomX);
    this.dispatcher?.on(DiagramEvent.LEAVE_DIAGRAM, this.graph?.draw);
    this.dispatcher?.on(DiagramEvent.ZOOMING_X, this.handleZoomX);
    this.dispatcher?.on(`${DiagramEvent.SYNC_CROSSHAIR}${this.options?.type}`, this.handleSyncCrosshair);
    this.options?.type === AnalysisType.compare &&
      this.dispatcher?.on(DiagramEvent.SYNC_APPEARANCE, this.updateAppearance);
    this.graph?.updateOptions(options);
    this.diagram?.updateOptions(options);
    this.setIsCompare(isCompare);
  };

  public readonly setIsShowEvents = (isShowEvents?: boolean) => this.diagram?.setIsShowEvents(!!isShowEvents);

  public readonly draw = () => {
    this.diagram?.draw();
    this.graph?.draw();
  };

  public readonly updateAppearance = (appearance: DiagramAppearance) => {
    this.diagram?.updateAppearance(appearance);
    if (this.options?.type === AnalysisType.compare) return;
    this.dispatcher?.fire(DiagramEvent.SYNC_APPEARANCE, appearance);
  };

  public readonly setIsCompare = (isCompare: boolean) => this.diagram?.setIsCompare(isCompare);

  public readonly setIsDisabled = (isDisabled: boolean) => this.diagram?.setIsDisabled(isDisabled);

  public readonly setIsActiveHeaMap = (isActiveHeatMap: boolean) => {
    if (this.options?.type === AnalysisType.current) return;
    this.isActiveHeatMap = isActiveHeatMap;
    this.diagram?.setIsActiveHeatMap(isActiveHeatMap);
  };

  public readonly setUnitsCountDiagramParams = (value: UnitsCountDiagramParams | null) => {
    this.diagram?.setUnitsCountDiagramParams(value);
  };

  public readonly destroy = () => {
    this.destroyDiagram();
    this.destroyGraph();
    this.dispatcher?.off(`${DiagramEvent.ZOOMING_X}${this.options?.type}`, this.handleZoomX);
    this.dispatcher?.off(DiagramEvent.LEAVE_DIAGRAM, this.graph?.draw);
    this.dispatcher?.off(DiagramEvent.ZOOMING_X, this.handleZoomX);
    this.dispatcher?.off(`${DiagramEvent.SYNC_CROSSHAIR}${this.options?.type}`, this.handleSyncCrosshair);
    this.options?.type === AnalysisType.compare &&
      this.dispatcher?.off(DiagramEvent.SYNC_APPEARANCE, this.updateAppearance);
  };
}
