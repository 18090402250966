import React from "react";
import RouteButtonAdd from "./route-button-add/route-button-add";
import RoutePoints from "./route-points/route-points";
import RouteButtonAnalysis from "./route-button-analysis/route-button-analysis";
import { SelectTemplate } from "../route-select-template";
import { RouteCosting } from "api/router/model/router";
import { useRouterCosting } from "../../hooks";
import { CtrlRouteContainer } from "./ctrl-route-styled";

import "./route-main.scss";

const className = "route-main";

const RouteMain = () => {
  const costing = useRouterCosting();

  return (
    <CtrlRouteContainer>
      <div className={className}>
        <div className={`${className}-buttons`}>
          <RouteButtonAdd />
          {costing !== RouteCosting.Pedestrian && <RouteButtonAnalysis />}
        </div>
        <div className={`${className}-container`}>
          {costing === RouteCosting.Template ? <SelectTemplate /> : <RoutePoints />}
        </div>
      </div>
    </CtrlRouteContainer>
  );
};

export default RouteMain;
