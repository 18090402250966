export const SPEEDCAM_MAP_IMAGE_KEY = "SPEEDCAM_MAP_IMAGE_KEY";
export const CAMERA_MAP_IMAGE_KEY = "CAMERA_MAP_IMAGE_KEY";
export const DTP_MAP_IMAGE_KEY = "DTP_MAP_IMAGE_KEY";
export const BLOCKING_MAP_IMAGE_KEY = "BLOCKING_MAP_IMAGE_KEY";
export const BUS_STOP_MAP_IMAGE_KEY = "BUS_STOP_MAP_IMAGE_KEY";
export const DETECTOR_MAP_IMAGE_KEY = "DETECTOR_MAP_IMAGE_KEY";
export const ND_DETECTOR_MAP_IMAGE_KEY = "ND_DETECTOR_MAP_IMAGE_KEY";
export const FLOODING_MAP_IMAGE_KEY = "FLOODING_MAP_IMAGE_KEY";
export const TLS_MAP_IMAGE_KEY = "TLS_MAP_IMAGE_KEY";
export const WEATHER_MAP_IMAGE_KEY = "WEATHER_MAP_IMAGE_KEY";
export const SIGN_ROAD_WORKS_MAP_IMAGE_KEY = "SIGN_ROAD_WORKS_MAP_IMAGE_KEY";
export const SIGN_ROUGH_ROAD_MAP_IMAGE_KEY = "SIGN_ROUGH_ROAD_MAP_IMAGE_KEY";
export const SIGN_OTHER_DANGERS_MAP_IMAGE_KEY = "SIGN_OTHER_DANGERS_MAP_IMAGE_KEY";
export const TELEMETRY_MAP_IMAGE_KEY = "TELEMETRY_MAP_IMAGE_KEY";
export const TELEMETRY_MAP_UNMATCHED_IMAGE_KEY = "TELEMETRY_MAP_UNMATCHED_IMAGE_KEY";
export const BLOCKING_PLAN_MAP_IMAGE_KEY = "BLOCKING_PLAN_MAP_IMAGE_KEY";
export const BLOCKING_TEMPORARY_MAP_IMAGE_KEY = "BLOCKING_TEMPORARY_MAP_IMAGE_KEY";

// -----
const transportHubs = "transport-hubs-icon";
// -----

export const icons = {
  [transportHubs]: `
		<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.5 0.508614C10.8359 0.634916 7.34815 2.14575 4.74695 4.74695C2.02767 7.46623 0.5 11.1544 0.5 15C0.5 17.8678 1.35041 20.6713 2.94369 23.0558C4.53697 25.4403 6.80156 27.2988 9.45109 28.3963C12.1006 29.4937 15.0161 29.7809 17.8288 29.2214C20.6415 28.6619 23.2252 27.2809 25.253 25.253C27.2809 23.2252 28.6619 20.6415 29.2214 17.8288C29.7809 15.0161 29.4937 12.1006 28.3963 9.45109C27.2988 6.80156 25.4403 4.53697 23.0558 2.94369C20.6713 1.35041 17.8678 0.5 15 0.5H14.5V0.508614Z" fill="#A259FF" stroke="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M16 6C16 5.44772 15.5523 5 15 5C14.4477 5 14 5.44772 14 6V10.1C11.7178 10.5633 10 12.581 10 15C10 16.0191 10.3049 16.967 10.8284 17.7574L7.29289 21.2929C6.90237 21.6834 6.90237 22.3166 7.29289 22.7071C7.68342 23.0976 8.31658 23.0976 8.70711 22.7071L12.2426 19.1716C13.033 19.6951 13.9809 20 15 20C16.0191 20 16.967 19.6951 17.7574 19.1716L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L19.1716 17.7574C19.6951 16.967 20 16.0191 20 15C20 12.581 18.2822 10.5633 16 10.1V6ZM18 15C18 16.6569 16.6569 18 15 18C13.3431 18 12 16.6569 12 15C12 13.3431 13.3431 12 15 12C16.6569 12 18 13.3431 18 15Z" fill="white"/>
		</svg>
	`,
  // ----
  [BLOCKING_TEMPORARY_MAP_IMAGE_KEY]: `
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <style type="text/css">
            .blocking-temporary-svg-circle{fill-rule:evenodd;clip-rule:evenodd;stroke:#FFFFFF;stroke-miterlimit:10;}
            .blocking-temporary-svg-path{fill:#FFFFFF;}
        </style>
        <circle class="blocking-temporary-svg-circle" cx="12" cy="12" r="11.5"/>
        <path class="blocking-temporary-svg-path" d="M12,1c6.1,0,11,4.9,11,11s-4.9,11-11,11S1,18.1,1,12S5.9,1,12,1 M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12
            s12-5.4,12-12S18.6,0,12,0L12,0z"/>
        <path class="blocking-temporary-svg-path    " d="M17.5,14.4h-0.8l-0.8-7.3c-0.1-1-0.9-1.7-1.9-1.7H9.4c-1,0-1.8,0.7-1.9,1.7l-0.8,7.3H5.9
            c-0.3,0-0.5,0.2-0.5,0.5v2.5c0,0.3,0.2,0.5,0.5,0.5h11.6c0.3,0,0.5-0.2,0.5-0.5v-2.5C18,14.6,17.8,14.4,17.5,14.4z M8.5,7.2
            c0-0.4,0.4-0.8,0.9-0.8H14c0.5,0,0.8,0.3,0.9,0.8l0.8,7.2h-1v-0.2c0-0.3-0.2-0.5-0.5-0.5h-2c0-0.1,0-0.1,0-0.2v-0.8
            c0.9-0.2,1.7-1.1,1.7-2.1s-0.7-1.9-1.7-2.1V7.8c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v0.8c-0.9,0.2-1.7,1.1-1.7,2.1
            s0.7,1.9,1.7,2.1v0.8c0,0.1,0,0.1,0,0.2h-2c-0.3,0-0.5,0.2-0.5,0.5v0.2h-1L8.5,7.2z M12.2,9.6c0.4,0.2,0.6,0.6,0.6,1
            c0,0.4-0.3,0.8-0.6,1V9.6z M11.2,11.7c-0.4-0.2-0.6-0.6-0.6-1c0-0.5,0.3-0.8,0.6-1V11.7z M17,16.8H6.4v-1.5h0.7h2.1h5h2.1H17V16.8z"
        />
    </svg>
    `,
  [BLOCKING_PLAN_MAP_IMAGE_KEY]: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve">
        <style type="text/css">
            .overlap-icon-circle{fill-rule:evenodd;clip-rule:evenodd;fill:#FFA100;stroke:#FFFFFF;stroke-miterlimit:10;}
            .overlap-icon-path{fill:#FFFFFF;}
        </style>
        <circle class="overlap-icon-circle" cx="12" cy="12" r="11.5" />
        <path class="overlap-icon-path" d="M12,1c6.1,0,11,4.9,11,11s-4.9,11-11,11S1,18.1,1,12S5.9,1,12,1 M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12
		s12-5.4,12-12S18.6,0,12,0L12,0z" />
        <path class="overlap-icon-path" d="M12,5.6c0.2,0,0.4,0.1,0.6,0.3L18.4,16c0.3,0.4-0.1,1-0.6,1H6.2c-0.5,0-0.8-0.5-0.6-1l5.8-10.1
		C11.6,5.7,11.8,5.6,12,5.6 M12,4.5c-0.6,0-1.2,0.3-1.5,0.9L4.7,15.5c-0.3,0.5-0.3,1.2,0,1.7s0.9,0.9,1.5,0.9h11.6
		c0.6,0,1.2-0.3,1.5-0.9c0.3-0.5,0.3-1.2,0-1.7L13.5,5.4C13.2,4.9,12.6,4.5,12,4.5L12,4.5z" />
        <path class="overlap-icon-path" d="M13,10.7C13,10.7,13.1,10.7,13,10.7c0.2,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1
			c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5,0.1-0.6,0.3c-0.1,0.4,0,0.7,0.2,1.1C12.7,10.6,12.9,10.7,13,10.7z" />
            <path class="overlap-icon-path" d="M10.5,13.4l0.1,0.8c0,0.1,0,0.2,0,0.3l-0.1,0.1l-0.8,1.2c-0.4,0.6,0.3,1,0.6,0.5l0.2-0.2l0.8-1.3
			c0.1-0.1,0.1-0.3,0.1-0.5l-0.1-0.4L10.5,13.4z" />
            <path class="overlap-icon-path" d="M13.2,16.4h4.6l-1.6-2.6c-0.5,0.2-0.4,0.6-0.8,1.1l-1.9-1.1L13,12.2l-0.2-0.7c0-0.2-0.1-0.3-0.2-0.5
			c0,0-0.4-0.5-0.4-0.5c-0.2-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.6-0.1-0.9,0c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.5-0.5,0.7
			c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.1,0.3V12c0,0.3,0.4,0.4,0.6,0.1l0.5,0.3l0.1,0.1l-0.1,0.2c-0.1,0.1,0,0.3,0,0.4
			l0.1,0.1l1.6,1l0.2,0.1V16C12.5,16.4,12.9,16.5,13.2,16.4c0-0.1,0.1-0.2,0.1-0.4v-1.5c0.2,0,0.3,0,0.5-0.2l1.5,0.9
			C14.4,15.6,14.2,15,13.2,16.4z M10.8,12.2L10.5,12l-0.4-0.2c0,0,0.4-0.6,0.4-0.6c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.1,0.6,0,0.9,0
			L10.8,12.2z M12.2,13l-0.4-0.2l0.4-0.6v-0.1l0.3,1.1L12.2,13z" />
    </svg>`,
  [SPEEDCAM_MAP_IMAGE_KEY]: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <defs>
            <style>
                .cls-1{fill:none}.cls-2{clip-path:url(#clip-path)}.cls-3{fill:#003d84;stroke-width:2px}.cls-3,.cls-5{stroke:#fff}.cls-4{opacity:.997}.cls-5{fill:#fff;stroke-linejoin:round}.cls-6{stroke:none}
            </style>
            <clipPath id="clip-path">
                <path d="M0 0h32v32H0z" class="cls-1"/>
            </clipPath>
        </defs>
        <g class="cls-2">
            <g id="Ellipse_75" class="cls-3" data-name="Ellipse 75" transform="translate(2 2)">
                <circle cx="14" cy="14" r="14" class="cls-6"/>
                <circle cx="14" cy="14" r="15" class="cls-1"/>
            </g>
            <g id="Photo" class="cls-4" transform="translate(6 6)">
                <path id="Rectangle_300" d="M0 0h20v20H0z" class="cls-1" data-name="Rectangle 300"/>
                <g id="Group_355" data-name="Group 355" transform="translate(1.673 3.313)">
                    <path id="Path_94" d="M17.77 14.865v-.339a.61.61 0 0 0-.208-.475.636.636 0 0 0-.485-.2h-1.213a.636.636 0 0 0-.485.2.61.61 0 0 0-.208.475v.305h-1.386l-1.178-1.56a.729.729 0 0 0-.556-.271H8.585a.7.7 0 0 0-.572.288l-1.161 1.577H3.04a1.048 1.048 0 0 0-.745.305.988.988 0 0 0-.295.712V24.7a.787.787 0 0 0 .26.593.822.822 0 0 0 .607.254H17.6a1.048 1.048 0 0 0 .745-.305 1.014 1.014 0 0 0 .312-.729v-8.648a1.043 1.043 0 0 0-.884-1zm-1.906-.339h1.213v.322h-1.213zm2.08 10a.331.331 0 0 1-.1.237.346.346 0 0 1-.243.1H2.867a.159.159 0 0 1-.121-.051.152.152 0 0 1-.052-.119v-8.811a.331.331 0 0 1 .1-.237.346.346 0 0 1 .243-.1h3.815a.7.7 0 0 0 .572-.288l1.161-1.577h3.466l1.178 1.56a.729.729 0 0 0 .555.271l3.813.017a.344.344 0 0 1 .347.339z" class="cls-5" data-name="Path 94" transform="translate(-2 -13)"/>
                    <path id="Path_95" d="M15.263 34h-1.915a.339.339 0 1 0 0 .678h1.915a.339.339 0 1 0 0-.678z" class="cls-5" data-name="Path 95" transform="translate(-11.085 -30.44)"/>
                    <path id="Path_96" d="M34.638 36.9a3.155 3.155 0 1 0 3.238 3.154 3.2 3.2 0 0 0-3.238-3.154zm0 5.6a2.442 2.442 0 1 1 2.507-2.441 2.475 2.475 0 0 1-2.507 2.441z" class="cls-5" data-name="Path 96" transform="translate(-26.319 -32.848)"/>
                </g>
            </g>
        </g>
    </svg>`,
  [CAMERA_MAP_IMAGE_KEY]: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <defs>
        <style>
            .cls-1{fill:none}.cls-2{clip-path:url(#clip-path)}.cls-3{fill:#0098d0;stroke-width:2px;stroke:#fff}.cls-4{stroke:#fff;fill:#fff}.cls-5{stroke:none}
        </style>
        <clipPath id="clip-path">
            <path d="M0 0h32v32H0z" class="cls-1"/>
        </clipPath>
    </defs>
    <g id="pin_service_TCODD_KTO" class="cls-2">
        <g id="Ellipse_75" class="cls-3" data-name="Ellipse 75" transform="translate(2 2)">
            <circle cx="14" cy="14" r="14" class="cls-5"/>
            <circle cx="14" cy="14" r="15" class="cls-1"/>
        </g>
        <g id="Camera" transform="translate(4 4)">
            <path id="Rectangle_295" d="M0 0h24v24H0z" class="cls-1" data-name="Rectangle 295"/>
            <g id="Group_328" data-name="Group 328" transform="translate(2 6)">
                <path id="Path_80" d="M10.761 18.971A8.332 8.332 0 0 1 2.5 10.588V7.941a.438.438 0 0 1 .435-.441h15.652a.438.438 0 0 1 .435.441v2.647a8.332 8.332 0 0 1-8.261 8.383zM3.37 8.382v2.206a7.455 7.455 0 0 0 7.391 7.5 7.455 7.455 0 0 0 7.391-7.5V8.382z" class="cls-4" data-name="Path 80" transform="translate(-.761 -3.971)"/>
                <path id="Path_82" d="M12.434 17.455a2.978 2.978 0 1 1 2.934-2.977 2.959 2.959 0 0 1-2.934 2.977zm0-4.764a1.787 1.787 0 1 0 1.76 1.786 1.776 1.776 0 0 0-1.76-1.786z" class="cls-4" data-name="Path 82" transform="translate(-2.223 -5.427)"/>
                <path id="Path_83" d="M19.2 7.912H1.8A1.316 1.316 0 0 1 .5 6.588V3.941A.438.438 0 0 1 .935 3.5h19.13a.438.438 0 0 1 .435.441v2.647a1.316 1.316 0 0 1-1.3 1.324zM1.37 4.382v2.206a.439.439 0 0 0 .435.441H19.2a.439.439 0 0 0 .435-.441V4.382z" class="cls-4" data-name="Path 83" transform="translate(-.5 -3.5)"/>
            </g>
        </g>
    </g>
</svg>`,
  [DTP_MAP_IMAGE_KEY]: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <defs>
        <style>
            .cls-1{fill:none}.cls-2{clip - path:url(#clip-path)}.cls-3{fill:#fd1c00}.cls-4,.cls-6{fill:#fff}.cls-5,.cls-6{stroke:none}
    </style>
        <clipPath id="clip-path">
            <path d="M0 0h32v32H0z" class="cls-1" />
        </clipPath>
    </defs>
    <g id="Столкновение" class="cls-2">
        <g id="Path_61943" class="cls-3" data-name="Path 61943">
            <path d="M14 29c-4.007 0-7.773-1.56-10.607-4.393A14.902 14.902 0 0 1-1 14C-1 9.993.56 6.227 3.393 3.393A14.902 14.902 0 0 1 14-1c4.007 0 7.773 1.56 10.607 4.393A14.902 14.902 0 0 1 29 14c0 4.007-1.56 7.773-4.393 10.607A14.902 14.902 0 0 1 14 29z" class="cls-5" transform="translate(2 2)" />
            <path d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14S21.732 0 14 0m0-2a15.944 15.944 0 0 1 11.314 4.686A15.944 15.944 0 0 1 30 14a15.944 15.944 0 0 1-4.686 11.314A15.944 15.944 0 0 1 14 30a15.944 15.944 0 0 1-11.314-4.686A15.944 15.944 0 0 1-2 14 15.944 15.944 0 0 1 2.686 2.686 15.944 15.944 0 0 1 14-2z" class="cls-6" transform="translate(2 2)" />
        </g>
        <g id="noun_car_collision_1041537" data-name="noun_car collision_1041537" transform="translate(-.105 -12.848)">
            <g id="Group_60237" data-name="Group 60237" transform="translate(8.106 20.847)">
                <path id="Path_61938" d="M55.46 39.932l-6.526.689a2.266 2.266 0 0 0-4.33.32c-.248-.077-.625-.317-.7-1.068-.1-.961.472-1.132.642-1.16a.594.594 0 0 0 .088-.018l1.993-.585a.585.585 0 0 0 .276-.177l3.544-4.08 4.275-.451a.582.582 0 1 0-.122-1.158l-4.5.475a.584.584 0 0 0-.379.2L46.13 37.04l-1.794.526A2.058 2.058 0 0 0 42.74 40a2.18 2.18 0 0 0 1.922 2.14 2.26 2.26 0 0 0 2.152 1.56 2.33 2.33 0 0 0 .24-.013 2.27 2.27 0 0 0 2-1.906l6.527-.689a.582.582 0 1 0-.122-1.158zm-8.528 2.6a1.1 1.1 0 0 1-.232-2.194 1.128 1.128 0 0 1 .118-.006 1.1 1.1 0 0 1 1.1.987 1.1 1.1 0 0 1-.986 1.209z" class="cls-4" data-name="Path 61938" transform="translate(-40.103 -27.699)" />
                <path id="Path_61939" d="M29.693 26.4a.591.591 0 1 0 .976-.667l-.5-.732 1.042-.265a.59.59 0 0 0 .409-.776l-.42-1.133.93.465a.591.591 0 0 0 .8-.278l.448-.959.321 1.51a.591.591 0 0 0 .817.418l1.22-.538-.238 1.4a.591.591 0 0 0 .831.636l1.737-.8a.591.591 0 0 0-.5-1.073l-.718.332.234-1.373a.591.591 0 0 0-.821-.64l-1.56.688-.531-2.535a.591.591 0 0 0-1.114-.127l-.938 2.01L30.4 21.1a.591.591 0 0 0-.819.733l.7 1.919-1.226.312a.591.591 0 0 0-.342.907z" class="cls-4" data-name="Path 61939" transform="rotate(-14.98 -54.698 122.997)" />
            </g>
        </g>
    </g>
</svg>`,
  [BLOCKING_MAP_IMAGE_KEY]: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
<defs>
    <style>
        .cls-1{fill:#fda600}.cls-2{fill:#fff}.cls-3{fill:none}.cls-4{filter:url(#Path_61943)}
    </style>
    <filter id="Path_61943" width="46" height="46" x="0" y="0" filterUnits="userSpaceOnUse">
        <feOffset dy="2"/>
        <feGaussianBlur result="blur" stdDeviation="3"/>
        <feFlood flood-opacity=".141"/>
        <feComposite in2="blur" operator="in"/>
        <feComposite in="SourceGraphic"/>
    </filter>
</defs>
<g id="Group_61558" data-name="Group 61558" transform="translate(-1432 -469)">
    <g id="Столкновение" class="cls-4" transform="translate(1432 469)">
        <path id="Path_61943-2" d="M14 0A14 14 0 1 1 0 14 14 14 0 0 1 14 0z" class="cls-1" data-name="Path 61943" transform="translate(9 7)"/>
    </g>
    <g id="Group_61556" data-name="Group 61556" transform="translate(23 -50)">
        <g id="noun_barrier_1582396" transform="translate(1424 533)">
            <g id="Group_60574" data-name="Group 60574">
                <path id="Path_61978" d="M.445 1.751h.89V.438A.438.438 0 0 1 1.78 0h1.78A.444.444 0 0 1 4 .438v1.313h8V.438A.438.438 0 0 1 12.449 0h1.78a.436.436 0 0 1 .436.438v1.313h.89a.438.438 0 0 1 .445.438v2.626a.437.437 0 0 1-.445.429h-.89V7h.89a.444.444 0 0 1 .445.434v2.626a.438.438 0 0 1-.445.438h-.89v3.064a.442.442 0 0 1-.436.438h-1.78a.444.444 0 0 1-.449-.438V10.5H4v3.064a.449.449 0 0 1-.441.436H1.78a.444.444 0 0 1-.445-.438V10.5h-.89A.438.438 0 0 1 0 10.06V7.434A.444.444 0 0 1 .445 7h.89V5.245h-.89A.437.437 0 0 1 0 4.815V2.189a.438.438 0 0 1 .445-.438zm12 7.871l1.027-1.751h-1.023l-1.027 1.751zM14.5 7.871l-1.024 1.751h1.634V7.871zm-4.1 1.751l1.027-1.751h-1.374L9.027 9.622zm-2.4 0l1.027-1.751H8L6.973 9.622zm-2.053 0l1.026-1.751H5.6L4.578 9.622zm-2.387 0l1.026-1.751H3.551L2.524 9.622zm-2.053 0l1.026-1.751H.89v1.751h.616zm10.943-7l-1.028 1.756h1.027l1.027-1.751zM10.4 4.378l1.027-1.751h-1.374L9.027 4.378zm4.1-1.751l-1.024 1.751h1.634V2.627zM8 4.378l1.027-1.751H8L6.973 4.378zm-2.053 0l1.026-1.751H5.6L4.578 4.378zm-2.387 0l1.026-1.751H3.551L2.524 4.378zm-2.053 0l1.026-1.751H.89v1.751h.616zm11.387-2.627h.89V.876h-.89zm.89 3.494h-.89V7h.89zm-1.78 0H4V7h8zm1.78 5.253h-.89v2.627h.89zM2.225 1.751h.89V.876h-.89zm.89 3.494h-.89V7h.89zm0 7.88V10.5h-.89v2.627z" class="cls-2" data-name="Path 61978"/>
            </g>
        </g>
        <path id="Rectangle_6814" d="M0 0h20v20H0z" class="cls-3" data-name="Rectangle 6814" transform="translate(1422 530)"/>
    </g>
</g>
</svg>`,
  [BUS_STOP_MAP_IMAGE_KEY]: `<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
  <g class="layer">
   <g data-name="Group 68625" id="Group_68625">
    <g data-name="Rectangle 11099" fill="#007efc" stroke="#fff" transform="translate(1102,479) " id="Rectangle_11099">
     <rect id="svg_1" rx="4.5" class="cls-1" y="-478.20305" x="-1101.35322" height="17" width="17"/>
    </g>
    <g transform="translate(288,-502) " id="ic_bus">
     <g data-name="Group 68622" transform="translate(816,986) " id="Group_68622">
      <path data-name="Path 72999" transform="translate(-43.5880012512207,-204.0780029296875) " fill="#fff" d="m-1054.60522,-269.11005a1.115,1.115 0 1 0 1.115,1.115a1.115,1.115 0 0 0 -1.115,-1.115zm0,1.6a0.485,0.485 0 1 1 0.485,-0.485a0.485,0.485 0 0 1 -0.485,0.485z" id="Path_72999"/>
      <path data-name="Path 73000" transform="translate(-228.20199584960938,-204.0780029296875) " fill="#fff" d="m-863.44522,-269.11005a1.115,1.115 0 1 0 1.115,1.115a1.115,1.115 0 0 0 -1.115,-1.115zm0,1.6a0.485,0.485 0 1 1 0.485,-0.485a0.485,0.485 0 0 1 -0.485,0.485z" id="Path_73000"/>
      <path data-name="Path 73001" transform="translate(0,-76.74099731445312) " fill="#fff" d="m-1089.51922,-400.96205l-10,0a0.853,0.853 0 0 0 -0.8,0.653l-0.4,1.459a7.964,7.964 0 0 0 -0.134,1.319l0,1.528a0.668,0.668 0 0 0 0.666,0.666l0.679,0a1.315,1.315 0 0 1 2.63,0l3.916,0a1.315,1.315 0 0 1 2.63,0l0.813,0a0.668,0.668 0 0 0 0.666,-0.666l0,-4.3a0.668,0.668 0 0 0 -0.666,-0.659zm-8.565,2.811l-1.553,0l-0.427,-0.016a0.4,0.4 0 0 1 -0.289,-0.475l0.358,-1.429a0.626,0.626 0 0 1 0.591,-0.491l1.32,0l0,2.412l0,-0.001zm2.965,0l-2.566,0l0,-2.412l2.565,0l0.001,2.412zm2.965,0l-2.566,0l0,-2.412l2.567,0l-0.001,2.412zm2.893,-0.5a0.5,0.5 0 0 1 -0.5,0.5l-1.992,0l0,-2.412l1.994,0a0.5,0.5 0 0 1 0.5,0.5l-0.002,1.412z" id="Path_73001"/>
     </g>
    </g>
   </g>
  </g>
 </svg>`,
  [DETECTOR_MAP_IMAGE_KEY]: `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1125_22733)">
<rect x="4" y="2" width="30" height="30" rx="15" fill="#40A924"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 16.7757C18.5634 16.7757 18.1312 16.8596 17.7278 17.0224C17.3245 17.1852 16.958 17.4238 16.6493 17.7247C16.3497 18.0167 15.864 18.0167 15.5644 17.7247C15.2648 17.4327 15.2648 16.9593 15.5644 16.6673C16.0156 16.2276 16.5512 15.8788 17.1407 15.6409C17.7301 15.4029 18.3619 15.2804 19 15.2804C19.6381 15.2804 20.2699 15.4029 20.8593 15.6409C21.4488 15.8788 21.9844 16.2276 22.4356 16.6673C22.7352 16.9593 22.7352 17.4327 22.4356 17.7247C22.136 18.0167 21.6503 18.0167 21.3507 17.7247C21.042 17.4238 20.6755 17.1852 20.2722 17.0224C19.8688 16.8596 19.4366 16.7757 19 16.7757Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 13.7851C18.1605 13.7851 17.3291 13.9463 16.5535 14.2594C15.7779 14.5725 15.0731 15.0314 14.4795 15.61C14.1799 15.902 13.6941 15.902 13.3945 15.61C13.095 15.318 13.095 14.8446 13.3945 14.5526C14.1307 13.8352 15.0046 13.2661 15.9664 12.8779C16.9281 12.4896 17.959 12.2898 19 12.2898C20.041 12.2898 21.0719 12.4896 22.0336 12.8779C22.9954 13.2661 23.8693 13.8352 24.6055 14.5526C24.905 14.8446 24.905 15.318 24.6055 15.61C24.3059 15.902 23.8201 15.902 23.5205 15.61C22.9269 15.0314 22.2221 14.5725 21.4465 14.2594C20.6709 13.9463 19.8395 13.7851 19 13.7851Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 10.4953C17.7575 10.4953 16.5271 10.7338 15.3792 11.1972C14.2313 11.6607 13.1882 12.3399 12.3096 13.1961C12.01 13.4881 11.5243 13.4881 11.2247 13.1961C10.9251 12.9042 10.9251 12.4308 11.2247 12.1388C12.2458 11.1437 13.4579 10.3543 14.792 9.81575C16.1261 9.27719 17.556 9 19 9C20.444 9 21.8739 9.27719 23.208 9.81575C24.5421 10.3543 25.7542 11.1437 26.7753 12.1388C27.0749 12.4308 27.0749 12.9042 26.7753 13.1962C26.4757 13.4881 25.99 13.4881 25.6904 13.1962C24.8118 12.3399 23.7687 11.6607 22.6208 11.1972C21.4729 10.7338 20.2425 10.4953 19 10.4953Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0458 21.5109H16.9542C16.6718 21.5109 16.4428 21.7341 16.4428 22.0093V23.0062C16.4428 23.2815 16.6718 23.5047 16.9542 23.5047H21.0458C21.3282 23.5047 21.5572 23.2815 21.5572 23.0062V22.0093C21.5572 21.7341 21.3282 21.5109 21.0458 21.5109ZM16.9542 20.0156C15.8244 20.0156 14.9085 20.9082 14.9085 22.0093V23.0062C14.9085 24.1074 15.8244 25 16.9542 25H21.0458C22.1756 25 23.0915 24.1074 23.0915 23.0062V22.0093C23.0915 20.9082 22.1756 20.0156 21.0458 20.0156H16.9542Z" fill="white"/>
<rect x="5" y="3" width="28" height="28" rx="14" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_1125_22733" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1125_22733"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1125_22733" result="shape"/>
</filter>
</defs>
</svg>

`,
  [ND_DETECTOR_MAP_IMAGE_KEY]: `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1125_22793)">
<rect x="4" y="2" width="30" height="30" rx="15" fill="#747474"/>
<path d="M18.9387 13.7854C18.979 13.5295 19 13.2672 19 13C19 12.7589 18.9829 12.5219 18.95 12.2899C18.9666 12.2898 18.9833 12.2898 19 12.2898C20.041 12.2898 21.0719 12.4896 22.0336 12.8779C22.9954 13.2661 23.8693 13.8352 24.6055 14.5526C24.905 14.8446 24.905 15.318 24.6055 15.61C24.3059 15.902 23.8201 15.902 23.5205 15.61C22.9269 15.0314 22.2221 14.5725 21.4465 14.2594C20.6709 13.9463 19.8395 13.7851 19 13.7851C18.9796 13.7851 18.9591 13.7852 18.9387 13.7854Z" fill="white"/>
<path d="M15.5846 17.7437C16.8192 17.3315 17.8394 16.4508 18.4342 15.3126C18.6216 15.2912 18.8105 15.2804 19 15.2804C19.6381 15.2804 20.2699 15.4029 20.8593 15.6409C21.4488 15.8788 21.9844 16.2276 22.4356 16.6673C22.7352 16.9593 22.7352 17.4327 22.4356 17.7247C22.136 18.0167 21.6503 18.0167 21.3507 17.7247C21.042 17.4238 20.6755 17.1852 20.2722 17.0224C19.8688 16.8596 19.4366 16.7757 19 16.7757C18.5634 16.7757 18.1312 16.8596 17.7278 17.0224C17.3245 17.1852 16.958 17.4238 16.6493 17.7247C16.3564 18.0102 15.8855 18.0165 15.5846 17.7437Z" fill="white"/>
<path d="M18.3413 10.5177C18.0411 9.99383 17.6488 9.52949 17.1865 9.14675C17.7844 9.04931 18.3909 9 19 9C20.444 9 21.8739 9.27719 23.208 9.81575C24.5421 10.3543 25.7542 11.1437 26.7753 12.1388C27.0749 12.4308 27.0749 12.9042 26.7753 13.1962C26.4757 13.4881 25.99 13.4881 25.6904 13.1962C24.8118 12.3399 23.7687 11.6607 22.6208 11.1972C21.4729 10.7338 20.2425 10.4953 19 10.4953C18.7799 10.4953 18.5602 10.5028 18.3413 10.5177Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.9542 20.0156C15.8244 20.0156 14.9085 20.9082 14.9085 22.0093V23.0062C14.9085 24.1074 15.8244 25 16.9542 25H21.0458C22.1756 25 23.0915 24.1074 23.0915 23.0062V22.0093C23.0915 20.9082 22.1756 20.0156 21.0458 20.0156H16.9542ZM21.0458 21.5109H16.9542C16.6718 21.5109 16.4428 21.7341 16.4428 22.0093V23.0062C16.4428 23.2815 16.6718 23.5047 16.9542 23.5047H21.0458C21.3282 23.5047 21.5572 23.2815 21.5572 23.0062V22.0093C21.5572 21.7341 21.3282 21.5109 21.0458 21.5109Z" fill="white"/>
<path d="M14 17C16.1654 17 18 15.1811 18 13C18 10.8031 16.189 9 14 9C11.8031 9 10 10.8031 10 13C10 15.1969 11.8031 17 14 17ZM14 13.6772C13.7244 13.6772 13.5276 13.4882 13.5197 13.2205L13.4567 10.9685C13.4488 10.6457 13.6693 10.4252 14 10.4252C14.3307 10.4252 14.5433 10.6457 14.5354 10.9685L14.4724 13.2205C14.4646 13.4882 14.2756 13.6772 14 13.6772ZM13.9921 15.5512C13.6299 15.5512 13.3307 15.252 13.3307 14.8819C13.3307 14.5276 13.6378 14.2205 13.9921 14.2205C14.3622 14.2205 14.6614 14.5276 14.6614 14.8819C14.6614 15.252 14.3622 15.5512 13.9921 15.5512Z" fill="white"/>
<rect x="5" y="3" width="28" height="28" rx="14" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_1125_22793" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1125_22793"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1125_22793" result="shape"/>
</filter>
</defs>
</svg>

`,
  [TLS_MAP_IMAGE_KEY]: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <defs>
        <style>
            .cls-1{fill:none}.cls-2{clip-path:url(#clip-path)}.cls-3{fill:#59b200}.cls-4,.cls-6{fill:#fff}.cls-5,.cls-6{stroke:none}
        </style>
        <clipPath id="clip-path">
            <path d="M0 0h32v32H0z" class="cls-1"/>
        </clipPath>
    </defs>
    <g id="pin_service_TCODD_CO" class="cls-2">
        <g id="Path_61963" class="cls-3" data-name="Path 61963">
            <path d="M14 29c-4.007 0-7.773-1.56-10.607-4.393A14.902 14.902 0 0 1-1 14C-1 9.993.56 6.227 3.393 3.393A14.902 14.902 0 0 1 14-1c4.007 0 7.773 1.56 10.607 4.393A14.902 14.902 0 0 1 29 14c0 4.007-1.56 7.773-4.393 10.607A14.902 14.902 0 0 1 14 29z" class="cls-5" transform="translate(2 2)"/>
            <path d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14S21.732 0 14 0m0-2a15.944 15.944 0 0 1 11.314 4.686A15.944 15.944 0 0 1 30 14a15.944 15.944 0 0 1-4.686 11.314A15.944 15.944 0 0 1 14 30a15.944 15.944 0 0 1-11.314-4.686A15.944 15.944 0 0 1-2 14 15.944 15.944 0 0 1 2.686 2.686 15.944 15.944 0 0 1 14-2z" class="cls-6" transform="translate(2 2)"/>
        </g>
        <g id="Group_60495" data-name="Group 60495" transform="translate(5494 22842)">
            <g id="Group_60494" data-name="Group 60494" transform="translate(-5486 -22835)">
                <g id="Group_60472" data-name="Group 60472" transform="translate(3.433)">
                    <g id="Group_60471" data-name="Group 60471">
                        <path id="Path_61964" d="M264.458 54.544a.981.981 0 0 1-.007.1l.02-.152a.975.975 0 0 1-.043.173l.054-.137a1.249 1.249 0 0 1-.059.121c-.05.091.034-.049.036-.046a.644.644 0 0 1-.046.055l-.043.042c-.043.046.114-.082.043-.036a1.026 1.026 0 0 1-.125.07l.129-.057a.8.8 0 0 1-.163.046l.143-.021a5.549 5.549 0 0 1-.733.008h-6.019a.657.657 0 0 1-.105-.008l.143.021a.832.832 0 0 1-.163-.046l.129.057a1.131 1.131 0 0 1-.114-.063c-.086-.053.046.036.043.038a.621.621 0 0 1-.052-.049l-.039-.046c-.043-.046.077.121.034.046a1.478 1.478 0 0 1-.066-.133l.054.137a.933.933 0 0 1-.043-.173l.02.152a4.167 4.167 0 0 1-.007-.5V39.195a.98.98 0 0 1 .007-.125c-.007.051-.012.1-.02.152a.972.972 0 0 1 .043-.173l-.054.137a1.263 1.263 0 0 1 .059-.121c.05-.091-.034.049-.036.046a.628.628 0 0 1 .046-.055l.043-.042c.043-.046-.114.082-.043.036a1.024 1.024 0 0 1 .125-.07l-.129.057a.8.8 0 0 1 .163-.046l-.143.021a5.55 5.55 0 0 1 .733-.008H264.288a.657.657 0 0 1 .105.008l-.143-.021a.833.833 0 0 1 .163.046l-.129-.057a1.125 1.125 0 0 1 .114.063c.086.053-.046-.036-.043-.038a.622.622 0 0 1 .052.049l.039.046c.043.046-.077-.121-.034-.046a1.135 1.135 0 0 1 .066.133l-.054-.137a.936.936 0 0 1 .043.173l-.02-.152a4.1 4.1 0 0 1 .007.492v14.978a.537.537 0 1 0 1.072 0V39.189a1.3 1.3 0 0 0-1.255-1.329h-6.237a2.755 2.755 0 0 0-.717.044 1.315 1.315 0 0 0-.922 1.27v15.357a1.414 1.414 0 0 0 .331.905 1.207 1.207 0 0 0 .926.421h6.557a1.534 1.534 0 0 0 .382-.04 1.316 1.316 0 0 0 .935-1.274.559.559 0 0 0-.536-.569.565.565 0 0 0-.532.57z" class="cls-4" data-name="Path 61964" transform="translate(-256.4 -37.858)"/>
                    </g>
                </g>
                <g id="Group_60475" data-name="Group 60475" transform="translate(5.756 1.44)">
                    <g id="Group_60474" data-name="Group 60474">
                        <g id="Group_60473" data-name="Group 60473">
                            <path id="Path_61965" d="M389.795 120.663a1.867 1.867 0 0 1-.014.223c.007-.048.012-.095.02-.143a1.779 1.779 0 0 1-.12.44l.054-.129a1.622 1.622 0 0 1-.157.29c-.013.02-.027.038-.041.057-.054.075.084-.1.027-.034l-.109.122c-.037.04-.073.068-.113.1-.084.075.1-.073.025-.02l-.057.041a1.881 1.881 0 0 1-.29.157l.129-.054a1.781 1.781 0 0 1-.44.12l.143-.02a1.868 1.868 0 0 1-.447 0l.143.02a1.781 1.781 0 0 1-.44-.12l.129.054a1.62 1.62 0 0 1-.29-.157c-.02-.013-.038-.027-.057-.041-.075-.054.1.084.034.027-.041-.036-.082-.071-.122-.109s-.068-.073-.1-.113c-.075-.084.073.1.02.025l-.041-.057a1.881 1.881 0 0 1-.157-.29l.054.129a1.779 1.779 0 0 1-.12-.44l.02.143a1.87 1.87 0 0 1 0-.447c-.007.048-.012.095-.02.143a1.779 1.779 0 0 1 .12-.44l-.054.129a1.619 1.619 0 0 1 .157-.29c.013-.02.027-.038.041-.057.054-.075-.084.1-.027.034.036-.041.071-.082.109-.122s.073-.068.113-.1c.084-.075-.1.073-.025.02l.057-.041a1.882 1.882 0 0 1 .29-.157l-.129.054a1.78 1.78 0 0 1 .44-.12l-.143.02a1.869 1.869 0 0 1 .447 0l-.143-.02a1.78 1.78 0 0 1 .44.12l-.129-.054a1.62 1.62 0 0 1 .29.157c.02.013.038.027.057.041.075.054-.1-.084-.034-.027.041.036.082.071.121.109s.068.073.1.113c.075.084-.073-.1-.02-.025l.041.057a1.884 1.884 0 0 1 .157.29l-.054-.129a1.779 1.779 0 0 1 .12.44l-.02-.143a1.843 1.843 0 0 1 .014.223.536.536 0 0 0 1.072 0 2.225 2.225 0 0 0-.391-1.262 2.336 2.336 0 0 0-1.11-.851 2.234 2.234 0 0 0-2.432.65 2.341 2.341 0 0 0-.54 1.313 2.226 2.226 0 0 0 1.256 2.162 2.345 2.345 0 0 0 1.439.177 2.255 2.255 0 0 0 1.776-2.189.535.535 0 0 0-1.071 0z" class="cls-4" data-name="Path 61965" transform="translate(-386.386 -118.422)"/>
                        </g>
                    </g>
                </g>
                <g id="Group_60478" data-name="Group 60478" transform="translate(5.756 6.689)">
                    <g id="Group_60477" data-name="Group 60477">
                        <g id="Group_60476" data-name="Group 60476">
                            <path id="Path_61966" d="M389.795 385.263a1.867 1.867 0 0 1-.014.223c.007-.048.012-.095.02-.143a1.779 1.779 0 0 1-.12.44l.054-.129a1.62 1.62 0 0 1-.157.289c-.013.02-.027.038-.041.057-.054.075.084-.1.027-.034l-.109.122c-.037.04-.073.068-.113.1-.084.075.1-.073.025-.02l-.057.041a1.88 1.88 0 0 1-.29.157l.129-.054a1.78 1.78 0 0 1-.44.12l.143-.02a1.87 1.87 0 0 1-.447 0l.143.02a1.781 1.781 0 0 1-.44-.12l.129.054a1.618 1.618 0 0 1-.29-.157c-.02-.013-.038-.027-.057-.041-.075-.054.1.084.034.027-.041-.036-.082-.071-.122-.109s-.068-.073-.1-.113c-.075-.084.073.1.02.025l-.041-.057a1.878 1.878 0 0 1-.157-.289l.054.129a1.779 1.779 0 0 1-.12-.44l.02.143a1.87 1.87 0 0 1 0-.447c-.007.048-.012.095-.02.143a1.779 1.779 0 0 1 .12-.44l-.054.129a1.617 1.617 0 0 1 .157-.289c.013-.02.027-.038.041-.057.054-.075-.084.1-.027.034.036-.041.071-.082.109-.121s.073-.068.113-.1c.084-.075-.1.073-.025.02l.057-.041a1.88 1.88 0 0 1 .29-.157l-.129.054a1.781 1.781 0 0 1 .44-.12l-.143.02a1.87 1.87 0 0 1 .447 0l-.143-.02a1.78 1.78 0 0 1 .44.12l-.129-.054a1.616 1.616 0 0 1 .29.157c.02.012.038.027.057.041.075.054-.1-.084-.034-.027.041.036.082.072.121.109s.068.073.1.113c.075.084-.073-.1-.02-.025l.041.057a1.881 1.881 0 0 1 .157.289l-.054-.129a1.779 1.779 0 0 1 .12.44l-.02-.143a1.866 1.866 0 0 1 .014.223.536.536 0 0 0 1.072 0 2.226 2.226 0 0 0-.391-1.262 2.336 2.336 0 0 0-1.11-.851 2.234 2.234 0 0 0-2.432.651 2.341 2.341 0 0 0-.54 1.313 2.226 2.226 0 0 0 1.256 2.162 2.345 2.345 0 0 0 1.439.177 2.255 2.255 0 0 0 1.776-2.189.535.535 0 0 0-1.071 0z" class="cls-4" data-name="Path 61966" transform="translate(-386.386 -383.022)"/>
                        </g>
                    </g>
                </g>
                <g id="Group_60481" data-name="Group 60481" transform="translate(5.756 11.951)">
                    <g id="Group_60480" data-name="Group 60480">
                        <g id="Group_60479" data-name="Group 60479">
                            <path id="Path_61967" d="M389.795 649.963a1.869 1.869 0 0 1-.014.223c.007-.048.012-.095.02-.143a1.778 1.778 0 0 1-.12.44l.054-.129a1.617 1.617 0 0 1-.157.289c-.013.02-.027.038-.041.057-.054.075.084-.1.027-.034l-.109.122c-.037.04-.073.068-.113.1-.084.075.1-.073.025-.02l-.057.041a1.88 1.88 0 0 1-.29.157l.129-.054a1.786 1.786 0 0 1-.44.12l.143-.02a1.861 1.861 0 0 1-.447 0l.143.02a1.786 1.786 0 0 1-.44-.12l.129.054a1.62 1.62 0 0 1-.29-.157c-.02-.012-.038-.027-.057-.041-.075-.054.1.084.034.027-.041-.036-.082-.071-.122-.109s-.068-.073-.1-.113c-.075-.084.073.1.02.025l-.041-.057a1.874 1.874 0 0 1-.157-.289l.054.129a1.778 1.778 0 0 1-.12-.44l.02.143a1.871 1.871 0 0 1 0-.447c-.007.048-.012.095-.02.143a1.78 1.78 0 0 1 .12-.44l-.054.129a1.616 1.616 0 0 1 .157-.29c.013-.02.027-.037.041-.057.054-.075-.084.1-.027.034.036-.041.071-.082.109-.121s.073-.068.113-.1c.084-.075-.1.073-.025.02l.057-.041a1.875 1.875 0 0 1 .29-.157l-.129.054a1.783 1.783 0 0 1 .44-.12l-.143.02a1.867 1.867 0 0 1 .447 0l-.143-.02a1.783 1.783 0 0 1 .44.12l-.129-.054a1.624 1.624 0 0 1 .29.157c.02.013.038.027.057.041.075.054-.1-.084-.034-.027.041.036.082.071.121.109s.068.073.1.113c.075.084-.073-.1-.02-.025l.041.057a1.88 1.88 0 0 1 .157.29l-.054-.129a1.78 1.78 0 0 1 .12.44l-.02-.143a1.845 1.845 0 0 1 .014.223.536.536 0 1 0 1.072 0 2.225 2.225 0 0 0-.391-1.262 2.337 2.337 0 0 0-1.11-.851 2.234 2.234 0 0 0-2.432.651 2.341 2.341 0 0 0-.54 1.313 2.226 2.226 0 0 0 1.256 2.162 2.345 2.345 0 0 0 1.439.177 2.255 2.255 0 0 0 1.776-2.189.535.535 0 0 0-1.071 0z" class="cls-4" data-name="Path 61967" transform="translate(-386.386 -647.722)"/>
                        </g>
                    </g>
                </g>
                <g id="Group_60483" data-name="Group 60483" transform="translate(11.964 1.439)">
                    <g id="Group_60482" data-name="Group 60482">
                        <path id="Path_61968" d="M708.572 121.888v-2.952l-.536.536H711l-.379-.915-1 1q-.8.793-1.594 1.585l-.368.366a.536.536 0 1 0 .758.758l1-1q.8-.793 1.594-1.585l.368-.366a.539.539 0 0 0-.379-.915h-2.968a.544.544 0 0 0-.536.536v2.952a.536.536 0 0 0 1.072 0z" class="cls-4" data-name="Path 61968" transform="translate(-707.5 -118.4)"/>
                    </g>
                </g>
                <g id="Group_60485" data-name="Group 60485" transform="translate(11.964 6.672)">
                    <g id="Group_60484" data-name="Group 60484">
                        <path id="Path_61969" d="M708.572 386.588v-2.952l-.536.536H711l-.379-.915-1 1q-.8.793-1.594 1.585l-.368.366a.536.536 0 0 0 .758.758l1-1q.8-.793 1.594-1.585l.368-.366a.539.539 0 0 0-.379-.915h-2.968a.544.544 0 0 0-.536.536v2.952a.536.536 0 1 0 1.072 0z" class="cls-4" data-name="Path 61969" transform="translate(-707.5 -383.1)"/>
                    </g>
                </g>
                <g id="Group_60487" data-name="Group 60487" transform="translate(11.964 11.951)">
                    <g id="Group_60486" data-name="Group 60486">
                        <path id="Path_61970" d="M708.572 651.188v-2.952l-.536.536H711l-.379-.915-1 1q-.8.793-1.594 1.585l-.368.366a.536.536 0 1 0 .758.758l1-1q.8-.793 1.594-1.585l.368-.366a.539.539 0 0 0-.379-.915h-2.968a.544.544 0 0 0-.536.536v2.952a.536.536 0 1 0 1.072 0z" class="cls-4" data-name="Path 61970" transform="translate(-707.5 -647.7)"/>
                    </g>
                </g>
                <g id="Group_60489" data-name="Group 60489" transform="translate(0 1.439)">
                    <g id="Group_60488" data-name="Group 60488">
                        <path id="Path_61971" d="M94.587 121.888v-2.952a.544.544 0 0 0-.536-.536h-2.969a.539.539 0 0 0-.379.915l1 1q.8.793 1.594 1.585l.368.366a.536.536 0 0 0 .758-.758l-1-1q-.8-.793-1.594-1.585l-.368-.366-.379.915h2.968l-.536-.536v2.952a.536.536 0 0 0 1.072 0z" class="cls-4" data-name="Path 61971" transform="translate(-90.55 -118.4)"/>
                    </g>
                </g>
                <g id="Group_60491" data-name="Group 60491" transform="translate(0 6.672)">
                    <g id="Group_60490" data-name="Group 60490">
                        <path id="Path_61972" d="M94.587 386.588v-2.952a.544.544 0 0 0-.536-.536h-2.969a.539.539 0 0 0-.379.915l1 1q.8.793 1.594 1.585l.368.366a.536.536 0 0 0 .758-.758l-1-1q-.8-.793-1.594-1.585l-.368-.366-.379.915h2.968l-.536-.536v2.952a.536.536 0 0 0 1.072 0z" class="cls-4" data-name="Path 61972" transform="translate(-90.55 -383.1)"/>
                    </g>
                </g>
                <g id="Group_60493" data-name="Group 60493" transform="translate(0 11.951)">
                    <g id="Group_60492" data-name="Group 60492">
                        <path id="Path_61973" d="M94.587 651.188v-2.952a.544.544 0 0 0-.536-.536h-2.969a.539.539 0 0 0-.379.915l1 1q.8.793 1.594 1.585l.368.366a.536.536 0 1 0 .758-.758l-1-1q-.8-.793-1.594-1.585l-.368-.366-.379.915h2.968l-.536-.536v2.952a.536.536 0 0 0 1.072 0z" class="cls-4" data-name="Path 61973" transform="translate(-90.55 -647.7)"/>
                    </g>
                </g>
            </g>
            <path id="Rectangle_6792" d="M0 0h20v20H0z" class="cls-1" data-name="Rectangle 6792" transform="translate(-5488 -22836)"/>
        </g>
    </g>
</svg>`,
  [WEATHER_MAP_IMAGE_KEY]: `
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
<circle cx="20" cy="20" r="18" fill="#62c400" stroke="white" stroke-width="2"/>
<g id="ic_weather" transform="translate(10, 8)" fill="white">
        <path id="Path_72909" d="M17.439 7.737a.769.769 0 0 0 .544-.221l1.088-1.069a.748.748 0 0 0 .011-1.058l-.011-.011a.779.779 0 0 0-1.088 0L16.9 6.447a.746.746 0 0 0-.014 1.053l.014.014a.77.77 0 0 0 .545.221zM5.077 12.326a.765.765 0 0 0-.769-.756H2.769a.756.756 0 0 0-.055 1.511h1.593a.764.764 0 0 0 .77-.752zm.94-4.805A.776.776 0 0 0 7.1 7.51a.747.747 0 0 0 .007-1.055L7.1 6.448 6.016 5.379a.779.779 0 0 0-1.088 0 .746.746 0 0 0-.014 1.055l.014.014zm5.983-2a.763.763 0 0 0 .769-.756V3.253a.769.769 0 0 0-1.538 0v1.511a.764.764 0 0 0 .769.759zm9.231 6.047h-1.539a.756.756 0 0 0-.055 1.511h1.593a.756.756 0 1 0 .055-1.511h-.054z" class="cls-2" data-name="Path 72909" transform="translate(1 -1)"/>
        <g id="Group_68634" data-name="Group 68634" transform="translate(1 -1)">
            <path id="Path_73003" d="M8.059 11.614A3.991 3.991 0 1 1 14.4 15.38a4.091 4.091 0 0 1 1.156.327A4.991 4.991 0 1 0 7.03 11.9a5.528 5.528 0 0 1 .526-.2c.165-.042.335-.054.503-.086z" class="cls-2" data-name="Path 73003"/>
            <path id="Path_73004" d="M15.555 15.707a4.091 4.091 0 0 0-1.155-.327c-.045-.005-.087-.021-.132-.025h-.128a5.7 5.7 0 0 0-2.5-3.125 5.128 5.128 0 0 0-3.581-.616c-.168.032-.338.044-.5.092a5.528 5.528 0 0 0-.526.2 5.653 5.653 0 0 0-3.174 3.451l-.132-.005a4.09 4.09 0 0 0 .011 8.148h10.529a4.079 4.079 0 0 0 1.288-7.8zm-1.293 6.7H3.752a3 3 0 0 1-.028-5.967 2.052 2.052 0 0 1 .4.048l.495.107.127-.5a4.6 4.6 0 0 1 2.344-3.009 4.542 4.542 0 0 1 .76-.326c.067-.02.136-.021.2-.037a4.086 4.086 0 0 1 3.067.456 4.66 4.66 0 0 1 2.1 2.81c.01.037.029.07.038.108l.127.5.493-.107a2.253 2.253 0 0 1 .384-.048c.089.009.173.033.259.05a2.993 2.993 0 0 1-.259 5.917z" class="cls-2" data-name="Path 73004"/>
        </g>
    </g>
</svg>`,
  [SIGN_ROAD_WORKS_MAP_IMAGE_KEY]: `
<svg xmlns="http://www.w3.org/2000/svg" width="834.116" height="734.423"  version="1.1">
    <path style="fill:#ffffff;" d="m 0,689.423 c 0,24.853 20.147,45 45,45 l 744.116,0 c 24.853,0 45,-20.147 45,-45 c 0,-7.899 -2.079,-15.659 -6.029,-22.5 l -372.058,-644.423 c -8.038,-13.923 -22.894,-22.5 -38.971,-22.5 c -16.077,0 -30.933,8.577 -38.971,22.5 l -372.058,644.423 c -3.950,6.841 -6.029,14.601 -6.029,22.5 z"/>
    <path style="fill:none;stroke:#000000;stroke-width:2px;" d="m 1,689.423 c 0,24.301 19.699,44 44,44 l 744.116,0 c 24.301,0 44,-19.699 44,-44 c 0,-7.724 -2.033,-15.311 -5.895,-22.0 l -372.058,-644.423 c -7.860,-13.614 -22.385,-22.0 -38.105,-22.0 c -15.720,0 -30.245,8.386 -38.105,22.0 l -372.058,644.423 c -3.862,6.689 -5.895,14.276 -5.895,22.0 z"/>
    <path style="fill:#ff0000;" d="m 10,689.423 c 0,19.330 15.670,35 35,35 l 744.116,0 c 19.330,0 35,-15.670 35,-35 c 0,-6.144 -1.617,-12.179 -4.689,-17.5 l -372.058,-644.423 c -6.252,-10.829 -17.807,-17.5 -30.311,-17.5 c -12.504,0 -24.059,6.671 -30.311,17.5 l -372.058,644.423 c -3.072,5.321 -4.689,11.357 -4.689,17.5 z m 83.634,-33.852 c 0,-1.755 0.462,-3.480 1.340,-5.000 l 313.424,-542.866 c 1.786,-3.094 5.087,-5 8.660,-5.000 c 3.573,0 6.873,1.906 8.660,5.000 l 313.424,542.866 c 0.878,1.520 1.340,3.245 1.340,5.000 c 0,5.523 -4.477,10 -10,10 l -626.848,0 c -5.523,0 -10,-4.477 -10,-10 z"/>
    <path style="fill:#000000;" d="m 452.632,261.064 c -16.811,0 -26.967,12.680 -26.967,29.377 c 0,25.762 22.951,61.852 40.279,61.852 c 17.328,0 36.606,-17.500 36.606,-41.197 c 0,-19.680 -19.967,-50.033 -49.918,-50.033 z"/>
    <path style="fill:#000000;" d="m 430.829,621.965 c 0,11.408 9.248,20.656 20.656,20.656 c 11.408,0 20.656,-9.248 20.656,-20.656 l 0,-91.839 c 0.382,0.024 0.765,0.036 1.148,0.036 c 6.203,0 11.987,-3.132 15.376,-8.327 l 86.447,49.910 c -34.017,22.821 -52.631,10.633 -97.226,70.875 l 230.166,0 l -80.427,-139.304 c -17.123,19.357 -20.333,36.478 -41.128,59.101 l -95.376,-55.065 l -37.731,-140.816 c -0.495,-1.847 -1.444,-3.542 -2.760,-4.929 l -36.951,-39.016 c -2.167,-2.283 -5.176,-3.576 -8.324,-3.576 l -72.754,0 c -4.100,0 -7.888,2.187 -9.938,5.738 l -48.422,83.885 c -1.611,2.791 -2.460,5.957 -2.460,9.180 c 0,10.140 8.220,18.361 18.361,18.361 c 6.560,0 12.621,-3.500 15.901,-9.180 l 4.590,-7.950 l 26.037,15.033 l -8.033,13.913 c -1.007,1.744 -1.537,3.723 -1.537,5.738 c 0,4.100 2.187,7.888 5.738,9.938 l 97.992,56.576 z m -113.312,-214.842 l 29.668,-51.386 l 34.717,0 l -38.347,66.419 z m 81.967,44.408 l 26.404,-45.733 l 19.329,72.138 z"/>
    <path style="fill:#000000;" d="m 284.416,611.637 c -1.813,3.140 -2.767,6.702 -2.767,10.328 c 0,11.408 9.248,20.656 20.656,20.656 c 7.380,0 14.199,-3.937 17.888,-10.328 l 57.461,-99.838 c 1.007,-1.744 1.537,-3.723 1.537,-5.738 c 0,-0.468 -0.029,-0.935 -0.085,-1.399 l -3.216,-26.192 l -46.388,-26.782 l 6.197,50.468 z"/>
</svg>`,
  [SIGN_ROUGH_ROAD_MAP_IMAGE_KEY]: `
<svg xmlns="http://www.w3.org/2000/svg" width="834.116" height="734.423" version="1.1">
<path style="fill:#ffffff;" d="m 0,689.423 c 0,24.853 20.147,45 45,45 l 744.116,0 c 24.853,0 45,-20.147 45,-45 c 0,-7.899 -2.079,-15.659 -6.029,-22.5 l -372.058,-644.423 c -8.038,-13.923 -22.894,-22.5 -38.971,-22.5 c -16.077,0 -30.933,8.577 -38.971,22.5 l -372.058,644.423 c -3.950,6.841 -6.029,14.601 -6.029,22.5 z"/>
<path style="fill:none;stroke:#000000;stroke-width:2px;" d="m 1,689.423 c 0,24.301 19.699,44 44,44 l 744.116,0 c 24.301,0 44,-19.699 44,-44 c 0,-7.724 -2.033,-15.311 -5.895,-22.0 l -372.058,-644.423 c -7.860,-13.614 -22.385,-22.0 -38.105,-22.0 c -15.720,0 -30.245,8.386 -38.105,22.0 l -372.058,644.423 c -3.862,6.689 -5.895,14.276 -5.895,22.0 z"/>
<path style="fill:#ff0000;" d="m 10,689.423 c 0,19.330 15.670,35 35,35 l 744.116,0 c 19.330,0 35,-15.670 35,-35 c 0,-6.144 -1.617,-12.179 -4.689,-17.5 l -372.058,-644.423 c -6.252,-10.829 -17.807,-17.5 -30.311,-17.5 c -12.504,0 -24.059,6.671 -30.311,17.5 l -372.058,644.423 c -3.072,5.321 -4.689,11.357 -4.689,17.5 z m 83.634,-33.852 c 0,-1.755 0.462,-3.480 1.340,-5.000 l 313.424,-542.866 c 1.786,-3.094 5.087,-5 8.660,-5.000 c 3.573,0 6.873,1.906 8.660,5.000 l 313.424,542.866 c 0.878,1.520 1.340,3.245 1.340,5.000 c 0,5.523 -4.477,10 -10,10 l -626.848,0 c -5.523,0 -10,-4.477 -10,-10 z"/>
<path style="fill:#000000;" d="m 112.153,650.653 l 58.769,-101.792 c 5.128,1.272 10.663,1.956 16.629,1.956 c 27.541,0 45.901,-14.550 57.377,-34.426 c 11.475,-19.876 29.836,-34.426 57.376,-34.426 c 27.541,0 45.902,14.550 57.377,34.426 c 11.476,19.876 29.836,34.426 57.377,34.426 c 27.541,0 45.901,-14.550 57.377,-34.426 c 11.475,-19.876 29.836,-34.426 57.377,-34.426 c 27.540,0 45.901,14.550 57.376,34.426 c 11.476,19.876 29.836,34.426 57.377,34.426 c 5.966,0 11.501,-0.684 16.629,-1.956 l 58.769,101.792 z"/>
</svg>
`,
  [SIGN_OTHER_DANGERS_MAP_IMAGE_KEY]: `
<svg
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="634.14001"
   height="561.22998"
   id="svg2"
   version="1.1"
   inkscape:version="0.48.4 r9939"
   sodipodi:docname="1.32.svg">
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-491.13214)">
    <path
       id="path19629"
       style="opacity:1;color:#000000;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1px;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
       d="m 339.56545,497.17249 c -21.52311,-12.42634 -49.04475,-5.052 -61.47117,16.47128 L 6.0363923,984.86209 c -12.42642,21.52311 -5.05209,49.04471 16.4710997,61.47111 6.84091,3.9496 14.60091,6.0289 22.50008,6.0289 l 544.115798,-10e-5 c 7.89926,0 15.65926,-2.0793 22.50008,-6.0289 21.52319,-12.4264 28.89761,-39.948 16.47119,-61.47109 l -272.058,-471.21824 c -3.94962,-6.84091 -9.63028,-12.52165 -16.47119,-16.47128 z"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="sccsccsccs" />
    <path
       sodipodi:type="arc"
       style="opacity:1;color:#000000;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1px;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
       id="path19631"
       sodipodi:cx="-321.58774"
       sodipodi:cy="803.42432"
       sodipodi:rx="122.47533"
       sodipodi:ry="93.338097"
       d="m -199.11241,803.42432 a 122.47533,93.338097 0 1 1 -244.95065,0 122.47533,93.338097 0 1 1 244.95065,0 z"
       transform="matrix(0.35799687,0,0,0.46975228,432.1929,560.95272)" />
    <path
       sodipodi:nodetypes="csscccc"
       inkscape:connector-curvature="0"
       d="m 273.59482,716.08807 c -3.16073,-24.00819 13.73946,-46.03296 37.74766,-49.19369 24.00819,-3.16074 46.03296,13.73946 49.1937,37.74765 0.50014,3.79895 0.50014,7.64709 0,11.44604 l -21.1817,160.89093 -44.57797,0 z"
       style="opacity:1;color:#000000;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3.54299997999999980;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
       id="path19633" />
    <path
       id="path5791"
       style="opacity:1;fill:none;fill-opacity:1;stroke:#000000;stroke-opacity:1;stroke-width:2;stroke-miterlimit:4;stroke-dasharray:none"
       d="m 1.0074138,1007.3622 c 5.7e-5,-7.72358 2.033128,-15.31118 5.894964,-21.99998 L 278.96019,514.14412 c 12.15026,-21.0448 39.06025,-28.2554 60.10512,-16.1052 6.68883,3.8618 12.2433,9.4163 16.10512,16.1052 l 272.05769,471.2181 c 3.86184,6.6888 5.89491,14.2764 5.89497,21.99998 -7e-5,24.3005 -19.69961,43.9999 -44.00009,44 l -544.115503,0 c -24.300475,-10e-5 -44.0000132,-19.6995 -44.0000832,-44 z"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="cccscccccc" />
    <path
       style="opacity:1;fill:#ff0000;fill-opacity:1;stroke:none"
       d="M 316.3125 10 C 304.49402 10.264781 293.09264 16.514178 286.75 27.5 L 14.6875 498.71875 C 5.0225195 515.45903 10.75976 536.86625 27.5 546.53125 C 32.82067 549.60315 38.85622 551.21875 45 551.21875 L 589.125 551.21875 C 595.26878 551.21875 601.30433 549.60315 606.625 546.53125 C 623.36524 536.86625 629.10248 515.45903 619.4375 498.71875 L 347.375 27.5 C 344.30311 22.1793 339.88318 17.7594 334.5625 14.6875 C 328.80805 11.365156 322.50313 9.8613054 316.3125 10 z M 316.375 70.25 C 318.29628 70.114265 320.2689 70.558188 322.0625 71.59375 C 323.58269 72.47135 324.84108 73.7283 325.71875 75.25 L 567.28125 493.625 C 570.04221 498.40555 568.40793 504.51975 563.625 507.28125 C 562.10481 508.15885 560.38027 508.625 558.625 508.625 L 75.53125 508.625 C 70.008436 508.625 65.53125 504.1479 65.53125 498.625 C 65.53125 496.86955 65.967441 495.1428 66.84375 493.625 L 308.40625 75.25 C 310.13186 72.261187 313.17286 70.476225 316.375 70.25 z "
       transform="translate(0,491.13214)"
       id="path4410" />
  </g>
</svg>
`,
  [FLOODING_MAP_IMAGE_KEY]: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs>
            <style>.flooding-map-layer-cycle{fill:#1621f5;}.flooding-map-layer-path{fill:#fff;}</style>
        </defs>
        <circle class="flooding-map-layer-cycle" cx="12" cy="12" r="11.5" />
        <path class="flooding-map-layer-path" d="M12,1A11,11,0,1,1,1,12,11,11,0,0,1,12,1m0-1A12,12,0,1,0,24,12,12,12,0,0,0,12,0Z" />
        <path class="flooding-map-layer-path" d="M19.38,9.88c-.56,0-1.17-.63-1.76-1.23s-1.27-1.3-1.94-1.3-1.3.66-1.92,1.3S12.57,9.88,12,9.88s-1.18-.63-1.77-1.23S9,7.35,8.3,7.35,7,8,6.37,8.65,5.18,9.88,4.62,9.88A.11.11,0,0,0,4.5,10V11.5a.12.12,0,0,0,.12.12c.66,0,1.3-.67,1.92-1.31S7.73,9.08,8.3,9.08s1.18.63,1.77,1.23,1.27,1.31,1.94,1.31,1.3-.67,1.92-1.31,1.19-1.23,1.75-1.23,1.18.63,1.77,1.23,1.27,1.31,1.93,1.31a.12.12,0,0,0,.12-.12V10A.11.11,0,0,0,19.38,9.88Z" />
        <path class="flooding-map-layer-path" d="M19.38,14.92c-.56,0-1.17-.63-1.76-1.23s-1.27-1.31-1.94-1.31-1.3.67-1.92,1.31S12.57,14.92,12,14.92s-1.18-.63-1.77-1.23S9,12.38,8.3,12.38,7,13.05,6.37,13.69s-1.19,1.23-1.75,1.23A.11.11,0,0,0,4.5,15v1.51a.11.11,0,0,0,.12.11c.66,0,1.3-.66,1.92-1.3s1.19-1.23,1.76-1.23,1.18.63,1.77,1.23,1.27,1.3,1.94,1.3,1.3-.66,1.92-1.3,1.19-1.23,1.75-1.23,1.18.63,1.77,1.23,1.27,1.3,1.93,1.3a.11.11,0,0,0,.12-.11V15A.11.11,0,0,0,19.38,14.92Z" />
    </svg>
    `,
  [TELEMETRY_MAP_IMAGE_KEY]: `<?xml version="1.0" encoding="iso-8859-1"?>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 12" style="enable-background:new 0 0 12 12" xml:space="preserve">
        <g>
            <path style="fill:black" d="M6,0c0,0-4,5.685-4,8.211C2,10.305,3.791,12,6,12s4-1.695,4-3.789C10,5.685,6,0,6,0z"/>
        </g>
    </svg>`,
  [TELEMETRY_MAP_UNMATCHED_IMAGE_KEY]: `<?xml version="1.0" encoding="iso-8859-1"?>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 12" style="enable-background:new 0 0 12 12" xml:space="preserve">
        <g>
            <path style="fill:red" d="M6,0c0,0-4,5.685-4,8.211C2,10.305,3.791,12,6,12s4-1.695,4-3.789C10,5.685,6,0,6,0z"/>
        </g>
    </svg>`,
};
