import { useState } from "react";

export const useLocalState = <T>(initialState: T) => {
  const [state, setStateDirectly] = useState<T>(initialState);

  const setState = (statePart: Partial<T>) => {
    setStateDirectly({ ...state, ...(statePart as T) });
  };

  return {
    state,
    setState,
  };
};
