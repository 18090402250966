/* eslint-disable no-bitwise */
import * as RouterTYPES from "../../api/router/model/models";

/**
 * Формирует хэш из входных параметров для запроса
 * @param model - модель для запроса
 */
export function getHashIsochrone(model: RouterTYPES.IsochronesInput): number {
  const { location, contours, reverse, from, to, externalSystemIDs, withTraffic, useTrafficLayers } = model;

  let string = "";
  string += `${location ? location.lng : ""}`;
  string += `${location ? location.lat : ""}`;
  string += `${contours ? contours.join("") : ""}`;
  string += `${externalSystemIDs ? externalSystemIDs.join("") : ""}`;
  string += `${reverse}`;
  string += `${from}`;
  string += `${to}`;
  string += `${withTraffic}`;
  string += `${useTrafficLayers}`;

  return string.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

interface HashIsochrone {
  [key: number]: Array<GeoJSON.FeatureCollection>;
}

/** Используется для хранения уже загруженных данных */
export const hashIsochrone: HashIsochrone = {};
