// @ts-nocheck
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { intervalToDuration } from "date-fns";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { batch } from "react-redux";
import { GState } from "documentations";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import * as ROUTE_ACTIONS from "features/ctrl-route/store/actions";
import { ROUTE_PATH_ALTERNATIVE } from "map-helpers/order-layers";
import { RouterAPI } from "api/router";
import { GeocoderAPI } from "api/geocoder";
import { CHANGE_SELECTED_DAY, ES_UPDATE_SELECTED } from "old-store/view/action-types";
import { decodeShape } from "utils/shape-to-geojson";
import { addHistoryAC } from "old-store/user-history/action-creators";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { RouteCosting, RouteInput, TrafficUse } from "api/router/model/router";
import * as TYPES from "old-store/global/types";
import { removeRouteManeuver } from "../components/route-container/components/route-instructions/draw-route-maneuver";
import { roundUp } from "../components/route-container/common-components/route-difference-time/route-difference-time";
import { RouteResponse, TIME_STATUS } from "../types";
import RouteMapController from "../controller/route-map-controller";
import moment from "moment";
import { roadDetectorSlice } from "../../ctrl-road-detector";

let routeMapController: RouteMapController | null;
let _store: MiddlewareAPI<any, GState>;
let map: mapboxgl.Map;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
  routeMapController = new RouteMapController(_map, _store);
});

let unitsCountRouteUpdateTimer: number | undefined;
let prevActiveRouteIndex: number = 0;

const fetchRoute =
  (store: MiddlewareAPI<Dispatch<AnyAction>, GState>, action: any) =>
  async (saveRoute = false) => {
    return new Promise(async (resolve) => {
      const {
        router: {
          points,
          path,
          useTrafficLayers,
          timeStatus,
          isActive,
          costing,
          isRouteWithMetro,
          isCompareWithYandex,
        },
        view: { to, es },
      } = store.getState();

      if (!isActive) return;
      routeMapController?.updatePoints();
      const pointsWithValue = points.filter((el) => el.coor);

      batch(() => {
        if (store.getState().router.isRouteLengthError) {
          store.dispatch(ROUTE_SLICE.routerSlice.actions.setIsRouteLengthError(false));
          routeMapController?.setIsRouteLengthError(false);
        }

        store.dispatch(ROUTE_SLICE.routerSlice.actions.setErrorVariants(null));
        store.dispatch(ROUTE_SLICE.setRouteVariants(null));
        routeMapController?.removeRoute();
        routeMapController?.cursorDefault();
        store.dispatch(
          ROUTE_SLICE.setRouteIsLoading(costing === RouteCosting.Template || pointsWithValue.length === points.length)
        );
        store.dispatch(ROUTE_SLICE.setActiveIndexRoute(0));
      });

      if (pointsWithValue.length === points.length || costing === RouteCosting.Template) {
        const model: RouteInput = {
          locations: pointsWithValue.map((point) => point.coor!),
          zoom: Math.round(map?.getZoom()),
          costing: costing === RouteCosting.Cargo ? RouteCosting.Auto : costing,
          useTruckGraph: costing === RouteCosting.Cargo,
          saveRoute: saveRoute,
        };
        const {
          roadDetector: { isRoadDetectorMode, activeFiltersIds },
        } = store.getState();

        isRoadDetectorMode
          ? (model.vehicleClassTypes = {
              vehicleClassIDs: activeFiltersIds,
            })
          : (model.trafficTypes = {
              externalSystemIDs: es,
            });

        if (costing === RouteCosting.Multimodal) {
          model.transitMode = Number(!isRouteWithMetro);
        }

        if (timeStatus !== "last") {
          model.trafficTime = to;
        }

        RouterAPI.router.cancelRoute();

        try {
          const getPrepareModel = () => {
            let _model = {
              ...model,
              trafficUse: useTrafficLayers && TIME_STATUS.last === timeStatus ? TrafficUse.Layers : TrafficUse.Routing,
              showComparison: isCompareWithYandex,
            };

            if (model.costing === RouteCosting.Template) {
              const locations = path.filter((el) => el.coor).map((point) => point.coor!);
              _model = {
                ..._model,
                locations,
                costing: RouteCosting.Auto,
                zoom: 19,
              };
            }

            return _model;
          };

          const currentRouteApi = store.getState().roadDetector.isRoadDetectorMode
            ? RouterAPI.router.routeDetector
            : RouterAPI.router.route;

          const routeWithTraffic = await currentRouteApi(getPrepareModel());
          routeWithTraffic.withTraffic = true;
          routeWithTraffic.original.trip.summary.time = roundUp(routeWithTraffic.original.trip.summary.time, 60);
          routeWithTraffic.original.trip.summary.compare_time = roundUp(
            routeWithTraffic.original.trip.summary.compare_time,
            60
          );

          if (routeWithTraffic.comparison) {
            const { score, comparison: route } = routeWithTraffic;

            const yandexTime = intervalToDuration({ start: 0, end: routeWithTraffic.comparison.time * 1000 });
            const dtmTime = intervalToDuration({ start: 0, end: routeWithTraffic.time * 1000 });
            const time = `${yandexTime.hours ? yandexTime.hours + " ч" : ""} ${
              yandexTime.minutes ? yandexTime.minutes + " мин" : ""
            }`;

            const arrivalTime = moment().add("seconds", routeWithTraffic.comparison.time).format("HH:mm");

            const minutes = yandexTime.hours * 60 + yandexTime.minutes - (dtmTime.hours * 60 + dtmTime.minutes);

            const diffTime = minutes > 0 ? `+${minutes}` : minutes;

            store.dispatch(
              ROUTE_SLICE.setYandexVariant({
                route,
                time,
                score,
                diffTime,
                arrivalTime,
              })
            );
          }

          if (routeWithTraffic.length <= 0.05) {
            store.dispatch(ROUTE_SLICE.routerSlice.actions.setIsRouteLengthError(true));
            return routeMapController?.setIsRouteLengthError(true, routeWithTraffic.length);
          }

          /** Реверсим путь, чтобы слои на карте ложились в правильном направлении */
          routeWithTraffic.paintedPath?.features.reverse();

          const routes = [routeWithTraffic].map((route) => ({
            ...route,
            paintedPath: {
              ...route.paintedPath,
              features: route.paintedPath?.features.map((feature) => ({
                ...feature,
                properties: { ...feature.properties, unitsCountColor: null },
              })),
            },
          }));

          if (!useTrafficLayers) {
            const routeWithoutTraffic = await currentRouteApi({
              ...getPrepareModel(),
              trafficUse: useTrafficLayers ? TrafficUse.Layers : TrafficUse.Compare,
            });
            routeWithoutTraffic.withTraffic = false;
            routeWithoutTraffic.original.trip.summary.time = roundUp(
              routeWithoutTraffic.original.trip.summary.time,
              60
            );
            routeWithoutTraffic.original.trip.summary.compare_time = roundUp(
              routeWithoutTraffic.original.trip.summary.compare_time,
              60
            );
            routeWithoutTraffic.paintedPath?.features.reverse();

            /** Проверяем одинаковый маршрут или нет */
            const routeWithoutTrafficShape = routeWithoutTraffic.original.trip.legs[0].shape;
            const routeWithTrafficShape = routeWithTraffic.original.trip.legs[0].shape;
            if (routeWithoutTrafficShape !== routeWithTrafficShape && !isCompareWithYandex) {
              routes.push(routeWithoutTraffic);
            }
          }

          store.dispatch(ROUTE_SLICE.setRouteVariants(routes));
          routeMapController?.updateRoute(
            Boolean(
              ROUTE_SLICE.routerSlice.actions.setCosting.match(action) ||
                ROUTE_SLICE.routerSlice.actions.setTemplateId.match(action)
            )
          );
          routeMapController?.updateIntermediatePointsCoordinates();
          resolve(routes);
        } catch (error) {
          if (error.message !== "canceled")
            store.dispatch(
              ROUTE_SLICE.routerSlice.actions.setErrorVariants(
                "Ошибка при выполнении запроса. Нажмите, чтобы попробовать еще раз."
              )
            );

          console.error(error);
        } finally {
          store.dispatch(ROUTE_SLICE.setRouteIsLoading(false));
        }
      }
    });
  };

const fetchAddress = (store: MiddlewareAPI<Dispatch<AnyAction>>) => async (key: string, location: mapboxgl.LngLat) => {
  store.dispatch(ROUTE_SLICE.setPointCoor({ key, coor: location }));
  store.dispatch(ROUTE_SLICE.setPointIsLoading({ key, isLoading: true }));

  try {
    const address = await GeocoderAPI.geocode.address(location);

    batch(() => {
      store.dispatch(
        // @ts-ignore
        addHistoryAC({
          options: { location, address },
        })
      );

      store.dispatch(ROUTE_SLICE.setPointAddress({ key, address }));
    });
  } catch (error) {
    console.error(error);
    store.dispatch(ROUTE_SLICE.setPointError({ key, error: "Ошибка" }));
  } finally {
    store.dispatch(ROUTE_SLICE.setPointIsLoading({ key, isLoading: false }));
  }
};

export const routerMiddleware: Middleware<any, GState> = (store) => (next) => async (action) => {
  const result = next(action);
  const getRoute = fetchRoute(store, action);
  const getAddress = fetchAddress(store);

  if (!_store) _store = store;

  const {
    router: { isActive, isDisabled, isSimulatedDtp, activeIndexRoute },
    sectorAnalysis: { unitsCountDiagramParams, isActive: sectorAnalysisIsActive },
  } = store.getState();

  if (isActive && !isDisabled) routeMapController?.addListeners();
  else routeMapController?.removeListeners();

  if (roadDetectorSlice.actions.toggleIsRoadDetectorMode.match(action)) {
    getRoute();
  }

  if (ROUTE_SLICE.setSimulatedDtp.match(action)) {
    if (isSimulatedDtp) {
      getRoute(true).then((routes) => {
        if (!routes) return;
        const state = _store.getState();
        const route = routes?.[activeIndexRoute];
        const [lng, lat] = state.router.simulatedDtp;
        const body = {
          routeId: route.routeID,
          lng,
          lat,
        };

        const changeRoute = (routes: RouteResponse[] | null, newRoute: RouteResponse) =>
          routes?.map((route) => {
            if (route.routeID !== body.routeId) return route;
            return { ...newRoute, withTraffic: route.withTraffic };
          });

        RouterAPI.router.simulateIncident(state.oidc.user.access_token, body).then((routeWithSimDtp) => {
          store.dispatch(ROUTE_SLICE.setActiveIndexRoute(activeIndexRoute));
          const newRoutesVariants = changeRoute(state.router.routeVariants, routeWithSimDtp);
          store.dispatch(ROUTE_SLICE.setRouteVariants(newRoutesVariants));
          routeMapController?.updateRoute(false);
        });
      });
    } else {
      getRoute();
    }
  }

  if (ROUTE_SLICE.setIsShowYandexRoute.match(action)) {
    routeMapController?.updateRoute(false);
  }

  if (ROUTE_SLICE.setIsCompareWithYandex.match(action)) {
    getRoute();
  }

  const getActivePoint = () => {
    const {
      router: { points, path, costing, activePointKey },
    } = store.getState();

    const positions = costing === RouteCosting.Template ? path : points;

    const activePoint = positions.find((el) => activePointKey === el.key && !el.coor);
    if (activePoint) return activePoint;
    const pointWithoutCoor = positions.find((el) => !el.coor);
    return pointWithoutCoor;
  };

  const updateTrafficCheckboxDisabled = () => {
    const {
      router: { timeStatus },
    } = store.getState();

    if (timeStatus !== TIME_STATUS.last) store.dispatch(ROUTE_SLICE.setUseTrafficLayersIsDisabled(true));
    else store.dispatch(ROUTE_SLICE.setUseTrafficLayersIsDisabled(false));
  };

  const addIntermediatePoint = (location: mapboxgl.LngLat, key: string) => {
    const {
      router: { points, path, costing, routeVariants, activeIndexRoute },
    } = store.getState();

    const positions = costing === RouteCosting.Template ? path : points;

    if (routeVariants) {
      const activeVariant = routeVariants[activeIndexRoute];
      const pointsCoordiantes = decodeShape(activeVariant.original.trip.legs[0].shape);
      const line = turf.lineString(pointsCoordiantes);
      const point = turf.point(location.toArray());

      const {
        properties: { index: newPointIndex },
      } = turf.nearestPointOnLine(line, point);

      const existingPoints = positions.reduce((acc, el) => {
        if (el.coor) {
          const pt = turf.point(el.coor.toArray());

          const {
            properties: { index },
          } = turf.nearestPointOnLine(line, pt);

          acc.push({ key: el.key, index });
        }

        return acc;
      }, [] as { key: string; index: number | undefined }[]);

      const keyBeforePaste = (existingPoints || ([] as typeof existingPoints)).reduce((acc: string | undefined, cv) => {
        if (!acc && cv.index && newPointIndex! < cv.index) acc = cv?.key;
        return acc;
      }, undefined);

      if (keyBeforePaste) {
        store.dispatch(ROUTE_SLICE.addIntermediatePoint({ key, keyBeforePaste }));
      }
    }
  };

  if (sectorAnalysisSlice.actions.setIsActive.match(action)) {
    if (sectorAnalysisIsActive) {
      getRoute(true).then(() => {
        routeMapController?.boundMap();
      });
    }
    routeMapController?.setIsUnitsCountVisibility(false);
    routeMapController?.boundMap();
  }

  if (sectorAnalysisSlice.actions.setIsUnitsCountDiagram.match(action)) {
    routeMapController?.setIsUnitsCountVisibility(action.payload);
  }

  if (sectorAnalysisSlice.actions.setUnitsCountDiagramParams.match(action)) {
    routeMapController?.setUnitsCountDiagramParams(action.payload);
  }

  if (sectorAnalysisSlice.actions.setUnitsCountDiagramLimits.match(action)) {
    unitsCountRouteUpdateTimer = window.setTimeout(() => {
      routeMapController?.setUnitsCountDiagramParams(unitsCountDiagramParams);
      window.clearTimeout(unitsCountRouteUpdateTimer);
    }, 100);
  }

  if (action.type === ROUTE_ACTIONS.mapClick.type) {
    const features: mapboxgl.MapboxGeoJSONFeature[] = action.payload;

    if (features && features.length) {
      const feature = features.shift();

      if (feature?.layer.id === ROUTE_PATH_ALTERNATIVE) {
        const index = feature?.properties?.index!;

        if (typeof index === "number") store.dispatch(ROUTE_SLICE.setActiveIndexRoute(index));
      }
    }
  }

  if (action.type === TYPES.RULER_UPDATE_IS_ACTIVE) {
    store.dispatch(ROUTE_SLICE.setIsDisable(action.payload));
  }

  if (action.type === ROUTE_ACTIONS.addIntermediatePoint.type) {
    const { location, key } = action.payload;
    store.dispatch(ROUTE_SLICE.setIsRouteTimeVariantsOpen(false));
    addIntermediatePoint(location, key);
  }

  if (action.type === ROUTE_SLICE.routerSlice.actions.setIsRouteDiagram.type) {
    routeMapController?.setIsRouteDiagram(action.payload);
  }

  if (action.type === ROUTE_ACTIONS.mapClickNewPoint.type) {
    const location = action.payload;
    store.dispatch(ROUTE_SLICE.setIsRouteTimeVariantsOpen(false));
    const point = getActivePoint();

    if (point) {
      const { key } = point;
      getAddress(key, location);
    }
  }

  if (action.type === ROUTE_ACTIONS.updatePoint.type) {
    const { location, key } = action.payload;
    store.dispatch(ROUTE_SLICE.setIsRouteTimeVariantsOpen(false));
    RouterAPI.router.cancelRoute();
    routeMapController?.removeRoute();
    getAddress(key, location);
  }

  if (ROUTE_SLICE.fetchRouteWithSaving.match(action)) {
    prevActiveRouteIndex = activeIndexRoute;
    getRoute(true).then(() => {
      store.dispatch(ROUTE_SLICE.setActiveIndexRoute(prevActiveRouteIndex));
      store.dispatch(ROUTE_SLICE.setIsRouteTimeVariantsOpen(true));
    });
  }

  if (action.type === ROUTE_SLICE.setRouteTimeVariant.type) {
    routeMapController?.updateRouteTimeVariant();
  }

  if (
    action.type === ROUTE_SLICE.exchangePoints.type ||
    action.type === ROUTE_SLICE.onDragEnd.type ||
    action.type === ROUTE_SLICE.setUseTrafficLayers.type ||
    action.type === ROUTE_SLICE.setTemplateId.type ||
    // ROUTE_SLICE.setIsSaveRoute.match(action) ||
    ROUTE_SLICE.setIsRouteWithMetro.match(action) ||
    ROUTE_SLICE.routerSlice.actions.setCosting.match(action) ||
    ROUTE_ACTIONS.retryFetchRoute.match(action)
  ) {
    getRoute();
  }

  if (ROUTE_SLICE.setSimulatedDtp.match(action)) {
    routeMapController?.setSimulatedDtp(action.payload);
  }

  if (ROUTE_SLICE.setIsSimulatedDtp.match(action)) {
    if (!action.payload) {
      store.dispatch(ROUTE_SLICE.setSimulatedDtp(null));
      store.dispatch(ROUTE_SLICE.setSimulatedDtpDate(null));
      // @note возврат маршрута к версии без модельного ДТП
    }
    routeMapController?.setIsSimulatedDtp(action.payload);
  }

  if (action.type === ROUTE_SLICE.setIsShowManeuvers.type) removeRouteManeuver(map);

  if (ROUTE_SLICE.setTemplateId.match(action)) {
    if (store.getState().router.isCompareWithYandex) {
      store.dispatch(ROUTE_SLICE.setIsYandexVariantLoading(true));
      store.dispatch(ROUTE_SLICE.setYandexVariant(null));
    }
  }

  if (action.type === ROUTE_SLICE.setIsActive.type)
    if (action.payload === false) {
      routeMapController?.removePoints();
      routeMapController?.removeRoute();
      routeMapController?.setVisibilitySimulatedDtp(false);
      removeRouteManeuver(map);
    } else {
      routeMapController?.setVisibilitySimulatedDtp(true);
      routeMapController?.updatePoints();
      routeMapController?.updateRoute();
    }

  if (action.type === ROUTE_SLICE.updateByFavorite.type) getRoute();

  if (action.type === ROUTE_SLICE.setTimeStatus.type) {
    getRoute();
    updateTrafficCheckboxDisabled();
  }

  if (action.type === ROUTE_SLICE.setPointCoor.type) {
    if (store.getState().sectorAnalysis.isActive) {
      getRoute(true);
    } else {
      getRoute();
    }
  }

  if (action.type === ES_UPDATE_SELECTED) getRoute();

  if (action.type === CHANGE_SELECTED_DAY) getRoute();

  if (action.type === ROUTE_SLICE.setActiveIndexRoute.type) {
    store.dispatch(ROUTE_SLICE.setRouteTimeVariant(null));
    routeMapController?.updateRoute();
  }

  if (action.type === ROUTE_SLICE.clearPoints.type) {
    routeMapController?.removeRoute();
    routeMapController?.removePoints();
    RouterAPI.router.cancelRoute();
    GeocoderAPI.geocode.cancelAddress();
    store.dispatch(ROUTE_SLICE.routerSlice.actions.setIsSimulatedDtp(false));
    store.dispatch(ROUTE_SLICE.routerSlice.actions.setErrorVariants(null));
  }

  if (action.type === ROUTE_SLICE.clearPoint.type) {
    routeMapController?.removeRoute();
    routeMapController?.updatePoints();
    GeocoderAPI.geocode.cancelAddress();
    RouterAPI.router.cancelRoute();
    getRoute();
    store.dispatch(ROUTE_SLICE.routerSlice.actions.setIsSimulatedDtp(false));
    store.dispatch(ROUTE_SLICE.routerSlice.actions.setErrorVariants(null));
  }

  return result;
};
