/* eslint-disable max-lines-per-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import { LngLat } from "mapbox-gl";
import {
  favoriteChangeAddressAC,
  favoriteChangeNameAC,
  favoriteChangeLocationAC,
  updateFavoriteAC,
  deleteFavoriteAC,
  touchFavoriteAC,
} from "old-store/user-favorite/action-creators";
import RouteFavoritesStar from "../route-favorites-star/route-favorites-star";
import RouteFavoritesEdit from "../route-favorites-edit/route-favorites-edit";
import { RouteFavoriteInfo } from "../../../common-components/route-favorite-info/route-favorite-info";

import "./route-favorite-item.scss";

const className = "route-favorite-item";

class RouteFavoriteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      isHover: false,
    };
    this.item = React.createRef();
  }

  onChangeAddress = (event, { newValue }) => {
    const { id, changeAddress } = this.props;
    changeAddress({ id, address: newValue });
  };

  onChangeName = (event, { newValue }) => {
    const { id, changeName } = this.props;
    changeName({ id, name: newValue });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { id, changeLocation, changeAddress } = this.props;
    const { address, location } = suggestion;
    const loc = {
      lng: location[0],
      lat: location[1],
    };
    changeLocation({ id, location: loc });
    changeAddress({ id, address });
  };

  getKey() {
    const { points, activePoint } = this.props;
    let key = null;

    if (activePoint) {
      key = activePoint;
    } else {
      const point = points.find((el) => !el.address);
      if (point) {
        key = point.key;
      }
    }
    return key;
  }

  handleCancelButton = () => {
    const { address, location, name, id, deleteFavorite } = this.props;
    const data = {
      options: {
        name,
        location,
        address,
        id,
      },
    };
    deleteFavorite(data);
    this.handleCloseButton();
  };

  handleConfirmButton = ({ address, name }) => {
    const { location, id, updateFavorite } = this.props;
    const data = {
      options: {
        name,
        location,
        address,
        id,
      },
    };
    updateFavorite(data);
    this.handleCloseButton();
  };

  handleCloseButton = () => {
    this.setState({ modalIsOpen: false });
  };

  togglePopup = (e) => {
    e.stopPropagation();
    const { modalIsOpen } = this.state;
    this.setState({ modalIsOpen: !modalIsOpen });
  };

  handleClick = () => {
    const { touchFavorite, setNewAddress, address, id, name, location } = this.props;
    // const loc = [location.lng, location.lat];
    const coor = new LngLat(location.lng, location.lat);

    const key = this.getKey();
    if (key) {
      setNewAddress({
        key,
        address,
        coor,
        favoriteId: id,
        isFavorite: true,
        favoriteName: name,
      });

      const options = {
        address,
        name,
        id,
      };
      touchFavorite({ options });
    }
  };

  handleClearName = () => {};

  handleClearAddress = () => {};

  render() {
    const { address, name } = this.props;
    const { modalIsOpen, isHover } = this.state;
    return (
      <div className={className} ref={this.item}>
        <div
          className={`${className}-container`}
          onClick={this.handleClick}
          onMouseEnter={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}>
          <div className={`${className}-left`}>
            <div className={`${className}-star`}>
              <RouteFavoritesStar />
            </div>
            <div className={`${className}-label`}>
              <div className={`${className}-name`} title={name}>
                {name}
              </div>
              <div className={`${className}-address`} title={address}>
                {address}
              </div>
            </div>
          </div>
          <div className={`${className}-edit`}>
            {isHover && <RouteFavoritesEdit handleClick={(e) => this.togglePopup(e)} />}
          </div>
        </div>
        {modalIsOpen && (
          <RouteFavoriteInfo
            address={address}
            name={name}
            onChangeAddress={this.onChangeAddress}
            onChangeName={this.onChangeName}
            handleCloseButton={this.handleCloseButton}
            handleConfirmButton={this.handleConfirmButton}
            handleCancelButton={this.handleCancelButton}
            onSuggestionSelected={this.onSuggestionSelected}
            handleClearName={this.handleClearName}
            handleClearAddress={this.handleClearAddress}
            isFavorite
            item={this.item.current}
            type="favorite-item"
          />
        )}
      </div>
    );
  }
}

RouteFavoriteItem.propTypes = {
  points: PropTypes.array,
  activePoint: PropTypes.string,
  changeAddress: PropTypes.func,
  changeName: PropTypes.func,
  changeLocation: PropTypes.func,
  updateFavorite: PropTypes.func,
  deleteFavorite: PropTypes.func,
  setNewAddress: PropTypes.func,
  touchFavorite: PropTypes.func,
  address: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
  location: PropTypes.any,
};

export default connect(
  /** @param state {import("documentations").GState} */
  (state) => ({
    points: state.router.points,
    activePoint: state.router.activePointKey,
  }),
  (dispatch) => ({
    changeAddress: (data) => dispatch(favoriteChangeAddressAC(data)),
    changeName: (data) => dispatch(favoriteChangeNameAC(data)),
    changeLocation: (data) => dispatch(favoriteChangeLocationAC(data)),
    updateFavorite: (data) => dispatch(updateFavoriteAC(data)),
    deleteFavorite: (data) => dispatch(deleteFavoriteAC(data)),
    setNewAddress: (data) => dispatch(ROUTE_SLICE.updateByFavorite(data)),
    touchFavorite: (data) => dispatch(touchFavoriteAC(data)),
  })
)(RouteFavoriteItem);
