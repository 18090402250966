export namespace Layers {
  export namespace Identifiers {
    // isochrone
    export const ISOCHRONE_FILL_ID = "isochrone-fill";
    export const ISOCHRONE_STROKE_ID = "isochrone-stroke";

    // transport hubs
    export const TRANSPORT_HUBS = "transport-hubs-layer";
    export const TRANSPORT_HUBS_ICON = "transport-hubs-icon";

    // traffic
    export const TRAFFIC_ID = "traffic-id";
    export const TRAFFIC_ID_ARROW = "traffic-id-arrow";

    // route
    export const ROUTE_PATH = "route-path";
    export const ROUTE_PATH_STROKE = "route-path-stroke";
    export const ROUTE_PATH_ALTERNATIVE = "route-path-alternative";
    export const ROUTE_PATH_ALTERNATIVE_STROKE = "route-path-alternative-stroke";
    export const ROUTE_INTERMEDIATE_POINT = "route-intermediate-point";
    export const ROUTE_THROW_POINT = "route-move-throw-point";
    export const ROUTE_TLS = "route-tls-layer";
    export const ROUTE_BUS_TRANSIT = "route-bus-layer-transit";
    export const ROUTE_BUS_FOOT = "route-bus-layer-foot";
    export const ROUTE_METRO = "route-metro";
    export const ROUTE_PEDESTRIAN = "route-pedestrian-layer";
    export const ROUTE_VOLUMETRIC_DIAGRAM = "ROUTE_VOLUMETRIC_DIAGRAM";
    export const ROUTE_SIMULATED_DTP_LAYER = "ROUTE_SIMULATED_DTP_LAYER";
    export const ROUTE_SIMULATED_DTP_ICON = "ROUTE_SIMULATED_DTP_ICON";

    // graph
    export const GRAPH_EDGES_ID = "graph-edges-id";
    export const GRAPH_VERTICES_ID = "graph-vertices-id";
    export const GRAPH_EDGES_HIGHLIGHT_ID = "graph-edges-highlight-id";
    export const GRAPH_VERTICES_HIGHLIGHT_ID = "graph-vertices-highlight-id";
    export const GRAPH_SOURCE_ID = "graph-source-id";
    export const GRAPH_ARROW_ID = "graph-arrow-id";
    export const GRAPH_CARGO_EDGES_ID = "cargo-graph-id";
    export const GRAPH_PEDESTRIAN = "pedestrian-graph-layer";

    // telemetry
    export const TELEMETRY_POINTS_ID = "points-id";
    export const TELEMETRY_HEATMAP_ID = "heatmap-id";
    export const TELEMETRY_BIND_TRACK_ID = "telemetry-track-id";
    export const TELEMETRY_BIND_CONNECT_ID = "telemetry-bind-lines-id";
    export const TELEMETRY_BIND_MATCHED = "telemetry-bind-matched";
    export const TELEMETRY_BIND_LINE_ID = "telemetry-positions-line-id";
    export const TELEMETRY_BIND_POINT_ID = "telemetry-positions-point-id";
    export const TELEMETRY_BIND_UNMATCHED_LINE_ID = "telemetry-unmatched-track-id";
    export const TELEMETRY_BIND_UNMATCHED_POINT_ID = "telemetry-unmatched-point-id";

    // overlap
    export const BLOCKING_POINTS_ID = "blocking-points-id";
    export const LayerDash0 = "overlap-layer-dash-0";
    export const LayerDash1 = "overlap-layer-dash-1";
    export const LayerDash2 = "overlap-layer-dash-2";
    export const LayerDash3 = "overlap-layer-dash-3";
    export const LayerFull = "overlap-layer-full";
    export const LayerIcon = "overlap-layer-icons";
    export const TEMPORARY_BLOCKING_LAYER_ID = "TEMPORARY_BLOCKING_LAYER_ID";

    // speedcam
    export const SPEEDCAM_LAYER_ID = "speedcam-layer-id";
    export const SPEEDCAM_ACTIVE_LAYER_ID = `${SPEEDCAM_LAYER_ID}-active`;
    export const SPEEDCAM_HOVER_LAYER_ID = `${SPEEDCAM_LAYER_ID}-active`;
    export const SPEEDCAM_VOLUMETRIC = "speedcam-volumetric";

    // camera
    export const CAMERA_CODD_LAYER_ID = "camera-codd-layer-id";
    export const CAMERA_CODD_ACTIVE_LAYER_ID = `${CAMERA_CODD_LAYER_ID}-active`;
    export const CAMERA_CODD_HOVER_LAYER_ID = `${CAMERA_CODD_LAYER_ID}-hover`;
    export const CAMERA_ECHD_LAYER_ID = "camera-echd-layer-id";
    export const CAMERA_ECHD_ACTIVE_LAYER_ID = `${CAMERA_ECHD_LAYER_ID}-active`;
    export const CAMERA_ECHD_HOVER_LAYER_ID = `${CAMERA_ECHD_LAYER_ID}-hover`;

    // tls
    export const TLS_ID = "tls-layer-id";
    export const TLS_ACTIVE_ID = `${TLS_ID}-active`;
    export const TLS_HOVER_ID = `${TLS_ID}-hover`;

    // detector
    export const ND_DETECTOR_ID = "nd_detector-id";
    export const DETECTOR_ID = "detector-id";
    export const ND_DETECTOR_ACTIVE_ID = `${ND_DETECTOR_ID}-active`;
    export const ND_DETECTOR_HOVER_ID = `${ND_DETECTOR_ID}-hover`;
    export const DETECTOR_ACTIVE_ID = `${DETECTOR_ID}-active`;
    export const DETECTOR_HOVER_ID = `${DETECTOR_ID}-hover`;
    export const DETECTOR_VOLUMETRIC = "detector-volumetric";

    // other
    export const BUS_STOP_ID = "bus-stop-id";
    export const BUS_STOP_VOLUMETRIC = "bus-stop-volumetric-layer";
    export const OVERLAP_ID = "overflap-layer";
    export const OVERLAP_DASH_ID = "overflap-layer-dash";
    export const DTP_ID = "dtp-id";
    export const FLOODING_ID = "flooding-id";
    export const WEATHER_FORECAST_ID = "weatherForeCast";
    export const METEOSTATION_ID = "MAP_MS_CTRL__LAYER";
    export const WEATHER_ID = "weather-id";
    export const BAD_ROADS_ID = "bad-roads-id";
    export const PLACE_LABEL_CITY = "place_label_city";
    export const RAILWAY_SUBWAY = "railway-subway-min";
    export const SECTOR_ANALYSIS_LAYER_ID = "sector-analysis-id";

    // districts
    export const DISTRICT_ID = "megapolis-dtm-district-layer";
    export const DISTRICT_BORDER_ID = `${DISTRICT_ID}-border`;
    export const DISTRICT_TITLE_ID = `${DISTRICT_ID}-title`;
    export const DISTRICT_ZONE_ID = "megapolis-dtm-district-layer-zone";
    export const DISTRICT_ZONE_BORDER_ID = `${DISTRICT_ZONE_ID}-border`;
    export const DISTRICT_ZONE_TITLE_ID = `${DISTRICT_ZONE_ID}-title`;

    // correlation
    export const CORRELATION_HISTORY_ID = "correlation-traffic-historical";
    export const CORRELATION_FORECAST_ID = "correlation-traffic-forecast";
    export const CORRELATION_VOLUMETRIC = "correlation-volumetric";

    // travel heatmap
    export const TRAVEL_HEATMAP_ID = "travel_heatmap";
    export const TRAVEL_HEATMAP_BORDER_ID = "travel_heatmap-border";
    export const TRAVEL_HEATMAP_ACTIVE_ID = "travel_heatmap-active-border";
    export const TRAVEL_HEATMAP_HOVER_ID = "travel_heatmap-hover-border";

    // helpers
    export const ROAD_LABEL = "road_label";
    export const ROAD_LABEL_PRIMARY = "road_label_primary";
    export const ROAD_LABEL_MIN = "road_label_min";
    export const ROAD_LABEL_PRIMARY_MIN = "road_label_primary_min";

    // icons
    export const SPEEDCAM_MAP_IMAGE_KEY = "SPEEDCAM_MAP_IMAGE_KEY";
    export const CAMERA_MAP_IMAGE_KEY = "CAMERA_MAP_IMAGE_KEY";
    export const DTP_MAP_IMAGE_KEY = "DTP_MAP_IMAGE_KEY";
    export const BLOCKING_MAP_IMAGE_KEY = "BLOCKING_MAP_IMAGE_KEY";
    export const BUS_STOP_MAP_IMAGE_KEY = "BUS_STOP_MAP_IMAGE_KEY";
    export const DETECTOR_MAP_IMAGE_KEY = "DETECTOR_MAP_IMAGE_KEY";
    export const FLOODING_MAP_IMAGE_KEY = "FLOODING_MAP_IMAGE_KEY";
    export const TLS_MAP_IMAGE_KEY = "TLS_MAP_IMAGE_KEY";
    export const WEATHER_MAP_IMAGE_KEY = "WEATHER_MAP_IMAGE_KEY";
    export const SIGN_ROAD_WORKS_MAP_IMAGE_KEY = "SIGN_ROAD_WORKS_MAP_IMAGE_KEY";
    export const SIGN_ROUGH_ROAD_MAP_IMAGE_KEY = "SIGN_ROUGH_ROAD_MAP_IMAGE_KEY";
    export const SIGN_OTHER_DANGERS_MAP_IMAGE_KEY = "SIGN_OTHER_DANGERS_MAP_IMAGE_KEY";
    export const TELEMETRY_MAP_IMAGE_KEY = "TELEMETRY_MAP_IMAGE_KEY";
    export const TELEMETRY_MAP_UNMATCHED_IMAGE_KEY = "TELEMETRY_MAP_UNMATCHED_IMAGE_KEY";
    export const BLOCKING_PLAN_MAP_IMAGE_KEY = "BLOCKING_PLAN_MAP_IMAGE_KEY";
    export const BLOCKING_TEMPORARY_MAP_IMAGE_KEY = "BLOCKING_TEMPORARY_MAP_IMAGE_KEY";
  }

  export const cursorOrder = [
    Identifiers.OVERLAP_ID,
    Identifiers.OVERLAP_DASH_ID,
    Identifiers.GRAPH_EDGES_ID,
    Identifiers.GRAPH_EDGES_HIGHLIGHT_ID,
    Identifiers.TRAFFIC_ID,
    Identifiers.TELEMETRY_POINTS_ID,
    Identifiers.TELEMETRY_BIND_POINT_ID,
    Identifiers.BAD_ROADS_ID,
    Identifiers.DTP_ID,
    Identifiers.ND_DETECTOR_ID,
    Identifiers.DETECTOR_ID,
    Identifiers.ND_DETECTOR_ACTIVE_ID,
    Identifiers.ND_DETECTOR_HOVER_ID,
    Identifiers.DETECTOR_ACTIVE_ID,
    Identifiers.DETECTOR_HOVER_ID,
    Identifiers.TLS_ID,
    Identifiers.TLS_ACTIVE_ID,
    Identifiers.TLS_HOVER_ID,
    Identifiers.SPEEDCAM_LAYER_ID,
    Identifiers.SPEEDCAM_ACTIVE_LAYER_ID,
    Identifiers.SPEEDCAM_HOVER_LAYER_ID,
    Identifiers.CAMERA_CODD_LAYER_ID,
    Identifiers.CAMERA_CODD_ACTIVE_LAYER_ID,
    Identifiers.CAMERA_CODD_HOVER_LAYER_ID,
    Identifiers.CAMERA_ECHD_LAYER_ID,
    Identifiers.CAMERA_ECHD_ACTIVE_LAYER_ID,
    Identifiers.CAMERA_ECHD_HOVER_LAYER_ID,
    Identifiers.BUS_STOP_ID,
    Identifiers.WEATHER_FORECAST_ID,
    Identifiers.METEOSTATION_ID,
    Identifiers.TRAFFIC_ID_ARROW,
    Identifiers.CORRELATION_FORECAST_ID,
    Identifiers.ROUTE_THROW_POINT,
    Identifiers.ROUTE_PATH_ALTERNATIVE,
    Identifiers.WEATHER_ID,
    Identifiers.BLOCKING_POINTS_ID,
    Identifiers.ROUTE_INTERMEDIATE_POINT,
    Identifiers.LayerDash0,
    Identifiers.LayerDash1,
    Identifiers.LayerDash2,
    Identifiers.LayerDash3,
    Identifiers.LayerFull,
    Identifiers.TELEMETRY_BIND_UNMATCHED_POINT_ID,
    Identifiers.LayerIcon,
    Identifiers.FLOODING_ID,
    Identifiers.TEMPORARY_BLOCKING_LAYER_ID,
    Identifiers.DISTRICT_ID,
    Identifiers.DISTRICT_BORDER_ID,
    Identifiers.DISTRICT_TITLE_ID,
    Identifiers.DISTRICT_ZONE_ID,
    Identifiers.DISTRICT_ZONE_BORDER_ID,
    Identifiers.DISTRICT_ZONE_TITLE_ID,
    Identifiers.TRANSPORT_HUBS,
    Identifiers.TRAVEL_HEATMAP_ID,
    Identifiers.TRAVEL_HEATMAP_BORDER_ID,
  ];

  export const positionOrder = [
    Identifiers.TRAFFIC_ID,
    Identifiers.TRAFFIC_ID_ARROW,
    Identifiers.CORRELATION_HISTORY_ID,
    Identifiers.CORRELATION_FORECAST_ID,
    Identifiers.RAILWAY_SUBWAY,
    Identifiers.ISOCHRONE_FILL_ID,
    Identifiers.ISOCHRONE_STROKE_ID,
    Identifiers.GRAPH_EDGES_ID,
    Identifiers.GRAPH_VERTICES_ID,
    Identifiers.GRAPH_EDGES_HIGHLIGHT_ID,
    Identifiers.GRAPH_VERTICES_HIGHLIGHT_ID,
    Identifiers.GRAPH_ARROW_ID,
    Identifiers.GRAPH_CARGO_EDGES_ID,
    Identifiers.GRAPH_PEDESTRIAN,
    Identifiers.TELEMETRY_HEATMAP_ID,
    Identifiers.TELEMETRY_POINTS_ID,
    Identifiers.PLACE_LABEL_CITY,
    Identifiers.TELEMETRY_BIND_TRACK_ID,
    Identifiers.TELEMETRY_BIND_CONNECT_ID,
    Identifiers.TELEMETRY_BIND_LINE_ID,
    Identifiers.TELEMETRY_BIND_UNMATCHED_LINE_ID,
    Identifiers.TELEMETRY_BIND_POINT_ID,
    Identifiers.TELEMETRY_BIND_UNMATCHED_POINT_ID,
    Identifiers.BUS_STOP_ID,
    Identifiers.TRANSPORT_HUBS,
    Identifiers.SPEEDCAM_LAYER_ID,
    Identifiers.CAMERA_CODD_LAYER_ID,
    Identifiers.CAMERA_ECHD_LAYER_ID,
    Identifiers.TLS_ID,
    Identifiers.ND_DETECTOR_ID,
    Identifiers.DETECTOR_ID,
    Identifiers.DTP_ID,
    Identifiers.OVERLAP_ID,
    Identifiers.FLOODING_ID,
    Identifiers.OVERLAP_DASH_ID,
    Identifiers.BAD_ROADS_ID,
    Identifiers.WEATHER_ID,
    Identifiers.LayerDash0,
    Identifiers.LayerDash1,
    Identifiers.LayerDash2,
    Identifiers.LayerDash3,
    Identifiers.LayerFull,
    Identifiers.SPEEDCAM_ACTIVE_LAYER_ID,
    Identifiers.SPEEDCAM_HOVER_LAYER_ID,
    Identifiers.TLS_ACTIVE_ID,
    Identifiers.TLS_HOVER_ID,
    Identifiers.CAMERA_CODD_ACTIVE_LAYER_ID,
    Identifiers.CAMERA_CODD_HOVER_LAYER_ID,
    Identifiers.CAMERA_ECHD_ACTIVE_LAYER_ID,
    Identifiers.CAMERA_ECHD_HOVER_LAYER_ID,
    Identifiers.BLOCKING_POINTS_ID,
    Identifiers.ROUTE_PATH_ALTERNATIVE_STROKE,
    Identifiers.ROUTE_PATH_ALTERNATIVE,
    Identifiers.ROUTE_PATH_STROKE,
    Identifiers.ROUTE_PATH,
    Identifiers.ROUTE_TLS,
    Identifiers.ROUTE_BUS_TRANSIT,
    Identifiers.ROUTE_BUS_FOOT,
    Identifiers.ROUTE_METRO,
    Identifiers.ROUTE_PEDESTRIAN,
    Identifiers.ROUTE_INTERMEDIATE_POINT,
    Identifiers.ROUTE_SIMULATED_DTP_LAYER,
    Identifiers.ROUTE_VOLUMETRIC_DIAGRAM,
    Identifiers.SPEEDCAM_VOLUMETRIC,
    Identifiers.DETECTOR_VOLUMETRIC,
    Identifiers.CORRELATION_VOLUMETRIC,
  ];

  export const helpers = [
    Identifiers.ROAD_LABEL,
    Identifiers.ROAD_LABEL_PRIMARY,
    Identifiers.ROAD_LABEL_MIN,
    Identifiers.ROAD_LABEL_PRIMARY_MIN,
  ];

  export namespace Utils {
    const iconImageKeys = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    export function addRoutePointsToOrder(array: string[]) {
      for (let i = 0; i < 20; i++) array.push(`route-point-${iconImageKeys[i]}`);
    }

    const getActualListOrder = (order: string[], layers?: mapboxgl.AnyLayer[]) => {
      const layerIntermediatePoints = layers?.filter(
        ({ id }) =>
          id.startsWith(Identifiers.ROUTE_INTERMEDIATE_POINT) &&
          id !== Identifiers.ROUTE_INTERMEDIATE_POINT &&
          !order.some((keyId) => keyId === id)
      );
      return layerIntermediatePoints?.map(({ id }) => id);
    };

    export function getBeforeId(id: string, map: mapboxgl.Map) {
      if (!map) return;

      if (!positionOrder.includes("route-point-A")) {
        addRoutePointsToOrder(positionOrder);
      }

      const layers = map.getStyle().layers;
      let listOrder = [...positionOrder];
      const actualLayers = getActualListOrder(positionOrder, layers);

      if (Array.isArray(actualLayers)) {
        listOrder = listOrder.concat(actualLayers);
      }

      let index = listOrder.indexOf(id);

      if (index < 0) return;

      const layersAfter = listOrder.slice(index + 1);
      const beforeId = layersAfter.find((el) => Boolean(map.getLayer(el)));

      return beforeId;
    }
  }
}
