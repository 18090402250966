import { GraphTilesInput } from "./model/tiles";
import { APP_ENV } from "app-env";

const tilesUrl = `${APP_ENV.REACT_APP_DTM_ROUTER_API}tiles/`;

export const tiles = {
  /** Дорожный граф */
  graph: ({ clip, date, pedestrian }: GraphTilesInput) => {
    const params = new URLSearchParams();
    if (clip) params.set("clip", String(clip));
    if (date) params.set("date", String(date));
    if (pedestrian) params.set("pedestrian", String(pedestrian));
    return `${APP_ENV.REACT_APP_DTM_ROUTER_GRAPH_API}z{z}_x{x}_y{y}.mvt?${params.toString()}`;
  },
  osmGraph: () => {
    return `${tilesUrl}osm-graph/z{z}_x{x}_y{y}.mvt`;
  },
};
