const status = {
  "dinamic-1": `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
  <defs>
      <style>
          .cls-1{fill:#e30000}
      </style>
  </defs>
  <g id="ic_traffic_light_grow" transform="translate(-126.903 -90.469)">
      <g id="Group_64515" data-name="Group 64515" transform="translate(127.126 92.589)">
          <rect id="Rectangle_10659" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
          <path id="Path_72168" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
      </g>
      <g id="Group_64516" data-name="Group 64516" transform="translate(133.126 90.589)">
          <rect id="Rectangle_10659-2" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
          <path id="Path_72168-2" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
      </g>
      <g id="Group_64521" data-name="Group 64521" transform="translate(139.126 92.589)">
          <rect id="Rectangle_10659-3" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
          <path id="Path_72168-3" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
      </g>
  </g>
</svg>`,
  "dinamic-2": `
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14">
    <defs>
        <style>
            .cls-1{fill:#e30000}
        </style>
    </defs>
    <g id="ic_traffic_light_grow" transform="translate(-126.903 -90.469)">
        <g id="Group_64515" data-name="Group 64515" transform="translate(127.126 92.589)">
            <rect id="Rectangle_10659" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
        <g id="Group_64516" data-name="Group 64516" transform="translate(133.126 90.589)">
            <rect id="Rectangle_10659-2" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168-2" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
    </g>
</svg>`,
  "dinamic-3": `
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="12" viewBox="0 0 4 12">
    <defs>
        <style>
            .cls-1{isolation:isolate}.cls-2{fill:#e30000}
        </style>
    </defs>
    <g id="ic_traffic_light_grow" class="cls-1" transform="translate(-126.903 -92.469)">
        <g id="Group_64515" data-name="Group 64515" transform="translate(127.126 92.589)">
            <rect id="Rectangle_10659" width="2" height="11" class="cls-2" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168" d="M178.644 87.469l-2 3h4z" class="cls-2" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
    </g>
</svg>`,
  "dinamic-4": `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <defs>
        <style>
            .cls-1{fill:#62c400}
        </style>
    </defs>
    <g id="ic_traffic_light_grow" transform="rotate(180 71.451 52.235)">
        <g id="Group_64515" data-name="Group 64515" transform="translate(127.126 92.589)">
            <rect id="Rectangle_10659" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
        <g id="Group_64516" data-name="Group 64516" transform="translate(133.126 90.589)">
            <rect id="Rectangle_10659-2" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168-2" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
        <g id="Group_64521" data-name="Group 64521" transform="translate(139.126 92.589)">
            <rect id="Rectangle_10659-3" width="2" height="11" class="cls-1" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168-3" d="M178.644 87.469l-2 3h4z" class="cls-1" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
    </g>
</svg>`,
  "dinamic-5": `
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14">
    <defs>
        <style>
            .cls-1{isolation:isolate}.cls-2{fill:#62c400}
        </style>
    </defs>
    <g id="ic_traffic_light_grow" class="cls-1" transform="rotate(180 68.451 52.235)">
        <g id="Group_64515" data-name="Group 64515" transform="translate(127.126 92.589)">
            <rect id="Rectangle_10659" width="2" height="11" class="cls-2" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168" d="M178.644 87.469l-2 3h4z" class="cls-2" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
        <g id="Group_64516" data-name="Group 64516" transform="translate(133.126 90.589)">
            <rect id="Rectangle_10659-2" width="2" height="11" class="cls-2" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168-2" d="M178.644 87.469l-2 3h4z" class="cls-2" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
    </g>
</svg>`,
  "dinamic-6": `
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="12" viewBox="0 0 4 12">
    <defs>
        <style>
            .cls-1{isolation:isolate}.cls-2{fill:#62c400}
        </style>
    </defs>
    <g id="ic_traffic_light_grow" class="cls-1" transform="rotate(180 65.451 52.235)">
        <g id="Group_64515" data-name="Group 64515" transform="translate(127.126 92.589)">
            <rect id="Rectangle_10659" width="2" height="11" class="cls-2" data-name="Rectangle 10659" rx="1" transform="translate(.778 .88)"/>
            <path id="Path_72168" d="M178.644 87.469l-2 3h4z" class="cls-2" data-name="Path 72168" transform="translate(-176.867 -87.589)"/>
        </g>
    </g>
</svg>`,
  "dinamic-7": `
  
  <svg
  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
  width="16"
  height="14"
  viewBox="0 0 16 14"
  version="1.1"
  id="svg16"
  sodipodi:docname="ic_traffic_light_grow (8).svg"
  inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
 <metadata
    id="metadata20">
   <rdf:RDF>
     <cc:Work
        rdf:about="">
       <dc:format>image/svg+xml</dc:format>
       <dc:type
          rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
     </cc:Work>
   </rdf:RDF>
 </metadata>
 <sodipodi:namedview
    pagecolor="#ffffff"
    bordercolor="#666666"
    borderopacity="1"
    objecttolerance="10"
    gridtolerance="10"
    guidetolerance="10"
    inkscape:pageopacity="0"
    inkscape:pageshadow="2"
    inkscape:window-width="1550"
    inkscape:window-height="803"
    id="namedview18"
    showgrid="false"
    inkscape:zoom="16.857143"
    inkscape:cx="-1.6398304"
    inkscape:cy="7.0000001"
    inkscape:window-x="2061"
    inkscape:window-y="96"
    inkscape:window-maximized="0"
    inkscape:current-layer="Group_64515" />
 <defs
    id="defs4">
   <style
      id="style2">
           .cls-1{fill:#90cc32}
       </style>
 </defs>
 <g
    id="ic_traffic_light_grow"
    transform="rotate(180 71.451 52.235)">
   <g
      id="Group_64515"
      data-name="Group 64515"
      transform="translate(134.126,92.114424)">
     <rect
        id="Rectangle_10659"
        width="2"
        height="11"
        class="cls-1"
        data-name="Rectangle 10659"
        rx="1"
        transform="rotate(90)"
        x="4.6681356"
        y="-6.2695255"
        style="fill:#434343;stroke:none;stroke-opacity:1;fill-opacity:1" />
     <path
        id="Path_72168"
        d="m 8.0820847,5.6511864 -3,-2 v 4 z"
        class="cls-1"
        data-name="Path 72168"
        inkscape:connector-curvature="0"
        style="fill:#434343;stroke:none;stroke-opacity:1;fill-opacity:1" />
     <path
        id="Path_72168-6"
        d="m -6.6881016,5.6342373 3,2 v -4 z"
        class="cls-1"
        data-name="Path 72168"
        inkscape:connector-curvature="0"
        style="fill:#434343;stroke:none;stroke-opacity:1;fill-opacity:1" />
   </g>
 </g>
</svg>`,
  "dinamic-0": `
  <svg
  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
  width="16"
  height="14"
  viewBox="0 0 16 14"
  version="1.1"
  id="svg16"
  sodipodi:docname="ic_traffic_light_grow (8).svg"
  inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
 <metadata
    id="metadata20">
   <rdf:RDF>
     <cc:Work
        rdf:about="">
       <dc:format>image/svg+xml</dc:format>
       <dc:type
          rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
     </cc:Work>
   </rdf:RDF>
 </metadata>
 <sodipodi:namedview
    pagecolor="#ffffff"
    bordercolor="#666666"
    borderopacity="1"
    objecttolerance="10"
    gridtolerance="10"
    guidetolerance="10"
    inkscape:pageopacity="0"
    inkscape:pageshadow="2"
    inkscape:window-width="1550"
    inkscape:window-height="803"
    id="namedview18"
    showgrid="false"
    inkscape:zoom="16.857143"
    inkscape:cx="-1.6398304"
    inkscape:cy="7.0000001"
    inkscape:window-x="2061"
    inkscape:window-y="96"
    inkscape:window-maximized="0"
    inkscape:current-layer="Group_64515" />
 <defs
    id="defs4">
   <style
      id="style2">
           .cls-1{fill:#90cc32}
       </style>
 </defs>
 <g
    id="ic_traffic_light_grow"
    transform="rotate(180 71.451 52.235)">
   <g
      id="Group_64515"
      data-name="Group 64515"
      transform="translate(134.126,92.114424)">
     <rect
        id="Rectangle_10659"
        width="2"
        height="11"
        class="cls-1"
        data-name="Rectangle 10659"
        rx="1"
        transform="rotate(90)"
        x="4.6681356"
        y="-6.2695255"
        style="fill:#434343;stroke:none;stroke-opacity:1;fill-opacity:1" />
     <path
        id="Path_72168"
        d="m 8.0820847,5.6511864 -3,-2 v 4 z"
        class="cls-1"
        data-name="Path 72168"
        inkscape:connector-curvature="0"
        style="fill:#434343;stroke:none;stroke-opacity:1;fill-opacity:1" />
     <path
        id="Path_72168-6"
        d="m -6.6881016,5.6342373 3,2 v -4 z"
        class="cls-1"
        data-name="Path 72168"
        inkscape:connector-curvature="0"
        style="fill:#434343;stroke:none;stroke-opacity:1;fill-opacity:1" />
   </g>
 </g>
</svg>`,
};

export default function getStatusIcon(dynamicsID) {
  const svgString = status[`dinamic-${dynamicsID}`];
  const src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
  return src;
}
