import { memo } from "react";
import { Range } from "rc-slider";
import * as hooks from "./units-count-appearance-slider.hooks";
import * as consts from "./units-count-appearance-slider.consts";
import "./units-count-appearance-slider.scss";

export const UnitsCountAppearanceSlider = memo(() => {
  const params = hooks.useUnitsCountDiagramParams();
  const colorsCount = hooks.useSliderCount();
  const { values, handleChange } = hooks.useValues(params);
  const labels = hooks.useLabels(values);
  const trackStyles = hooks.useTrackStyles();
  const handleStyles = hooks.useHandleStyles();

  return (
    <div className={consts.baseClass}>
      <Range
        pushable
        max={params?.max}
        allowCross
        count={colorsCount}
        trackStyle={trackStyles}
        value={values}
        handleStyle={handleStyles}
        marks={labels}
        onChange={handleChange}
      />
    </div>
  );
});
