/* eslint-disable no-unused-vars */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-underscore-dangle */
import MeteostationControll from "@megapolis/mapbox-meteostation-controll";
import { Popup } from "mapbox-gl";
import ReactDOM from "react-dom";
import moment from "moment";

export function createMeteostationControl({ containerMeteostation, map, access_token, apiDomain }) {
  let popUpHtml;
  const _class = "map-ms-ctrl__kpp";

  const _model = {
    container: ReactDOM.findDOMNode(containerMeteostation),
    withClickListener: false,
    warehouse_url: apiDomain,
    getTileLink: () =>
      `${apiDomain}assetmeteostation/layerMVT/{z}/{x}/{y}?include=assetstatus,assetstatusid,lat,lng&filter=in(deleted,false) and ( in(statusudsid,1,2,3,4,5,7,8,9,10,11) or statusudsid eq null) and in(assetstatusid,1) and deleted eq false&sort=numSort asc`,
    getDetails: (idMS) => ({
      url: `${apiDomain}assetmeteostation/${idMS}`,
      access_token,
    }),
    syncState: (stateFlag) => {
      if (stateFlag === false && popUpHtml && typeof popUpHtml.remove === "function") popUpHtml.remove();
    },
    renderHTML: (data, lastvaluesData) => {
      if (popUpHtml && typeof popUpHtml.remove === "function") popUpHtml.remove();

      let id;
      let num;
      let lng;
      let lat;
      let updatedAt;

      if (data) {
        if (typeof data.id === "number") id = data.id;
        else return;

        if (typeof data.num === "string") num = data.num;
        else return;

        if (typeof data.lng === "number") lng = data.lng;
        else return;

        if (typeof data.lat === "number") lat = data.lat;
        else return;

        if (typeof data.updatedAt === "string") updatedAt = data.updatedAt;
        else return;
      } else return;

      const lastValuesItem = lastvaluesData.find((el) => el && el.assetId === id);

      popUpHtml = new Popup({
        closeOnClick: false,
        className: _class,
        anchor: "bottom",
      });

      popUpHtml.setLngLat([lng, lat]).setHTML(`
              <div class="${_class}__container">
                <div class="${_class}__head">Метеостанция <strong class="${_class}__head-name">${num}</strong></div>
                <div class="${_class}__graphic">
                  <img 
                    src="${apiDomain}assetmeteostation/chart/${id}?w=390&dateEnd=${moment(
        lastValuesItem.timestamp,
        "YYYY-MM-DDTHH:mm:ssZ"
      ).format("YYYY-MM-DDTHH:mm")}&interval=720&bearer_token=${access_token}" 
                    class="${_class}__graphic-img" 
                    alt="Метеостанция ${num}" 
                  />
                </div>
                <div class="${_class}__info">

                  <div class="${_class}__info__row">
                    <div class="${_class}__info__time">
                      <svg id="${_class}__info__time-icon" class="${_class}__info__time-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13"><path d="M6.5.65a5.64,5.64,0,0,1,2.27.46,5.91,5.91,0,0,1,3.12,3.12,5.64,5.64,0,0,1,.46,2.27,5.64,5.64,0,0,1-.46,2.27,5.91,5.91,0,0,1-3.12,3.12,5.64,5.64,0,0,1-2.27.46,5.64,5.64,0,0,1-2.27-.46A5.91,5.91,0,0,1,1.11,8.77,5.64,5.64,0,0,1,.65,6.5v0A5.66,5.66,0,0,1,1.1,4.21a5.86,5.86,0,0,1,3.1-3.1A5.66,5.66,0,0,1,6.47.65ZM6.5,0h0A6.37,6.37,0,0,0,4,.51,6.47,6.47,0,0,0,.51,4,6.37,6.37,0,0,0,0,6.5,6.37,6.37,0,0,0,.51,9,6.47,6.47,0,0,0,4,12.49,6.37,6.37,0,0,0,6.5,13h0A6.37,6.37,0,0,0,9,12.49,6.47,6.47,0,0,0,12.49,9,6.37,6.37,0,0,0,13,6.5,6.37,6.37,0,0,0,12.49,4,6.47,6.47,0,0,0,9,.51,6.37,6.37,0,0,0,6.5,0ZM4.29,9.62,3.9,9.1,6.69,6.89V3.25h.66v4Z"/></svg>
                      <span class="${_class}__info__time-value">${moment(
        lastValuesItem.timestamp,
        "YYYY-MM-DDTHH:mm:ssZ"
      ).format("HH:mm DD.MM.YYYY")}</span>
                    </div>
                  </div>

                  <div class="${_class}__info__row">
                    <div class="${_class}__info__label">
                      <span class="${_class}__info__label-name">Температура воздуха</span>
                    </div>
                    <div class="${_class}__info__value">
                      <i class="${_class}__info__legend" style="background-color: rgb(255, 87, 34);"></i>
                      <span class="${_class}__info__value-data">
                      ${lastValuesItem && "airTemp" in lastValuesItem ? `${lastValuesItem.airTemp} &#8451;` : "н/д"}
                      </span>
                    </div>
                  </div>
                
                  <div class="${_class}__info__row">
                    <div class="${_class}__info__label">
                      <span class="${_class}__info__label-name">Относ. влажность</span>
                    </div>
                    <div class="${_class}__info__value">
                      <i class="${_class}__info__legend" style="background-color: rgb(42, 122, 255);"></i>
                      <span class="${_class}__info__value-data">
                      ${lastValuesItem && "humidity" in lastValuesItem ? `${lastValuesItem.humidity} %` : "н/д"}
                      </span>
                      </div>
                      </div>
                      
                      <div class="${_class}__info__row">
                      <div class="${_class}__info__label">
                      <span class="${_class}__info__label-name">Скорость ветра</span>
                      </div>
                      <div class="${_class}__info__value">
                      <i class="${_class}__info__legend" style="background-color: rgb(251, 192, 45);"></i>
                      <span class="${_class}__info__value-data">
                      ${
                        lastValuesItem && "windSpeedAvg" in lastValuesItem
                          ? `${lastValuesItem.windSpeedAvg} м/с`
                          : "н/д"
                      }
                      </span>
                      </div>
                      </div>
                      
                      <div class="${_class}__info__row">
                      <div class="${_class}__info__label">
                      <span class="${_class}__info__label-name">Атмосферное давление</span>
                      </div>
                      <div class="${_class}__info__value">
                      <i class="${_class}__info__legend" style="background-color: rgb(76, 175, 80);"></i>
                      <span class="${_class}__info__value-data">
                      ${
                        lastValuesItem && "atmospherePressure" in lastValuesItem
                          ? `${lastValuesItem.atmospherePressure} гПа`
                          : "н/д"
                      }                       
                      </span>
                    </div>
                  </div>
                    
                </div>
              </div>
          `);

      popUpHtml.addTo(map);
    },
  };

  return new MeteostationControll(_model);
}
