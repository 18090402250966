import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Checkbox } from "components";
import { GState } from "documentations";
import { changeIsLocalDistortions } from "old-store/global/actions";
import { CtrlCorrelation } from "features/ctrl-correlation";
import CtrlTraffic from "features/ctrl-traffic";
import { CtrlIDZ } from "features/ctrl-idz";
import { HistoryTravelButton } from "../../features";

const useIsLocalDistortions = () => useSelector((store: GState) => store.global.isLocalDistortions);
const useIsCorrelation = () => useSelector((store: GState) => store.global.correlation);

export const TrafficGroup = () => {
  const isLocalDistortions = useIsLocalDistortions();
  const isCorrelation = useIsCorrelation();
  const dispatch = useDispatch();

  const handleIsLocalDistortionsChange = useCallback(() => {
    dispatch(changeIsLocalDistortions(!isLocalDistortions));
  }, [dispatch, isLocalDistortions]);

  const popupContent = useMemo(
    () => (
      <Checkbox
        value="Расхождение р"
        label="Расхождение р"
        title="Расхождение р"
        checked={isLocalDistortions}
        onChange={handleIsLocalDistortionsChange}
      />
    ),
    [isLocalDistortions, handleIsLocalDistortionsChange]
  );

  return (
    <ButtonGroup canBeOpen={isCorrelation} popupContent={popupContent} className="traffic-group">
      <HistoryTravelButton />
      <CtrlCorrelation />
      <CtrlTraffic />
      <CtrlIDZ />
    </ButtonGroup>
  );
};
