import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { Broker } from "./broker";

export class ApplicationSettings extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  // @note задел на будущее
  // private handleIsEmptyPiecesChange = (state: GState.ApplicationSettingsState) => {
  //   console.info("broker", state.telematics.isEmptyPieces);
  // };

  private handleActiveMapStyleChange = (state: GState.ApplicationSettingsState) => {
    this.mapController?.setMapStyle(state.activeMapStyle);
  };

  private handleMapLoad = () => {};

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    // @note задел на будущее
    // this.uiStore.applicationSettings.events.isEmptyPiecesDeliveryman.watch(this.handleIsEmptyPiecesChange);
    this.uiStore.applicationSettings.events.activeMapStyleDeliveryman.watch(this.handleActiveMapStyleChange);
  };
}
