import { MapController } from "map-controller";
import { UiStore } from "store";
import * as Brokers from "./brokers";

export class GlobalBroker extends Brokers.Broker {
  private readonly observer: Brokers.ObserverBroker;
  private readonly transportHubs: Brokers.TransportHubsBroker;
  private readonly pedestrianGraph: Brokers.PedestrianGraphBroker;
  private readonly administrativeDivision: Brokers.AdministrativeDivisionBroker;
  private readonly urlParams: Brokers.UrlParamsBroker;
  private readonly busStops: Brokers.BusStopsBroker;
  private readonly travelHeatmap: Brokers.TravelHeatmapBroker;
  private readonly zoneStatistics: Brokers.ZoneStatisticsBroker;
  private readonly applicationSettings: Brokers.ApplicationSettings;

  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);

    this.observer = new Brokers.ObserverBroker(this.mapController, this.uiStore);
    this.transportHubs = new Brokers.TransportHubsBroker(this.mapController, this.uiStore);
    this.pedestrianGraph = new Brokers.PedestrianGraphBroker(this.mapController, this.uiStore);
    this.administrativeDivision = new Brokers.AdministrativeDivisionBroker(this.mapController, this.uiStore);
    this.urlParams = new Brokers.UrlParamsBroker(this.mapController, this.uiStore);
    this.busStops = new Brokers.BusStopsBroker(this.mapController, this.uiStore);
    this.travelHeatmap = new Brokers.TravelHeatmapBroker(this.mapController, this.uiStore);
    this.zoneStatistics = new Brokers.ZoneStatisticsBroker(this.mapController, this.uiStore);
    this.applicationSettings = new Brokers.ApplicationSettings(this.mapController, this.uiStore);
  }
}
