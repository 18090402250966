import { shared } from "shared";

type Param = {
  key: string;
  value: string;
};

const includeParam = (store: URLSearchParams) => (param: Param) => store.append(param.key, param.value);

export const includeParams = (path: string) => (params: Param[]) => {
  const urlParams = new URLSearchParams(path);
  params.forEach(includeParam(urlParams));
  return `${path}?${urlParams.toString()}`;
};

export const pullOutFeatures = (json: any) => json.features;
export const validateResponse = (res: Response) => {
  if (res.status !== 200 && res.status !== 204) return new Error();
  return res.json();
};
export const toJson = (res: Response) => res.json();
export const error = () => ({
  error: true,
});

export const CANCELED_FETCH_MESSAGE = "CANCELED_FETCH";

export const fetchFeatures = (url: string) => fetch(url).then(toJson).then(pullOutFeatures).catch(error);
export const fetchJson = (url: string) => fetch(url).then(toJson).catch(error);
export const safeFetchJson = (url: string) => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${shared.global.accessToken.value}`,
  };

  return fetch(url, { headers }).then(validateResponse).catch(error);
};

export const getParamsToString = <T>(params: T) => {
  const keysArray = [];
  for (const paramsKey in params) {
    const keyTS = paramsKey as keyof T;
    keysArray.push({
      key: paramsKey,
      value: Array.isArray(params[keyTS]) ? params[keyTS] : String(params[keyTS]),
    });
  }

  if (keysArray.length) {
    const getValidValue = (key: string, value: any) => {
      if (Array.isArray(value)) {
        return value.reduce((previousValue, currentValue, currentIndex) => {
          if (currentIndex === 0) {
            return previousValue + currentValue;
          }

          return previousValue + `&${key}=${currentValue}`;
        }, "");
      }
      return value;
    };
    return keysArray.reduce((previousValue, currentValue, currentIndex) => {
      if (currentIndex === 0 && keysArray.length > 1) {
        return `?${currentValue.key}=${getValidValue(currentValue.key, currentValue.value)}&`;
      }
      if (currentIndex === 0 && keysArray.length === 1) {
        return `?${currentValue.key}=${getValidValue(currentValue.key, currentValue.value)}`;
      }
      if (currentIndex === keysArray.length - 1) {
        return previousValue + `${currentValue.key}=${getValidValue(currentValue.key, currentValue.value)}`;
      }
      return previousValue + `${currentValue.key}=${getValidValue(currentValue.key, currentValue.value)}&`;
    }, "");
  }
  return "";
};
