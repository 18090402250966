import { memo } from "react";
import { GState } from "documentations";
import { shared } from "shared";
import { LimitsKeys } from "features/sector-analysis/types";
import "./sector-analysis-units-count-legend.scss";

type Props = {
  params: GState["sectorAnalysis"]["unitsCountDiagramParams"];
};

const baseClass = "sector-analysis-units-count-legend";
const circleClass = `${baseClass}__circle`;
const titleClass = `${baseClass}__title`;
const unitsClass = `${baseClass}__units`;
const maxColor = shared.unitsCountDiagram.unitsCountColor[4];
const getBackgroundColor = (backgroundColor: string) => ({ backgroundColor });

const Label = memo<{ limit: number; color: string }>(({ limit, color }) => {
  return (
    <div key={color} className={titleClass}>
      <div className={circleClass} style={getBackgroundColor(color)}></div>
      <span className={unitsClass}>{limit} ед.</span>
    </div>
  );
});

export const SectorAnalysisUnitsCountLegend = memo<Props>(({ params }) => {
  if (!params) return null;

  return (
    <div className={baseClass}>
      {Object.values(params.limits).map((limit, index) => {
        const color =
          shared.unitsCountDiagram.unitsCountColor[index as LimitsKeys] ??
          shared.unitsCountDiagram.unitsCountColor["-1"];
        return <Label limit={limit} color={color} />;
      })}
      <Label limit={params.max} color={maxColor} />
    </div>
  );
});
