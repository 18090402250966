import { Popups, Popup } from "../popup-controller";
import { RectangleBounds, getRectangleBounds } from "./get-rectangle-bounds";
import { checkIntersectRect } from "./check-intersect-rect";

interface GetIntersectedPopups {
  rectBounds: RectangleBounds;
  id: string;
  popups: Popups;
}

/**
 * Кeturns the popups that the rectangle intersect
 */
export function getIntersectedPopups({ rectBounds, id: popupId, popups }: GetIntersectedPopups) {
  const intersectedPopups: Popup[] = [];

  for (const [id, popup] of popups) {
    if (id === popupId) continue;
    const { translateX, translateY, width, height } = popup;
    const popupBounds = getRectangleBounds({ x: translateX, y: translateY, width, height });
    const isIntersect = checkIntersectRect(rectBounds, popupBounds);
    if (isIntersect) intersectedPopups.push(popup);
  }

  return intersectedPopups;
}
