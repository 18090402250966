/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
import React, { SyntheticEvent, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loader } from "components/loader";
import * as ACTIONS from "features/ctrl-route/store/slice";
import { GState } from "documentations";
import { RouteResponse } from "features/ctrl-route/types";
import { RouteCosting } from "api/router/model/router";
import { RouteInstructions } from "../route-instructions";
import { RouteVariantsItem } from "./route-variant-auto/route-variants-item";
import { getRouteDistributionWidth } from "./helpers/get-route-distribution-width";
import { getDifferenceTime } from "./helpers/get-difference-time";
import { RouteVariantBus } from "./route-variant-bus";
import { RouteVariantPedestrian } from "./route-variant-pedestrian";
import "./route-variants.scss";

export const RouteVariants: React.FC = () => {
  const dispatch = useDispatch();

  const {
    routeVariants,
    isManeuversShow,
    routeIsLoading,
    activeIndexRoute,
    selectedTime,
    routeTimeVariant,
    costing,
    isDisabled,
    isCompareWithYandex,
  } = useSelector(
    (state: GState) => ({
      routeVariants: state.router.routeVariants,
      isManeuversShow: state.router.isManeuversShow,
      routeIsLoading: state.router.routeIsLoading,
      activeIndexRoute: state.router.activeIndexRoute,
      selectedTime: state.view.selectedDay,
      routeTimeVariant: state.router.routeTimeVariant,
      costing: state.router.costing,
      isDisabled: state.router.isDisabled,
      isCompareWithYandex: state.router.isCompareWithYandex,
    }),
    shallowEqual
  );

  const handleClickManeuvers = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(ACTIONS.setIsShowManeuvers(true));
  };

  const renderVariant = useCallback(
    (variant: RouteResponse, index: number) => {
      const isActive = index === activeIndexRoute && routeTimeVariant === null;
      const differenceTime = getDifferenceTime(variant, routeVariants!);
      const handleToggleRoute = () => !isActive && dispatch(ACTIONS.setActiveIndexRoute(index));

      if (costing === RouteCosting.Auto || costing === RouteCosting.Cargo || costing === RouteCosting.Template) {
        const width = getRouteDistributionWidth(variant, routeVariants!);
        return (
          <RouteVariantsItem
            attributes={variant.original.trip.summary}
            handleToggleRoute={handleToggleRoute}
            key={index}
            index={index}
            isActive={isActive}
            score={variant.score}
            scoreIdz={variant.scoreIdz}
            variant={variant}
            width={width}
            differenceTime={differenceTime}
            handleClickManeuvers={handleClickManeuvers}
            maneuvers={isManeuversShow}
            selectedTime={selectedTime}
            isDisabled={isDisabled}
          />
        );
      }

      if (costing === RouteCosting.Pedestrian) {
        return <RouteVariantPedestrian key={index} variant={variant} />;
      }

      return <RouteVariantBus key={index} variant={variant} />;
    },
    [routeVariants, activeIndexRoute, routeTimeVariant, selectedTime, isManeuversShow, handleClickManeuvers]
  );

  return (
    <div className="ctrl-route-variants">
      <div className="ctrl-route-variants-container">
        {routeIsLoading && <Loader />}

        {!routeIsLoading &&
          routeVariants?.map((variant, index) => {
            if (isCompareWithYandex && index !== 0) return null;
            const isActive = index === activeIndexRoute;

            if (!isManeuversShow) return renderVariant(variant, index);

            if (isManeuversShow && isActive)
              return (
                <React.Fragment key={index}>
                  {renderVariant(variant, index)}
                  {isManeuversShow && <RouteInstructions />}
                </React.Fragment>
              );

            return null;
          })}
      </div>
    </div>
  );
};
