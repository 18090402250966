import React from "react";
import { Props } from "./row.types";

import "./row.scss";

const row = `details-administrative-division__row`;

export const Row = React.memo((props: Props) => {
  return (
    <div className={row}>
      <span className={`${row}__title`}>{props.title}:</span>
      <span className={`${row}__value`}>{props.children}</span>
    </div>
  );
});
