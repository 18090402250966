import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { TransportHubsTypes } from "types";
import { Broker } from "./broker";

export class TransportHubsBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleUiIsVisibleChange = (store: GState.TransportHubsState) => {
    this.mapController.transportHubs?.setVisibility?.(store.isVisible);
  };

  private handleFeatureCollectionLoad = (collection: TransportHubsTypes.FeatureCollection) => {
    this.mapController.transportHubs?.setData(collection);
  };

  private handleMapLoad = () => {
    this.uiStore.transportHubs.events.setIsDisabled(false);
  };

  protected readonly subscribe = () => {
    this.uiStore.transportHubs.events.handleIsVisibleChange.watch(this.handleUiIsVisibleChange);
    this.uiStore.transportHubs.effects.loadTransportHubs.doneData.watch(this.handleFeatureCollectionLoad);
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
  };
}
