import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";
import getBeforeId, { TELEMETRY_BIND_UNMATCHED_POINT_ID } from "map-helpers/order-layers";
import { TELEMETRY_MAP_UNMATCHED_IMAGE_KEY } from "map-helpers/assets/map-svg-icons";

export class TelemetryBindUnmatchedPointsLayer extends BaseLayer.Abstract<GeoJSON.Point, GeoJSON.GeoJsonProperties> {
  private layerConfig: mapboxgl.SymbolLayer = {
    id: TELEMETRY_BIND_UNMATCHED_POINT_ID,
    type: "symbol",
    source: TELEMETRY_BIND_UNMATCHED_POINT_ID,
    layout: {
      "icon-image": TELEMETRY_MAP_UNMATCHED_IMAGE_KEY,
      "icon-size": ["interpolate", ["exponential", 1.5], ["zoom"], 9, 0.2, 18, 0.6],
      "icon-rotate": ["get", "bearing"],
      "icon-allow-overlap": true,
    },
  };

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: TELEMETRY_BIND_UNMATCHED_POINT_ID,
      beforeId: getBeforeId(TELEMETRY_BIND_UNMATCHED_POINT_ID, map),
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }
}
