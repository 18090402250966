import { APP_ENV } from "app-env";

export const mapStyles: MapStyle[] = [];
export const defaultStyle: MapStyle = {
  id: "megapolis_light",
  style: `${APP_ENV.REACT_APP_MAP_STYLE}styles?l=vector`,
  name: "Светлая",
};

if (APP_ENV.REACT_APP_CONFIGURATION?.settings?.mapStyles?.includes("light")) {
  mapStyles.push(defaultStyle);
}

if (APP_ENV.REACT_APP_CONFIGURATION?.settings?.mapStyles?.includes("dark")) {
  mapStyles.push({
    id: "megapolis_dark",
    style: `${APP_ENV.REACT_APP_MAP_STYLE}styles/megapolis-dark/style.json`,
    name: "Тёмная",
  });
}

if (APP_ENV.REACT_APP_CONFIGURATION?.settings?.mapStyles?.includes("dtm_style")) {
  mapStyles.push({
    id: "dtm_style",
    style: "https://dev-api.megapolis-it.ru/dtm/traffic-api-dev/api/Map/styles.json",
    name: "Стиль ДТМ",
  });
}

if (APP_ENV.REACT_APP_CONFIGURATION?.settings?.mapStyles?.includes("roadcad")) {
  mapStyles.push({
    id: "megapolis_roadcad",
    style: `${APP_ENV.REACT_APP_MAP_STYLE}styles?l=vector&l=roads`,
    name: "Дорожная разметка",
  });
}

if (APP_ENV.REACT_APP_CONFIGURATION?.settings?.mapStyles?.includes("satellite")) {
  mapStyles.push({
    id: "google_satellite",
    style: {
      glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      version: 8,
      name: "Empty",
      sources: {
        "raster-tiles": {
          type: "raster",
          tiles: ["https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"],
          tileSize: 512,
        },
      },
      layers: [{ id: "simple-tiles", type: "raster", source: "raster-tiles", minzoom: 0, maxzoom: 22 }],
    },
    name: "Спутник",
  });
}
