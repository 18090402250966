import * as turf from "@turf/turf";
import { FullPosition, RnisFullPosition } from "api/telemetry/model/models";
import { RnisTelemetryFeatureProperties } from "../types";

export function getBindUnmatchedPointsData(points: any, positions: FullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.Point, any>[] = [];

  for (const [i, position] of positions.entries()) {
    const point = points[i];

    if (point.type === "unmatched")
      features.push(
        turf.point([position.longitude, position.latitude], {
          esid: position.externalSystemID,
          bearing: position.bearing,
          speed: position.speed! / 10,
          tmid: position.externalTerminalID,
          unitid: position.externalUnitID,
          time: position.dateTime,
        })
      );
  }

  return features;
}

export function getRnisBindUnmatchedPointsData(points: any, positions: RnisFullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.Point, RnisTelemetryFeatureProperties>[] = [];

  for (const [i, position] of positions.entries()) {
    const point = points[i];

    if (point.type === "unmatched")
      features.push(
        turf.point([position.lng, position.lat], {
          om_id: position.vehicleId,
          tt: position.tt,
          dir: position.dir,
          speed: position.speed,
        })
      );
  }

  return features;
}
