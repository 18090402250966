import { TrafficAPI } from "api/traffic";
import { TrafficTileFilter } from "api/traffic/model/tiles";
import { TrafficPeriod } from "api/traffic/model/traffic";
import TrafficProperties from "../properties/traffic-properties";
import getBeforeId from "../order-layers";

export default class CorrelationLayer {
  constructor(map) {
    this.map = map;
    this.layerId = "correlation-traffic";
    this.properties = new TrafficProperties();
    this.from = null;
    this.to = null;
    this.type = "last";
    this.es = [];
    this.filter = "city";
    this.isLocalDistortions = false;

    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
  }

  get forecastLayerId() {
    return `${this.layerId}-forecast`;
  }

  get historicalLayerId() {
    return `${this.layerId}-historical`;
  }

  getUrlTiles() {
    const { es, filter, from, to } = this;
    const input = { es };
    if (filter === "highway") input.filter = TrafficTileFilter.Highway;
    if (filter === "trans") input.filter = TrafficTileFilter.Transport;
    if (filter === "dedicated") input.filter = TrafficTileFilter.DedicatedLines;
    if (from !== null && to !== null) {
      input.from = from;
      input.to = to;
    } else {
      input.period = TrafficPeriod.Last;
    }

    return TrafficAPI.tiles.trafficDiff(input);
  }

  addHistoricalLayer() {
    const historicalLayer = {
      "id": this.historicalLayerId,
      "type": "line",
      "source": this.layerId,
      "source-layer": "diff",
      "layout": this.properties.getLayout(),
      "paint": {
        "line-color": this.properties.getLineColorHistorical(this.isLocalDistortions),
        "line-offset": this.properties.getLineOffset(),
        "line-width": this.properties.getLineWidth(),
        "line-opacity": 1,
      },
    };
    const beforeId = getBeforeId(this.historicalLayerId, this.map);
    this.map.addLayer(historicalLayer, beforeId);
  }

  addForecastLayer() {
    const forecastLayer = {
      "id": this.forecastLayerId,
      "type": "line",
      "source": this.layerId,
      "source-layer": "diff",
      "layout": this.properties.getLayout(),
      "paint": {
        "line-color": this.properties.getLineColorForecast(this.isLocalDistortions),
        "line-offset": this.properties.getLineOffset(),
        "line-width": this.properties.getLineWidth(),
        "line-dasharray": this.properties.getLineDashArray(),
        "line-opacity": 1,
      },
    };
    const beforeId = getBeforeId(this.forecastLayerId, this.map);
    this.map.addLayer(forecastLayer, beforeId);
  }

  setIsLocalDistortions = (isLocalDistortions = false) => {
    this.isLocalDistortions = isLocalDistortions;
    this.map.setPaintProperty(
      this.historicalLayerId,
      "line-color",
      this.properties.getLineColorHistorical(this.isLocalDistortions)
    );
    this.map.setPaintProperty(
      this.forecastLayerId,
      "line-color",
      this.properties.getLineColorForecast(this.isLocalDistortions)
    );
  };

  addSource() {
    const url = this.getUrlTiles();
    this.map.addSource(this.layerId, {
      type: "vector",
      tiles: [url],
    });
  }

  checkLayer() {
    const check = this.map.getLayer(this.historicalLayerId);
    return check;
  }

  checkSource() {
    const check = this.map.getSource(this.layerId);
    return check;
  }

  add() {
    if (!this.checkSource()) this.addSource();

    if (!this.checkLayer()) {
      this.addHistoricalLayer();
      this.addForecastLayer();
    }
  }

  remove() {
    if (this.checkLayer()) {
      this.map.removeLayer(this.historicalLayerId);
      this.map.removeLayer(this.forecastLayerId);
    }
    if (this.checkSource()) this.map.removeSource(this.layerId);
  }

  update({ from, to, filter, es }) {
    this.from = from;
    this.to = to;
    this.filter = filter;
    this.es = es;
    this.remove();
    this.add();
  }

  setOpacity = (isVisible) => {
    this.opacity = isVisible ? 1 : 0;
    const forecastLayer = this.map.getLayer(this.forecastLayerId);
    const historicalLayer = this.map.getLayer(this.forecastLayerId);
    if (!forecastLayer || !historicalLayer) return;
    this.map.setPaintProperty(this.forecastLayerId, "line-opacity", this.opacity);
    this.map.setPaintProperty(this.historicalLayerId, "line-opacity", this.opacity);
  };
}
