import { createEffect } from "effector";
import { TravelHeatmapAPI } from "api";
import { TravelHeatmapTypes } from "types";
import { PayloadCache } from "./payload-cache";
import * as utils from "./travel-heatmap.utils";

const sectorInfoCache = new PayloadCache();
const graphDataCache = new PayloadCache();

const loadSectorInfo = createEffect(async (payload: TravelHeatmapTypes.LoadSectorInfoPayload): Promise<
  TravelHeatmapTypes.SectorInformation | Error
> => {
  const payloadKey = utils.payloadToJSON(payload);
  const cache = sectorInfoCache.getCache<TravelHeatmapTypes.SectorInformation>(payloadKey);
  if (cache) {
    return Promise.resolve(cache);
  }
  loadMissingSectorInfoAbortController = new AbortController();

  const response = await new TravelHeatmapAPI().getSectorInfo(payload);
  if (response instanceof Error) return response;
  sectorInfoCache.addCache(payloadKey, response);
  return response;
});

let loadMissingSectorInfoAbortController = new AbortController();
const loadMissingSectorInfo = createEffect((payload: TravelHeatmapTypes.LoadSectorInfoPayload): Promise<
  TravelHeatmapTypes.SectorInformation | Error
> => {
  loadMissingSectorInfoAbortController.abort();
  loadMissingSectorInfoAbortController = new AbortController();
  return new TravelHeatmapAPI().getSectorInfo(payload, loadMissingSectorInfoAbortController);
});

const loadCorrespondenceMax = createEffect((payload?: TravelHeatmapTypes.RequestStatisticPayload): Promise<
  number | Error
> => {
  if (!payload) return Promise.resolve(new Error());
  return new TravelHeatmapAPI().getCorrespondenceMax(payload);
});

const loadStatisticMax = createEffect((payload?: TravelHeatmapTypes.RequestStatisticPayload): Promise<
  number | Error
> => {
  if (!payload) return Promise.resolve(new Error());
  return new TravelHeatmapAPI().getMax(payload);
});

let loadWeekDaysController = new AbortController();
let loadHoursController = new AbortController();
const loadGraphicsData = createEffect(
  async (payload: TravelHeatmapTypes.RequestStatisticPayload | null): Promise<any> => {
    if (!payload) {
      return Promise.resolve({
        days: [],
        hours: [],
      });
    }

    loadWeekDaysController.abort();
    loadHoursController.abort();
    loadWeekDaysController = new AbortController();
    loadHoursController = new AbortController();

    const payloadKey = utils.payloadToJSON(payload);
    const cache = graphDataCache.getCache<any>(payloadKey);

    if (cache) {
      return Promise.resolve(cache);
    }

    const result = await Promise.all([
      new TravelHeatmapAPI().getWeekDays(payload, loadWeekDaysController),
      new TravelHeatmapAPI().getHours(payload, loadHoursController),
    ])
      .then(([days, hours]) => {
        return {
          days: days?.data?.map((day: any) => day?.value) ?? [],
          hours: hours?.data?.map((hour: any) => hour?.value) ?? [],
        };
      })
      .catch(console.error);

    graphDataCache.addCache(payloadKey, result ?? null);

    return result;
  }
);

export const effects = {
  loadSectorInfo,
  loadMissingSectorInfo,
  loadStatisticMax,
  loadCorrespondenceMax,
  loadGraphicsData,
};

export type Effects = typeof effects;
