export const districts = [
  {
    id: 15,
    name: "ЮАО",
    external_cod: 12,
  },
  {
    id: 14,
    name: "ВАО",
    external_cod: 11,
  },
  {
    id: 13,
    name: "САО",
    external_cod: 10,
  },
  {
    id: 12,
    name: "ЮЗАО",
    external_cod: 9,
  },
  {
    id: 11,
    name: "СЗАО",
    external_cod: 8,
  },
  {
    id: 10,
    name: "СВАО",
    external_cod: 7,
  },
  {
    id: 9,
    name: "ЗАО",
    external_cod: 6,
  },
  {
    id: 8,
    name: "ЗелАО",
    external_cod: 5,
  },
  {
    id: 7,
    name: "ЦАО",
    external_cod: 4,
  },
  {
    id: 6,
    name: "ЮВАО",
    external_cod: 2,
  },
  {
    id: 5,
    name: "Московская область",
    external_cod: null,
  },
  {
    id: 4,
    name: "в большинстве округах",
    external_cod: 1,
  },
  {
    id: 3,
    name: "Москва",
    external_cod: -1,
  },
  {
    id: 2,
    name: "в отдельных округах",
    external_cod: null,
  },
  {
    id: 1,
    name: "повсеместно",
    external_cod: null,
  },
];
