import { Theme } from "react-select";

export const customStyles = {
  option: (provided: Theme) => ({
    ...provided,
    cursor: "pointer",
  }),
};

export const themeSelect = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: "transparent",
  },
});
