import mapboxgl from "mapbox-gl";
import * as THREE from "three";
// @ts-ignore
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { Layers } from "../../layers";

const radius = 420;
const radialSegments = 6;

export class TravelHeatmapLayer3D implements mapboxgl.CustomLayerInterface {
  public readonly id: string = "TRAVEL_HEATMAP_3D_ID";
  public readonly type: "custom" = "custom";
  public readonly renderingMode: "3d" = "3d";

  private renderer?: THREE.WebGLRenderer;
  private scene?: THREE.Scene;
  private camera?: THREE.Camera;
  private world?: THREE.Group;
  private colorPrice = 0;
  private raycaster?: THREE.Raycaster;
  private cameraTransform?: THREE.Matrix4;
  private cameraCenter?: mapboxgl.MercatorCoordinate;
  private features: GeoJSON.Feature<any, any>[] = [];
  private _isVisible = false;
  private minMaxFilter: TravelHeatmapTypes.MinMaxFilter = [0, 1000000000];

  public get visibility() {
    return this._isVisible;
  }

  public set visibility(isVisible: boolean) {
    this._isVisible = isVisible;
    this.searchFeatures();
    this.fillScene();
    this.map.triggerRepaint();
  }

  constructor(private readonly map: mapboxgl.Map) {
    this.map.addLayer(this);
  }

  private searchFeatures = () => {
    this.features = this.map.querySourceFeatures(Layers.Identifiers.TRAVEL_HEATMAP_ID, { sourceLayer: "h3" });
  };

  private parseColor = (color: string) => {
    const hexadecimalString = color.replace("#", "");
    let decimalColor: number | null = null;
    try {
      decimalColor = parseInt(hexadecimalString, 16);
    } catch {}

    return decimalColor;
  };

  private createLightning = () => {
    const skyColor = 0xb1e1ff; // light blue
    const groundColor = 0xb97a20; // brownish orange

    this.scene?.add(new THREE.AmbientLight(0xffffff, 0.25));
    this.scene?.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(-70, -70, 100).normalize();
    this.scene?.add(directionalLight);
  };

  private clearScene = () => {
    if (!this.world) return;

    while (this.world.children.length) {
      const item = this.world.children[this.world.children.length - 1];
      const mesh = item as THREE.Mesh;
      if (!Array.isArray(mesh.material)) {
        mesh.material?.dispose?.();
      }
      mesh.geometry?.dispose?.();
      this.world.remove(item);
    }
    this.world.clear();
  };

  private getColor = (trips: number) => {
    if (trips >= 0 && trips <= this.colorPrice) return shared.travelHeatmap.colorPalette[1];
    if (trips >= this.colorPrice && trips < this.colorPrice * 2) return shared.travelHeatmap.colorPalette[2];
    if (trips >= this.colorPrice * 2 && trips < this.colorPrice * 3) return shared.travelHeatmap.colorPalette[3];
    if (trips >= this.colorPrice * 3 && trips < this.colorPrice * 4) return shared.travelHeatmap.colorPalette[4];
    if (trips >= this.colorPrice * 4 && trips < this.colorPrice * 5) return shared.travelHeatmap.colorPalette[5];
    if (trips >= this.colorPrice * 5 && trips < this.colorPrice * 6) return shared.travelHeatmap.colorPalette[6];
    if (trips >= this.colorPrice * 6 && trips < this.colorPrice * 7) return shared.travelHeatmap.colorPalette[7];
    if (trips >= this.colorPrice * 7 && trips < this.colorPrice * 8) return shared.travelHeatmap.colorPalette[8];
    if (trips >= this.colorPrice * 8 && trips < this.colorPrice * 9) return shared.travelHeatmap.colorPalette[9];
    return shared.travelHeatmap.colorPalette[0];
  };

  private fillScene = () => {
    if (!this.scene || !this.world || !this.cameraCenter) return;

    const scale = this.cameraCenter.meterInMercatorCoordinateUnits();
    this.clearScene();

    const geometries: {
      [key: string]: { colorMaterial: THREE.MeshPhongMaterial; geometries: THREE.CylinderGeometry[] };
    } = {};

    let i = 0;
    for (i; i < this.features?.length; i++) {
      const properties = this.features[i].properties;

      if (properties.Value >= this.minMaxFilter[0] && properties.Value <= this.minMaxFilter[1]) {
        const height = properties.Value;
        const mercator = mapboxgl.MercatorCoordinate.fromLngLat([properties.centr_lng, properties.centr_lat]);
        const geometry = new THREE.CylinderGeometry(radius, radius, height, radialSegments);
        const parsedColor = this.parseColor(this.getColor(properties.Value)) ?? 0x0262cc;
        const dx = -(this.cameraCenter.x - mercator.x) / scale;
        const dy = (this.cameraCenter.y - mercator.y) / scale;

        geometry.rotateX(Math.PI / 2);
        geometry.rotateZ(Math.PI / 1.93);
        geometry.translate(dx, dy, height / 2);

        if (!geometries[parsedColor]) {
          geometries[parsedColor] = {
            colorMaterial: new THREE.MeshPhongMaterial({
              transparent: true,
              opacity: 0.7,
              color: parsedColor,
            }),
            geometries: [],
          };
        }

        geometries[parsedColor].geometries.push(geometry);
      }
    }

    const geometriesInfo = Object.values(geometries);

    i = 0;
    for (i; i < geometriesInfo.length; i++) {
      const element = geometriesInfo[i];
      const geometry = mergeBufferGeometries(element.geometries);
      const mesh = new THREE.Mesh(geometry, element.colorMaterial);
      this.world.add(mesh);
    }
  };

  private handleSourceData = (event: { sourceId: string }) => {
    if (
      event.sourceId !== Layers.Identifiers.TRAVEL_HEATMAP_ID ||
      !this.map.getSource(Layers.Identifiers.TRAVEL_HEATMAP_ID) ||
      !this.map.isSourceLoaded(Layers.Identifiers.TRAVEL_HEATMAP_ID)
    ) {
      return;
    }

    this.searchFeatures();
    this.fillScene();
  };

  public readonly onRemove = () => {
    this.map.off("sourcedata", this.handleSourceData);
  };

  public readonly onAdd = (map: mapboxgl.Map, gl: WebGLRenderingContext) => {
    this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6);
    this.scene = new THREE.Scene();
    this.world = new THREE.Group();
    this.scene.add(this.world);

    this.cameraCenter = mapboxgl.MercatorCoordinate.fromLngLat(this.map.getCenter(), 0);

    const { x, y, z } = this.cameraCenter;
    this.world.scale.setScalar(this.cameraCenter.meterInMercatorCoordinateUnits());
    this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z ?? 0).scale(new THREE.Vector3(1, -1, 1));

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
    this.raycaster = new THREE.Raycaster();

    this.createLightning();

    if (this.features.length) {
      this.fillScene();
    }

    this.map.on("sourcedata", this.handleSourceData);
    this.map.triggerRepaint();
  };

  public readonly setColorPrice = (colorPrice: number) => {
    this.colorPrice = colorPrice;
  };

  public readonly setMinMaxFilter = (minMaxFilter: TravelHeatmapLayer3D["minMaxFilter"]) => {
    this.minMaxFilter = minMaxFilter;
    this.fillScene();
    this.map.triggerRepaint();
  };

  public readonly render = (gl: WebGLRenderingContext, matrix: number[]) => {
    if (!this._isVisible) return;
    if (!this.renderer || !this.scene || !this.camera || !this.cameraTransform) return;

    this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
  };

  public readonly destroy = () => {
    this.map.off("sourcedata", this.handleSourceData);
    if (!this.map.getLayer(this.id)) return;
    this.map?.removeLayer(this.id);
  };
}
