import { useEffect } from "react";
import { loadedTransportTypeDictionary } from "./view/action-creators";
import type { Dispatch } from "redux";
import { RouterAPI } from "../api/router";

export const useLoadingTransportTypeDictionary = (dispatch: Dispatch<any>) => {
  useEffect(() => {
    RouterAPI.meta.externalsystems().then((data) => {
      const filterData = data.filter((item) => item?.isShowInUI);
      dispatch(loadedTransportTypeDictionary(filterData));
    });
  }, [dispatch]);
};
