/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cn from "classnames";
import { AnalysisType, ChangeDateProps } from "features/sector-analysis/types";
import FilterExternal from "features/filter-external-system/filter-external-system";
import { ReactComponent as ArrowIcon } from "assets/icons/stroke-arrow.svg";
import { DayOfWeek } from "api/router/model/models";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { Checkbox, CustomDatePicker, MultiDaySelector, OneDaySelector } from "components";

import "./index.scss";

type Props = {
  dateFrom: string;
  dateTo: string;
  changeDate: (value: ChangeDateProps) => void;
  es: Array<number>;
  changeEs: (value: Array<number>) => void;
  changeIsShowEvent?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  isShowEvents?: boolean;
  changeIsShowCompare?: () => void;
  isShowCompare?: boolean;
  type: AnalysisType;
  days: Array<DayOfWeek>;
  changeDays: (value: Array<DayOfWeek>) => void;
};

const className = "sector-analysis-filter";

const useGetIsActiveHeatmap = () => useSelector((store: GState) => store.sectorAnalysis.isActiveHeatmap);
const useIsFiltersDisabled = () => useSelector((store: GState) => store.sectorAnalysis.isFiltersDisabled, shallowEqual);

const useTransportTypeDictionary = () =>
  //список без Детектора
  useSelector((state: GState) => state.view.transportTypeDictionary)?.filter((item) => item.id !== 101);

export const SectorAnalysisFilter = ({
  es,
  days,
  type,
  dateTo,
  dateFrom,
  isShowCompare,
  changeEs,
  changeDate,
  changeDays,
  changeIsShowCompare,
}: Props) => {
  const dispatch = useDispatch();
  const transportTypeDictionary = useTransportTypeDictionary();
  const isActiveHeatmap = useGetIsActiveHeatmap();
  const isFiltersDisabled = useIsFiltersDisabled();
  const handleChangeIsActiveHeatmap = useCallback(() => {
    dispatch(sectorAnalysisSlice.actions.changeIsActiveHeatmap());
  }, [dispatch]);
  const changeRangeDate = (from: string | null, to?: string | null) => {
    changeDate({
      dateFrom: moment(from).startOf("day").toISOString(true),
      dateTo: moment(to).add("1", "day").startOf("day").toISOString(true),
    });
  };

  const isOneDayRange = moment(dateFrom).get("dayOfYear") === moment(dateTo).subtract(1, "s").get("dayOfYear");

  const butonClasName = cn(`${className}__button`, {
    [`${className}__button_compare`]: isShowCompare,
  });

  return (
    <div className={className}>
      <CustomDatePicker
        disabled={isFiltersDisabled}
        dateFrom={dateFrom}
        dateTo={dateTo}
        limitPeriod={31}
        onChange={changeRangeDate}
        type={"PERIOD"}
      />

      <div className={`${className}__days`}>
        {isOneDayRange ? (
          <OneDaySelector disabled={isFiltersDisabled} date={dateFrom} onChange={changeRangeDate} />
        ) : (
          <div className={`${className}__multi`}>
            <MultiDaySelector
              from={dateFrom}
              to={dateTo}
              disabled={isOneDayRange || isFiltersDisabled}
              selectedDays={days}
              onChangeDays={changeDays}
              changeDate={changeRangeDate}
            />
          </div>
        )}
      </div>

      <FilterExternal
        disabled={isFiltersDisabled}
        selected={es}
        updateSelected={changeEs}
        rowHeight={32}
        transportTypeDictionary={transportTypeDictionary}
      />

      {type === "current" && (
        <button className={butonClasName} onClick={changeIsShowCompare}>
          {isShowCompare && <ArrowIcon />}
          <span className={`${className}__button-label`}>{!isShowCompare ? "Сравнить" : "Свернуть"}</span>
          {!isShowCompare && <ArrowIcon />}
        </button>
      )}

      {type === "compare" && (
        <>
          <div className={`${className}-show-event`}>
            <Checkbox
              disabled={isFiltersDisabled}
              value="Разница значений"
              onChange={handleChangeIsActiveHeatmap}
              checked={isActiveHeatmap}
              label="Разница значений"
            />
          </div>
        </>
      )}
    </div>
  );
};
