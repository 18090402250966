import { memo } from "react";

import "./legend-item.scss";

type Props = {
  icon?: React.ReactNode;
  title: string;
};

const baseClass = "legend-item";
const titleClass = `${baseClass}__title`;

export const LegendItem = memo<Props>(({ icon, title }) => {
  return (
    <div className={baseClass}>
      {icon}
      <span className={titleClass}>{title}</span>
    </div>
  );
});
