import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialStateFromUrl } from "utils/get-initial-state-from-url";
import { SpeedcamItem, SpeedcamState } from "../types";
import { getStatisticById, GetStatisticByIdParamsType, StatisticItemType } from "../../../api/speedcam";

export const initialState: SpeedcamState = {
  isActive: getInitialStateFromUrl("speedcam"),
  speedcams: {},
};
const NAME = "speedcam";
export const speedcamSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    setCurrentMode(state, action: PayloadAction<{ id: number; mode: SpeedcamItem["currentMode"] }>) {
      const { mode, id } = action.payload;
      state.speedcams[id] = { ...state.speedcams[id], currentMode: mode };
    },
    setFilter(state, action: PayloadAction<{ id: number; filter: SpeedcamItem["filter"] }>) {
      const { filter, id } = action.payload;
      state.speedcams[id] = { ...state.speedcams[id], filter };
    },
    setOverSpeed(state, action: PayloadAction<{ id: number; overSpeed: SpeedcamItem["overSpeed"] }>) {
      const { overSpeed, id } = action.payload;
      state.speedcams[id] = { ...state.speedcams[id], overSpeed };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadStatisticById.pending, (state, action) => {
      state.speedcams[action.meta.arg.whCamId] = {
        ...state.speedcams[action.meta.arg.whCamId],
        countFixations: undefined,
        countViolations: undefined,
      };
    });
    builder.addCase(loadStatisticById.fulfilled, (state, action) => {
      const { violations, fixations, countViolations, countFixations } = action.payload;
      if (fixations.length) {
        state.speedcams[action.meta.arg.whCamId] = {
          overSpeed: state.speedcams[action.meta.arg.whCamId]?.overSpeed || 0,
          filter: state.speedcams[action.meta.arg.whCamId]?.filter,
          currentMode: state.speedcams[action.meta.arg.whCamId]?.currentMode || "fixations",
          violations,
          fixations,
          countViolations,
          countFixations,
        };
      } else {
        state.speedcams[action.meta.arg.whCamId] = {
          overSpeed: state.speedcams[action.meta.arg.whCamId]?.overSpeed || 0,
          filter: state.speedcams[action.meta.arg.whCamId]?.filter,
          currentMode: state.speedcams[action.meta.arg.whCamId]?.currentMode || "fixations",
          countViolations: 0,
          countFixations: 0,
          violations: [],
          fixations: [],
        };
      }
    });
  },
});
export const loadStatisticById = createAsyncThunk(
  `${NAME}/loadStatisticById`,
  async (params: GetStatisticByIdParamsType) => {
    try {
      const res = await getStatisticById(params);
      const fixations = res.data.items.reduce((prev: StatisticItemType[], current: StatisticItemType) => {
        if (current.date === prev[prev.length - 1]?.date) {
          prev[prev.length - 1].count += current.count;
          return prev;
        }
        return [...prev, current];
      }, []);
      const violations = res.data.items.filter((i) => i.kind === 2);
      const countFixations = fixations.reduce((prev: number, current: StatisticItemType) => prev + current.count, 0);
      const countViolations = violations.reduce((prev: number, current: StatisticItemType) => prev + current.count, 0);
      return {
        fixations,
        violations,
        countViolations,
        countFixations,
      };
    } catch (error) {
      throw error;
    }
  }
);
