import { constants } from "./store.constants";

export enum EMode {
  View = "view",
  Edit = "edit",
}

export interface Attribute {
  guid: string;
  label: string;
  value: string;
  isNew: boolean;
}

export interface InitializeState {
  readonly type: typeof constants.INITIALIZE;
  readonly payload: Attribute[];
}

export interface CreateAttribute {
  readonly type: typeof constants.CREATE;
  readonly payload: Attribute;
}

export interface DeleteAttribute {
  readonly type: typeof constants.DELETE;
  readonly payload: {
    id: string;
    guid: string;
  };
}

export interface SubmitSave {
  readonly type: typeof constants.SUBMIT_SAVE;
  readonly payload: {
    id: string;
    attribute: Attribute;
  };
}

export type Action = InitializeState | CreateAttribute | DeleteAttribute | SubmitSave;
