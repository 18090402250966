import React, { memo } from "react";
import { Checkbox } from "components";
import { CheckboxGroup } from "../checkbox-group";
import { useValues, useCallbacks } from "./traffic-score-checkboxes.hooks";

export const TrafficScoreCheckboxes = memo(() => {
  const { isShowTrafficScore, isShowGlobalTrafficScore } = useValues();
  const { handleIsShowTrafficScoreChange, handleIsShowGlobalTrafficScoreChange } = useCallbacks();

  return (
    <CheckboxGroup>
      <Checkbox
        value=""
        label="Балл пробок по маршруту"
        title="Балл пробок по маршруту"
        checked={isShowTrafficScore}
        onChange={handleIsShowTrafficScoreChange}
      />
      <Checkbox
        value=""
        label="Балл пробок по городу"
        title="Балл пробок по городу"
        checked={isShowGlobalTrafficScore}
        onChange={handleIsShowGlobalTrafficScoreChange}
      />
    </CheckboxGroup>
  );
});
