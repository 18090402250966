import React, { FC, useEffect } from "react";
import styled, { css } from "styled-components";

interface RoundToggleButtonPropsType {
  values: { value: string; caption: string; hoverTitle?: string }[];
  activeValue: string;
  setActive: (v: string) => void;
  disableValues?: string[];
}

export const RoundToggleButton: FC<RoundToggleButtonPropsType> = (props) => {
  useEffect(() => {
    if (props.disableValues?.includes(props.activeValue)) {
      const innerValue = props.values.filter((x) => x.value !== props.activeValue)[0]?.value;
      props.setActive(innerValue);
    }
  }, [props.disableValues, props.activeValue, props.values]);

  return (
    <ToggleButtonBlock disable={Boolean(props.disableValues?.length)}>
      {props.values.map((item) => {
        const isDisable = props.disableValues?.includes(item.value);
        const className = item.value === props.activeValue ? "active-button" : "";
        return (
          <Button
            title={item.hoverTitle}
            key={item.value}
            onClick={() => (isDisable ? null : props.setActive(item.value))}
            buttonsCount={props.values.length}
            disable={isDisable}
            className={className}>
            {item.caption}
          </Button>
        );
      })}
    </ToggleButtonBlock>
  );
};

const ToggleButtonBlock = styled.div<{ disable: boolean }>`
  width: 100%;
  display: flex;
  border-radius: 30px;
  border: 1px solid #e0e0e0;
  height: 30px;
  ${(props) =>
    props.disable &&
    css`
      background: rgba(3, 40, 76, 0.05);
    `}
  .active-button {
    background-color: #62c400;
    color: #ffffff;
    border-radius: 30px;
  }
`;

const Button = styled.div<{ buttonsCount: number; disable?: boolean }>`
  width: ${(props) => 100 / props.buttonsCount}%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disable &&
    css`
      cursor: initial;
      opacity: 0.5;
    `}
`;
