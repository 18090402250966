import { store } from "./application-settings";
import { events } from "./application-settings.events";
import * as reducers from "./application-settings.reducers";

/** Фабрика по созданию подписок на изменение стора */
export const subscribesFactory = () => {
  store.on(events.handleActiveMapStyleChange, reducers.activeMapStyleReducer);
  store.on(events.handleTelematicsIsEmptyPieces, reducers.isTelematicsEmptyPiecesReducer);
  store.on(events.handleApplicationSettingsButtonClick, reducers.isPopupVisibleNextReducer);
  store.on(events.handleOutsideClick, reducers.isPopupVisibleOutsideClickReducer);
};
