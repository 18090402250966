import { Attribute } from "./store.types";

export const initialize = (id: string): Attribute[] => {
  try {
    const strData = sessionStorage.getItem(`dtm_edge_${id}`);

    if (!strData) return [];

    return JSON.parse(strData) as Attribute[];
  } catch (err) {
    return [];
  }
};
