import React from "react";
import { Row } from "./components";
import { Props } from "./general-info.types";

import "./general-info.scss";

const generalInfo = `details-administrative-division__general-info`;

export const GeneralInfo = (props: Props) => {
  return (
    <div className={generalInfo} data-cypress={"general-info"}>
      <Row title="Дата">{props.date}</Row>
      <Row title="Балл пробок">{props.trafficScore}</Row>
      <Row title="ИДЗ">{props.trafficIndex}</Row>
      <Row title="Длина дорожной сети">
        {props.roadsLength} <span className={`${generalInfo}__dimension`}>м</span>
      </Row>
    </div>
  );
};
