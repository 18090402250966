import { RouteResponse } from "features/ctrl-route/types";

function getMaxOfArray(numArray: Array<any>) {
  return Math.max.apply(null, numArray);
}

export function getRouteDistributionWidth(variant: RouteResponse, routeVariants: Array<RouteResponse>) {
  const allTimes: Array<number> = [];

  routeVariants.forEach((el) => {
    allTimes.push(el.time);
    allTimes.push(el.compareTime);
  });

  const itemTime = variant.time > variant.compareTime ? variant.time : variant.compareTime;

  const maxTime = getMaxOfArray(allTimes);

  const ratio = itemTime / maxTime;

  return ratio;
}

export function getDistributionByLength(variant: RouteResponse, routeVariants: Array<RouteResponse>) {
  const allLength = routeVariants.map((el) => el.length);

  const itemLength = variant.length;

  const maxTime = getMaxOfArray(allLength);

  const ratio = itemLength / maxTime;

  return ratio;
}
