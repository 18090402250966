// @ts-nocheck
import { memo, useCallback, useState } from "react";
import { AnyAction, applyMiddleware, combineReducers, createStore, Store } from "redux";
import { batch, Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "@megapolis/react-auth";
import { composeWithDevTools } from "redux-devtools-extension";
import { Loader } from "components";
import { GState } from "documentations";
import { initialActions, middleWares, reducers, states } from "./consts";
import { useLoadingTransportTypeDictionary } from "./provider.hooks";

type Props = {
  reducers?: typeof reducers;
  state?: typeof states;
  middleware?: typeof middleWares;
  debug?: boolean;
};

type Oidc = { user: { access_token: string } | null };

let store: Store;
const oidcState: Oidc = { user: null };
const oidcReducer = (oidcStore = oidcState, action: AnyAction) => {
  if (action.type === initialActions.module) {
    oidcStore.user = action.payload;
  }

  return oidcStore;
};

const useStore = (args: Props): any => {
  if (store) return store;

  const { debug, state = {}, reducers = {}, middleware = [] } = args;
  state["oidc"] = oidcState;
  reducers["oidc"] = oidcReducer;
  const rootReducer = combineReducers({ ...reducers });

  const enhancers = debug ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

  store = createStore(rootReducer, state, enhancers);

  return store;
};

const updateStore = (dispatch) => (payload: Oidc) => () => {
  dispatch({ type: initialActions.module, payload });
  dispatch({ type: initialActions.project, payload });
};

export const User: React.FC = (props) => {
  const dispatch = useDispatch();
  const authContext = useAuthContext();
  const userOnStore = useSelector((state: GState) => state.oidc.user, shallowEqual);
  const [, setState] = useState(userOnStore);

  const update = useCallback(
    (payload) => {
      batch(updateStore(dispatch)(payload));
    },
    [dispatch]
  );

  if (!userOnStore) {
    update(authContext.user);
    setState(authContext.user);
    return <Loader isLarge />;
  }

  return <>{props.children}</>;
};

const useProviderValue = (debug: boolean) => ({ state: states, reducers, middleware: middleWares, debug });
const useIsDebug = () => process.env.NODE_ENV === "development";

export const OldStoreProvider: React.FC = memo((props) => {
  const { children } = props;
  const debug = useIsDebug();
  const providerValue = useProviderValue(debug);
  const store = useStore(providerValue);

  const { dispatch } = store;
  useLoadingTransportTypeDictionary(dispatch);

  return (
    <Provider store={store}>
      <User>{children}</User>
    </Provider>
  );
});
