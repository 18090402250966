import { HISTORY_SET } from "./action-types";

export const initialState = {
  items: [],
};

export const historyReducer = (state = initialState, { type, payload }) => {
  if (type === HISTORY_SET)
    return {
      ...state,
      items: payload,
    };

  return state;
};
