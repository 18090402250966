import React, { FC, memo, useLayoutEffect, useRef } from "react";
import moment from "moment";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { declesionCounts } from "utils/declension-counts";
import { preciseNumber } from "utils/precise-number";
import { Incident } from "api/router/model/analysisResponse";
import { SectorAnalysisTooltipIncidentList } from "../sector-analysis-tooltip-incident";
import "./index.scss";

const baseClass = "sector-analysis-tooltip";

interface SectorAnalysisTooltipProps {
  averageSpeed?: number;
  freeFlowSpeed?: number | null;
  vehicleCount?: number;
  type: "graph" | "diagram";
  dataPointIndex: number;
  isShowCompare?: boolean;
  timeTravel?: number;
  dateKeys: Array<string> | null;
  incidents?: Incident[];
  pos?: any;
}

export const SectorAnalysisTooltip: FC<SectorAnalysisTooltipProps> = memo((props) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const { averageSpeed, freeFlowSpeed, vehicleCount, type, dataPointIndex, timeTravel, dateKeys, incidents, pos } =
    props;

  const getTimeRange = () => {
    if (!dateKeys) return "н/д";

    let dateFrom = dateKeys[dataPointIndex];

    let dateTo = dateKeys[dataPointIndex + 1] ? dateKeys[dataPointIndex + 1] : dateKeys[0];

    if (dataPointIndex === dateKeys.length) {
      dateFrom = dateKeys[0];
      dateTo = dateKeys[1];
    }

    if (!dateFrom || !dateTo) return "н/д";

    const formatedDateFrom = moment(dateFrom).format("HH:mm");

    const formatedDateTo = moment(dateTo).format("HH:mm");

    return `${formatedDateFrom} - ${formatedDateTo}`;
  };

  const getTimeTravel = () => {
    if (typeof timeTravel !== "number") return "н/д";
    const time = Number(timeTravel / 60);
    const precisedTime = preciseNumber(time);
    if (Number(precisedTime) === 0) return "н/д";
    return `${precisedTime} мин`;
  };

  const getAverageSpeed = () => {
    if (typeof averageSpeed !== "number") return "н/д";
    const precisedTime = preciseNumber(averageSpeed);
    return `${precisedTime} км/ч`;
  };

  const getFreeFlowSpeed = () => {
    if (typeof freeFlowSpeed !== "number") return "50 км/ч*";
    const precisedTime = preciseNumber(freeFlowSpeed);
    return `${precisedTime} км/ч`;
  };

  const getVehicleCount = () => {
    if (typeof vehicleCount !== "number") return "н/д";
    const count = Number(vehicleCount).toFixed(0);
    return `${count} ${declesionCounts(count)}`;
  };

  useLayoutEffect(() => {
    const tooltip = nodeRef.current;
    if (!tooltip || !pos) return;

    const { left, top, rectangleX, rectangleY } = pos;
    const leftStyle = left + rectangleX - tooltip.offsetWidth / 2;
    const topStyle = top + rectangleY - tooltip.offsetHeight - 15;

    tooltip.style.display = "block";
    tooltip.style.position = "fixed";
    tooltip.style.left = `${leftStyle}px`;
    tooltip.style.top = `${topStyle}px`;
  }, [pos, nodeRef]);

  return (
    <div className={baseClass} ref={nodeRef}>
      <div className={`${baseClass}-header`}>
        <TimeIcon />
        {getTimeRange()}
      </div>
      {type === "graph" ? (
        <>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Средняя скорость</span>
            <span className={`${baseClass}-value`}>{getAverageSpeed()}</span>
          </div>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Время проезда</span>
            <span className={`${baseClass}-value`}>{getTimeTravel()}</span>
          </div>
        </>
      ) : (
        <>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Средняя скорость</span>
            <span className={`${baseClass}-value`}>{getAverageSpeed()}</span>
          </div>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Без пробок</span>
            <span className={`${baseClass}-value`}>{getFreeFlowSpeed()}</span>
          </div>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Среднее число ТС</span>
            <span className={`${baseClass}-value`}>{getVehicleCount()}</span>
          </div>
          <SectorAnalysisTooltipIncidentList incidents={incidents} />
        </>
      )}
    </div>
  );
});
