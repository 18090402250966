import React from "react";
import { constants } from "../store.constants";
import { Action } from "../store.types";

export const useDeleteAttribute = (id: string, dispatch: React.Dispatch<Action>) =>
  React.useCallback(
    (guid: string) =>
      dispatch({
        type: constants.DELETE,
        payload: { id, guid },
      }),
    [dispatch]
  );
