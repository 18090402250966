import { State } from "./bus-stops.types";

export const setDefaultIsVisible = (state: State): State => ({ ...state, isVisible: false });
export const handleSetEsFromRedux = (state: State, es: State["es"]): State => ({ ...state, es });
export const setNextIsVisible = (state: State): State => ({ ...state, isVisible: !state.isVisible });
export const handleSetToDateFromRedux = (state: State, to: State["to"]): State => ({ ...state, to });
export const handleSetFromDateFromRedux = (state: State, from: State["from"]): State => ({ ...state, from });
export const setIsVisible = (state: State, payload: State["isVisible"]): State => ({ ...state, isVisible: payload });
export const handleSetIsDisabled = (state: State, isDisabled: State["isDisabled"]): State => ({ ...state, isDisabled });
export const handleSetTrafficTypeFromRedux = (state: State, trafficType: State["trafficType"]): State => ({
  ...state,
  trafficType,
});
