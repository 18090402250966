import { memo } from "react";
import { ToggleButton, ToggleButtonType } from "components";
import { useHandleChange, useIsUnitsCountDiagram } from "./diagram-mode-toggle.hooks";
import "./diagram-mode-toggle.scss";

const baseClass = "diagram-mode-toggle";
const buttons: [ToggleButtonType, ToggleButtonType] = [
  {
    id: "0",
    text: "Скорость",
  },
  {
    id: "1",
    text: "Количество",
  },
];

export const DiagramModeToggle = memo(() => {
  const isUnitsCountDiagram = useIsUnitsCountDiagram();
  const handleChange = useHandleChange();

  return (
    <div className={baseClass}>
      <ToggleButton isFilling value={String(Number(isUnitsCountDiagram))} buttons={buttons} onChange={handleChange} />
    </div>
  );
});
