import { Map, GeoJSONSource, AnyLayer } from "mapbox-gl";
import { Incident } from "api/incident/incident-api-types";
import { MapLayer } from "map-helpers/new-layer/map-layer";
import { BLOCKING_TEMPORARY_MAP_IMAGE_KEY } from "map-helpers/assets/map-svg-icons";

const layout = {
  "icon-image": [
    "case",
    ["boolean", ["has", "icon-image"], false],
    ["get", "icon-image"],
    BLOCKING_TEMPORARY_MAP_IMAGE_KEY,
  ],
  "icon-anchor": ["get", "icon-anchor"],
  "icon-rotate": ["get", "icon-rotate"],
  "icon-size": MapLayer.getZoomLinearExpression(1.2),
  "icon-allow-overlap": true,
  "icon-pitch-alignment": "map",
};

export class TemporaryBlockingLayer {
  private readonly map: Map;
  public readonly layerId: string;
  private collection: GeoJSON.Feature<GeoJSON.Point, Incident>[] = [];
  private data = {
    type: "FeatureCollection" as const,
    features: [] as GeoJSON.Feature<GeoJSON.Point, Incident>[],
  };

  private get layerData() {
    return {
      id: this.layerId,
      type: "symbol" as const,
      source: this.layerId,
      layout,
    } as AnyLayer;
  }

  constructor(map: Map, layerId: string) {
    this.map = map;
    this.layerId = layerId;
    this.map.on("styleimagemissing", this.handleImageMissing);
  }

  private handleImageMissing = () => MapLayer.loadImage(this.map, BLOCKING_TEMPORARY_MAP_IMAGE_KEY);

  public add = () => {
    this.map.addSource(this.layerId, {
      type: "geojson",
      data: this.data,
    });

    this.map.addLayer(this.layerData);
  };

  public removeLayer = () => {
    if (!this.map.getLayer(this.layerId)) return;
    this.map.removeLayer(this.layerId);

    if (!this.map.getSource(this.layerId)) return;
    this.map.removeSource(this.layerId);

    this.collection = [];
  };

  public setData = (newCollection: GeoJSON.Feature<GeoJSON.Point, Incident>[]) => {
    this.collection = newCollection;
    this.data = { ...this.data, features: this.collection };
    (this.map.getSource(this.layerId) as GeoJSONSource).setData(this.data);
  };

  public setVisibility = (isVisibility: boolean) => {
    const _visibility = isVisibility ? "visible" : "none";
    const layer = this.map.getLayer(this.layerId);
    if (!layer) return;
    this.map.setLayoutProperty(this.layerId, "visibility", _visibility);
  };

  public show = () => {
    this.setVisibility(true);
  };

  public hide = () => {
    this.setVisibility(false);
  };

  public destroy = () => {
    this.removeLayer();
    this.map.off("styleimagemissing", this.handleImageMissing);
  };
}
