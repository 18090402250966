import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TypedEmitter } from "tiny-typed-emitter";
import { Grid } from "./grid";
import { SegmentsRows } from "./segments-rows";
import { Indicators } from "./indicators";
import * as utils from "./diagram-3d.utils";
import { Events, Handlers } from "./diagram-3d.events";
import { DiagramData } from "./diagram-3d.types";

const debounceDelay = 300;

export class SectorAnalysisDiagram3D extends TypedEmitter<Handlers> {
  public readonly events = Events;
  private renderer?: THREE.WebGLRenderer;
  private orbitController?: OrbitControls;
  private scene?: THREE.Scene;
  private world?: THREE.Group;
  private camera?: THREE.PerspectiveCamera;
  private sizes?: DOMRect;
  private grid = new Grid();
  private rows = new SegmentsRows();
  private data: DiagramData | null = null;
  private animationFrameId?: number;
  private indicators = new Indicators();
  private raycaster?: THREE.Raycaster;
  private hightLightMaterial = new THREE.MeshPhongMaterial({ transparent: true, color: 0x000000, opacity: 0.7 });
  private hightLightMesh: THREE.Mesh | null = null;
  private opacity = 0.7;
  private appearanceSetterTimeoutId?: number;
  private opacitySetterTimeoutId?: number;
  private unitsCountSetterTimeoutId?: number;

  constructor(private readonly canvas: HTMLCanvasElement) {
    super();
    this.prepareScene();
    this.addLighting();
    this.render();
  }

  private handleLeave = () => {
    this.emit(this.events.cursorLeave);
    if (!this.hightLightMesh || !this.scene?.getObjectById(this.hightLightMesh.id)) return;
    this.scene.remove(this.hightLightMesh);
  };

  private handleOrbitControllerChange = () => {
    if ((this.orbitController?.getDistance() ?? 0) >= 952) return this.grid.setIsVisible(false);
    this.grid.setIsVisible(true);
  };

  private handleMousemove = (event: MouseEvent) => {
    if (!this.raycaster || !this.camera || !this.scene || !this.sizes || !this.data) return;
    event.preventDefault();

    const sizes = this.canvas.parentElement?.getBoundingClientRect() ?? this.canvas.getBoundingClientRect();
    this.emit(this.events.cursorLeave);
    const mouse = new THREE.Vector2();
    const clientX = event.clientX - sizes.left;
    const clientY = event.clientY - sizes.top;
    mouse.x = (clientX / sizes.width) * 2 - 1;
    mouse.y = -(clientY / sizes.height) * 2 + 1;

    this.raycaster?.setFromCamera(mouse, this.camera);

    const intersects = this.raycaster
      .intersectObject(this.scene, true)
      .filter((intersect) => intersect.object.name === "cell");

    const nearestIntersect = intersects[0];

    if (this.hightLightMesh && this.scene.getObjectById(this.hightLightMesh.id)) {
      this.scene.remove(this.hightLightMesh);
    }

    if (!nearestIntersect) return;

    const { x, y } = nearestIntersect.point;

    const cachedCell = this.rows.cache.getCell({ x, y });
    if (!cachedCell) return;

    const boxDepth = utils.getSegmentDepth(cachedCell.cell.unitsCount);
    const hoverBoxGeometry = new THREE.BoxGeometry(
      utils.getSegmentWidth(sizes.width),
      utils.getSegmentHeight(sizes.height, this.data.rowsCount),
      boxDepth
    );

    hoverBoxGeometry.translate(cachedCell.x, cachedCell.y, boxDepth / 2);
    this.hightLightMesh = new THREE.Mesh(hoverBoxGeometry, this.hightLightMaterial);
    this.scene.add(this.hightLightMesh);
    const point = { x: event.clientX - sizes.left, y: event.clientY - sizes.top };
    this.emit(this.events.cellHover, point, cachedCell);
  };

  private addLighting = () => {
    const startX = (this.sizes?.width ?? 0) / 2;
    const startY = (this.sizes?.height ?? 0) / 2;

    const spotLightBackward = new THREE.DirectionalLight(0xffffff, 0.6);
    spotLightBackward.position.set(0, -startY, 150);

    const spotLightForward = new THREE.DirectionalLight(0xffffff, 0.6);
    spotLightForward.position.set(0, startY, 150);

    const spotLightLeft = new THREE.DirectionalLight(0xffffff, 0.6);
    spotLightLeft.position.set(-startX - 100, 0, 150);

    const spotLightRight = new THREE.DirectionalLight(0xffffff, 0.6);
    spotLightRight.position.set(startX + 100, 0, 150);

    this.scene?.add(spotLightBackward);
    this.scene?.add(spotLightForward);
    this.scene?.add(spotLightLeft);
    this.scene?.add(spotLightRight);
  };

  private prepareScene = () => {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      precision: "highp",
      antialias: true,
      alpha: true,
    });
    this.sizes = this.canvas.parentElement?.getBoundingClientRect() ?? this.canvas.getBoundingClientRect();
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.raycaster = new THREE.Raycaster();
    this.scene = new THREE.Scene();
    this.world = new THREE.Group();
    this.scene.add(this.world);
    this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100000);
    this.camera.up.set(0, 0, 1);
    this.camera.position.z = 700;
    this.orbitController = new OrbitControls(this.camera, this.renderer.domElement);

    this.orbitController.minDistance = 10;
    this.orbitController.maxDistance = 3000;
    this.orbitController.update();

    this.renderer.domElement.addEventListener("mousemove", this.handleMousemove, false);
    this.renderer.domElement.addEventListener("mouseleave", this.handleLeave, false);
    this.orbitController.addEventListener("change", this.handleOrbitControllerChange);
    this.getBackgroundArea(this.sizes.width, this.sizes.height);
  };

  private render = () => {
    if (!this.scene || !this.camera) return;
    this.animationFrameId = requestAnimationFrame(this.render);
    this.orbitController?.update();
    this.renderer?.render(this.scene, this.camera);
  };

  private getBackgroundArea = (width: number, height: number) => {
    this.data && this.setData(this.data);

    const area = this.world?.getObjectByName("backgroundArea");
    area && this.world?.remove(area);

    const planeGeometry = new THREE.BoxGeometry(width, height, 1);
    const planeMaterial = new THREE.MeshPhongMaterial({ transparent: true, opacity: 0.7, color: 0xc7c7c7 });
    const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    planeMesh.name = "backgroundArea";
    planeMesh.position.setZ(-0.5);
    this.world?.add(planeMesh);
  };

  public readonly setData = (data: DiagramData) => {
    this.data = data;
    this.grid.clear().setSizes(this.sizes).calculate(data.rows.length).addTo(this.scene);
    this.rows.clear().setOpacity(this.opacity).setSizes(this.sizes).calculate(data.rows).addTo(this.scene);
    this.indicators.clear().setSizes(this.sizes).calculate(data).addTo(this.scene);
    return this;
  };

  public readonly setOpacity = (opacity: number) => {
    if (typeof this.opacitySetterTimeoutId === "number") {
      window.clearTimeout(this.opacitySetterTimeoutId);
    }

    this.opacitySetterTimeoutId = window.setTimeout(() => {
      this.opacity = opacity;
      this.rows.setOpacity(opacity);
    }, debounceDelay);

    return this;
  };

  public readonly setAppearance = (appearance?: DiagramAppearance | null) => {
    if (!appearance) return this;
    if (typeof this.appearanceSetterTimeoutId === "number") {
      window.clearTimeout(this.appearanceSetterTimeoutId);
    }

    this.appearanceSetterTimeoutId = window.setTimeout(() => {
      this.rows.setAppearance(appearance);
      if (!this.data) return;
      this.rows.clear().setOpacity(this.opacity).setSizes(this.sizes).calculate(this.data.rows).addTo(this.scene);
    }, debounceDelay);

    return this;
  };

  public readonly setUnitsCountFilter = (unitsCountFilter: [number, number] | null) => {
    if (typeof this.unitsCountSetterTimeoutId === "number") {
      window.clearTimeout(this.unitsCountSetterTimeoutId);
    }

    this.unitsCountSetterTimeoutId = window.setTimeout(() => {
      this.rows.setUnitsCountFilter(unitsCountFilter);
      if (!this.data) return;
      this.rows.clear().setOpacity(this.opacity).setSizes(this.sizes).calculate(this.data.rows).addTo(this.scene);
    }, debounceDelay);

    return this;
  };

  public readonly setVisibility = (isVisible: boolean) => {
    if (!isVisible && this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.grid.clear();
      this.rows.clear();
      return this.scene?.clear();
    }
    this.render();

    if (!this.data) return;
    this.setData(this.data);
  };

  public readonly resize = () => {
    this.sizes = this.canvas.parentElement?.getBoundingClientRect() ?? this.canvas.getBoundingClientRect();
    this.renderer?.setSize(this.sizes.width, this.sizes.height);
    this.getBackgroundArea(this.sizes.width, this.sizes.height);
  };

  public readonly destroy = () => {
    this.removeAllListeners(this.events.cellHover);

    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    this.grid.clear().removeFrom(this.scene);
    this.rows.clear().removeFrom(this.scene);

    while (this.scene?.children.length) {
      const child = this.scene?.children[this.scene?.children.length - 1];
      if (child) this.scene.remove(child);
    }

    this.renderer?.domElement.removeEventListener("mousemove", this.handleMousemove, false);
    this.renderer?.domElement.removeEventListener("mouseleave", this.handleLeave, false);
    this.scene?.clear();
    this.renderer?.clear();
    this.renderer?.dispose();
    this.scene = undefined;
    this.renderer = undefined;
  };
}
