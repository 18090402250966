import { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shared } from "shared";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "features/sector-analysis/store";
import { UnitsCountDiagramParams, UnitsCountDiagramParamsLimits } from "../../../../types";
import * as utils from "./units-count-appearance-slider.utils";
import * as consts from "./units-count-appearance-slider.consts";

export const useIsUnitsCountDiagram = () => useSelector((state: GState) => state.sectorAnalysis.isUnitsCountDiagram);
export const useUnitsCountDiagramParams = () => {
  return useSelector((state: GState) => state.sectorAnalysis.unitsCountDiagramParams);
};

export const useSliderCount = () => {
  return useMemo(() => Object.values(shared.unitsCountDiagram.unitsCountColor).length - 1, []);
};

export const useTrackStyles = () => {
  return useMemo(
    () =>
      Object.values(shared.unitsCountDiagram.unitsCountColor)
        .filter(utils.excludeDisabledFilterPredicate)
        .map((color) => ({
          backgroundColor: color || "#000000",
        })),
    []
  );
};

export const useHandleStyles = () => {
  return useMemo(
    () =>
      Object.values(shared.unitsCountDiagram.unitsCountColor)
        .filter(utils.excludeDisabledFilterPredicate)
        .map((color) => ({
          ...consts.handleStyle,
          backgroundColor: color || "#000000",
        })),
    []
  );
};

export const useValues = (params: UnitsCountDiagramParams | null) => {
  const [values, setValues] = useState(utils.getValues(params));
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (newValues: number[]) => {
      newValues[0] = values[0] ?? 0;
      newValues[newValues.length - 1] = newValues[newValues.length - 1];

      const deltasArray = [...newValues];
      deltasArray.shift();
      deltasArray.pop();

      const newLimits = deltasArray.reduce((acc, limit, index) => {
        return { ...acc, [index + 1]: limit };
      }, {} as UnitsCountDiagramParamsLimits);

      setValues(newValues);
      dispatch(sectorAnalysisSlice.actions.setUnitsCountDiagramLimits(newLimits));
    },
    [values, params, dispatch]
  );

  return {
    values,
    handleChange,
  };
};

export const useLabels = (values: number[]) => {
  const max = Math.max(...values);
  const delta = isNaN(max) ? 1 / consts.intersectionDelta : max / consts.intersectionDelta;

  return useMemo(
    () =>
      values.reduce((acc, value, index) => {
        const emptyLabel = { ...acc };
        const defaultLabel = { ...acc, [value]: <div className={consts.labelFontClass}>{value} ед.</div> };

        if (index === 0 || index === values.length - 1) return defaultLabel;
        if (Math.abs(values[0] - value) <= delta) return emptyLabel;
        if (Math.abs(values[index + 1] - value) <= delta) return emptyLabel;

        return defaultLabel;
      }, {}),
    [values]
  );
};
