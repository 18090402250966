import getBeforeId from "../order-layers";
import Layer from "./layer";

export default class RoutePointLayer extends Layer {
  addLayer() {
    const pointLayer = {
      id: this.layerId,
      type: "symbol",
      source: this.layerId,
      layout: {
        "icon-image": this.layerId,
        "icon-allow-overlap": true,
        "icon-size": ["interpolate", ["exponential", 1.5], ["zoom"], 6, 0.6, 12, 0.8, 18, 1.2],
        "icon-anchor": "bottom",
        "icon-ignore-placement": true,
        "text-ignore-placement": true,
      },
    };

    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(pointLayer, beforeId);
  }

  addSource() {
    if (this.data)
      this.map.addSource(this.layerId, {
        type: "geojson",
        data: this.data,
      });
  }
}
