import React from "react";
import cn from "classnames";
import { ReactComponent as DynamicsDisabled } from "assets/icons/traffic-index/dynamics-disabled.svg";
import { ReactComponent as DynamicsSame } from "assets/icons/traffic-index/dynamics-same.svg";
import { ReactComponent as DynamicsIncreasing } from "assets/icons/traffic-index/dynamics-increasing.svg";
import { ReactComponent as DynamicsIncreasingSlow } from "assets/icons/traffic-index/dynamics-increasing-slow.svg";
import { ReactComponent as DynamicsDecreasing } from "assets/icons/traffic-index/dynamics-decreasing.svg";
import { ReactComponent as DynamicsDecreasingSlow } from "assets/icons/traffic-index/dynamics-decreasing-slow.svg";

const getIcon = (dynamicsID: any) => {
  if (!Number.isInteger(dynamicsID)) return <DynamicsDisabled />;

  if (dynamicsID === 0) return <DynamicsSame />;
  if (dynamicsID === 1) return <DynamicsIncreasingSlow />;
  if (dynamicsID === 2) return <DynamicsIncreasing />;
  if (dynamicsID === 3) return <DynamicsDecreasingSlow />;
  if (dynamicsID === 4) return <DynamicsDecreasing />;
  if (dynamicsID === 5) return <DynamicsSame />;

  return <DynamicsDisabled />;
};

export const IDZPrediction = ({ dynamicsID, dynamicsMessage, customClass }: any) => {
  return (
    <div
      className={cn("ctrl-idz__prediction", customClass!)}
      title={Boolean(dynamicsMessage) ? dynamicsMessage : "Н/д"}>
      {getIcon(dynamicsID)}
    </div>
  );
};
