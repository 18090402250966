import React, { Component } from "react";
import PropTypes from "prop-types";
import { getLengthData } from "./get-length-data";
import { getTimeData } from "./get-time-data";
import "./route-traffic-distribution.scss";

const className = "route-traffic-distribution";

class RouteTrafficDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { variant, width, noPoint, type } = this.props;
    const substrateWidth = {
      width: `${width * 100}%`,
    };
    let data = [];

    if (type === "length") {
      data = getLengthData(variant);
    } else if (type === "time") {
      data = getTimeData(variant);
    }

    return (
      <div className={className}>
        <div className={`${className}-substrate`}>
          <div style={substrateWidth}>
            <div className={`${className}-distribute`}>
              {!noPoint && <div className={`${className}-start-point ${className}-point`} />}
              {!noPoint && <div className={`${className}-end-point ${className}-point`} />}
              {data.map((el, index) => {
                return <div className={`${className}-part`} style={el} key={index} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RouteTrafficDistribution.propTypes = {
  width: PropTypes.number.isRequired,
  noPoint: PropTypes.bool,
};

export default RouteTrafficDistribution;
