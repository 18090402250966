import { APP_ENV } from "app-env";
import { NavigationGroupeLayers } from "components";

type ButtonProps = Pick<
  React.ComponentProps<typeof NavigationGroupeLayers.Item>,
  "iconNormalUrl" | "iconActiveUrl" | "title"
>;

export const buttonProps: ButtonProps = {
  iconNormalUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/roads-graph_normal.svg`,
  iconActiveUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/roads-graph_active.svg`,
  title: APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.transportGraph?.caption ?? "Граф дорог",
};
