import { createContext, useContext, useEffect, useState } from "react";
import * as Cookie from "js-cookie";
import { TypedEmitter } from "tiny-typed-emitter";
import { prepareDiagramAppearance, ValuesTuple } from "./utils";
import defaultSettings from "./default-settings.json";

interface SettingSpeedColorEvents {
  update: (appearance: DiagramAppearance) => void;
}

class SettingSpeedColor extends TypedEmitter<SettingSpeedColorEvents> {
  private _appearance: DiagramAppearance;
  private readonly optCookies = {
    expires: 365,
  };
  public readonly events = {
    update: "update" as const,
  };

  constructor() {
    super();
    this._appearance = Cookie.getJSON("diagramAppearance") as DiagramAppearance;
    if (!this._appearance) this.save(defaultSettings.diagramAppearance);
  }

  private emitUpdate = (appearance: DiagramAppearance) => {
    this.emit(this.events.update, appearance);
  };

  public readonly save = (appearance: DiagramAppearance) => {
    Cookie.set("diagramAppearance", appearance, this.optCookies);
    return appearance;
  };

  public readonly update = (value?: ValuesTuple) => {
    if (value) {
      this._appearance = prepareDiagramAppearance(value);
    }
    this.save(this._appearance);
    this.emitUpdate(this._appearance);
  };

  public readonly reset = () => {
    this._appearance = defaultSettings.diagramAppearance;
    this.save(this._appearance);
    this.emitUpdate(this._appearance);
    return this._appearance;
  };

  public get appearance() {
    return this._appearance;
  }
}

const settingSpeedColor = new SettingSpeedColor();

export const SectorAnalyseContext = createContext({
  settingSpeedColor,
});

export const useGetAppearance = () => {
  const { settingSpeedColor } = useSectorAnalyseContext();
  const [appearance, setAppearance] = useState(settingSpeedColor.appearance);

  useEffect(() => {
    settingSpeedColor.on(settingSpeedColor.events.update, setAppearance);

    return () => {
      settingSpeedColor.off(settingSpeedColor.events.update, setAppearance);
    };
  }, [settingSpeedColor]);

  return appearance;
};

export const useSectorAnalyseContext = () => useContext(SectorAnalyseContext);

export const valueSectorAnalysis = {
  settingSpeedColor,
};
