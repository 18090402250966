import mapboxgl from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import { PolygonTool, PolygonFigure, AbstractCollection } from "../../utils";
import { Events, Handlers } from "./zone-statistics.events";

export class ZoneStatistics extends TypedEmitter<Handlers> {
  public readonly events = Events;
  private polygonTool?: PolygonTool;
  private isActive = false;

  constructor(private readonly map: mapboxgl.Map) {
    super();
  }

  private handlePolygonChange = (collection: AbstractCollection<PolygonFigure>) => {
    const feature = Object.values(collection).pop();

    if (!feature) return;

    this.emit(this.events.polygonChange, feature);
  };

  public readonly setIsActive = (value: ZoneStatistics["isActive"]) => {
    this.isActive = value;

    if (!this.isActive && this.polygonTool) {
      this.polygonTool.setData({});
      this.polygonTool.isEditable = this.isActive;
      this.polygonTool.isVisible = this.isActive;
      this.polygonTool.destroy();
      return;
    }

    this.polygonTool = new PolygonTool({
      map: this.map,
      // @ts-ignore
      onChange: this.handlePolygonChange,
    });

    this.polygonTool.isEditable = this.isActive;
    this.polygonTool.isVisible = this.isActive;
    this.polygonTool.create();
  };

  public readonly destroy = () => {
    this.polygonTool?.destroy();
  };
}
