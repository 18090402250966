import React, { memo } from "react";
import { SingleButton, BUTTON_FORM, IconSVGName } from "components";
import { useStoreContext } from "store";

export const TravelHeatmap = memo(() => {
  const { store } = useStoreContext();
  const isActive = store.travelHeatmap.hooks.useIsActive();
  const isDisabled = store.travelHeatmap.hooks.useIsDisabled();

  return (
    <div className="ctrl-points">
      <SingleButton
        buttonGroupItem
        isActive={isActive}
        disabled={isDisabled}
        type={BUTTON_FORM.round}
        title="Аналитики такси и каршеринга"
        iconSvg={IconSVGName.travel_heatmap}
        onClick={store.travelHeatmap.events.handleTravelHeatmapClick}
      />
    </div>
  );
});
