import { memo, useCallback } from "react";
import DatePicker, { ITYPES, ISHOWS } from "@megapolis/datepicker-dev";
import { TimeSlider } from "components";
import { useStoreContext } from "store";
import { Title } from "../title";
import "./filter.scss";

const baseClass = "zone-statistics-card-filter";

export const Filter = memo(() => {
  const { store } = useStoreContext();
  const dateRange = store.zoneStatistics.hooks.useDateRange();
  const timeRange = store.zoneStatistics.hooks.useTimeRange();

  const handleDateRangeChange = useCallback((newFrom: string | null, newTo: string | null) => {
    if (!newFrom || !newTo) return;

    store.zoneStatistics.events.setDateRange({
      from: newFrom,
      to: newTo,
    });
  }, []);

  return (
    <div className={baseClass}>
      <Title>Период</Title>
      <DatePicker
        type={ITYPES.PERIOD}
        show={ISHOWS.DAYS}
        from={dateRange.from}
        to={dateRange.to}
        format="DD.MM.YYYY"
        onChange={handleDateRangeChange}
        closeAfterChange
      />
      <TimeSlider value={timeRange} onChange={store.zoneStatistics.events.setTimeRange} />
    </div>
  );
});
