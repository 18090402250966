import { memo } from "react";
import { CloseButton } from "components";
import { useStoreContext } from "store";
import "./header.scss";

const baseClass = "zone-statistics-card-header";
const headingClass = `${baseClass}__heading`;

export const Header = memo(() => {
  const { store } = useStoreContext();

  return (
    <div className={baseClass}>
      <span className={headingClass}>Транспортная статистика в выбранной области</span>
      <CloseButton onClick={store.zoneStatistics.events.handleZoneStatisticsCloseClick} />
    </div>
  );
});
