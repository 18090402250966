import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as observer from "observer";
import { DayOfWeek } from "api/router/model/models";
import { GState } from "../../../../documentations";
import { ChangeDateProps } from "features/sector-analysis/types";
import { sectorAnalysisSlice } from "../../store/sector-analysis-slice";

export const useObserverSubs = () => {
  const callbacks = useCallbacks();

  useEffect(() => {
    observer.on(observer.EVENTS.ON_IS_ZOOM_TOOLTIP_CHANGE, callbacks.changeIsZoomTooltip);

    return () => {
      observer.off(observer.EVENTS.ON_IS_ZOOM_TOOLTIP_CHANGE, callbacks.changeIsZoomTooltip);
    };
  }, [callbacks.changeIsZoomTooltip]);
};

export const useCallbacks = () => {
  const dispatch = useDispatch();
  const { isShowEvents, isShowCompare } = useStoreProps();

  const changeIsZoomTooltip = useCallback(
    (value: boolean) => {
      dispatch(sectorAnalysisSlice.actions.setIsZoomTooltip(value));
    },
    [dispatch]
  );

  const changeCurrentDate = useCallback(
    (value: ChangeDateProps) => {
      dispatch(sectorAnalysisSlice.actions.setCurrentRangeDate(value));
    },
    [dispatch]
  );

  const changeCompareDate = useCallback(
    (value: ChangeDateProps) => {
      dispatch(sectorAnalysisSlice.actions.setCompareRangeDate(value));
    },
    [dispatch]
  );

  const changeCurrentEs = useCallback(
    (value: Array<number>) => {
      dispatch(sectorAnalysisSlice.actions.setCurrentEs(value));
    },
    [dispatch]
  );

  const changeCompareEs = useCallback(
    (value: Array<number>) => {
      dispatch(sectorAnalysisSlice.actions.setCompareEs(value));
    },
    [dispatch]
  );

  const changeCurrentDays = useCallback(
    (value: Array<DayOfWeek>) => {
      dispatch(sectorAnalysisSlice.actions.setCurrentDays(value));
    },
    [dispatch]
  );

  const changeCompareDays = useCallback(
    (value: Array<DayOfWeek>) => {
      dispatch(sectorAnalysisSlice.actions.setCompareDays(value));
    },
    [dispatch]
  );

  const changeIsShowEvent = useCallback(() => {
    dispatch(sectorAnalysisSlice.actions.setIsShowEvents(!isShowEvents));
  }, [dispatch, isShowEvents]);

  const changeIsShowCompare = useCallback(() => {
    dispatch(sectorAnalysisSlice.actions.setIsShowCompare(!isShowCompare));
  }, [dispatch, isShowCompare]);

  return {
    changeCurrentEs,
    changeCompareEs,
    changeIsShowEvent,
    changeCurrentDate,
    changeCompareDate,
    changeCurrentDays,
    changeCompareDays,
    changeIsZoomTooltip,
    changeIsShowCompare,
  };
};

/**
 * @hook allows to get needed for component props from store
 */
export const useStoreProps = () =>
  useSelector(
    (state: GState) => ({
      period: state.sectorAnalysis.periodM,
      current: state.sectorAnalysis.current,
      compare: state.sectorAnalysis.compare,
      isActive: state.sectorAnalysis.isActive,
      dateKeys: state.sectorAnalysis.dateKeys,
      routeVariants: state.router.routeVariants,
      routeIsLoading: state.router.routeIsLoading,
      graphRange: state.sectorAnalysis.graphRange,
      isShowEvents: state.sectorAnalysis.isShowEvents,
      isShowCompare: state.sectorAnalysis.isShowCompare,
      isDisabled: state.router.isDisabled,
    }),
    shallowEqual
  );
