import { h3ToGeoBoundary } from "h3-js";
import { TravelHeatmapTypes } from "types";
import { FeatureCollection } from "./travel-heatmap.types";

export class H3IndexesAggregator {
  public static toFeatureCollection(indexes: TravelHeatmapTypes.H3IndexesFeatureCollection): FeatureCollection {
    const featureCollection = {
      type: "FeatureCollection",
      features: [],
    } as FeatureCollection;

    let i = 0;
    for (i; i < indexes.length; i++) {
      const { h3Index, properties } = indexes[i];
      featureCollection.features.push({
        type: "Feature",
        properties,
        geometry: {
          type: "Polygon",
          coordinates: [h3ToGeoBoundary(h3Index, true).reverse()],
        },
      });
    }

    return featureCollection;
  }
}
