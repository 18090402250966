import React from "react";
import { createEvent } from "effector";
import { State } from "./application-settings.types";

/** События UI */
const handleOutsideClick = createEvent();
const handleActiveMapStyleChange = createEvent<string | number>();
const handleTelematicsIsEmptyPieces = createEvent<React.SyntheticEvent<HTMLInputElement>>();
const handleApplicationSettingsButtonClick = createEvent<React.MouseEventHandler<HTMLButtonElement>>();

/** Доставщики информации в брокер */
const isEmptyPiecesDeliveryman = createEvent<State>();
const activeMapStyleDeliveryman = createEvent<State>();

export const events = {
  /** События UI */
  handleOutsideClick,
  handleActiveMapStyleChange,
  handleTelematicsIsEmptyPieces,
  handleApplicationSettingsButtonClick,
  /** Доставщики информации в брокер */
  isEmptyPiecesDeliveryman,
  activeMapStyleDeliveryman,
};

export type Events = typeof events;
