import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Range } from "rc-slider";
import { AnalysisTotal } from "api/router/model/analysisResponse";
import * as diagramConsts from "../../../sector-analysis-diagram/analysis-diagram/utils/consts";
import { defaultSettings, prepareDiagramAppearance, ValuesTuple } from "../../../sector-analysis";
import { consts, utils, hooks, getOffsetX, getPercentageLabel, getSpeedLabel, speedLabelStyles } from "../../utils";
import { SliderLabel } from "./slider-label";

import "./sector-analysis-appearance-slider.scss";

const baseClass = "sector-analysis-appearance-slider";

const useSliderCount = () => {
  return defaultSettings.diagramAppearance.length;
};

type SliderProps = {
  appearance?: DiagramAppearance;
  total: AnalysisTotal | null;
  handleRangeChange: (value: number[]) => void;
};

const handleStyle: CSSProperties = {
  width: "20px",
  height: "20px",
  border: "4px solid #ffffff",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.161)",
};

type StopperProps = {
  offsetX: number;
  offsetY: number;
};

export const Stopper: React.FC<StopperProps> = ({ offsetX, offsetY }) => {
  return <div className={`${baseClass}__stopper`} style={{ top: `${offsetY}%`, left: `${offsetX}%` }} />;
};

const convertAppearanceToSliderModel = (appearance: DiagramAppearance | undefined): number[] => {
  const defaultValues = utils.getDefaultValue();
  if (!appearance) return defaultValues;
  return appearance.map((item) => item.value);
};

const isInImposition = (labelsOffset: number[], offset: number) => {
  return Math.min(...labelsOffset.map((item) => Math.abs(item - offset))) < 5.5;
};

export const SectorAnalysisAppearanceSlider: React.FC<SliderProps> = ({ total, appearance, handleRangeChange }) => {
  const colorsCount = useSliderCount();
  const values = convertAppearanceToSliderModel(appearance);
  const [model, setModel] = useState(prepareDiagramAppearance(values as ValuesTuple));
  const percentageLabels = hooks.usePercentageLabels(model);
  const freeSpeed = Math.round(total?.freeFlowSpeed || 0);
  const maxSpeed = Math.round((freeSpeed * 150) / 100);
  const speedLabels = hooks.useSpeedLabels(model, maxSpeed || 0);
  const trackStyles = defaultSettings.diagramAppearance.map(({ key }) => ({
    backgroundColor: diagramConsts.trafficColors[key] || "#000000",
  }));
  const handleStyles = consts.defaultHandlesValue.map(({ key }) => ({
    ...handleStyle,
    backgroundColor: diagramConsts.trafficColors[key] || "#000000",
  }));
  const isHundredLabelInImposition = isInImposition(percentageLabels.offsets, getOffsetX(100));
  const isZeroLabelInImposition = isInImposition(percentageLabels.offsets, 4);
  const isMaxLabelInImposition = isInImposition(percentageLabels.offsets, 98.5);

  useEffect(() => {
    setModel(prepareDiagramAppearance(convertAppearanceToSliderModel(appearance) as ValuesTuple));
  }, [appearance]);

  const handleChange = useCallback(
    (newValues: number[]) => {
      newValues[0] = 0;
      newValues[newValues.length - 1] = 150;
      handleRangeChange(newValues);
    },
    [handleRangeChange]
  );

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__labels ${baseClass}__labels_speed`}>
        {!isZeroLabelInImposition && (
          <SliderLabel labelStyle={speedLabelStyles} title={getSpeedLabel(0)} offsetX={2.5} />
        )}
        {!isHundredLabelInImposition && (
          <SliderLabel labelStyle={speedLabelStyles} title={getSpeedLabel(freeSpeed)} offsetX={getOffsetX(100)} />
        )}
        {!isMaxLabelInImposition && (
          <SliderLabel labelStyle={speedLabelStyles} title={getSpeedLabel(maxSpeed)} offsetX={98.5} />
        )}
        {speedLabels}
      </div>
      <Range
        pushable
        max={150}
        allowCross
        count={colorsCount}
        trackStyle={trackStyles}
        defaultValue={values}
        value={values}
        handleStyle={handleStyles}
        onChange={handleChange}
      />
      <div className={`${baseClass}__labels`}>
        {!isZeroLabelInImposition && (
          <SliderLabel labelStyle={speedLabelStyles} title={getPercentageLabel(0)} offsetX={1.5} />
        )}
        {!isHundredLabelInImposition && (
          <SliderLabel labelStyle={speedLabelStyles} title={getPercentageLabel(100)} offsetX={getOffsetX(100)} />
        )}
        {!isMaxLabelInImposition && (
          <SliderLabel labelStyle={speedLabelStyles} title={getPercentageLabel(150)} offsetX={98.5} />
        )}
        {percentageLabels.labels}
      </div>
    </div>
  );
};
