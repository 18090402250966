import { memo, useCallback } from "react";
import DatePicker, { ITYPES, ISHOWS } from "@megapolis/datepicker-dev";
import { Checkbox, ToggleButton, ToggleButtonType, TimeSlider, Loader, WeekdaySelector } from "components";
import { useStoreContext } from "store";
import { shared } from "shared";
import { ReactComponent as SectorExportIcon } from "assets/icons/sector-export.svg";
import { ReactComponent as SectorImportIcon } from "assets/icons/sector-import.svg";
import { ReactComponent as TaxiIcon } from "assets/icons/taxi.svg";
import { ReactComponent as CarsharingIcon } from "assets/icons/carsharing.svg";
import { TravelHeatmapTypes } from "types";

import "./travel-heatmap-settings.scss";

const directionModeButtons: [ToggleButtonType, ToggleButtonType] = [
  {
    id: TravelHeatmapTypes.DirectionModeEnum.from,
    icon: <SectorExportIcon />,
    text: "Началось",
  },
  {
    id: TravelHeatmapTypes.DirectionModeEnum.in,
    icon: <SectorImportIcon />,
    text: "Закончилось",
  },
];

const transportTypeButtons: [ToggleButtonType, ToggleButtonType] = [
  {
    id: TravelHeatmapTypes.TransportTypeEnum.taxi,
    icon: <TaxiIcon />,
    text: "Такси",
  },
  {
    id: TravelHeatmapTypes.TransportTypeEnum.carsharing,
    icon: <CarsharingIcon />,
    text: "Каршеринг",
  },
];

const baseClass = "travel-heatmap-settings";
const loaderClass = `${baseClass}__loader`;

export const TravelHeatmapSettings = memo(() => {
  const { store } = useStoreContext();
  const isActive = store.travelHeatmap.hooks.useIsActive();
  const from = store.travelHeatmap.hooks.useFrom();
  const to = store.travelHeatmap.hooks.useTo();
  const directionMode = store.travelHeatmap.hooks.useDirectionMode();
  const transportType = store.travelHeatmap.hooks.useTransportType();
  const isLoading = store.travelHeatmap.hooks.useIsLoading();
  const isCorrespondenceMatrix = store.travelHeatmap.hooks.useIsCorrespondenceMatrix();
  const weekdays = store.travelHeatmap.hooks.useWeekdays();
  const timeRange = store.travelHeatmap.hooks.useTimeRange();
  const isCorrespondenceDisabled = store.travelHeatmap.hooks.useIsCorrespondenceDisabled();
  const handleDatePeriodChange = useCallback(
    (from: string | null, to: string | null) => {
      if (!from || !to) return;
      store.travelHeatmap.events.handlePeriodChange({ from, to });
    },
    [store.travelHeatmap.events]
  );

  const handleDirectionModeChange = useCallback(
    (id: string) => {
      const option = shared.travelHeatmap.directionModeOptions.find(({ value }) => value === id);
      if (!option) return;
      store.travelHeatmap.events.handleDirectionModeChange(option);
    },
    [store.travelHeatmap.events]
  );

  const handleTransportTypeChange = useCallback(
    (id: string) => {
      const option = shared.travelHeatmap.transportTypesOptions.find(({ value }) => value === id);
      if (!option) return;
      store.travelHeatmap.events.handleTransportTypeChange(option);
    },
    [store.travelHeatmap.events]
  );

  if (!isActive) return null;

  return (
    <div className={baseClass}>
      <DatePicker
        type={ITYPES.PERIOD}
        show={ISHOWS.DAYS}
        from={from}
        to={to}
        format="DD.MM.YYYY"
        onChange={handleDatePeriodChange}
        closeAfterChange
      />
      <WeekdaySelector selected={weekdays} onChange={store.travelHeatmap.events.setWeekdays} />
      <TimeSlider value={timeRange} onChange={store.travelHeatmap.events.handleTimeRangeChange} />
      <ToggleButton value={directionMode.value} buttons={directionModeButtons} onChange={handleDirectionModeChange} />
      <ToggleButton value={transportType.value} buttons={transportTypeButtons} onChange={handleTransportTypeChange} />
      <Checkbox
        disabled={isCorrespondenceDisabled}
        label="Матрица корреспонденций"
        value="Матрица корреспонденций"
        onChange={store.travelHeatmap.events.handleIsCorrespondenceMatrixChange}
        checked={isCorrespondenceMatrix}
      />
      {isLoading && (
        <div className={loaderClass}>
          <Loader isLarge />
        </div>
      )}
    </div>
  );
});
