import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import * as Cookie from "js-cookie";
import { DayOfWeek } from "api/router/model/models";
import { AnalysisResponse } from "api/router/model/analysisResponse";
import {
  AnalysisGraphRange,
  AnalysisType,
  ChangeDateProps,
  SectorAnalysisState,
  UnitsCountDiagramParamsLimits,
} from "../types";
import { dateKeys15plus10, dateKeys15plus3, getInitialAnalysisData } from "./sector-analysis-utils";
import { APP_ENV } from "app-env";

moment.locale("ru-RU");

const expires = 365;
const defaultIsShowLegend = Cookie.getJSON("isShowLegend");
const defaultIsShowAverageSpeed = Cookie.getJSON("isShowAverageSpeed");
const defaultIsShowAverageTime = Cookie.getJSON("isShowAverageTime");
const defaultIsShowTrafficScore = Cookie.getJSON("isShowTrafficScore");
const defaultIsShowGlobalTrafficScore = Cookie.getJSON("isShowGlobalTrafficScore");
const defaultIsShowTrafficIndex = Cookie.getJSON("isShowTrafficIndex");
const defaultIsShowGlobalTrafficIndex = Cookie.getJSON("isShowGlobalTrafficIndex");
const defaultIsUnitsCountGraph = Cookie.getJSON("isUnitsCountGraph");

const initDateKeys =
  APP_ENV.REACT_APP_DTM_REGION.toLowerCase() === "Владивосток".toLowerCase() ? dateKeys15plus10 : dateKeys15plus3;

export const initialState: SectorAnalysisState = {
  isActive: false,
  isDisabled: true,
  isActiveHeatmap: false,
  isShowEvents: false,
  isShowCompare: false,
  graphRange: null,
  dateKeys: initDateKeys,
  periodM: 15,
  current: getInitialAnalysisData(AnalysisType.current),
  compare: getInitialAnalysisData(AnalysisType.compare),
  isZoomTooltip: false,
  isAppearanceMenu: false,
  isShowLegend: defaultIsShowLegend ?? true,
  isFiltersDisabled: false,
  isDiagramDisabled: false,
  isShowAverageSpeed: defaultIsShowAverageSpeed ?? true,
  isShowAverageTime: defaultIsShowAverageTime ?? true,
  isShowTrafficScore: defaultIsShowTrafficScore ?? false,
  isShowGlobalTrafficScore: defaultIsShowGlobalTrafficScore ?? false,
  isShowTrafficIndex: defaultIsShowTrafficIndex ?? false,
  isShowGlobalTrafficIndex: defaultIsShowGlobalTrafficIndex ?? false,
  isUnitsCountDiagram: false,
  unitsCountDiagramParams: null,
  isUnitsCountGraph: defaultIsUnitsCountGraph ?? false,
  isVolumetric: false,
  isVolumetricFullscreen: false,
  segmentsOpacity: 0.7,
  minMaxUnitsCount3DFilter: [0, 0],
};

export const sectorAnalysisSlice = createSlice({
  name: "sectorAnalysis",
  initialState,
  reducers: {
    setIsZoomTooltip(state, { payload }: PayloadAction<boolean>) {
      state.isZoomTooltip = payload;
    },
    setIsActive(state, { payload }: PayloadAction<boolean>) {
      if (!payload) {
        state.current = initialState.current;
        state.compare = initialState.compare;
        state.isUnitsCountDiagram = false;
      }

      state.isActive = payload;
      state.isShowCompare = false;
      state.isShowEvents = false;
    },
    setSegmentsOpacity(state, { payload }: PayloadAction<SectorAnalysisState["segmentsOpacity"]>) {
      state.segmentsOpacity = payload;
    },
    setIsVolumetric(state, { payload }: PayloadAction<SectorAnalysisState["isVolumetric"]>) {
      state.isVolumetric = payload;
      if (!payload) {
        state.isVolumetricFullscreen = false;
      }
    },
    setIsVolumetricFullscreen(state, { payload }: PayloadAction<SectorAnalysisState["isVolumetricFullscreen"]>) {
      state.isVolumetricFullscreen = payload;
    },
    setMinMaxUnitsCount3DFilter(state, { payload }: PayloadAction<SectorAnalysisState["minMaxUnitsCount3DFilter"]>) {
      state.minMaxUnitsCount3DFilter = payload;
    },
    setUnitsCountDiagramParams(state, { payload }: PayloadAction<SectorAnalysisState["unitsCountDiagramParams"]>) {
      state.unitsCountDiagramParams = payload;
    },
    setUnitsCountDiagramLimits(state, { payload }: PayloadAction<UnitsCountDiagramParamsLimits>) {
      if (state.unitsCountDiagramParams) {
        state.unitsCountDiagramParams.limits = payload;
      }
    },
    setIsUnitsCountDiagram(state, { payload }: PayloadAction<boolean>) {
      state.isUnitsCountDiagram = payload;
    },
    setIsDisabled(state, { payload }: PayloadAction<boolean>) {
      state.isDisabled = payload;
    },
    changeIsActiveHeatmap(state) {
      state.isActiveHeatmap = !state.isActiveHeatmap;
    },
    setIsShowEvents(state, { payload }: PayloadAction<boolean>) {
      state.isShowEvents = payload;
    },
    setIsShowCompare(state, { payload }: PayloadAction<boolean>) {
      state.isShowCompare = payload;
    },
    setIsShowLegend(state, { payload }: PayloadAction<boolean>) {
      state.isShowLegend = payload;
      Cookie.set("isShowLegend", String(payload), { expires });
    },
    setIsUnitsCountGraph(state, { payload }: PayloadAction<boolean>) {
      state.isUnitsCountGraph = payload;
      Cookie.set("isUnitsCountGraph", String(payload), { expires });
    },
    setIsFiltersDisabled(state, { payload }: PayloadAction<boolean>) {
      state.isFiltersDisabled = payload;
    },
    setIsDiagramDisabled(state, { payload }: PayloadAction<boolean>) {
      state.isDiagramDisabled = payload;
    },
    setCurrentRangeDate(state, { payload }: PayloadAction<ChangeDateProps>) {
      state.current.dateFrom = payload.dateFrom;
      state.current.dateTo = payload.dateTo;

      if (!state.isShowCompare) {
        state.compare.dateFrom = payload.dateFrom;
        state.compare.dateTo = payload.dateTo;
      }
    },
    setCompareRangeDate(state, { payload }: PayloadAction<ChangeDateProps>) {
      state.compare.dateFrom = payload.dateFrom;
      state.compare.dateTo = payload.dateTo;
    },
    setCurrentEs(state, { payload }: PayloadAction<Array<number>>) {
      state.current.es = payload;

      if (!state.isShowCompare) state.compare.es = payload;
    },
    setCompareEs(state, { payload }: PayloadAction<Array<number>>) {
      state.compare.es = payload;
    },
    setCurrentisLoading(state, { payload }: PayloadAction<boolean>) {
      state.current.isLoading = payload;
    },
    setCompareisLoading(state, { payload }: PayloadAction<boolean>) {
      state.compare.isLoading = payload;
    },
    setCurrentDays(state, { payload }: PayloadAction<Array<DayOfWeek>>) {
      state.current.days = payload;

      if (!state.isShowCompare) state.compare.days = payload;
    },
    setCompareDays(state, { payload }: PayloadAction<Array<DayOfWeek>>) {
      state.compare.days = payload;
    },
    setCurrentData(state, { payload }: PayloadAction<AnalysisResponse>) {
      state.minMaxUnitsCount3DFilter = [payload.diagram.minUnitsPerCell ?? 0, payload.diagram.maxUnitsPerCell ?? 0];
      state.current.diagram = payload.diagram;
      state.current.graph = payload.graph;
      state.current.total = payload.total;
    },
    setIsAppearanceMenu(state, { payload }: PayloadAction<boolean>) {
      state.isAppearanceMenu = payload;
    },
    setDefaultCurrentData(state) {
      state.current.diagram = null;
      state.current.graph = null;
      state.current.total = null;
    },
    setCompareData(state, { payload }: PayloadAction<AnalysisResponse>) {
      state.compare.diagram = payload.diagram;
      state.compare.graph = payload.graph;
      state.compare.total = payload.total;
    },
    setDefaultCompareData(state) {
      state.compare.diagram = null;
      state.compare.graph = null;
      state.compare.total = null;
    },
    setGraphRange(state, { payload }: PayloadAction<AnalysisGraphRange>) {
      state.graphRange = payload;
    },
    setIsShowAverageSpeed: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowAverageSpeed = payload;
    },
    setIsShowAverageTime: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowAverageTime = payload;
    },
    setIsShowTrafficScore: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowTrafficScore = payload;
    },
    setIsShowGlobalTrafficScore: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowGlobalTrafficScore = payload;
    },
    setIsShowTrafficIndex: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowTrafficIndex = payload;
    },
    setIsShowGlobalTrafficIndex: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowGlobalTrafficIndex = payload;
    },
  },
});
