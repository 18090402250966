import { h3ToGeoBoundary } from "h3-js";
import * as turf from "@turf/turf";
import { TravelHeatmapTypes } from "types";

export const colorPalette = {
  0: "#808080",
  1: "#D5E2EF",
  2: "#C2D4E6",
  3: "#AFC4DE",
  4: "#99B7D6",
  5: "#85A8CE",
  6: "#719AC6",
  7: "#5D8BBE",
  8: "#487DB6",
  9: "#346EAD",
};

export const directionModeOptions = [
  {
    label: "из",
    value: TravelHeatmapTypes.DirectionModeEnum.from,
  },
  {
    label: "в",
    value: TravelHeatmapTypes.DirectionModeEnum.in,
  },
];

export const transportTypesOptions = [
  {
    label: "такси",
    value: TravelHeatmapTypes.TransportTypeEnum.taxi,
  },
  {
    label: "каршеринг",
    value: TravelHeatmapTypes.TransportTypeEnum.carsharing,
  },
];

export const directionModeMapping = {
  [TravelHeatmapTypes.DirectionModeEnum.from]: "Началось",
  [TravelHeatmapTypes.DirectionModeEnum.in]: "Окончилось",
};

export const transportTypeMapping = {
  [TravelHeatmapTypes.TransportTypeEnum.taxi]: "такси",
  [TravelHeatmapTypes.TransportTypeEnum.carsharing]: "каршерингe",
};

export const getCentroid = (h3Index: string) => {
  const coordinates = h3ToGeoBoundary(h3Index, true);
  const poly = turf.polygon([[...coordinates]]);
  return turf.centroid(poly).geometry.coordinates;
};
