import mapboxgl from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { RouterAPI } from "api/router";
import { Edge } from "api/router/truck-graph";
import { GRAPH_CARGO_EDGES_ID } from "map-helpers/order-layers";
import { CargoGraphLayer } from "../map-layer/cargo-graph-layer";

export class GraphController {
  private selectedEdges: Edge[] = [];
  private cargoGraphLayer: CargoGraphLayer;

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.cargoGraphLayer = new CargoGraphLayer(this.map!, GRAPH_CARGO_EDGES_ID, GRAPH_CARGO_EDGES_ID);
    this.map.on("style.load", this.update);
    this.getData();
    this.update();
  }

  public update = () => {
    const {
      graph: { isCargoActive },
    } = this.store.getState();

    if (isCargoActive) this.addLayer();
    else this.removeLayer();
  };

  private addLayer() {
    const url = RouterAPI.tiles.osmGraph();
    this.cargoGraphLayer.add(url);
    this.updateSelectedEdges();
  }

  private removeLayer() {
    this.cargoGraphLayer.remove();
  }

  private updateSelectedEdges() {
    const edges = this.selectedEdges.map((el) => el.osmId);
    this.cargoGraphLayer?.setFilter(edges);
  }

  private getData = async () => {
    const versions = await RouterAPI.truckGraph.getVersions();
    if (!versions.length) return;
    let actualVersion = versions.find((el) => el.isDefault);
    if (!actualVersion) {
      actualVersion = versions[0];
    }
    await this.changeVersion(actualVersion.versionId);
  };

  private changeVersion = async (versionId: number) => {
    const edges = await RouterAPI.truckGraph.getEdges(versionId);
    this.selectedEdges = edges;
    this.updateSelectedEdges();
  };
}
