import React, { memo, useCallback, useEffect } from "react";
import { RouteSection } from "../route-section/route-section";
import { RouteAddress } from "../route-address/route-address";
import { useLocalState } from "./hooks";

import "./route-favorite-info.scss";

const className = "route-favorite-info";

interface Props {
  name: string;
  address: string;
  isFavorite: boolean;
  parent: HTMLDivElement | null;
  handleClearName: () => void;
  handleClearAddress: () => void;
  onSuggestionSelected: (event: any, { suggestion }: any) => void;
  handleCloseButton: React.MouseEventHandler<HTMLButtonElement>;
  handleCancelButton: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirmButton: ({ address, name }: any) => void;
}

type State = {
  addressFailed: boolean;
  nameFailed: boolean;
} & Pick<Props, "name" | "address">;

type ChangeEvent = { newValue: string };

const getStyle = (parent: HTMLDivElement | null) => {
  const style = {
    top: "",
  };

  if (parent?.offsetTop) {
    style.top = `${parent.getBoundingClientRect().bottom - 100}px`;
  }

  return style;
};

export const RouteFavoriteInfo: React.FC<Props> = memo((props) => {
  const { name, address, parent, isFavorite } = props;
  const { handleClearName, handleClearAddress, onSuggestionSelected } = props;
  const { handleCloseButton, handleCancelButton, handleConfirmButton } = props;
  const style = getStyle(parent);
  const { state, setState } = useLocalState<State>({
    name,
    address,
    nameFailed: false,
    addressFailed: false,
  });

  const handleFieldChange = useCallback(
    (field: "name" | "address") =>
      (...args: [any, ChangeEvent]) => {
        const [, { newValue }] = args;
        setState({ [field]: newValue, [`${field}Failed`]: false });
      },
    []
  );

  const handleConfirm = useCallback(() => {
    const { name, address } = state;

    if (!address) return setState({ addressFailed: true });
    if (!name) return setState({ nameFailed: true });

    handleConfirmButton({ name, address });
  }, [state]);

  useEffect(() => {
    if (name !== state.name) setState({ name });
    if (address !== state.address) setState({ address });
  }, [name, address]);

  return (
    <div className={className} style={style}>
      <RouteSection>
        <div className={`${className}-header`}>
          <span>{!isFavorite ? "Добавить в Избранное" : "Информация об Избранном"}</span>
          <button type="button" onClick={handleCloseButton} className={`${className}-close`}>
            <i className="dtm-close-icon" />
          </button>
        </div>
      </RouteSection>
      <RouteSection>
        <RouteAddress
          placeholder="Введите название для этого адреса"
          value={state.name}
          onChange={handleFieldChange("name")}
          noSuggestion
          onSuggestionSelected={onSuggestionSelected}
          handleClearButton={handleClearName}
          failed={state.nameFailed}
          mainPointsLength={2}
          isLoading={false}
          alwaysRenderSuggestions={false}
          noFavoritesSuggestion={false}
          keyPoint=""
        />
        <div className={`${className}-address`}>
          <RouteAddress
            placeholder="Введите адрес"
            value={state.address}
            onChange={handleFieldChange("address")}
            onSuggestionSelected={onSuggestionSelected}
            handleClearButton={handleClearAddress}
            failed={state.addressFailed}
            noFavoritesSuggestion
            mainPointsLength={2}
            isLoading={false}
            alwaysRenderSuggestions={false}
            noSuggestion={false}
            keyPoint=""
          />
        </div>
      </RouteSection>
      <RouteSection>
        <div className={`${className}-buttons`}>
          <button type="button" onClick={handleConfirm} className={`${className}-button ${className}-confirm`}>
            {!isFavorite ? "Добавить" : "Сохранить"}
          </button>
          <button type="button" onClick={handleCancelButton} className={`${className}-button ${className}-cancel`}>
            {!isFavorite ? "Очистить" : "Удалить"}
          </button>
        </div>
      </RouteSection>
    </div>
  );
});
