import { State } from "./pedestrian-graph.types";

export const setDefaultIsVisible: Reducer<State> = (state) => ({ ...state, isVisible: false });
export const setNextIsVisible: Reducer<State, React.MouseEvent<HTMLButtonElement>> = (state) => ({
  ...state,
  isVisible: !state.isVisible,
});
export const setIsVisible: Reducer<State, boolean> = (state, isVisible) => ({
  ...state,
  isVisible,
});
export const handleSetIsDisabled: Reducer<State, State["isDisabled"]> = (state, isDisabled) => ({
  ...state,
  isDisabled,
});
