import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type GraphState = {
  isCargoActive: boolean;
};

export const initialState: GraphState = {
  isCargoActive: false,
};

export const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setIsCargoActive(state, action: PayloadAction<boolean>) {
      state.isCargoActive = action.payload;
    },
  },
});
