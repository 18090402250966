import moment from "moment";
import { RouteManeuver } from "../types";

interface GetManeuversArrivedTime {
  maneuvers: RouteManeuver[];
  withTraffic: boolean;
  startTime: moment.Moment;
}

export function getManeuversArrivedTime({ maneuvers, withTraffic, startTime }: GetManeuversArrivedTime) {
  return maneuvers.reduce(
    (acc, man) => {
      const itemTime = withTraffic ? man.time : man.compare_time;
      const lastTime = acc[acc.length - 1];
      const currentTime = moment(lastTime).add(itemTime, "second");
      return [...acc, currentTime];
    },
    [startTime]
  );
}
