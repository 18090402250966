import moment from "moment";
import { declesionHours } from "../../../utils/declension-hours";
import { WeatherSelectOption } from "../types";

/**
 * Создание опций для селектора времени
 */
export function createWeatherOptions(): Array<WeatherSelectOption> {
  const timeWeatherOptions = [
    {
      label: "до 3 часов",
      value: 0,
    },
    {
      label: "1 день",
      value: 1,
    },
    {
      label: "2 дня",
      value: 2,
    },
    {
      label: "3 дня",
      value: 3,
    },
    {
      label: "4 дня",
      value: 4,
    },
    {
      label: "5 дней",
      value: 5,
    },
  ];

  const nowHour = moment().get("hour");

  /**
   * Прогноз на 36 часов создается раз в сутки в 9:00,
   * мы берем два ближайших которые попадают в этот диапазон
   */

  /** Прогноз за предыдущий день */
  let yesterdayLabel;
  if (nowHour > 9 && nowHour < 21) {
    yesterdayLabel = nowHour + 24 - 9;
    const yesterHoursForecast = {
      label: `${yesterdayLabel} ${declesionHours(yesterdayLabel)}`,
      value: 20,
    };
    timeWeatherOptions.splice(2, 0, yesterHoursForecast);
  }

  /** Прогноз за сегодня */
  let todayLabel;
  if (nowHour > 12) {
    todayLabel = nowHour - 9;
    const todayHoursForecast = {
      label: `${todayLabel} ${declesionHours(todayLabel)}`,
      value: 10,
    };
    timeWeatherOptions.splice(1, 0, todayHoursForecast);
  } else if (nowHour < 9) {
    todayLabel = nowHour + 24 - 9;
    const todayHoursForecast = {
      label: `${todayLabel} ${declesionHours(todayLabel)}`,
      value: 10,
    };
    timeWeatherOptions.splice(1, 0, todayHoursForecast);
  }

  return timeWeatherOptions;
}
