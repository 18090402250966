import React, { useState } from "react";
import { Caption } from "../caption";
import { CustomArrow, GroupBlock, GroupBody, GroupHeader } from "./caption-group.styled";

type CaptionGroupPropsType = {
  groupLabel: string;
  items: { label: string; value: string }[];
};
export const CaptionGroup = React.memo((props: CaptionGroupPropsType) => {
  const borderTop = props.groupLabel === "Сведения о ребре";

  const [isShowGroup, setIsShowGroup] = useState(true);

  return (
    <GroupBlock borderTop={borderTop}>
      <GroupHeader>
        <span>{props.groupLabel}</span>
        <CustomArrow isRight={!isShowGroup} onClick={() => setIsShowGroup(!isShowGroup)} />
      </GroupHeader>
      {isShowGroup && (
        <GroupBody>
          {props.items.map(({ label, value }) => (
            <Caption key={label} label={label} value={value} />
          ))}
        </GroupBody>
      )}
    </GroupBlock>
  );
});
