import { useCallback, useEffect, useRef, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @ts-ignore
import TrafficHistoryPlayerCtrl from "@megapolis/traffic-history-player-mapbox-ctrl";
import { GState } from "documentations";
import {
  changeCorrelationDayAC,
  changeCorrelationIsCustomAC,
  changeSelectedDayAC,
} from "old-store/view/action-creators";
import { EVENTS, on } from "observer";
import {
  CORRELATION_VIEW,
  PERIOD,
  SELECTED_TIME,
  TIME_FROM,
  TIME_TO,
  TYPE_TRAFFIC,
  updateViewAC,
} from "old-store/view/view";
import { trafficUpdateScoreValueAC, trafficUpdateTypeAC } from "old-store/traffic/action-creators";
import {
  addTrafficLayer,
  hideCurrentDetectorRoadLayer,
  hideCurrentTrafficLayer,
  hideLayer,
  removeAnimateTrafficLayers,
  showLayer,
} from "./map-helpers";
import { loadData } from "./get-data-traffic";
import "./styles/view-data.scss";
import { APP_ENV } from "app-env";
import { roadDetectorSlice } from "../ctrl-road-detector";
import { historyTravelSlice } from "../ctrl-history-travel";
import { correlationUpdateIsActive } from "old-store/global/actions";
import { loadVCParamSelector } from "features/ctrl-road-detector/store/selectors";

let map: mapboxgl.Map;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

export const useGetHistoryTravelCtrl = () => {
  const dispatch = useDispatch();
  const dataStore = useSelector(
    (state: GState) => ({
      isActive: state.view.time,
      routeIsActive: state.router.isActive,
      mapInit: state.router.mapIsLoaded,
      selectedDay: state.view.selectedDay,
      es: state.view.es,
      filter: state.view.filter,
      correlationView: state.global.correlation,
      correlationDay: state.view.correlationDay,
      isCustomCorrelation: state.view.isCustomCorrelation,
      isRoadDetectorMode: state.roadDetector.isRoadDetectorMode,
      activeFiltersIds: state.roadDetector.activeFiltersIds,
    }),
    shallowEqual
  );

  const activeFiltersLoadParams = useSelector(loadVCParamSelector);

  const { isActive, mapInit, selectedDay, es, filter, isRoadDetectorMode, activeFiltersIds } = dataStore;
  const { correlationDay, correlationView, isCustomCorrelation } = dataStore;
  const player = useRef<TrafficHistoryPlayerCtrl>();
  const detectorPlayer = useRef<TrafficHistoryPlayerCtrl>();
  // const correlationCtrl = useRef<CorrelationControl>();
  const ctrl = useRef<any>();

  const [dataScores, setDataScores] = useState<{ detectorsScores: any[]; trafficScores: any[] }>();
  const [periodData, setPeriodData] = useState<{ selectedTime: string; period: number }>({
    selectedTime: "",
    period: 60,
  });
  const upDataViewState = useCallback(
    (obj) => {
      const { selectedTime, period, type, value } = obj;
      const data = {
        [SELECTED_TIME]: moment(selectedTime).toISOString(true),
        [PERIOD]: period,
        [TYPE_TRAFFIC]: type,
        [TIME_FROM]: moment(selectedTime).subtract(period, "minute").toISOString(true),
        [TIME_TO]: moment(selectedTime).toISOString(true),
      };
      if (type === "forecast") {
        // @ts-ignore
        data[CORRELATION_VIEW] = false;
      }
      batch(() => {
        dispatch(changeSelectedDayAC(selectedTime));
        dispatch(updateViewAC(data));
        dispatch(trafficUpdateScoreValueAC(value));
        dispatch(trafficUpdateTypeAC(type));
      });
    },
    [dispatch]
  );

  const setScoresPTP = useCallback(
    ({ selectedTime, period }: typeof periodData) => {
      const mode = isRoadDetectorMode ? "detectorsScores" : "trafficScores";
      const format = "YYYY-MM-DDTHH:mm:00+03:00";
      const arrayTimes = [
        moment(selectedTime).subtract(1, "hour").format(format),
        moment(selectedTime).format(format),
        moment(selectedTime).add(1, "hour").format(format),
      ];
      const currentDataScore = dataScores?.[mode]
        ?.filter((x) => x)
        .find((x) => {
          return moment(x?.data?.[0]?.scores?.[0]?.time).format("DD") === moment(selectedTime).format("DD");
        });

      const currentPeriodData = currentDataScore?.data.find((i: any) => i.periodM === period);
      const res = currentPeriodData?.scores
        .filter((x: any) => arrayTimes.includes(x.time))
        .map(({ dynamicsMessage, score, time }: any) => ({
          scoreMSG: dynamicsMessage,
          score,
          time,
        }));

      res && isRoadDetectorMode && dispatch(historyTravelSlice.actions.setDetectorScores(res));
    },

    [dataScores, isRoadDetectorMode]
  );

  useEffect(() => {
    if (dataScores) {
      setScoresPTP(periodData);
    }
  }, [dataScores, periodData]);

  const upDataDetectorViewState = useCallback(
    (obj) => {
      const { selectedTime, period, type, value } = obj;
      setPeriodData({ selectedTime, period });
      const data = {
        [SELECTED_TIME]: moment(selectedTime).toISOString(true),
        [PERIOD]: period,
        [TYPE_TRAFFIC]: type,
        [TIME_FROM]: moment(selectedTime).subtract(period, "minute").toISOString(true),
        [TIME_TO]: moment(selectedTime).toISOString(true),
      };
      if (type === "forecast") {
        // @ts-ignore
        data[CORRELATION_VIEW] = false;
      }
      batch(() => {
        dispatch(changeSelectedDayAC(selectedTime));

        dispatch(updateViewAC(data));
        dispatch(trafficUpdateTypeAC(type));
        dispatch(roadDetectorSlice.actions.setDetectorScore({ score: value }));
      });
    },
    [dispatch]
  );
  const upDataDate = useCallback(
    (str, callback) => {
      dispatch(changeSelectedDayAC(str));
      if (typeof callback === "function") {
        callback(moment(str).valueOf());
      }
    },
    [dispatch]
  );

  const onChangeCorrelation = useCallback(
    (date: any) => {
      dispatch(changeCorrelationDayAC(date));
    },
    [dispatch]
  );

  const onChangeIsApplyCorrelation = useCallback(
    (date: any) => {
      dispatch(changeCorrelationIsCustomAC(date));
      player.current?.changeIsApplyCorrelation(date);
      detectorPlayer.current?.changeIsApplyCorrelation(date);
      dispatch(correlationUpdateIsActive(date));
    },
    [dispatch]
  );

  const _loadData = useCallback(
    (intDate, correlationDay) => {
      const day = moment(intDate, "x").format("YYYY-MM-DD");
      const data = {
        es,
        day,
      };

      return new Promise((res) => {
        loadData(data, correlationDay, isRoadDetectorMode).then((result: any) => {
          // @ts-ignore
          setDataScores((pre) => {
            if (isRoadDetectorMode) {
              return { detectorsScores: pre?.detectorsScores ? [...pre.detectorsScores, result] : [result] };
            } else {
              return { trafficScores: pre?.trafficScores ? [...pre.trafficScores, result] : [result] };
            }
          });
          res(result);
        });
      });
    },
    [es, isRoadDetectorMode]
  );

  const tz =
    APP_ENV.REACT_APP_DTM_REGION.toLowerCase() === "Владивосток".toLowerCase() ? "Asia/Vladivostok" : undefined;

  const initPlayer = useCallback(() => {
    player.current = new TrafficHistoryPlayerCtrl({
      tz,
      selectedTime: moment(selectedDay),
      onChangeTime: (obj: any) => upDataViewState(obj),
      selectedDay: moment(selectedDay),
      onChangeDay: (val: any, callback: any) => upDataDate(val, callback),
      loadLayer: addTrafficLayer,
      hideCurrentTrafficLayer,
      hideLayer,
      showLayer,
      loadData: _loadData,
      locationFilter: { eq: [], es, filter },
      removeAnimateTrafficLayers,
      returnControl: (_ctrl: any) => {
        ctrl.current = _ctrl;
      },
      prefixClassName: "traffic-history-player",
      showCorrelation: true,
      isApplyCorrelation: correlationView,
      onChangeCorrelation,
      onChangeIsApplyCorrelation,
      correlationDay,
    });
  }, [
    _loadData,
    es,
    filter,
    selectedDay,
    isActive,
    correlationView,
    isCustomCorrelation,
    onChangeCorrelation,
    onChangeIsApplyCorrelation,
    correlationDay,
    upDataDate,
    upDataViewState,
    detectorPlayer,
    isRoadDetectorMode,
  ]);

  const detectorPlayerInit = useCallback(() => {
    detectorPlayer.current = new TrafficHistoryPlayerCtrl({
      tz,
      selectedTime: moment(selectedDay),
      onChangeTime: (obj: any) => upDataDetectorViewState(obj),
      // onChangeTime: (obj: any) => null,
      selectedDay: moment(selectedDay),
      onChangeDay: (val: any, callback: any) => upDataDate(val, callback),
      loadLayer: (par: any) => addTrafficLayer(par, true, activeFiltersLoadParams),
      hideCurrentTrafficLayer: hideCurrentDetectorRoadLayer,
      hideLayer,
      showLayer,
      loadData: _loadData,
      locationFilter: { eq: [], activeFiltersIds, filter },
      removeAnimateTrafficLayers,
      returnControl: (_ctrl: any) => {
        ctrl.current = _ctrl;
      },
      prefixClassName: "traffic-history-player",
      showCorrelation: true,
      isApplyCorrelation: correlationView,
      onChangeCorrelation,
      onChangeIsApplyCorrelation,
      correlationDay,
    });
  }, [
    _loadData,
    es,
    filter,
    selectedDay,
    isActive,
    correlationView,
    isCustomCorrelation,
    onChangeCorrelation,
    onChangeIsApplyCorrelation,
    correlationDay,
    upDataDate,
    upDataViewState,
    player,
    isRoadDetectorMode,
    activeFiltersIds,
    activeFiltersLoadParams,
  ]);

  useEffect(() => {
    if (mapInit && !isRoadDetectorMode && !player.current) {
      initPlayer();
    }
  }, [initPlayer, mapInit, isRoadDetectorMode, correlationView]);

  useEffect(() => {
    if (mapInit && isRoadDetectorMode && !detectorPlayer.current) {
      detectorPlayerInit();
    }
  }, [detectorPlayerInit, mapInit, isRoadDetectorMode, correlationView]);

  useEffect(() => {
    if (!player.current || !mapInit) return;
    if (isActive && !isRoadDetectorMode) {
      if (detectorPlayer.current) {
        // @ts-ignore
        map.removeControl(detectorPlayer.current);
      }
      if (player.current) {
        // @ts-ignore
        map.addControl(player.current, "bottom-left");
      }
    }
    if (!isActive && !isRoadDetectorMode) {
      if (player.current) {
        // @ts-ignore
        map.removeControl(player.current);
      }
      dispatch(trafficUpdateTypeAC("last"));
      dispatch(trafficUpdateScoreValueAC(null));
      dispatch(
        updateViewAC({
          type: "last",
          selectedDay: moment().toISOString(true),
        })
      );
    }
  }, [dispatch, player, isActive, mapInit, isRoadDetectorMode]);

  useEffect(() => {
    if (!detectorPlayer.current) return;
    if (isActive && isRoadDetectorMode) {
      if (player.current) {
        // @ts-ignore
        map.removeControl(player.current);
        dispatch(
          updateViewAC({
            type: "last",
            selectedDay: moment().toISOString(true),
          })
        );
      }
      if (detectorPlayer.current) {
        // @ts-ignore
        map.addControl(detectorPlayer.current, "bottom-left");
      }
    }
    if (!isActive && isRoadDetectorMode) {
      if (detectorPlayer.current) {
        // @ts-ignore
        map.removeControl(detectorPlayer.current);
      }
      dispatch(trafficUpdateTypeAC("last"));
      dispatch(trafficUpdateScoreValueAC(null));
      dispatch(
        updateViewAC({
          type: "last",
          selectedDay: moment().toISOString(true),
        })
      );
    }
  }, [dispatch, detectorPlayer, isActive, mapInit, isRoadDetectorMode]);

  useEffect(() => {
    player.current?.changeCorrelationDay(correlationDay);
  }, [player, correlationDay, correlationView, isCustomCorrelation]);

  useEffect(() => {
    player.current?.changeIsApplyCorrelation(correlationView);
    detectorPlayer.current?.changeIsApplyCorrelation(correlationView);
    dispatch(changeCorrelationIsCustomAC(correlationView));
  }, [player, correlationView, isActive]);
};
