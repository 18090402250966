import RouteProperties from "map-helpers/properties/route-properties";
import getBeforeId, { ROUTE_PATH_ALTERNATIVE, ROUTE_PATH_ALTERNATIVE_STROKE } from "../order-layers";
import Layer from "./layer";

const layerId = ROUTE_PATH_ALTERNATIVE_STROKE;

export default class RouteAlternativeStrokeLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    /** @type {mapboxgl.Map} */
    this.map = map;
    this.routeProperties = new RouteProperties();
  }

  addLayer() {
    const layer = {
      id: layerId,
      type: "line",
      source: ROUTE_PATH_ALTERNATIVE,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#fff",
        "line-width": this.routeProperties.lineWidthStroke,
      },
    };

    const beforeId = getBeforeId(layerId, this.map);

    this.map.addLayer(layer, beforeId);
  }

  update() {
    if (!this.checkLayer()) this.addLayer();
  }
}
