import mapboxgl from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import moment from "moment";
import { CancelTokenSource } from "axios";
import { GState } from "documentations";
import { FLOODING_ID } from "map-helpers/order-layers";
import IncidentAPI from "api/incident/incident-api";
import { Incident } from "api/incident/model/incident";
import { IncidentInputModel } from "api/incident/incident-api-types";
import { FloodingLayer } from "../map-layer/flooding-layer";
import { CityEventsPopup } from "map-helpers/popups/city-events-popup";

export class FloodingController {
  private floodingLayer: FloodingLayer;
  private cancelToken?: CancelTokenSource = undefined;
  private popup: CityEventsPopup;
  private token = "";

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.floodingLayer = new FloodingLayer(map, FLOODING_ID, FLOODING_ID);
    this.map.on("style.load", this.update);

    this.popup = new CityEventsPopup({
      map: this.map,
      layerId: FLOODING_ID,
      iconUrl: "/img/flooding/flooding-popup-icon.svg",
    });

    this.update();

    this.token = store.getState().oidc.user.access_token;
  }

  public readonly update = () => {
    const {
      flooding: { isActive },
    } = this.store.getState();

    this.cancelToken?.cancel();

    if (isActive) this.addLayer();
    else this.removeLayer();
  };

  private async addLayer() {
    const incidents = await this.fetchData();
    incidents && this.floodingLayer.add(incidents);
  }

  private removeLayer() {
    this.floodingLayer.remove();
  }

  private async fetchData() {
    const model: IncidentInputModel = {
      DateWhenActive: moment().startOf("day").format("YYYY-MM-DD"),
      Type: {
        id: 30,
      },
      Status: {
        id: 10,
      },
      PageSize: -1,
      CreateFrom: "2015-01-01",
      token: this.token,
    };

    const { request, cancelToken } = await IncidentAPI.getIncidentList<{ items: Incident[] }>(model);
    this.cancelToken = cancelToken;

    const { data } = await request;
    const incidents = data?.items || [];
    return incidents;
  }
}
