export namespace AdministrativeDivisionTypes {
  export enum Type {
    "districts" = "districts",
    "districtZones" = "districtZones",
    "unset" = "unset",
  }

  export type Roads = Pick<Statistic, "roads" | "roadsLength">;

  export type DistrictFeatureProperties = {
    OsmId: number;
    Name: string;
    ShortName?: string;
  };

  export type DistrictZonesFeatureProperties = {
    OsmId: number;
    Name: string;
  };

  export type DistrictAssetCount = {
    assetCount: number;
    districtName: string;
  };

  export type DistrictFeature = GeoJSON.Feature<GeoJSON.Polygon, DistrictFeatureProperties>;
  export type DistrictZonesFeature = GeoJSON.Feature<GeoJSON.Polygon, DistrictZonesFeatureProperties>;

  export type MissingStatisticPayload = {
    id: number;
    name: string;
    fullName: string;
  };

  export type Statistic = {
    id: number;
    name: string;
    fullName: string;
    trafficScore?: number;
    trafficIndex?: number;
    carCount?: number;
    tlsCount?: number;
    roadSignCount?: number;
    speedCamCount?: number;
    roadsLength?: number;
    roads?: string[];
  };
}
