import { RouteCosting } from "api/router/model/router";
import { ReactComponent as CarIcon } from "assets/icons/car.svg";
import { ReactComponent as BusFrontIcon } from "assets/icons/bus-front.svg";
import { ReactComponent as CargoIcon } from "assets/icons/cargo.svg";
import { ReactComponent as PedestrianIcon } from "assets/icons/pedestrian.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/template.svg";

export const icons = {
  [RouteCosting.Auto]: <CarIcon />,
  [RouteCosting.Multimodal]: <BusFrontIcon />,
  [RouteCosting.Cargo]: <CargoIcon />,
  [RouteCosting.Pedestrian]: <PedestrianIcon />,
  [RouteCosting.Template]: <TemplateIcon />,
};

export const titles = {
  [RouteCosting.Auto]: "Все ТС",
  [RouteCosting.Multimodal]: "НГПТ",
  [RouteCosting.Cargo]: "Грузовые ТС",
  [RouteCosting.Pedestrian]: "Пешеход",
  [RouteCosting.Template]: "Избранные",
};
