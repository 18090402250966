export enum EVENTS {
  INIT_MAP = "INIT_MAP",
  SECTOR_ANALYSIS_DIAGRAM_MOUSEMOVE = "SECTOR_ANALYSIS_DIAGRAM_MOUSEMOVE",
  SECTOR_ANALYSIS_MAP_DRAW_PATH = "SECTOR_ANALYSIS_MAP_DRAW_PATH",
  SECTOR_ANALYSIS_MAP_REMOVE_PATH = "SECTOR_ANALYSIS_MAP_REMOVE_PATH",
  SECTOR_ANALYSIS_MAP_UPDATE_PATH = "SECTOR_ANALYSIS_MAP_UPDATE_PATH",
  MAP_POPUP_ENTER = "MAP_POPUP_ENTER",
  MAP_POPUP_LEAVE = "MAP_POPUP_LEAVE",
  REMOVE_MAP = "REMOVE_MAP",
  DIAGRAM_LEAVE = "DIAGRAM_LEAVE",
  ON_DETAILS_POPUP_CLOSE = "ON_DETAILS_POPUP_CLOSE",
  CHANGE_IS_COMPARE = "CHANGE_IS_COMPARE",
  RESTORE_DIAGRAM_ZOOM = "RESTORE_DIAGRAM_ZOOM",
  HIDE_DIAGRAM_POPUPS = "HIDE_DIAGRAM_POPUPS",
  ON_IS_ZOOM_TOOLTIP_CHANGE = "ON_IS_ZOOM_TOOLTIP_CHANGE",
  WINDOW_RESIZE = "WINDOW_RESIZE",
  DIAGRAM_SCALING = "DIAGRAM_SCALING",
  APPEARANCE_CHANGE = "APPEARANCE_CHANGE",
  SYNC_DIAGRAM_SELECTION = "SYNC_DIAGRAM_SELECTION",
  REMOVE_SELECTIONS = "REMOVE_SELECTIONS",
  CHANGE_SELECTION_POPUP_VISIBILITY = "CHANGE_SELECTION_POPUP_VISIBILITY",
}
