import * as consts from "./consts";
import { addHours, addSeconds, format } from "date-fns";
import { APP_ENV } from "../../../../../../app-env";
import moment from "moment";

type GetTime = {
  startX: number;
  offsetX: number;
  width: number;
};

const isVld = APP_ENV.REACT_APP_DTM_REGION.toLowerCase() === "Владивосток".toLowerCase();

export const getColumnsInHour = (period: number) => 60 / period;

export const formatGraphTickValue = (value: number, range: number) => {
  if (range < 1) return value.toFixed(2);
  if (range < 10) return value.toFixed(1);
  return value.toFixed(0);
};

export const getTime = ({ startX, offsetX, width }: GetTime) => {
  const oneDay = 86400;
  const seconds = (oneDay * (startX - offsetX)) / width;

  if (seconds < 0) return null;
  if (isVld) {
    return format(addSeconds(addHours(new Date(0), -2), seconds), "HH:mm");
  }
  return moment(0).subtract(3, "h").add(seconds, "s").format("H:mm");
};

export const getAxisStepByTotalLength = (totalLength: number) => {
  if (totalLength >= 110000) return consts.stepTicks[8];
  if (totalLength < 110000 && totalLength >= 55000) return consts.stepTicks[7];
  if (totalLength < 55000 && totalLength >= 22000) return consts.stepTicks[6];
  if (totalLength < 22000 && totalLength >= 11000) return consts.stepTicks[5];
  if (totalLength < 11000 && totalLength >= 5500) return consts.stepTicks[4];
  if (totalLength < 5500 && totalLength >= 2200) return consts.stepTicks[3];
  if (totalLength < 2200 && totalLength >= 1100) return consts.stepTicks[2];
  if (totalLength < 1100 && totalLength >= 550) return consts.stepTicks[1];
  return consts.stepTicks[0];
};

export const lightenColor = (color: string, percent: number) => {
  const num = parseInt((color ?? "").replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

export const getKeyByColumnAndRow = (column: number, row: number) => {
  return `column${column}row${row}`;
};

export const getBetweenStepCount = (stepLength: number) => {
  const stepIndex = consts.stepTicks.findIndex((el) => el === stepLength);
  const previousStep = consts.stepTicks[stepIndex - 1];
  const minusTwoStep = consts.stepTicks[stepIndex - 2];
  if (minusTwoStep) return stepLength / minusTwoStep;
  if (previousStep) return stepLength / previousStep;
  return 2;
};
