import mapboxgl from "mapbox-gl";
import { Layer } from "./pedestrian-graph.layer";

type Options = {
  isVisible: boolean;
};

const initialOptions: Options = { isVisible: false };

export class PedestrianGraph {
  private layer?: Layer;

  constructor(private readonly map: mapboxgl.Map, private options: Options = initialOptions) {
    this.layer = new Layer(this.map);
    this.layer.setVisibility(this.options.isVisible);
    this.map.on("style.load", this.handleStyleLoad);
  }

  private handleStyleLoad = () => {
    this.layer?.destroy();
    this.layer = new Layer(this.map);
    this.layer.setVisibility(this.options.isVisible);
  };

  public readonly setVisibility = (value: boolean) => {
    this.options.isVisible = value;
    this.layer?.setVisibility(this.options.isVisible);
  };

  public readonly destroy = () => {
    this.map.off("style.load", this.handleStyleLoad);
  };

  public handleChangeDate = (date: string) => {
    this.layer?.destroy();
    this.layer = new Layer(this.map, date);
  };
}
