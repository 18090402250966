import { APP_ENV } from "app-env";
import axios from "axios";
import { DetectorDataApiResponseType, RoadDetectorFiltersItemApiResponseType } from "./detector-data.types";
import { getParamsToString } from "../utils";

const detectorDataApi = axios.create({
  baseURL: APP_ENV.REACT_APP_DOMAIN_API,
});

const detectorRouterApi = axios.create({
  baseURL: APP_ENV.REACT_APP_DTM_ROUTER_API,
});

const detectorTrafficApi = axios.create({
  baseURL: APP_ENV.REACT_APP_DTM_TRAFFIC_API,
});

export type GetDetectorDataByIdParamsType = {
  detectorId: string;
  from?: string;
  to?: string;
  last?: boolean;
  interval?: number;
  split?: number;
};

export const getDetectorDataById = (params: GetDetectorDataByIdParamsType): Promise<DetectorDataApiResponseType> => {
  const path = `Detector/fixationlanes`;
  const keysString = getParamsToString(params);
  return detectorRouterApi.get(path + keysString);
};

export const getRoadDetectorFiltersItem = (): Promise<RoadDetectorFiltersItemApiResponseType> => {
  const path = `detectorservice/Detector/vehicles`;

  return detectorDataApi.get(path);
};

export type GetRoadDetectorsParamsType = {
  vc?: number[];
  from?: string;
  to?: string;
  md?: string;
};
export const getRoadDetectors = (params: GetRoadDetectorsParamsType) => {
  const path = "detector/detectors-track";
  const keysString = getParamsToString(params);
  return detectorRouterApi.get(path + keysString);
};

export type GetDetectorScoreParamsType = {
  period?: string;
  from?: string;
  to?: string;
  data?: string;
  vc?: number[];
};

export const getDetectorScore = (params: GetDetectorScoreParamsType) => {
  const path = "Detectors/city";
  const keysString = getParamsToString(params);
  return detectorTrafficApi.get(path + keysString);
};

export const getAlternativeScoreParams = (params: GetDetectorScoreParamsType) => {
  const path = "Detectors/city/alternative";
  const keysString = getParamsToString(params);
  return detectorTrafficApi.get(path + keysString);
};

export type SplitParamsType = {
  from: string;
  to: string;
  data: string;
  split: string;
  day?: string;
};

type SplitDataResponse<T> = {
  data: T[];
};

export type GetCityFlowParamsSplitResponseType = {
  split: string;
  flowParams: {
    time: string;
    type: string;
    averageSpeed: string;
    freeFlowAverageSpeed: string;
    averageOcc: string;
    averageUtilization: string;
    averageDensity: string;
    averageVolume: string;
  }[];
};

export const getCityFlowParamsSplit = (
  params: SplitParamsType
): Promise<SplitDataResponse<GetCityFlowParamsSplitResponseType>> => {
  const path = "Detectors/city/flowparams/split";
  const keysString = getParamsToString(params);
  return detectorTrafficApi.get(path + keysString);
};

export type GetScoreParamsSplitResponseType = {
  split: string;
  scores: {
    time: string;
    type: string;
    score: string;
    dynamicsMessage: string;
  }[];
};

export const getScoreParamsSplit = (
  params: SplitParamsType
): Promise<SplitDataResponse<GetScoreParamsSplitResponseType>> => {
  const path = "Detectors/city/split";
  const keysString = getParamsToString(params);
  return detectorTrafficApi.get(path + keysString);
};

export const getScoreParamsSplitCorrelation = async (input: SplitParamsType, correlationDay: string) => {
  const paramsSplit = getParamsToString(input);
  const paramsCorrelation = getParamsToString({ ...input, day: correlationDay });
  const url = `dtm/traffic/api/Detectors/city/split`;
  const [{ data: split }, { data: correlation }] = await Promise.all([
    detectorDataApi.get(url + paramsSplit),
    detectorDataApi.get(url + paramsCorrelation),
  ]);

  if (!Array.isArray(split) || !Array.isArray(correlation)) return [];

  return split.map((period: any, indexPeriod: number) => {
    return {
      ...period,
      scores: period.scores.map((item: any, indexItem: number) => {
        const scores = correlation[indexPeriod].scores;
        const slice = scores[indexItem];
        const correlationScore = slice?.score ?? 0;

        return {
          ...item,
          correlation: correlationScore,
        };
      }),
    };
  });
};

export const getAlternativeScoreParamsSplit = (
  params: SplitParamsType
): Promise<SplitDataResponse<GetScoreParamsSplitResponseType>> => {
  const path = "Detectors/city/alternative/split";
  const keysString = getParamsToString(params);
  return detectorTrafficApi.get(path + keysString);
};
