import mapboxgl from "mapbox-gl";

export enum Events {
  load = "@dtm.map.load",
  remove = "@dtm.map.remove",
  viewBoxChange = "@dtm.map.view-box.change",
}

export type Handlers = {
  [Events.load]: (map?: mapboxgl.Map) => void;
  [Events.remove]: () => void;
  [Events.viewBoxChange]: (nw: mapboxgl.LngLat, se: mapboxgl.LngLat) => void;
};
