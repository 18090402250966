import { createEffect } from "effector/effector.umd";
import { getGraphStatistics } from "api";
import { State } from "./graph-compare.types";

const fetchGraphByDate = createEffect(
  async ({ date, graphType }: { date: string; graphType: State["selectedGraphTypes"] }) => {
    let dataRoad;
    let dataPedestrian;
    if (graphType.road) {
      dataRoad = { ...(await getGraphStatistics(date)), date };
    }
    if (graphType.pedestrian) {
      dataPedestrian = { ...(await getGraphStatistics(date, true)), date };
    }

    if (dataRoad && dataPedestrian) {
      return {
        date,
        edgesCount: dataRoad.edgesCount + dataPedestrian.edgesCount,
        nodesCount: dataRoad.nodesCount + dataPedestrian.nodesCount,
        roadsNetworkLength: dataRoad.roadsNetworkLength + dataPedestrian.roadsNetworkLength,
      };
    }

    if (dataRoad && !dataPedestrian) {
      return dataRoad;
    }
    if (!dataRoad && dataPedestrian) {
      return dataPedestrian;
    }
    return { ...(await getGraphStatistics(date)), date };
  }
);

export const effects = { fetchGraphByDate };
