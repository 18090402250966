import getBeforeId, { WEATHER_ID } from "../order-layers";
import Layer from "./layer";

const layerId = WEATHER_ID;

export default class WeatherLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  addLayer() {
    const layer = {
      id: layerId,
      type: "symbol",
      source: layerId,
      layout: {
        "icon-image": "{icon}",
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  addSource() {
    if (this.data)
      this.map.addSource(layerId, {
        type: "geojson",
        data: this.data,
      });
  }
}
