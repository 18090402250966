import styled from "styled-components";

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  width: 400px;
  border-radius: 8px;
`;

export const HeaderBlock = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  height: 61px;
  border-bottom: 1px solid #e0e0e0;
`;

export const TitleWithIconBlock = styled.div`
  padding-left: 15px;
  display: flex;
  gap: 9px;
  align-items: center;
`;

export const HeaderTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
export const HeaderName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const HeaderAddress = styled.div`
  font-size: 11px;
  line-height: 16px;
  width: 291px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #747474;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
  padding-right: 15px;
`;

export const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
`;

export const TitleBlock = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  padding-bottom: 8px;
  svg {
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .title-light {
    font-size: 12px;
    line-height: 16px;
  }
  .arrow-up {
    rotate: 180deg;
  }
`;
