import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { esUpdateSelectedAC } from "old-store/view/action-creators";
import FilterExternal from "./filter-system";

import "./filter-system.scss";
import { roadDetectorSlice } from "../ctrl-road-detector";

const className = "filter-system";

export const FilterExternalSystemNew = (props) => {
  const dispatch = useDispatch();

  const { selected, filter, transportTypeDictionary } = useSelector((state) => ({
    /** Выбранные пункты меню */
    selected: state.view.es,
    /** Фильтр дорог */
    filter: state.view.filter,
    /** Справочник типов транспорта */
    transportTypeDictionary: state.view.transportTypeDictionary,
  }));

  useEffect(() => {
    props.setIsActive(Boolean(selected.length));
  }, [selected]);

  const updateSelected = useCallback((data) => dispatch(esUpdateSelectedAC(data)), [dispatch]);

  return (
    <div className={className}>
      <FilterExternal
        toggleTitle={props.toggleTitle}
        isShowPopup={props.isShowPopup}
        setIsShowPopup={props.setIsShowPopup}
        selected={selected}
        filter={filter}
        transportTypeDictionary={transportTypeDictionary}
        updateSelected={updateSelected}
        rowHeight={40}
      />
    </div>
  );
};

const useGetValidFilterItems = () => {
  const filterItems = useSelector((state) => state.roadDetector.roadDetectorFilters);

  return {
    validFilterItems: filterItems.map((i) => {
      if (i.id === 1) return { id: i.id, name: "Мотоциклы" };
      if (i.id === 2) return { id: i.id, name: "Малые легковые ТС" };
      if (i.id === 3) return { id: i.id, name: "Легковые ТС и малые грузовики" };
      if (i.id === 4) return { id: i.id, name: "Малые/cредние автобусы и грузовики" };
      if (i.id === 5) return { id: i.id, name: "Полноразмерные автобусы и аналоги" };
      if (i.id === 6) return { id: i.id, name: "Автопоезда" };
      return i;
    }),
  };
};

export const DetectorFilterSystemNew = (props) => {
  const { validFilterItems } = useGetValidFilterItems();
  const dispatch = useDispatch();
  const updateSelected = (data) => {
    dispatch(roadDetectorSlice.actions.setActiveFiltersIds({ ids: data }));
  };

  const selected = useSelector((state) => state.roadDetector.activeFiltersIds);

  return (
    <div className={className}>
      <FilterExternal
        toggleTitle={props.toggleTitle}
        isShowPopup={props.isShowPopup}
        setIsShowPopup={props.setIsShowPopup}
        selected={selected}
        filter={validFilterItems}
        transportTypeDictionary={validFilterItems}
        updateSelected={updateSelected}
        rowHeight={40}
        detectorMode
      />
    </div>
  );
};
