import { memo, useMemo } from "react";
import cn from "classnames";
import { trafficScoreIcons } from "assets";

import "./score-icon.scss";

type ScoreType = keyof typeof trafficScoreIcons;

type Props = {
  score: number | null;
  isDisabled?: boolean;
};

const baseClass = "score-icon";

export const ScoreIcon = memo<Props>(({ score, isDisabled }) => {
  const icon = useMemo(
    () => (score && !isDisabled ? trafficScoreIcons[score as ScoreType] : trafficScoreIcons.default),
    [score]
  );
  const className = useMemo(
    () =>
      cn(baseClass, {
        [`${baseClass}_${score}`]: score,
        [`${baseClass}_disabled`]: !!isDisabled,
      }),
    [score, isDisabled]
  );

  return (
    <div className={className} data-cypress="score-icon">
      {icon}
    </div>
  );
});
