/* eslint-disable no-nested-ternary */
import mapboxgl from "mapbox-gl";
import moment from "moment";
import {
  BAD_ROADS_ID,
  BLOCKING_POINTS_ID,
  LayerDash0,
  LayerDash1,
  LayerDash2,
  LayerDash3,
  LayerFull,
  LayerIcon,
  OVERLAP_DASH_ID,
  OVERLAP_ID,
} from "../order-layers";
import Popup from "./popup";

const replaceNull = (invalidProps) => {
  const props = {};

  for (const property in invalidProps) {
    if (invalidProps[property] === "null") {
      props[property] = null;
    } else props[property] = invalidProps[property];
  }
  return props;
};

const options = {
  layerId: OVERLAP_ID,
};
export default class DtpPopup extends Popup {
  constructor(map) {
    super(map, options);
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "dtm-popup",
      maxWidth: "600px",
    });
    this.layers = [
      LayerIcon,
      OVERLAP_DASH_ID,
      OVERLAP_ID,
      BAD_ROADS_ID,
      BLOCKING_POINTS_ID,
      LayerDash0,
      LayerDash1,
      LayerDash2,
      LayerDash3,
      LayerFull,
    ];
  }

  getIncidentStatus(incidentStatus) {
    const json = JSON.parse(incidentStatus);
    return json.name;
  }

  formatDate(date) {
    const format = "HH:mm DD.MM.YYYY";
    const formatDate = moment(date).format(format);
    return formatDate;
  }

  getSubtype(incidentSubtype) {
    const json = JSON.parse(incidentSubtype);
    return `  <img
    className="route-instructions-img"
    src="/img/blocking/subtype-${json.id}.svg"
    width="20"
    height="20"
    alt=${json.name}
  /> <span>${json.name}</span>`;
  }

  getDeclinationLines(n) {
    const text_forms = ["полоса", "полосы", "полос"];
    // eslint-disable-next-line no-param-reassign
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return text_forms[2];

    if (n1 > 1 && n1 < 5) return text_forms[1];

    if (n1 === 1) return text_forms[0];

    return text_forms[2];
  }

  getCondition(roadCondition) {
    const json = JSON.parse(roadCondition);
    return json.name;
  }

  getPopupHTML(props) {
    const {
      description,
      blockedLanesNumber,
      lanesNumber,
      hasPublicTransportHindrance,
      overlapBegin,
      overlapEnd,
      overlapExpectedBegin,
      overlapExpectedEnd,
      blockedLaneLength,
      freeLaneLength,
      speedLimit,
      incidentSubtype,
      roadCondition,
      type,
    } = replaceNull(props);

    const popupHTML = `<p>
    ${
      incidentSubtype
        ? `<span class='blocking-name'>${this.getSubtype(incidentSubtype)}</span>`
        : type === 6
        ? '<span class="blocking-name"><span>Дорожно транспортное происшествие</span></span>'
        : '<span class="blocking-name"><span>Перекрытие</span></span>'
    }
    <br />

    ${
      /** Неполное перекрытие */
      blockedLanesNumber > 0 && lanesNumber > 0 && blockedLanesNumber !== lanesNumber
        ? `<span>${blockedLanesNumber > 1 ? "Перекрыто" : "Перекрыта"} ${blockedLanesNumber} ${this.getDeclinationLines(
            blockedLanesNumber
          )} из ${lanesNumber}</span> <br />`
        : ""
    }

    ${
      /** Полное перекрытие */
      blockedLanesNumber > 0 && lanesNumber > 0 && blockedLanesNumber === lanesNumber
        ? `<span>Полное перекрытие ${blockedLanesNumber}  ${blockedLanesNumber > 1 ? "полос" : "полосы"} </span> <br />`
        : ""
    }

    ${
      hasPublicTransportHindrance
        ? `<span> Помехи НГПТ ${hasPublicTransportHindrance ? "есть" : "нет"}</span> <br />`
        : ""
    }

    ${
      overlapBegin && overlapEnd
        ? `<span>Факт с ${this.formatDate(overlapBegin)} по ${this.formatDate(overlapEnd)} </span> <br />`
        : ""
    }

    ${overlapBegin && !overlapEnd ? `<span>Факт с ${this.formatDate(overlapBegin)}</span> <br />` : ""}

    ${!overlapBegin && overlapEnd ? `<span>Факт по ${this.formatDate(overlapEnd)} </span> <br />` : ""}

    ${
      overlapExpectedBegin && overlapExpectedEnd
        ? `<span>План с ${this.formatDate(overlapExpectedBegin)} по ${this.formatDate(
            overlapExpectedEnd
          )} </span> <br />`
        : ""
    }

    ${
      overlapExpectedBegin && !overlapExpectedEnd
        ? `<span>План с ${this.formatDate(overlapExpectedBegin)}</span> <br />`
        : ""
    }
    ${
      !overlapExpectedBegin && overlapExpectedEnd
        ? `<span>План по ${this.formatDate(overlapExpectedEnd)} </span> <br />`
        : ""
    }

    ${blockedLaneLength ? `<span >Длина перекрытия ${blockedLaneLength} м</span> <br />` : ""}

    ${freeLaneLength ? `<span>Длина свободного участка ${freeLaneLength} м</span> <br />` : ""}

    ${speedLimit ? `<span>Ограничение скорости ${speedLimit} км/ч</span> <br />` : ""}

    ${roadCondition ? `<span>Состояние покрытия: ${this.getCondition(roadCondition)}</span> <br />` : ""}
    
    ${!roadCondition ? "<span>Состояние покрытия: Неизвестно</span> <br />" : ""}

    <br />
    ${description ? `<span class='blocking-small'>${description}</span> <br />` : ""}
    </p>
    `;
    return popupHTML;
  }
}
