import { MapController } from "map-controller";
import { GState, UiStore } from "store";
import { Broker } from "./broker";

export class ZoneStatisticsBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleIsActiveChange = (state: GState.ZoneStatisticsState) => {
    this.mapController.zoneStatistics?.setIsActive(state.isActive);
  };

  private handleZoneStatisticsClose = () => {
    this.mapController.zoneStatistics?.setIsActive(false);
  };

  private handleMapLoad = () => {
    this.uiStore.zoneStatistics.events.setIsDisabled(false);
    this.mapController.zoneStatistics?.on(
      this.mapController.zoneStatistics.events.polygonChange,
      this.uiStore.zoneStatistics.events.setZone
    );
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    this.uiStore.zoneStatistics.events.handleIsActiveChange.watch(this.handleIsActiveChange);
    this.uiStore.zoneStatistics.events.handleZoneStatisticsCloseClick.watch(this.handleZoneStatisticsClose);
  };
}
