import moment from "moment";
import { declesionHours } from "./declension-hours";

/**
 * Формартирует время для вывода
 * @param seconds - время в секундах
 */
export function formatTimeDuration(seconds: number): string {
  let timeFormat = "HHч mm мин";

  if (seconds >= 36000 && seconds % 3600 === 0) {
    const hour = declesionHours(seconds / 3600);
    timeFormat = `HH ${hour}`;
  } else if (seconds % 3600 === 0) {
    const hour = declesionHours(seconds / 3600);
    timeFormat = `H ${hour}`;
  } else if (seconds <= 36000 && seconds >= 3600) timeFormat = "Hч m мин";
  else if (seconds < 3600) timeFormat = "m мин";

  const time = moment().startOf("day").add(seconds, "seconds").format(timeFormat);

  return time;
}
