import mapboxgl from "mapbox-gl";
import { TRAFFIC_ID } from "../order-layers";
import Popup from "./popup";

const options = {
  layerId: TRAFFIC_ID,
};
export default class TrafficPopup extends Popup {
  constructor(map) {
    super(map, options);
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "dtm-popup",
      maxWidth: "300px",
    });
  }

  getPopupHTML(props) {
    const { speed, maxSpeed, metaIDCount, metaNameCount, metaTypeCount } = props;

    const decelerationRation = maxSpeed / speed;
    const lossRation = maxSpeed / speed - 1;
    const slowdown = (1 - speed / maxSpeed) * 100;

    const transport = [
      {
        id: "А",
        name: "Автобус",
        names: "Автобусы",
        routes: [],
      },
      {
        id: "Тм",
        name: "Трамвай",
        names: "Трамваи",
        routes: [],
      },
      {
        id: "Тб",
        name: "Троллейбус",
        names: "Троллейбусы",
        routes: [],
      },
      {
        id: "Э",
        name: "Электробус",
        names: "Электробусы",
        routes: [],
      },
    ];

    if (metaIDCount && metaNameCount && metaTypeCount) {
      const len = Math.min.apply(null, [metaIDCount, metaNameCount, metaTypeCount]);

      if (len)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < len; i++) {
          const metaName = props[`metaName_${i}`];
          const metaType = props[`metaType_${i}`];
          const indexType = transport.findIndex((el) => el.id === metaType);
          if (typeof metaName === "string" && indexType > -1)
            if (!transport[indexType].routes.some((el) => el === metaName)) {
              transport[indexType].routes.push(metaName);
            }
        }
    }

    const filteredTransport = transport.filter((el) => el.routes.length);

    const popupHTML = `<p>${transport
      .filter((el) => el.routes.length)
      .map(
        (itemTrans) =>
          `<span class='ctrl-traffic-popup-row'><span class='ctrl-traffic-popup-name'><strong>${
            itemTrans[itemTrans.routes.length === 1 ? "name" : "names"]
          }</strong></span></span> <span class='ctrl-traffic-popup-row'>${itemTrans.routes.join(", ")}</span>`
      )
      .join("<hr />")}${filteredTransport.length ? "<hr />" : ""}<span class='ctrl-traffic-popup-row'>
      <span class='ctrl-traffic-popup-name'>Средняя скорость</span>
      <span class='ctrl-traffic-popup-value'> <strong>${speed}</strong> км/ч</span>
      </span>

      <span class='ctrl-traffic-popup-row'>
      <span class='ctrl-traffic-popup-name'>Скорость свободной дороги</span>
      <span class='ctrl-traffic-popup-value'><strong>${maxSpeed}</strong> км/ч</span>
      </span>

      <span class='ctrl-traffic-popup-row'>
      <span class='ctrl-traffic-popup-name'>Замедление</span>
      <span class='ctrl-traffic-popup-value'> <strong>${decelerationRation.toFixed(1)}х</strong></span>
      </span>

      <span class='ctrl-traffic-popup-row'>
      <span class='ctrl-traffic-popup-name'>${slowdown <= 0 ? "Ускорение на" : "Замедление на"}</span>
      <span class='ctrl-traffic-popup-value'> <strong>${Math.abs(slowdown.toFixed(0))}</strong>%</span>
      </span>

      <span class='ctrl-traffic-popup-row'>
      <span class='ctrl-traffic-popup-name'>Время на заторы</span>
      <span class='ctrl-traffic-popup-value'> <strong>${Math.round(lossRation * 100)}</strong>%</span>
      </span> 
    </p>
    `;
    return popupHTML;
  }
}
