import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoadDetectorState } from "../types";
import {
  getAlternativeScoreParams,
  getDetectorScore,
  GetDetectorScoreParamsType,
  getRoadDetectorFiltersItem,
} from "api/detector-data/detector-data";

export const initialState: RoadDetectorState = {
  isActive: false,
  isRoadDetectorMode: false,
  roadDetectorFilters: [],
  activeFiltersIds: [],
  detectorScore: {
    score: 0,
    dynamicsMessage: "",
    dynamicsId: 7,
  },
  detectorIDZ: {
    score: 0,
    dynamicsMessage: "",
    dynamicsId: 7,
  },
};

const NAME = "road-detector";

export const roadDetectorSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    toggleIsActive(state) {
      state.isActive = !state.isActive;
    },
    toggleIsRoadDetectorMode(state) {
      state.isRoadDetectorMode = !state.isRoadDetectorMode;
    },
    setActiveFiltersIds(state, action: PayloadAction<{ ids: number[] }>) {
      state.activeFiltersIds = action.payload.ids;
    },
    setDetectorScore(state, action: PayloadAction<{ score: number }>) {
      state.detectorScore = { ...state.detectorScore, score: action.payload.score };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLastDetectorData.fulfilled, (state, action) => {
      /** по умолчанию все типы ТС включены */
      state.activeFiltersIds = action.payload.map((i) => i.id);
      state.roadDetectorFilters = action.payload;
    });

    builder.addCase(loadDetectorScore.fulfilled, (state, action) => {
      if (action.meta.arg.period === "last") {
        state.detectorScore = action.payload;
      } else {
        const { dynamicsId, dynamicsMessage, scoreParams, prevScoreParams } = action.payload;
        state.detectorScore = {
          score: state.detectorScore.score,
          dynamicsId,
          dynamicsMessage,
          scoreParams,
          prevScoreParams,
        };
      }
    });

    builder.addCase(loadDetectorIDZ.fulfilled, (state, action) => {
      state.detectorIDZ = { ...action.payload, dynamicsId: action.payload.dynamicsID };
    });
  },
});

export const loadLastDetectorData = createAsyncThunk(`${NAME}/loadLastDetectorData`, async () => {
  try {
    const data = await getRoadDetectorFiltersItem();
    return data.data.filter((i) => i.key > 0).map((i) => ({ name: i.name, id: i.key }));
  } catch (error) {
    throw error;
  }
});

export const loadDetectorScore = createAsyncThunk(
  `${NAME}/loadDetectorScore`,
  async (params: GetDetectorScoreParamsType) => {
    try {
      return (await getDetectorScore(params)).data;
    } catch (error) {
      throw error;
    }
  }
);

export const loadDetectorIDZ = createAsyncThunk(
  `${NAME}/loadDetectorIDZ`,
  async (params: GetDetectorScoreParamsType) => {
    try {
      return (await getAlternativeScoreParams(params)).data;
    } catch (error) {
      throw error;
    }
  }
);
