import React from "react";
import { OverSpeedMessageType } from "../../help";
import { CrossWhite, DownloadWhite, PauseCircle, Play } from "assets";
import moment from "moment";
import { Loader } from "components";
import {
  ButtonsBlock,
  FullScreenContainer,
  FullScreenHeader,
  MessageBlock,
  MiniPhoto,
  MiniPhotosBlock,
  PhotoBlock,
} from "./full-screen.styled";

type Props = {
  setIsSubscribe: (value: boolean) => void;
  isSubscribe: boolean;
  fixationPhoto?: string;
  fixationTime?: string;
  overSpeedMessage: OverSpeedMessageType;
  isFullScreenMode: boolean;
  setIsFullScreenMode: (v: boolean) => void;
  fixationNumber?: number;
};

export const FullScreen = (props: Props) => {
  const downloadPhoto = () => {
    if (props.fixationPhoto) {
      const link = document.createElement("a");
      link.href = props.fixationPhoto;
      link.download = `${props.fixationNumber || props.fixationPhoto}.png`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  };

  if (!props.isFullScreenMode) {
    return null;
  }

  return (
    <FullScreenContainer>
      <FullScreenHeader>
        <div>1/1</div>
        <ButtonsBlock>
          <DownloadWhite onClick={downloadPhoto} />
          <CrossWhite onClick={() => props.setIsFullScreenMode(false)} />
        </ButtonsBlock>
      </FullScreenHeader>
      <PhotoBlock>
        {props.fixationNumber && (
          <MessageBlock isViolation={props.overSpeedMessage.isViolations}>
            <div>{props.overSpeedMessage.message}</div>
            <div>
              {moment(props.fixationTime).format("DD MMM YYYY, HH:mm ")} - Фиксация №{props.fixationNumber}
            </div>
          </MessageBlock>
        )}
        {props.fixationPhoto ? <img src={props.fixationPhoto} alt="fixationPhoto" /> : <Loader />}
      </PhotoBlock>
      {props.fixationPhoto && (
        <MiniPhotosBlock>
          <MiniPhoto isActive>
            <img src={props.fixationPhoto} alt="fixationPhotoMini" />
            {props.isSubscribe ? (
              <PauseCircle onClick={() => props.setIsSubscribe(false)} />
            ) : (
              <Play onClick={() => props.setIsSubscribe(true)} />
            )}
          </MiniPhoto>
        </MiniPhotosBlock>
      )}
    </FullScreenContainer>
  );
};
