import { getTrafficInstance } from "api";
import { ScoreParamsOutput, TrafficScoringCityInput, TrafficScoringCitySplitInput } from "./model/scoring";

const scoringUrl = "/scoring";

const getScoringParams = (input: TrafficScoringCityInput & TrafficScoringCitySplitInput) => {
  const params = new URLSearchParams();
  if (input.from) params.set("from", input.from);
  if (input.to) params.set("to", input.to);
  if (input.day) params.set("day", input.day);
  if (input.period) params.set("period", input.period);
  if (input.data) params.set("data", input.data);
  if (input.es?.length) input.es.forEach((es) => params.append("es", String(es)));
  if (input.esSet) params.set("es-set", input.esSet);
  if (input.model) params.set("model", input.model);
  if (input.dynamics) params.set("dynamics", input.dynamics);
  if (input.split?.length) input.split.forEach((split) => params.append("split", String(split)));
  return params;
};

export const scoring = {
  /** Балл пробок в городе */
  city: async (input: TrafficScoringCityInput) => {
    const params = getScoringParams(input);
    const url = `${scoringUrl}/city`;
    const { data } = await getTrafficInstance().get<ScoreParamsOutput>(url, { params });
    return data;
  },
  alternative: async (input: TrafficScoringCityInput) => {
    const params = getScoringParams(input);
    const url = `${scoringUrl}/city/alternative`;
    const { data } = await getTrafficInstance().get<ScoreParamsOutput>(url, { params });
    return data;
  },
  /** Балл пробок в городе, разбитый по времени */
  citySplit: async (input: TrafficScoringCitySplitInput) => {
    const params = getScoringParams(input);
    const url = `${scoringUrl}/city/split`;
    const { data } = await getTrafficInstance().get(url, { params });
    return data;
  },
  citySplitCorrelation: async (input: TrafficScoringCitySplitInput, correlationDay: string) => {
    const paramsSplit = getScoringParams(input);
    const paramsCorrelation = getScoringParams({ ...input, day: correlationDay });
    const url = `${scoringUrl}/city/split`;
    const [{ data: split }, { data: correlation }] = await Promise.all([
      getTrafficInstance().get(url, { params: paramsSplit }),
      getTrafficInstance().get(url, { params: paramsCorrelation }),
    ]);

    if (!Array.isArray(split) || !Array.isArray(correlation)) return [];

    const data = split.map((period: any, indexPeriod: number) => {
      return {
        ...period,
        scores: period.scores.map((item: any, indexItem: number) => {
          const scores = correlation[indexPeriod].scores;
          const slice = scores[indexItem];
          const correlationScore = slice?.score ?? 0;

          return {
            ...item,
            correlation: correlationScore,
          };
        }),
      };
    });

    return data;
  },
};
