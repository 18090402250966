import mapboxgl from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import { AdministrativeDivisionTypes } from "types";
import { Layers } from "../../layers";
import { Layer } from "./administrative-division.layer";
import { Popup } from "./popup";
import { Events, Handlers } from "./administrative-division.events";

type Options = {
  visibility: AdministrativeDivisionTypes.Type;
};

const initialOptions: Options = { visibility: AdministrativeDivisionTypes.Type.unset };

const layers = [
  Layers.Identifiers.DISTRICT_ID,
  Layers.Identifiers.DISTRICT_ZONE_ID,
  Layers.Identifiers.DISTRICT_BORDER_ID,
  Layers.Identifiers.DISTRICT_TITLE_ID,
  Layers.Identifiers.DISTRICT_ZONE_BORDER_ID,
  Layers.Identifiers.DISTRICT_ZONE_TITLE_ID,
];

export class AdministrativeDivision extends TypedEmitter<Handlers> {
  public readonly events = Events;
  private readonly statistics: { [key: number]: AdministrativeDivisionTypes.Statistic } = {};
  private districtLayer: Layer;
  private districtZonesLayer: Layer;
  private districts: AdministrativeDivisionTypes.DistrictFeature[] = [];
  private districtZones: AdministrativeDivisionTypes.DistrictZonesFeature[] = [];
  private popup: Popup;
  private visibility = initialOptions.visibility;
  private lastPopupPosition?: mapboxgl.LngLat;

  private get isVisible() {
    return this.visibility !== AdministrativeDivisionTypes.Type.unset;
  }

  constructor(private readonly map: mapboxgl.Map, private options: Options = initialOptions) {
    super();
    this.districtLayer = new Layer(this.map, { id: Layers.Identifiers.DISTRICT_ID });
    this.districtZonesLayer = new Layer(this.map, { id: Layers.Identifiers.DISTRICT_ZONE_ID });
    this.popup = new Popup(this.map);

    this.setVisibility(this.options.visibility);
    this.subscribe();
  }

  private handleStyleLoad = () => {
    this.districtLayer.destroy();
    this.districtZonesLayer.destroy();
    this.districtLayer = new Layer(this.map, { id: Layers.Identifiers.DISTRICT_ID });
    this.districtZonesLayer = new Layer(this.map, { id: Layers.Identifiers.DISTRICT_ZONE_ID });
    this.districtLayer.setData(this.districts);
    this.districtZonesLayer.setData(this.districtZones);
    this.setVisibility(this.visibility);
  };

  private handleClick = (e: mapboxgl.MapMouseEvent) => {
    if (!this.isVisible) return;

    const feature = this.map.queryRenderedFeatures(e.point, { layers }).shift();

    if (!feature) return;

    const id = feature?.properties?.OsmId;

    if (typeof id !== "number") return;

    const statistic = this.statistics[id];

    if (!statistic) {
      this.lastPopupPosition = e.lngLat;
      return this.emit(this.events.missingStatistic, {
        id: feature?.properties?.OsmId,
        name: feature?.properties?.ShortName,
        fullName: feature.properties?.Name,
      });
    }

    this.lastPopupPosition = e.lngLat;
    this.popup.open(e.lngLat, statistic);
  };

  private handleMousemove = (e: mapboxgl.MapMouseEvent) => {
    if (!this.isVisible) return;

    const feature = this.map
      .queryRenderedFeatures(e.point, {
        layers,
      })
      .shift();

    if (!feature) {
      this.districtLayer.setActiveId(null);
      this.districtZonesLayer.setActiveId(null);
    }

    if (this.visibility === AdministrativeDivisionTypes.Type.districts) {
      this.districtLayer.setActiveId(feature?.properties?.OsmId);
    }

    if (this.visibility === AdministrativeDivisionTypes.Type.districtZones) {
      this.districtZonesLayer.setActiveId(feature?.properties?.OsmId);
    }
  };

  private subscribe = () => {
    this.map.on("click", this.handleClick);
    this.map.on("mousemove", this.handleMousemove);
    this.map.on("style.load", this.handleStyleLoad);
  };

  private unsubscribe = () => {
    this.map.off("click", this.handleClick);
    this.map.off("mousemove", this.handleMousemove);
    this.map.off("style.load", this.handleStyleLoad);
  };

  public readonly setDistricts = (features: AdministrativeDivisionTypes.DistrictFeature[]) => {
    this.districts = features;
    this.districtLayer.setData(this.districts);
  };

  public readonly setDistrictZones = (features: AdministrativeDivisionTypes.DistrictZonesFeature[]) => {
    this.districtZones = features;
    this.districtZonesLayer.setData(this.districtZones);
  };

  public readonly setVisibility = (visibility: Options["visibility"]) => {
    this.visibility = visibility;

    if (this.visibility === AdministrativeDivisionTypes.Type.unset) {
      this.popup.close();
      this.districtLayer.setLayerVisibility(false);
      return this.districtZonesLayer.setLayerVisibility(false);
    }

    this.districtLayer.setLayerVisibility(this.visibility === AdministrativeDivisionTypes.Type.districts);
    this.districtZonesLayer.setLayerVisibility(this.visibility === AdministrativeDivisionTypes.Type.districtZones);
  };

  public readonly addStatistic = (statistic?: AdministrativeDivisionTypes.Statistic) => {
    if (!statistic) return;
    this.statistics[statistic.id] = statistic;
  };

  public readonly openPopup = (id?: number) => {
    if (typeof id !== "number") return;
    const statistic = this.statistics[id];
    if (!statistic || !this.lastPopupPosition) return;
    this.popup.open(this.lastPopupPosition, statistic);
  };

  public readonly destroy = () => {
    this.popup.close();
    this.districtLayer.destroy();
    this.districtZonesLayer.destroy();
    this.unsubscribe();
  };
}
