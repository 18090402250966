import { TELEMETRY_HEATMAP_ID } from "../order-layers";
import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";

export default class RnisHeatmapLayer extends BaseLayer.Abstract<GeoJSON.Point> {
  private layerConfig: mapboxgl.HeatmapLayer = {
    id: TELEMETRY_HEATMAP_ID,
    type: "heatmap",
    source: TELEMETRY_HEATMAP_ID,
    paint: {
      "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 5, 1, 12, 2, 18, 4],
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        "rgba(33,102,172,0)",
        0.2,
        "rgb(103,169,207)",
        0.4,
        "rgb(209,229,240)",
        0.6,
        "rgb(253,219,199)",
        0.8,
        "rgb(239,138,98)",
        1,
        "rgb(178,24,43)",
      ],
      "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 5, 1, 12, 2, 18, 4],
    },
  };

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: TELEMETRY_HEATMAP_ID,
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }

  remove() {
    this.setVisibility(false);
  }
}
