import { TrafficLightProperties } from "features/ctrl-route/types";
import { preciseNumber } from "utils/precise-number";
import { shared } from "shared";
import {
  AnalysisDiagramOptions,
  AnalysisDiagramRectParams,
  AnalysisDiagramRects,
  DiagramEvent,
  DiagramMouseRegions,
  UnitsCountDiagramParams,
} from "../../../../types";
import { AnalysisCell, AnalysisRow, Incident } from "api/router/model/analysisResponse";
import { Dispatcher } from "../dispatcher";
import { GroupSelector } from "../group-selector";
import * as observer from "observer";
import * as utils from "../utils/canvas";
import * as math from "../utils/math";
import * as consts from "../utils/consts";
import * as icons from "../utils/icons";

const ImgEvents = new Image();
const blob = new Blob([icons.starIcon], { type: "image/svg+xml" });
ImgEvents.src = URL.createObjectURL(blob);

type DrawIncidents = {
  x: number;
  y: number;
  incidents: Incident[] | undefined;
};

type SpeedColor = { freeSpeed: number; speed: number };

export class DiagramRender {
  protected prevX = 0;
  protected prevY = 0;
  protected offsetX = 0;
  protected offsetY = 0;
  private stepLength = 500;
  private canvasHeight = 670;
  protected isCompare = false;
  private img?: HTMLImageElement;
  private diagramPaddingTop = 10;
  private tlsImageLoaded = false;
  protected isShowEvents = false;
  private diagramBodyHeight = 500;
  protected isDetailsPopup = false;
  protected isActiveHeatMap = false;
  private freeTrafficCategory = 100;
  private defaultMaxSpeedValue = 200;
  private maxAppearanceColorKey = 999;
  protected canvas: HTMLCanvasElement;
  protected isDiagramMouseDown = false;
  protected isUnitsCountDiagram = false;
  private tlsSelectedImageLoaded = false;
  protected appearance?: DiagramAppearance;
  protected ctx?: CanvasRenderingContext2D;
  private selectedImage?: HTMLImageElement;
  protected sidesCanvas: HTMLCanvasElement;
  protected options: AnalysisDiagramOptions;
  protected scaleX: number = consts.minScale;
  protected scaleY: number = consts.minScale;
  protected rects: AnalysisDiagramRects = {};
  protected sidesCtx?: CanvasRenderingContext2D;
  protected groupSelector: GroupSelector;
  protected crossHairSubstrate: HTMLCanvasElement;
  protected groupSelectorCanvas: HTMLCanvasElement;
  protected crosshairCtx?: CanvasRenderingContext2D;
  protected diagramBodyTop = this.diagramPaddingTop;
  protected mouseRegion = DiagramMouseRegions.outside;
  protected dispatcher: Dispatcher = new Dispatcher();
  protected previousRectangleKey: string | null = null;
  protected groupSelectorCtx?: CanvasRenderingContext2D;
  protected getXY: (e: MouseEvent) => { x: number; y: number };
  protected unitCountDiagramParams: UnitsCountDiagramParams | null = null;
  private diagramBodyBottom = this.diagramBodyTop + this.diagramBodyHeight;

  constructor(canvas: HTMLCanvasElement, options: AnalysisDiagramOptions) {
    this.options = options;
    this.canvas = canvas;
    this.crossHairSubstrate = document.createElement("canvas");
    this.sidesCanvas = document.createElement("canvas");
    this.groupSelectorCanvas = document.createElement("canvas");

    if (this.canvas.parentElement)
      this.canvas.parentElement.append(this.sidesCanvas, this.groupSelectorCanvas, this.crossHairSubstrate);

    this.canvas.width = consts.canvasWidth;
    this.canvasHeight = this.canvas.offsetHeight - 170;
    this.diagramBodyBottom = this.diagramBodyTop + this.scaledBodyHeight;
    this.canvas.height = this.canvasHeight;
    this.appearance = options.appearance;

    utils.setSettings(this.crossHairSubstrate, this.canvasHeight);
    utils.setSettings(this.sidesCanvas, this.canvasHeight);
    utils.setSettings(this.groupSelectorCanvas, this.canvasHeight);
    this.initCtx();
    this.groupSelector = new GroupSelector(this.options, {
      canvas: this.groupSelectorCanvas,
      ctx: this.groupSelectorCtx,
    });
    this.getXY = utils.getXY.bind(this, this.crossHairSubstrate);
  }

  public updateOptions(options: AnalysisDiagramOptions) {
    this.options = options;
    this.scaleX = consts.minScale;
    this.scaleY = consts.minScale;
    this.offsetX = 0;
    this.offsetY = 0;
    this.groupSelector.updateOptions(options);
    this.isUnitsCountDiagram = options.isUnitsCountDiagram;

    this.dispatcher.fire(DiagramEvent.ZOOMING_X, this.scaleX, this.offsetX);
    observer.dispatch(observer.EVENTS.ON_IS_ZOOM_TOOLTIP_CHANGE, false);
    requestAnimationFrame(() => this.draw());
  }

  protected clearTLS = () => {
    // PROMPT: calculating a block width where will be drawn a tls icon
    const iconBlockWidth = Math.abs(this.canvas.width - consts.bodyRight);
    // PROMPT: Clear icon space before redrawing. It needs for draw selected tls icon
    this.sidesCtx?.clearRect(consts.bodyRight + 1, this.diagramBodyTop, iconBlockWidth, this.scaledBodyHeight + 10);
  };

  protected clearByCtx = (ctx?: CanvasRenderingContext2D) => {
    ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  private clear = () => {
    this.clearByCtx(this.ctx);
    this.clearByCtx(this.sidesCtx);
  };

  public draw = (clear: boolean = true) => {
    if (!this.ctx || !this.crosshairCtx) return;

    clear && this.clear();
    this.isActiveHeatMap ? this.drawHeatMap() : this.drawDiagramSectors();
    this.drawDiagramAxis();
    this.drawDiagramTicks();
    this.drawTLS([], clear);
    this.drawPaddingToBlock(this.ctx);
    this.drawDiagramTotalLength();
  };

  protected initCtx = () => {
    this.ctx = utils.setDPI({
      canvas: this.canvas,
      width: consts.canvasWidth,
      height: 524,
    });
    this.crosshairCtx = utils.setDPI({
      canvas: this.crossHairSubstrate,
      width: consts.canvasWidth,
      height: 524,
    });
    this.sidesCtx = utils.setDPI({
      canvas: this.sidesCanvas,
      width: consts.canvasWidth,
      height: 524,
    });
    this.groupSelectorCtx = utils.setDPI({
      canvas: this.groupSelectorCanvas,
      width: consts.canvasWidth,
      height: 524,
    });
  };

  /**
   * Method allows to get tls Y on diagram
   * @param {TrafficLightProperties} tls - tls props object from tls list
   */
  private getTlsY = (tls: TrafficLightProperties) => {
    const tlsDistance = tls.DistanceFromStart;
    const rowsCount = this.options.data?.rowsCount || 1;
    const rowHeightPX = this.scaledBodyHeight / rowsCount;
    const partLength = Math.floor(this.options.data?.partLength || 1);
    const temp = Math.floor(tlsDistance / partLength);
    const rowPadding = temp * rowHeightPX;
    const y = this.diagramBodyTop + rowPadding + this.offsetY;

    return {
      instance: tls,
      y,
    };
  };

  protected isSelectedTls = (mouseY: number, tls: TrafficLightProperties) => {
    const tlsY = this.options?.trafficLights?.map(this.getTlsY).find((el) => el.instance.FacilityId === tls.FacilityId);

    if (!tlsY) return;

    if (Math.abs(mouseY - tlsY.y) <= 10) return this.selectedImage;
  };

  /**
   * Method allows to draw white limits on sides
   * @param ctx - canvas rendering context
   * @param startX - x start coordinate
   */
  private drawPaddingToBlock = (ctx: CanvasRenderingContext2D, startX: number = 0) => {
    ctx.save();
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(startX, 0, this.canvas.width, this.diagramPaddingTop);
    ctx.fillRect(consts.bodyLeft, this.diagramBodyBottom, consts.canvasWidth, 25);
    ctx.restore();
  };

  /**
   * Method allows to draw tls icons in right block
   * @param {(HTMLImageElement | undefined)[] | undefined} selected - array of icons for tls
   */
  protected drawTLS = (selected: (HTMLImageElement | undefined)[] = [], clear: boolean = true) => {
    if (!clear) return;
    const { trafficLights, data } = this.options;
    const rowsCount = this.options.data?.rowsCount || 1;
    const rowHeightPX = this.scaledBodyHeight / rowsCount;

    if (!trafficLights || !trafficLights.length || !data) return;

    // Method allows to get same items count in tls list
    const countOfItems = (array: TrafficLightProperties[], facilityId: number) => {
      return array.filter((tls) => tls.FacilityId === facilityId).length;
    };

    const drawTls = () => {
      this.clearTLS();
      // PROMPT: ignore list needs for collapsing traffic light duplicates
      const ignoreList: number[] = [];
      trafficLights.forEach((tls, index) => {
        if (!this.sidesCtx) return;
        const equalCount = countOfItems(trafficLights, tls.FacilityId);
        const isIgnore = ignoreList.some((id) => id === tls.FacilityId);

        if (isIgnore) return;
        if (!this.img) return;

        const defImg = selected?.[index] ? (selected[index] as HTMLImageElement) : this.img;
        const marginTop = Math.abs(rowHeightPX - defImg.height) / 4;
        const x = consts.bodyRight;
        const y = this.getTlsY(tls).y + (defImg.height > 10 ? -marginTop : marginTop);
        this.sidesCtx?.drawImage(defImg, x, y);

        if (equalCount > 1) {
          const textHeight = defImg.height;
          const textX = x + defImg.width - 1.5;
          const textY = y + textHeight / 2 + 1;
          this.sidesCtx.font = `${textHeight}px Roboto`;
          this.sidesCtx.fillText(`${equalCount}`, textX, textY);
        }

        ignoreList.push(tls.FacilityId);
      });
    };

    const loadImages = () => {
      const DOMURL = window.URL || window.webkitURL || window;
      this.img = new Image();
      this.selectedImage = new Image();

      const imgBlob = new Blob([icons.tlsSvgString], { type: "image/svg+xml" });
      const selectedImgBlob = new Blob([icons.tlsSelectedSvgString], { type: "image/svg+xml" });
      const imgUrl = DOMURL.createObjectURL(imgBlob);
      const selectedImgUrl = DOMURL.createObjectURL(selectedImgBlob);

      this.img.src = imgUrl;
      this.selectedImage.src = selectedImgUrl;
      this.img.onload = () => {
        this.tlsImageLoaded = true;
        drawTls();
      };
      this.selectedImage.onload = () => {
        this.tlsSelectedImageLoaded = true;
        drawTls();
      };
    };

    if (!this.tlsImageLoaded || !this.tlsSelectedImageLoaded) return loadImages();

    drawTls();
  };

  private sizeStar = 3;

  private get rowHeight() {
    const { data } = this.options;
    if (!data) return 0;

    return this.scaledBodyHeight / data.rowsCount;
  }

  /**
   * Method allows to check if sector rect is in bound by x
   * @param xCoordinate - x sector rect coordinate value
   * @returns boolean
   */
  private checkIsInBoundX = (xCoordinate: number) =>
    xCoordinate >= consts.bodyLeft - this.bodyColumnWidth &&
    xCoordinate < consts.bodyLeft + consts.bodyWidth + this.bodyColumnWidth;

  /**
   * Method allows to check if sector rect is in bound by y
   * @param yCoordinate - y sector rect coordinate value
   * @returns boolean
   */
  private checkIsInBoundY = (yCoordinate: number) =>
    yCoordinate >= this.diagramBodyTop - this.rowHeight && yCoordinate < this.diagramBodyTop + 500 + this.rowHeight;

  /**
   * Method allows to check if sector rect is in viewport bounds
   * @param xCoordinate - x sector rect coordinate value
   * @param yCoordinate - y sector rect coordinate value
   * @returns void
   */
  private checkIsInBounds = (xCoordinate: number, yCoordinate: number) =>
    this.checkIsInBoundX(xCoordinate) && this.checkIsInBoundY(yCoordinate);

  /**
   * Method allows to draw a diagram heatmap sectors row
   * @param row - sectors row data
   * @param rowIndex - sectors row index
   * @returns void
   */
  private drawHeatMapSectorsRow = (row: AnalysisRow, rowIndex: number) => {
    const { data, dateKeys, compareWith } = this.options;
    if (!data) return;
    const offsetY = rowIndex * this.rowHeight + this.diagramBodyTop + this.offsetY;
    const isLastRow = rowIndex + this.offsetY === data.rows.length - 1;
    const rectangleHeight = isLastRow ? this.rowHeight : this.rowHeight - 0.2;

    dateKeys.forEach((rowKey, columnIndex) => {
      const isFirstColumn = columnIndex === 0;
      const isHour = (columnIndex + 1) % math.getColumnsInHour(this.options.period) === 0;
      const offsetX = columnIndex * this.bodyColumnWidth + this.bodyLeftWithOffset;
      const isIncident = !!row?.cells?.[rowKey]?.incidents;

      if (!this.checkIsInBounds(offsetX, offsetY)) return;

      let rectangleWidth = this.bodyColumnWidth;
      const columnData = row?.cells?.[rowKey];
      const rowCompare = compareWith?.diagram?.rows[rowIndex];
      const columnCompare = rowCompare?.cells ? rowCompare?.cells[rowKey] : undefined;
      const comparing =
        columnData?.trafficCategory === 100 ? consts.defaultTrafficCategory : columnData?.trafficCategory;
      const toCompare =
        columnCompare?.trafficCategory === 100 ? consts.defaultTrafficCategory : columnCompare?.trafficCategory;
      const delta = (comparing ?? consts.defaultTrafficCategory) - (toCompare ?? consts.defaultTrafficCategory);
      const color = consts.compareColors[-delta];

      if (isHour && !isFirstColumn) rectangleWidth = this.bodyColumnWidth - 1;

      utils.drawRectangle(this.ctx, {
        x: offsetX,
        y: offsetY,
        w: rectangleWidth,
        h: rectangleHeight,
        color,
      });

      const key = math.getKeyByColumnAndRow(columnIndex, rowIndex);

      this.rects[key] = {
        data: this.getCellData(rowIndex, columnIndex),
        freeFlowSpeed: row.freeFlowSpeed,
        date: rowKey,
        x: offsetX,
        y: offsetY,
        w: rectangleWidth,
        h: rectangleHeight,
        color,
      };

      if (!isIncident || !this.isShowEvents) return;

      this.drawImageEvents(
        offsetX + (rectangleWidth - this.sizeStar) / 2,
        offsetY + (rectangleHeight - this.sizeStar) / 2
      );
    });
    this.groupSelector.updateRects(this.rects);
  };

  private drawDiagramSectorsRow = (row: AnalysisRow, rowIndex: number) => {
    const { data, dateKeys } = this.options;
    if (!data) return;
    const offsetY = rowIndex * this.rowHeight + this.diagramBodyTop + this.offsetY;
    const isLastRow = rowIndex === data.rows.length - 1;
    const rectangleHeight = isLastRow ? this.rowHeight : this.rowHeight - 0.2;
    let incidents: Incident[] = [];

    dateKeys.forEach((rowKey, columnIndex) => {
      const key = math.getKeyByColumnAndRow(columnIndex, rowIndex);
      const offsetX = columnIndex * this.bodyColumnWidth + this.bodyLeftWithOffset;
      const isFirstColumn = columnIndex === 0;
      const isHour = (columnIndex + 1) % math.getColumnsInHour(this.options.period) === 0;
      let rectangleWidth = this.bodyColumnWidth;
      let color = consts.trafficColors[-1];

      if (!this.checkIsInBounds(offsetX, offsetY)) return;
      if (isHour && !isFirstColumn) rectangleWidth = this.bodyColumnWidth - 1;
      if (row.cells) {
        const columnData = row.cells[rowKey];

        if (this.isUnitsCountDiagram) {
          color = shared.unitsCountDiagram.getColoByUnitsCount(
            this.unitCountDiagramParams,
            row.cells[rowKey]?.unitsCount ?? -1,
            true
          );
        } else {
          color = this.getColorByTraffic({
            speed: columnData?.speed,
            freeSpeed: row.freeFlowSpeed,
          });
        }

        incidents = columnData?.incidents || [];
      }

      utils.drawRectangle(this.ctx, {
        x: offsetX,
        y: offsetY,
        w: rectangleWidth,
        h: rectangleHeight,
        color,
      });

      this.rects[key] = {
        data: this.getCellData(rowIndex, columnIndex),
        freeFlowSpeed: row.freeFlowSpeed,
        date: rowKey,
        x: offsetX,
        y: offsetY,
        w: this.bodyColumnWidth,
        h: rectangleHeight,
        color,
      };

      const x = offsetX;
      const y = offsetY;

      this.drawIncidents({ x, y, incidents });
    });
  };

  private getColorByTraffic = ({ speed, freeSpeed }: SpeedColor) => {
    if (!this.appearance || !Array.isArray(this.appearance)) return consts.trafficColors[-1];

    // заглушка до правки на бэке
    const notNaNFreeRowSpeed = isNaN(freeSpeed) ? 50 : freeSpeed;

    const cellPercentage = Math.abs(speed / notNaNFreeRowSpeed) * 100;

    if (isNaN(cellPercentage)) return consts.trafficColors[-1];
    if (cellPercentage <= 0) return consts.trafficColors[4];
    if (cellPercentage >= 150) return consts.trafficColors[this.freeTrafficCategory];

    let isColorExist = false;
    const color = this.appearance.reduce((color, appearanceData, index) => {
      if (isColorExist) return color;
      if (!this.appearance) return consts.trafficColors[-1];
      const minBorder = appearanceData.value;
      const maxBorder = this.appearance[index + 1]?.value ?? this.defaultMaxSpeedValue;
      const colorKey =
        appearanceData.key === this.maxAppearanceColorKey ? this.freeTrafficCategory : appearanceData.key;

      if (cellPercentage >= minBorder && cellPercentage <= maxBorder) {
        isColorExist = true;
        return consts.trafficColors[colorKey];
      }

      return color;
    }, consts.trafficColors[-1]);

    return color;
  };

  /**
   * Method allows to draw heatmap diagram sectors
   * @returns void
   */
  private drawHeatMap = () => {
    const { data, dateKeys } = this.options;
    if (!data || !this.ctx) return;

    this.ctx.fillStyle = "#e6e6e6";
    this.ctx.fillRect(
      consts.bodyLeft,
      this.diagramBodyTop,
      this.bodyColumnWidth * dateKeys.length,
      this.scaledBodyHeight
    );

    data.rows.forEach(this.drawHeatMapSectorsRow);
  };

  /**
   * Method allows to draw diagram sectors
   * @returns void
   */
  private drawDiagramSectors = () => {
    this.options.data?.rows.forEach(this.drawDiagramSectorsRow);
    this.groupSelector.updateRects(this.rects);
  };

  /**
   * Method allows to draw total length title
   * @returns void
   */
  private drawDiagramTotalLength = () => {
    const { data } = this.options;
    if (!data || !this.crosshairCtx) return;

    const { routeLength } = data;
    const value = routeLength > 1000 ? routeLength / 1000 : routeLength;

    if (!value) return;

    this.crosshairCtx.save();
    const preciseValue = preciseNumber(value);
    const axisValue = this.formatLength(routeLength);
    const maxOffset = this.crosshairCtx.measureText(String(axisValue));
    const totalLength = routeLength <= 1000 ? `${preciseValue} м` : `${preciseValue} км`;

    this.crosshairCtx.lineWidth = 1;
    this.crosshairCtx.strokeStyle = consts.axisColor;
    this.crosshairCtx.beginPath();
    this.crosshairCtx.moveTo(consts.bodyLeft - consts.tickWidth, this.diagramBodyBottom - 1);
    this.crosshairCtx.lineTo(consts.bodyLeft, this.diagramBodyBottom - 1);
    this.crosshairCtx.stroke();
    this.crosshairCtx.clearRect(0, this.diagramBodyBottom, consts.bodyWidth + consts.bodyLeft, 25);
    this.crosshairCtx.fillStyle = consts.crossHairColor;
    this.crosshairCtx.font = 'normal normal 500 10px/1.8 "Roboto"';
    this.crosshairCtx.textAlign = "left";
    this.crosshairCtx.fillText(
      totalLength,
      consts.bodyLeft - maxOffset.width - consts.tickValueOffset,
      this.diagramBodyHeight + 10 + this.diagramBodyTop
    );
    this.crosshairCtx.closePath();
    this.crosshairCtx.restore();
  };

  /**
   * Method allows to draw diagram distance sidebar
   * @returns void
   */
  private drawDiagramTicks = () => {
    const { data } = this.options;
    if (!data || !this.sidesCtx) return;

    const { routeLength } = data;

    this.stepLength = math.getAxisStepByTotalLength(routeLength);
    const betweenStepCount = math.getBetweenStepCount(this.stepLength);
    const intermediateLength = this.stepLength / betweenStepCount;
    const segmentHeight = (intermediateLength * this.scaledBodyHeight) / routeLength;
    const totalCount = Math.ceil(this.scaledBodyHeight / segmentHeight);
    const startY = this.offsetY;

    [...new Array(totalCount)].forEach((...args) => {
      if (!this.sidesCtx) return;
      const [, i] = args;
      const value = this.formatLength(this.stepLength * (i / betweenStepCount));
      const offsetY =
        (i === 0 ? i * segmentHeight + this.diagramBodyTop + 1 : i * segmentHeight + this.diagramBodyTop) + startY;

      if (i !== 0 && offsetY < this.diagramBodyTop) return;
      if (offsetY > this.diagramBodyHeight) return;
      if ((intermediateLength * i) / routeLength >= 0.99) return;
      if (i % betweenStepCount === 0) {
        this.sidesCtx.lineWidth = 1;
        this.sidesCtx.strokeStyle = consts.axisColor;
        this.sidesCtx.beginPath();
        this.sidesCtx.moveTo(consts.bodyLeft - consts.tickWidth, offsetY);
        this.sidesCtx.lineTo(consts.bodyLeft, offsetY);
        this.sidesCtx.stroke();

        this.sidesCtx.textAlign = "end";
        this.sidesCtx.textBaseline = "middle";
        this.sidesCtx.fillStyle = consts.defaultTextColor;
        this.sidesCtx.font = 'normal normal normal 10px "Roboto"';
        this.sidesCtx.fillText(`${value}`, consts.bodyLeft - consts.tickValueOffset, offsetY);
        this.sidesCtx.closePath();
      } else {
        this.sidesCtx.lineWidth = 1;
        this.sidesCtx.strokeStyle = consts.axisColor;
        this.sidesCtx.beginPath();
        this.sidesCtx.moveTo(consts.bodyLeft - consts.tickWidth + 1, offsetY);
        this.sidesCtx.lineTo(consts.bodyLeft, offsetY);
        this.sidesCtx.stroke();
      }
    });

    this.sidesCtx.save();
    this.sidesCtx.fillStyle = "#ffffff";
    this.sidesCtx.fillRect(0, 0, consts.bodyLeft, this.diagramBodyTop - 5);
    this.sidesCtx.restore();
  };

  /**
   * Method allows to draw diagram distance axis line
   * @returns void
   */
  private drawDiagramAxis = () => {
    if (!this.ctx) return;

    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = consts.axisColor;
    this.ctx.beginPath();
    this.ctx.moveTo(consts.bodyLeft, this.diagramBodyTop);
    this.ctx.lineTo(consts.bodyLeft, this.diagramBodyTop + this.diagramBodyHeight);
    this.ctx.stroke();
  };

  /**
   * Method allows to draw diagram hovering crosshair by x, y center coordinates
   * @param xCoordinate - x center coordinate
   * @param yCoordinate - y center coordinate
   * @returns void
   */
  protected drawCrosshair = (xCoordinate: number, yCoordinate: number) => {
    if (!this.crosshairCtx) return;

    this.clearCrosshair();

    const bottom = this.diagramBodyBottom - 5;

    if (yCoordinate >= bottom) return;

    const { column, row } = this.getColumnAndRow(xCoordinate, yCoordinate);

    this.highlightSector(column, row);

    this.crosshairCtx.save();
    this.crosshairCtx.lineWidth = 1;
    this.crosshairCtx.strokeStyle = consts.crossHairColor;
    this.crosshairCtx.setLineDash([10, 6]);

    this.crosshairCtx.beginPath();
    this.crosshairCtx.moveTo(xCoordinate, this.diagramBodyTop);
    this.crosshairCtx.lineTo(xCoordinate, bottom);
    this.crosshairCtx.stroke();
    this.crosshairCtx.moveTo(consts.bodyLeft, yCoordinate);
    this.crosshairCtx.lineTo(consts.bodyRight, yCoordinate);
    this.crosshairCtx.stroke();
    this.crosshairCtx.closePath();

    this.crosshairCtx.beginPath();
    this.crosshairCtx.setLineDash([]);
    this.crosshairCtx.moveTo(xCoordinate, bottom);
    this.crosshairCtx.lineTo(xCoordinate, bottom);
    this.crosshairCtx.stroke();
    this.crosshairCtx.closePath();

    this.crosshairCtx.fillStyle = "#ffffff";
    this.crosshairCtx.fillRect(consts.bodyLeft, 0, this.blockWidth, 10);

    const time = math.getTime({
      startX: xCoordinate,
      offsetX: this.bodyLeftWithOffset,
      width: this.scaledBodyWidth,
    });
    if (time) {
      this.crosshairCtx.fillStyle = consts.crossHairColor;
      this.crosshairCtx.font = 'normal normal normal 10px "Roboto"';
      this.crosshairCtx.textAlign = "center";
      this.crosshairCtx.textBaseline = "alphabetic";
      this.crosshairCtx.fillText(`${time}`, xCoordinate, this.diagramBodyTop - 3);
    }

    const length = this.getLength(yCoordinate);
    if (length) {
      const lengthText = `${length}`;
      const lengthMeasure = this.crosshairCtx.measureText(lengthText);
      this.crosshairCtx.fillStyle = consts.crossHairColor;
      this.crosshairCtx.font = 'normal normal normal 10px "Roboto"';
      this.crosshairCtx.textAlign = "start";
      this.crosshairCtx.textBaseline = "middle";
      this.crosshairCtx.fillText(lengthText, consts.bodyLeft - lengthMeasure.width - 3, yCoordinate);
    }
    this.crosshairCtx.restore();
    this.drawDiagramTotalLength();
  };

  private get blockWidth() {
    return this.canvas.width - (consts.bodyLeft + Math.abs(this.canvas.width - consts.bodyRight));
  }

  protected drawCrosshairStoppers = () => {
    if (!this.crosshairCtx) return;

    this.crosshairCtx.save();
    this.crosshairCtx.fillStyle = "#ffffff";
    this.crosshairCtx.fillRect(
      consts.bodyLeft,
      this.diagramBodyBottom - 4,
      this.blockWidth,
      Math.abs(this.canvas.height - this.diagramBodyBottom)
    );
    this.crosshairCtx.restore();
  };

  protected clearCrosshair = () => {
    this.crosshairCtx?.clearRect(0, 0, consts.canvasWidth, 525);
    this.drawDiagramTotalLength();
  };

  protected handleDiagramDrag = (event: MouseEvent) => {
    if (this.scaleY === consts.minScale && this.scaleX === consts.minScale) return;

    this.clearCrosshair();

    const { x, y } = this.getXY(event);
    const deltaX = x - this.prevX;
    const deltaY = y - this.prevY;

    this.prevX = x;
    this.prevY = y;
    let offsetX = this.offsetX + deltaX;
    let offsetY = this.offsetY + deltaY;

    if (this.offsetX === offsetX && this.offsetY === offsetY) return;
    if (offsetX > 0) return;
    if (offsetY > 0) return;

    const boundX = this.scaledBodyWidth - (Math.abs(offsetX) + consts.bodyWidth);
    const boundY = this.scaledBodyHeight - (Math.abs(offsetY) + this.diagramBodyHeight);

    if (boundX <= 0 || boundY <= 0) return;

    this.offsetX = offsetX;
    this.offsetY = offsetY;

    this.dispatcher.fire(`${DiagramEvent.ZOOMING_X}${this.options?.type}`, this.scaleX, this.offsetX);
    requestAnimationFrame(() => this.draw());
  };

  private setScaleY = (e: WheelEvent) => {
    const { deltaY } = e;
    const { dateKeys } = this.options;
    const step = (dateKeys.length * 100) / this.diagramBodyHeight / 100;

    if (deltaY < 0 && this.scaleY < consts.maxScale)
      this.scaleY = this.scaleY + step < consts.maxScale ? this.scaleY + step : consts.maxScale;
    else if (deltaY > 0 && this.scaleY > consts.minScale) {
      this.scaleY = this.scaleY - step > consts.minScale ? this.scaleY - step : consts.minScale;
      this.offsetY = 0;
    }
  };

  private setScaleX = (e: WheelEvent) => {
    const { deltaY } = e;
    const { dateKeys } = this.options;
    const step = (dateKeys.length * 100) / consts.bodyWidth / 100;
    const rect = this.canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const offsetXFromStart = x - consts.bodyLeft;
    const column = (offsetXFromStart - this.offsetX) / this.scaledBodyWidth;
    const offsetByCurrentScale = column * this.scaledBodyWidth;

    if (deltaY < 0 && this.scaleX < consts.maxScale)
      this.scaleX = this.scaleX + step < consts.maxScale ? this.scaleX + step : consts.maxScale;
    else if (deltaY > 0 && this.scaleX > consts.minScale)
      this.scaleX = this.scaleX - step > consts.minScale ? this.scaleX - step : consts.minScale;

    const maxLeft = consts.bodyLeft;
    const minLeft = consts.bodyRight - this.scaledBodyWidth;

    const offsetByNewScale = column * this.scaledBodyWidth;

    let offset = Math.round(this.offsetX - (offsetByNewScale - offsetByCurrentScale));

    if (offset > 0) offset = 0;

    const bodyLeftWithOffset = consts.bodyLeft + offset;

    if (bodyLeftWithOffset >= minLeft && bodyLeftWithOffset <= maxLeft) this.offsetX = offset;
    else if (bodyLeftWithOffset > maxLeft) this.offsetX = 0;
    else if (bodyLeftWithOffset < minLeft) this.offsetX = minLeft - consts.bodyLeft;

    this.dispatcher.fire(DiagramEvent.ZOOMING_X, this.scaleX, this.offsetX);
  };

  private setOffsetX = (delta: number) => {
    if (this.scaleX <= consts.minScale) return;

    const offsetDelta = this.columnWidth;
    let newOffset = this.offsetX;

    if (delta > 0) newOffset -= offsetDelta;
    if (delta < 0) newOffset += offsetDelta;
    if (this.offsetX === newOffset) return;

    const rightBorder = this.scaledBodyWidth - (Math.abs(newOffset) + consts.bodyWidth);

    if (newOffset > 0) newOffset = 0;
    if (rightBorder <= 0) return;

    this.offsetX = newOffset;
    this.dispatcher.fire(DiagramEvent.ZOOMING_X, this.scaleX, this.offsetX);
  };

  private setOffsetY = (delta: number) => {
    if (this.scaleY <= consts.minScale) return;

    const offsetDelta = this.scaledBodyHeight / this.options.dateKeys.length;
    let newOffset = this.offsetY;

    if (delta > 0) newOffset -= offsetDelta;
    if (delta < 0) newOffset += offsetDelta;
    if (this.offsetY === newOffset) return;

    const bottomBorder = this.scaledBodyHeight - (Math.abs(newOffset) + this.diagramBodyHeight);

    if (newOffset > 0) newOffset = 0;
    if (bottomBorder <= 0) return;

    this.offsetY = newOffset;
  };

  protected handleWheel = (e: WheelEvent) => {
    this.clearCrosshair();
    if (e.ctrlKey) {
      this.setScaleY(e);
      this.setScaleX(e);
      this.dispatcher.fire(DiagramEvent.ZOOM);
      return requestAnimationFrame(() => this.draw());
    }

    if (e.shiftKey) {
      this.setOffsetX(e.deltaY);
    } else {
      this.setOffsetY(e.deltaY);
    }

    this.dispatcher.fire(DiagramEvent.ZOOM);
    requestAnimationFrame(() => this.draw());
  };

  private drawImageEvents(offsetX: number, offsetY: number) {
    if (!this.ctx) return;

    this.ctx.save();
    utils.clipByBodyWidth(this.ctx);
    this.ctx.drawImage(ImgEvents, offsetX, offsetY, this.sizeStar, this.sizeStar);
    this.ctx.restore();
  }

  protected get columnWidth() {
    return this.scaledBodyWidth / this.options.dateKeys.length;
  }

  protected getRectanglePos = (args: { column: number; row: number }) => {
    const { data } = this.options;
    const { column, row } = args;

    if (!data) throw new Error("data is undefined");

    const rowHeight = this.scaledBodyHeight / data!.rowsCount;
    const rectangleY = this.diagramBodyTop + rowHeight * row;
    const rectangleX = this.columnWidth * column + this.offsetX + consts.bodyLeft;

    return {
      rectangleX,
      rectangleY,
    };
  };

  private isDrawEvents = (incidents: Incident[] | undefined) =>
    Array.isArray(incidents) && incidents.length && this.isShowEvents;

  /**
   * Method allows to draw road incidents on diagram by x and y coordinates
   * @param {DrawIncidents} args - argumants for drawing
   * @returns void
   */
  private drawIncidents = ({ x, y, incidents }: DrawIncidents) =>
    this.isDrawEvents(incidents) &&
    this.drawImageEvents(x + (this.bodyColumnWidth - this.sizeStar) / 2, y + (this.rowHeight - this.sizeStar) / 2);

  /**
   * Method allows to draw highlighted sector by these item data
   * @param {AnalysisDiagramRectParams} item - sector data which would be highlighted
   * @returns void
   */
  private drawSector = (
    ctx: CanvasRenderingContext2D,
    { item, highlight }: { item: AnalysisDiagramRectParams; highlight: boolean }
  ) => {
    const { x, y, w, h, color } = item;

    if (!this.checkIsInsideDiagram(x, y)) return;

    const rectColor = highlight ? math.lightenColor(color, 20) : color;
    utils.drawRectangle(ctx, { x, y, w, h, color: rectColor });
  };

  /**
   * Method allows to highlight sector by indexes of column and row
   * @param {number} column - sector column index
   * @param {number} row - sector row index
   * @returns void
   */
  protected highlightSector(column: number, row: number) {
    const key = math.getKeyByColumnAndRow(column, row);
    const item = this.rects[key];

    if (!this.crosshairCtx || !this.ctx) return;
    if (!item) return;

    this.drawSector(this.crosshairCtx, { item, highlight: true });
  }

  public get bodyColumnWidth() {
    const { dateKeys } = this.options;
    return Math.round(this.scaledBodyWidth / dateKeys.length);
  }

  public get bodyLeftWithOffset() {
    return consts.bodyLeft + this.offsetX;
  }

  public get bodyTopWithOffset() {
    return this.diagramBodyTop + this.offsetY;
  }

  private get scaledBodyWidth() {
    return consts.bodyWidth * this.scaleX;
  }

  protected get scaledBodyHeight() {
    return this.diagramBodyHeight * this.scaleY;
  }

  protected getColumnAndRow = (x: number, y: number) => {
    const { dateKeys, data } = this.options;
    const column = Math.floor(((x - this.bodyLeftWithOffset) / this.scaledBodyWidth) * dateKeys.length);
    const row = Math.floor(((y - this.bodyTopWithOffset) / this.scaledBodyHeight) * data!.rowsCount);
    return { column, row };
  };

  private getCellData = (row: number, column: number): AnalysisCell | undefined => {
    const { dateKeys, data } = this.options;
    const key = dateKeys[column];

    if (!data || !data.rows || !data.rows[row] || !data.rows[row].cells || !data.rows[row].cells) return;

    return (data?.rows[row]?.cells ?? {})[key];
  };

  protected checkIsInsideDiagram = (x: number, y: number) => {
    return x >= consts.bodyLeft && x <= consts.bodyRight && y >= this.diagramBodyTop && y <= this.diagramBodyBottom;
  };

  private getLength(y: number) {
    const { data } = this.options;

    if (!data) return;

    const { routeLength } = data;

    if (!routeLength) return;

    const length = (routeLength * (y - (this.diagramBodyTop + this.offsetY))) / this.scaledBodyHeight;

    if (length > routeLength) return null;

    const digitLength = routeLength > 1000 ? length / 1000 : length;
    const digitRouteLength = routeLength > 1000 ? routeLength / 1000 : routeLength;

    return math.formatGraphTickValue(digitLength, digitRouteLength);
  }

  private formatLength(length: number) {
    const { data } = this.options;

    if (!data) return;

    const { routeLength } = data;

    if (!routeLength) return;

    if (routeLength > 1000) {
      if (this.stepLength >= 1000) return (length / 1000).toFixed(0);

      return (length / 1000).toFixed(1);
    }

    return length.toFixed(0);
  }
}
