import styled from "styled-components";
import { APP_ENV } from "app-env";

const cdn = APP_ENV.REACT_APP_DTM_MEDIA.split("integration")[0];

export const LoadingStyledComponent = styled.div`
  display: block;
  position: absolute;
  width: 10%;
  height: 10%;
  background: url(${cdn + "image/loader.gif"});
  background-position: 50% 50%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-color: transparent;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
