import TrafficProperties from "map-helpers/properties/traffic-properties";

export function getTimeStyle(item) {
  const { traffic, timePercent } = item.properties;
  const width = timePercent * 100;
  const properties = new TrafficProperties();
  const colors = properties.getTrafficColors();

  const backgroundColor = colors[traffic];
  const style = {
    height: "4px",
    backgroundColor,
    width,
  };
  return style;
}
