import React from "react";
import { RadioButton } from "../radio-button";
import "./radio-group.scss";

type RadioButtonProps = React.ComponentProps<typeof RadioButton>;
type Option = {
  uid: RadioButtonProps["uid"];
  label: RadioButtonProps["label"];
};

type Props = {
  value: Option["uid"];
  options: Option[];
  onChange: RadioButtonProps["onChange"];
};

export const RadioGroup = React.memo<Props>((props) => {
  return (
    <div className="radio-group">
      {props.options.map((option) => (
        <RadioButton
          key={option.uid}
          uid={option.uid}
          isChecked={option.uid === props.value}
          label={option.label}
          onChange={props.onChange}
        />
      ))}
    </div>
  );
});
