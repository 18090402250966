import React from "react";
import ReactDOM from "react-dom";
import { dispatch, on } from "observer";
import { EVENTS } from "observer/events";
import { getRectangleBounds } from "./utils/get-rectangle-bounds";
import { checkIntersectDocument } from "./utils/check-intersect-document";
import { getDistanceFromRectToPoint } from "./utils/get-distance-from-popup-to-point";
import { getIntersectedPopups } from "./utils/get-intersected-popups";
import { getTranslateBounds } from "./utils/get-translate-bounds";
import { APP_ENV } from "../../app-env";
import { OldStoreProvider } from "../../old-store";
import { Store } from "../../store";
import { IconsGlobalStyled } from "../../styles/IconsGlobalStyled";
import { Authentication } from "@megapolis/react-auth";

export enum Warehouse {
  speedcam = "speedcam",
  camera = "camera",
  tls = "tls",
  detector = "detector",
  roadDetector = "roadDetector",
}

export type WarehousType = keyof typeof Warehouse;

export interface AddPopup {
  component: React.ReactElement;
  lngLat: mapboxgl.LngLat;
  id: string;
  type: WarehousType;
  itemId: number;
}

export type Popup = {
  element: HTMLDivElement;
  translateX: number;
  translateY: number;
  type: WarehousType;
  lngLat: mapboxgl.LngLat;
  width: number;
  height: number;
  maxHeight: number;
  itWasMoved?: boolean;
};

interface GetTranslate {
  lngLat: mapboxgl.LngLat;
  height: number;
  width: number;
  maxHeight: number;
  id: string;
  type: WarehousType;
}

interface GetRectangleParams {
  translateX: number;
  translateY: number;
  width: number;
  maxHeight: number;
  id: string;
}

interface GetClosestParamsInRange {
  x: number;
  y: number;
  type: WarehousType;
  width: number;
  translateY: number;
  maxHeight: number;
  id: string;
}

export type RectangleParams = {
  isCanFit: boolean;
  isIntersectDocument: boolean;
  intersectedPopups: Popup[];
  coordinates: { translateX: number; translateY: number };
};

interface GetPopupClosestToPoint {
  params: RectangleParams[];
  x: number;
  y: number;
  type: WarehousType;
}

export type Popups = Map<string, Popup>;

class PopupController {
  /**
   * Список открытых попапов
   */
  private popups: Popups = new Map<string, Popup>();
  /**
   * Id перетаскиваемого в данный момент попапа
   */
  private draggableId: string | null = null;
  /**
   * Координаты для перетаскивания
   */
  private prevX: number | null = null;
  private prevY: number | null = null;
  /**
   * Отступ от границ окна
   */
  private documentPadding = 70;
  /**
   * Деолтные размеры попапов, разного типа
   */
  private popupSize = {
    [Warehouse.speedcam]: { width: 320, maxHeight: 650, height: 583 },
    [Warehouse.camera]: { width: 320, height: 315, maxHeight: 350 },
    [Warehouse.tls]: { width: 320, height: 370, maxHeight: 380 },
    [Warehouse.detector]: { width: 320, height: 81, maxHeight: 450 },
    [Warehouse.roadDetector]: { width: 500, height: 81, maxHeight: 450 },
  };
  /**
   * Resize Observer, используется для автоматической подстройки размещения объекта,
   * при подгрузке новых данных
   */
  private resizeObserver: any;
  /**
   * Список классов DOM элементов, для которых перетаскивание не будет работать.
   * В основном это интерактивные элементы для которых срабатывает клик
   */
  private notDragableZones = [
    "new-react-map-popup__title",
    "pill",
    "new-react-map-popup__buttons",
    "camera-video",
    "tls-popup-button",
    "not-dragable-zone",
  ];
  /**
   * DOM элемент, в котором хранятся все попапы
   */
  private popupContainer: HTMLDivElement;

  constructor(private map: mapboxgl.Map) {
    // @ts-ignore
    this.resizeObserver = new ResizeObserver(this.handleResize);
    const app = document.getElementById("app");
    this.popupContainer = document.createElement("div");
    this.popupContainer.style.zIndex = "10";
    this.popupContainer.className = "map-popup-controller-container";
    app?.appendChild(this.popupContainer);
  }

  /**
   * Добавление попапа на карту
   */
  public addPopup = (options: AddPopup) => {
    const { component, id } = options;
    const popup = this.popups.get(id);
    if (popup) return this.removePopupById(id);

    const container = this.createContainer(options);
    ReactDOM.render(
      <Authentication
        authority={APP_ENV.REACT_APP_AUTH}
        scope={APP_ENV.REACT_APP_AUTH_SCOPE}
        client_id={APP_ENV.REACT_APP_AUTH_CLIENT_ID}>
        <OldStoreProvider>
          <Store>
            <IconsGlobalStyled />
            {component}
          </Store>
        </OldStoreProvider>
      </Authentication>,
      container
    );
  };

  /**
   * Удаление попапа по id
   */
  public removePopupById = (id: string) => {
    const popup = this.popups.get(id);
    if (!popup) return;
    ReactDOM.unmountComponentAtNode(popup.element);
    this.resizeObserver.unobserve(popup.element);
    popup.element.remove();
    this.popups.delete(id);
  };

  /**
   * Удаление всех попапов указанного типа
   */
  public removePopupByType = (type: WarehousType) => {
    for (const [id, popup] of this.popups) if (popup.type === type) this.removePopupById(id);
  };

  public handleFullScreenMode = (id: string, isFullScreen: boolean) => {
    const popup = this.popups.get(id);
    if (popup && isFullScreen) {
      popup.element.style.transform = `translate(0px, 0px)`;
    }
    if (popup && !isFullScreen) {
      popup.element.style.transform = `translate(${popup.translateX}px, ${popup.translateY}px)`;
    }
  };

  private get iconOffset() {
    const zoom = Math.round(this.map.getZoom());
    if (zoom <= 10) return 10;
    switch (zoom) {
      case 11:
        return 12;
      case 12:
        return 14;
      case 13:
        return 16;
      case 14:
        return 18;
      case 15:
        return 20;
      case 16:
        return 22;
      default:
        return 25;
    }
  }

  private dragStart = (id: string, e: MouseEvent) => {
    const isNotDragable = this.checkDragableZone(e);
    if (isNotDragable) return;
    const popup = this.popups.get(id);
    if (!popup) return;
    this.draggableId = id;
    popup.element.style.cursor = "grabbing";
    popup.itWasMoved = true;
    this.popupContainer.appendChild(popup.element);
    document.addEventListener("mousemove", this.handleDragMove);
    document.addEventListener("mouseup", this.handleDragUp);
  };

  private checkDragableZone = (e: MouseEvent) => {
    const path = e.composedPath();
    return !!path.find((el) => {
      return this.notDragableZones.find((cl) => {
        // @ts-ignore
        return el?.classList?.contains(cl);
      });
    });
  };

  private handleDragMove = ({ x, y }: MouseEvent) => {
    if (this.prevX && this.prevY) {
      const offsetX = x - this.prevX;
      const offsetY = y - this.prevY;
      if (!this.draggableId) return;
      const popup = this.popups.get(this.draggableId);
      if (!popup) return;
      const { translateX, translateY, width, height } = popup;
      const translateWithOffsetY = translateY + offsetY;
      const translateWithOffsetX = translateX + offsetX;
      const { x: newOffsetX, y: newOffsetY } = getTranslateBounds({
        width,
        height,
        translateX: translateWithOffsetX,
        translateY: translateWithOffsetY,
      });
      popup.element.style.transform = `translate(${newOffsetX}px, ${newOffsetY}px)`;
      popup.translateX = newOffsetX;
      popup.translateY = newOffsetY;
    }
    this.prevX = x;
    this.prevY = y;
  };

  private handleDragUp = () => {
    if (!this.draggableId) return;
    const popup = this.popups.get(this.draggableId);
    if (!popup) return;
    popup.element.style.cursor = "grab";
    this.draggableId = null;
    this.prevX = null;
    this.prevY = null;
    document.removeEventListener("mousemove", this.handleDragMove);
    document.removeEventListener("mouseup", this.handleDragUp);
  };

  private handleResize = (entries: any) => {
    for (const entry of entries) {
      const id = entry.target.id;
      const popup = this.popups.get(id);
      if (!popup) return;
      const height = entry.contentRect.height;
      popup.height = height;
      if (popup.itWasMoved) return;
      const { lngLat, maxHeight, width, type } = popup;
      const { translateY, translateX } = this.getTranslate({ lngLat, width, height, maxHeight, id, type });
      popup.element.style.transform = `translate(${translateX}px, ${translateY}px)`;
      popup.translateY = translateY;
      popup.translateX = translateX;
    }
  };

  private handleMouseEnter = ({ id, type }: { id: number | string; type: WarehousType }) => {
    dispatch(EVENTS.MAP_POPUP_ENTER, { id, type });
  };

  private handleMouseLeave = ({ id, type }: { id: number | string; type: WarehousType }) => {
    dispatch(EVENTS.MAP_POPUP_LEAVE, { id, type });
  };

  private createContainer = ({ id, type, lngLat, itemId }: AddPopup) => {
    const { width, height, maxHeight } = this.popupSize[type];
    const { translateY, translateX } = this.getTranslate({ lngLat, width, height, maxHeight, id, type });
    const element = document.createElement("div");
    element.style.position = "absolute";
    // element.style.zIndex = '1000';
    element.style.top = "0";
    element.style.left = "0";
    element.style.cursor = "grab";
    element.style.transform = `translate(${translateX}px, ${translateY}px)`;
    element.addEventListener("mousedown", (e) => this.dragStart(id, e));
    element.addEventListener("mouseenter", () => this.handleMouseEnter({ id: itemId, type }));
    element.addEventListener("mouseleave", () => this.handleMouseLeave({ id: itemId, type }));
    element.id = id;
    this.popupContainer.appendChild(element);
    this.popups.set(id, { element, type, translateX, translateY, lngLat, width, height, maxHeight });
    this.resizeObserver.observe(element);
    return element;
  };

  private getTranslate({ lngLat, height, width, maxHeight, id, type }: GetTranslate) {
    const { x, y } = this.map.project(lngLat);
    const translateYAbove = y - height - this.iconOffset;
    const translateYBelow = y + this.iconOffset;
    const translateYSide = y - height < this.documentPadding ? this.documentPadding : y - height;
    const translateXSide = x - width / 2;
    const translateXRight = x + this.iconOffset;
    const translateXLeft = x - this.iconOffset - width;

    /** ABOVE */
    const aboveRectangle = this.getRecangleParams({
      translateX: translateXSide,
      translateY: translateYAbove,
      width,
      maxHeight: height,
      id,
    });

    if (aboveRectangle.isCanFit) return aboveRectangle.coordinates;

    /** BELOW */
    const belowRectangle = this.getRecangleParams({
      translateX: translateXSide,
      translateY: translateYBelow,
      width,
      maxHeight,
      id,
    });

    if (belowRectangle.isCanFit) return belowRectangle.coordinates;

    if (!aboveRectangle.isIntersectDocument) {
      const closestPopup = this.getClosestParamsInRange({
        x,
        y,
        id,
        type,
        width,
        maxHeight,
        translateY: translateYAbove,
      });

      if (closestPopup) return closestPopup.coordinates;
    }

    if (!belowRectangle.isIntersectDocument) {
      const closestPopup = this.getClosestParamsInRange({
        x,
        y,
        id,
        type,
        width,
        maxHeight,
        translateY: translateYBelow,
      });

      if (closestPopup) return closestPopup.coordinates;
    }

    /** RIGHT */
    const rightRectangle = this.getRecangleParams({
      translateX: translateXRight,
      translateY: translateYSide,
      width,
      maxHeight,
      id,
    });

    if (rightRectangle.isCanFit) return rightRectangle.coordinates;

    /** LEFT */
    const leftRectangle = this.getRecangleParams({
      translateX: translateXLeft,
      translateY: translateYSide,
      width,
      maxHeight,
      id,
    });

    if (leftRectangle.isCanFit) return leftRectangle.coordinates;

    /** GET OFFSET FOR POPUP */
    const popupsWithoutCurrent = [...this.popups.keys()]
      .filter((popupId) => popupId !== id)
      .map((popupId) => this.popups.get(popupId));

    const popupBounds = popupsWithoutCurrent.map((popup) => {
      // eslint-disable-next-line array-callback-return
      if (!popup) return;
      const { type, translateY, translateX } = popup;
      const { width, height } = this.popupSize[type];
      return getRectangleBounds({ x: translateX, y: translateY, width, height });
    });

    const offsets: number[] = [];

    popupBounds.forEach((popup) => {
      if (!popup) return;
      const left = popup.left - this.iconOffset - width;
      const right = popup.right + this.iconOffset;
      offsets.push(left, right);
    });

    const popupsWithOffset = offsets.map((offset) => {
      const above = this.getRecangleParams({
        translateX: offset,
        translateY: translateYAbove,
        width,
        maxHeight: height,
        id,
      });

      const below = this.getRecangleParams({
        translateX: offset,
        translateY: translateYBelow,
        width,
        maxHeight,
        id,
      });

      const side = this.getRecangleParams({
        translateX: offset,
        translateY: translateYSide,
        width,
        maxHeight,
        id,
      });

      return [above, below, side];
    });

    const params = popupsWithOffset.flat().filter((el) => el.isCanFit);

    const paramsWithOffset = this.getPopupClosestToPoint({ params, x, y, type });

    const closestParamsInWindow = this.getClosestParamsInWindow({
      x,
      y,
      id,
      type,
      width,
      maxHeight,
      translateY: translateYSide,
    });

    if (closestParamsInWindow) return closestParamsInWindow.coordinates;

    if (paramsWithOffset) return paramsWithOffset.coordinates;

    if (!aboveRectangle.isIntersectDocument) return aboveRectangle.coordinates;

    if (!belowRectangle.isIntersectDocument) return belowRectangle.coordinates;

    if (!rightRectangle.isIntersectDocument) return rightRectangle.coordinates;

    if (!leftRectangle.isIntersectDocument) return leftRectangle.coordinates;

    return { translateX: Math.round(0), translateY: Math.round(0) };
  }

  private getClosestParamsInRange({ x, y, type, width, translateY, maxHeight, id }: GetClosestParamsInRange) {
    const params = Array(64)
      .fill(1)
      .map((el, index) => {
        const translateX = x - width + index * 10;
        return this.getRecangleParams({ translateX, translateY, width, maxHeight, id });
      })
      .filter((el) => el.isCanFit);
    return this.getPopupClosestToPoint({ x, y, params, type });
  }

  private getClosestParamsInWindow({ x, y, type, width, maxHeight, id }: GetClosestParamsInRange) {
    const horizontalCount = 50;
    const verticalCount = 20;
    const horizontalStep = document.documentElement.clientWidth / horizontalCount;
    const verticalStep = document.documentElement.clientHeight / verticalCount;
    const params: RectangleParams[] = [];

    for (let i = 0; i < horizontalCount; i++)
      for (let j = 0; j < verticalCount; j++) {
        const translateX = 0 + i * horizontalStep;
        const translateY = 0 + j * verticalStep;
        const param = this.getRecangleParams({ translateX, translateY, width, maxHeight, id });
        if (param.isCanFit) params.push(param);
      }

    return this.getPopupClosestToPoint({ x, y, params, type });
  }

  private getRecangleParams({ translateX, translateY, width, maxHeight, id }: GetRectangleParams): RectangleParams {
    const rectBounds = getRectangleBounds({ x: translateX, y: translateY, width, height: maxHeight });
    const isIntersectDocument = checkIntersectDocument(rectBounds);
    const intersectedPopups = getIntersectedPopups({ rectBounds, id, popups: this.popups });
    const isCanFit = !isIntersectDocument && !intersectedPopups.length;
    return {
      isCanFit,
      intersectedPopups,
      isIntersectDocument,
      coordinates: { translateX: Math.round(translateX), translateY: Math.round(translateY) },
    };
  }

  private getPopupClosestToPoint({ x, y, params, type }: GetPopupClosestToPoint) {
    if (!params.length) return;

    const { width, height } = this.popupSize[type];

    let closestPopup = params[0];
    let minDistance = getDistanceFromRectToPoint({ rectParams: closestPopup, width, height, x, y });

    params.forEach((popup, index) => {
      if (index === 0) return;
      const distance = getDistanceFromRectToPoint({ rectParams: popup, width, height, x, y });
      if (distance < minDistance) {
        closestPopup = popup;
        minDistance = distance;
      }
    });

    return closestPopup;
  }
}

export let popupController: PopupController | undefined;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  popupController = new PopupController(map);
});
