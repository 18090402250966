import { APP_ENV } from "app-env";
import { BusStopsTypes } from "types";
import { safeFetchJson } from "../utils";

const getStopUrl = (stopId: number) => `${APP_ENV.REACT_APP_DTM_KPP_API}stop/${stopId}`;
const getBoardUrl = (stopId: number) => `${APP_ENV.REACT_APP_ASPP_API}board/stoppoint/${stopId}/boards`;
const getRouteInfoUrl = (routeId: number) => `${APP_ENV.REACT_APP_DTM_KPP_API}route/${routeId}`;

type StopResponse = { name: string; routes: { id: number; num: string }[] };
type BoardsResponse = { asipID: number }[];
type RouteInfoResponse = {
  transportType: BusStopsTypes.PopupRoute["transportType"];
  name: string;
  appearence: { backgroundColor: string };
};

export class BusStopAPI {
  public getStopInfo(stopId: number): Promise<StopResponse | Error> {
    return safeFetchJson(getStopUrl(stopId));
  }

  public getStopBoards(stopId: number): Promise<BoardsResponse | Error> {
    return safeFetchJson(getBoardUrl(stopId));
  }

  public getRouteInfo(routeId: number): Promise<RouteInfoResponse | Error> {
    return safeFetchJson(getRouteInfoUrl(routeId));
  }
}
