import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialStateFromUrl } from "utils/get-initial-state-from-url";
import * as TYPES from "../types";

export const initialState: TYPES.TelemetryState = {
  isActive: getInitialStateFromUrl("telemetry"),
  pointsData: [],
};

export const telemetrySlice = createSlice({
  name: "telemetry",
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    setPointsData(state, action: PayloadAction<GeoJSON.Feature[]>) {
      state.pointsData = action.payload;
    },
  },
});
