import getBeforeId, { ISOCHRONE_STROKE_ID } from "../order-layers";
import Layer from "./layer";

const layerId = ISOCHRONE_STROKE_ID;

export default class IsochroneLayerStroke extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  prepareData() {
    const prepareData = JSON.parse(JSON.stringify(this.data));

    prepareData.features.forEach((el) => {
      el.geometry.type = "LineString";
      el.geometry.coordinates.pop();
    });
    this.data = prepareData;
  }

  addLayer() {
    const layer = {
      id: layerId,
      type: "line",
      source: layerId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#9612de",
        "line-width": 2,
        // 'line-dasharray': [2, 2],
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  addSource() {
    if (this.data)
      this.map.addSource(layerId, {
        type: "geojson",
        data: {
          features: this.data.features,
          type: "FeatureCollection",
        },
      });
  }

  update(data) {
    this.data = data;
    this.prepareData();
    if (this.checkSource()) this.updateLayerData(this.data);
    else this.add();
  }
}
