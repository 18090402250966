import React, { memo, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import cn from "classnames";
import { useLocalState } from "./compass.hooks";
import { ReactComponent as CubeIcon } from "assets/icons/cube.svg";
import { ReactComponent as TriangleIcon } from "assets/icons/triangle.svg";

import "./compass.scss";

const baseClass = "dtm-map-compass";
const outerClass = `${baseClass}__outer`;
const triangleClass = `${baseClass}__outer__triangle`;
const innerBaseClass = `${baseClass}__inner`;

type BearingPayload = { bearing: number };

type Props = {
  bearing: number;
  isVolumetric: boolean;
  onVolumetricClick: React.MouseEventHandler<HTMLButtonElement>;
  onBearingChange: ({ bearing }: BearingPayload) => void;
};

type State = {
  isMouseDown: boolean;
  bearing: number;
};

const initialState: State = {
  isMouseDown: false,
  bearing: 0,
};

const useInnerClass = (isVolumetric: boolean) => {
  return useMemo(() => cn(innerBaseClass, { [`${innerBaseClass}_volumetric`]: isVolumetric }), [isVolumetric]);
};

const useRotateStyle = (bearing: number) => {
  return useMemo(() => ({ transform: `rotate(${bearing}deg)` }), [bearing]);
};

const getLineCornerInRadians = (line: [[number, number], [number, number]]) => {
  const [[Ax, Ay], [Bx, By]] = line;
  return Math.atan2(By - Ay, Bx - Ax);
};

const toDegrees = (radians: number) => {
  return radians * (180 / Math.PI);
};

export const Compass: React.FC<Props> = memo((props) => {
  const { isVolumetric, onVolumetricClick, onBearingChange } = props;
  const { state, dispatch } = useLocalState({ ...initialState, bearing: props.bearing });
  const volumetricButtonRef = useRef<HTMLButtonElement | null>(null);
  const rotateStyle = useRotateStyle(state.bearing);
  const innerClass = useInnerClass(isVolumetric);

  const handleOuterMouseDown = () => {
    dispatch({ isMouseDown: true });
  };

  const handleMouseup = () => {
    dispatch({ isMouseDown: false });
  };

  const handleMousemove = (event: MouseEvent) => {
    if (!state.isMouseDown) return;

    const { clientX, clientY } = event;
    const rect = volumetricButtonRef.current?.getBoundingClientRect();
    if (!rect) return;

    const { x, y } = rect;

    const myAngle = toDegrees(
      getLineCornerInRadians([
        [x + 16, y + 16],
        [clientX, clientY],
      ])
    );
    const payload = { bearing: myAngle + 90 };

    dispatch(payload);
    onBearingChange(payload);
  };

  useEffect(() => {
    if (state.bearing === props.bearing) return;
    dispatch({ bearing: props.bearing });
  }, [props.bearing]);

  useLayoutEffect(() => {
    window.addEventListener("mouseup", handleMouseup);
    window.addEventListener("mousemove", handleMousemove);

    return () => {
      window.removeEventListener("mouseup", handleMouseup);
      window.removeEventListener("mousemove", handleMousemove);
    };
  }, [handleMouseup, handleMousemove]);

  return (
    <div className={baseClass}>
      <button className={outerClass} onMouseDown={handleOuterMouseDown}>
        <div style={rotateStyle} className={triangleClass}>
          <TriangleIcon />
        </div>
      </button>
      <button ref={volumetricButtonRef} className={innerClass} onClick={onVolumetricClick}>
        <CubeIcon />
      </button>
    </div>
  );
});
