export const leftRightPadding = 8;

export const trafficType = [4, 3, 2, 1, 100];

export const defaultHandlesValue: DiagramAppearance = [
  {
    key: 4,
    value: 0,
  },
  {
    key: 4,
    label: "3",
    value: 35,
  },
  {
    key: 3,
    label: "2",
    value: 55,
  },
  {
    key: 2,
    label: "1",
    value: 65,
  },
  {
    key: 1,
    label: "100",
    value: 85,
  },
  {
    key: 999,
    value: 150,
  },
];
