import React, { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ScoreIcon, TrafficIndexIcon, Loader } from "components";
import { BusFrontIcon } from "assets";
import { RouteResponse } from "features/ctrl-route/types";
import { formatLengthRoute } from "utils/format-length-route";
import { getTimeRoute } from "utils/route-helpers";
import { routerSlice } from "features/ctrl-route/store/slice";
import { RouteInfoIcon } from "./route-info-icon";
import { getArrivalTime, getTotalPedestrianTime, useRoutes, useScores } from "./route-variant-bus.hooks";

import "./index.scss";

type Props = {
  variant: RouteResponse;
};

const baseClass = "route-variant-bus";

export const RouteVariantBus = memo<Props>(({ variant }) => {
  const dispatch = useDispatch();
  const length = formatLengthRoute(variant.length);
  const timeRoute = getTimeRoute(variant);
  const arrivalTime = useMemo(() => getArrivalTime(variant.time), [variant.time]);
  const totalPedestrianTime = useMemo(() => getTotalPedestrianTime(variant), [variant]);
  const { routes, isLoading: isRoutesLoading } = useRoutes(variant);
  const { score, trafficIndex, isLoading: isScoreLoading } = useScores(variant);
  const handleClickManeuvers = useCallback(() => {
    dispatch(routerSlice.actions.setIsShowManeuvers(true));
  }, [dispatch]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__icon`}>
        <BusFrontIcon />
      </div>
      <div>
        <div className={`${baseClass}__row`}>
          <span className={`${baseClass}__length`}>{length}</span>
          <span className={`${baseClass}__time`}>
            {timeRoute.withTraffic}({arrivalTime})
          </span>
          <div className={`${baseClass}__scores`}>
            {isScoreLoading ? (
              <Loader />
            ) : (
              <>
                <ScoreIcon isDisabled={typeof score === "number"} score={score} />
                <TrafficIndexIcon score={trafficIndex ?? 0} customClass={`${baseClass}__traffic-score`} />
              </>
            )}
          </div>
        </div>
        <div className={`${baseClass}__row`}>
          <div>в т.ч. пешком {totalPedestrianTime || 1} мин</div>
        </div>
        <div className={`${baseClass}__row`}>
          {isRoutesLoading ? (
            <Loader />
          ) : (
            <div className={`${baseClass}__transports`}>
              {routes?.map((route, index) => (
                <RouteInfoIcon key={route.routeType + index} {...route} isSeparator={index !== routes.length - 1} />
              ))}
            </div>
          )}
        </div>
        <div className={`${baseClass}__row`}>
          <button className={`${baseClass}__maneuvers`} type="button" onClick={handleClickManeuvers}>
            Посмотреть по маневрам
          </button>
        </div>
      </div>
    </div>
  );
});
