/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import * as turf from "@turf/turf";
import getImage from "../route-main/route-points/route-input-row/route-address-point/getImage";

// Стрелка маневра
const ROUTE_MANEUVER_BODY_SOURCE = "route-maneuver-body";
const ROUTE_MANEUVER_BODY_MAIN = "route-maneuver-body-main";
const ROUTE_MANEUVER_BODY_STROKE = "route-maneuver-body-stroke";
const ROUTE_MANEUVER_ARROW_SOURCE = "route-maneuver-arrow";
const ROUTE_MANEUVER_ARROW_STROKE = "route-maneuver-arrow-black";
const ROUTE_MANEUVER_ARROW_MAIN = "route-maneuver-arrow-white";

// Настройка отображения стрелки маневра
const MIN_ZOOM_MANEUVER = 15;

// Ширина пути маршрута
const SMALL_ZOOM_WIDTH_PATH = 7;
const MEDIUM_ZOOM_WIDTH_PATH = 9;
const BIG_ZOOM_WIDTH_PATH = 14;

const maneuverBodyLayerStroke = {
  id: ROUTE_MANEUVER_BODY_MAIN,
  type: "line",
  source: ROUTE_MANEUVER_BODY_SOURCE,
  minzoom: MIN_ZOOM_MANEUVER,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "black",
    "line-width": [
      "interpolate",
      ["exponential", 1.5],
      ["zoom"],
      5,
      SMALL_ZOOM_WIDTH_PATH * 1.4,
      12,
      MEDIUM_ZOOM_WIDTH_PATH * 1.4,
      18,
      BIG_ZOOM_WIDTH_PATH * 1.4,
    ],
    "line-opacity": 1,
  },
};

const maneuverBodyLayerMain = {
  id: ROUTE_MANEUVER_BODY_STROKE,
  type: "line",
  source: ROUTE_MANEUVER_BODY_SOURCE,
  minzoom: MIN_ZOOM_MANEUVER,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "white",
    "line-width": [
      "interpolate",
      ["exponential", 1.5],
      ["zoom"],
      5,
      SMALL_ZOOM_WIDTH_PATH,
      12,
      MEDIUM_ZOOM_WIDTH_PATH,
      18,
      BIG_ZOOM_WIDTH_PATH,
    ],
    "line-opacity": 1,
  },
};

// используется для определения отрисован маневр или нет
let currentCoor;

/**
 * Удаляет стрелку маневра с карты
 * @param {Object} map - карта
 */
export function removeRouteManeuver(map) {
  [
    ROUTE_MANEUVER_ARROW_MAIN,
    ROUTE_MANEUVER_ARROW_STROKE,
    ROUTE_MANEUVER_BODY_MAIN,
    ROUTE_MANEUVER_BODY_STROKE,
  ].forEach((el) => {
    const _layer = map.getLayer(el);
    if (_layer) {
      map.removeLayer(el);
    }
  });

  [ROUTE_MANEUVER_BODY_SOURCE, ROUTE_MANEUVER_ARROW_SOURCE].forEach((el) => {
    const _source = map.getSource(el);
    if (_source) {
      map.removeSource(el);
    }
  });
  currentCoor = null;
}

/**
 * Возвращает сегмент маршрута вокруг точки
 * @param {Array} routeCoor - координаты маршрута
 * @param {Array} maneuverCoor - координаты маневра
 * @param {Number} MAX_LENGTH_BACK - длина прокладываемая от координат маневра
 * @param {Number} MAX_LENGTH_NEXT - длина прокладываемая до координат маневра
 * @return {Array} - массив координат сегмента
 */
function getSegmentFromRoute(routeCoor, maneuverCoor, MAX_LENGTH_BACK, MAX_LENGTH_NEXT) {
  const indexCoor = routeCoor.findIndex((el) => el === maneuverCoor);
  const backCoors = [];
  const backLineCoor = [maneuverCoor];
  let minimalLength = 0;

  for (let i = 1; i < 20; i++) {
    const indexPointBack = indexCoor - i;

    if (routeCoor[indexPointBack]) {
      backLineCoor.push(routeCoor[indexPointBack]);
      const backLine = turf.lineString(backLineCoor);
      const lengthBackLine = turf.length(backLine);

      if (lengthBackLine < MAX_LENGTH_BACK) {
        backCoors.unshift(routeCoor[indexPointBack]);
        minimalLength = lengthBackLine;
      } else {
        const lenght = MAX_LENGTH_BACK - minimalLength;
        const nextCoor = routeCoor[indexPointBack];
        const prevCoor = routeCoor[indexPointBack + 1];
        const line = turf.lineString([prevCoor, nextCoor]);
        const pointAlong = turf.along(line, lenght);
        backCoors.unshift(pointAlong.geometry.coordinates);
        break;
      }
    }
  }

  const nextLineCoor = [maneuverCoor];
  const nextCoors = [];
  let maximalLength = 0;

  for (let j = 1; j < 20; j++) {
    const indexPointNext = indexCoor + j;

    if (routeCoor[indexPointNext]) {
      nextLineCoor.push(routeCoor[indexPointNext]);
      const nextLine = turf.lineString(nextLineCoor);
      const lengthNextLine = turf.length(nextLine);

      if (lengthNextLine < MAX_LENGTH_NEXT) {
        nextCoors.push(routeCoor[indexPointNext]);
        maximalLength = lengthNextLine;
      } else {
        const lenght = MAX_LENGTH_NEXT - maximalLength;
        const nextCoor = routeCoor[indexPointNext];
        const prevCoor = routeCoor[indexPointNext - 1];
        const line = turf.lineString([prevCoor, nextCoor]);
        const pointAlong = turf.along(line, lenght);
        nextCoors.push(pointAlong.geometry.coordinates);
        break;
      }
    }
  }

  const segmentCoords = [...backCoors, maneuverCoor, ...nextCoors];

  return segmentCoords;
}

/**
 * Добавляет стрелку маневра на карту
 * @param {Array} routeCoor - координаты маршрута
 * @param {Array} maneuverCoor - координаты маневра
 * @param {Object} map - карта
 * @param {Number} typeManeuver - тип маневра
 */
export function drawRouteManeuver(routeCoor, maneuverCoor, map, typeManeuver) {
  // Проверка отрисован маневр или нет.
  if (maneuverCoor !== currentCoor) {
    const MAX_LENGTH_BACK = 0.015;
    let MAX_LENGTH_NEXT = 0.015;

    const specialManeuvers = [11, 12, 13, 14];

    if (specialManeuvers.find((el) => el === typeManeuver)) {
      MAX_LENGTH_NEXT = 0.03;
    }

    getImage.addTriangleToMap("white", map);
    getImage.addTriangleToMap("black", map);

    const bodyCoords = getSegmentFromRoute(routeCoor, maneuverCoor, MAX_LENGTH_BACK, MAX_LENGTH_NEXT);

    const coorTriangle = bodyCoords[bodyCoords.length - 1];

    const penultPoint = turf.point(bodyCoords[bodyCoords.length - 2]);
    const lastPoint = turf.point(bodyCoords[bodyCoords.length - 1]);

    const bearing = turf.bearing(penultPoint, lastPoint);

    removeRouteManeuver(map);

    const lineSourceBody = turf.lineString(bodyCoords);

    map.addSource(ROUTE_MANEUVER_BODY_SOURCE, {
      type: "geojson",
      data: lineSourceBody,
    });

    const pointSourceTriangle = turf.point(coorTriangle);

    map.addSource(ROUTE_MANEUVER_ARROW_SOURCE, {
      type: "geojson",
      data: pointSourceTriangle,
    });

    const triangleLayerMain = {
      id: ROUTE_MANEUVER_ARROW_MAIN,
      type: "symbol",
      source: ROUTE_MANEUVER_ARROW_SOURCE,
      minzoom: MIN_ZOOM_MANEUVER,
      layout: {
        "icon-image": "route-icon-triangle-white",
        "icon-size": ["interpolate", ["exponential", 1.5], ["zoom"], 15, 0.6, 18, 1],
        "icon-anchor": "bottom",
        "icon-rotate": bearing,
        "icon-allow-overlap": true,
        "icon-offset": [0, -3],
      },
    };

    const triangleLayerStroke = {
      id: ROUTE_MANEUVER_ARROW_STROKE,
      type: "symbol",
      source: ROUTE_MANEUVER_ARROW_SOURCE,
      minzoom: MIN_ZOOM_MANEUVER,
      layout: {
        "icon-image": "route-icon-triangle-black",
        "icon-size": ["interpolate", ["exponential", 1.5], ["zoom"], 15, 0.8, 18, 1.2],
        "icon-anchor": "bottom",
        "icon-rotate": bearing,
        "icon-allow-overlap": true,
      },
    };

    map.addLayer(maneuverBodyLayerStroke);
    map.addLayer(triangleLayerStroke);
    map.addLayer(maneuverBodyLayerMain);
    map.addLayer(triangleLayerMain);
    currentCoor = maneuverCoor;
  }
}
