/* eslint-disable func-names */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from "react";
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";

const MEASURE_POINTS = "measure-points";
const MEASURE_LINE = "measure-line";
const MEASURE_POINTS_SUB = "measure-points-substrate";
const MEASURE_LINE_SUB = "measure-line-substrate";
const MEASURE_GEOJSON = "measure-geojson";

function cleanMap() {
  const { map } = this.props;
  const { popup } = this.state;

  [MEASURE_POINTS, MEASURE_LINE, MEASURE_POINTS_SUB, MEASURE_LINE_SUB].forEach((el) => {
    const layer = map.getLayer(el);
    if (layer) map.removeLayer(el);
  });

  const source = map.getSource(MEASURE_GEOJSON);
  if (source) map.removeSource(MEASURE_GEOJSON);

  popup.forEach((pop) => {
    pop.remove();
  });

  this.setState({
    points: [],
    popup: [],
    totalLength: null,
    indexPaint: 0,
    lengthSegment: [0],
  });
}

function getSegmentLength() {
  const { points, indexPaint } = this.state;
  if (points.length > 1) {
    const lineCoor = [];
    points.forEach((el) => {
      lineCoor.push([el.lng, el.lat]);
    });
    const linestring = turf.lineString(lineCoor);

    const lengthSegment = [0];

    let totalLength = 0;

    turf.segmentEach(linestring, (currentSegment) => {
      const length = turf.length(currentSegment);

      totalLength += length;

      lengthSegment.push(totalLength.toFixed(2));
    });

    this.setState({ lengthSegment, indexPaint: indexPaint + 1, totalLength });
  }
}

function updateView(render) {
  const { map } = this.props;
  const { points, lengthSegment, popup } = this.state;

  // Добавление geojson
  const geojson = {
    type: "FeatureCollection",
    features: [],
  };

  points.forEach((el, index) => {
    const point = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [el.lng, el.lat],
      },
      properties: {
        id: index,
      },
    };

    geojson.features.push(point);
  });

  const _source = map.getSource(MEASURE_GEOJSON);

  if (!_source)
    map.addSource(MEASURE_GEOJSON, {
      type: "geojson",
      data: geojson,
    });
  else _source.setData(geojson);

  // Добавление точек
  const layerPointMain = map.getLayer(MEASURE_POINTS);
  const layerPointSub = map.getLayer(MEASURE_POINTS_SUB);

  if (!layerPointSub)
    map.addLayer({
      id: MEASURE_POINTS_SUB,
      type: "circle",
      source: MEASURE_GEOJSON,
      paint: {
        "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 6, 6, 12, 5, 18, 4],
        "circle-color": "#fff",
      },
    });

  if (!layerPointMain)
    map.addLayer({
      id: MEASURE_POINTS,
      type: "circle",
      source: MEASURE_GEOJSON,
      paint: {
        "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 6, 5, 12, 4, 18, 3.5],
        "circle-color": "#fff",
        "circle-stroke-width": 2,
        "circle-stroke-color": "#838383",
      },
    });

  // Добавление линий

  if (points.length > 1) {
    const linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };

    points.forEach((el) => {
      linestring.geometry.coordinates.push([el.lng, el.lat]);
    });

    geojson.features.push(linestring);

    const _source = map.getSource(MEASURE_GEOJSON);

    if (_source) _source.setData(geojson);

    const layerLineMain = map.getLayer(MEASURE_LINE);
    const layerLineSub = map.getLayer(MEASURE_LINE_SUB);

    if (!layerLineSub)
      map.addLayer(
        {
          id: MEASURE_LINE_SUB,
          type: "line",
          source: MEASURE_GEOJSON,
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#fff",
            "line-width": ["interpolate", ["exponential", 1.5], ["zoom"], 6, 5, 12, 4, 18, 3.5],
          },
        },
        MEASURE_POINTS_SUB
      );

    if (!layerLineMain)
      map.addLayer(
        {
          id: MEASURE_LINE,
          type: "line",
          source: MEASURE_GEOJSON,
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#838383",
            "line-width": ["interpolate", ["exponential", 1.5], ["zoom"], 6, 4, 12, 3.5, 18, 3],
          },
        },
        MEASURE_POINTS_SUB
      );
  } else {
    const _layerLine = map.getLayer(MEASURE_LINE);

    if (_layerLine) map.removeLayer(MEASURE_LINE);
  }

  // Добавление попапа
  if (!render) {
    popup.forEach((pop) => {
      pop.remove();
    });

    const templePopup = [];

    points.forEach((el, index) => {
      const pop = new mapboxgl.Popup({
        closeButton: false,
        className: "instruments-measure-distance-popup",
        closeOnClick: false,
      });

      pop.setLngLat([el.lng, el.lat]).setHTML(`${lengthSegment[index]} км`).addTo(map);
      templePopup.push(pop);
    });
    this.setState({ popup: templePopup });
  }
}

function mapEventClick(e) {
  const { indexPaint, points } = this.state;
  const { map } = this.props;

  const layers = [];

  const _layer = map.getLayer(MEASURE_POINTS);

  if (_layer) layers.push(MEASURE_POINTS);

  const features = map.queryRenderedFeatures(e.point, { layers });

  if (Array.isArray(features) && features.length) {
    const feature = features.shift();
    if (feature)
      if (feature.layer.id === MEASURE_POINTS) {
        if (points.length === 1) cleanMap.call(this);
        else if (points.length === 2) {
          const filterPoints = points.filter((el, index) => index !== features[0].properties.id);
          this.setState({ points: filterPoints, indexPaint: indexPaint + 1, totalLength: null });
        } else {
          const filterPoints = points.filter((el, index) => index !== features[0].properties.id);
          this.setState({ points: filterPoints, indexPaint: indexPaint + 1 });
        }
      }
  } else this.setState({ points: [...this.state.points, e.lngLat], indexPaint: indexPaint + 1 });

  getSegmentLength.call(this);
}

function mapEventMouseMove(e) {
  const { map } = this.props;
  const _layer = map.getLayer(MEASURE_POINTS);
  let features;
  if (_layer) {
    features = map.queryRenderedFeatures(e.point, { layers: [MEASURE_POINTS] });
    if (features) map.getCanvas().style.cursor = features.length ? "pointer" : "crosshair";
  }
}

function mapNeedRender() {
  const render = true;
  updateView.call(this, render);
}

class MeasureDistance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalLength: null,
      points: [],
      lengthSegment: [0],
      indexPaint: 0,
      popup: [],
    };
  }

  componentDidMount() {
    if (typeof this.props.map === "object" && this.props.map !== null) {
      const { map } = this.props;
      map.on("click", mapEventClick.bind(this));
      map.on("mousemove", mapEventMouseMove.bind(this));
      map.on("needrenderPoints", mapNeedRender.bind(this));
      map.getCanvas().style.cursor = "crosshair";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { indexPaint } = this.state;
    if (indexPaint !== prevState.indexPaint) updateView.call(this);
  }

  componentWillUnmount() {
    const { map } = this.props;
    cleanMap.call(this);
    map.off("click", mapEventClick.bind(this));
    map.off("mousemove", mapEventMouseMove.bind(this));
    map.off("needrenderPoints", mapNeedRender.bind(this));
    map.getCanvas().style.cursor = "";
  }

  render() {
    const length = this.state.totalLength;
    return (
      <div className="instruments-measure-distance">
        <span className="instruments-measure-distance-text">
          {length ? `Общее расстояние: ${length.toFixed(2)} км` : "Укажите точки"}{" "}
        </span>
        {length && (
          <button
            className="instruments-measure-distance-clear-button"
            onClick={() => {
              cleanMap.call(this);
            }}
            type="button">
            X
          </button>
        )}
      </div>
    );
  }
}

MeasureDistance.propTypes = {
  map: PropTypes.instanceOf(Object).isRequired,
};

export default MeasureDistance;
