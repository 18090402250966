import React from "react";

import "./route-length-error.scss";

const baseClass = "route-length-error";
const headerClass = `${baseClass}__block ${baseClass}__header`;
const descriptionClass = `${baseClass}__block ${baseClass}__description`;

export const RouteLengthError: React.FC = () => {
  return (
    <div className={baseClass}>
      <span className={headerClass}>Ошибка при выполнении запроса.</span>
      <span className={descriptionClass}>Расстояние между точками меньше 50м</span>
    </div>
  );
};
