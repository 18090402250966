import React from "react";
import { v4 } from "uuid";
import { Props } from "./highways-list.types";

import "./highways-list.scss";

const highwaysListClass = "details-administrative-division__highways-list";

export const HighwaysList = (props: Props) => {
  return (
    <div className={highwaysListClass}>
      <div className={`${highwaysListClass}__title`}>{"Перечень основных магистралей"}</div>
      <div className={`${highwaysListClass}__list`}>
        {props.mainHighways?.map((road) => (
          <div key={v4()} className={`${highwaysListClass}__list-item`}>
            {road}
          </div>
        ))}
      </div>
    </div>
  );
};
