import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";
import { Layers } from "../../layers";

const getName = (layerId: string) => {
  if (layerId === Layers.Identifiers.DISTRICT_ID) return "ShortName";
  return "Name";
};

const getNameExpression = (layerId: string): mapboxgl.Expression => ["get", getName(layerId)];

const getAuxiliaryLayers = (layerId: string) => {
  const bordersLayer: mapboxgl.LineLayer = {
    id: `${layerId}-border`,
    type: "line" as const,
    source: layerId,
    layout: {},
    paint: {
      "line-color": "#4488D7",
      "line-width": 3,
      "line-opacity": 0.8,
    },
  };

  const titlesLayer: mapboxgl.SymbolLayer = {
    id: `${layerId}-title`,
    type: "symbol" as const,
    source: layerId,
    layout: {
      "text-field": getNameExpression(layerId),
    },
    paint: {
      "text-color": "#000000",
      "text-opacity": 0.7,
    },
  };

  return { bordersLayer, titlesLayer };
};

export class Layer extends BaseLayer.Abstract<GeoJSON.Polygon, GeoJSON.GeoJsonProperties> {
  private readonly borderLayerId: string;
  private readonly titleLayerId: string;

  constructor(map: mapboxgl.Map, options: BaseLayer.Types.BaseLayerOptions) {
    super(map, options);
    this.borderLayerId = `${this.options.id}-border`;
    this.titleLayerId = `${this.options.id}-title`;

    this.setLayer({
      id: this.options.id,
      type: "fill",
      source: this.options.id,
      layout: {},
      paint: {
        "fill-color": "#007EFC",
        "fill-opacity": this.getFeatureOpacity(this.activeId),
      },
    });

    const { bordersLayer, titlesLayer } = getAuxiliaryLayers(this.options.id);

    this.addSource();
    this.addLayer();
    this.addAuxiliaryLayers(bordersLayer, titlesLayer);
  }

  private getFeatureOpacity = (activeId: string | number | null): mapboxgl.Expression => [
    "case",
    ["boolean", ["==", activeId, ["get", "OsmId"]], true],
    0.3,
    0.1,
  ];

  private setLayerVisibilityById = (layerId: string, isVisible: string) => {
    const layer = this.map.getLayer(layerId);
    if (!layer) return;
    this.map.setLayoutProperty(layerId, "visibility", isVisible);
  };

  public setLayerVisibility = (isVisible: boolean) => {
    this.visibility = isVisible ? "visible" : "none";

    this.setLayerVisibilityById(this.options.id, this.visibility);
    this.setLayerVisibilityById(this.borderLayerId, this.visibility);
    this.setLayerVisibilityById(this.titleLayerId, this.visibility);
  };

  public setActiveId = (id: string | number | null) => {
    this.activeId = id;
    if (!this.activeId) {
      this.map.setPaintProperty(this.options.id, "fill-opacity", this.getFeatureOpacity(-1));
      return this;
    }

    this.map.setPaintProperty(this.options.id, "fill-opacity", this.getFeatureOpacity(this.activeId));

    return this;
  };
}
