import { useCallback, useState as useReactState } from "react";
import moment from "moment";
import { State, Props, StateSetter } from "../";
import { Mode, SelectorKey } from "../types";
import { options, select, dates } from "./utils";

const days = ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"];

type HookProps = Props & {
  state: State;
  selectRef: React.MutableRefObject<HTMLSelectElement | null>;
  setState: StateSetter;
};

/**
 * @hook Creates component local state
 */
export const useState = () =>
  useReactState<State>({
    mode: Mode.BUTTON,
    selected: options.search.value(SelectorKey.TODAY),
  });

/**
 * @hook Allows to get callbacks for buttons and selects
 * @param param0
 */
export const useCallbacks = ({ state, date, selectRef, disabled, onChange, setState }: HookProps) => {
  const handleClickNextButton = useCallback(() => {
    if (disabled) return;
    const newDate = dates.next(date);
    onChange(newDate, newDate);
    setState({ ...state, mode: Mode.BUTTON });
  }, [state, date, disabled, onChange, setState]);

  const handleClickPrevButton = useCallback(() => {
    if (disabled) return;
    const newDate = dates.prev(date);
    onChange(newDate, newDate);
    setState({ ...state, mode: Mode.BUTTON });
  }, [state, date, disabled, onChange, setState]);

  const handleClick = useCallback(() => {
    if (disabled) return;
    setState({ ...state, mode: Mode.SELECT });
    const timeout = window.setTimeout(() => {
      if (!selectRef?.current) return;

      select.style.tall(selectRef.current);
      window.clearTimeout(timeout);
    }, 0);
  }, [selectRef, state, disabled, setState]);

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!selectRef?.current) return;

      select.style.normal(selectRef.current);
      const selected = options.search.value(event.target.value as SelectorKey);
      const getDate = dates[selected?.value || ""];
      if (!getDate) return;
      const newDate = getDate(date);

      onChange(newDate, newDate);
      setState({
        ...state,
        selected,
      });
    },
    [date, state, selectRef, setState, onChange]
  );

  return {
    handleClick,
    handleChange: handleSelectChange,
    handleNext: handleClickNextButton,
    handlePrev: handleClickPrevButton,
  };
};

/**
 * @hook Helps to get functions for getting and checking date label
 * @param date - actual selected date string
 */
export const useLabel = (date: string) => {
  const numberOfDay = useCallback(() => moment(date).day(), [date]);
  const isToday = useCallback(() => moment(date).get("dayOfYear") === moment().get("dayOfYear"), [date]);
  const isYesterDay = useCallback(() => moment(date).get("dayOfYear") === moment().get("dayOfYear") - 1, [date]);
  const isBeforeYesterday = useCallback(() => moment(date).get("dayOfYear") === moment().get("dayOfYear") - 2, [date]);
  const labelOfDay = useCallback(() => days[numberOfDay()], [numberOfDay]);

  const getLabel = useCallback(() => {
    if (isToday()) return "сегодня";
    if (isYesterDay()) return "вчера";
    if (isBeforeYesterday()) return "позавчера";
    return labelOfDay();
  }, [isToday, isYesterDay, isBeforeYesterday, labelOfDay]);

  return {
    isToday,
    isYesterDay,
    isBeforeYesterday,
    getLabel,
  };
};
