import { memo } from "react";
import { ZoneStatistics } from "features";
import "./zone-statistics-group.scss";

const baseClass = "zone-statistics-group";

export const ZoneStatisticsGroup = memo(() => {
  return (
    <div className={baseClass}>
      <ZoneStatistics />
    </div>
  );
});
