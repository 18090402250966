import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialStateFromUrl } from "utils/get-initial-state-from-url";
import { FloodingState } from "../types";

export const initialState: FloodingState = {
  isActive: getInitialStateFromUrl("flooding"),
};

export const floodingSlice = createSlice({
  name: "flooding",
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
  },
});
