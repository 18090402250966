import React from "react";
import mapboxgl, { LngLat, Popup } from "mapbox-gl";
import { point as createPoint } from "@turf/turf";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { APP_ENV } from "app-env";
import * as Observer from "observer";
import { popupController, WarehousType } from "map-helpers/popups/popup-controller";
import { SPEEDCAM_LAYER_ID } from "map-helpers/order-layers";
import { SpeedcamPopup } from "../components/speedcam-popup";
import { SpeedcamBorder, SpeedcamLayer, SpeedcamVolumetricLayer } from "../map-layer";
import { CreatePopupProps } from "../types";

const filter =
  "include=assetstatus,assetstatusid,lat,lng,addresses,assetTypeId&filter=in(deleted,false) and deleted eq false and withoutFixations eq false&additionalFields=lat,lng,overspeed";

type PopupEventArgs = { id: number; type: WarehousType };

const minZoom = 15.5;
const maxPitch = 20;

export class SpeedcamController {
  private speedcamLayer?: SpeedcamLayer;
  private speedcamVolumetricLayer?: SpeedcamVolumetricLayer;
  private speedcamBorderLayer?: SpeedcamBorder;
  private fixationsPopup: Popup;
  private hovered: THREE.Intersection | null = null;

  private get tileUrl() {
    return `${
      APP_ENV.REACT_APP_DTM_WAREHOUSE_API
    }assetspeedcam/layerMVT/{z}/{x}/{y}?${filter}&calculatedVehicleStatistics=true&offsetRightSamePlace=${
      APP_ENV.REACT_APP_SAME_PLACE_OBJECTS_OFFSET || 0
    }`;
  }

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.createLayers();
    this.subscribe();
    this.update();

    this.fixationsPopup = new Popup({
      closeButton: false,
      closeOnClick: false,
      className: "route-length-error-popup__count",
    });
  }

  public get isActive() {
    const {
      speedcam: { isActive },
    } = this.store.getState();

    return isActive;
  }

  public get isVolumetric() {
    return this.map.getZoom() >= minZoom && this.map.getPitch() >= maxPitch;
  }

  private handleMapChange = () => {
    if (!this.speedcamVolumetricLayer) return;
    this.speedcamLayer?.setOpacity(!this.isVolumetric);
    this.speedcamVolumetricLayer.setIsVisible(this.isVolumetric);
  };

  private handleMousemove = (e: mapboxgl.MapMouseEvent) => {
    if (!this.isVolumetric) return;
    const hovered = this.speedcamVolumetricLayer?.raycast(e.point)?.[0];

    if (!hovered) {
      if (this.hovered) {
        // @ts-ignore
        this.hovered.object.material.opacity = 0.5;
      }
      this.hovered = null;
      this.fixationsPopup.remove();
      return this.map.triggerRepaint();
    }

    if (this.hovered) {
      // @ts-ignore
      this.hovered.object.material.opacity = 0.5;
    }

    const properties = this.speedcamVolumetricLayer?.getProperties(hovered.object.uuid);
    if (!properties) {
      return this.fixationsPopup.remove();
    }
    // @ts-ignore
    hovered.object.material.opacity = 0.7;
    this.hovered = hovered;
    this.fixationsPopup
      .setHTML(`${properties?.FixationLastHour ?? 0} ТС/час`)
      .setLngLat(e.lngLat)
      .addTo(this.map);
    this.map.triggerRepaint();
  };

  private subscribe = () => {
    this.map.on("click", this.handleClick);
    this.map.on("mousemove", this.handleMousemove);
    this.map.on("zoom", this.handleMapChange);
    this.map.on("pitch", this.handleMapChange);
    this.map.on("style.load", this.handleStyleChange);
    Observer.on(Observer.EVENTS.MAP_POPUP_ENTER, this.handlePopupEnter);
    Observer.on(Observer.EVENTS.MAP_POPUP_LEAVE, this.handlePopupLeave);
  };

  private unsubscribe = () => {
    this.map.off("click", this.handleClick);
    this.map.off("mousemove", this.handleMousemove);
    this.map.off("zoom", this.handleMapChange);
    this.map.off("pitch", this.handleMapChange);
    this.map.off("style.load", this.handleStyleChange);
    Observer.off(Observer.EVENTS.MAP_POPUP_ENTER, this.handlePopupEnter);
    Observer.off(Observer.EVENTS.MAP_POPUP_LEAVE, this.handlePopupLeave);
  };

  private handleStyleChange = () => {
    if (!this.map.isStyleLoaded()) {
      return this.map.once("idle", this.reCreateLayers);
    }

    this.reCreateLayers();
  };

  private reCreateLayers = async () => {
    await this.destroyLayers();
    this.createLayers();
  };

  private handleClick = (e: mapboxgl.MapMouseEvent) => {
    const features = this.map.queryRenderedFeatures(e.point, { layers: [SPEEDCAM_LAYER_ID] });
    const feature = features.shift();
    if (!feature) return;

    const {
      // @ts-ignore
      properties: { Id, Num, address, Lat, Lng, overspeed },
    } = feature;
    const numbOverspeed = overspeed ?? Number(overspeed);
    const lngLat = new LngLat(Lng, Lat);
    this.createPopup({ id: Id, num: Num, address, lngLat, overspeed: numbOverspeed });
    if (this.isVolumetric) {
      if (this.speedcamVolumetricLayer?.isSelected(Id)) {
        return this.speedcamVolumetricLayer?.removeSelection(Id);
      }
      const shapeUnder = this.speedcamVolumetricLayer?.raycast(e.point)?.[0];
      if (!shapeUnder) return;
      const { x, y } = shapeUnder.object.position;
      this.speedcamVolumetricLayer?.select(Id, { x, y });
      return this.speedcamBorderLayer?.setVisibility(false);
    }
    this.speedcamBorderLayer?.setData([createPoint(lngLat.toArray())]);
    this.speedcamBorderLayer?.setVisibility(true);
  };

  private handlePopupEnter = ({ type }: PopupEventArgs) => {
    if (type !== "speedcam") return;
    this.speedcamBorderLayer?.setIsHover(true);
  };

  private handlePopupLeave = ({ type }: PopupEventArgs) => {
    if (type !== "speedcam") return;
    this.speedcamBorderLayer?.setIsHover(false);
  };

  private createLayers = async () => {
    this.speedcamLayer = new SpeedcamLayer(this.map, this.tileUrl);
    this.speedcamBorderLayer = new SpeedcamBorder(this.map);
    this.speedcamVolumetricLayer = new SpeedcamVolumetricLayer(this.map);
    this.map.addLayer(this.speedcamVolumetricLayer);

    this.speedcamLayer.setVisibility(this.isActive);
    this.speedcamBorderLayer.setVisibility(this.isActive);
  };

  private destroyLayers = async () => {
    this.speedcamLayer?.destroy();
    this.speedcamBorderLayer?.destroy();
    this.speedcamLayer = undefined;
    this.speedcamBorderLayer = undefined;
  };

  private createHandlePopupClose = (id: number, containerId: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.ctrlKey) {
      popupController?.removePopupByType("speedcam");
    } else {
      popupController?.removePopupById(containerId);
    }

    this.speedcamBorderLayer?.setIsHover(false);
    this.speedcamBorderLayer?.setVisibility(false);
    this.speedcamVolumetricLayer?.removeSelection(String(id));
  };

  private createPopup({ id, num, address, lngLat, overspeed }: CreatePopupProps) {
    const containerId = `speedcam-${id}`;
    const component = (
      <SpeedcamPopup
        id={id}
        num={num}
        address={address}
        overspeed={overspeed}
        onClickClose={this.createHandlePopupClose(id, containerId)}
        handleFullScreenMode={(value: boolean) => popupController?.handleFullScreenMode(containerId, value)}
      />
    );
    popupController?.addPopup({ component, lngLat, id: containerId, type: "speedcam", itemId: id });
  }

  public update = () => {
    this.speedcamLayer?.setVisibility(this.isActive);
    this.speedcamBorderLayer?.setVisibility(this.isActive);
    if (this.speedcamVolumetricLayer) this.speedcamVolumetricLayer.setIsVisible(this.isActive && this.isVolumetric);
    if (this.isActive) return;

    popupController?.removePopupByType("speedcam");
  };

  public destroy = () => {
    this.unsubscribe();
    this.destroyLayers();
  };
}
