import React from "react";
import cn from "classnames";
import { RadioOn, RadioOff } from "assets";
import { useHandleChange } from "./radio-button.hooks";
import * as consts from "./radio-button.consts";
import type { Props } from "./radio-button.types";
import "./radio-button.scss";

export const RadioButton = React.memo<Props>((props) => {
  const { isChecked, label } = props;
  const iconContainerClass = cn(consts.iconContainerClass, isChecked && consts.checkedIconCOntainerClass);
  const { handleChange } = useHandleChange(props);

  return (
    <button className={consts.baseClass} onClick={handleChange}>
      <input type="radio" className={consts.radioButtonClass} checked={isChecked} onChange={consts.mockOnChange} />
      <div className={iconContainerClass}>{isChecked ? <RadioOn /> : <RadioOff />}</div>
      {label && <span className={consts.captionClass}>{label}</span>}
    </button>
  );
});
