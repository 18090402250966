import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import mapboxgl from "mapbox-gl";
import { useAccessToken } from "@megapolis/react-auth";
import { CompassController, Zoom } from "features";
import { setMapIsLoaded } from "features/ctrl-route/store/slice";
import { MapController } from "map-controller";
import { GlobalBroker } from "event-brokers";
import { useStoreContext } from "store";
import { hooks } from "./map-view.hooks";
import { APP_ENV } from "app-env";

export const MapView = memo(() => {
  const { store } = useStoreContext();
  const mapControllerRef = useRef<MapController | null>(null);
  const eventBroker = useRef<GlobalBroker | null>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const isShowTravelHeatmap = !Boolean(APP_ENV.REACT_APP_CONFIGURATION?.rnisServices?.analytics === "hidden");

  hooks.usePedestrianControl(mapControllerRef);

  const isLoadTravelHeatmap = store.travelHeatmap.hooks.useIsActive() && isShowTravelHeatmap;

  const setMapLoaded = (value: boolean) => {
    dispatch(setMapIsLoaded(value));
  };

  useEffect(() => {
    if (isLoadTravelHeatmap) {
      mapControllerRef.current?.createTravelHeatmap();
    } else {
      mapControllerRef.current?.travelHeatmap?.destroy();
    }
  }, [isLoadTravelHeatmap]);

  useLayoutEffect(() => {
    if (mapControllerRef.current || !store) return;
    mapControllerRef.current = new MapController({ accessToken: accessToken ?? "" });
    eventBroker.current = new GlobalBroker(mapControllerRef.current, store);

    // --- @note убрать ---
    const handleMapLoad = (map?: mapboxgl.Map) => {
      setMap(map ?? null);
      setMapLoaded(true);
    };
    mapControllerRef.current.on(mapControllerRef.current.events.load, handleMapLoad);
    // --------------------

    return () => {
      mapControllerRef.current?.off(mapControllerRef.current.events.load, handleMapLoad);
      mapControllerRef.current?.destroy();
    };
  }, [accessToken]);

  useEffect(() => {
    if (!mapControllerRef.current) return;
    mapControllerRef.current.accessToken = accessToken ?? "";
  }, [accessToken, store]);

  return (
    <>
      <div className="app-map" id="map" />
      <CompassController map={map} />
      <Zoom map={map} />
    </>
  );
});
