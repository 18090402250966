import { GeojsonLayer } from "map-helpers/new-layer/geojson-layer";

export class RouteMetroLayer extends GeojsonLayer {
  addSource(data: GeoJSON.FeatureCollection) {
    this.map.addSource(this.sourceId, {
      type: "geojson",
      data,
    });
  }

  addLayer() {
    this.map.addLayer(
      {
        id: this.layerId,
        type: "line",
        source: this.sourceId,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "color"],
          "line-opacity": 0.9,
          "line-width": 10,
        },
      },
      this.getBeforeId()
    );
  }
}
