export enum TrafficPeriod {
  Last = "last",
  Today = "today",
  Yesterday = "yesterday",
  Week = "week",
  Month = "month",
}

export enum TrafficSet {
  Default = "default",
  UseStops = "use-stops",
}

export enum TrafficData {
  Historical = "historical",
  Forecast = "forecast",
  Merged = "merged ",
}
