import React from "react";

type Props = {
  label: string;
};

export const KeyLabel = ({ label }: Props) => {
  if (!label.length) return null;

  return (
    <>
      {label}
      <br />
    </>
  );
};
