import { createEvent } from "effector";
import { State } from "./pedestrian-graph.types";

const restoreIsVisible = createEvent();
const setIsDisabled = createEvent<boolean>();
const handleIsVisibleChange = createEvent<State>();
const initializeIsVisible = createEvent<boolean>();
const handleIsVisibleClick = createEvent<React.MouseEvent<HTMLButtonElement>>();

export const events = {
  setIsDisabled,
  restoreIsVisible,
  initializeIsVisible,
  handleIsVisibleClick,
  handleIsVisibleChange,
};

export type Events = typeof events;
