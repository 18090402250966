import RouteProperties from "map-helpers/properties/route-properties";
import getBeforeId, { ROUTE_PATH_ALTERNATIVE } from "../order-layers";
import Layer from "./layer";

const layerId = ROUTE_PATH_ALTERNATIVE;

export default class RouteAlternativeLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    /** @type {mapboxgl.Map} */
    this.map = map;
    this.routeProperties = new RouteProperties();
  }

  addLayer() {
    const layer = {
      id: layerId,
      type: "line",
      source: layerId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#838383",
        "line-width": this.routeProperties.lineWidth,
        "line-opacity": 0.7,
      },
    };

    const beforeId = getBeforeId(layerId, this.map);

    this.map.addLayer(layer, beforeId);
  }

  addSource() {
    if (this.data)
      this.map.addSource(layerId, {
        type: "geojson",
        data: this.data,
        tolerance: 0.15,
      });
  }
}
