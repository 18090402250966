import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GState } from "documentations";
import { NavigationGroupeLayers } from "components";
import { cameraSlice } from "features/ctrl-camera/store/slice";
import * as consts from "./camera-button.consts";

export const CameraButton = () => {
  const dispatch = useDispatch();

  const { isActive } = useSelector((state: GState) => ({
    isActive: state.camera.isActive,
  }));

  const handleClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      dispatch(cameraSlice.actions.setIsActive(!isActive));
    },
    [dispatch, isActive]
  );

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};
