import { createEffect } from "effector";
import { shared } from "shared";
import { TransportHubsAPI } from "api";

const loadTransportHubs = createEffect(async () => {
  if (!shared.global.accessToken.value) return;
  return new TransportHubsAPI().getTransportHubsList();
});

export const effects = {
  loadTransportHubs,
};

export type Effects = typeof effects;
