import React from "react";
import cn from "classnames";
import { APP_ENV } from "app-env";
import "./external-system-icon.scss";

const baseClass = "filter-icon";
const typeClass = `${baseClass}__type`;

interface Props {
  /** Номер источника данных */
  value: number;
  /** Состояние иконки */
  isActive: boolean;
  /** Обработка клика */
  handleClick: (e: React.SyntheticEvent) => void;
}

export const ExternalSystemIcon: React.FC<Props> = ({ value, isActive, handleClick }) => {
  const className = cn(typeClass, { [`${typeClass}_disabled`]: !isActive });

  return (
    <div onClick={handleClick} className={baseClass}>
      {Boolean(value) && (
        <img className={className} src={`${APP_ENV.REACT_APP_DTM_MEDIA}transport-types/es-${value}.svg`} alt="" />
      )}
    </div>
  );
};
