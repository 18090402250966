import { getRouterInstance } from "api";

const routerUrl = "/truck-graph";

export type GraphVersion = {
  versionName: string;
  versionId: number;
  isDefault: boolean;
};

export type Edge = {
  edgeId?: number;
  osmId: number;
  osmVersion: number;
};

export const truckGraph = {
  getVersions: async () => {
    const url = `${routerUrl}/versions`;
    const { data } = await getRouterInstance().get<GraphVersion[]>(url);
    return data;
  },
  getEdges: async (versionId: number) => {
    const params = new URLSearchParams();
    params.set("versionId", String(versionId));
    const url = `${routerUrl}/edges`;
    const { data } = await getRouterInstance().get<Edge[]>(url, { params });
    return data;
  },
};
