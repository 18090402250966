import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GState } from "documentations";
import { NavigationGroupeLayers } from "components";
import "./graph-compare-button.scss";
import graphCompareIcon from "assets/icons/graph-compare.svg";
import graphCompareIconActive from "assets/icons/graph-compare-active.svg";
import { useStoreContext } from "../../store";

const useIsShowGraphCompare = () => {
  const { store } = useStoreContext();
  const isTransportHubs = store.transportHubs.hooks.useIsVisible();
  const isPedestrianGraph = store.pedestrianGraph.hooks.useIsVisible();
  const isBusStops = store.busStops.hooks.useIsVisible();

  const { tlsIsActive, graphIsActive } = useSelector((state: GState) => ({
    tlsIsActive: state.tls.isActive,
    graphIsActive: state.view.graph,
  }));

  return tlsIsActive || isBusStops || graphIsActive || isPedestrianGraph || isTransportHubs;
};

const useGraphCompareTools = () => {
  const { store } = useStoreContext();
  const isVisible = store.graphCompare.hooks.useIsVisible();
  return {
    isVisible,
    setIsVisibleToggle: () => store.graphCompare.events.handleIsVisibleChange(!isVisible),
  };
};

export const GraphCompareButton = () => {
  const isShow = useIsShowGraphCompare();
  const { store } = useStoreContext();

  useEffect(() => {
    if (!isShow) {
      store.graphCompare.events.handleIsVisibleChange(false);
      store.graphCompare.events.getInit();
    }
  }, [isShow]);

  const { setIsVisibleToggle, isVisible } = useGraphCompareTools();

  if (!isShow) {
    return null;
  }

  return (
    <div className="graph-compare-button">
      <NavigationGroupeLayers
        iconNormalUrl={graphCompareIcon}
        iconActiveUrl={graphCompareIconActive}
        title={"Сравнение графов"}
        isActive={isVisible}
        onClick={setIsVisibleToggle}
      />
    </div>
  );
};
