export default class TrafficProperties {
  trafficColors = {
    [-1]: "#7f7f7f",
    1: "#0ABE0A",
    2: "#FFC203",
    3: "#F8000A",
    4: "#B00000",
    100: "#0A820A",
  };

  mutedTrafficColors = {
    [-1]: "#7f7f7f",
    1: "#6BA56B",
    2: "#F4D064",
    3: "#B2474A",
    4: "#7F2A2A",
    100: "#3A633A",
  };

  getTrafficColors() {
    return this.trafficColors;
  }

  getLineColorHistorical(isLocalDistortions) {
    if (!isLocalDistortions) return ["get", "historicalTrafficColor"];
    return [
      "case",
      ["boolean", ["==", ["get", "forecastTraffic"], ["get", "historicalTraffic"]], true],
      "#c5c5c5",
      ["get", "historicalTrafficColor"],
    ];
  }

  getLineColorForecast(isLocalDistortions) {
    if (!isLocalDistortions) return ["get", "forecastTrafficColor"];
    return [
      "case",
      ["boolean", ["==", ["get", "forecastTraffic"], ["get", "historicalTraffic"]], true],
      "#c5c5c5",
      ["get", "forecastTrafficColor"],
    ];
  }

  #lineColorsWithoutDefault = [
    "match",
    ["get", "traffic"],
    100,
    this.trafficColors[100],
    4,
    this.trafficColors[4],
    3,
    this.trafficColors[3],
    2,
    this.trafficColors[2],
    1,
    this.trafficColors[1],
    -1,
    this.trafficColors[-1],
  ];

  getTrafficLineColors(isEmptyPieces) {
    if (isEmptyPieces) {
      return [...this.#lineColorsWithoutDefault, "#808080"];
    }

    return [...this.#lineColorsWithoutDefault, "transparent"];
  }

  getLineColorStandart(isUnitsCountVisibility) {
    if (isUnitsCountVisibility) {
      return ["get", "unitsCountColor"];
    }

    return [...this.#lineColorsWithoutDefault, "#808080"];
  }

  getLineOffset() {
    return [
      "let",
      "offsetType",
      [
        "match",
        ["get", "oneway"],
        0,
        1,
        1,
        [
          "case",
          [
            "any",
            ["==", ["get", "highway"], "motorway"],
            ["==", ["get", "highway"], "trunk"],
            ["==", ["get", "highway"], "primary"],
          ],
          2,
          0,
        ],
        0,
      ],
      [
        "interpolate",
        ["exponential", 1.5],
        ["zoom"],
        5,
        ["match", ["var", "offsetType"], 1, 0.5, 2, 2, 0],
        12,
        ["match", ["var", "offsetType"], 1, 0.75, 2, 1, 0],
        18,
        ["match", ["var", "offsetType"], 1, 4, 2, 0, 0],
      ],
    ];
  }

  getLineOffsetByForward() {
    return ["case", ["==", ["get", "forward"], false], -3, 3];
  }

  getLineWidth() {
    return [
      "let",
      "min_zoom_big_road",
      ["match", ["get", "oneway"], 0, 1, 1, 2, 0],
      "min_zoom_mid_road",
      0,
      "min_zoom_sml_road",
      0,
      "mid_zoom_big_road",
      ["match", ["get", "oneway"], 0, 1.5, 1, 2.5, 0],
      "mid_zoom_mid_road",
      ["match", ["get", "oneway"], 0, 1.25, 1, 2, 0],
      "mid_zoom_sml_road",
      0.5,
      "max_zoom_big_road",
      ["match", ["get", "oneway"], 0, 12, 1, 24, 0],
      "max_zoom_mid_road",
      ["match", ["get", "oneway"], 0, 8, 1, 16, 0],
      "max_zoom_sml_road",
      ["match", ["get", "oneway"], 0, 6, 1, 10, 0],
      [
        "interpolate",
        ["exponential", 1.5],
        ["zoom"],
        5,
        [
          "match",
          ["get", "highway"],
          "motorway",
          ["var", "min_zoom_big_road"],
          "motorway_link",
          ["var", "min_zoom_big_road"],
          "motorway_junction",
          ["var", "min_zoom_big_road"],
          "trunk",
          ["var", "min_zoom_big_road"],
          "trunk_link",
          ["var", "min_zoom_big_road"],
          "primary",
          ["var", "min_zoom_big_road"],
          "primary_link",
          ["var", "min_zoom_mid_road"],
          "secondary",
          ["var", "min_zoom_mid_road"],
          "secondary_link",
          ["var", "min_zoom_mid_road"],
          "tertiary",
          ["var", "min_zoom_mid_road"],
          ["var", "min_zoom_sml_road"],
        ],
        12,
        [
          "match",
          ["get", "highway"],
          "motorway",
          ["var", "mid_zoom_big_road"],
          "motorway_link",
          ["var", "mid_zoom_big_road"],
          "motorway_junction",
          ["var", "mid_zoom_big_road"],
          "trunk",
          ["var", "mid_zoom_big_road"],
          "trunk_link",
          ["var", "mid_zoom_big_road"],
          "primary",
          ["var", "mid_zoom_mid_road"],
          "primary_link",
          ["var", "mid_zoom_mid_road"],
          "secondary",
          ["var", "mid_zoom_mid_road"],
          "secondary_link",
          ["var", "mid_zoom_mid_road"],
          "tertiary",
          ["var", "mid_zoom_mid_road"],
          ["var", "mid_zoom_sml_road"],
        ],
        18,
        [
          "match",
          ["get", "highway"],
          "motorway",
          ["var", "max_zoom_big_road"],
          "motorway_link",
          ["var", "max_zoom_big_road"],
          "motorway_junction",
          ["var", "max_zoom_big_road"],
          "trunk",
          ["var", "max_zoom_big_road"],
          "trunk_link",
          ["var", "max_zoom_big_road"],
          "primary",
          ["var", "max_zoom_big_road"],
          "primary_link",
          ["var", "max_zoom_mid_road"],
          "secondary",
          ["var", "max_zoom_mid_road"],
          "secondary_link",
          ["var", "max_zoom_mid_road"],
          "tertiary",
          ["var", "max_zoom_mid_road"],
          ["var", "max_zoom_sml_road"],
        ],
      ],
    ];
  }

  getLineDashArray() {
    return [0.5, 3];
  }

  getLayout() {
    return {
      "line-join": "round",
      "line-cap": "round",
    };
  }

  getPaint() {
    return {
      "line-color": this.getLineColorStandart(),
      "line-offset": this.getLineOffset(),
      "line-width": this.getLineWidth(),
    };
  }
}
