import React, { memo, useEffect, useMemo, useState } from "react";
import cn from "classnames";

import "./button-group.scss";

type Props = {
  className?: string;
  canBeOpen?: boolean;
  popupContent?: JSX.Element;
};

const baseClass = "button-group";
const itemsClass = `${baseClass}__items`;
const itemClassName = `${baseClass}__item`;
const separator = `${baseClass}__separator`;
const popupContainer = `${baseClass}__popup`;

export const ButtonGroup: React.FC<Props> = memo(({ children, className = "", canBeOpen = false, popupContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const length = useMemo(() => React.Children.toArray(children).length, [children]);
  const buttonClass = useMemo(
    () => cn(baseClass, { [`${baseClass}_open`]: isOpen, [className]: className }),
    [className, isOpen]
  );

  useEffect(() => {
    if (canBeOpen) return;
    setIsOpen(false);
  }, [canBeOpen]);

  const handleMouseEnter = () => {
    if (!canBeOpen || !popupContent) return;
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    if (!canBeOpen || !popupContent) return;
    setIsOpen(false);
  };

  const handleMousemove = () => {
    if (!canBeOpen || !popupContent) return;
    if (isOpen) return;
    setIsOpen(true);
  };

  return (
    <div
      className={buttonClass}
      onMouseMove={handleMousemove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className={itemsClass} style={{ display: "flex" }}>
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) return null;
          return (
            <>
              <div className={itemClassName}>{child}</div>
              {index !== length - 1 && <i className={separator} />}
            </>
          );
        })}
      </div>
      {popupContent && isOpen && <div className={popupContainer}>{popupContent}</div>}
    </div>
  );
});
