import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EVENTS, on } from "observer";
import { NavigationGroupeLayers } from "components";
import { GState } from "documentations";
import { toggleIsActive, weatherFetchForecast } from "old-store/weather/reducer";
import { WEATHER_ID } from "map-helpers/order-layers";
import WeatherLayer from "map-helpers/layers/weather-layer";
import * as consts from "./ctrl-weather.consts";
import "./ctrl-weather.scss";

export interface Popup {
  districtId: number;
  lat: number;
  lng: number;
}

type Props = {
  popupState: [Popup[], React.Dispatch<React.SetStateAction<Popup[]>>];
};

let weatherLayer: WeatherLayer;
let currentTime: string;
let map: mapboxgl.Map;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

const CtrlWeather = ({ popupState }: Props) => {
  const [popups, setPopups] = popupState;
  const [isDisabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const { isActive, data, to, dataIndex, type, mapInit } = useSelector(
    (state: GState) => ({
      isActive: state.weather.isActive,
      data: state.weather.data,
      timeStatus: state.router.timeStatus,
      to: state.view.selectedDay,
      dataIndex: state.weather.dataIndex,
      type: state.view.type,
      mapInit: state.router.mapIsLoaded,
    }),
    shallowEqual
  );

  /** Запрос начальных данных */
  useEffect(() => {
    let time = moment().toISOString();
    if (type !== "last") time = to;

    dispatch(weatherFetchForecast({ to: time }));
  }, []);

  /** Инициализация слоя */
  useEffect(() => {
    if (map && mapInit) {
      weatherLayer = new WeatherLayer(map);
      if (isActive) weatherLayer?.add(data);
    }
  }, [mapInit]);

  /** Подписка на клик  */
  useEffect(() => {
    const clickOnDistrickt = (e: mapboxgl.MapMouseEvent) => {
      const layer = map.getLayer(WEATHER_ID);
      if (layer) {
        const features = map.queryRenderedFeatures([e.point.x, e.point.y]);
        if (features.length > 0)
          if (features[0].layer.id === WEATHER_ID) {
            const props = features[0].properties;
            if (props) {
              const { LngLat, id } = props;
              const { lng, lat } = JSON.parse(LngLat);
              const checkExist = popups.find((el) => el.districtId === id);
              if (!checkExist)
                setPopups([
                  {
                    districtId: id,
                    lat: lng,
                    lng: lat,
                  },
                ]);
            }
          } else setPopups([]);
        else setPopups([]);
      }
    };
    if (map && isActive) map.on("click", clickOnDistrickt);
    return () => {
      if (map) map.off("click", clickOnDistrickt);
    };
  }, [mapInit, isActive]);

  /** Включение и отключение слоя */
  useEffect(() => {
    const addLayer = () => {
      weatherLayer.add(data);
    };
    const removeLayer = () => {
      setPopups([]);
      weatherLayer.remove();
    };
    if (isActive && weatherLayer) addLayer();

    if (!isActive && weatherLayer) removeLayer();
  }, [isActive]);

  /** Обновление данных */
  useEffect(() => {
    let time = moment().toISOString();
    if (type !== "last") time = to;

    if (isActive && currentTime !== time) dispatch(weatherFetchForecast({ to: time }));

    const now = moment(to).valueOf();
    const range5day = moment().add(5, "day").valueOf();

    if (now > range5day) setDisabled(true);
    else setDisabled(false);

    currentTime = time;
  }, [to, type]);

  /** Обновление слоя */
  useEffect(() => {
    if (isActive && weatherLayer) weatherLayer.update(data);
  }, [dataIndex]);

  /** Обновление слоя при смене стиля карты */
  useEffect(() => {
    const addLayer = () => {
      if (isActive) weatherLayer?.add(data);
    };

    if (map) map.on("style.load", addLayer);
    return () => {
      if (map) map.off("style.load", addLayer);
    };
  }, [mapInit, isActive]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      document.body.dispatchEvent(new Event("click"));
      dispatch(toggleIsActive(!isActive));
    },
    [isActive, dispatch]
  );

  return (
    <div className="ctrl-weather">
      <NavigationGroupeLayers.Item
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isDisabled={isDisabled}
        isActive={isActive}
        onClick={handleClick}
      />
    </div>
  );
};

export { CtrlWeather };
