import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { CtrlRoute } from "features";
import { SingleButton } from "components";
import "./index.scss";

const className = "route-button";

export const RouteButton = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(CtrlRoute.actionsAsync.fetchFavoriteRoadsDictionary(undefined));
  }, [dispatch]);

  const { isActive, isDisabled } = useSelector((state: GState) => ({
    isActive: state.router.isActive,
    isDisabled: !state.router.mapIsLoaded,
  }));

  const handleClick = () => {
    dispatch(CtrlRoute.routerSlice.actions.setIsActive(!isActive));
  };

  return (
    <SingleButton
      iconSvg="route"
      isActive={isActive}
      onClick={handleClick}
      title="Построение маршрута"
      type="round"
      className={className}
      disabled={isDisabled}
      buttonGroupItem
    />
  );
};
