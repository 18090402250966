import React, { useCallback, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { NavigationGroupeLayers } from "components";
import { tlsSlice } from "features/ctrl-tls";
import { graphSlice } from "features/ctrl-graph";

import { graphUpdateIsActiveAC } from "old-store/view/action-creators";
import { useStoreContext } from "store";
import * as consts from "./roads-group.consts";
import * as hooks from "./roads-group.hooks";

import "./roads-group.scss";

const baseClass = "roads-group";

export const RoadsGroup = () => {
  const items = hooks.useItems();
  const { store } = useStoreContext();
  const isTransportHubs = store.transportHubs.hooks.useIsVisible();
  const isPedestrianGraph = store.pedestrianGraph.hooks.useIsVisible();
  const isBusStops = store.busStops.hooks.useIsVisible();

  const dispatch = useDispatch();
  const { tlsIsActive, graphIsActive, isCargoActive } = useSelector((state: GState) => ({
    tlsIsActive: state.tls.isActive,
    isCargoActive: state.graph.isCargoActive,
    graphIsActive: state.view.graph,
  }));

  useEffect(() => {
    store.graphCompare.events.setSelectedGraphTypes({ road: graphIsActive, pedestrian: isPedestrianGraph });
  }, [graphIsActive, isPedestrianGraph]);

  const isActive = tlsIsActive || isBusStops || graphIsActive || isPedestrianGraph || isTransportHubs || isCargoActive;

  const handleGroupClick = useCallback(() => {
    batch(() => {
      dispatch(tlsSlice.actions.setIsActive(false));
      dispatch(graphUpdateIsActiveAC(false));
      dispatch(graphSlice.actions.setIsCargoActive(false));
      store.busStops.events.restoreIsVisible();
      store.transportHubs.events.restoreIsVisible();
      store.pedestrianGraph.events.restoreIsVisible();
    });
  }, [dispatch, store]);

  return (
    <div className={baseClass}>
      <NavigationGroupeLayers
        title={consts.buttonProps.title}
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        isActive={isActive}
        onClick={handleGroupClick}>
        {items}
      </NavigationGroupeLayers>
    </div>
  );
};
