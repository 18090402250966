import React from "react";

type PopupProps = {
  baseClass: string;
  rows: React.ReactNode[];
  buttons?: React.ReactNode[];
};

export const Popup = ({ baseClass, rows, buttons }: PopupProps) => {
  return (
    <div className={`${baseClass}-popup selector-popup`} data-testid="popup">
      {rows}
      {buttons && (
        <>
          <span className={`${baseClass}-separator`} />
          <div className={`${baseClass}-popup__buttons`}>{buttons}</div>
        </>
      )}
    </div>
  );
};
