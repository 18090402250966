import { TrafficAPI } from "../../api/traffic";
import {
  TRAFFIC_MAKE_ACTIVE,
  TRAFFIC_SCORE_LEVEL,
  TRAFFIC_SET_FAILED_ALTERNATIVE,
  TRAFFIC_SET_FAILED_SCORE,
  TRAFFIC_SET_IS_EMPTY_PIECES,
  TRAFFIC_SET_IS_TRAFFIC_MODE_TOGGLE,
  TRAFFIC_TOGGLE_ACTIVE,
  TRAFFIC_UPDATE_ACTIVE,
  TRAFFIC_UPDATE_ALTERNATIVE,
  TRAFFIC_UPDATE_SCORE,
  TRAFFIC_UPDATE_TYPE,
} from "./action-types";

export function trafficIsEmptyPiecesAC(payload) {
  return {
    type: TRAFFIC_SET_IS_EMPTY_PIECES,
    payload,
  };
}

export function trafficToggleActiveAC() {
  return {
    type: TRAFFIC_TOGGLE_ACTIVE,
  };
}

export function trafficUpdateScoreAC(payload) {
  return {
    type: TRAFFIC_UPDATE_SCORE,
    payload,
  };
}

export function trafficSetFailedScore(payload) {
  return {
    type: TRAFFIC_SET_FAILED_SCORE,
    payload,
  };
}

export function trafficFetchScoreAC(payload) {
  return async (dispatch) => {
    try {
      const score = await TrafficAPI.scoring.city(payload);
      dispatch(trafficUpdateScoreAC(score));
    } catch (error) {
      dispatch(trafficSetFailedScore());
      console.error(error, "Failed to fetch score");
    }
  };
}

export function trafficUpdateScoreValueAC(payload) {
  return {
    type: TRAFFIC_SCORE_LEVEL,
    payload,
  };
}

export function trafficUpdateActiveAC(payload) {
  return {
    type: TRAFFIC_UPDATE_ACTIVE,
    payload,
  };
}

export function trafficModeToggleAC(payload) {
  return {
    type: TRAFFIC_SET_IS_TRAFFIC_MODE_TOGGLE,
    payload,
  };
}

export function trafficUpdateTypeAC(payload) {
  return {
    type: TRAFFIC_UPDATE_TYPE,
    payload,
  };
}

export function trafficMakeActiveAC() {
  return {
    type: TRAFFIC_MAKE_ACTIVE,
  };
}

// -------

export function trafficUpdateAlternative(payload) {
  return {
    type: TRAFFIC_UPDATE_ALTERNATIVE,
    payload,
  };
}

export function trafficSetFailedAlternative(payload) {
  return {
    type: TRAFFIC_SET_FAILED_ALTERNATIVE,
    payload,
  };
}

export function trafficFetchScoreAlternative(payload) {
  return async (dispatch) => {
    try {
      const score = await TrafficAPI.scoring.alternative(payload);
      dispatch(trafficUpdateAlternative(score));
    } catch (error) {
      dispatch(trafficSetFailedAlternative());
      console.error(error, "Failed to fetch score");
    }
  };
}
