import React, { FC } from "react";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { hooks } from "./graph-compare-body.hooks";

import "./graph-compare-body.scss";

interface GraphCompareBodyPropsType {
  isGraphOpen: boolean;
}

export const GraphCompareBody: FC<GraphCompareBodyPropsType> = (props) => {
  const { graphVersions, versionsValidValues, diffBetweenValues, isStatisticsLoading, events } =
    hooks.useGetGraphVersionsTools();

  const isVersionsMoreThanOne = graphVersions.length > 1;
  const isVersionsMoreOrEqTwo = graphVersions.length >= 2;

  const versionsStatistic = hooks.useMemoVersionsStatistic({
    isStatisticsLoading,
    versionsValidValues,
    diffBetweenValues,
    isVersionsMoreThanOne,
  });

  const dateOfVersions = hooks.useMemoDateOfVersions({ graphVersions, isVersionsMoreThanOne, events });

  if (!props.isGraphOpen) {
    return null;
  }

  return (
    <div className="body-block">
      <div className="title-block">
        <div className="title-block__title">Просмотр и сравнение версий графа</div>
        <button
          className={`title-block__add-button ${isVersionsMoreOrEqTwo ? "disable" : ""}`}
          title={isVersionsMoreOrEqTwo ? undefined : "Добавить версию графа для сравнения"}
          disabled={isVersionsMoreOrEqTwo}
          onClick={() => events.createVersion()}>
          <PlusIcon />
        </button>
      </div>
      <div className="versions-block">{dateOfVersions}</div>
      <div className="title-block">
        <div className="title-block__title">Статистика</div>
      </div>
      <div className="statistic-blocks">
        <div className="statistic-block">
          {isVersionsMoreThanOne && <span className="statistic-block__label">Версии</span>}
          <span className="statistic-block__label">Вершины, ед.</span>
          <span className="statistic-block__label">Ребра, ед.</span>
          <span className="statistic-block__label">Длина УДС, км</span>
        </div>
        {versionsStatistic}
      </div>
    </div>
  );
};
