import { createStore } from "effector";
import { events } from "./graph-card.events";
import * as reducers from "./graph-card.reducers";
import { State } from "./graph-card.types";

const initialState: State = {
  isEditMode: false,
  isVisible: false,
  edgeInfo: {},
  cardData: [],
};

export const store = createStore(initialState);

store.on(events.handleIsVisibleChange, reducers.setIsVisible);
store.on(events.handleIsEditModeChange, reducers.setIsEditMode);
store.on(events.handleIsEditModeChange, reducers.setIsEditMode);
store.on(events.handleSetEdgeInfo, reducers.setEdgeInfo);
store.on(events.handleSetCardData, reducers.setCardData);
