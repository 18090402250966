import { memo } from "react";
import { Range } from "rc-slider";
import * as hooks from "./segments-opacity-slider.hooks";
import * as consts from "./segments-opacity-slider.consts";
import "./segments-opacity-slider.scss";

export const SegmentsOpacitySlider = memo(() => {
  const values = hooks.useValues();
  const labels = hooks.useLabels(values);
  const handleChange = hooks.useHandleChange();

  return (
    <div className={consts.baseClass}>
      <Range
        min={consts.rangeMin}
        max={consts.rangeMax}
        step={consts.rangeStep}
        trackStyle={[{ backgroundColor: "#F0F0F0" }]}
        value={values}
        handleStyle={consts.handlesStyle}
        marks={labels}
        onChange={handleChange}
      />
    </div>
  );
});
