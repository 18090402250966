import * as turf from "@turf/turf";
import { FullPosition, RnisFullPosition } from "api/telemetry/model/models";

export function getBindLineData(positions: FullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  positions.reduce((a, b) => {
    features.push(
      turf.lineString([
        [a.longitude, a.latitude],
        [b.longitude, b.latitude],
      ])
    );
    return b;
  });

  return features;
}

export function getRnisBindLineData(positions: RnisFullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  positions.reduce((a, b) => {
    features.push(
      turf.lineString([
        [a.lng, a.lat],
        [b.lng, b.lat],
      ])
    );
    return b;
  });

  return features;
}
