import React from "react";
import { v4 } from "uuid";
import { constants } from "../store.constants";
import { Action } from "../store.types";

export const useCreateAttribute = (dispatch: React.Dispatch<Action>) =>
  React.useCallback(
    () =>
      dispatch({
        type: constants.CREATE,
        payload: {
          guid: v4(),
          label: "",
          value: "",
          isNew: true,
        },
      }),
    [dispatch]
  );
