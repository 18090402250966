import mapboxgl from "mapbox-gl";
import { ISOCHRONE_FILL_ID } from "../order-layers";
import Popup from "./popup";

const options = {
  layerId: ISOCHRONE_FILL_ID,
};
export default class IsochronePopup extends Popup {
  constructor(map) {
    super(map, options);
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "dtm-popup",
      maxWidth: "200px",
    });
  }

  getPopupHTML(props) {
    const { contour, previousContour } = props;
    const popupHTML = `
    <p>${previousContour} - ${contour} минут</p>
    `;
    return popupHTML;
  }
}
