import React, { CSSProperties } from "react";

const baseClass = "sector-analysis-diagram-legend";

type PercentageColumnProps = {
  from: number;
  to: number;
  color?: string;
};

export const LegendColumn: React.FC<PercentageColumnProps> = ({ from, to, color }) => {
  let circleStyle: CSSProperties | undefined;

  if (color) circleStyle = { backgroundColor: color };

  return (
    <div className={`${baseClass}__percentage-column`}>
      <div style={circleStyle} className={`${baseClass}__percentage-column__circle`} />
      <span>
        {from}% - {to}%
      </span>
    </div>
  );
};
