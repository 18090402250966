import { memo } from "react";
import { NavigationGroupeLayers } from "components";
import { useStoreContext } from "store";
import { AdministrativeDivisionTypes as Types } from "types";
import * as consts from "./administrative-division.consts";

import "./administrative-division.scss";

export const AdministrativeDivision = memo(() => {
  const { store } = useStoreContext();
  const activeType = store.administrativeDivision.hooks.useActiveType();
  const isDisabled = store.administrativeDivision.hooks.useIsDisabled();
  return (
    <div className="administrative-division">
      <NavigationGroupeLayers
        iconNormalUrl={consts.administrativeDivision.iconNormalUrl}
        iconActiveUrl={consts.administrativeDivision.iconActiveUrl}
        title={consts.administrativeDivision.title}
        isActive={activeType !== Types.Type.unset}
        onClick={store.administrativeDivision.events.handleRestoreActiveType}>
        <NavigationGroupeLayers.Item
          iconNormalUrl={consts.counties.iconNormalUrl}
          iconActiveUrl={consts.counties.iconActiveUrl}
          title={consts.counties.title}
          isDisabled={isDisabled}
          isActive={activeType === Types.Type.districts}
          onClick={store.administrativeDivision.events.handleDistrictsClick}
        />
        <NavigationGroupeLayers.Item
          iconNormalUrl={consts.districts.iconNormalUrl}
          iconActiveUrl={consts.districts.iconActiveUrl}
          title={consts.districts.title}
          isDisabled={isDisabled}
          isActive={activeType === Types.Type.districtZones}
          onClick={store.administrativeDivision.events.handleDistrictZonesClick}
        />
      </NavigationGroupeLayers>
    </div>
  );
});
