import { Middleware, MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { CHANGE_SELECTED_DAY } from "old-store/view/action-types";
import { updateStateInUrl } from "utils/update-state-in-url";
import { DetectorController } from "../controller";
import { detectorSlice } from "./slice";

let _store: MiddlewareAPI<any, GState>;
let controller: DetectorController;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  controller = new DetectorController(map, _store);
});

export const detectorMiddleware: Middleware<any, GState> = (store) => (next) => async (action) => {
  next(action);

  if (!_store) _store = store;

  if (detectorSlice.actions.setIsActiveDetector.match(action)) {
    controller.updateDetectorLayer();
    updateStateInUrl("detector", action.payload);
  }

  if (detectorSlice.actions.setIsActiveNoneDirectionDetector.match(action)) {
    controller.updateNDDetectorLayer();
  }

  if (action.type === CHANGE_SELECTED_DAY) {
    const date = encodeURIComponent(store.getState().view.selectedDay);
    controller?.setDate(date);
  }
};
