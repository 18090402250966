import React, { memo, useMemo } from "react";
import cn from "classnames";

import "./loader.scss";

const baseSize = 16;
const baseClass = "loading-component";
const largeClassName = `${baseClass}_large`;

type Props = {
  width?: number;
  height?: number;
  isLarge?: boolean;
};

export const Loader: React.FC<Props> = memo((props) => {
  const { width = baseSize, height = baseSize, isLarge = false } = props;
  const className = useMemo(() => cn(baseClass, { [largeClassName]: isLarge }), [isLarge]);
  const widthSize = useMemo(() => (isLarge ? baseSize : width), [isLarge, width]);
  const heightSize = useMemo(() => (isLarge ? baseSize : height), [isLarge, height]);

  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle cx={widthSize} cy={heightSize} r="15" />
      </svg>
    </div>
  );
});
