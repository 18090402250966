import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { TrafficData, TrafficPeriod } from "api/traffic/model/traffic";
import { trafficFetchScoreAC, trafficMakeActiveAC, trafficToggleActiveAC } from "old-store/traffic/action-creators";
import TrafficLayer from "../../map-helpers/layers/traffic-layer";
import TrafficPopup from "../../map-helpers/popups/traffic-popup";
import { compareArrays } from "../../utils/compare-two-arrays";
import TrafficButton from "./components/traffic-button";
import "./styles/ctrl-traffic.scss";
import { roadDetectorSlice } from "../ctrl-road-detector";

let map;

on(EVENTS.INIT_MAP, (_map) => {
  map = _map;
});

class CtrlTraffic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentValue: 15,
    };
  }

  componentDidMount() {
    const { type } = this.props;

    if (type === "last") {
      this.autoUpdateScore();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      type,
      isEmptyPieces,
      isActiveTraffic,
      isTrafficMode,
      mapInit,
      es,
      filter,
      from,
      to,
      correlation,
      makeActive,
      routeTimeType,
      routeIsActive,
      isRoadDetectorMode,
    } = this.props;

    let checkEs;
    if (Array.isArray(es) && Array.isArray(prevProps.es)) {
      checkEs = compareArrays([...es], [...prevProps.es]);
    }

    /** Обновление бала пробок */
    if (routeIsActive !== prevProps.routeIsActive) {
      if (routeTimeType === "last" && routeIsActive) this.autoUpdateScore();
      else {
        this.cancelAutoUpdate();
        this.fetchScore();
      }
    }

    if (routeTimeType !== prevProps.routeTimeType && routeIsActive) {
      if (routeTimeType === "last") this.autoUpdateScore();
      else {
        this.cancelAutoUpdate();
        this.fetchScore();
      }
    }

    if (type !== prevProps.type && !routeIsActive) {
      if (type === "last") this.autoUpdateScore();
      else {
        this.cancelAutoUpdate();
        this.fetchScore();
      }
    }

    if (isEmptyPieces !== prevProps.isEmptyPieces) {
      this.trafficLayer?.setIsEmptyPieces(isEmptyPieces);
    }

    if (mapInit !== prevProps.mapInit) {
      this.trafficLayer = new TrafficLayer(map);
      this.trafficPopup = new TrafficPopup(map);
      map.off("style.load", this.updateLayer);
      map.on("style.load", this.updateLayer);

      if (isActiveTraffic && isTrafficMode) {
        this.updateLayer();
        this.trafficPopup.add();
      }

      this.trafficLayer.setIsEmptyPieces(isEmptyPieces);
    }

    if (mapInit) {
      if (isActiveTraffic !== prevProps.isActiveTraffic && isTrafficMode) {
        if (isActiveTraffic) {
          this.updateLayer();
          this.trafficPopup.add();
        } else {
          this.trafficLayer.remove();
          this.trafficPopup.remove();
        }
      }

      if (correlation !== prevProps.correlation && correlation && isTrafficMode) {
        makeActive();
      }

      if (!prevProps.isRoadDetectorMode !== isRoadDetectorMode && isRoadDetectorMode) {
        this.trafficLayer.remove();
      }

      if (
        filter !== prevProps.filter ||
        !checkEs ||
        routeTimeType !== prevProps.routeTimeType ||
        from !== prevProps.from ||
        to !== prevProps.to ||
        type !== prevProps.type
      ) {
        this.fetchScore();
        if (isActiveTraffic && isTrafficMode) {
          this.updateLayer();
        }
      }
    }
  }

  cancelAutoUpdate = () => {
    if (typeof this.autoUpdateTimer === "number") {
      clearTimeout(this.autoUpdateTimer);
    }
  };

  updateLayer = () => {
    const { es, filter, type, from, to, correlation, isActiveTraffic, isTrafficMode } = this.props;
    const data = {
      es,
      filter,
      type,
      correlation,
    };
    if (type !== "last") {
      data.from = from;
      data.to = to;
    }
    if (isActiveTraffic && isTrafficMode) {
      this.trafficLayer.update(data);
    }
  };

  getTitle = () => {
    const { scoring, detectorScore, isTrafficMode } = this.props;
    const { score: trafficScore } = scoring;

    const score = isTrafficMode ? trafficScore : detectorScore?.score;
    let title = "";
    if (typeof score === "number") {
      title = `Уровень пробок ${score}`;
    }
    return title;
  };

  autoUpdateScore = () => {
    this.fetchScore();
    this.autoUpdateTimer = setTimeout(() => {
      this.autoUpdateScore();
    }, 300000);
  };

  fetchScore = () => {
    const { es, from, to, routeTimeType, type, routeIsActive, fetchScore } = this.props;

    if (routeIsActive && routeTimeType === "last") {
      const input = { es, period: TrafficPeriod.Last, data: TrafficData.Historical };
      fetchScore(input);
    } else if (!routeIsActive && type === "last") {
      const input = { es, period: TrafficPeriod.Last, data: TrafficData.Historical };
      fetchScore(input);
    } else if (from && to) {
      if (moment().valueOf() < moment(from).valueOf()) {
        const input = { es, data: TrafficData.Forecast, from, to };
        fetchScore(input);
      } else {
        const input = { es, data: TrafficData.Historical, from, to };
        fetchScore(input);
      }
    }
  };

  updateFavicon = (level) => {
    const levelInRange = level >= 0 && level <= 10;
    const faviconUrl = levelInRange ? level : "default";
    const node_head = document.getElementsByTagName("head")[0];
    if (node_head !== null) {
      const node_link = node_head.querySelector('link[rel="shortcut icon"]');
      if (node_link !== null) {
        node_link.setAttribute("href", `/img/favicon/favicon-${faviconUrl}.ico`);
      }
    }
  };

  onChange = () => {
    const { trafficToggleActive, detectorTrafficToggleActive, isRoadDetectorMode, isTrafficMode } = this.props;
    if (isTrafficMode) {
      trafficToggleActive();
    }
    if (isRoadDetectorMode) {
      detectorTrafficToggleActive();
    }
  };

  render() {
    const { isActiveTraffic, scoring, forceValue, isActiveRoadDetector, isTrafficMode, detectorScore } = this.props;
    const { dynamicsMessage, dynamicsID, scoreParams, prevScoreParams } = isTrafficMode ? scoring : detectorScore;
    const value = isTrafficMode ? forceValue || scoring.score : detectorScore?.score;
    this.updateFavicon(value);
    const title = this.getTitle();
    const averageSpeed = scoreParams ? scoreParams.averageSpeed : 0;
    const prevAverageSpeed = prevScoreParams ? prevScoreParams.averageSpeed : 0;
    return (
      <div className="ctrl-traffic">
        <TrafficButton
          apply={isActiveTraffic || isActiveRoadDetector}
          onChange={this.onChange}
          toggleExperiment={() => null}
          titleScore={title}
          score={value}
          dynamicsMessage={dynamicsMessage}
          dynamicsID={dynamicsID}
          prevAverageSpeed={prevAverageSpeed}
          averageSpeed={averageSpeed}
        />
      </div>
    );
  }
}

CtrlTraffic.propTypes = {
  isActiveTraffic: PropTypes.bool.isRequired,
  isRoadDetectorMode: PropTypes.bool.isRequired,
  isTrafficMode: PropTypes.bool.isRequired,
  isActiveRoadDetector: PropTypes.bool.isRequired,
  scoring: PropTypes.instanceOf(Object),
  es: PropTypes.instanceOf(Array),
  type: PropTypes.string.isRequired,
  from: PropTypes.string,
  to: PropTypes.string,
  filter: PropTypes.string,
  correlation: PropTypes.bool,
  trafficToggleActive: PropTypes.func.isRequired,
  detectorTrafficToggleActive: PropTypes.func.isRequired,
  fetchScore: PropTypes.func.isRequired,
  makeActive: PropTypes.func.isRequired,
  mapInit: PropTypes.bool.isRequired,
  routeTimeType: PropTypes.string,
  forceValue: PropTypes.number,
  detectorScore: PropTypes.instanceOf(Object),
  routeIsActive: PropTypes.bool,
  isEmptyPieces: PropTypes.bool,
};

export default connect(
  (state) => ({
    isRoadDetectorMode: state.roadDetector.isRoadDetectorMode,
    isTrafficMode: state.traffic.isTrafficMode,
    isActiveTraffic: state.traffic.isActive,
    isActiveRoadDetector: state.roadDetector.isActive,
    scoring: state.traffic.score,
    isEmptyPieces: state.traffic.isEmptyPieces,
    es: state.view.es,
    type: state.traffic.type,
    from: state.view.from,
    to: state.view.to,
    filter: state.view.filter,
    correlation: state.global.correlation,
    routeTimeType: state.router.timeStatus,
    forceValue: state.traffic.forceValue,
    routeIsActive: state.router.isActive,
    trafficPercent: state.traffic.trafficPercent,
    mapInit: state.router.mapIsLoaded,
    detectorScore: state.roadDetector.detectorScore,
  }),
  (dispatch) => ({
    trafficToggleActive: (data) => dispatch(trafficToggleActiveAC(data)),
    detectorTrafficToggleActive: () => dispatch(roadDetectorSlice.actions.toggleIsActive()),
    fetchScore: (data) => dispatch(trafficFetchScoreAC(data)),
    makeActive: (data) => dispatch(trafficMakeActiveAC(data)),
  })
)(CtrlTraffic);
