import React, { memo, useMemo } from "react";
import cn from "classnames";
import { ReactComponent as FavoriteIcon } from "assets/icons/favorite.svg";

import "./favorite-button.scss";

const baseClass = "favorite-button";

type Props = {
  isActive: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const FavoriteButton: React.FC<Props> = memo(({ isActive, onClick }) => {
  const className = useMemo(() => cn(baseClass, { [`${baseClass}_active`]: isActive }), [isActive]);

  return (
    <button type="button" onClick={onClick} className={className}>
      <FavoriteIcon />
    </button>
  );
});
