// #region General
export const hoursCount = 24;
// #endregion

// #region Grid lines
export const linesRounding = 0;
export const lineDepthDelta = 0.7;
export const gridLineWidth = 1;
// #endregion

// #region Segments
export const unitsCountDepthFactor = 1.5;
export const trafficColors: { [key: number]: string } = {
  [-1]: "#c7c7c7",
  4: "#af2c04",
  3: "#fa501e",
  2: "#ffe24a",
  1: "#62c400",
  100: "#408000",
};
// #endregion

// #region Ticker
export const stepTicks = [50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000];
export const tickerLabelsSize = 5;
// #endregion
