import React, { useEffect, useState } from "react";
import { APP_ENV } from "app-env";
import { CameraVideo, MapPopup } from "components";
import { PopupStyledContainer } from "./PopupStyledContainer";

const className = "tls-popup";
type Props = {
  id: number;
  num: string;
  address: string;
  token: string;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const TlsPopup: React.FC<Props> = ({ id, num, address, token, onClickClose }) => {
  const [cameras, setCameras] = useState([]);
  const [activeCamera, setActiveCamera] = useState(null);
  const [noCameras, setNoCameras] = useState(false);

  /**
   * Get tls cameras
   */
  useEffect(() => {
    const getTlsCameras = async () => {
      const baseUrl = `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assetcamera`;
      const tlsUrl = `${baseUrl}?filter=exists(refersTo.referenceId, 'eq', ${id})`;
      try {
        const response = await fetch(tlsUrl, {
          method: "get",
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        });

        const { data } = await response.json();

        setCameras(data);

        if (data.length > 0) {
          setActiveCamera(data[0].num);
          setNoCameras(false);
        } else setNoCameras(true);
      } catch (error) {
        console.error(error);
      }
    };

    getTlsCameras();
  }, [id, token]);

  const camera: any = cameras.find((el: any) => el.num === activeCamera);
  const title = `Светофорный объект № ${num}`;
  const titleUrl = `https://warehouse.megapolis-it.ru/tls/${id}`;

  return (
    <MapPopup title={title} titleUrl={titleUrl} address={address} onClickClose={onClickClose}>
      {cameras && (
        <PopupStyledContainer>
          <div className={`${className}-tabs`}>
            {cameras.map((el: any) => {
              return (
                <button
                  className={`${className}-button  ${activeCamera === el.num ? `${className}-button-active` : ""}`}
                  type="button"
                  onClick={() => setActiveCamera(el.num)}
                  key={el.num}>
                  {el.num}
                </button>
              );
            })}
          </div>
        </PopupStyledContainer>
      )}
      {activeCamera && (
        <CameraVideo
          camId={camera.id}
          camType="codd"
          caption={camera.summary}
          token={token}
          fileStorage="dtm"
          description={camera.description}
        />
      )}
      {!activeCamera && !noCameras && <div className={`${className}-placeholder`} />}
      {noCameras && <div className={`${className}-no-camera`}>Нет привязанных камер</div>}
    </MapPopup>
  );
};
