import { IStore } from "../store.types";
import { store } from "./administrative-division";
import { Effects, effects } from "./administrative-division.effects";
import { Events, events } from "./administrative-division.events";
import { Hooks, hooks } from "./administrative-division.hooks";
import { State } from "./administrative-division.types";

export class Store implements IStore<State, Events, Hooks, Effects> {
  public readonly store = store;
  public readonly events = events;
  public readonly hooks = hooks;
  public readonly effects = effects;

  public readonly destroy = () => {
    console.info("destroy");
  };
}

export const administrativeDivisionStore = new Store();
