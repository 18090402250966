import { APP_ENV } from "app-env";
import { shared } from "shared";
import { ZoneStatisticsTypes } from "types";
import { validateResponse } from "../utils";

const statisticsRequestUrl = `${APP_ENV.REACT_APP_DTM_TRAFFIC_API}speed/region-stat`;
const handleError = () => new Error();
export class ZoneStatisticsAPI {
  public readonly getStatistics = (
    zone: ZoneStatisticsTypes.GetStatisticsBody,
    abortController: AbortController
  ): Promise<ZoneStatisticsTypes.Statistics[] | Error> => {
    const urlParams = new URLSearchParams();
    const body = JSON.stringify(zone);
    const url = statisticsRequestUrl + "?" + urlParams;

    return fetch(url, {
      body,
      method: "post",
      signal: abortController.signal,
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${shared.global.accessToken.value}`,
      },
    })
      .then(validateResponse)
      .catch(handleError);
  };
}
