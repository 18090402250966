import React from "react";
import PropTypes from "prop-types";
import getStatusIcon from "../utils/status-icon-image";

const TrafficStatus = ({ dynamicsMessage, dynamicsID, averageSpeed, prevAverageSpeed }) => {
  const source = getStatusIcon(dynamicsID);

  const getTitle = () => {
    let title = dynamicsMessage;
    if (typeof prevAverageSpeed === "number") title += `\nСредняя скорость за час: ${prevAverageSpeed.toFixed(1)} км/ч`;
    if (typeof averageSpeed === "number") title += `\nСредняя скорость сейчас: ${averageSpeed.toFixed(1)} км/ч`;
    return title;
  };

  return <img className="ctrl-traffic-status" src={source} alt={dynamicsMessage} title={getTitle()} />;
};

TrafficStatus.propTypes = {
  dynamicsMessage: PropTypes.string.isRequired,
  dynamicsID: PropTypes.number.isRequired,
  averageSpeed: PropTypes.number.isRequired,
  prevAverageSpeed: PropTypes.number.isRequired,
};

export default TrafficStatus;
