import React, { memo } from "react";

interface Props {
  error: string;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const RouteErrorButton: React.FC<Props> = memo(({ error, handleClick }) => {
  return (
    <button disabled={!handleClick} className="route-container-error-button" onClick={handleClick}>
      <span className="route-container-error-message">{error}</span>
    </button>
  );
});
