import React, { memo, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Compass } from "components";

type Props = {
  map: mapboxgl.Map | null;
};

const getState = (pitch?: number) => (pitch ?? 0) >= 20;

export const CompassController = memo<Props>(({ map }) => {
  const [isVolumetricView, setIsVolumetricView] = useState(getState(map?.getPitch()));
  const [bearing, setBearing] = useState(map?.getBearing() ?? 0);

  const handleVolumetricClick = () => {
    const newValue = !isVolumetricView;
    const getOptions = (pitch: number, bearing: number) => ({ bearing, pitch, duration: 500 });

    setIsVolumetricView(newValue);

    if (!newValue) {
      return map?.easeTo(getOptions(0, 0));
    }

    map?.easeTo(getOptions(40, map.getBearing()));
  };

  const handleMapRotate = () => {
    if (!map) return;
    setBearing(-map.getBearing());
  };

  const handlePitchChange = () => {
    setIsVolumetricView(getState(map?.getPitch()));
  };

  useEffect(() => {
    map?.on("rotate", handleMapRotate);
    map?.on("pitch", handlePitchChange);
    return () => {
      map?.off("rotate", handleMapRotate);
      map?.off("pitch", handlePitchChange);
    };
  }, [map]);

  const handleBearingChange = ({ bearing }: { bearing: number }) => {
    map?.setBearing(-bearing);
  };

  return (
    <div className="app__compass">
      <Compass
        bearing={bearing}
        isVolumetric={isVolumetricView}
        onBearingChange={handleBearingChange}
        onVolumetricClick={handleVolumetricClick}
      />
    </div>
  );
});
