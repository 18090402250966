import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { State } from "./travel-heatmap.types";

export const handleSetNextIsActive = (state: State): State => {
  const isActive = !state.isActive;
  if (!isActive) {
    return {
      ...state,
      isActive,
      activeItem: [],
      from: new Date("2021-09-07").toJSON(),
      to: new Date("2021-09-16").toJSON(),
      directionMode: shared.travelHeatmap.directionModeOptions[0],
      transportType: shared.travelHeatmap.transportTypesOptions[0],
      isCorrespondenceMatrix: false,
      maxTrips: 0,
      minMaxFilter: [0, 1000000000],
    };
  }

  return { ...state, isActive };
};
export const handleSetNextIsCorrespondenceMatrix = (state: State): State => {
  return { ...state, isCorrespondenceMatrix: !state.isCorrespondenceMatrix };
};
export const handleSetColorPrice = (state: State, colorPrice: State["colorPrice"]): State => ({ ...state, colorPrice });
export const handleSetActiveItem = (state: State, payload: State["activeItem"]): State => ({
  ...state,
  activeItem: payload,
});
export const handleCardCloseClick = (state: State): State => ({
  ...state,
  activeItem: [],
});
export const handlePeriodChange = (state: State, { from, to }: TravelHeatmapTypes.PeriodChangePayload): State => ({
  ...state,
  from,
  to,
});
export const handleDirectionModeChange = (state: State, payload: State["directionMode"]): State => ({
  ...state,
  directionMode: payload,
});
export const handleTransportTypeChange = (state: State, payload: State["transportType"]): State => ({
  ...state,
  transportType: payload,
});
export const handleSetHoverItem = (state: State, payload: State["hoverItem"]): State => ({
  ...state,
  hoverItem: payload,
});
export const handleAddSectorInfo = (state: State, payload: TravelHeatmapTypes.SectorInformation): State => {
  return { ...state, sectorsInformation: { ...state.sectorsInformation, [payload.h3Index]: payload } };
};
export const handleSetIsLoading = (state: State, payload: State["isLoading"]): State => ({
  ...state,
  isLoading: payload,
});
export const handleSetStatisticMax = (state: State, payload: State["maxTrips"] | Error): State => {
  if (payload instanceof Error) return state;
  return {
    ...state,
    maxTrips: payload,
    minMaxFilter: [0, payload],
  };
};
export const handleSetMinMaxFilter = (state: State, payload: State["minMaxFilter"]): State => ({
  ...state,
  minMaxFilter: payload,
});
export const handleRestoreActiveItems = (state: State): State => ({ ...state, activeItem: [] });
export const handleSetWeekdays = (state: State, payload: State["weekdays"]): State => ({
  ...state,
  weekdays: payload,
});
export const handleSetTimeRange = (state: State, payload: { min: number; max: number }): State => ({
  ...state,
  timeStart: payload.min,
  timeEnd: payload.max,
});
export const handleGraphicsData = (state: State, payload: State["graphicData"]): State => ({
  ...state,
  graphicData: payload,
});
export const handleMapLoaded = (state: State): State => ({ ...state, isDisabled: false });

export const handleSetIsDisabled = (state: State, isDisabled: State["isDisabled"]): State => ({ ...state, isDisabled });
