import * as TYPES from "./types";
import * as ACTIONS from "./actions";

type InferValueTypes<T> = T extends { [key: string]: infer U } ? U : never;
type ActionTypes = ReturnType<InferValueTypes<typeof ACTIONS>>;

export const globalState: TYPES.GlobalState = {
  activeStyle: "Светлая",
  ruler: false,
  dtp: false,
  correlation: false,
  meteostation: false,
  isLocalDistortions: false,
};

export const globalReducer = (state = globalState, action: ActionTypes) => {
  if (action.type === TYPES.MAP_CHANGE_STYLE)
    return {
      ...state,
      activeStyle: action.payload,
    };

  if (action.type === TYPES.CHANGE_IS_LOCAL_DISTORTIONS) {
    return {
      ...state,
      isLocalDistortions: action.payload,
    };
  }

  if (action.type === TYPES.CORRELATION_UPDATE_IS_ACTIVE) {
    if (action.payload) {
      return {
        ...state,
        correlation: action.payload,
      };
    }
    return {
      ...state,
      correlation: action.payload,
      isLocalDistortions: false,
    };
  }

  if (action.type === TYPES.METEOSTATION_UPDATE_IS_ACTIVE)
    return {
      ...state,
      meteostation: action.payload,
    };

  if (action.type === TYPES.RULER_UPDATE_IS_ACTIVE)
    return {
      ...state,
      ruler: action.payload,
    };

  if (action.type === TYPES.DTP_UPDATE_IS_ACTIVE)
    return {
      ...state,
      dtp: action.payload,
    };

  return state;
};
