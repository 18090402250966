import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { BusStopsTypes } from "types";
import { Broker } from "./broker";

export class BusStopsBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleIsVisibleChange = (state: GState.BusStopsState) => {
    this.mapController.busStops?.setVisibility(state.isVisible);
  };

  private handleEsChange = (es: GState.BusStopsState["es"]) => {
    this.mapController.busStops?.setEs(es);
  };

  private handleFromChange = (from: GState.BusStopsState["from"]) => {
    this.mapController.busStops?.setFrom(from);
  };

  private handleToChange = (to: GState.BusStopsState["to"]) => {
    this.mapController.busStops?.setTo(to);
  };

  private handleTrafficTypeChange = (trafficType: GState.BusStopsState["trafficType"]) => {
    this.mapController.busStops?.setTrafficType(trafficType);
  };

  private handleBusStopInformationDoneData = (busStopInfo: BusStopsTypes.PopupProps | Error) => {
    if (busStopInfo instanceof Error) return this.mapController.busStops?.stopInfoExpectation();
    this.mapController.busStops?.addMissingStopInformation(busStopInfo);
  };

  private handleMapLoad = () => {
    this.uiStore.busStops.events.setIsDisabled(false);
    this.mapController.busStops?.on(
      this.mapController.busStops.events.missingBusStopInfo,
      this.uiStore.busStops.events.handleMissingBusStopInfo
    );
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    this.uiStore.busStops.events.handleIsVisibleChange.watch(this.handleIsVisibleChange);
    this.uiStore.busStops.events.setEsFromRedux.watch(this.handleEsChange);
    this.uiStore.busStops.events.setFromDateFromRedux.watch(this.handleFromChange);
    this.uiStore.busStops.events.setToDateFromRedux.watch(this.handleToChange);
    this.uiStore.busStops.events.setTrafficTypeFromRedux.watch(this.handleTrafficTypeChange);
    this.uiStore.busStops.effects.getBusStopInformation.doneData.watch(this.handleBusStopInformationDoneData);
  };
}
