import { useStore } from "effector-react";
import { store } from "./pedestrian-graph";

const useIsVisible = () => useStore(store).isVisible;
const useIsDisabled = () => useStore(store).isDisabled;

export const hooks = {
  useIsVisible,
  useIsDisabled,
};

export type Hooks = typeof hooks;
