import React, { memo } from "react";
import { Checkbox } from "components";
import { CheckboxGroup } from "../checkbox-group";
import { useValues, useCallbacks } from "./units-count-checkboxes.hooks";

const title = "Количество транспорта";

export const UnitsCountCheckboxes = memo(() => {
  const { isUnitsCountGraph } = useValues();
  const { handleIsUnitsCountGraph } = useCallbacks();

  return (
    <CheckboxGroup>
      <Checkbox value="" label={title} title={title} checked={isUnitsCountGraph} onChange={handleIsUnitsCountGraph} />
    </CheckboxGroup>
  );
});
