import styled from "styled-components";

export const PopupContainer = styled.div<{ isFullMode: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  width: ${(props) => (props.isFullMode ? 480 : 320)}px;
`;

export const HeaderBlock = styled.div`
  padding-top: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  height: 75px;
  border-bottom: 1px solid #e0e0e0;
`;

export const TitleWithIconBlock = styled.div`
  padding-left: 15px;
  display: flex;
  gap: 9px;
  width: 80%;
`;

export const HeaderTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-bottom: 15px;
`;
export const HeaderNameBlock = styled.div`
  display: flex;
  gap: 6px;
  .num {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #747474;
  }
`;

export const HeaderAddress = styled.div`
  font-size: 11px;
  line-height: 16px;
  min-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #747474;
  min-height: 15px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const FirstBlock = styled.div`
  position: relative;
  width: 16px;
  height: 18px;
  align-self: center;
`;

export const TransportFlowBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 15px;
`;

export const TitleBlock = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  svg {
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .title-light {
    font-size: 12px;
    line-height: 16px;
  }
  .arrow-up {
    rotate: 180deg;
  }
`;

export const TransportFlowItemsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TransportFlowItem = styled.div`
  display: flex;
  justify-content: space-between;

  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #747474;
  }

  .value {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ToggleButtonBlock = styled.div`
  padding: 15px 15px 18px 15px;
`;
