import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loader } from "components";
import { getHistoryAC } from "old-store/user-history/action-creators";
import { getFavoriteAC } from "old-store/user-favorite/action-creators";
import { GState } from "documentations";
import { RouteCosting } from "api/router/model/router";
import { Tooltip } from "utils";
import { RouteErrorButton } from "./components/route-error-button";
import { RouteLengthError } from "./components/route-length-error";
import { RouteSection } from "./common-components/route-section/route-section";
import { RouteHeader } from "./components/route-header";
import { RouteDateTime } from "./components/route-date-time";
import { RouteOptions } from "./components/route-options";
import { RouteVariants } from "./components/route-variants/route-variants";
import { RouteVariantYandex } from "./components/route-variants/route-variant-yandex";
import { RouteTransportType } from "./components/route-transport-type";
import RouteFavorite from "./components/route-favorite/route-favorite";
import RouteMain from "./components/route-main/route-main";
import RouteTimeVariants from "./components/route-time-variants/route-time-variants";
import { retryFetchRoute } from "features/ctrl-route/store/actions";

import "./index.scss";

export const containerClassName = "route-container";

const useState = () =>
  useSelector(
    (state: GState) => ({
      routeVariants: state.router.routeVariants,
      isLoadVariants: state.router.routeIsLoading,
      favoriteItems: state.favorite.items,
      historyItems: state.history.items,
      showManeuvers: state.router.isManeuversShow,
      errorVariants: state.router.errorVariants,
      isActive: state.router.isActive,
      costing: state.router.costing,
      isRouteLengthError: state.router.isRouteLengthError,
      isDisabled: state.router.isDisabled,
      isCompareWithYandex: state.router.isCompareWithYandex,
    }),
    shallowEqual
  );

export const RouteContainer = () => {
  const {
    routeVariants,
    isLoadVariants,
    favoriteItems,
    historyItems,
    showManeuvers,
    errorVariants,
    costing,
    isRouteLengthError,
    isDisabled,
    isCompareWithYandex,
  } = useState();

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(retryFetchRoute());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFavoriteAC());
    dispatch(getHistoryAC());
  }, [dispatch]);

  const getFavoriteSectionStatus = () => {
    if (isLoadVariants) return false;
    if (routeVariants && routeVariants.length > 0) return false;
    if (favoriteItems.length > 0) return true;
    if (historyItems.length > 0) return true;
  };

  const isShowFavoriteSection = getFavoriteSectionStatus();

  const isTemplateCosting = costing === RouteCosting.Template;
  const isPedestrianCosting = costing === RouteCosting.Pedestrian;

  return (
    <div className={containerClassName}>
      <RouteSection noPadding>
        <RouteHeader />
      </RouteSection>

      <div className={`${containerClassName}-scroll`}>
        {!showManeuvers && !isPedestrianCosting && routeVariants && routeVariants.length > 0 && (
          <RouteSection noPadding>
            <RouteTimeVariants />
          </RouteSection>
        )}

        {!isDisabled && isShowFavoriteSection && !isTemplateCosting && (
          <RouteSection noPadding>
            <RouteFavorite />
          </RouteSection>
        )}

        {errorVariants && (
          <RouteSection>
            <RouteErrorButton error={errorVariants} handleClick={handleClick} />
          </RouteSection>
        )}

        {isRouteLengthError && (
          <RouteSection>
            <RouteLengthError />
          </RouteSection>
        )}

        {isLoadVariants && !routeVariants && (
          <RouteSection noPadding>
            <div className="route-container-loader-wrapper">
              <Loader />
            </div>
          </RouteSection>
        )}

        {routeVariants && routeVariants.length > 0 && (
          <RouteSection noPadding>
            <RouteVariants />
            {isCompareWithYandex && <RouteVariantYandex />}
          </RouteSection>
        )}

        <RouteSection noPadding showManeuvers={showManeuvers}>
          <RouteMain />
        </RouteSection>

        {!showManeuvers && !isPedestrianCosting && (
          <RouteSection>
            <RouteDateTime />
            <RouteOptions />
          </RouteSection>
        )}

        {!showManeuvers && (
          <RouteSection title="Тип" noPadding>
            <RouteTransportType />
          </RouteSection>
        )}

        {isDisabled && (
          <div className={`${containerClassName}__tooltip`}>
            <Tooltip label="Для работы с маршрутами выключите Линейку" isCloseIcon={false} />
          </div>
        )}
      </div>
    </div>
  );
};
