import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { TravelHeatmapTypes } from "types";
import { Broker } from "./broker";

let minMaxFilterTimeout: number | undefined;

export class TravelHeatmapBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleTravelHeatmapIsActiveChange = (state: GState.TravelHeatmapState) => {
    this.mapController.travelHeatmap?.setIsVisible(state.isActive);
    this.mapController.travelHeatmap?.setActiveItem(state.activeItem);
  };

  private handleCardCloseClick = () => {
    this.mapController.travelHeatmap?.setActiveItem([]);
  };

  private handleListHoverItemChange = (hoverItem: TravelHeatmapTypes.H3IndexFeature | null) => {
    this.mapController.travelHeatmap?.setHoverItem(hoverItem);
  };

  private handleFilterChange = (params: TravelHeatmapTypes.FilterParams) => {
    this.mapController.travelHeatmap?.setFilterParams(params);
  };

  private handleSetMinMaxFilter = (minMaxFilter: TravelHeatmapTypes.MinMaxFilter) => {
    window.clearTimeout(minMaxFilterTimeout);
    minMaxFilterTimeout = window.setTimeout(() => {
      this.mapController.travelHeatmap?.setMinMaxFilter(minMaxFilter);
    }, 300);
  };

  private handleLoadMissingInfo = (sectorInfo: TravelHeatmapTypes.SectorInformation | Error) => {
    if (sectorInfo instanceof Error) return;
    this.mapController.travelHeatmap?.setSectorInfo(sectorInfo);
  };

  private handleMapMissingSectorInfo = (h3Index: string, coordinates: number[] | [number, number]) => {
    this.uiStore.travelHeatmap.events.handleMissingStatistic({
      h3Index,
      coordinates,
    });
  };

  private handleSetColorPrice = (colorPrice: number) => {
    this.mapController.travelHeatmap?.setColorPrice(colorPrice);
  };

  private handleMapLoad = () => {
    this.uiStore.travelHeatmap.events.mapLoaded();

    this.mapController.travelHeatmap?.on(
      this.mapController.travelHeatmap.events.activeItemChange,
      this.uiStore.travelHeatmap.events.handleActiveItemChange
    );

    this.mapController.travelHeatmap?.on(
      this.mapController.travelHeatmap.events.hoverItemChange,
      this.uiStore.travelHeatmap.events.handleHoverItemChange
    );

    this.mapController.travelHeatmap?.on(
      this.mapController.travelHeatmap.events.missingSectorInfo,
      this.handleMapMissingSectorInfo
    );
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    this.uiStore.travelHeatmap.events.handleTravelHeatmapIsActiveChange.watch(this.handleTravelHeatmapIsActiveChange);
    this.uiStore.travelHeatmap.events.setColorPrice.watch(this.handleSetColorPrice);
    this.uiStore.travelHeatmap.events.handleCardCloseClick.watch(this.handleCardCloseClick);
    this.uiStore.travelHeatmap.events.handleListHoverItemChange.watch(this.handleListHoverItemChange);
    this.uiStore.travelHeatmap.events.restoreActiveItems.watch(this.handleCardCloseClick);
    this.uiStore.travelHeatmap.events.handleFilterChange.watch(this.handleFilterChange);
    this.uiStore.travelHeatmap.effects.loadMissingSectorInfo.doneData.watch(this.handleLoadMissingInfo);
    this.uiStore.travelHeatmap.events.setMinMaxFilter.watch(this.handleSetMinMaxFilter);
  };
}
