import React, { memo, useMemo } from "react";
import { Event } from "effector";
import cn from "classnames";
import { Icons } from "./single-button.icons";
import { IconSVGName } from "./single-button.types";

import "./single-button.scss";

export enum BUTTON_FORM {
  round = "round",
  square = "square",
}

export interface SingleButtonProps {
  /** Active button or not */
  isActive: boolean;
  /** Title for button */
  title: string;
  /** Form button */
  type: keyof typeof BUTTON_FORM;
  /** Click listener */
  onClick: React.MouseEventHandler<HTMLButtonElement> | Event<React.MouseEventHandler<HTMLButtonElement>>;
  /** Name svg icon in fonts */
  iconName?: string;
  /** Name svg icon */
  iconSvg?: keyof typeof IconSVGName;
  /** Text in button */
  label?: string;
  /** Disabled button or not */
  disabled?: boolean;
  /** mix class name */
  className?: string;
  /** Button is part of group button */
  buttonGroupItem?: boolean;
}

export const SingleButton: React.FC<SingleButtonProps> = memo((props) => {
  const className = useMemo(
    () =>
      cn("single-button", {
        "single-button-active": props.isActive,
        "single-button-round": props.type === BUTTON_FORM.round,
        "single-button-square": props.type === BUTTON_FORM.square,
        "single-button-disabled": props.disabled,
        "single-button-group-item": props.buttonGroupItem,
        [props.className ?? ""]: props.className,
      }),
    [props.isActive, props.type, props.disabled, props.buttonGroupItem, props.className]
  );

  return (
    <button
      className={className}
      onClick={props.onClick as React.MouseEventHandler<HTMLButtonElement>}
      disabled={props.disabled}
      type="button"
      title={props.title}
      data-testid="single-button">
      {props.iconName && <i className={`${props.iconName} single-button-icon`} />}
      {props.iconSvg && Icons[props.iconSvg]}
      {props.label}
    </button>
  );
});
