import React from "react";
import moment from "moment";
import * as observer from "observer";
import { GroupSelectorPopup } from "./group-selector-popup";
import { AnalysisDiagramOptions, AnalysisDiagramRectParams, AnalysisDiagramRects } from "../../../../types";
import * as utils from "../utils/canvas";
import { render, unmountComponentAtNode } from "react-dom";

type RenderParams = {
  canvas: HTMLCanvasElement;
  ctx?: CanvasRenderingContext2D;
};

export type SectorCoordinates = {
  x: number;
  y: number;
};

export type Selection = {
  from?: SectorCoordinates;
  to?: SectorCoordinates;
};

export class GroupSelector {
  private canvas: HTMLCanvasElement;
  private ctx?: CanvasRenderingContext2D;
  private rects: AnalysisDiagramRects = {};
  private selection: Selection | null = null;
  private popupWrapper: HTMLDivElement;

  public getSelection = () => {
    return this.selection;
  };

  public get isGroupSelected() {
    return !!this.selection;
  }

  constructor(private options: AnalysisDiagramOptions, { canvas, ctx }: RenderParams) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.popupWrapper = document.createElement("div");
    this.popupWrapper.className = "group-selection-wrapper";
    this.canvas.parentElement?.appendChild(this.popupWrapper);
  }

  private clear = () => {
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  };

  private getSize = (selection: Selection) => {
    if (!selection.from || !selection.to) return { width: 0, height: 0 };
    const { x: fromX, y: fromY } = selection.from;
    const { x: toX, y: toY } = selection.to;

    return {
      width: Math.abs(fromX - toX),
      height: Math.abs(fromY - toY),
    };
  };

  private checkIsInSelection = (rect: AnalysisDiagramRectParams) => {
    if (!this.selection?.from || !this.selection?.to) return false;

    const { x: fromX, y: fromY } = this.selection.from;
    const { x: toX, y: toY } = this.selection.to;
    const { x: rectX, y: rectY } = rect;

    return rectX >= fromX && rectX <= toX && rectY >= fromY && rectY <= toY;
  };

  private getSum = (array: (number | undefined)[]) =>
    array.reduce((acc: number, item) => (typeof item === "number" ? acc + item : acc), 0);

  private getAverage = (array: (number | undefined)[]) => (count: number) => (afterDot?: number) => {
    const average = this.getSum(array) / count;
    if (typeof afterDot !== "number") return average;

    return Math.round(average * afterDot) / afterDot;
  };

  private getSelectionData = () => {
    const selected: AnalysisDiagramRectParams[] = [];
    // There are many items. Reduce loop is slower.
    Object.keys(this.rects).forEach((key) => {
      if (!this.rects[key]) return;
      if (this.checkIsInSelection(this.rects[key])) selected.push(this.rects[key]);
    });

    const freeSpeeds = selected.map((item) => item.freeFlowSpeed).filter((item) => typeof item === "number");
    const speeds = selected.map((item) => item.data?.speed);
    const carCounts = selected.map((item) => item.data?.unitsCount);
    const maxFreeSpeed = Math.round(Math.max(...freeSpeeds) * 100) / 100;
    const averageSpeed = this.getAverage(speeds)(speeds.length)(100);
    const averageCarCount = Math.floor(this.getAverage(carCounts)(carCounts.length)());
    const dates = selected.map((item) => moment(item.date));
    const min = moment.min(dates).format("HH:mm");
    const max = moment.max(dates).format("HH:mm");

    return {
      period: `${min} - ${max}`,
      averageSpeed,
      freeSpeed: maxFreeSpeed || 0,
      averageCarCount,
    };
  };

  public readonly updateOptions = (options: AnalysisDiagramOptions) => (this.options = options);

  public readonly updateRects = (rects: AnalysisDiagramRects) => (this.rects = rects);

  public readonly removeSelection = (isDispatch = false) => {
    this.selection = null;
    this.clear();
    this.hidePopup();
    if (isDispatch) observer.dispatch(observer.EVENTS.REMOVE_SELECTIONS, this.options.type);

    return this.isGroupSelected;
  };

  private rollSelection = () => {
    if (!this.selection?.from || !this.selection?.to) return;

    const { x: fromX, y: fromY } = this.selection?.from;
    const { x: toX, y: toY } = this.selection?.to;

    if (fromX > toX) {
      const tempFromX = this.selection.from.x;
      this.selection.from.x = this.selection.to.x;
      this.selection.to.x = tempFromX;
    }

    if (fromY > toY) {
      const tempFromY = this.selection.from.y;
      this.selection.from.y = this.selection.to.y;
      this.selection.to.y = tempFromY;
    }
  };

  public readonly showPopup = () => {
    if (!this.selection?.from || !this.selection?.to) return;

    this.rollSelection();

    const { x: fromX, y: fromY } = this.selection.from;
    const { width, height } = this.getSize(this.selection);
    const offsetX = width / 2 - 110;
    const offsetY = height / 2 - 61;
    const popupX = fromX + offsetX;
    const popupY = fromY + offsetY;
    const selectionData = this.getSelectionData();
    render(
      <GroupSelectorPopup data={selectionData} x={popupX} y={popupY} handleClick={this.removeSelection} />,
      this.popupWrapper
    );
  };

  public readonly hidePopup = () => {
    unmountComponentAtNode(this.popupWrapper);
  };

  public readonly drawSelection = (from?: SectorCoordinates, to?: SectorCoordinates) => {
    this.clear();
    this.selection = { from, to };

    if (!this.ctx || !from || !to) return;

    const { x: fromX, y: fromY } = from;
    const { x: toX, y: toY } = to;

    utils.drawLineRect(this.ctx, {
      coordinates: {
        x1: fromX,
        y1: fromY,
        x2: toX,
        y2: toY,
      },
      color: "#2058d3",
      alpha: 0.5,
      stroke: "#ffffff",
    });
  };

  public readonly destroy = () => {
    this.removeSelection();
  };
}
