import { memo } from "react";
import { Range } from "rc-slider";
import * as hooks from "./segments-units-count-slider.hooks";
import * as consts from "./segments-units-count-slider.consts";
import "./segments-units-count-slider.scss";

export const SegmentsUnitsCountSlider = memo(() => {
  const minMax = hooks.useMinMax();
  const values = hooks.useValues();
  const labels = hooks.useLabels(values);
  const handleChange = hooks.useHandleChange();

  return (
    <div className={consts.baseClass}>
      <Range
        min={minMax[0]}
        max={minMax[1]}
        step={consts.rangeStep}
        trackStyle={consts.trackStyle}
        value={values}
        handleStyle={consts.handlesStyle}
        marks={labels}
        pushable={true}
        allowCross={false}
        onChange={handleChange}
      />
    </div>
  );
});
