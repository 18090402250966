import React from "react";
import { useSelector } from "react-redux";
import { GState } from "documentations";
import { RouteContainer } from "../route-container";

export const Router = () => {
  const { isRouteActive } = useSelector((state: GState) => ({
    isRouteActive: state.router.isActive,
  }));

  return <>{isRouteActive && <RouteContainer />}</>;
};
