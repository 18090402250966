import { useCallback, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { GState } from "documentations";
import { RouteCosting } from "api/router/model/router";
import { RoutePoint } from "features/ctrl-route/types";

const getDataOnTitleFromStore = (state: GState) => ({
  costing: state.router.costing,
  templateId: state.router.templateId,
  favoriteRoadsDictionary: state.router.favoriteRoadsDictionary,
  points: state.router.points,
});

const getTitleByAddress = (points: RoutePoint[]) => {
  const names = points
    .filter((el) => el.isMain)
    .map((el) => {
      if (el.favoriteName) return el.favoriteName;
      return el.address;
    })
    .filter(Boolean)
    .join(" - ");

  return names;
};

export const useGetTitle = () => {
  const { points, costing, templateId, favoriteRoadsDictionary } = useSelector(getDataOnTitleFromStore, shallowEqual);

  const getTitleByTemplate = useCallback(() => {
    return favoriteRoadsDictionary.find(({ id }) => id === templateId)?.name || "";
  }, [templateId, favoriteRoadsDictionary]);

  return useMemo(() => {
    if (costing === RouteCosting.Template) {
      return getTitleByTemplate();
    }

    return getTitleByAddress(points);
  }, [points, costing, getTitleByTemplate]);
};
