import _ from "lodash";
import * as observer from "observer";
import {
  AnalysisDiagramOptions,
  AnalysisType,
  DiagramEvent,
  DiagramMouseRegions,
  UnitsCountDiagramParams,
} from "../../../../types";
import { DiagramRender } from "./diagram-render";
import { Selection } from "../group-selector";
import * as utils from "../utils/canvas";
import * as consts from "../utils/consts";

type DrawOptions = {
  scaleX: number;
  scaleY: number;
  offsetX: number;
  offsetY: number;
};

type SyncSelectionEvent = {
  type: string;
  selection: Selection | null;
};

export class DiagramController extends DiagramRender {
  private isSelecting = false;
  private isDisabled = false;
  private selectingCoordinates: Selection = {};

  constructor(canvas: HTMLCanvasElement, options: AnalysisDiagramOptions) {
    super(canvas, options);

    this.initActions();
    this.updateOptions(options);
  }

  private handleMouseLeaveCanvas = (e: MouseEvent) => {
    const calendarPopup = document.querySelector(".rpc__area");

    // @ts-ignore
    if (e && e.toElement) {
      // @ts-ignore
      if (e.toElement.className === "sector-analysis-tooltip") return;
    }

    if (calendarPopup) return;

    this.dispatcher.fire(DiagramEvent.LEAVE, this.options.type);
    this.clearCrosshair();

    if (!this.previousRectangleKey) return;

    const previousItem = this.rects[this.previousRectangleKey];

    if (!previousItem) return;

    const { x, y, w, h, color } = previousItem;

    utils.drawRectangle(this.ctx, { x, y, w, h, color });
    observer.dispatch(observer.EVENTS.DIAGRAM_LEAVE, this.options.type);
  };

  private getSelectedTLS = (y: number) => {
    const isSelectedTls = this.isSelectedTls.bind(this, y);
    return this.options?.trafficLights?.map(isSelectedTls) || [];
  };

  private handleSelecting = (e: MouseEvent) => {
    const { x, y } = this.getXY(e);
    if (!this.checkIsInsideDiagram(x, y)) return;
    this.clearCrosshair();
    this.dispatcher.fire(DiagramEvent.HIDE_DIAGRAM_POPUPS);
    this.selectingCoordinates.to = { x, y };
    this.groupSelector.drawSelection(this.selectingCoordinates.from, this.selectingCoordinates.to);

    if (!this.isCompare) return;

    observer.dispatch(observer.EVENTS.SYNC_DIAGRAM_SELECTION, {
      type: this.options.type,
      selection: this.groupSelector.getSelection(),
    });
  };

  private get differenceX() {
    return consts.bodyWidth + consts.bodyPaddingLeft * 2 + consts.canvasPaddingLeft * 2;
  }

  private getNormalizedX = (x: number) => {
    return x + (this.options.type === "compare" ? this.differenceX : -this.differenceX);
  };

  private handleSyncDiagramSelection = (event: SyncSelectionEvent) => {
    const { type, selection } = event;

    if (this.options.type === type) return;
    if (!selection) return;
    const selectionCopy = _.cloneDeep(selection);

    this.selectingCoordinates = selectionCopy;
    this.groupSelector.drawSelection(this.selectingCoordinates.from, this.selectingCoordinates.to);
  };

  private handleMouseMoveCanvas = (e: MouseEvent) => {
    const calendarPopup = document.querySelector(".rpc__area");

    if (this.isDisabled || !this.canvas || calendarPopup) return;

    const { data, type } = this.options;

    if (!data) return;

    const { x, y } = this.getXY(e);
    const { column, row } = this.getColumnAndRow(x, y);
    const { rectangleX, rectangleY } = this.getRectanglePos({ column, row });
    const isInsideDiagram = this.checkIsInsideDiagram(x, y);

    if (!isInsideDiagram) return this.handleLeave(e);
    if (this.isDetailsPopup) return;
    if (!this.isSelecting && this.groupSelector.isGroupSelected) return;
    if (e.ctrlKey && this.isDiagramMouseDown) return this.handleSelecting(e);
    if (this.groupSelector.isGroupSelected) return;

    this.drawCrosshair(x, y);
    this.drawTLS(this.getSelectedTLS(y));
    observer.dispatch(observer.EVENTS.SECTOR_ANALYSIS_DIAGRAM_MOUSEMOVE, { type, e });
    this.mouseRegion = DiagramMouseRegions.diagram;

    if (!this.isDiagramMouseDown) {
      this.dispatcher.fire(`${DiagramEvent.SYNC_CROSSHAIR}${type}`, x);
      return this.dispatcher.fire(`${DiagramEvent.MOUSE_MOVE}${type}`, {
        column,
        row,
        type,
        rectangleX,
        rectangleY: rectangleY + this.offsetY,
      });
    }

    this.dispatcher.fire(DiagramEvent.HIDE_DIAGRAM_POPUPS);
    this.handleDiagramDrag(e);
  };

  private handleDetailsPopupClose = () => {
    this.isDetailsPopup = false;
    this.clearCrosshair();
  };

  private handleMouseWheel = (e: WheelEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (this.isDisabled || this.groupSelector.isGroupSelected) return;
    if (!e.ctrlKey && this.scaleX === consts.minScale && this.scaleY === consts.minScale) return;

    const { x, y } = this.getXY(e);
    const isInsideDiagramOrGraph = this.checkIsInsideDiagram(x, y);

    if (!isInsideDiagramOrGraph) return;

    this.handleWheel(e);
    observer.dispatch(observer.EVENTS.DIAGRAM_SCALING, this.options.type, {
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
    });
    this.setTooltipVisibility();
  };

  private setTooltipVisibility = () => {
    const isMinScale = this.scaleY <= consts.minScale && this.scaleX <= consts.minScale;
    if (!isMinScale) return observer.dispatch(observer.EVENTS.ON_IS_ZOOM_TOOLTIP_CHANGE, true);
    observer.dispatch(observer.EVENTS.ON_IS_ZOOM_TOOLTIP_CHANGE, false);
  };

  private handleDblClick = (e: MouseEvent) => {
    if (this.isDisabled || this.isDetailsPopup || !this.isCompare) return;
    const { data, type } = this.options;

    if (!this.canvas || !data) return;

    const { x, y } = this.getXY(e);
    const isInsideDiagram = this.checkIsInsideDiagram(x, y);

    if (!isInsideDiagram) return;

    const { column, row } = this.getColumnAndRow(x, y);
    const { rectangleY, rectangleX } = this.getRectanglePos({ column, row });

    this.clearCrosshair();
    utils.drawRectangle(this.crosshairCtx, {
      x: rectangleX,
      y: this.diagramBodyTop,
      w: this.columnWidth,
      h: this.scaledBodyHeight,
      color: "#2058d3",
      alpha: 0.5,
    });
    this.drawCrosshairStoppers();
    this.isDetailsPopup = true;
    this.dispatcher.fire(DiagramEvent.DBL_CLICK, {
      type,
      column,
      row,
      rectangleX,
      rectangleY: rectangleY + this.offsetY,
    });
  };

  private handleMouseUp = () => {
    if (this.isDisabled) return;
    this.isDiagramMouseDown = false;
    if (this.groupSelector.isGroupSelected) {
      this.isSelecting = false;
      observer.dispatch(observer.EVENTS.CHANGE_SELECTION_POPUP_VISIBILITY, this.options.type, true);
      this.dispatcher.fire(DiagramEvent.SET_IS_FILTER_DISABLED, true);
      return this.groupSelector.showPopup();
    }
  };

  private handleRemoveSelections = (type: string) => {
    this.dispatcher.fire(DiagramEvent.SET_IS_FILTER_DISABLED, false);
    if (this.options.type === type) return;

    this.groupSelector.removeSelection(false);
    this.selectingCoordinates.from = undefined;
    this.selectingCoordinates.to = undefined;
  };

  private handleChangeSelectionPopupVisibility = (type: string, isVisible: boolean) => {
    if (this.options.type === type) return;
    if (isVisible) this.groupSelector.showPopup();
    if (!isVisible) this.groupSelector.removeSelection();
  };

  private handleMouseDown = (e: MouseEvent) => {
    if (this.isDisabled) return;

    const { x, y } = this.getXY(e);
    const isInsideDiagram = this.checkIsInsideDiagram(x, y);

    if (!isInsideDiagram) return;

    this.prevX = x;
    this.prevY = y;

    if (e.ctrlKey && !this.groupSelector.isGroupSelected) {
      this.isSelecting = true;
      this.selectingCoordinates.from = { x, y };
      observer.dispatch(observer.EVENTS.DIAGRAM_LEAVE);
      this.dispatcher.fire(DiagramEvent.LEAVE_DIAGRAM);
    }

    this.isDiagramMouseDown = true;
  };

  private handleUpdateCrosshair = ({ type, e }: { type: string; e: MouseEvent }) => {
    if (this.options.type === type) return;

    const { x, y } = this.getXY(e);
    const normalizedX = this.getNormalizedX(x);
    const { column, row } = this.getColumnAndRow(normalizedX, y);
    const selected = this.options?.trafficLights?.map((item) => this.isSelectedTls(y, item)) || [];
    const { rectangleY } = this.getRectanglePos({ column, row });

    this.drawTLS(selected);
    this.clearCrosshair();
    this.highlightSector(column, row);
    this.drawCrosshair(normalizedX, y);
    this.dispatcher.fire(`${DiagramEvent.SYNC_CROSSHAIR}${this.options.type}`, normalizedX);
    this.dispatcher.fire(`${DiagramEvent.MOUSE_MOVE}${this.options.type}`, {
      column,
      row,
      type: this.options.type,
      rectangleX: normalizedX,
      rectangleY: rectangleY + this.offsetY,
    });
  };

  private handleChangeIsCompare = (value: boolean) => {
    this.isCompare = value;
  };

  private restoreZoom = () => {
    this.scaleX = consts.minScale;
    this.offsetX = 0;
    this.scaleY = consts.minScale;
    this.offsetY = 0;
    this.groupSelector.removeSelection(false);
    this.dispatcher.fire(DiagramEvent.ZOOM);
    observer.dispatch(observer.EVENTS.ON_IS_ZOOM_TOOLTIP_CHANGE, false);
    this.dispatcher.fire(`${DiagramEvent.ZOOMING_X}${this.options?.type}`, this.scaleX, this.offsetX);
    this.dispatcher.fire(DiagramEvent.SET_IS_FILTER_DISABLED, false);
    requestAnimationFrame(() => this.draw());
  };

  private handleDiagramLeave = (type: string) => this.options.type !== type && this.clearCrosshair();

  public handleLeave = (e: MouseEvent) => {
    if (this.isDetailsPopup) return;

    this.handleMouseLeaveCanvas(e);
    this.clearCrosshair();
    this.drawCrosshairStoppers();
    this.handleDetailsPopupClose();
    this.drawTLS();
    this.isDiagramMouseDown = false;
    this.dispatcher.fire(DiagramEvent.LEAVE_DIAGRAM);
    observer.dispatch(observer.EVENTS.DIAGRAM_LEAVE);
    this.mouseRegion = DiagramMouseRegions.outside;
    if (!this.groupSelector.isGroupSelected) return;
    this.groupSelector.showPopup();
    if (!this.isCompare) return;
    observer.dispatch(observer.EVENTS.CHANGE_SELECTION_POPUP_VISIBILITY, this.options.type, true);
  };

  private updateScale = (options: DrawOptions) => {
    const { scaleX, scaleY, offsetX, offsetY } = options;

    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.offsetX = offsetX;
    this.offsetY = offsetY;

    this.dispatcher.fire(DiagramEvent.ZOOM);
    this.clearCrosshair();
    requestAnimationFrame(() => this.draw());
  };

  private handleDiagramScaling = (type: AnalysisType, options: DrawOptions) => {
    if (this.options.type === type) return;

    this.updateScale(options);
  };

  private initActions = () => {
    this.crossHairSubstrate.addEventListener("mouseup", this.handleMouseUp);
    this.crossHairSubstrate.addEventListener("wheel", this.handleMouseWheel);
    this.crossHairSubstrate.addEventListener("mouseleave", this.handleLeave);
    this.crossHairSubstrate.addEventListener("dblclick", this.handleDblClick);
    this.crossHairSubstrate.addEventListener("mousedown", this.handleMouseDown);
    this.crossHairSubstrate.addEventListener("mousemove", this.handleMouseMoveCanvas);
    observer.on(observer.EVENTS.RESTORE_DIAGRAM_ZOOM, this.restoreZoom);
    observer.on(observer.EVENTS.DIAGRAM_LEAVE, this.handleDiagramLeave);
    observer.on(observer.EVENTS.CHANGE_IS_COMPARE, this.handleChangeIsCompare);
    observer.on(observer.EVENTS.ON_DETAILS_POPUP_CLOSE, this.handleDetailsPopupClose);
    observer.on(observer.EVENTS.SECTOR_ANALYSIS_DIAGRAM_MOUSEMOVE, this.handleUpdateCrosshair);
    observer.on(observer.EVENTS.DIAGRAM_SCALING, this.handleDiagramScaling);
    observer.on(observer.EVENTS.SYNC_DIAGRAM_SELECTION, this.handleSyncDiagramSelection);
    observer.on(observer.EVENTS.CHANGE_SELECTION_POPUP_VISIBILITY, this.handleChangeSelectionPopupVisibility);
    observer.on(observer.EVENTS.REMOVE_SELECTIONS, this.handleRemoveSelections);
  };

  private removeActions = () => {
    this.crossHairSubstrate.removeEventListener("mouseup", this.handleMouseUp);
    this.crossHairSubstrate.removeEventListener("wheel", this.handleMouseWheel);
    this.crossHairSubstrate.removeEventListener("mouseleave", this.handleLeave);
    this.crossHairSubstrate.removeEventListener("dblclick", this.handleDblClick);
    this.crossHairSubstrate.removeEventListener("mousedown", this.handleMouseDown);
    this.crossHairSubstrate.removeEventListener("mousemove", this.handleMouseMoveCanvas);
    observer.off(observer.EVENTS.RESTORE_DIAGRAM_ZOOM, this.restoreZoom);
    observer.off(observer.EVENTS.DIAGRAM_LEAVE, this.handleDiagramLeave);
    observer.off(observer.EVENTS.CHANGE_IS_COMPARE, this.handleChangeIsCompare);
    observer.off(observer.EVENTS.ON_DETAILS_POPUP_CLOSE, this.handleDetailsPopupClose);
    observer.off(observer.EVENTS.SECTOR_ANALYSIS_DIAGRAM_MOUSEMOVE, this.handleUpdateCrosshair);
    observer.off(observer.EVENTS.DIAGRAM_SCALING, this.handleDiagramScaling);
    observer.off(observer.EVENTS.SYNC_DIAGRAM_SELECTION, this.handleSyncDiagramSelection);
    observer.off(observer.EVENTS.CHANGE_SELECTION_POPUP_VISIBILITY, this.handleChangeSelectionPopupVisibility);
    observer.off(observer.EVENTS.REMOVE_SELECTIONS, this.handleRemoveSelections);
  };

  public readonly setIsShowEvents = (isShowEvents: boolean) => {
    this.isShowEvents = isShowEvents;
    this.draw(true);
  };

  public readonly updateAppearance = (appearance: DiagramAppearance) => {
    this.appearance = appearance;
    requestAnimationFrame(() => this.draw());
  };

  public setIsCompare = (isCompare: boolean) => (this.isCompare = isCompare);

  public setIsDisabled = (isDisabled: boolean) => (this.isDisabled = isDisabled);

  public setUnitsCountDiagramParams = (value: UnitsCountDiagramParams | null) => {
    this.unitCountDiagramParams = value;
    requestAnimationFrame(() => this.draw());
  };

  public setIsActiveHeatMap = (isActiveHeatMap: boolean, noRender = false) => {
    this.isActiveHeatMap = isActiveHeatMap;
    if (noRender || this.options.type === AnalysisType.current) return;
    this.draw();
  };

  public readonly destroy = () => {
    this.sidesCanvas.remove();
    this.crossHairSubstrate.remove();
    this.groupSelectorCanvas.remove();
    this.groupSelector.destroy();
    this.removeActions();
  };
}
