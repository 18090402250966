import { Theme } from "react-select";
import config from "./dtm-select-styles.config.json";

export const styles = {
  control: (provided: Theme) => ({
    ...provided,
    ...config.control,
  }),
  container: (provided: Theme) => ({
    ...provided,
    ...config.container,
  }),
  indicatorSeparator: () => config.indicatorSeparator,
  dropdownIndicator: (provided: Theme) => ({
    ...provided,
    ...config.dropdownIndicator,
  }),
  option: (provided: Theme) => ({
    ...provided,
    ...config.option,
  }),
  singleValue: (provided: Theme) => ({
    ...provided,
    ...config.singleValue,
  }),
};
