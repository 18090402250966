import { TrafficAPI } from "api/traffic";
import { TrafficPeriod } from "api/traffic/model/traffic";
import getBeforeId, { TELEMETRY_HEATMAP_ID } from "../order-layers";
import Layer from "./layer";

const layerId = TELEMETRY_HEATMAP_ID;

export default class HeatmapLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  getUrl() {
    // TODO - добавить зависимость от времени
    const { es } = this.data;
    const input = { es, period: TrafficPeriod.Last };
    return TrafficAPI.tiles.points(input);
  }

  addLayer() {
    const layer = {
      "id": TELEMETRY_HEATMAP_ID,
      "type": "heatmap",
      "source": TELEMETRY_HEATMAP_ID,
      "source-layer": "points",
      "paint": {
        "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 5, 1, 12, 2, 18, 4],
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(33,102,172,0)",
          0.2,
          "rgb(103,169,207)",
          0.4,
          "rgb(209,229,240)",
          0.6,
          "rgb(253,219,199)",
          0.8,
          "rgb(239,138,98)",
          1,
          "rgb(178,24,43)",
        ],
        "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 5, 1, 12, 2, 18, 4],
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  addSource() {
    if (this.data) {
      const url = this.getUrl();
      this.map.addSource(layerId, {
        type: "vector",
        tiles: [url],
      });
    }
  }

  update(data) {
    this.data = data;
    this.remove();
    this.add();
  }
}
