import React, { memo, useCallback, useMemo } from "react";
import cn from "classnames";

import "./checkbox.scss";

export const className = "route-checkbox";

interface CheckboxProps {
  /** A checkbox can appear disabled and be unable to change states. */
  disabled?: boolean;
  /** Called when the user attempts to change the checked state. */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** The HTML input value */
  value: string;
  /** Whether or not checkbox is checked. */
  checked: boolean;
  /** The text of the associated label element. */
  label?: string;
  /** HTML title attribute */
  title?: string;
}

export const Checkbox: React.FC<CheckboxProps> = memo(({ disabled, onChange, value, checked, label, title = "" }) => {
  const containerClass = useMemo(
    () =>
      cn(className, {
        [`${className}-disabled`]: disabled,
      }),
    [disabled]
  );

  const handleClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      onChange(e);
    },
    [disabled, onChange]
  );

  return (
    <div className={containerClass} data-testid="checkbox" title={title}>
      <label className={`${className}-container`}>
        <input type="checkbox" checked={checked} onChange={handleClick} value={value} disabled={disabled} />
        <span className={`${className}-checkmark`} />
        <span className={`${className}-label`}>{label ? label : ""}</span>
      </label>
    </div>
  );
});
