import * as React from "react";
import Tooltip from "rc-tooltip";
import { ReactComponent as IconTemperature } from "../assets/temperature.svg";
import { ReactComponent as IconPressure } from "../assets/pressure.svg";
import { ReactComponent as IconWind } from "../assets/wind.svg";
import { ReactComponent as IconGusts } from "../assets/gusts.svg";

interface Props {
  district:
    | {
        id: number;
        name: string;
        external_cod: number | null;
      }
    | undefined;
}

const WeatherHeader: React.FC<Props> = ({ district }) => {
  return (
    <div className="cwp-header">
      <div className="cwp-header-name">
        <div className="cwp-header-name-text">{district ? district.name : ""}</div>
      </div>
      <div className="cwp-header-data">
        <Tooltip
          placement="top"
          trigger="hover"
          overlay={<div className="rc-tooltip-inner-text">Температура</div>}
          mouseLeaveDelay={0}>
          <div className="cwp-header-image">
            <IconTemperature />
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          trigger="hover"
          overlay={<div className="rc-tooltip-inner-text">Атмосферное давление</div>}
          mouseLeaveDelay={0}>
          <div className="cwp-header-image">
            <IconPressure />
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          trigger="hover"
          overlay={<div className="rc-tooltip-inner-text">Направление и скорость ветра</div>}
          mouseLeaveDelay={0}>
          <div className="cwp-header-image">
            <IconWind />
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          trigger="hover"
          overlay={<div className="rc-tooltip-inner-text">Порывы ветра</div>}
          mouseLeaveDelay={0}>
          <div className="cwp-header-image">
            <IconGusts />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default WeatherHeader;
