import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "components";
import { GState } from "documentations";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";

import "./route-variant-yandex.scss";

const useYandexVariant = () => useSelector((store: GState) => store.router.yandexVariant);
const useIsYandexLoading = () => useSelector((store: GState) => store.router.isYandexVariantLoading);
const useIsShowYandexRoute = () => {
  const dispatch = useDispatch();
  const isShowYandexRoute = useSelector((store: GState) => store.router.isShowYandexRoute);
  const toggleIsShowYandexRoute = () => dispatch(ROUTE_SLICE.setIsShowYandexRoute(!isShowYandexRoute));
  return { isShowYandexRoute, toggleIsShowYandexRoute };
};

export const RouteVariantYandex = memo(() => {
  const yandexVariant = useYandexVariant();
  const isLoading = useIsYandexLoading();
  const { isShowYandexRoute, toggleIsShowYandexRoute } = useIsShowYandexRoute();

  const showDiffText = `${isShowYandexRoute ? "Скрыть" : "Показать"} разницу на карте`;

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="block">
            <span className="container_title">Яндекс</span>
            <span className="container_diffText" onClick={toggleIsShowYandexRoute}>
              {showDiffText}
            </span>
          </div>
          <div className="block">
            <span className="block_caption">Время с пробками</span>
            <span className={`block_time-label_${yandexVariant?.score ?? 0}`}>{yandexVariant?.time}</span>
          </div>
          <div className="block">
            <span className="block_caption">Время прибытия</span>
            <span className="block_arrivalTime">{yandexVariant?.arrivalTime}</span>
          </div>
          <div className="block">
            <span className="block_caption">Разница</span>
            <span className="block_diff-time">{yandexVariant?.diffTime} мин</span>
          </div>
        </div>
      )}
    </div>
  );
});
