import getBeforeId, { ISOCHRONE_FILL_ID } from "../order-layers";
import Layer from "./layer";

const layerId = ISOCHRONE_FILL_ID;

export default class IsochroneLayerFill extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  prepareData() {
    const prepareData = JSON.parse(JSON.stringify(this.data));
    prepareData.features.forEach((el) => {
      el.geometry.type = "Polygon";
    });
    this.data = prepareData;
  }

  addLayer() {
    const layer = {
      id: layerId,
      type: "fill",
      source: layerId,
      layout: {},
      paint: {
        "fill-outline-color": "#9612de",
        "fill-color": "#9612de",
        "fill-opacity": 0.1,
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  addSource() {
    if (this.data)
      this.map.addSource(layerId, {
        type: "geojson",
        data: this.data,
      });
  }

  update(data) {
    this.data = data;
    this.prepareData();
    if (this.checkSource()) this.updateLayerData(this.data);
    else this.add();
  }
}
