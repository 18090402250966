import mapboxgl from "mapbox-gl";

export class ZoomScale {
  private static instance?: ZoomScale;
  private zoomScale = 1;
  private map?: mapboxgl.Map;

  constructor() {
    if (ZoomScale.instance) return ZoomScale.instance;
    ZoomScale.instance = this;
  }

  public setMap = (map: mapboxgl.Map) => {
    this.map = map;
  };

  public get zoom() {
    return this.map?.getZoom() ?? 1;
  }

  public getScaleByZoom = (zoom: number) => {
    return 2 ** zoom / 419200;
  };

  public get currentScale() {
    this.zoomScale = this.getScaleByZoom(this.zoom);
    return this.zoomScale;
  }

  public setScale = (zoomScale: number) => {
    this.zoomScale = zoomScale;
  };

  public destroy = () => {
    ZoomScale.instance = undefined;
    this.map = undefined;
    this.zoomScale = 1;
  };
}
