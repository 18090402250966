import { memo } from "react";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";

import "./zoom-control.scss";

type Props = {
  onPlusClick: React.MouseEventHandler<HTMLButtonElement>;
  onMinusClick: React.MouseEventHandler<HTMLButtonElement>;
};

const baseClass = "zoom-control";
const plusClass = `${baseClass}__plus`;
const dividerClass = `${baseClass}__divider`;
const minusClass = `${baseClass}__minus`;

export const ZoomControl = memo<Props>(({ onPlusClick, onMinusClick }) => {
  return (
    <div className={baseClass}>
      <button className={plusClass} onClick={onPlusClick}>
        <PlusIcon />
      </button>
      <i className={dividerClass} />
      <button className={minusClass} onClick={onMinusClick}>
        <MinusIcon />
      </button>
    </div>
  );
});
