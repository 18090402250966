import { APP_ENV } from "app-env";
import { NavigationGroupeLayers } from "components";

type ButtonProps = Pick<
  React.ComponentProps<typeof NavigationGroupeLayers.Item>,
  "iconNormalUrl" | "iconActiveUrl" | "title"
>;

export const administrativeDivision: ButtonProps = {
  iconNormalUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/administrative-divisions_normal.svg`,
  iconActiveUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/administrative-divisions_active.svg`,
  title: APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.caption ?? "Административные единицы",
};

export const districts: ButtonProps = {
  iconNormalUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/${
    APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.districts?.icon ?? "district"
  }_normal.svg`,
  iconActiveUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/${
    APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.districts?.icon ?? "district"
  }_active.svg`,
  title: APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.districts?.caption ?? "Районы",
};

export const counties: ButtonProps = {
  iconNormalUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/${
    APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.counties?.icon ?? "counties"
  }_normal.svg`,
  iconActiveUrl: `${APP_ENV.REACT_APP_DTM_MEDIA}icons/${
    APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.counties?.icon ?? "counties"
  }_active.svg`,
  title: APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.counties?.caption ?? "Округа",
};
