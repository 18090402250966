/* eslint-disable max-lines-per-function */
import moment from "moment";
import queryString from "query-string";
import { routerSlice } from "features/ctrl-route/store/slice";
import { roundDate } from "../../utils/round-date";
import {
  BLOCKING_UPDATE_VIEW,
  CHANGE_CORRELATION_DAY,
  CHANGE_IS_CUSTOM_CORRELATION,
  CHANGE_IS_PEDESTRIAN_GRAPH,
  CHANGE_SELECTED_DAY,
  ES_UPDATE_SELECTED,
  FILTER_UPDATE_VALUE,
  GRAPH_UPDATE_IS_ACTIVE,
  HEATMAP_UPDATE_IS_ACTIVE,
  LOADED_TRANSPORT_TYPE_DICTIONARY,
  TIME_UPDATE_IS_ACTIVE,
} from "./action-types";
import { temporaryBlockingSlice } from "features/ctrl-temporary-blocking/store/slice";

const UPDATE_VIEW = "UPDATE_VIEW";
export const CORRELATION_VIEW = "correlation";
export const TIME_FROM = "from";
export const TIME_TO = "to";
export const SELECTED_TIME = "selectedTime";
export const SELECTED_DAY = "selectedDay";
export const CORRELATION_DAY = "correlationDay";
export const IS_CUSTOM_CORRELATION = "isCustomCorrelation";
export const SOURCE_TRAFFIC = "es";
export const TYPE_TRAFFIC = "type";
export const PERIOD = "period";
export const FILTER = "filter";
export const ISOCHRONE_VIEW = "isochrone";
export const ISOCHRONE_SELECTED = "isochroneSelected";

moment.locale("ru");

const prepareRound = (date, period) => {
  return roundDate(moment(date).startOf("minute"), moment.duration(period, "minutes"), "ceil").toISOString(true);
};

// const initialEs = [2, 7, 14, 15, 17, 3, 16, 6, 100];
// const getInitialEs = () => {
//   const { search } = window.location;
//   const searchParams = new URLSearchParams(search);
//   const es = searchParams.getAll("es").map((el) => Number(el));

//   return es ?? initialEs;
// };

export const initialState = {
  [TIME_FROM]: null,
  [TIME_TO]: null,
  [SELECTED_TIME]: prepareRound(moment(), 30),
  [SELECTED_DAY]: prepareRound(moment(), 30),
  [CORRELATION_DAY]: roundDate(
    moment().startOf("minute").subtract(7, "days"),
    moment.duration(30, "minutes"),
    "ceil"
  ).toISOString(true),
  [IS_CUSTOM_CORRELATION]: false,
  transportTypeDictionary: null,
  es: [],
  [PERIOD]: 30,
  [TYPE_TRAFFIC]: "last",
  filter: "city",
  blocking: false,
  graph: false,
  isPedestrianGraph: false,
  heatmap: false,
  time: false,
  blockingTemporary: {
    isActive: false,
  },
};

export function updateViewAC(payload) {
  return {
    type: UPDATE_VIEW,
    payload,
  };
}

export const viewReducer = (state = initialState, { type, payload }) => {
  if (type === TIME_UPDATE_IS_ACTIVE)
    return {
      ...state,
      time: payload,
    };

  if (routerSlice.actions.setIsActive.type === type) {
    if (payload)
      return {
        ...state,
        time: false,
      };

    return state;
  }

  if (type === ES_UPDATE_SELECTED)
    return {
      ...state,
      es: payload,
    };

  if (type === LOADED_TRANSPORT_TYPE_DICTIONARY) {
    let es = state.es;
    const params = queryString.parse(window.location.search);

    if (typeof params.es !== "string" && !Array.isArray(params.es)) {
      es = payload?.reduce((acc, type) => {
        if (typeof type.id === "number") acc.push(type.id);

        return acc;
      }, []);
    }

    const result = {
      ...state,
      es,
      transportTypeDictionary: payload,
    };

    return result;
  }

  if (type === FILTER_UPDATE_VALUE)
    return {
      ...state,
      filter: payload,
    };

  if (type === GRAPH_UPDATE_IS_ACTIVE)
    return {
      ...state,
      graph: payload,
    };

  if (type === CHANGE_IS_PEDESTRIAN_GRAPH)
    return {
      ...state,
      isPedestrianGraph: payload,
    };

  if (type === HEATMAP_UPDATE_IS_ACTIVE)
    return {
      ...state,
      heatmap: payload,
    };

  if (type === BLOCKING_UPDATE_VIEW)
    return {
      ...state,
      blocking: payload,
    };

  if (type === UPDATE_VIEW) {
    const newState = { ...state };
    Object.keys(payload).forEach((key) => {
      newState[key] = payload[key];
    });
    return newState;
  }

  if (type === CHANGE_SELECTED_DAY) {
    const _period = state.period ?? 30;
    const to = moment(payload);
    const _to = prepareRound(to, _period);
    const from = moment(_to).subtract(_period, "minute");
    const _from = prepareRound(from, _period);

    return {
      ...state,
      [SELECTED_DAY]: _to,
      from: _from,
      to: _to,
      [CORRELATION_DAY]: `${moment(state.correlationDay).format(`YYYY-MM-DD`)}T${moment(_from).format("HH:mm:00")}`,
    };
  }

  if (type === CHANGE_CORRELATION_DAY) {
    return {
      ...state,
      [CORRELATION_DAY]: `${moment(payload).format(`YYYY-MM-DD`)}T${moment(state.to).format("HH:mm:00")}`,
    };
  }

  if (type === CHANGE_IS_CUSTOM_CORRELATION) {
    return {
      ...state,
      [IS_CUSTOM_CORRELATION]: Boolean(payload),
    };
  }

  if (temporaryBlockingSlice.actions.setIsActive.type === type) {
    return {
      ...state,
      blockingTemporary: { ...state.blockingTemporary, isActive: payload },
    };
  }

  return state;
};
