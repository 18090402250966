import { createEffect } from "effector";
import { ZoneStatisticsAPI } from "api";
import { getStatisticsRequestBody } from "./zone-statistics.utils";
import { State } from "./zone-statistics.types";

const getStatisticsThrottlingDelay = 250;
let getStatisticsAbort: AbortController = new AbortController();
let getStatisticsThrottlingTimeout: number | undefined;

const loadStatistics = createEffect(async (state: State): Promise<State["statistics"] | Error> => {
  getStatisticsAbort.abort();
  const api = new ZoneStatisticsAPI();
  const body = getStatisticsRequestBody(state);

  if (!body) return Promise.resolve(new Error());

  getStatisticsAbort = new AbortController();
  const response = await api.getStatistics(body, getStatisticsAbort);
  if (response instanceof Error) return Promise.resolve(response);
  if (!response.length) return Promise.resolve(new Error());

  const statSums = response.reduce(
    (acc, item) => ({
      unitsCountSum: acc.unitsCountSum + item.unitsCount,
      speedSum: acc.speedSum + item.avgSpeed,
      scoreSum: acc.scoreSum + item?.scoreOutput?.score ?? 0,
    }),
    {
      unitsCountSum: 0,
      speedSum: 0,
      scoreSum: 0,
    }
  );

  return {
    unitsCount: statSums.unitsCountSum,
    avgSpeed: statSums.speedSum / response.length,
    scoreOutput: {
      score: Math.round(statSums.scoreSum / response.length),
    },
  };
});

const startStatisticsLoading = createEffect((state: State) => {
  return new Promise((resolve) => {
    window.clearTimeout(getStatisticsThrottlingTimeout);
    getStatisticsThrottlingTimeout = window.setTimeout(async () => {
      resolve(await loadStatistics(state));
    }, getStatisticsThrottlingDelay);
  }) as Promise<State["statistics"] | Error | null>;
});

export const effects = {
  loadStatistics,
  startStatisticsLoading,
};

export type Effects = typeof effects;
