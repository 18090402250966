import React from "react";
import { Props } from "./radio-button.types";

export const useHandleChange = (props: Props) => {
  const { uid, isDisabled, onChange } = props;

  const handleChange = React.useCallback(() => {
    if (isDisabled) return;
    onChange?.(uid);
  }, [onChange, uid, isDisabled]);

  return { handleChange };
};
