import React from "react";
import { Props } from "./data-section.types";

import "./data-section.scss";

const dataSectionClass = `details-administrative-division__data-section`;

export const DataSection = (props: Props) => {
  return (
    <div className={dataSectionClass}>
      <div className={`${dataSectionClass}__title`}>{props.title}</div>
      <div>
        <div className={`${dataSectionClass}__description`}>
          <div>{props.description}</div>
          <div>{"Кол-во"}</div>
        </div>
        <div data-cypress={props["data-cypress"]}>
          {props.data?.map((item) => (
            <div key={item.uid} className={`${dataSectionClass}__data`}>
              <div>{item.name}</div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
