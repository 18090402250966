import { RectangleBounds } from "./get-rectangle-bounds";

/**
 * Checks if the rectangle intersect another rectangle
 *
 * @param r1 first rectanngle
 * @param r2 another rectangle
 */
export function checkIntersectRect(r1: RectangleBounds, r2: RectangleBounds) {
  return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
}
