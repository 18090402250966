import { RouterAPI } from "../../api/router";
import { HISTORY_SET } from "./action-types";

export function setItemAC(payload) {
  return {
    type: HISTORY_SET,
    payload,
  };
}

export function getHistoryAC() {
  return (dispatch, getState) => {
    const state = getState();

    const {
      router: { points },
    } = state;

    let address;

    points.forEach((el) => {
      if (el.address) address = el.address;
    });

    RouterAPI.location.history.get().then((data) => {
      data = data.map((el) => {
        el.type = "history";
        return el;
      });

      const filterData = data.filter((el) => el.address !== address);

      dispatch(setItemAC(filterData));
    });
  };
}

export function addHistoryAC(payload) {
  return (dispatch) => {
    const { options } = payload;
    RouterAPI.location.history.add(options).then(() => {
      dispatch(getHistoryAC({ options: {} }));
    });
  };
}

export function touchHistoryAC(payload) {
  const { options } = payload;

  return (dispatch) => {
    RouterAPI.location.history.touch(options).then(() => {
      dispatch(getHistoryAC({ options }));
    });
  };
}

export function deleteHistoryAC(payload) {
  const { options } = payload;

  return (dispatch) => {
    RouterAPI.location.history.delete(options).then(() => {
      dispatch(getHistoryAC({ options: {} }));
    });
  };
}

export function deleteFavoritedHistoryAC(payload) {
  return (dispatch, getState) => {
    let address;
    let id;
    const state = getState();
    const { items } = state.history;
    items.forEach((el) => {
      if (el.address === payload.result.address) {
        address = el.address;
        id = el.id;
      }
    });

    const data = {
      options: {
        address,
        id,
      },
    };

    dispatch(deleteHistoryAC(data));
  };
}
