// @ts-nocheck
import moment from "moment";
import _ from "lodash";
import { MiddlewareAPI } from "redux";
import { batch } from "react-redux";
import { shared } from "shared";
import { DayOfWeek } from "api/router/model/models";
import { GState } from "documentations";
import { RouterAPI } from "api/router";
import { CANCELED_FETCH_MESSAGE } from "api/utils";
import { AnalysisInput } from "api/router/model/router";
import { AnalysisData, AnalysisGraphRange, AnalysisType } from "../types";
import { sectorAnalysisSlice } from "./sector-analysis-slice";
import { dispatch as observerDispatch, EVENTS } from "observer";

const rowHeights = [50, 100, 250, 500, 1000];

const getRowsCount = (routeLength: number) => {
  if (routeLength >= 50 && routeLength <= 8000) return Math.ceil(routeLength / rowHeights[0]);
  if (routeLength > 8000 && routeLength <= 20000) return Math.ceil(routeLength / rowHeights[1]);
  if (routeLength > 20000 && routeLength <= 50000) return Math.ceil(routeLength / rowHeights[2]);
  if (routeLength > 50000 && routeLength <= 110000) return Math.ceil(routeLength / rowHeights[3]);
  if (routeLength > 110000 && routeLength <= 200000) return Math.ceil(routeLength / rowHeights[4]);

  return 214;
};

export const getInitialAnalysisData = (type: AnalysisType): AnalysisData => {
  const data = {
    dateFrom: moment().startOf("day").toISOString(true),
    dateTo: moment().add(1, "day").startOf("day").startOf("day").toISOString(true),
    es: [2, 7, 14, 15, 17, 3, 16, 6, 100],
    type,
    isLoading: false,
    days: [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
      DayOfWeek.Saturday,
      DayOfWeek.Sunday,
    ],
    diagram: null,
    graph: null,
    total: null,
    isZoomTooltip: false,
  };

  return _.cloneDeep(data);
};

export const updateGraphOptions = (store: MiddlewareAPI<any, GState>) => {
  const {
    sectorAnalysis: {
      current: { graph: currentGraph },
      compare: { graph: compareGraph },
    },
  } = store.getState();

  const timeData: (number | null)[] = [];
  const speedData: (number | null)[] = [];
  const unitsCountData: (number | null)[] = [];

  if (currentGraph || compareGraph) {
    if (currentGraph && currentGraph.points)
      Object.keys(currentGraph.points).forEach((key: string) => {
        const item = currentGraph.points[key];
        if (item) {
          timeData.push(item.time);
          speedData.push(item.speed);
          unitsCountData.push(item.unitCounts);
        }
      });

    if (compareGraph && compareGraph.points)
      Object.keys(compareGraph.points).forEach((key: any) => {
        const item = compareGraph.points[key];
        if (item) {
          timeData.push(item.time);
          speedData.push(item.speed);
          unitsCountData.push(item.unitCounts);
        }
      });

    const maxSpeed = _.max(speedData);
    const minSpeed = _.min(speedData);
    const maxTime = _.max(timeData);
    const minTime = _.min(timeData);
    const maxUnitsCount = _.max(unitsCountData);
    const minUnitsCount = _.min(unitsCountData);

    const graphRange: AnalysisGraphRange = { minSpeed, maxSpeed, minTime, maxTime, maxUnitsCount, minUnitsCount };
    store.dispatch(sectorAnalysisSlice.actions.setGraphRange(graphRange));
  }
};

export const fetchCurrentData = async (store: MiddlewareAPI<any, GState>) => {
  const {
    sectorAnalysis: {
      isShowCompare,
      isActive,
      current: { es, dateFrom, dateTo, days },
    },
    router: { routeVariants, activeIndexRoute },
    oidc: {
      user: { access_token },
    },
  }: GState = store.getState();

  const activeRoute = (routeVariants ?? [])[activeIndexRoute];
  const routeLength = (activeRoute?.length || 1) * 1000;

  if (isActive && routeVariants) {
    store.dispatch(sectorAnalysisSlice.actions.setCurrentisLoading(true));

    const fetchType = "current";

    const model: AnalysisInput = {
      routeID: activeRoute.routeID,
      trafficPeriod: {
        from: dateFrom,
        to: dateTo,
      },
      days,
      rowsCount: getRowsCount(routeLength),
      withScore: true,
    };
    const { isRoadDetectorMode, activeFiltersIds } = store.getState().roadDetector;

    isRoadDetectorMode
      ? (model.vehicleClassTypes = {
          vehicleClassIDs: activeFiltersIds,
        })
      : (model.trafficTypes = {
          externalSystemIDs: es,
        });

    try {
      const currentAnalysisApi = isRoadDetectorMode ? RouterAPI.router.analysisDetector : RouterAPI.router.analysis;

      const data = await currentAnalysisApi(model, access_token, fetchType);
      batch(() => {
        observerDispatch(EVENTS.SECTOR_ANALYSIS_MAP_UPDATE_PATH);
        store.dispatch(sectorAnalysisSlice.actions.setCurrentData(data));

        const unitsCountDiagramParams = shared.unitsCountDiagram.calculateUnitsCountDiagramParams(data.diagram);
        store.dispatch(sectorAnalysisSlice.actions.setUnitsCountDiagramParams(unitsCountDiagramParams));

        store.dispatch(sectorAnalysisSlice.actions.setCurrentisLoading(false));
        if (!isShowCompare) {
          store.dispatch(sectorAnalysisSlice.actions.setCompareData(data));
          store.dispatch(sectorAnalysisSlice.actions.setCompareEs(es));
          store.dispatch(sectorAnalysisSlice.actions.setCompareDays(days));
          store.dispatch(
            sectorAnalysisSlice.actions.setCompareRangeDate({
              dateFrom,
              dateTo,
            })
          );
        }
      });
      updateGraphOptions(store);
    } catch (error) {
      console.error(error);
      if (error.message !== CANCELED_FETCH_MESSAGE)
        batch(() => {
          store.dispatch(sectorAnalysisSlice.actions.setDefaultCurrentData());
          store.dispatch(sectorAnalysisSlice.actions.setCurrentisLoading(false));
        });
    }
  }
};

export const fetchCompareData = async (store: MiddlewareAPI<any, GState>) => {
  const {
    sectorAnalysis: {
      isShowCompare,
      isActive,
      compare: { es, dateFrom, dateTo, days },
    },
    router: { routeVariants, activeIndexRoute },
    oidc: {
      user: { access_token },
    },
  }: GState = store.getState();

  const activeRoute = (routeVariants ?? [])[activeIndexRoute];
  const routeLength = (activeRoute?.length || 1) * 1000;

  if (isActive && isShowCompare && routeVariants) {
    store.dispatch(sectorAnalysisSlice.actions.setCompareisLoading(true));

    const isRoadDetectorMode = store.getState().roadDetector.isRoadDetectorMode;

    const model: AnalysisInput = {
      routeID: activeRoute.routeID,
      trafficPeriod: {
        from: dateFrom,
        to: dateTo,
      },
      days,
      rowsCount: getRowsCount(routeLength),
      withScore: true,
    };

    isRoadDetectorMode
      ? (model.vehicleClassTypes = {
          vehicleClassIDs: activeFiltersIds,
        })
      : (model.trafficTypes = {
          externalSystemIDs: es,
        });

    const fetchType = "compare";

    try {
      const currentAnalysisApi = isRoadDetectorMode ? RouterAPI.router.analysisDetector : RouterAPI.router.analysis;

      const data = await currentAnalysisApi(model, access_token, fetchType);
      batch(() => {
        store.dispatch(sectorAnalysisSlice.actions.setCompareData(data));
        store.dispatch(sectorAnalysisSlice.actions.setCompareisLoading(false));
      });
      updateGraphOptions(store);
    } catch (error) {
      console.error(error);
      if (error.message !== CANCELED_FETCH_MESSAGE)
        batch(() => {
          store.dispatch(sectorAnalysisSlice.actions.setDefaultCompareData());
          store.dispatch(sectorAnalysisSlice.actions.setCompareisLoading(false));
        });
    }
  }
};

export const dateKeys15plus3 = [
  "1970-01-01T00:00:00+03:00",
  "1970-01-01T00:15:00+03:00",
  "1970-01-01T00:30:00+03:00",
  "1970-01-01T00:45:00+03:00",
  "1970-01-01T01:00:00+03:00",
  "1970-01-01T01:15:00+03:00",
  "1970-01-01T01:30:00+03:00",
  "1970-01-01T01:45:00+03:00",
  "1970-01-01T02:00:00+03:00",
  "1970-01-01T02:15:00+03:00",
  "1970-01-01T02:30:00+03:00",
  "1970-01-01T02:45:00+03:00",
  "1970-01-01T03:00:00+03:00",
  "1970-01-01T03:15:00+03:00",
  "1970-01-01T03:30:00+03:00",
  "1970-01-01T03:45:00+03:00",
  "1970-01-01T04:00:00+03:00",
  "1970-01-01T04:15:00+03:00",
  "1970-01-01T04:30:00+03:00",
  "1970-01-01T04:45:00+03:00",
  "1970-01-01T05:00:00+03:00",
  "1970-01-01T05:15:00+03:00",
  "1970-01-01T05:30:00+03:00",
  "1970-01-01T05:45:00+03:00",
  "1970-01-01T06:00:00+03:00",
  "1970-01-01T06:15:00+03:00",
  "1970-01-01T06:30:00+03:00",
  "1970-01-01T06:45:00+03:00",
  "1970-01-01T07:00:00+03:00",
  "1970-01-01T07:15:00+03:00",
  "1970-01-01T07:30:00+03:00",
  "1970-01-01T07:45:00+03:00",
  "1970-01-01T08:00:00+03:00",
  "1970-01-01T08:15:00+03:00",
  "1970-01-01T08:30:00+03:00",
  "1970-01-01T08:45:00+03:00",
  "1970-01-01T09:00:00+03:00",
  "1970-01-01T09:15:00+03:00",
  "1970-01-01T09:30:00+03:00",
  "1970-01-01T09:45:00+03:00",
  "1970-01-01T10:00:00+03:00",
  "1970-01-01T10:15:00+03:00",
  "1970-01-01T10:30:00+03:00",
  "1970-01-01T10:45:00+03:00",
  "1970-01-01T11:00:00+03:00",
  "1970-01-01T11:15:00+03:00",
  "1970-01-01T11:30:00+03:00",
  "1970-01-01T11:45:00+03:00",
  "1970-01-01T12:00:00+03:00",
  "1970-01-01T12:15:00+03:00",
  "1970-01-01T12:30:00+03:00",
  "1970-01-01T12:45:00+03:00",
  "1970-01-01T13:00:00+03:00",
  "1970-01-01T13:15:00+03:00",
  "1970-01-01T13:30:00+03:00",
  "1970-01-01T13:45:00+03:00",
  "1970-01-01T14:00:00+03:00",
  "1970-01-01T14:15:00+03:00",
  "1970-01-01T14:30:00+03:00",
  "1970-01-01T14:45:00+03:00",
  "1970-01-01T15:00:00+03:00",
  "1970-01-01T15:15:00+03:00",
  "1970-01-01T15:30:00+03:00",
  "1970-01-01T15:45:00+03:00",
  "1970-01-01T16:00:00+03:00",
  "1970-01-01T16:15:00+03:00",
  "1970-01-01T16:30:00+03:00",
  "1970-01-01T16:45:00+03:00",
  "1970-01-01T17:00:00+03:00",
  "1970-01-01T17:15:00+03:00",
  "1970-01-01T17:30:00+03:00",
  "1970-01-01T17:45:00+03:00",
  "1970-01-01T18:00:00+03:00",
  "1970-01-01T18:15:00+03:00",
  "1970-01-01T18:30:00+03:00",
  "1970-01-01T18:45:00+03:00",
  "1970-01-01T19:00:00+03:00",
  "1970-01-01T19:15:00+03:00",
  "1970-01-01T19:30:00+03:00",
  "1970-01-01T19:45:00+03:00",
  "1970-01-01T20:00:00+03:00",
  "1970-01-01T20:15:00+03:00",
  "1970-01-01T20:30:00+03:00",
  "1970-01-01T20:45:00+03:00",
  "1970-01-01T21:00:00+03:00",
  "1970-01-01T21:15:00+03:00",
  "1970-01-01T21:30:00+03:00",
  "1970-01-01T21:45:00+03:00",
  "1970-01-01T22:00:00+03:00",
  "1970-01-01T22:15:00+03:00",
  "1970-01-01T22:30:00+03:00",
  "1970-01-01T22:45:00+03:00",
  "1970-01-01T23:00:00+03:00",
  "1970-01-01T23:15:00+03:00",
  "1970-01-01T23:30:00+03:00",
  "1970-01-01T23:45:00+03:00",
];

export const dateKeys15plus10 = [
  "1970-01-01T00:00:00+10:00",
  "1970-01-01T00:15:00+10:00",
  "1970-01-01T00:30:00+10:00",
  "1970-01-01T00:45:00+10:00",
  "1970-01-01T01:00:00+10:00",
  "1970-01-01T01:15:00+10:00",
  "1970-01-01T01:30:00+10:00",
  "1970-01-01T01:45:00+10:00",
  "1970-01-01T02:00:00+10:00",
  "1970-01-01T02:15:00+10:00",
  "1970-01-01T02:30:00+10:00",
  "1970-01-01T02:45:00+10:00",
  "1970-01-01T03:00:00+10:00",
  "1970-01-01T03:15:00+10:00",
  "1970-01-01T03:30:00+10:00",
  "1970-01-01T03:45:00+10:00",
  "1970-01-01T04:00:00+10:00",
  "1970-01-01T04:15:00+10:00",
  "1970-01-01T04:30:00+10:00",
  "1970-01-01T04:45:00+10:00",
  "1970-01-01T05:00:00+10:00",
  "1970-01-01T05:15:00+10:00",
  "1970-01-01T05:30:00+10:00",
  "1970-01-01T05:45:00+10:00",
  "1970-01-01T06:00:00+10:00",
  "1970-01-01T06:15:00+10:00",
  "1970-01-01T06:30:00+10:00",
  "1970-01-01T06:45:00+10:00",
  "1970-01-01T07:00:00+10:00",
  "1970-01-01T07:15:00+10:00",
  "1970-01-01T07:30:00+10:00",
  "1970-01-01T07:45:00+10:00",
  "1970-01-01T08:00:00+10:00",
  "1970-01-01T08:15:00+10:00",
  "1970-01-01T08:30:00+10:00",
  "1970-01-01T08:45:00+10:00",
  "1970-01-01T09:00:00+10:00",
  "1970-01-01T09:15:00+10:00",
  "1970-01-01T09:30:00+10:00",
  "1970-01-01T09:45:00+10:00",
  "1970-01-01T10:00:00+10:00",
  "1970-01-01T10:15:00+10:00",
  "1970-01-01T10:30:00+10:00",
  "1970-01-01T10:45:00+10:00",
  "1970-01-01T11:00:00+10:00",
  "1970-01-01T11:15:00+10:00",
  "1970-01-01T11:30:00+10:00",
  "1970-01-01T11:45:00+10:00",
  "1970-01-01T12:00:00+10:00",
  "1970-01-01T12:15:00+10:00",
  "1970-01-01T12:30:00+10:00",
  "1970-01-01T12:45:00+10:00",
  "1970-01-01T13:00:00+10:00",
  "1970-01-01T13:15:00+10:00",
  "1970-01-01T13:30:00+10:00",
  "1970-01-01T13:45:00+10:00",
  "1970-01-01T14:00:00+10:00",
  "1970-01-01T14:15:00+10:00",
  "1970-01-01T14:30:00+10:00",
  "1970-01-01T14:45:00+10:00",
  "1970-01-01T15:00:00+10:00",
  "1970-01-01T15:15:00+10:00",
  "1970-01-01T15:30:00+10:00",
  "1970-01-01T15:45:00+10:00",
  "1970-01-01T16:00:00+10:00",
  "1970-01-01T16:15:00+10:00",
  "1970-01-01T16:30:00+10:00",
  "1970-01-01T16:45:00+10:00",
  "1970-01-01T17:00:00+10:00",
  "1970-01-01T17:15:00+10:00",
  "1970-01-01T17:30:00+10:00",
  "1970-01-01T17:45:00+10:00",
  "1970-01-01T18:00:00+10:00",
  "1970-01-01T18:15:00+10:00",
  "1970-01-01T18:30:00+10:00",
  "1970-01-01T18:45:00+10:00",
  "1970-01-01T19:00:00+10:00",
  "1970-01-01T19:15:00+10:00",
  "1970-01-01T19:30:00+10:00",
  "1970-01-01T19:45:00+10:00",
  "1970-01-01T20:00:00+10:00",
  "1970-01-01T20:15:00+10:00",
  "1970-01-01T20:30:00+10:00",
  "1970-01-01T20:45:00+10:00",
  "1970-01-01T21:00:00+10:00",
  "1970-01-01T21:15:00+10:00",
  "1970-01-01T21:30:00+10:00",
  "1970-01-01T21:45:00+10:00",
  "1970-01-01T22:00:00+10:00",
  "1970-01-01T22:15:00+10:00",
  "1970-01-01T22:30:00+10:00",
  "1970-01-01T22:45:00+10:00",
  "1970-01-01T23:00:00+10:00",
  "1970-01-01T23:15:00+10:00",
  "1970-01-01T23:30:00+10:00",
  "1970-01-01T23:45:00+10:00",
];

export const dateKeys30 = [
  "1970-01-01T00:00:00+03:00",
  "1970-01-01T00:30:00+03:00",
  "1970-01-01T01:00:00+03:00",
  "1970-01-01T01:30:00+03:00",
  "1970-01-01T02:00:00+03:00",
  "1970-01-01T02:30:00+03:00",
  "1970-01-01T03:00:00+03:00",
  "1970-01-01T03:30:00+03:00",
  "1970-01-01T04:00:00+03:00",
  "1970-01-01T04:30:00+03:00",
  "1970-01-01T05:00:00+03:00",
  "1970-01-01T05:30:00+03:00",
  "1970-01-01T06:00:00+03:00",
  "1970-01-01T06:30:00+03:00",
  "1970-01-01T07:00:00+03:00",
  "1970-01-01T07:30:00+03:00",
  "1970-01-01T08:00:00+03:00",
  "1970-01-01T08:30:00+03:00",
  "1970-01-01T09:00:00+03:00",
  "1970-01-01T09:30:00+03:00",
  "1970-01-01T10:00:00+03:00",
  "1970-01-01T10:30:00+03:00",
  "1970-01-01T11:00:00+03:00",
  "1970-01-01T11:30:00+03:00",
  "1970-01-01T12:00:00+03:00",
  "1970-01-01T12:30:00+03:00",
  "1970-01-01T13:00:00+03:00",
  "1970-01-01T13:30:00+03:00",
  "1970-01-01T14:00:00+03:00",
  "1970-01-01T14:30:00+03:00",
  "1970-01-01T15:00:00+03:00",
  "1970-01-01T15:30:00+03:00",
  "1970-01-01T16:00:00+03:00",
  "1970-01-01T16:30:00+03:00",
  "1970-01-01T17:00:00+03:00",
  "1970-01-01T17:30:00+03:00",
  "1970-01-01T18:00:00+03:00",
  "1970-01-01T18:30:00+03:00",
  "1970-01-01T19:00:00+03:00",
  "1970-01-01T19:30:00+03:00",
  "1970-01-01T20:00:00+03:00",
  "1970-01-01T20:30:00+03:00",
  "1970-01-01T21:00:00+03:00",
  "1970-01-01T21:30:00+03:00",
  "1970-01-01T22:00:00+03:00",
  "1970-01-01T22:30:00+03:00",
  "1970-01-01T23:00:00+03:00",
  "1970-01-01T23:30:00+03:00",
];
