import React, { Component } from "react";
import PropTypes from "prop-types";
import "./route-favorites-edit.scss";

const className = "route-favorite-edit";

class RouteFavoritesEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleClick } = this.props;
    return (
      <button type="button" onClick={handleClick} className={className}>
        <i className="dtm-edit-icon" />
      </button>
    );
  }
}

RouteFavoritesEdit.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default RouteFavoritesEdit;
