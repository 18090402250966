interface GetTranslateBounds {
  width: number;
  height: number;
  translateX: number;
  translateY: number;
}

/**
 * Return max and min translate values
 *
 * @param width - popup width
 * @param height - popup height
 */
export function getTranslateBounds({ width, height, translateX, translateY }: GetTranslateBounds) {
  let x = translateX;
  let y = translateY;

  const maxX = document.documentElement.clientWidth - 70 - width;
  const minX = 0;
  const maxY = document.documentElement.clientHeight - height;
  const minY = 70;

  if (translateX > maxX) x = maxX;
  if (translateX < minX) x = minX;
  if (translateY > maxY) y = maxY;
  if (translateY < minY) y = minY;

  return { x, y };
}
