export default class Layer {
  constructor(map, layerId) {
    this.map = map;
    this.layerId = layerId;
  }

  checkLayer() {
    return this.map?.getLayer(this.layerId);
  }

  checkSource() {
    const check = this.map.getSource(this.layerId);
    return check;
  }

  updateLayerData() {
    if (this.data) {
      const source = this.map.getSource(this.layerId);
      if (source) source.setData(this.data);
    }
  }

  add(data) {
    if (data) this.data = data;
    this.addSource();
    this.addLayer();
  }

  remove(unmount = false) {
    if (this.checkLayer()) this.map.removeLayer(this.layerId);

    if (this.checkSource()) this.map.removeSource(this.layerId);

    if (unmount) this.data = null;
  }

  update(data) {
    this.data = data;
    if (this.checkSource()) this.updateLayerData(this.data);
    else this.add();
  }
}
