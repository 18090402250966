/* eslint-disable no-await-in-loop */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LngLat } from "mapbox-gl";
import * as turf from "@turf/turf";
import moment from "moment";
import { EVENTS, on } from "observer";
import { blockingUpdateViewAC } from "old-store/view/action-creators";
import DtpPopup from "map-helpers/popups/dtp-popup";
import BadRoadsLayer from "map-helpers/layers/incident-layer";
import { NavigationGroupeLayers } from "components";
import IncidentAPI from "api/incident/incident-api";
import { RouterAPI } from "api/router";
import DtpLayer from "map-helpers/layers/dtp-layer";
import { BLOCKING_POINTS_ID } from "map-helpers/order-layers";
import OverlapControl from "./overlap-mapbox";
import * as consts from "./ctrl-blocking.consts";
import "./ctrl-blocking.scss";

const className = "ctrl-blocking";

let map;

on(EVENTS.INIT_MAP, (_map) => {
  map = _map;
});

class CtrlBlocking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      points: null,
    };

    this.updatePointLayer = this.updatePointLayer.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapInit, isActive, selectedDay, type } = this.props;

    const { data } = this.state;

    const date = type === "last" ? moment().toISOString(true) : selectedDay;

    if (mapInit !== prevProps.mapInit && mapInit) {
      this.initControl();
      this.addLayer(date);
      map.on("style.load", () => this.redrawLayer(date));
    }

    if (mapInit) {
      if (type !== prevProps.type || selectedDay !== prevProps.selectedDay) {
        if (isActive) this.updateLayer(date);
      }

      if (data !== prevState.data || isActive !== prevProps.isActive) {
        if (isActive) {
          this.addLayer(date);
        }

        if (!isActive) this.removeLayer();
      }
    }
  }

  redrawLayer = (date) => {
    const { isActive } = this.props;

    if (isActive) {
      this.removeLayer();

      this.addLayer(date);
    }
  };

  addLayer = (date) => {
    const { data } = this.state;

    const { isActive } = this.props;

    if (isActive) {
      this.pointLayer.add();

      if (data) {
        this.badRoadsLayer.add(data);
      }

      this.blockingPopup.add();

      this.overlapLayer.showLayer(date);
    }
  };

  removeLayer = () => {
    this.badRoadsLayer.remove();

    this.overlapLayer.hideLayer();

    this.blockingPopup.remove();

    this.pointLayer.remove();
  };

  updateLayer = (date) => {
    this.overlapLayer.hideLayer();

    this.overlapLayer.showLayer(date);
  };

  initControl = () => {
    const { access_token } = this.props;

    const options = {
      token: access_token,
      updatePointLayer: this.updatePointLayer,
      beforeId: BLOCKING_POINTS_ID,
    };

    this.overlapLayer = new OverlapControl(options);

    map.addControl(this.overlapLayer);

    this.blockingPopup = new DtpPopup(map);

    this.badRoadsLayer = new BadRoadsLayer(map);

    this.pointLayer = new DtpLayer(map);
  };

  loadList = async () => {
    const { isActive } = this.props;

    /** События из АСУНС c типом подтопление */
    const model = {
      CreateFrom: moment().startOf("day").format("YYYY-MM-DD"),
      Type: {
        id: 6, //30
      },
      Status: {
        id: 10,
      },
      PageSize: 100,
    };

    const result = await IncidentAPI.getIncidentList(model);

    this.setState({ points: result });

    if (isActive) this.pointLayer.addIncidentsFlooding(result);

    const geojson = {
      type: "FeatureCollection",
      features: [],
    };

    /** Привязка к графу каждого события */
    for (let index = 0; index < result.items.length; index++) {
      try {
        const item = result.items[index];
        if (!item.lat || !item.lng) continue;
        const data = {
          lat: item.lat,
          lng: item.lng,
        };
        const edges = await RouterAPI.router.mapmatch.point(data);
        if (!edges) continue;
        const edge = edges[0];
        if (!edge || !edge.shape) continue;
        const lineString = turf.lineString(
          edge.shape.map((el) => {
            return new LngLat(el.lng, el.lat).toArray();
          }),
          { overlapBegin: item.begin, description: item.description, type: 30 }
        );
        geojson.features.push(lineString);
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    this.setState({ data: geojson });

    if (isActive) this.badRoadsLayer.add(geojson);
  };

  updateData = (data) => {
    if (this.pointLayer) {
      this.pointLayer.addIncidentsBlocking(data);
    }
  };

  updatePointLayer = (data) => {
    this.updateData(data);
  };

  handleClick = (e) => {
    const { isActive, setView } = this.props;
    e.stopPropagation();
    setView(!isActive);
  };

  render() {
    const { isActive } = this.props;

    return (
      <div className={className}>
        <NavigationGroupeLayers.Item
          iconNormalUrl={consts.buttonProps.iconNormalUrl}
          iconActiveUrl={consts.buttonProps.iconActiveUrl}
          title={consts.buttonProps.title}
          isActive={isActive}
          onClick={this.handleClick}
        />
      </div>
    );
  }
}

CtrlBlocking.propTypes = {
  isActive: PropTypes.bool,
  setView: PropTypes.func,
  access_token: PropTypes.string,
  mapInit: PropTypes.bool,
  selectedDay: PropTypes.string,
  type: PropTypes.string,
};

export default connect(
  (state) => ({
    isActive: state.view.blocking,
    access_token: state.oidc.user.access_token,
    selectedDay: state.view.selectedDay,
    type: state.view.type,
    mapInit: state.router.mapIsLoaded,
  }),
  (dispatch) => ({
    setView: (data) => dispatch(blockingUpdateViewAC(data)),
  })
)(CtrlBlocking);
