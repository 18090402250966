/* eslint-disable quotes */
import React from "react";
import mapboxgl, { LngLat } from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { CAMERA_CODD_LAYER_ID, CAMERA_ECHD_LAYER_ID } from "map-helpers/order-layers";
import { popupController, WarehousType } from "map-helpers/popups/popup-controller";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { CameraLayer } from "../map-layer/camera-layer";
import { CamType, CreatePopup } from "../types";
import { CameraPopup } from "../components/camera-popup";
import { APP_ENV } from "app-env";

export class CameraController {
  private cameraCODDLayer: CameraLayer;
  private cameraECHDLayer: CameraLayer;
  private activeItems: number[] = [];
  private hoverItemId: number | null = null;

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.cameraCODDLayer = new CameraLayer(map, CAMERA_CODD_LAYER_ID, CAMERA_CODD_LAYER_ID);
    this.cameraECHDLayer = new CameraLayer(map, CAMERA_ECHD_LAYER_ID, CAMERA_ECHD_LAYER_ID);
    this.cameraCODDLayer.on("click", this.handleClick);
    this.cameraECHDLayer.on("click", this.handleClick);
    this.map.on("style.load", this.update);
    on(EVENTS.MAP_POPUP_ENTER, this.handlePopupEnter);
    on(EVENTS.MAP_POPUP_LEAVE, this.handlePopupLeave);
    this.update();
  }

  public update = () => {
    const {
      camera: { isActive },
    } = this.store.getState();

    if (isActive) this.addLayer();
    else this.removeLayer();
  };

  private handleClick = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;
    const camType = feature.layer.id === CAMERA_CODD_LAYER_ID ? CamType.codd : CamType.echd;
    const {
      // @ts-ignore
      properties: { Id, Num, address, Lat, Lng },
    } = feature;
    if (!Id || !Num || !address || !Lat || !Lng) return;
    const lngLat = new LngLat(Lng, Lat);
    this.createPopup({ id: Id, num: Num, address, lngLat, camType });
    this.setActiveItems(Id);
  };

  private handlePopupEnter = ({ id, type }: { id: number; type: WarehousType }) => {
    if (type !== "camera") return;
    this.setHoverFilter(id);
    this.setFilterByActiveItems();
  };

  private handlePopupLeave = ({ type }: { id: number; type: WarehousType }) => {
    if (type !== "camera") return;
    this.setHoverFilter(null);
    this.setFilterByActiveItems();
  };

  private setActiveItems(id: number | null) {
    const nullFilter: mapboxgl.Expression = ["boolean", false];
    if (typeof id !== "number") {
      this.activeItems = [];
      this.cameraCODDLayer.setFilterOnActiveLayer(nullFilter);
      this.cameraECHDLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
    if (this.activeItems.includes(id)) this.activeItems = this.activeItems.filter((el) => el !== id);
    else this.activeItems.push(id);

    if (!this.activeItems.length) {
      this.cameraCODDLayer.setFilterOnActiveLayer(nullFilter);
      this.cameraECHDLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
    this.setFilterByActiveItems();
  }

  private setFilterByActiveItems() {
    const filter = ["any"];
    this.activeItems
      .filter((el) => el !== this.hoverItemId)
      // @ts-ignore
      .forEach((el) => filter.push(["==", ["get", "Id"], el]));
    this.cameraCODDLayer.setFilterOnActiveLayer(["any", filter]);
    this.cameraECHDLayer.setFilterOnActiveLayer(["any", filter]);
  }

  private setHoverFilter(id: number | null) {
    const nullFilter: mapboxgl.Expression = ["boolean", false];
    if (typeof id !== "number") {
      this.hoverItemId = null;
      this.cameraCODDLayer.setFilterOnHoverLayer(nullFilter);
      this.cameraECHDLayer.setFilterOnHoverLayer(nullFilter);
      return;
    }
    this.hoverItemId = id;
    const filter: mapboxgl.Expression = ["==", ["get", "Id"], id];
    this.cameraCODDLayer.setFilterOnHoverLayer(filter);
    this.cameraECHDLayer.setFilterOnHoverLayer(filter);
  }

  private addLayer() {
    const coddUrl = this.getCoddTileUrl();
    this.cameraCODDLayer.add(coddUrl);
    const echdUrl = this.getEchdTileUrl();
    this.cameraECHDLayer.add(echdUrl);
  }

  private removeLayer() {
    this.cameraCODDLayer.remove();
    this.cameraECHDLayer.remove();
    popupController?.removePopupByType("speedcam");
    this.setActiveItems(null);
  }

  private getCoddTileUrl() {
    const filter = "in(deleted,false) and deleted eq false";
    const additionalFields = "lat,lng";
    const odata = `filter=${filter}&additionalFields=${additionalFields}`;
    return `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assetcamera/layerMVT/{z}/{x}/{y}?${odata}`;
  }

  private getEchdTileUrl() {
    const filter = "in(deleted,false) and deleted eq false and startswith(num,'MMC')";
    const additionalFields = "lat,lng";
    const odata = `filter=${filter}&additionalFields=${additionalFields}`;
    return `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assetechdcam/layerMVT/{z}/{x}/{y}?${odata}`;
  }

  private createPopup({ id, num, address, lngLat, camType }: CreatePopup) {
    const {
      oidc: {
        user: { access_token: token },
      },
    } = this.store.getState();
    const containerId = `camera-${id}`;
    const onClickClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e.ctrlKey) {
        popupController?.removePopupByType("camera");
        this.setActiveItems(null);
        this.setHoverFilter(null);
      } else {
        popupController?.removePopupById(containerId);
        this.setActiveItems(id);
        this.setHoverFilter(null);
      }
    };
    const component = (
      <CameraPopup id={id} num={num} address={address} token={token} onClickClose={onClickClose} camType={camType} />
    );
    popupController?.addPopup({ component, lngLat, id: containerId, type: "camera", itemId: id });
  }
}
