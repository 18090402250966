import * as THREE from "three";

export class Group {
  protected world: THREE.Group = new THREE.Group();
  protected sizes?: DOMRect;
  protected opacity = 0.7;

  public readonly calculate?: (...args: any) => Group;

  public readonly setSizes = (sizes?: DOMRect) => {
    this.sizes = sizes;
    return this;
  };

  public readonly clear = () => {
    this.world.clear();
    return this;
  };

  public readonly addTo = (target?: THREE.Scene | THREE.Group) => {
    if (!target || target.getObjectById(this.world.id)) return this;
    target.add(this.world);
    return this;
  };

  public readonly removeFrom = (target?: THREE.Scene | THREE.Group) => {
    if (!target || !target.getObjectById(this.world.id)) return;
    target.remove(this.world);
    return this;
  };
}
