import * as TYPES from "./types";

export const changeMapStyle = (style: string) =>
  ({
    type: TYPES.MAP_CHANGE_STYLE,
    payload: style,
  } as const);

export const rulerUpdateIsActive = (isActive: boolean) =>
  ({
    type: TYPES.RULER_UPDATE_IS_ACTIVE,
    payload: isActive,
  } as const);

export const dtpUpdateIsActive = (isActive: boolean) =>
  ({
    type: TYPES.DTP_UPDATE_IS_ACTIVE,
    payload: isActive,
  } as const);

export const correlationUpdateIsActive = (isActive: boolean) =>
  ({
    type: TYPES.CORRELATION_UPDATE_IS_ACTIVE,
    payload: isActive,
  } as const);

export const meteostationUpdateIsActive = (isActive: boolean) =>
  ({
    type: TYPES.METEOSTATION_UPDATE_IS_ACTIVE,
    payload: isActive,
  } as const);

export function changeIsLocalDistortions(payload: boolean) {
  return {
    type: TYPES.CHANGE_IS_LOCAL_DISTORTIONS,
    payload,
  };
}
