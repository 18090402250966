import { TransportHubsTypes } from "types";
import { State } from "./transport-hubs.types";

export const setFeatureCollection = (state: State, payload: TransportHubsTypes.FeatureCollection): State => {
  return {
    ...state,
    featureCollection: payload,
  };
};

export const setDefaultIsVisible = (state: State): State => ({ ...state, isVisible: false });
export const setNextIsVisible = (state: State): State => ({ ...state, isVisible: !state.isVisible });
export const setIsVisible = (state: State, payload: boolean): State => ({ ...state, isVisible: payload });
export const handleSetIsDisabled = (state: State, isDisabled: State["isDisabled"]): State => ({ ...state, isDisabled });
