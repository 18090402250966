import React, { useMemo, useState } from "react";
import { RoundToggleButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import { speedcamSlice } from "../../../../store/slice";
import { GState } from "documentations";
import { StatisticChart } from "./components/statistic-chart";
import { GreenArrow } from "../../../../../../assets";
import { TitleBlock } from "../../speedcam-popup.styled";
import { ButtonsBlock, SpeedcamStatisticContainer } from "./speedcam-statistic.styled";
import { getInterval, useGetStatistic } from "./hooks";

type Props = {
  camId: number;
};

export const SpeedcamStatistic = (props: Props) => {
  const { fixations, violations, currentMode, timeLength, filterButtons } = useGetStatistic(props.camId);

  const dispatch = useDispatch();

  const setCurrentMode = (dirtyMode: string) => {
    const mode = dirtyMode as "fixations" | "violations";
    dispatch(speedcamSlice.actions.setCurrentMode({ mode, id: props.camId }));
  };

  const overSpeed = useSelector((state: GState) => state.speedcam.speedcams?.[props.camId]?.overSpeed);

  const [isShowStatistic, setIsShowStatistic] = useState(true);

  const data = useMemo(
    () => (currentMode === "fixations" ? fixations : violations),
    [currentMode, fixations, violations]
  );
  const disableValues = !Boolean(overSpeed) ? ["violations"] : undefined;
  const buttonsValues: { value: string; caption: string; hoverTitle?: string }[] = [
    {
      value: "fixations",
      caption: "Фиксации",
    },
    {
      value: "violations",
      caption: "Нарушения",
      hoverTitle: disableValues ? "Разрешенная скорость на данном участке не определена" : undefined,
    },
  ];

  return (
    <div>
      <TitleBlock>
        <div className="title">Статистика</div>
        <div onClick={() => setIsShowStatistic(!isShowStatistic)} className="not-dragable-zone">
          {isShowStatistic ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
        </div>
      </TitleBlock>
      {isShowStatistic && (
        <SpeedcamStatisticContainer className="not-dragable-zone">
          <RoundToggleButton
            disableValues={disableValues}
            activeValue={currentMode}
            values={buttonsValues}
            setActive={setCurrentMode}
          />
          <ButtonsBlock>{filterButtons}</ButtonsBlock>
          <StatisticChart data={data} interval={getInterval(timeLength)} />
        </SpeedcamStatisticContainer>
      )}
    </div>
  );
};
