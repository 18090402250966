import mapboxgl from "mapbox-gl";
import { BusStopsTypes } from "types";
import "./bus-stop.popup.scss";

const text_forms = ["секунда", "секунды", "секунд"];

export function secondsDeclension(n: number) {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) return text_forms[2];

  if (n1 > 1 && n1 < 5) return text_forms[1];

  if (n1 === 1) return text_forms[0];

  return text_forms[2];
}

const transportIcons: { [key: string]: string } = {
  А: "/img/transport-type/bus.svg",
  Э: "/img/transport-type/electrobus.svg",
  Тм: "/img/transport-type/tram.svg",
  Тб: "/img/transport-type/trolleybus.svg",
};

const baseClass = "dtm-popup__bus-stops";
const containerClass = `${baseClass}__content`;
const titleClass = `${containerClass}__title`;
const marginedTitleClass = `${titleClass} ${titleClass}_margin-bottom`;
const valueClass = `${containerClass}__value`;
const metaClass = `${containerClass}__meta`;
const routesClass = `${containerClass}__routes`;
const routeClass = `${routesClass}__route`;
const routeNumClass = `${routeClass}__num`;

export class BusStopPopup {
  private popup: mapboxgl.Popup;

  constructor(private readonly map: mapboxgl.Map) {
    this.popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
      className: baseClass,
      maxWidth: "200px",
    });
  }

  public readonly show = (lngLat: mapboxgl.LngLat, props: BusStopsTypes.PopupProps) => {
    this.popup.setLngLat(lngLat).setHTML(this.getPopupHTML(props)).addTo(this.map);
  };

  public readonly hide = () => {
    this.popup.remove();
  };

  public readonly getPopupHTML = (props: BusStopsTypes.PopupProps) => {
    const { id, time, routes } = props;
    const label = secondsDeclension(time ?? 0);

    const routesHTML = routes?.reduce(
      (acc, route) =>
        acc +
        `
      <div class="${routeClass}">
        <img src="${transportIcons[route.transportType]}" alt="" />
        <span class="${routeNumClass}" style="background-color: ${route.color}">${route.num}</span>
        ${route.name}
      </div>
    `,
      ""
    );

    const popupHTML = `
      <div class="${containerClass}">
      ${
        props.name
          ? `<span class="${marginedTitleClass}">
      Остановка: <span class="${valueClass}">${props.name ?? "н/д"}</span>
    </span>`
          : ""
      }
        
        <div class="${metaClass}">
          <span class="${titleClass}">
            ID ОП: <span class="${valueClass}">${id}</span>
          </span>
          ${
            props.boardId
              ? `<span class="${titleClass}">
            ID табло: <span class="${valueClass}">${props.boardId}</span>
          </span>`
              : ""
          }
          <span class="${marginedTitleClass}">
            Среднее время остановки: <span class="${valueClass}">${time ? `${time} ${label}` : "н/д"}</span>
          </span>
        </div>
        ${
          routes?.length
            ? `
              <span class="${titleClass}">
                Маршруты:
              </span>
              <div class="${routesClass}">
                ${routesHTML}
              </div>
            `
            : ""
        }
      </div>
    `;

    return popupHTML;
  };
}
