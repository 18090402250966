import { LineDataType } from "features/ctrl-detector/types";
import React, { FC } from "react";
import {
  TitleBlock,
  TransportFlowBlock,
  TransportFlowItem,
  TransportFlowItemsBlock,
} from "../../detector-popup.styled";
import { arrayFlowItemLabels } from "../consts";

type TransportFlowMiniBlockPropsType = {
  flowData?: Partial<LineDataType>;
  isLoad?: boolean;
};
export const TransportFlowMiniBlock: FC<TransportFlowMiniBlockPropsType> = (props) => {
  if (props.isLoad) {
    return null;
  }

  if (!props.flowData && !props.isLoad) {
    return (
      <TransportFlowBlock>
        <TitleBlock>
          <div className="title">Нет данных</div>
        </TitleBlock>
      </TransportFlowBlock>
    );
  }
  return (
    <TransportFlowBlock>
      <TitleBlock>
        <div className="title">Параметры транспортного потока</div>
      </TitleBlock>
      <TransportFlowItemsBlock>
        {arrayFlowItemLabels.map((item) => (
          <TransportFlowItem key={item.name}>
            <div className="label">{item.label}</div>
            <div className="value">{props.flowData?.[item.name]}</div>
          </TransportFlowItem>
        ))}
      </TransportFlowItemsBlock>
    </TransportFlowBlock>
  );
};
