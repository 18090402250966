import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { changeSelectedDayAC } from "old-store/view/action-creators";
import DatePicker, { ITYPES } from "@megapolis/datepicker-dev";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import localization from "moment/locale/ru";
import "./route-date-picker.scss";
moment.updateLocale("ru", localization);

const className = "route-date-picker";

class RouteDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (key) => {
    const { selectedDay, changeSelectedDay } = this.props;
    let newDay;
    if (key === "add") {
      newDay = moment(selectedDay).add(1, "day").toISOString(true);
    }
    if (key === "subtract") {
      newDay = moment(selectedDay).subtract(1, "day").toISOString(true);
    }
    changeSelectedDay(newDay);
  };

  handleChangeDay = (day) => {
    const { changeSelectedDay, selectedDay } = this.props;
    const hour = moment(selectedDay).get("hour");
    const minute = moment(selectedDay).get("minute");
    const newDay = moment(day).set("hour", hour).set("minute", minute).toISOString(true);
    changeSelectedDay(newDay);
  };

  render() {
    const { selectedDay } = this.props;
    return (
      <div className={className}>
        <DatePicker
          customClass={`${className}-calendar`}
          type={ITYPES.DATE}
          from={moment(selectedDay).toISOString(true)}
          format="DD.MM.YYYY"
          beforeViewDecorator
          beforeRenderDecorator={() => <CalendarIcon />}
          onChange={this.handleChangeDay}
        />
        <div className={`${className}-buttons`}>
          <button
            type="button"
            className={`${className}-button-left ${className}-button`}
            onClick={() => this.handleClick("subtract")}>
            <i className="dtm-arrow-down-icon" />
          </button>
          <button
            type="button"
            className={`${className}-button-right ${className}-button`}
            onClick={() => this.handleClick("add")}>
            <i className="dtm-arrow-down-icon" />
          </button>
        </div>
      </div>
    );
  }
}

RouteDatePicker.propTypes = {
  selectedDay: PropTypes.string.isRequired,
  changeSelectedDay: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedDay: state.view.selectedDay,
  }),
  (dispatch) => ({
    changeSelectedDay: (data) => dispatch(changeSelectedDayAC(data)),
  })
)(RouteDatePicker);
