import mapboxgl from "mapbox-gl";
import { ZoomScale } from "./zoom-scale";
import settings from "./tool.settings.json";

export type AbstractCollection<T> = { [key: string | number]: T };

export type ToolOptions = {
  map: mapboxgl.Map;
  onChange: <T>(collection: AbstractCollection<T>) => void;
};

export class Tool<T extends { id: string | number }> {
  protected zoomScale = new ZoomScale();
  protected collection: AbstractCollection<T> = {};
  protected ctx?: CanvasRenderingContext2D | null;
  protected canvas?: HTMLCanvasElement;
  protected _isVisible = true;
  protected _isEditable = false;

  public set isVisible(value: boolean) {
    this._isVisible = value;
    this.render();
  }

  public set isEditable(value: boolean) {
    this._isEditable = value;
    this.render();
  }

  public get arrCollection() {
    return Object.values<T>(this.collection);
  }

  public get settings() {
    return settings;
  }

  public get isVisible() {
    return this._isVisible;
  }

  public get isEditable() {
    return this._isEditable;
  }

  protected get map() {
    return this.options.map;
  }

  protected get editButtonSize() {
    const zoom = this.map.getZoom();
    const stopper = 10;
    if (zoom <= stopper) return this.getButtonSize(stopper + (stopper - zoom));
    return this.getButtonSize(zoom);
  }

  protected get onChange() {
    return this.options.onChange;
  }

  constructor(protected options: ToolOptions) {
    this.createCanvas();
    this.map.on("resize", this.handleResize);
    this.map.on("move", this.handleMove);
  }

  private handleResize = () => {
    this.updateScale(this.ctx);
    this.render();
  };

  private handleMove = () => {
    this.render();
  };

  protected updateScale(ctx?: CanvasRenderingContext2D | null) {
    let scale = 1;
    if (!ctx) return;
    const { canvas } = ctx;
    let width = canvas.offsetWidth;
    let height = canvas.offsetHeight;
    const devicePixelRatio = window.devicePixelRatio || 1;
    const backingStoreRatio =
      (ctx as any)?.webkitBackingStorePixelRatio ||
      (ctx as any)?.mozBackingStorePixelRatio ||
      (ctx as any)?.msBackingStorePixelRatio ||
      (ctx as any)?.oBackingStorePixelRatio ||
      (ctx as any)?.backingStorePixelRatio ||
      1;
    if (devicePixelRatio !== backingStoreRatio) {
      scale = devicePixelRatio / backingStoreRatio;
    }

    height *= scale;
    width *= scale;
    if (!ctx) return;
    ctx.canvas.height = height;
    ctx.canvas.width = width;
    ctx.scale(scale, scale);
  }

  private createCanvas = () => {
    const container = document.querySelector(".mapboxgl-canvas-container");
    this.canvas = document.createElement("canvas");
    this.canvas.className = "megapolis-tools-canvas";
    this.canvas.style.position = "absolute";
    this.canvas.style.top = "0";
    this.canvas.style.left = "0";
    this.canvas.style.width = "100%";
    this.canvas.style.height = "100%";
    container?.appendChild(this.canvas);
    this.ctx = this.canvas.getContext("2d");
    this.updateScale(this.ctx);
  };

  private getButtonSize = (zoom: number) => {
    return this.settings.defaultEditPointRadius / (zoom / 2.5);
  };

  protected drawEditPoint = (point: mapboxgl.Point) => {
    if (!this.ctx) return;

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.fillStyle = "#ffffff";
    this.ctx.strokeStyle = this.settings.defaultStrokeColor;
    this.ctx.lineWidth = 1;
    this.ctx.globalAlpha = 1;
    this.ctx.setLineDash([]);
    this.ctx.arc(point.x, point.y, this.editButtonSize, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();
  };

  protected drawPolygon = (points: number[][]) => {
    points.forEach((coordinates, index) => {
      if (!this.ctx || !this.map) return;

      const point = this.map.project(coordinates as mapboxgl.LngLatLike);

      if (index === 0) {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.fillStyle = this.settings.defaultColor;
        this.ctx.globalAlpha = this.settings.defaultOpacity;
        this.ctx.strokeStyle = this.settings.defaultStrokeColor;
        this.ctx.lineWidth = this.settings.defaultLineWidth;
        return this.ctx.moveTo(point.x, point.y);
      }

      this.ctx.lineTo(point.x, point.y);

      if (index === points.length - 1) {
        const startPoint = this.map.project(points[0] as mapboxgl.LngLatLike);
        this.ctx.lineTo(startPoint.x, startPoint.y);
        this.ctx.fill();
        this.ctx.globalAlpha = 1;
        this.ctx.setLineDash(this.settings.defaultLineDash);
        this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.restore();
      }
    });
  };

  protected getLineLength = (start: mapboxgl.Point, end: mapboxgl.Point) => {
    const dx = (start.x - end.x) ** 2;
    const dy = (start.y - end.y) ** 2;
    return dx + dy;
  };

  protected subscribe = () => {};
  protected unsubscribe = () => {};
  public create = () => {};
  public render = () => {};

  public clear = () => {
    this.ctx?.clearRect(0, 0, 10000, 10000);
  };

  public setData = (data: Tool<T>["collection"]) => {
    this.collection = data;
    this.render();
  };

  public getData = () => {
    return this.collection;
  };

  public destroy = () => {
    this.collection = {};
    this.map.off("resize", this.handleResize);
    this.map.off("move", this.render);
    this.canvas?.remove();
  };
}
