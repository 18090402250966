import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { NavigationGroupeLayers } from "components";
import * as observer from "observer";
import { temporaryBlockingSlice } from "../../store/slice";
import { TemporaryBlockingController } from "../../controller";
import * as consts from "./temporary-blocking.consts";

let controller: TemporaryBlockingController;

const useSubscribe = (isActive: boolean) => {
  const token = useSelector((state: GState) => state.oidc.user.access_token);
  const handleMapInit = useCallback(
    (map: mapboxgl.Map) => {
      controller = new TemporaryBlockingController(map, isActive, token);
    },
    [isActive]
  );

  useEffect(() => {
    observer.on(observer.EVENTS.INIT_MAP, handleMapInit);
    return () => {
      observer.off(observer.EVENTS.INIT_MAP, handleMapInit);
    };
  }, [handleMapInit]);
};

const useController = (isActive: boolean) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      dispatch(temporaryBlockingSlice.actions.setIsActive(!isActive));
    },
    [dispatch, isActive]
  );

  useEffect(() => {
    if (!controller) return;
    controller.update(isActive);

    return controller?.destroy;
  }, [isActive]);

  return { handleClick };
};

export const CtrlTemporaryBlocking = () => {
  const { isActive } = useSelector((store: GState) => store.view.blockingTemporary);
  const { handleClick } = useController(isActive);
  useSubscribe(isActive);

  return (
    <div className="ctrl-temporary-blocking">
      <NavigationGroupeLayers.Item
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isActive={isActive}
        onClick={handleClick}
      />
    </div>
  );
};
