/* eslint-disable prefer-destructuring */
// @ts-nocheck
import { IControl, Layer, LineLayer, Map as mapboxMap, SymbolLayer } from "mapbox-gl";
import { length as featureLength, lineChunk, lineString } from "@turf/turf";
import { Feature } from "geojson";
import moment from "moment";
import DtpLayer from "../../map-helpers/layers/dtp-layer";
import getBeforeId, {
  LayerDash0,
  LayerDash1,
  LayerDash2,
  LayerDash3,
  LayerFull,
  LayerIcon,
} from "../../map-helpers/order-layers";
import { BLOCKING_PLAN_MAP_IMAGE_KEY, icons } from "../../map-helpers/assets/map-svg-icons";
import { APP_ENV } from "app-env";

declare interface DataResponse {
  incidentGeoJsons: {
    featureCollection: {
      features: Array<Feature>;
      type: string;
    };
    incidentInfo: any;
  }[];
}

type beforeIdFunction = () => string;
declare interface OptionsOverlapControl {
  token: string;
  beforeId?: string | Array<string> | beforeIdFunction;
  url?: string;
}

export const events = {
  show: "show",
  hide: "hide",
  error: "error",
  click: "click",
  hover: "hover",
  leave: "leave",
  move: "move",
  loadStart: "loadStart",
  loadEnd: "loadEnd",
};

export default class OverlapControl implements IControl {
  private token: string;

  private map: mapboxMap;

  private hover?: Feature<mapboxgl.GeoJSONGeometry, { [name: string]: any }>;

  private listeners: Map<string, Array<Function>> = new Map();

  private button: HTMLButtonElement = document.createElement("button");

  defaultPosition = "top-right";

  private show = false;

  private requestController?: AbortController;

  private layers: Array<string> = [LayerIcon, LayerDash0, LayerDash1, LayerDash2, LayerDash3, LayerFull];

  private layerDash0: LineLayer = {
    id: LayerDash0,
    type: "line",
    source: LayerDash0,
    paint: {
      "line-width": 2,
      "line-opacity": 0.85,
      "line-dasharray": [1, 3],
      "line-color": ["get", "color"],
    },
  };

  private layerDash1: Layer = {
    id: LayerDash1,
    type: "line",
    source: LayerDash1,
    paint: {
      "line-width": 2,
      "line-opacity": 0.85,
      "line-dasharray": [2, 2],
      "line-color": ["get", "color"],
    },
  };

  private layerDash2: Layer = {
    id: LayerDash2,
    type: "line",
    source: LayerDash2,
    paint: {
      "line-width": 2,
      "line-opacity": 0.85,
      "line-dasharray": [3, 2],
      "line-color": ["get", "color"],
    },
  };

  private layerDash3: Layer = {
    id: LayerDash3,
    type: "line",
    source: LayerDash3,
    paint: {
      "line-width": 2,
      "line-opacity": 0.85,
      "line-dasharray": [4, 1],
      "line-color": ["get", "color"],
    },
  };

  private layerFull: Layer = {
    id: LayerFull,
    type: "line",
    source: LayerFull,
    paint: {
      "line-width": 2,
      "line-opacity": 0.85,
      "line-color": ["get", "color"],
    },
  };

  private readonly layerIcon: SymbolLayer = {
    id: LayerIcon,
    type: "symbol",
    source: LayerIcon,
    layout: {
      "icon-image": [
        "case",
        ["boolean", ["has", "icon-image"], false],
        ["get", "icon-image"],
        BLOCKING_PLAN_MAP_IMAGE_KEY,
      ],
      "icon-anchor": ["get", "icon-anchor"],
      "icon-rotate": ["get", "icon-rotate"],
      "icon-size": ["interpolate", ["linear"], ["zoom"], 8, 0.1, 22, 1.5],
      "icon-allow-overlap": true,
      "icon-pitch-alignment": "map",
    },
  };

  url = APP_ENV.REACT_APP_DTM_INCIDENT_API;

  constructor(options: OptionsOverlapControl) {
    if (!options.token || typeof options.token !== "string") throw new Error('Ожидался options.token = "string"');

    this.beforeId = options.beforeId;
    this.token = options.token;
    if (options.url) this.url = options.url;

    this.updatePointLayer = options.updatePointLayer;
    this.loadData = this.loadData.bind(this);
    this.getDefaultPosition = this.getDefaultPosition.bind(this);
    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    this.dispatch = this.dispatch.bind(this);
    this.showLayer = this.showLayer.bind(this);
    this.hideLayer = this.hideLayer.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.mapMouseMove = this.mapMouseMove.bind(this);
    this.mapClick = this.mapClick.bind(this);
    this.pointLayer = new DtpLayer(this.map);
  }

  private loadData(date: string) {
    if (this.requestController) this.requestController.abort();
    const dateIso = decodeURIComponent(moment(date).toISOString());
    return new Promise((resolve, reject) => {
      this.requestController = new AbortController();
      const url = `${this.url}OverlapIncidents/geojson?dateTimeWhenActive=${dateIso}&pageSize=-1`;
      const option = {
        method: "GET",
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };

      fetch(url, option)
        .then((response) => {
          if (response.status === 200) return response.json();

          throw response;
        })
        .then((response) => resolve(response))
        .catch(reject);
    });
  }

  setBeforeId(idLayer: string | Array<string> | beforeIdFunction) {
    this.beforeId = idLayer;
  }

  get isHover(): boolean {
    return !!this.hover;
  }

  get isShow(): boolean {
    return this.show;
  }

  getLayer(): Layer {
    return this.layer;
  }

  moveLayerBefore(beforeLayerId: string) {
    if (this.show) this.map.moveLayer(this.layer.id, beforeLayerId);
  }

  getDefaultPosition() {
    return this.defaultPosition;
  }

  on(event: keyof typeof events, listener: Function) {
    const listeners = this.listeners.get(event);
    if (listeners) this.listeners.set(event, [listener, ...listeners]);
    else this.listeners.set(event, [listener]);
  }

  off(event: keyof typeof events, listener: Function) {
    const listeners = this.listeners.get(event);
    if (listeners)
      this.listeners.set(
        event,
        listeners.filter((_listener) => _listener !== listener)
      );
  }

  private dispatch(event: events, ...data: any) {
    if (!this.show) return;
    const listeners = this.listeners.get(event);
    if (listeners) listeners.forEach((listener) => listener(...data));
  }

  /** Method allows to create a default source object */
  private getDefSource = () => ({
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  /** Method allows to create a default source data object */
  private getDefSourceData = () => ({
    type: "FeatureCollection",
    features: [],
  });

  /**
   * Method allows to create a Point geojson feature from coordinates
   * @param coordinates - coordinates of your future feature
   */
  private getPointFeature = (coordinates: GeoJSON.Position, properties?: any) => ({
    type: "Feature",
    properties,
    geometry: {
      type: "Point",
      coordinates,
    },
  });

  /**
   * Method allows to get an overlap center
   * @param coordinates - coordinates of overlap feature from server
   */
  private getOverlapCenter = (feature) => {
    const coordinates = [...feature.geometry.coordinates];
    const line = lineString(coordinates);
    const lineLength = featureLength(line, { units: "meters" });
    const chucksCollection = lineChunk(line, lineLength / 2, {
      units: "meters",
    });
    return this.getPointFeature(
      chucksCollection.features.pop()?.geometry?.coordinates.shift() ?? [],
      feature.properties
    );
  };

  showLayer(date: string) {
    if (this.show) return;
    this.show = true;
    this.dispatch(events.show);

    this.map.addSource(LayerDash0, this.getDefSource());

    this.map.addSource(LayerDash1, this.getDefSource());
    this.map.addSource(LayerDash2, this.getDefSource());
    this.map.addSource(LayerDash3, this.getDefSource());
    this.map.addSource(LayerFull, this.getDefSource());
    this.map.addSource(LayerIcon, this.getDefSource());

    const beforeId = getBeforeId(LayerFull, this.map);

    this.map.addLayer(this.layerDash0, beforeId);
    this.map.addLayer(this.layerDash1, beforeId);
    this.map.addLayer(this.layerDash2, beforeId);
    this.map.addLayer(this.layerDash3, beforeId);
    this.map.addLayer(this.layerFull, beforeId);
    this.map.addLayer(this.layerIcon, beforeId);

    this.map.on("click", this.mapClick);
    this.map.on("mousemove", this.mapMouseMove);
    this.dispatch(events.loadStart);
    this.loadData(date)
      .then((data: DataResponse) => {
        this.updatePointLayer(data);
        this.dispatch(events.loadEnd);
        const sourceDash0 = this.getDefSourceData();
        const sourceDash1 = this.getDefSourceData();
        const sourceDash2 = this.getDefSourceData();
        const sourceDash3 = this.getDefSourceData();
        const sourceFull = this.getDefSourceData();
        const sourceIcons = this.getDefSourceData();

        data.incidentGeoJsons.forEach((item) => {
          item.featureCollection.features
            .filter((feature) => Array.isArray(feature?.geometry?.coordinates))
            .forEach((feature) => {
              // prompt: setting icons to the center of overlap
              sourceIcons.features.push(this.getOverlapCenter(feature));
              if (feature.properties.traversability && feature.properties.traversability === 3) {
                const percentOverlap = feature.properties.lanesNumber / feature.properties.blockedLanesNumber;
                if (percentOverlap === 1)
                  sourceFull.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#FF0000" },
                  });
                else
                  sourceDash3.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#FF0000" },
                  });
              } else if (feature.properties.traversability && feature.properties.traversability === 2) {
                const percentOverlap = feature.properties.lanesNumber / feature.properties.blockedLanesNumber;
                if (percentOverlap === 1)
                  sourceFull.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#000" },
                  });
                else
                  sourceDash2.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#000" },
                  });
              } else if (feature.properties.traversability && feature.properties.traversability === 1) {
                const percentOverlap = feature.properties.lanesNumber / feature.properties.blockedLanesNumber;
                if (percentOverlap === 1)
                  sourceFull.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#000" },
                  });
                else
                  sourceDash1.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#000" },
                  });
              } else {
                const percentOverlap = feature.properties.lanesNumber / feature.properties.blockedLanesNumber;
                if (percentOverlap === 1)
                  sourceFull.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#000" },
                  });
                else
                  sourceDash0.features.push({
                    ...feature,
                    properties: { ...feature.properties, color: "#000" },
                  });
              }
            });
        });

        this.map.getSource(LayerDash0).setData(sourceDash0);
        this.map.getSource(LayerDash1).setData(sourceDash1);
        this.map.getSource(LayerDash2).setData(sourceDash2);
        this.map.getSource(LayerDash3).setData(sourceDash3);
        this.map.getSource(LayerFull).setData(sourceFull);
        this.map.getSource(LayerIcon).setData(sourceIcons);
      })
      .catch((err) => {
        console.error(err);
        this.dispatch(events.error, err);
      });
  }

  hideLayer() {
    if (!this.show) return;
    this.show = false;
    this.layers.forEach((layer) => {
      if (this.map.getLayer(layer)) this.map.removeLayer(layer);
      if (this.map.getSource(layer)) this.map.removeSource(layer);
    });
    this.map.off("click", this.mapClick);
    this.map.off("mousemove", this.mapMouseMove);
    this.dispatch(events.hide);
  }

  private mapMouseMove(event: any) {
    const features = this.map.queryRenderedFeatures(event.point, {
      layers: this.layers,
    });
    if (!features || features.length === 0) {
      if (this.hover) {
        this.dispatch(events.leave, {
          point: event.point,
          lngLat: event.lngLat,
          originalEvent: event.originalEvent,
          features,
        });
        this.hover = null;
      }
      return;
    }
    this.dispatch(events.move, {
      point: event.point,
      lngLat: event.lngLat,
      originalEvent: event.originalEvent,
      features,
    });
    if (!this.hover) {
      this.hover = features[0];
      this.dispatch(events.hover, {
        point: event.point,
        lngLat: event.lngLat,
        originalEvent: event.originalEvent,
        features,
      });
    }
  }

  private mapClick(event: any) {
    const features = this.map.queryRenderedFeatures(event.point, {
      layers: this.layers,
    });
    if (!features || features.length === 0) return;
    this.dispatch(events.click, {
      point: event.point,
      lngLat: event.lngLat,
      originalEvent: event.originalEvent,
      features,
    });
  }

  private loadImage = (event: Event & { id: EIcon }) => {
    const key = event.id;
    const svg = icons[key];
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const src = URL.createObjectURL(blob);
    const newImage = new Image();
    newImage.setAttribute("src", src);
    newImage.onload = () => {
      const checkImage = this.map.hasImage(key);
      if (!checkImage) this.map.addImage(key, newImage);
    };
  };

  onAdd(map: mapboxMap) {
    this.map = map;
    const eventName = "styleimagemissing";
    this.map.on(eventName, this.loadImage);
    this.listeners.set(eventName, this.loadImage);
    return this.button;
  }

  onRemove() {
    // удаляем всех слушателей
    this.listeners.clear();
  }
}
