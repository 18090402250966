import { MapLayer, Events } from "./map-layer";

export abstract class VectorTileLayer extends MapLayer {
  protected abstract addSource(url: string): void;

  protected abstract addLayer(): void;

  protected addActiveLayer?(): void;

  protected addHoverLayer?(): void;

  public add(url: string) {
    this.remove();
    this.addSource(url);
    this.addLayer();
    if (this.addActiveLayer) this.addActiveLayer();
    if (this.addHoverLayer) this.addHoverLayer();
    this.dispatch(Events.addLayer);
  }

  public update(url: string) {
    this.add(url);
  }
}
