import React from "react";
import { Incident } from "api/router/model/analysisResponse";
import Icon from "./event-6.png";
import "./index.scss";
import moment from "moment";

const baseClass = "sector-analysis-tooltip-incident";

export const SectorAnalysisTooltipIncident = (props: { name: string; id: number; begin: string }) => {
  const { name, id, begin } = props;

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__header`}>
        <img className={`${baseClass}__icon`} src={Icon} alt={name} />
        <div>
          <a
            className={`${baseClass}__title`}
            href={`http://roads.mos.ru/monitor/event/detailsnew/${id}`}
            rel="noreferrer"
            target="_blank">
            {name} № {id} от {moment(begin).format("h:mm:ss DD.MM.YYYY")}
          </a>
        </div>
      </div>
      {/* <div className={`${baseClass}__body`}>
                {description && <div className={`${baseClass}__description`}>{description}</div>}
            </div> */}
    </div>
  );
};

export const SectorAnalysisTooltipIncidentList = ({ incidents }: { incidents?: Incident[] }) => {
  if (!incidents) return null;

  return (
    <div className={`${baseClass}__list`}>
      {incidents.map((incident, key) => {
        const {
          begin,
          id,
          type: { name },
        } = incident.info;
        return <SectorAnalysisTooltipIncident key={key} name={name} id={id} begin={begin} />;
      })}
    </div>
  );
};
