import { RouteCosting } from "api/router/model/router";
import { FeatureCollectionIGeometryObjectOverlapIncidentProperties } from "../../api/incident/model/featureCollectionIGeometryObjectOverlapIncidentProperties";

export interface RouteProperties {
  traffic: number;
  trafficColor: string;
  speed?: number;
  timePercent: number;
  length: number;
  unitsCount: number;
  angles: number[];
  unitsCountColor: string | null;
}

export interface RouteSummary {
  min_lon: number;
  max_lon: number;
  max_lat: number;
  time: number;
  compare_time: number;
  length: number;
  min_lat: number;
}

export enum TravelType {
  Bus = "bus",
  Foot = "foot",
  Metro = "metro",
}

export type RouteManeuver = {
  begin_shape_index: number;
  compare_time: number;
  end_shape_index: number;
  instruction: string;
  length: number;
  time: number;
  travel_mode: string;
  travel_type: TravelType;
  type: number;
  verbal_pre_transition_instruction: string;
  transit_info: {
    route_id: number;
    transit_stops: {
      stop_id: number;
    }[];
  };
};

export type RouteLegs = {
  maneuvers: RouteManeuver[];
  shape: string;
  summary: RouteSummary;
};

export type IncidentProperties = {
  IncidentId: number;
  OSMWayID: number;
};

export type TrafficLightProperties = {
  DistanceFromStart: number;
  FacilityId: number;
  FromWayId: number;
  ToWayId: number;
};

export interface RouteResponse {
  /** ID маршрута */
  routeID: string;
  /** Балл пробки вдоль маршрута */
  score: number;
  /** Индекс дорожных заторов */
  scoreIdz: number;
  /** Средняя скорость */
  averageSpeed: number;
  /** Длина пробки в процентах */
  jamLengthPercent: number;
  /** Длинна пути, км */
  length: number;
  /** Время, в секундах */
  time: number;
  /** Сравнительное время, в секундах */
  compareTime: number;
  trafficLights?: GeoJSON.FeatureCollection<GeoJSON.Point, TrafficLightProperties>;
  /** Инциденты */
  incidents?: GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.Point, IncidentProperties>;
  paintedPath?: GeoJSON.FeatureCollection<GeoJSON.LineString, RouteProperties>;
  /** Подробный ответ */
  original: {
    trip: {
      language: string;
      summary: RouteSummary;
      units: string;
      legs: Array<RouteLegs>;
      status_message: string;
      status: number;
    };
  };
  /** сравнение маршрута с яндексом */
  comparison?: RouteComparison | null;

  // TODO убрать
  withTraffic: boolean;
}

export interface RouteComparison {
  routerName: string;
  isSucceed: boolean;
  time: number;
  length: number;
  path: FeatureCollectionIGeometryObjectOverlapIncidentProperties;
}

export interface RoutePoint {
  key: string;
  coor?: mapboxgl.LngLat | null;
  error?: string | null;
  address?: string | null;
  isMain?: boolean;
  isLoading?: boolean;
  isFavorite?: boolean;
  favoriteId?: number | null;
  favoriteName?: string | null;
}

export interface NewIntermediatePoint {
  key: string;
  keyBeforePaste: string;
}

export enum TIME_STATUS {
  last = "last",
  departure = "departure",
  arrival = "arrival",
}

export enum TYPE_VARIANTS {
  time = "time",
  length = "length",
  arrival = "arrival",
}

export type TimeStatus = TIME_STATUS.last | TIME_STATUS.departure | TIME_STATUS.arrival;

export type TypeVariants = TYPE_VARIANTS.arrival | TYPE_VARIANTS.length | TYPE_VARIANTS.time;

export interface RouteState {
  /** Points for routing on voluntary */
  points: Array<RoutePoint>;
  /** Points for routing on template */
  path: Array<RoutePoint>;
  /** Show maneuvers block or not */
  isManeuversShow: boolean;
  /** Active route block or not */
  isActive: boolean;
  /** Disabled event handlers or not */
  isDisabled: boolean;
  /** Route variants */
  routeVariants: Array<RouteResponse> | null;
  /** Use traffic forecast or not */
  useTrafficLayers: boolean;
  /** Disabled traffic checkbox or not */
  useTrafficLayersIsDisabled: boolean;
  /** Route is fetching or not */
  routeIsLoading: boolean;
  /** Selected index for route */
  activeIndexRoute: number;
  /** Selected focus */
  activePointKey: string | null;
  /** Selected time for arrival or departing */
  timeStatus: TimeStatus;
  /** Selected route variant from variants departure */
  routeTimeVariant: RouteResponse | null;
  /** Variants route departure */
  typeVariant: TypeVariants;
  /** Selected time period for route departure */
  period: number;
  /** Error message when failed fetching route */
  errorVariants: string | null;
  /** Error message when route length is below 50metres */
  isRouteLengthError: boolean;
  /** Start route time */
  routeTime: string | null;
  /** flag for disabling button */
  mapIsLoaded: boolean;
  /** Transport type */
  costing: RouteCosting;
  /** Template id */
  templateId: number | string;
  /** Is route 3d/2d diagram enable */
  isRouteDiagram: boolean;
  /** Template dictionary */
  favoriteRoadsDictionary: API.CdnAPI.FavoriteRoadsDictionary[];
  /** Будет ли маршрут построен с учетом метро */
  isRouteWithMetro: boolean;
  /** Будет ли добавлено модельное ДТП */
  isSimulatedDtp: boolean;
  /** Координаты модельного ДТП */
  simulatedDtp: [number, number] | null;
  /** Дата модельного ДТП */
  simulatedDtpDate: Date | null;
  /** Включен ли режим сравнения с Yandex */
  isCompareWithYandex: boolean;
  /** Идет ли загрузка варианта из Yandex */
  isYandexVariantLoading: boolean;
  /** Информация о маршрутах */
  routeInfos: {
    id: string;
    to: string;
    ref: string;
    from: string;
    note: string;
    type: string;
    route: string;
    colour: string;
  }[];
  /** Вариант, который пришел из Yandex */
  yandexVariant?: {
    route: RouteComparison;
    time?: string;
    score?: string;
    arrivalTime?: string;
    diffTime?: string;
  } | null;
  /** Открыт ли контрол с вариантами отправления */
  isRouteTimeVariantsOpen: boolean;
  /** Показывать ли разницу на карте */
  isShowYandexRoute: boolean;
}
