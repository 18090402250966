import { useState, useLayoutEffect, RefObject, useMemo } from "react";
import * as consts from "./time-slider.consts";

export const useWidth = (containerRef: RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (!containerRef.current || width) return;

    setWidth(containerRef.current.getBoundingClientRect().width);
  }, [containerRef.current, width]);

  return width;
};

export const useLabels = (values: number[], width: number, isDisabled = false) => {
  const maxCenterOffset = useMemo(
    () => (values[1] === consts.max ? consts.toCenterMaxOffset : consts.toCenterOffset),
    [values[1]]
  );
  const minOffset = useMemo(() => (values[0] * width) / consts.max - consts.toCenterOffset, [values[0], width]);
  const maxOffset = useMemo(() => (values[1] * width) / consts.max - maxCenterOffset, [values[1], width]);

  return (
    <>
      <span className={consts.labelClass} style={{ left: `${minOffset}px`, opacity: isDisabled ? 0.5 : 1 }}>
        {values[0]}ч.
      </span>
      <span className={consts.labelClass} style={{ left: `${maxOffset}px`, opacity: isDisabled ? 0.5 : 1 }}>
        {values[1]}ч.
      </span>
    </>
  );
};
