import React, { memo } from "react";
import { Checkbox } from "components";

type Props = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const RouteCompareWithCtrl: React.FC<Props> = memo((props) => {
  const { isChecked, isDisabled, onChange } = props;

  return (
    <div className="route-traffic-ctrl">
      <Checkbox
        label="Сравнить с маршрутом Яндекс"
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
        value="sector-analysis"
      />
    </div>
  );
});
