import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import RouteHistoryItem from "../route-history/route-history-item/route-history-item";
import RouteFavoriteItem from "./route-favorite-item/route-favorite-item";
import "./route-favorite.scss";

const className = "route-favorite";

class RouteFavorite extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSortedItems = () => {
    const { itemsFavorite, itemsHistory } = this.props;
    const unsortedItems = [...itemsFavorite, ...itemsHistory];
    const sortedItems = unsortedItems.sort((a, b) => {
      return moment(b.lastUse).valueOf() - moment(a.lastUse).valueOf();
    });
    return sortedItems;
  };

  render() {
    const items = this.getSortedItems();
    return (
      <div className={className}>
        <div className={`${className}-scroll`}>
          {items
            .map((el) => {
              const { address, name, id, location, type } = el;
              if (type === "favorite") {
                return <RouteFavoriteItem address={address} name={name} id={id} key={id} location={location} />;
              }
              if (type === "history") {
                return <RouteHistoryItem address={address} id={id} key={id} location={location} />;
              }
              return null;
            })
            .filter((el) => el !== null)}
        </div>
      </div>
    );
  }
}

RouteFavorite.propTypes = {
  itemsFavorite: PropTypes.array,
  itemsHistory: PropTypes.array,
};

export default connect((state) => ({
  itemsFavorite: state.favorite.items,
  itemsHistory: state.history.items,
}))(RouteFavorite);
