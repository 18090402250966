import { createStore, sample } from "effector";
import { events } from "./pedestrian-graph.events";
import * as reducers from "./pedestrian-graph.reducers";
import { State } from "./pedestrian-graph.types";

const initialState: State = {
  isDisabled: true,
  isVisible: false,
};

export const store = createStore<State>(initialState);

store.on(events.initializeIsVisible, reducers.setIsVisible);
store.on(events.setIsDisabled, reducers.handleSetIsDisabled);
store.on(events.restoreIsVisible, reducers.setDefaultIsVisible);
store.on(events.handleIsVisibleClick, reducers.setNextIsVisible);

sample({
  source: store,
  clock: events.restoreIsVisible,
  target: events.handleIsVisibleChange,
});

sample({
  source: store,
  clock: events.handleIsVisibleClick,
  fn: (source, clock) => {
    clock.stopPropagation();
    return source;
  },
  target: events.handleIsVisibleChange,
});
