import { initialStateFactory } from "./zone-statistics.utils";
import { State } from "./zone-statistics.types";

export const setNextIsVisible: Reducer<State, React.MouseEvent<HTMLButtonElement, MouseEvent>> = (state) => ({
  ...state,
  isActive: !state.isActive,
});
export const handleSetTimeRange: Reducer<State, State["timeRange"]> = (state, timeRange) => ({ ...state, timeRange });

export const handleSetIsDisabled: Reducer<State, State["isDisabled"]> = (state, isDisabled) => ({
  ...state,
  isDisabled,
});

export const handleSetZone: Reducer<State, State["zone"]> = (state, zone) => ({
  ...state,
  zone,
});

export const handleSetStatistic: Reducer<State, State["statistics"] | Error | null> = (state, statistics) => {
  if (!statistics || statistics instanceof Error) return { ...state, statistics: null };
  return { ...state, statistics };
};

export const handleSetDateRange: Reducer<State, State["dateRange"]> = (state, payload) => {
  if (payload.from === state.dateRange.from && payload.to === state.dateRange.to) return state;

  return {
    ...state,
    dateRange: {
      ...payload,
    },
  };
};

export const handleSetIsLoading: Reducer<State, State["isLoading"]> = (state, isLoading) => ({
  ...state,
  isLoading,
});

export const handleZoneStatisticsClose: Reducer<State, React.MouseEvent<HTMLButtonElement, MouseEvent>> = (state) => {
  return { ...state, ...initialStateFactory(), isDisabled: false, es: state.es };
};

export const handleSetEsFromRedux: Reducer<State, State["es"]> = (state, es) => ({ ...state, es });
