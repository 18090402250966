import {
  GRAPH_EDGES_HIGHLIGHT_ID,
  GRAPH_EDGES_ID,
  GRAPH_VERTICES_HIGHLIGHT_ID,
} from "../../../map-helpers/order-layers";
import { APP_ENV } from "../../../app-env";

let graphPopupTimeout;
// let roadClass;

const isOSMGraph = Boolean(APP_ENV.REACT_APP_DTM_ROUTER_GRAPH_API.includes("graph-osm"));

const queryFeaturesByGraph = (_map, e) => {
  const width = 2;
  const height = 2;
  const features = _map.queryRenderedFeatures(
    [
      [e.point.x - width / 2, e.point.y - height / 2],
      [e.point.x + width / 2, e.point.y + height / 2],
    ],
    { layers: [GRAPH_EDGES_ID] }
  );

  return features;
};

/**
 * Show highlight layers
 * @param {Number} idEdge - id of graph edge
 * @param {Object} map - map
 * @param {Number} fromNode - id of node from
 * @param {Number} toNode - id of node to
 */
function showHighlightLayer(idEdge, map, fromNode, toNode) {
  if (!isOSMGraph) {
    map.setFilter(GRAPH_EDGES_HIGHLIGHT_ID, ["==", "edgeID", idEdge]);
    map.setFilter(GRAPH_VERTICES_HIGHLIGHT_ID, ["any", ["==", "nodeID", fromNode], ["==", "nodeID", toNode]]);
  }
}

/**
 * Hide highlight layer
 * @param {Object} map - map
 */
function hideHighlightLayer(map) {
  if (!isOSMGraph) {
    map.setFilter(GRAPH_EDGES_HIGHLIGHT_ID, ["==", "edgeID", "none"]);
    map.setFilter(GRAPH_VERTICES_HIGHLIGHT_ID, ["==", "edgeID", "none"]);
  }
}

// Map for unsubscribe event listener
// eslint-disable-next-line no-underscore-dangle
let _map;

/**
 * Call when mouse leave area of graph edge
 * @param {Object} map - map
 */
function onMouseLeave() {
  if (typeof graphPopupTimeout === "number") clearTimeout(graphPopupTimeout);

  hideHighlightLayer(_map);
}

/**
 * Mouse move handler
 * @param {Event} e - event
 */
function handleMoveMouse(e) {
  const layer = _map.getLayer(GRAPH_EDGES_ID);

  if (layer) {
    const zoom = _map.getZoom();
    if (zoom > 15) {
      const features = queryFeaturesByGraph(_map, e);

      if (features.length > 0) {
        const props = features?.[0]?.properties;
        showHighlightLayer(props.edgeID, _map, props.fromNode, props.toNode);
        // onMouseEnter(_map, e, features);
      } else {
        // onMouseLeave(_map);
        hideHighlightLayer(_map);
      }
    } else {
      hideHighlightLayer(_map);
    }
  }
}

function handleClickOnGraph(e, setShowIsCard, setEdgeInfo) {
  const layer = _map.getLayer(GRAPH_EDGES_ID);

  if (layer) {
    const zoom = _map.getZoom();
    if (zoom > 15) {
      const features = queryFeaturesByGraph(_map, e);
      if (features.length) {
        setEdgeInfo(features[0].properties);
        setShowIsCard(true);
      } else {
        setShowIsCard(false);
        setEdgeInfo(null);
      }
    }
  }
}

/**
 * Get road classes
 * */
// async function getRoadClasses() {
//   const data = await RouterAPI.meta.roadclasses();
//   roadClass = data;
// }

/**
 * Show graph popup
 * @param {Object} map - map
 * @param {Boolean} graph - flag
 * @param setShowIsCard
 * @param setCardData
 */
export function toggleGraphCard(map, graph, setShowIsCard, setEdgeInfo) {
  if (graph) {
    _map = map;
    // getRoadClasses();
    map.on("mousemove", handleMoveMouse);
    map.on("mouseleave", onMouseLeave);
    map.on("mouseout", onMouseLeave);
    map.on("click", (e) => handleClickOnGraph(e, setShowIsCard, setEdgeInfo));
  } else {
    map.off("mousemove", handleMoveMouse);
    map.off("mouseleave", onMouseLeave);
    map.off("mouseout", onMouseLeave);
    map.off("click", (e) => handleClickOnGraph(e, setShowIsCard, setEdgeInfo));
  }
}
