import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Cookie from "js-cookie";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "../../../../../../store";
import { cookieOptions } from "../shared";

export const useValues = () => {
  return useSelector(
    (state: GState) => ({
      isShowAverageSpeed: state.sectorAnalysis.isShowAverageSpeed,
      isShowAverageTime: state.sectorAnalysis.isShowAverageTime,
    }),
    shallowEqual
  );
};

export const useCallbacks = () => {
  const dispatch = useDispatch();

  const handleIsShowAverageSpeedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      Cookie.set("isShowAverageSpeed", String(value), cookieOptions);
      dispatch(sectorAnalysisSlice.actions.setIsShowAverageSpeed(value));
    },
    [dispatch]
  );

  const handleIsShowAverageTimeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      Cookie.set("isShowAverageTime", String(value), cookieOptions);
      dispatch(sectorAnalysisSlice.actions.setIsShowAverageTime(value));
    },
    [dispatch]
  );

  return {
    handleIsShowAverageSpeedChange,
    handleIsShowAverageTimeChange,
  };
};
