export const baseClass = "travel-heatmap-card";
export const separatorClass = `${baseClass}__separator`;
export const graphicHeader = `${baseClass}__graphic-header`;
export const graphicHeaderHeading = `${graphicHeader}__heading`;
export const loaderClass = `${baseClass}__loader`;
export const headerClass = `${baseClass}__header`;
export const headerHeadingClass = `${headerClass}__heading`;
export const contentClass = `${baseClass}__content`;
export const contentHeadingClass = `${contentClass}__heading`;
export const periodClass = `${contentClass}__period`;
export const periodDateClass = `${periodClass}__date`;
export const countClass = `${contentClass}__count`;
export const accordionClass = `${contentClass}__accordion`;
export const accordionIndicatorClass = `${accordionClass}__indicator`;
export const panelBaseClass = `${contentClass}__panel`;
export const panelSector = `${panelBaseClass}__sector`;
