import { memo } from "react";
import { APP_ENV } from "app-env";
import { AdministrativeDivision } from "features";
import { EquipmentsGroup, BlockingGroup, RoadsGroup, WeatherGroup, GraphCompareButton } from "layout";

export const RightPanel = memo(() => {
  return (
    <>
      {APP_ENV.REACT_APP_CONFIGURATION?.layers?.administrativeDivision?.visibility === "on" && (
        <AdministrativeDivision />
      )}
      <WeatherGroup />
      {APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.visibility === "on" && <RoadsGroup />}
      <BlockingGroup />
      {APP_ENV.REACT_APP_CONFIGURATION?.layers?.equipments?.visibility === "on" && <EquipmentsGroup />}
      <GraphCompareButton />
    </>
  );
});
