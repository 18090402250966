import { createStore, sample } from "effector";
import { events } from "./bus-stops.events";
import { effects } from "./bus-stops.effects";
import * as reducers from "./bus-stops.reducers";
import { State } from "./bus-stops.types";

const initialState: State = {
  isDisabled: true,
  isVisible: false,
  es: [],
  from: null,
  to: null,
  trafficType: null,
};

export const store = createStore(initialState);

store.on(events.initializeIsVisible, reducers.setIsVisible);
store.on(events.setIsDisabled, reducers.handleSetIsDisabled);
store.on(events.setEsFromRedux, reducers.handleSetEsFromRedux);
store.on(events.restoreIsVisible, reducers.setDefaultIsVisible);
store.on(events.handleIsVisibleClick, reducers.setNextIsVisible);
store.on(events.setToDateFromRedux, reducers.handleSetToDateFromRedux);
store.on(events.setFromDateFromRedux, reducers.handleSetFromDateFromRedux);
store.on(events.setTrafficTypeFromRedux, reducers.handleSetTrafficTypeFromRedux);

sample({
  source: store,
  clock: events.handleIsVisibleClick,
  fn: (source, clock) => {
    clock.stopPropagation();
    return source;
  },
  target: events.handleIsVisibleChange,
});

sample({
  source: store,
  clock: events.restoreIsVisible,
  target: events.handleIsVisibleChange,
});

sample({
  clock: events.handleMissingBusStopInfo,
  target: effects.getBusStopInformation,
});
