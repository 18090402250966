import { memo, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import { ZoomControl } from "components";

type Props = {
  map: mapboxgl.Map | null;
};

export const Zoom = memo<Props>(({ map }) => {
  const handlePlusClick = useCallback(() => {
    map?.zoomIn();
  }, [map]);

  const handleMinusClick = useCallback(() => {
    map?.zoomOut();
  }, [map]);

  return (
    <div className="app__zoom">
      <ZoomControl onPlusClick={handlePlusClick} onMinusClick={handleMinusClick} />
    </div>
  );
});
