// CANVAS GEOMETRY
export const canvasWidth = 768;
export const canvasPaddingTop = 0;
export const canvasPaddingRight = 16;
export const canvasPaddingLeft = 16;

// BODY GEOMETRY
export const bodyWidth = 672;
export const bodyPaddingLeft = (canvasWidth - bodyWidth - canvasPaddingRight - canvasPaddingLeft) / 2;
export const bodyLeft = canvasPaddingLeft + bodyPaddingLeft;
export const bodyRight = bodyLeft + bodyWidth;

// GRAPH GEOMETRY
export const graphPaddingTop = 10;
export const graphPaddingBottom = 20;
export const graphTop = canvasPaddingTop;
export const graphBodyHeight = 100;
export const graphHeight = graphBodyHeight + graphPaddingTop + graphPaddingBottom;
export const graphBodyTop = graphTop + graphPaddingTop;
export const graphBodyBottom = graphBodyTop + graphBodyHeight;

// COLORS
export const trafficColors: { [key: number]: string } = {
  [-1]: "#c7c7c7",
  4: "#af2c04",
  3: "#fa501e",
  2: "#ffe24a",
  1: "#62c400",
  100: "#408000",
};

export const defaultTrafficCategory = 0;
export const compareColors: { [key: number]: string } = {
  [-4]: "#002E87",
  [-3]: "#0040BB",
  [-2]: "#0E5BEE",
  [-1]: "#3177FF",
  0: "#ffffff",
  1: "#FF7272",
  2: "#FF3030",
  3: "#E20000",
  4: "#960000",
};

export const averageSpeedColor = "#00b8fc";
export const averageTimeColor = "#fda600";
export const unitsCountColor = "#b051e0";
export const defaultTextColor = "#747474";
export const axisColor = "#e2e2e2";
export const mainTextColor = "#212121";
export const crossHairColor = "black";
export const gridColor = "#f3f3f3";

export const stepTicks = [50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000];
export const graphVerticalTicksCount = 5;
export const tickWidth = 6;
export const tickValueOffset = 12;
export const minScale = 1;
export const maxScale = 3;
