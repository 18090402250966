import { createStore } from "effector";
import Cookies from "js-cookie";
import { shared } from "shared";
import { State } from "./application-settings.types";

const cookieMapStyleId = Cookies.get("map-style");
const cookieIsEmptyPieces = Cookies.get("telemetry-is-empty-pieces");
const initialState: State = {
  isPopupVisible: false,
  activeMapStyle: shared.map.mapStyles.find(({ id }) => id === cookieMapStyleId) ?? shared.map.defaultStyle,
  telematics: {
    isEmptyPieces: cookieIsEmptyPieces ? cookieIsEmptyPieces === "true" : true,
  },
};

export const store = createStore<State>(initialState);
