import React from "react";
import cn from "classnames";
import { GState } from "documentations";
import { useSelector } from "react-redux";
import "./route-time-variants-tabs.scss";

const baseClass = "route-time-variants-tabs";

type TabType = "time" | "arrival" | "length";

type Props = {
  type: TabType;
  handleChangeValue: (value: TabType) => void;
};

type TabProps = {
  title: string;
  isActive: boolean;
  isDisabled?: boolean;
} & Props;

const RouteTimeVariantsTab = ({ type, title, isActive, isDisabled = false, handleChangeValue }: TabProps) => {
  const className = cn(`${baseClass}__btn`, {
    [`${baseClass}__btn_apply`]: isActive,
    [`${baseClass}__btn_disabled`]: isDisabled,
  });
  const handleClick = () => {
    handleChangeValue(type);
  };

  return (
    <button type="button" className={className} disabled={isDisabled} onClick={handleClick}>
      {title}
    </button>
  );
};

const RouteTimeVariantsTabs = ({ type, handleChangeValue }: Props) => {
  const costing = useSelector<GState>((store) => store.router.costing);

  return (
    <div className={baseClass}>
      <RouteTimeVariantsTab
        type="time"
        title="Время поездки"
        isActive={type === "time"}
        handleChangeValue={handleChangeValue}
      />
      <span className={`${baseClass}-separator`}>|</span>
      <RouteTimeVariantsTab
        type="arrival"
        title="Время прибытия"
        isActive={type === "arrival"}
        isDisabled={costing === "Template"}
        handleChangeValue={handleChangeValue}
      />
      <span className={`${baseClass}-separator`}>|</span>
      <RouteTimeVariantsTab
        type="length"
        title="Длина маршрута"
        isActive={type === "length"}
        handleChangeValue={handleChangeValue}
      />
    </div>
  );
};

export default RouteTimeVariantsTabs;
