import { sample, createEffect } from "effector";
import Cookies from "js-cookie";
import { shared } from "shared";
import { store } from "../application-settings";
import { events } from "../application-settings.events";

const whriteCookie = createEffect((mapStyle: MapStyle) => {
  Cookies.set("map-style", mapStyle.id, shared.global.cookieOptions);
});

export const whriteMapStyleToCookieSampleFactory = () => {
  sample({
    source: store,
    clock: events.handleActiveMapStyleChange,
    fn: (source) => source.activeMapStyle,
    target: whriteCookie,
  });
};
