import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as observer from "observer";
import { GState } from "documentations";
import { NavigationGroupeLayers } from "components";
import { CtrlWeather } from "features";
import { Popup } from "features/ctrl-weather/ctrl-weather";
import WeatherPopup from "features/ctrl-weather/popup";
import CtrlMeteostation from "features/ctrl-meteostation/ctrl-meteostation";
import { weatherSlice } from "old-store/weather";
import * as consts from "./weather-group.consts";
import { meteostationUpdateIsActive } from "old-store/global/actions";

import "./weather-group.scss";

const baseClass = "weather-group";

let map: mapboxgl.Map;

observer.on(observer.EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

export const WeatherGroup = () => {
  const popupsState = React.useState<Popup[]>([]);
  const [popups, setPopups] = popupsState;
  const dispatch = useDispatch();
  const { weatherIsActive, meteoIsActive } = useSelector((state: GState) => ({
    weatherIsActive: state.weather.isActive,
    meteoIsActive: state.global.meteostation,
  }));
  const isActive = weatherIsActive || meteoIsActive;
  const handleGroupClick = React.useCallback(() => {
    dispatch(meteostationUpdateIsActive(false));
    dispatch(weatherSlice.actions.toggleIsActive(false));
  }, [dispatch]);

  const handleClear = React.useCallback(() => setPopups([]), []);

  return (
    <>
      <div className={baseClass}>
        <NavigationGroupeLayers
          title={consts.groupe.title}
          iconNormalUrl={consts.groupe.iconNormalUrl}
          iconActiveUrl={consts.groupe.iconActiveUrl}
          isActive={isActive}
          onClick={handleGroupClick}>
          <CtrlWeather popupState={popupsState} />
          <CtrlMeteostation />
        </NavigationGroupeLayers>
      </div>
      {popups?.map((el) => (
        <WeatherPopup
          key={el.districtId}
          map={map}
          lat={el.lat}
          lng={el.lng}
          id={el.districtId}
          clearPopup={handleClear}
        />
      ))}
    </>
  );
};
