import { Middleware, MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { updateStateInUrl } from "utils/update-state-in-url";
import { SpeedcamController } from "../controller";
import { speedcamSlice } from "./slice";

let _store: MiddlewareAPI<any, GState>;
let speedcamController: SpeedcamController;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  speedcamController = new SpeedcamController(map, _store);
});

export const speedcamMiddleware: Middleware<GState> = (store) => (next) => async (action) => {
  next(action);

  if (!_store) _store = store;

  if (speedcamSlice.actions.setIsActive.match(action)) {
    speedcamController.update();
    updateStateInUrl("speedcam", action.payload);
  }
};
