import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Score0Icon } from "assets/icons/traffic/0.svg";
import { ReactComponent as Score1Icon } from "assets/icons/traffic/1.svg";
import { ReactComponent as Score2Icon } from "assets/icons/traffic/2.svg";
import { ReactComponent as Score3Icon } from "assets/icons/traffic/3.svg";
import { ReactComponent as Score4Icon } from "assets/icons/traffic/4.svg";
import { ReactComponent as Score5Icon } from "assets/icons/traffic/5.svg";
import { ReactComponent as Score6Icon } from "assets/icons/traffic/6.svg";
import { ReactComponent as Score7Icon } from "assets/icons/traffic/7.svg";
import { ReactComponent as Score8Icon } from "assets/icons/traffic/8.svg";
import { ReactComponent as Score9Icon } from "assets/icons/traffic/9.svg";
import { ReactComponent as Score10Icon } from "assets/icons/traffic/10.svg";
import { ReactComponent as ScoreDefaultIcon } from "assets/icons/traffic/default.svg";

const TrafficScore = ({ apply, onChange, titleScore, score }) => {
  const getTrafficScoreIcon = () => {
    switch (score) {
      case 0:
        return <Score0Icon />;
      case 1:
        return <Score1Icon />;
      case 2:
        return <Score2Icon />;
      case 3:
        return <Score3Icon />;
      case 4:
        return <Score4Icon />;
      case 5:
        return <Score5Icon />;
      case 6:
        return <Score6Icon />;
      case 7:
        return <Score7Icon />;
      case 8:
        return <Score8Icon />;
      case 9:
        return <Score9Icon />;
      case 10:
        return <Score10Icon />;
      default:
        return <ScoreDefaultIcon />;
    }
  };

  return (
    <button
      className={apply === true ? "ctrl-traffic-btn apply" : "ctrl-traffic-btn"}
      onClick={(evt) => onChange(evt)}
      title={titleScore}
      type="button">
      {getTrafficScoreIcon()}
    </button>
  );
};

TrafficScore.propTypes = {
  apply: PropTypes.bool.isRequired,
  titleScore: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default TrafficScore;
