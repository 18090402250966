import mapboxgl from "mapbox-gl";
import * as THREE from "three";

const settings = {
  fov: 75,
  aspect: 2,
  near: 0.1,
  far: 25,
  topBack: {
    width: 10,
    height: 4,
    depth: 0.1,
  },
  leftRight: {
    width: 4,
    height: 4,
    depth: 0.1,
  },
};

type Options = {
  layerId: string;
  source: string;
  sourceLayer: string;
};

const topTextureUrl = "img/textures/stop_top.png";
const leftTextureUrl = "img/textures/stop_left.png";
const rightTextureUrl = "img/textures/stop_right.png";
const backTextureUrl = "img/textures/stop_back.png";

export class BusStopLayer3D implements mapboxgl.CustomLayerInterface {
  public id;
  public type = "custom" as const;
  public renderingMode = "3d" as const;
  private renderer?: THREE.WebGLRenderer;
  private scene?: THREE.Scene;
  private camera?: THREE.Camera;
  private world?: THREE.Group;
  private topMaterial: THREE.MeshBasicMaterial | null = null;
  private leftMaterial: THREE.MeshBasicMaterial | null = null;
  private rightMaterial: THREE.MeshBasicMaterial | null = null;
  private backMaterial: THREE.MeshBasicMaterial | null = null;
  private cameraTransform?: THREE.Matrix4;
  private cameraCenter?: mapboxgl.MercatorCoordinate;
  private features: mapboxgl.MapboxGeoJSONFeature[] = [];
  private fillSceneTimeout?: number;
  private isVisible = false;

  constructor(private map: mapboxgl.Map, private options: Options) {
    this.id = this.options.layerId;
  }

  private toRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
  };

  private clearScene = () => {
    if (!this.world) return;

    while (this.world.children.length) {
      const item = this.world.children[this.world.children.length - 1];
      const mesh = item as THREE.Mesh;
      if (!Array.isArray(mesh.material)) {
        mesh.material?.dispose?.();
      }
      mesh.geometry?.dispose?.();
      this.world.remove(item);
    }
    this.world.clear();
  };

  private fillScene = () => {
    if (!this.world || !this.cameraCenter) return;
    if (!this.backMaterial || !this.topMaterial || !this.leftMaterial || !this.rightMaterial) return;

    const scale = this.cameraCenter.meterInMercatorCoordinateUnits();
    this.clearScene();

    for (let i = 0; i < this.features.length; i++) {
      const { geometry, properties } = this.features[i] as GeoJSON.Feature<GeoJSON.Point>;
      const angle: number = this.toRadians(properties?.angle ?? 0);
      const coordinates = geometry.coordinates as [number, number];
      const mercator = mapboxgl.MercatorCoordinate.fromLngLat(coordinates);
      const dx = -(this.cameraCenter.x - mercator.x) / scale;
      const dy = (this.cameraCenter.y - mercator.y) / scale;

      const backGeometry = new THREE.BoxGeometry(
        settings.topBack.width,
        settings.topBack.height,
        settings.topBack.depth
      );
      const topGeometry = new THREE.BoxGeometry(
        settings.topBack.width,
        settings.topBack.height,
        settings.topBack.depth
      );
      const leftGeometry = new THREE.BoxGeometry(
        settings.leftRight.width,
        settings.leftRight.height,
        settings.leftRight.depth
      );
      const rightGeometry = new THREE.BoxGeometry(
        settings.leftRight.width,
        settings.leftRight.height,
        settings.leftRight.depth
      );

      const backRectangle = new THREE.Mesh(backGeometry, this.backMaterial);
      const topRectangle = new THREE.Mesh(topGeometry, this.topMaterial);
      const leftRectangle = new THREE.Mesh(leftGeometry, this.leftMaterial);
      const rightRectangle = new THREE.Mesh(rightGeometry, this.rightMaterial);

      const radius = settings.topBack.width / 2;
      const sideRadius = settings.leftRight.width / 2;

      const leftAngle = Math.PI / 2 - angle;
      const leftCenterX = dx + radius * Math.cos(leftAngle);
      const leftCenterY = dy + radius * Math.sin(leftAngle);
      const leftX = leftCenterX + sideRadius * Math.cos(leftAngle + Math.PI / 2);
      const leftY = leftCenterY + sideRadius * Math.sin(leftAngle + Math.PI / 2);

      const rightAngle = Math.PI / 2 - angle + Math.PI;
      const rightCenterX = dx + radius * Math.cos(rightAngle);
      const rightCenterY = dy + radius * Math.sin(rightAngle);
      const rightX = rightCenterX + sideRadius * Math.cos(rightAngle - Math.PI / 2);
      const rightY = rightCenterY + sideRadius * Math.sin(rightAngle - Math.PI / 2);

      const topRadius = settings.topBack.height / 2;
      const topX = dx + topRadius * Math.cos(Math.PI / 2 - angle + Math.PI / 2);
      const topY = dy + topRadius * Math.sin(Math.PI / 2 - angle + Math.PI / 2);

      leftRectangle.translateX(leftX);
      leftRectangle.translateY(leftY);
      leftRectangle.translateZ(settings.topBack.height / 2);
      leftRectangle.rotateX(Math.PI / 2);
      leftRectangle.rotateY(Math.PI / 2 - angle + Math.PI / 2);

      rightRectangle.translateX(rightX);
      rightRectangle.translateY(rightY);
      rightRectangle.translateZ(settings.topBack.height / 2);
      rightRectangle.rotateX(Math.PI / 2);
      rightRectangle.rotateY(Math.PI / 2 - angle + Math.PI / 2);

      backRectangle.translateX(dx);
      backRectangle.translateY(dy);
      backRectangle.translateZ(settings.topBack.height / 2);
      backRectangle.rotateX(Math.PI / 2);
      backRectangle.rotateY(Math.PI - angle - Math.PI / 2);

      topRectangle.translateX(topX);
      topRectangle.translateY(topY);
      topRectangle.translateZ(settings.topBack.height);
      topRectangle.rotateZ(Math.PI - angle - Math.PI / 2);

      this.world.add(leftRectangle);
      this.world.add(backRectangle);
      this.world.add(rightRectangle);
      this.world.add(topRectangle);
    }
  };

  private createLightning = () => {
    const skyColor = 0xb1e1ff; // light blue
    const groundColor = 0xb97a20; // brownish orange

    this.scene?.add(new THREE.AmbientLight(0xffffff, 0.25));
    this.scene?.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(-70, -70, 100).normalize();
    this.scene?.add(directionalLight);
  };

  private handleViewportChange = () => {
    if (!this.isVisible) {
      this.features = [];
      return this.clearScene();
    }

    window.clearTimeout(this.fillSceneTimeout);
    this.fillSceneTimeout = window.setTimeout(() => {
      this.features = this.map.querySourceFeatures(this.options.source, {
        sourceLayer: this.options.sourceLayer,
      });
      this.fillScene();
    }, 250);
  };

  /* ----------- Public API ----------- */

  public readonly onAdd = (map: mapboxgl.Map, gl: WebGLRenderingContext) => {
    this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6);
    this.scene = new THREE.Scene();
    this.world = new THREE.Group();
    this.world.name = "world";
    this.scene.add(this.world);

    this.cameraCenter = mapboxgl.MercatorCoordinate.fromLngLat(this.map.getCenter(), 0);

    const { x, y, z } = this.cameraCenter;
    this.world.scale.setScalar(this.cameraCenter.meterInMercatorCoordinateUnits());
    this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z ?? 0).scale(new THREE.Vector3(1, -1, 1));

    const textureLoader = new THREE.TextureLoader();
    const topTexture = textureLoader.load(topTextureUrl);
    const leftTexture = textureLoader.load(leftTextureUrl);
    const rightTexture = textureLoader.load(rightTextureUrl);
    const backTexture = textureLoader.load(backTextureUrl);

    this.topMaterial = new THREE.MeshBasicMaterial({
      map: topTexture,
      side: THREE.DoubleSide,
    });

    this.leftMaterial = new THREE.MeshBasicMaterial({
      map: leftTexture,
      side: THREE.DoubleSide,
    });

    this.rightMaterial = new THREE.MeshBasicMaterial({
      map: rightTexture,
      side: THREE.DoubleSide,
    });

    this.backMaterial = new THREE.MeshBasicMaterial({
      map: backTexture,
      side: THREE.DoubleSide,
    });

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;

    this.map.on("rotatestart", this.handleViewportChange);
    this.map.on("rotateend", this.handleViewportChange);
    this.map.on("dragstart", this.handleViewportChange);
    this.map.on("dragend", this.handleViewportChange);
    this.map.on("zoom", this.handleViewportChange);

    this.createLightning();
    this.handleViewportChange();
    this.map.triggerRepaint();
  };

  public readonly onRemove = () => {
    this.map.off("rotatestart", this.handleViewportChange);
    this.map.off("rotateend", this.handleViewportChange);
    this.map.off("dragstart", this.handleViewportChange);
    this.map.off("dragend", this.handleViewportChange);
    this.map.off("zoom", this.handleViewportChange);
  };

  public readonly setVisibility = (isVisible: boolean) => {
    this.isVisible = isVisible;

    if (!this.isVisible) {
      this.features = [];
      this.clearScene();
    } else {
      this.handleViewportChange();
    }
  };

  public readonly render = (gl: WebGLRenderingContext, matrix: number[]) => {
    if (!this.isVisible) return;
    if (!this.renderer || !this.scene || !this.camera || !this.cameraTransform) return;

    this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
  };

  public readonly destroy = () => {
    this.topMaterial?.map?.dispose();
    this.topMaterial?.dispose();
    this.leftMaterial?.map?.dispose();
    this.leftMaterial?.dispose();
    this.rightMaterial?.map?.dispose();
    this.rightMaterial?.dispose();
    this.backMaterial?.map?.dispose();
    this.backMaterial?.dispose();

    this.renderer?.clear();
    this.renderer?.dispose();
  };
}
