import { APP_ENV } from "app-env";
import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { GeocoderAPI } from "../geocoder";

const parseWeekdays = (weekdays: number[]) => {
  return weekdays.reduce((acc, weekday, index) => {
    if (index === weekdays.length - 1) return acc.concat(String(weekday));
    return acc.concat(`${weekday},`);
  }, "");
};

const parseH3Indexes = (h3Collection: string[]) => {
  return h3Collection.reduce((acc, h3Index, index) => {
    if (index === h3Collection.length - 1) return acc.concat(h3Index);
    return acc.concat(`${h3Index},`);
  }, "");
};

export class TravelHeatmapAPI {
  public readonly getSectorInfo = async (
    payload: TravelHeatmapTypes.LoadSectorInfoPayload,
    abortController?: AbortController
  ): Promise<TravelHeatmapTypes.SectorInformation | Error> => {
    const { h3Index, coordinates } = payload;
    const [lng, lat] = coordinates;
    const addressData = await GeocoderAPI.geocode.address({ lng, lat });
    return {
      h3Index,
      address: addressData?.address || "",
      adminAreaShortString: addressData?.adminAreaShortString || "",
      adminAreaString: addressData?.adminAreaString || "",
    };
  };

  public readonly getMax = async (payload: TravelHeatmapTypes.RequestStatisticPayload) => {
    const { dateStart, dateEnd, transportType, directionMode, timeStartSecs, timeEndSecs, weekdays } = payload;

    const weekdaysString = parseWeekdays(weekdays);
    const params = new URLSearchParams();
    params.set("DateStart", dateStart);
    params.set("DateEnd", dateEnd);
    params.set("TypeTS", transportType);
    params.set("DirectionTrip", directionMode);
    params.set("TimeStartInsideDay", String(timeStartSecs));
    params.set("TimeEndInsideDay", String(timeEndSecs));

    if (weekdays.length) {
      params.set("WeekDays", weekdaysString);
    }

    const response = await fetch(
      `${APP_ENV.REACT_APP_DTM_RNIS_API}external/v1/h3/statistics/max?` + params.toString(),
      {
        headers: {
          "content-type": "application/json",
          "authorization": `Bearer ${shared.global.accessToken.value}`,
        },
      }
    )
      .then((res) => res.json())
      .catch(console.log);

    if (typeof response !== "number") return new Error();

    return response;
  };

  public readonly getCorrespondenceMax = async (payload: TravelHeatmapTypes.RequestStatisticPayload) => {
    const { dateStart, dateEnd, transportType, directionMode, h3Indexes, timeStartSecs, timeEndSecs, weekdays } =
      payload;

    const weekdaysString = parseWeekdays(weekdays);
    const sectorsString = parseH3Indexes(h3Indexes);
    const params = new URLSearchParams();
    params.set("DateStart", dateStart);
    params.set("DateEnd", dateEnd);
    params.set("TypeTS", transportType);
    params.set("DirectionTrip", directionMode);
    params.set("TimeStartInsideDay", String(timeStartSecs));
    params.set("TimeEndInsideDay", String(timeEndSecs));

    if (h3Indexes.length) {
      params.set("h3indexes_16", sectorsString);
    }

    if (weekdays.length) {
      params.set("WeekDays", weekdaysString);
    }

    const response = await fetch(
      `${APP_ENV.REACT_APP_DTM_RNIS_API}external/v1/h3/statistics/correspondence/max?` + params.toString(),
      {
        headers: {
          "content-type": "application/json",
          "authorization": `Bearer ${shared.global.accessToken.value}`,
        },
      }
    )
      .then((res) => res.json())
      .catch(console.log);

    if (typeof response !== "number") return new Error();

    return response;
  };

  public readonly getWeekDays = async (
    payload: TravelHeatmapTypes.RequestStatisticPayload,
    abortController?: AbortController
  ) => {
    const { dateStart, dateEnd, transportType, directionMode, h3Indexes, timeStartSecs, timeEndSecs, weekdays } =
      payload;

    const weekdaysString = parseWeekdays(weekdays);
    const sectorsString = parseH3Indexes(h3Indexes);
    const params = new URLSearchParams();
    params.set("DateStart", dateStart);
    params.set("DateEnd", dateEnd);
    params.set("TypeTS", transportType);
    params.set("DirectionTrip", directionMode);
    params.set("TimeStartInsideDay", String(timeStartSecs));
    params.set("TimeEndInsideDay", String(timeEndSecs));

    if (h3Indexes.length) {
      params.set("h3indexes_16", sectorsString);
    }

    if (weekdays.length) {
      params.set("WeekDays", weekdaysString);
    }

    const response = await fetch(
      `${APP_ENV.REACT_APP_DTM_RNIS_API}external/v1/h3/statistics/weekdays?` + params.toString(),
      {
        headers: {
          "content-type": "application/json",
          "authorization": `Bearer ${shared.global.accessToken.value}`,
        },
        signal: abortController?.signal,
      }
    )
      .then((res) => res.json())
      .catch(console.error);

    return response;
  };

  public readonly getHours = async (
    payload: TravelHeatmapTypes.RequestStatisticPayload,
    abortController?: AbortController
  ) => {
    const { dateStart, dateEnd, transportType, directionMode, h3Indexes, timeStartSecs, timeEndSecs, weekdays } =
      payload;

    const weekdaysString = parseWeekdays(weekdays);
    const sectorsString = parseH3Indexes(h3Indexes);
    const params = new URLSearchParams();
    params.set("DateStart", dateStart);
    params.set("DateEnd", dateEnd);
    params.set("TypeTS", transportType);
    params.set("DirectionTrip", directionMode);
    params.set("TimeStartInsideDay", String(timeStartSecs));
    params.set("TimeEndInsideDay", String(timeEndSecs));

    if (h3Indexes.length) {
      params.set("h3indexes_16", sectorsString);
    }

    if (weekdays.length) {
      params.set("WeekDays", weekdaysString);
    }

    const response = await fetch(
      `${APP_ENV.REACT_APP_DTM_RNIS_API}external/v1/h3/statistics/hours?` + params.toString(),
      {
        headers: {
          "content-type": "application/json",
          "authorization": `Bearer ${shared.global.accessToken.value}`,
        },
        signal: abortController?.signal,
      }
    )
      .then((res) => res.json())
      .catch(console.error);

    return response;
  };
}
