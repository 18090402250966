import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { detectorSlice } from "features/ctrl-detector/store/slice";
import { NavigationGroupeLayers } from "components";
import * as consts from "./none-direction-detector-button.consts";

export const NoneDirectionDetectorButton = () => {
  const dispatch = useDispatch();

  const { isActive } = useSelector((state: GState) => ({
    isActive: state.detector.isActiveNoneDirectionDetector,
  }));

  const handleClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      dispatch(detectorSlice.actions.setIsActiveNoneDirectionDetector(!isActive));
    },
    [dispatch, isActive]
  );

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};
