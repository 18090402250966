import { DiagramEvent } from "../../../../types";

type EventType = DiagramEvent | string;
type Listener = Function | undefined;

export class Dispatcher {
  private static instance?: Dispatcher;
  private listeners: Map<string, Array<Function>> = new Map();

  constructor() {
    if (Dispatcher.instance) return Dispatcher.instance;

    Dispatcher.instance = this;
  }

  public fire(event: EventType, ...data: any) {
    const listeners = this.listeners.get(event);
    if (listeners) listeners.forEach((listener) => listener(...data));
  }

  public on(event: EventType, listener: Listener) {
    if (!listener) return;

    const listeners = this.listeners.get(event);
    if (listeners) this.listeners.set(event, [listener, ...listeners]);
    else this.listeners.set(event, [listener]);
  }

  public off(event: EventType, listener: Listener) {
    if (!listener) return;

    const listeners = this.listeners.get(event);
    if (listeners)
      this.listeners.set(
        event,
        listeners.filter((_listener) => _listener !== listener)
      );
  }

  public clear = () => this.listeners.clear();
}
