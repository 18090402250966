import React from "react";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { CloseButton } from "components/close-button";

import "./group-selector-popup.scss";

type SelectionData = {
  period: string;
  averageSpeed: number;
  freeSpeed: number;
  averageCarCount: number;
};

type Props = {
  data: SelectionData;
  x: number;
  y: number;
  handleClick: () => void;
};

type PopupRowProps = {
  title: string;
  value: string;
  units?: string;
};

export const PopupRow: React.FC<PopupRowProps> = ({ title, value, units }) => {
  const combinedValue = value.length !== 0 ? `${value} ${units}` : "н/д";

  return (
    <div className="group-selector-popup__content__row group-selector-popup__row">
      <span className="group-selector-popup__row__title">{title}</span>
      <span className="group-selector-popup__row__value">{combinedValue}</span>
    </div>
  );
};

const getValue = (value: number) => String(value || "");

export const GroupSelectorPopup: React.FC<Props> = ({
  data: { period, averageSpeed, freeSpeed, averageCarCount },
  x,
  y,
  handleClick,
}) => {
  return (
    <div style={{ left: x, top: y }} className="group-selector-popup">
      <div className="group-selector-popup__header">
        <div className="group-selector-popup__header__time">
          <TimeIcon />
          <span>{period}</span>
        </div>
        <CloseButton onClick={handleClick} />
      </div>
      <div className="group-selector-popup__content">
        <PopupRow title="Средняя скорость" value={getValue(averageSpeed)} units="км/ч" />
        <PopupRow title="Без пробок" value={getValue(freeSpeed)} units="км/ч" />
        <PopupRow title="Среднее число ТС" value={getValue(averageCarCount)} units="единиц" />
      </div>
    </div>
  );
};
