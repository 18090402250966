import { getLengthStyle } from "./get-length-style";

export function getLengthData(el) {
  const data = [];
  if (el && el.paintedPath) {
    el.paintedPath.features.forEach((item) => {
      const style = getLengthStyle(item, el);
      if (data.length > 0) {
        const lastIndex = data.length - 1;
        const lastItem = data[lastIndex];
        if (lastItem.backgroundColor === style.backgroundColor) lastItem.width += style.width;
        else data.push(style);
      } else data.push(style);
    });
    data.forEach((styleItem) => {
      styleItem.width = `${styleItem.width}%`;
    });

    return data.reverse();
  }
  return [];
}
