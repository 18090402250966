/** Util for simply append and remove ulr params */
export class UrlParams {
  public static _: UrlParams = new UrlParams();

  constructor() {
    if (UrlParams._) return UrlParams._;
    UrlParams._ = this;
  }

  public readonly get = (key: string) => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get(key);
  };

  public readonly set = (key: string, value: string) => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    params.set(key, value);

    const newUrl = `${window.location.origin}?${params.toString()}`;
    window.history.replaceState(null, document.title, newUrl);
  };

  public readonly delete = (key: string) => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    params.delete(key);
    const newUrl = `${window.location.origin}?${params.toString()}`;
    window.history.replaceState(null, document.title, newUrl);
  };
}
