import styled from "styled-components";

const CaptionBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  font-size: 12px;
  color: var(--text-color-default);
`;
const Value = styled.div`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

const ModalCopy = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  position: absolute;
  left: 15px;
  bottom: 15px;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(26, 32, 36, 0.32), 0 6px 12px rgba(91, 104, 113, 0.12);
  border-radius: 12px;
  gap: 28px;
  width: 320px;
  height: 56px;
  span {
    letter-spacing: 0.018em;
    font-size: 13px;
    font-weight: 600;
  }
`;

export { ModalCopy, Label, Value, CaptionBlock };
