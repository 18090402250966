import React from "react";
import { Body, Header } from "./components";
import { Props } from "./details-administrative-division.types";

import "./details-administrative-division.scss";

const baseClassName = "details-administrative-division";

export const DetailsAdministrativeDivision = (props: Props) => {
  return (
    <div className={baseClassName} data-cypress={props["data-cypress"]}>
      <Header
        administrativeDivisionName={props.administrativeDivisionName}
        administrativeDivisionIcon={props.administrativeDivisionIcon}
      />
      <Body
        date={props.date}
        trafficScore={props.trafficScore}
        trafficIndex={props.trafficIndex}
        roadsLength={props.roadsLength}
        contactsList={props.contactsList}
        objectData={props.objectData}
        eventData={props.eventData}
        mainHighways={props.mainHighways}
      />
    </div>
  );
};
