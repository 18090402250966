import * as TYPES from "./model/tiles";
import { APP_ENV } from "app-env";

const tilesUrl = `${APP_ENV.REACT_APP_DTM_TRAFFIC_API}tiles`;

const getTilesParams = (
  input: TYPES.TrafficTilesInput & TYPES.TrafficDiffTilesInput & TYPES.TilesInput & TYPES.RnisTilesInput
) => {
  const params = new URLSearchParams();
  if (input.telematicDateBegin) params.set("telematicDateBegin", input.telematicDateBegin);
  if (input.telematicDateEnd) params.set("telematicDateEnd", input.telematicDateEnd);
  if (input.registrieCodes) params.set("registrieCodes", input.registrieCodes);
  if (input.from) params.set("from", input.from);
  if (input.to) params.set("to", input.to);
  if (input.day) params.set("day", input.day);
  if (input.period) params.set("period", input.period);
  if (input.data) params.set("data", input.data);
  if (input.filter) params.set("filter", input.filter);
  if (input.es?.length) input.es.forEach((es) => params.append("es", String(es)));
  if (input.esSet) params.set("es-set", input.esSet);
  return params;
};

export const tiles = {
  /** Получить OSM Way раскрашенные трафиком */
  traffic: (input: TYPES.TrafficTilesInput) => {
    const params = getTilesParams(input);
    return `${tilesUrl}/traffic?zoom={z}&x={x}&y={y}&${params.toString()}`;
  },
  /** Получить OSM Way раскрашенные cравнением прогноза трафика с фактическим трафиком */
  trafficDiff: (input: TYPES.TrafficDiffTilesInput) => {
    const params = getTilesParams(input);
    return `${tilesUrl}/traffic/diff?zoom={z}&x={x}&y={y}&${params.toString()}`;
  },
  /** Точки телематики */
  points: (input: TYPES.TilesInput) => {
    const params = getTilesParams(input);
    return `${tilesUrl}/points?zoom={z}&x={x}&y={y}&${params.toString()}`;
  },
  /** Точки телематики РНИС */
  rnisPoints: (input: TYPES.RnisTilesInput) => {
    const params = getTilesParams(input);
    return `${APP_ENV.REACT_APP_DOMAIN_API}rnis/v2/monitoringobject/fgbarchive?${params.toString()}`;
  },
  /** Остановки со средним временем остановки транспорта */
  stops: (input: TYPES.TilesInput) => {
    const params = getTilesParams(input);
    const paramsString = params.toString();
    const baseUrl = `${tilesUrl}/stops?zoom={z}&x={x}&y={y}`;
    if (!paramsString.length) return baseUrl;
    return `${baseUrl}&${paramsString}`;
  },
};
