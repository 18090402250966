import React from "react";
import cn from "classnames";

import "./route-section.scss";

const baseClass = "route-section";

type Props = {
  title?: string;
  noPadding?: boolean;
  showManeuvers?: boolean;
};

export const RouteSection: React.FC<Props> = ({ title, noPadding, showManeuvers, children }) => {
  const className = cn(baseClass, noPadding && "route-section-no-padding");
  const style: React.CSSProperties = {};

  if (showManeuvers) {
    style.display = "none";
  }

  return (
    <div title={title} className={className} style={style}>
      {children}
    </div>
  );
};
