import React from "react";
import cn from "classnames";
import { utils, hooks } from "./utils";
import { KeyLabel, Popup } from "./components";
import { ButtonKey } from "./types";
import { DayOfWeek } from "api/router/model/models";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-fill-up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-fill-down.svg";

import "./index.scss";
export interface DaySelectorProps {
  from: string;
  to: string;
  selectedDays: Array<DayOfWeek>;
  onChangeDays: (value: Array<DayOfWeek>) => void;
  changeDate: (from: string, to: string) => void;
  disabled?: boolean;
}

export type State = {
  isOpen: boolean;
  keyMode: ButtonKey | null;
};

const getContainerClass = (disabled: boolean | undefined) =>
  cn(utils.baseClass, "selector-popup", {
    [`${utils.baseClass}-disabled`]: disabled,
  });

export const MultiDaySelector = (props: DaySelectorProps) => {
  const { disabled } = props;
  const { baseClass } = utils;
  const [state, setState] = hooks.useState();
  const callbacks = hooks.useCallBacks({ ...props, state, setState });
  const keyLabel = state.keyMode ? utils.buttonsSource.find((item) => item.key === state.keyMode)?.label || "" : "";
  const containerClass = getContainerClass(disabled);

  return (
    <div
      data-testid="container"
      className={containerClass}
      onMouseEnter={callbacks.handleContainerMouseEnter}
      onMouseLeave={callbacks.handleContainerMouseLeave}>
      <button className={`${baseClass}-label`} onClick={callbacks.handleLabelClick}>
        <span data-testid="label" className={`${baseClass}-label-text`}>
          <KeyLabel label={keyLabel} />
          {callbacks.getLabel()}
        </span>
        {state.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {state.isOpen && (
        <Popup
          baseClass={baseClass}
          rows={callbacks.getRows()}
          buttons={utils.getButtons(callbacks.handleButtonsClick)}
        />
      )}
    </div>
  );
};
