// @ts-nocheck
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import mapboxgl from "mapbox-gl";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { IsochronesInput, TrafficUse } from "api/router/model/router";
import { Checkbox } from "components/checkbox";
import { GState } from "../../documentations";
import { updateSelectedIsochrone, isochroneFetchData, setData } from "old-store/isochrone/isochrone-slice";
import IsochronePopup from "../../map-helpers/popups/isochrone-popup";
import IsochroneLayerFill from "../../map-helpers/layers/isochrone-layer-fill";
import IsochroneLayerStroke from "../../map-helpers/layers/isochrone-layer-stroke";

import "./ctrl-isochrone.scss";

const className = "ctrl-isochrone";

let popup: IsochronePopup;
let layerFill: IsochroneLayerFill;
let layerStroke: IsochroneLayerStroke;

let map: mapboxgl.Map | undefined;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

const CtrlIsochrone = () => {
  if (!popup) popup = new IsochronePopup(map);
  layerFill = new IsochroneLayerFill(map);
  layerStroke = new IsochroneLayerStroke(map);
  const dispatch = useDispatch();
  const { selected, items, data, es, points, timeStatus, to, dataIndex } = useSelector(
    (state: GState) => ({
      selected: state.isochrone.selected,
      items: state.isochrone.items,
      data: state.isochrone.data,
      es: state.view.es,
      points: state.router.points,
      timeStatus: state.router.timeStatus,
      to: state.view.selectedDay,
      dataIndex: state.isochrone.dataIndex,
    }),
    shallowEqual
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newSelected = [...selected];
    const item = Number(e.target.value);
    const checked = selected.includes(item);
    if (checked) newSelected = newSelected.filter((el) => el !== item);
    else newSelected.push(item);

    dispatch(updateSelectedIsochrone(newSelected));
  };

  const cleanMap = useCallback(() => {
    layerFill.remove(true);
    layerStroke.remove(true);
    popup.remove();
  }, []);

  const updateDataIsochrone = useCallback(() => {
    const getCoordinates = () => {
      let reverse = false;
      const coordsArray: mapboxgl.LngLat[] = points.reduce((acc, item: { coor: mapboxgl.LngLat }) => {
        if (item.coor) return [...acc, item.coor];

        return acc;
      }, []);

      if (coordsArray.length === 1) {
        const point = points.find((el, index) => {
          if (el.coor) {
            if (index !== 0) reverse = true;

            return true;
          }
          return false;
        });
        const coor = point?.coor;
        return {
          coor,
          reverse,
        };
      }
      return null;
    };

    const currentPoint = getCoordinates();

    const fetchData = () => {
      if (currentPoint) {
        const { reverse } = currentPoint;
        const sortSelected = selected.slice().sort((a, b) => Number(a) - Number(b));
        const model: IsochronesInput = {
          location: currentPoint.coor!,
          reverse,
          contours: sortSelected,
          polygons: true,
          trafficTypes: {
            externalSystemIDs: es,
          },
          trafficUse: TrafficUse.Routing,
        };

        if (timeStatus !== "last") {
          model.trafficTime = to;
        }
        dispatch(isochroneFetchData(model));
      }
    };

    const updateData = () => {
      if (selected.length > 0 && es.length > 0) fetchData();
      else {
        popup.remove();
        layerFill.remove();
        layerStroke.remove();
      }
    };

    if (!currentPoint) cleanMap();
    else updateData();
  }, [selected, es, timeStatus, to, points, dispatch]);

  useEffect(() => {
    updateDataIsochrone();
    return () => {
      cleanMap();
      dispatch(setData(null));
    };
  }, [es, timeStatus, to, points, dispatch]);

  useEffect(() => {
    updateDataIsochrone();
  }, [selected]);

  const updateGeojson = useCallback(
    (geojson: GeoJSON.FeatureCollection): GeoJSON.FeatureCollection => {
      const sortSelected = selected.slice().sort((a, b) => Number(a) - Number(b));
      const result = {
        ...geojson,
        features: geojson.features.map((el: any) => {
          const feature = {
            ...el,
            properties: {
              ...el.properties,
            },
          };
          const contour = Number(feature.properties.contour);
          const indexCountour = sortSelected.findIndex((item) => contour === item);
          let previousContour = 0;
          if (indexCountour > 0) previousContour = sortSelected[indexCountour - 1];

          feature.properties.previousContour = previousContour;
          return feature;
        }),
      };
      return result;
    },
    [selected]
  );

  const updateLayer = useCallback(() => {
    if (data) {
      const polygonData = updateGeojson(data[0]);
      const lineData = updateGeojson(data[1]);
      layerFill.update(polygonData);
      layerStroke.update(lineData);
      popup.add();
    }
  }, [data, updateGeojson]);

  useEffect(() => {
    updateLayer();
  }, [dataIndex]);

  return (
    <div className={className}>
      {items.map((el) => {
        return (
          <div className={`${className}-item`} key={el}>
            <Checkbox label={`${el} мин`} value={String(el)} onChange={handleChange} checked={selected.includes(el)} />
          </div>
        );
      })}
    </div>
  );
};

export default CtrlIsochrone;
