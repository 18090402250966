import mapboxgl from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { TrafficAPI } from "api/traffic";
import { BaseLayer } from "map-helpers";
import { togglePointsPopup } from "../map-popup/show-rnis-points-popup";
import TrafficProperties from "map-helpers/properties/traffic-properties";
import {
  TELEMETRY_BIND_POINT_ID,
  TELEMETRY_BIND_TRACK_ID,
  TELEMETRY_BIND_UNMATCHED_POINT_ID,
  TELEMETRY_POINTS_ID,
} from "map-helpers/order-layers";
import { geojson } from "flatgeobuf";
import * as Layers from "../map-layers";
import { MapboxTelemetryFeatures, RnisTelemetryFeatureProperties } from "../types";
import { TelemetryAPI } from "api/telemetry";
import { RouterAPI } from "api/router";
import { getRnisBindPointsData } from "../utils/getBindPointsData";
import { getRnisBindLineData } from "../utils/getBindLineData";
import { getBindTrackData } from "../utils/getBindTrackData";
import { getRnisBindUnmatchedPointsData } from "../utils/getBindUnmatchedPointsData";
import { getRnisBindUnmatchedLineData } from "../utils/getBindUnmatchedLineData";
import { getRnisBindConnectData } from "../utils/getBindConnectData";
import { roundDate } from "utils/round-date";
import { getTrafficCategory } from "utils/trafficCategory";
import moment from "moment";
import { telemetrySlice } from "../store/slice";
import { GeoJSON } from "ol/format";

class RnisTelemetryLayer extends BaseLayer.Abstract<GeoJSON.Point, GeoJSON.GeoJsonProperties> {
  private properties: TrafficProperties = new TrafficProperties();
  private trafficColors = this.properties.getTrafficColors();
  private layerConfig: mapboxgl.CircleLayer = {
    id: TELEMETRY_POINTS_ID,
    type: "circle",
    source: TELEMETRY_POINTS_ID,
    paint: {
      "circle-color": [
        "case",
        ["==", ["get", "speed"], 0],
        "#b9b0b0",
        ["==", ["get", "traffic"], 100],
        this.trafficColors[100],
        ["==", ["get", "traffic"], 4],
        this.trafficColors[4],
        ["==", ["get", "traffic"], 3],
        this.trafficColors[3],
        ["==", ["get", "traffic"], 2],
        this.trafficColors[2],
        ["==", ["get", "traffic"], 1],
        this.trafficColors[1],
        "#0ABE0A",
      ],
      "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 1, 12, 2, 18, 4],
    },
  };

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: TELEMETRY_POINTS_ID,
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }

  async setPointsData(tileUrl: string, accessToken: string, setPointsDataInStore: (data: GeoJSON.Feature[]) => void) {
    const geojsonsCollection = [];

    try {
      const a = await fetch(tileUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const b = await geojson.deserialize(a.body as ReadableStream<Uint8Array>);

      for await (const item of b as any) {
        geojsonsCollection.push({
          ...item,
          properties: {
            ...item.properties,
            speed: Math.round(item.properties.speed),
            traffic: getTrafficCategory(item.properties.speed),
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
    setPointsDataInStore(geojsonsCollection);
    await this.setData(geojsonsCollection);
  }
}

export class RnisTelemetryController {
  private telemetryPointsLayer?: RnisTelemetryLayer;
  private access_token = this.store.getState().oidc.user.access_token;
  private width = 2;
  private height = 2;
  private isShowingTrack = false;
  private closeMarker: mapboxgl.Marker;
  private clickTimeout?: NodeJS.Timeout;
  private telemetryMatched?: Layers.TelemetryMatchedLayer;
  private telemetryBindPointsLayer?: Layers.TelemetryBindPointsLayer;
  private telemetryBindLineLayer?: Layers.TelemetryBindLineLayer;
  private telemetryBindTrackLayer?: Layers.TelemetryBindTrackLayer;
  private telemetryBindConnectLayer?: Layers.TelemetryBindConnectLayer;
  private telemetryBindUnmatchedPointsLayer?: Layers.TelemetryBindUnmatchedPointsLayer;
  private telemetryBindUnmatchedLineLayer?: Layers.TelemetryBindUnmatchedLineLayer;

  private get isActive() {
    return this.store.getState().telemetry.isActive;
  }

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.subscribe();
    this.createLayers();
    this.closeMarker = new mapboxgl.Marker(this.getCloseIcon());
    this.update();
  }

  private getCloseIcon() {
    const button = document.createElement("button");
    button.onclick = () => {
      this.resetTrack();
      this.isShowingTrack = false;
    };
    button.className = "telemetry-map-close-icon";
    button.innerHTML = `
        <svg width="12" height="12" viewBox="0 0 32 32">
            <path fill="currentColor" d="M2.518-.5A2.91 2.91 0 00.497.316H.495L.493.318a2.752 2.752 0 00-.844 1.943l.666.004h-.666v.041c0 .771.313 1.474.82 1.98l11.578 11.631L.6 27.513a2.804 2.804 0 00-1.045 2.184A2.81 2.81 0 002.358 32.5c.868 0 1.65-.4 2.166-1.025l-.031.035 11.572-11.625 11.469 11.596c.49.523 1.192.85 1.963.85h.014a2.874 2.874 0 001.992-.814l.002-.002a2.783 2.783 0 000-3.97L20.062 15.92l11.52-11.592a2.802 2.802 0 00.002-3.982l.002-.002h-.002l-.002-.002-.586.59.582-.596a2.913 2.913 0 00-4.024 0l-.006.004-11.557 11.611L4.55.328l-.143.141.141-.143V.324l-.426.424.42-.43a2.91 2.91 0 00-2.023-.816z"/>
        </svg>`;
    return button;
  }

  public update() {
    if (this.isActive) {
      return this.showLayers();
    }

    this.hideLayers();
  }

  private showLayers() {
    this.telemetryPointsLayer?.setVisibility(true);
    togglePointsPopup(this.map, true);
  }

  private hideLayers() {
    this.telemetryPointsLayer?.setVisibility(false);
    this.isShowingTrack = false;
    togglePointsPopup(this.map, false);
    this.hideBindLayers();
  }

  private handleStyleChange = () => {
    if (!this.map.isStyleLoaded()) {
      return this.map.once("idle", this.reCreateLayers);
    }

    this.reCreateLayers();
  };

  private subscribe = () => {
    this.map.on("click", this.handleClick);
    this.map.on("dblclick", this.handleDblClick);
    this.map.on("mousemove", this.handleMouseMove);
    this.map.on("style.load", this.handleStyleChange);
  };

  private unsubscribe = () => {
    this.map.off("click", this.handleClick);
    this.map.off("dblclick", this.handleDblClick);
    this.map.off("mousemove", this.handleMouseMove);
    this.map.off("style.load", this.handleStyleChange);
  };

  private reCreateLayers = () => {
    this.isShowingTrack = false;
    togglePointsPopup(this.map, false);
    this.destroyLayers();
    this.createLayers();
  };

  public updateLayersAfterChangeHistoryDate() {
    this.destroyLayers();
    this.createLayers();
  }

  private createLayers = async () => {
    const setPointsData = (data: GeoJSON.Feature[]) => {
      this.store.dispatch(telemetrySlice.actions.setPointsData(data));
    };
    this.telemetryPointsLayer = new RnisTelemetryLayer(this.map);
    this.telemetryPointsLayer.setPointsData(this.getTileUrl(), this.access_token, setPointsData);
    this.telemetryMatched = new Layers.TelemetryMatchedLayer(this.map);
    this.telemetryBindPointsLayer = new Layers.TelemetryBindPointsLayer(this.map);
    this.telemetryBindLineLayer = new Layers.TelemetryBindLineLayer(this.map);
    this.telemetryBindTrackLayer = new Layers.TelemetryBindTrackLayer(this.map);
    this.telemetryBindConnectLayer = new Layers.TelemetryBindConnectLayer(this.map);
    this.telemetryBindUnmatchedPointsLayer = new Layers.TelemetryBindUnmatchedPointsLayer(this.map);
    this.telemetryBindUnmatchedLineLayer = new Layers.TelemetryBindUnmatchedLineLayer(this.map);

    this.telemetryPointsLayer.setVisibility(this.isActive);
    this.telemetryMatched.setVisibility(this.isActive);
    this.telemetryBindPointsLayer.setVisibility(this.isActive);
    this.telemetryBindLineLayer.setVisibility(this.isActive);
    this.telemetryBindTrackLayer.setVisibility(this.isActive);
    this.telemetryBindConnectLayer.setVisibility(this.isActive);
    this.telemetryBindUnmatchedPointsLayer.setVisibility(this.isActive);
    this.telemetryBindUnmatchedLineLayer.setVisibility(this.isActive);
  };

  private destroyLayers = () => {
    this.telemetryMatched?.destroy();
    this.telemetryPointsLayer?.destroy();
    this.telemetryBindPointsLayer?.destroy();
    this.telemetryBindLineLayer?.destroy();
    this.telemetryBindTrackLayer?.destroy();
    this.telemetryBindConnectLayer?.destroy();
    this.telemetryBindUnmatchedPointsLayer?.destroy();
    this.telemetryBindUnmatchedLineLayer?.destroy();
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== "Escape") return;
    this.isShowingTrack = false;
    this.resetTrack();
  };

  private resetTrack = () => {
    this.hideBindLayers();
    this.update();
    this.closeMarker.remove();
  };

  private handleMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const width = 10 / 2;
    const features = this.map.queryRenderedFeatures([
      [e.point.x - width, e.point.y - width],
      [e.point.x + width, e.point.y + width],
    ]);
    const layers = features.map((el) => el.layer.id);
    if (
      layers.includes(TELEMETRY_BIND_POINT_ID) ||
      layers.includes(TELEMETRY_BIND_UNMATCHED_POINT_ID) ||
      !layers.includes(TELEMETRY_BIND_TRACK_ID)
    ) {
      return this.closeMarker.remove();
    }
    this.closeMarker.setLngLat(e.lngLat).addTo(this.map);
  };

  private hideBindLayers() {
    this.telemetryMatched?.setVisibility(false);
    this.telemetryBindLineLayer?.setVisibility(false);
    this.telemetryBindTrackLayer?.setVisibility(false);
    this.telemetryBindPointsLayer?.setVisibility(false);
    this.telemetryBindConnectLayer?.setVisibility(false);
    this.telemetryBindUnmatchedLineLayer?.setVisibility(false);
    this.telemetryBindUnmatchedPointsLayer?.setVisibility(false);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  private handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.isShowingTrack) return;
    if (this.clickTimeout) clearTimeout(this.clickTimeout);
    const features = this.getFeaturesByLayers(e, [TELEMETRY_POINTS_ID, TELEMETRY_BIND_POINT_ID]);
    if (!features.length) return;
    this.clickTimeout = setTimeout(() => {
      const feature = features[0];
      const { tt, om_id } = feature.properties;
      this.bindPoint({ tt, om_id });
    }, 300);
  };

  private handleSetVisible = (layer: BaseLayer.Abstract<any, any>) => () => {
    layer.setVisibility(true);
  };

  private async bindPoint(args: Pick<RnisTelemetryFeatureProperties, "tt" | "om_id">) {
    const { tt, om_id } = args;

    const { timeFrom, timeTo } = this.getTimeRange(String(tt));
    const filter = {
      om_id,
      from: timeFrom,
      to: timeTo,
    };

    new Promise(async (res) => {
      const positions = await TelemetryAPI.telemetry.rnisPositions(filter, this.access_token);

      const track = positions.map((el) => ({
        time: el.tt,
        lat: el.lat,
        lng: el.lng,
      }));
      const attributes = await RouterAPI.router.mapmatch.attributes(track);
      const matchedPoints = attributes.matched_points.filter((el: { type: string }) => el.type !== "unmatched");
      const matchedPositions = positions.filter((el, index) => attributes.matched_points[index]?.type !== "unmatched");

      this.telemetryPointsLayer?.setVisibility(false);

      this.telemetryBindPointsLayer
        ?.setData(getRnisBindPointsData(positions))
        .then(this.handleSetVisible(this.telemetryBindPointsLayer), () => {});

      this.telemetryBindLineLayer
        ?.setData(getRnisBindLineData(positions))
        .then(this.handleSetVisible(this.telemetryBindLineLayer), () => {});

      this.telemetryBindTrackLayer
        ?.setData(getBindTrackData(attributes))
        .then(this.handleSetVisible(this.telemetryBindTrackLayer), () => {});

      this.telemetryBindUnmatchedPointsLayer
        ?.setData(getRnisBindUnmatchedPointsData(attributes.matched_points, positions))
        .then(this.handleSetVisible(this.telemetryBindUnmatchedPointsLayer), () => {});

      this.telemetryBindUnmatchedLineLayer
        ?.setData(getRnisBindUnmatchedLineData(attributes.matched_points, positions))
        .then(this.handleSetVisible(this.telemetryBindUnmatchedLineLayer), () => {});

      this.telemetryMatched
        ?.setData(getRnisBindConnectData(matchedPoints, matchedPositions))
        .then(this.handleSetVisible(this.telemetryMatched), () => {});

      this.telemetryBindPointsLayer?.setData(getRnisBindPointsData(matchedPositions));
      this.telemetryBindLineLayer?.setData(getRnisBindLineData(matchedPositions));
      this.telemetryBindConnectLayer?.setData(getRnisBindConnectData(attributes.matched_points, positions));

      res(true);
    })
      .then(() => {
        this.isShowingTrack = true;
        document.addEventListener("keydown", this.handleKeyDown);
      })
      .catch(console.error);
  }

  private handleDblClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.preventDefault();
    if (this.clickTimeout) clearTimeout(this.clickTimeout);
    const features = this.getFeaturesByLayers(e, [TELEMETRY_BIND_POINT_ID]);
    if (!features.length) return;
    this.hideBindLayers();
    this.update();
    this.isShowingTrack = false;
  };

  private getTimeRange(time: string) {
    const {
      view: { from, to, type },
    } = this.store.getState();
    let timeTo: string;
    let timeFrom: string;
    if (time) {
      timeTo = roundDate(moment(Number(time) * 1000), moment.duration(5, "minutes"), "floor")
        .add(20, "minute")
        .toISOString(true);
      timeFrom = roundDate(moment(Number(time) * 1000), moment.duration(5, "minutes"), "floor")
        .subtract(20, "minute")
        .toISOString(true);
    } else if (type === "last") {
      timeTo = roundDate(moment(), moment.duration(5, "minutes"), "floor").toISOString(true);
      timeFrom = moment(timeTo).subtract(40, "minute").toISOString(true);
    } else {
      timeFrom = from;
      timeTo = to;
    }
    return { timeFrom, timeTo };
  }

  private getFeaturesByLayers(
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
    layers: string[]
  ): MapboxTelemetryFeatures<RnisTelemetryFeatureProperties> {
    const existLayers = layers.filter((el) => this.map.getLayer(el));
    const features = this.map.queryRenderedFeatures(
      [
        [e.point.x - this.width / 2, e.point.y - this.height / 2],
        [e.point.x + this.width / 2, e.point.y + this.height / 2],
      ],
      { layers: existLayers }
    ) as MapboxTelemetryFeatures<RnisTelemetryFeatureProperties>;
    return features;
  }

  private getTileUrl() {
    const telematicDateEnd = roundDate(moment(), moment.duration(5, "minutes"), "floor")
      .toISOString(true)
      .split(".")[0];
    const telematicDateBegin = roundDate(moment(), moment.duration(5, "minutes"), "floor")
      .subtract(3, "minute")
      .toISOString(true)
      .split(".")[0];

    return TrafficAPI.tiles.rnisPoints({ telematicDateBegin, telematicDateEnd });
  }

  public destroy = () => {
    this.unsubscribe();
    this.destroyLayers();
  };
}
