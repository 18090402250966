import { memo } from "react";
import { Loader } from "components";
import { useStoreContext } from "store";
import { Header, Filter, Statistics } from "./components";
import "./zone-statistics-card.scss";

const baseClass = "zone-statistics-card";
const loaderClass = `${baseClass}__loader`;
const contentClass = `${baseClass}__content`;
const dividerClass = `${contentClass}__divider`;

export const ZoneStatisticsCard = memo(() => {
  const { store } = useStoreContext();
  const isActive = store.zoneStatistics.hooks.useIsActive();
  const zone = store.zoneStatistics.hooks.useZone();
  const isLoading = store.zoneStatistics.hooks.useIsLoading();

  if (!isActive || !zone) return null;

  return (
    <div className={baseClass}>
      <Header />
      <div className={contentClass}>
        <Filter />
        <div className={dividerClass} />
        <Statistics />
      </div>
      {isLoading && (
        <div className={loaderClass}>
          <Loader isLarge />
        </div>
      )}
    </div>
  );
});
