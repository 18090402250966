import mapboxgl from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import { APP_ENV } from "app-env";
import { TilesInput, TrafficAPI, TrafficPeriod } from "api";
import { BusStopsTypes } from "types";
import { Layers } from "../../layers";
import { BusStopPopup } from "./bus-stops.popup";
import { BusStopLayer2D } from "./bus-stops.layer.2d";
import { BusStopLayer3D } from "./bus-stops.layer.3d";
import { Events, Handlers } from "./bus-stops.events";
import moment from "moment";

export type Options = {
  isVisible: boolean;
};

const initialOptions: Options = {
  isVisible: false,
};

const initialDate = moment().toJSON();

export class BusStops extends TypedEmitter<Handlers> {
  public readonly events = Events;
  private layer2D: BusStopLayer2D;
  private layer3D: BusStopLayer3D;
  private popup: BusStopPopup;
  private es: number[] = [2, 7, 14, 15, 17, 3, 16, 6, 100];
  private from: string | null = initialDate;
  private to: string | null = initialDate;
  private trafficType: string | null = "last";
  private isBusStopInfoPending = false;
  private requestedBusStopCoordinates: mapboxgl.LngLat | null = null;
  private stopsInfo: { [key: string]: BusStopsTypes.PopupProps } = {};

  constructor(private readonly map: mapboxgl.Map, private options: Options = initialOptions) {
    super();
    this.layer2D = new BusStopLayer2D(this.map, this.getTileUrl());
    this.layer3D = new BusStopLayer3D(this.map, {
      layerId: Layers.Identifiers.BUS_STOP_VOLUMETRIC,
      source: Layers.Identifiers.BUS_STOP_ID,
      sourceLayer: "stops",
    });
    this.popup = new BusStopPopup(this.map);

    this.map.addLayer(this.layer3D);

    this.layer2D.setVisibility(this.options.isVisible);
    this.layer3D.setVisibility(this.options.isVisible);
    this.subscribe();
  }

  private handleMapClick = (e: mapboxgl.MapMouseEvent) => {
    const feature = this.map
      .queryRenderedFeatures(e.point, {
        layers: [Layers.Identifiers.BUS_STOP_ID],
      })
      .shift() as GeoJSON.Feature<GeoJSON.Point, BusStopsTypes.FeatureProperties> | undefined;
    if (!feature) return;

    const id = feature.properties.id;
    const stopInfo = this.stopsInfo[id];

    const coordinates = mapboxgl.LngLat.convert(feature.geometry.coordinates as [number, number]);
    this.popup.show(coordinates, {
      id: feature.properties.id,
      name: feature.properties.name,
      time: feature.properties.time,
    });

    if (typeof id !== "number" || this.isBusStopInfoPending) return;
    if (!stopInfo) {
      this.isBusStopInfoPending = true;
      this.requestedBusStopCoordinates = coordinates;
      this.popup.hide();
      return this.emit(this.events.missingBusStopInfo, feature.properties);
    }
    this.popup.show(coordinates, stopInfo);
  };

  private handleMapChange = () => {
    const isVisible =
      this.map.getZoom() >= (APP_ENV.REACT_APP_DTM_MAP_3D_START_ZOOM ?? 16) &&
      this.map.getPitch() >= (APP_ENV.REACT_APP_DTM_MAP_3D_START_PITCH ?? 20);

    this.layer3D.setVisibility(isVisible);
    this.layer2D.setOpacityVisibility(!isVisible);
  };

  private subscribe = () => {
    this.map.on("click", this.handleMapClick);
    this.map.on("pitch", this.handleMapChange);
    this.map.on("zoom", this.handleMapChange);
  };

  private unsubscribe = () => {
    this.map.off("pitch", this.handleMapChange);
    this.map.off("zoom", this.handleMapChange);
  };

  private getTileUrl() {
    const { es, trafficType, from, to } = this;

    const input: TilesInput = { es };

    if (trafficType !== "last") {
      input.from = from ?? undefined;
      input.to = to ?? undefined;
    } else {
      input.period = TrafficPeriod.Last;
    }
    return TrafficAPI.tiles.stops(input);
  }

  public readonly stopInfoExpectation = () => {
    this.isBusStopInfoPending = false;
    this.requestedBusStopCoordinates = null;
  };

  public readonly addMissingStopInformation = (info: BusStopsTypes.PopupProps) => {
    if (typeof info.id !== "number") return this.stopInfoExpectation();

    this.stopsInfo[info.id] = info;

    if (!this.requestedBusStopCoordinates) return this.stopInfoExpectation();

    this.popup.show(this.requestedBusStopCoordinates, info);
    this.stopInfoExpectation();
  };

  public readonly setVisibility = (value: Options["isVisible"]) => {
    this.options.isVisible = value;
    this.layer2D.setVisibility(this.options.isVisible);
    if (!value) this.popup.hide();
  };

  public readonly setEs = (es: BusStops["es"]) => {
    this.es = es;
    this.layer2D.setTileUrl(this.getTileUrl());
  };

  public readonly setFrom = (from: BusStops["from"]) => {
    this.from = from;
    this.layer2D.setTileUrl(this.getTileUrl());
  };

  public readonly setTo = (to: BusStops["to"]) => {
    this.to = to;
    this.layer2D.setTileUrl(this.getTileUrl());
  };

  public readonly setTrafficType = (trafficType: BusStops["trafficType"]) => {
    this.trafficType = trafficType;
    this.layer2D.setTileUrl(this.getTileUrl());
  };

  public readonly destroy = () => {
    this.unsubscribe();
    this.layer2D.destroy();
  };
}
