import { createEffect } from "effector";
import { BusStopAPI } from "api";
import { BusStopsTypes } from "types";

type RouteInfo = { id: number; name: string; transportType: BusStopsTypes.PopupRoute["transportType"]; color: string };

const getBusStopInformation = createEffect(async (busStop: BusStopsTypes.FeatureProperties) => {
  const { id: stopId } = busStop;
  const api = new BusStopAPI();

  const fetchStopFromKpp = async (): Promise<BusStopsTypes.PopupProps | Error> => {
    const busStopInfo = await api.getStopInfo(stopId);

    if (busStopInfo instanceof Error) return info;

    const routesColors: RouteInfo[] = (
      await Promise.all(
        busStopInfo.routes.map(async (route): Promise<RouteInfo | Error> => {
          const routeInfo = await api.getRouteInfo(route.id);

          if (routeInfo instanceof Error) return routeInfo;

          return {
            id: route.id,
            name: routeInfo.name,
            color: routeInfo.appearence.backgroundColor,
            transportType: routeInfo.transportType,
          };
        })
      )
    ).filter((routeColor) => !!routeColor) as RouteInfo[];

    return {
      id: stopId,
      name: busStop.name,
      time: busStop.time,
      routes: routesColors.map((routeColor) => {
        const route = busStopInfo.routes.find(({ id }) => id === routeColor.id);

        return {
          id: route?.id,
          num: route?.num,
          name: routeColor.name,
          color: routeColor.color,
          transportType: routeColor.transportType,
        };
      }),
    };
  };

  const [info, boards] = await Promise.all([fetchStopFromKpp(), api.getStopBoards(stopId)]);

  if (boards instanceof Error || info instanceof Error) return info;

  info.boardId = boards?.[0]?.asipID;

  return info;
});

export const effects = {
  getBusStopInformation,
};

export type Effects = typeof effects;
