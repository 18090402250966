import React from "react";
import { CameraVideo, MapPopup } from "components";
import { CamType } from "features/ctrl-camera/types";

type Props = {
  id: number;
  num: string;
  address: string;
  token: string;
  camType: CamType;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const baseClass = "camera-popup";

export const CameraPopup: React.FC<Props> = ({ id, num, address, token, onClickClose, camType }) => {
  const titleUrl = `https://warehouse.megapolis-it.ru/${camType === CamType.echd ? "echdcam" : "camera"}/${id}`;
  const title = `Камера ${camType === CamType.echd ? "ЕЦХД" : "телеобзора"} № ${num}`;

  return (
    <MapPopup title={title} titleUrl={titleUrl} address={address} onClickClose={onClickClose}>
      <CameraVideo
        camId={id}
        // @ts-ignore
        camType={camType}
        caption={address}
        fileStorage="dtm"
        token={token}
        description={address}
      />
    </MapPopup>
  );
};
