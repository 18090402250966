import { Middleware } from "redux";
import { GState } from "documentations";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import * as ISOCHRONE_SLICE from "old-store/isochrone/isochrone-slice";

export const isochroneMiddleware: Middleware<any, GState> = (store) => (next) => async (action) => {
  const result = next(action);

  /**
   * Update disabled status for checkbox
   */
  const updateDisabled = () => {
    const {
      router: { points },
    } = store.getState();

    const pointsWithCoords = points.filter((el) => el.coor);

    /**
     * Isochrons are shown for only one point
     */
    if (pointsWithCoords.length === 1) store.dispatch(ISOCHRONE_SLICE.setIsDisabled(false));
    else {
      store.dispatch(ISOCHRONE_SLICE.setIsDisabled(true));
      store.dispatch(ISOCHRONE_SLICE.toggleIsActive(false));
    }
  };

  if (action.type === ROUTE_SLICE.setPointCoor.type) updateDisabled();

  if (action.type === ROUTE_SLICE.clearPoint.type) updateDisabled();

  if (action.type === ROUTE_SLICE.clearPoints.type) updateDisabled();

  if (action.type === ROUTE_SLICE.updateByFavorite.type) updateDisabled();

  return result;
};
