import mapboxgl from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import SectorAnalysisLayer from "features/sector-analysis/map-layers/sector-analysis-layer";
import { on, EVENTS } from "observer";

class SectorAnalysisMapController {
  layer: SectorAnalysisLayer;

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.layer = new SectorAnalysisLayer(this.map);

    this.removePath = this.removePath.bind(this);

    on(EVENTS.SECTOR_ANALYSIS_MAP_DRAW_PATH, this.drawPath);
    on(EVENTS.SECTOR_ANALYSIS_MAP_UPDATE_PATH, this.updatePath);
    on(EVENTS.DIAGRAM_LEAVE, this.removePath);
  }

  drawPath = (row: number) => {
    const {
      sectorAnalysis: {
        current: { diagram },
      },
    } = this.store.getState();

    if (diagram) {
      const data = diagram.rows[row];
      if (data?.geometry) this.layer.update(data.geometry);
    }
  };

  removePath() {
    this.layer.remove();
  }

  private updatePath = () => {
    this.removePath();
    this.layer = new SectorAnalysisLayer(this.map);
  };

  cursorDefault() {
    if (this.map) this.map.getCanvas().style.cursor = "";
  }
}

export default SectorAnalysisMapController;
