import mapboxgl from "mapbox-gl";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropResult } from "react-beautiful-dnd";
import moment from "moment";
import { RouteCosting } from "api/router/model/router";
import { createDefaultRoutePoint } from "../utils/create-default-route-point";
import { generateKey } from "../utils";
import * as actionsAsync from "./actions-async";
import * as TYPES from "../types";

export const initialState: TYPES.RouteState = {
  points: [createDefaultRoutePoint({ key: "from" }), createDefaultRoutePoint({ key: "to" })],
  path: [],
  isManeuversShow: false,
  isActive: false,
  isDisabled: false,
  routeVariants: null,
  useTrafficLayers: false,
  useTrafficLayersIsDisabled: false,
  routeIsLoading: false,
  activeIndexRoute: 0,
  activePointKey: null,
  timeStatus: TYPES.TIME_STATUS.last,
  routeTimeVariant: null,
  typeVariant: TYPES.TYPE_VARIANTS.time,
  period: 15,
  errorVariants: null,
  isRouteLengthError: false,
  isRouteDiagram: true,
  routeTime: null,
  mapIsLoaded: false,
  costing: RouteCosting.Auto,
  templateId: 1,
  favoriteRoadsDictionary: [],
  isSimulatedDtp: false,
  simulatedDtp: null,
  simulatedDtpDate: null,
  isCompareWithYandex: false,
  yandexVariant: null,
  isYandexVariantLoading: false,
  isRouteWithMetro: false,
  routeInfos: [],
  isRouteTimeVariantsOpen: false,
  isShowYandexRoute: false,
};

const getPointsByFavoriteRoadsDictionary = (
  favoriteRoadsDictionary: API.CdnAPI.FavoriteRoadsDictionary[],
  templateId: string | number
) => {
  const dictionary = favoriteRoadsDictionary.find(({ id }) => id === templateId);
  const geometry = (dictionary?.geometry || []) as { lng: number; lat: number }[];
  const points = geometry.reduce((acc: TYPES.RoutePoint[], coor, index, arr) => {
    acc.push({
      key: index === 0 ? "from" : index === arr.length - 1 ? "to" : generateKey(index),
      coor: new mapboxgl.LngLat(coor.lng, coor.lat),
      error: null,
      address: null,
      isMain: true,
      favoriteId: null,
      favoriteName: null,
      isFavorite: false,
      isLoading: false,
    });

    return acc;
  }, []);

  return points;
};

export const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    setCosting(state, { payload }: PayloadAction<RouteCosting>) {
      if (payload === RouteCosting.Template) {
        state.path = getPointsByFavoriteRoadsDictionary(state.favoriteRoadsDictionary, state.templateId);
      }

      state.yandexVariant = null;
      state.isRouteWithMetro = false;
      state.isYandexVariantLoading = false;
      state.isCompareWithYandex = false;
      state.costing = payload;
    },
    fetchRouteWithSaving() {},
    setRouteInfos(state, { payload }: PayloadAction<TYPES.RouteState["routeInfos"]>) {
      state.routeInfos = payload;
    },
    setMapIsLoaded(state, { payload }: PayloadAction<boolean>) {
      state.mapIsLoaded = payload;
    },
    setIsRouteTimeVariantsOpen(state, { payload }: PayloadAction<TYPES.RouteState["isRouteTimeVariantsOpen"]>) {
      state.isRouteTimeVariantsOpen = payload;
    },
    setIsRouteDiagram(state, { payload }: PayloadAction<boolean>) {
      state.isRouteDiagram = payload;
    },
    setPeriod(state, { payload }: PayloadAction<number>) {
      state.period = payload;
    },
    setTypeVariant(state, { payload }: PayloadAction<TYPES.TypeVariants>) {
      state.typeVariant = payload;
    },
    setIsShowManeuvers(state, { payload }: PayloadAction<boolean>) {
      state.isManeuversShow = payload;
    },
    setIsActive(state, { payload }: PayloadAction<boolean>) {
      state.isActive = payload;
    },
    setIsDisable(state, { payload }: PayloadAction<boolean>) {
      state.isDisabled = payload;
    },
    setIsRouteLengthError(state, { payload }: PayloadAction<boolean>) {
      state.isRouteLengthError = payload;
    },
    setIsCompareWithYandex(state, { payload }: PayloadAction<boolean>) {
      state.isCompareWithYandex = payload;
    },
    setYandexVariant(state, { payload }: PayloadAction<TYPES.RouteState["yandexVariant"]>) {
      state.yandexVariant = payload;
    },
    setIsShowYandexRoute(state, { payload }: PayloadAction<TYPES.RouteState["isShowYandexRoute"]>) {
      state.isShowYandexRoute = payload;
    },
    setIsYandexVariantLoading(state, { payload }: PayloadAction<boolean>) {
      state.isYandexVariantLoading = payload;
    },
    setIsRouteWithMetro(state, { payload }: PayloadAction<boolean>) {
      state.isRouteWithMetro = payload;
    },
    setPointError(state, { payload }: PayloadAction<{ key: string; error: string | null }>) {
      const { key, error } = payload;
      state.points = state.points.map((point) => {
        if (point.key === key) point.error = error;
        return point;
      });
    },
    setPointIsLoading(state, { payload }: PayloadAction<{ key: string; isLoading: boolean }>) {
      const { key, isLoading } = payload;
      state.points = state.points.map((point) => {
        if (point.key === key) point.isLoading = isLoading;
        return point;
      });
    },
    setIsSimulatedDtp(state, { payload }: PayloadAction<boolean>) {
      state.isSimulatedDtp = payload;
    },
    setSimulatedDtpDate(state, { payload }: PayloadAction<Date>) {
      state.simulatedDtpDate = payload;
    },
    setSimulatedDtp(state, { payload }: PayloadAction<TYPES.RouteState["simulatedDtp"]>) {
      state.simulatedDtp = payload;
    },
    setPointAddress(state, { payload }: PayloadAction<{ key: string; address: string | null }>) {
      const { key, address } = payload;

      state.points = state.points.map((point) => {
        if (point.key === key) point.address = address;
        return point;
      });
    },
    setPointName(state, { payload }: PayloadAction<{ key: string; name: string | null }>) {
      const { key, name } = payload;

      state.points = state.points.map((point) => {
        if (point.key === key) point.favoriteName = name;
        return point;
      });
    },
    setPointCoor(state, { payload }: PayloadAction<{ key: string; coor: mapboxgl.LngLat }>) {
      const { key, coor } = payload;

      const editKey = state.costing === RouteCosting.Template ? "path" : "points";

      state[editKey] = state[editKey].map((point) => {
        if (point.key === key) {
          point.coor = coor;
          point.favoriteId = null;
          point.favoriteName = null;
          point.isFavorite = false;
          point.address = null;
        }
        return point;
      });
    },
    setFavoriteForPoint(
      state,
      { payload }: PayloadAction<{ key: string; name: string | null; status: boolean; id: number }>
    ) {
      const { key, name, status, id } = payload;

      state.points = state.points.map((point) => {
        if (point.key === key) {
          point.favoriteName = name;
          point.isFavorite = status;
          point.favoriteId = id;
        }

        return point;
      });
    },
    exchangePoints(state) {
      const mainPoints = state.points.filter((el) => el.isMain);

      const newPoints = [mainPoints[1], mainPoints[0]];

      state.points = newPoints;
    },
    onDragEnd(state, { payload }: PayloadAction<DropResult>) {
      if (!payload.destination) return;

      const mainPoints = state.points.filter((el) => el.isMain);

      const result = Array.from(mainPoints);

      const [removed] = result.splice(payload.source.index, 1);

      result.splice(payload.destination.index, 0, removed);

      state.points = result;
    },
    clearPoint(state, { payload }: PayloadAction<string>) {
      const editKey = state.costing === RouteCosting.Template ? "path" : "points";
      const filterMainPointPredicate = (el: TYPES.RoutePoint) => el.isMain;
      const searchPredicate = (el: TYPES.RoutePoint) => el.key === payload;
      const searchNegativePredicate = (el: TYPES.RoutePoint) => el.key !== payload;
      const mainPoints = state[editKey].filter(filterMainPointPredicate);
      const routePoint = state[editKey].find(searchPredicate);
      const routePointIndex = state[editKey].findIndex(searchPredicate);
      const createPointWithAddress = () => {
        return createDefaultRoutePoint({
          key: payload,
          coor: routePoint?.coor,
          address: routePoint?.address,
        });
      };

      if (routePoint?.isMain) {
        if (mainPoints.length > 2) {
          if (routePoint.isFavorite) {
            state[editKey][routePointIndex] = createPointWithAddress();
          } else {
            state[editKey] = state[editKey].filter(searchNegativePredicate);
          }
        } else {
          state[editKey] = state[editKey].map((point) => {
            if (point.key !== payload) return point;
            if (routePoint.isFavorite) {
              return createPointWithAddress();
            }
            return createDefaultRoutePoint({ key: payload });
          });
        }

        state[editKey] = state[editKey].filter(filterMainPointPredicate);
      } else {
        state[editKey] = state[editKey].filter(searchNegativePredicate);
      }

      state.routeVariants = null;
      state.activeIndexRoute = 0;
      state.routeIsLoading = false;
      state.activePointKey = null;
    },
    addPoint(state) {
      state.points.push(createDefaultRoutePoint({ key: generateKey() }));
    },
    clearPoints(state) {
      state.points = [createDefaultRoutePoint({ key: "from" }), createDefaultRoutePoint({ key: "to" })];
      state.routeVariants = null;
      state.activeIndexRoute = 0;
      state.routeIsLoading = false;
      state.activePointKey = null;
    },
    setRouteVariants(state, { payload }: PayloadAction<Array<TYPES.RouteResponse> | null>) {
      state.routeVariants = payload;
      state.routeTime = moment().toISOString();
    },
    setRouteIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.routeIsLoading = payload;
    },
    setUseTrafficLayers(state, { payload }: PayloadAction<boolean>) {
      state.useTrafficLayers = payload;
    },
    setUseTrafficLayersIsDisabled(state, { payload }: PayloadAction<boolean>) {
      state.useTrafficLayersIsDisabled = payload;
    },
    setActiveIndexRoute(state, { payload }: PayloadAction<number>) {
      state.activeIndexRoute = payload;
    },
    setActivePointKey(state, { payload }: PayloadAction<string | null>) {
      state.activePointKey = payload;
    },
    setTimeStatus(state, { payload }: PayloadAction<TYPES.TimeStatus>) {
      state.timeStatus = payload;
    },
    setErrorVariants(state, { payload }: PayloadAction<string | null>) {
      state.errorVariants = payload;
    },
    addIntermediatePoint(state, { payload }: PayloadAction<TYPES.NewIntermediatePoint>) {
      const { key, keyBeforePaste } = payload;
      const editKey = state.costing === RouteCosting.Template ? "path" : "points";
      const beforePasteIndex = state[editKey].findIndex((el) => el.key === keyBeforePaste);
      state[editKey].splice(beforePasteIndex, 0, createDefaultRoutePoint({ key, isMain: false }));
    },
    updateByFavorite(state, { payload }: PayloadAction<TYPES.RoutePoint>) {
      const { key } = payload;

      state.points = state.points.map((point) => {
        if (point.key === key) point = createDefaultRoutePoint(payload);

        return point;
      });
    },
    setRouteTimeVariant(state, { payload }: PayloadAction<TYPES.RouteResponse | null>) {
      state.routeTimeVariant = payload;
    },
    setTemplateId(state, { payload }: PayloadAction<number | string>) {
      state.path = getPointsByFavoriteRoadsDictionary(state.favoriteRoadsDictionary, payload);
      state.templateId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionsAsync.fetchFavoriteRoadsDictionary.fulfilled, (state, { payload }) => {
      state.favoriteRoadsDictionary = payload;
    });
  },
});

export const {
  setIsRouteTimeVariantsOpen,
  setIsShowManeuvers,
  setIsActive,
  setIsDisable,
  setPointIsLoading,
  exchangePoints,
  setPointCoor,
  setPointAddress,
  onDragEnd,
  clearPoint,
  addPoint,
  clearPoints,
  setRouteVariants,
  setRouteIsLoading,
  setUseTrafficLayers,
  setUseTrafficLayersIsDisabled,
  setActiveIndexRoute,
  setTimeStatus,
  addIntermediatePoint,
  updateByFavorite,
  setPointName,
  setFavoriteForPoint,
  setRouteTimeVariant,
  setPeriod,
  setTypeVariant,
  setPointError,
  setMapIsLoaded,
  setTemplateId,
  setIsSimulatedDtp,
  setSimulatedDtp,
  setIsCompareWithYandex,
  setYandexVariant,
  setIsYandexVariantLoading,
  setSimulatedDtpDate,
  setIsRouteWithMetro,
  setRouteInfos,
  fetchRouteWithSaving,
  setIsShowYandexRoute,
} = routerSlice.actions;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  state: initialState,
  reducer: routerSlice.reducer,
};
