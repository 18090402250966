import React from "react";
import { NavigationGroupeLayers } from "components";
import { useData } from "./cargo-graph-button.hooks";
import * as consts from "./cargo-graph-button.consts";

export const CargoGraphButton = () => {
  const { isActive, handleClick } = useData();

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};
