/* eslint-disable no-underscore-dangle */
import { FAVORITE_SET, FAVORITE_CHANGE_ADDRESS, FAVORITE_CHANGE_NAME, FAVORITE_CHANGE_LOCATION } from "./action-types";

export const initialState = {
  items: [],
};

export const favoriteReducer = (state = initialState, { type, payload }) => {
  if (type === FAVORITE_SET)
    return {
      ...state,
      items: payload,
    };

  if (type === FAVORITE_CHANGE_ADDRESS)
    return {
      ...state,
      items: state.items.map((el) => {
        if (el.id === payload.id) el.address = payload.address;

        return el;
      }),
    };

  if (type === FAVORITE_CHANGE_NAME)
    return {
      ...state,
      items: state.items.map((el) => {
        if (el.id === payload.id) el.name = payload.name;

        return el;
      }),
    };

  if (type === FAVORITE_CHANGE_LOCATION)
    return {
      ...state,
      items: state.items.map((el) => {
        if (el.id === payload.id) el.location = payload.location;

        return el;
      }),
    };

  return state;
};
