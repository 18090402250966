import React, { useEffect, useMemo, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { APP_ENV } from "app-env";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { FilterButtonWithCount } from "components";
import { overSpeedEnum } from "./help";
import _ from "lodash";
import { speedcamSlice } from "features/ctrl-speedcam/store/slice";
import axios from "axios";
import moment from "moment";

export type FixationDataType = {
  cameraId: number;
  fixationTime: string;
  id: number;
  lat: number;
  lng: number;
  plate: string;
  speed: number;
  overSpeed: number;
};

export const useSubscribeFixations = (camId: number) => {
  const token = useSelector((s: GState) => s.oidc.user.access_token);

  const [fixationData, setFixationData] = useState<FixationDataType | undefined>();

  const [fixationPhoto, setFixationPhoto] = useState<string | undefined>();

  const [isSubscribe, setIsSubscribe] = useState(true);

  const dispatch = useDispatch();

  const filter = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.filter);

  const setFilter = (filterValue: "withViolations" | overSpeedEnum | undefined) => {
    dispatch(speedcamSlice.actions.setFilter({ id: camId, filter: filterValue }));
  };
  const currentMode = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.currentMode);
  const overSpeed = useSelector((state: GState) => state.speedcam.speedcams?.[camId]?.overSpeed);

  useEffect(() => {
    setFilter(undefined);
    setFixationPhoto(undefined);
  }, [currentMode]);

  const setFilterHandler = (f: typeof filter) => {
    if (f !== filter) {
      setFixationPhoto(undefined);
      setFilter(f);
    }
  };

  const violationFilterButtons = useMemo(
    () => (
      <>
        <FilterButtonWithCount
          text={"Все"}
          isActive={filter === "withViolations"}
          onClick={() => setFilterHandler("withViolations")}
        />
        <FilterButtonWithCount
          text={"20 – 40 км/ч"}
          isActive={filter === overSpeedEnum.more20}
          onClick={() => setFilterHandler(overSpeedEnum.more20)}
        />
        <FilterButtonWithCount
          text={"40 – 60 км/ч"}
          isActive={filter === overSpeedEnum.more40}
          onClick={() => setFilterHandler(overSpeedEnum.more40)}
        />
        <FilterButtonWithCount
          text={"60 – 80 км/ч"}
          isActive={filter === overSpeedEnum.more60}
          onClick={() => setFilterHandler(overSpeedEnum.more60)}
        />
        <FilterButtonWithCount
          text={">80 км/ч"}
          isActive={filter === overSpeedEnum.more80}
          onClick={() => setFilterHandler(overSpeedEnum.more80)}
        />
      </>
    ),
    [filter]
  );

  const fixationFilterButtons = useMemo(
    () => (
      <>
        <FilterButtonWithCount
          text={"Все"}
          isActive={filter === undefined}
          onClick={() => setFilterHandler(undefined)}
        />
        <FilterButtonWithCount
          text={"Без нарушений"}
          isActive={filter === overSpeedEnum.less20}
          onClick={() => setFilterHandler(overSpeedEnum.less20)}
        />
        <FilterButtonWithCount
          disable={!Boolean(overSpeed)}
          text={"С нарушениями"}
          isActive={filter === "withViolations"}
          onClick={() => setFilterHandler("withViolations")}
        />
      </>
    ),
    [filter, overSpeed]
  );

  useEffect(() => {
    const getFilters = (filterValue: typeof filter): { violations?: string } | undefined => {
      if (filterValue === overSpeedEnum.less20) {
        return {
          violations: "not",
        };
      }
      if (filterValue === overSpeedEnum.more20) {
        return {
          violations: "overspeed20",
        };
      }
      if (filterValue === overSpeedEnum.more40) {
        return {
          violations: "overspeed40",
        };
      }
      if (filterValue === overSpeedEnum.more60) {
        return {
          violations: "overspeed60",
        };
      }
      if (filterValue === overSpeedEnum.more80) {
        return {
          violations: "overspeed80",
        };
      }
      if (filterValue === "withViolations") {
        return {
          violations: "any",
        };
      }
    };

    if (fixationPhoto === undefined) {
      const stringFilter = getFilters(filter)?.violations;
      axios(
        `${APP_ENV.REACT_APP_DOMAIN_API}traffic-cam/Speedcam/lastfixationbycamid?` +
          `whCamId=${camId}&maxSecondOld=3600` +
          `${stringFilter ? `&violations=${stringFilter}` : ""}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then(async (r) => {
          if (r.status === 404) {
            setFixationPhoto("No-Data");
          } else {
            const { event_time, id, lat, lng, speed_threshold: overSpeed, speed, cam_wh_id: cameraId, plate } = r.data;

            const fixationTime = moment(event_time.endsWith("Z") ? event_time : event_time + "Z").format(
              "YYYY-MM-DDTHH:mm:ss"
            );
            setFixationData({
              fixationTime,
              id,
              lat,
              lng,
              overSpeed,
              speed,
              cameraId,
              plate,
            });
          }
        })
        .catch(() => {
          setFixationPhoto("No-Data");
        });
    }
  }, [filter, fixationPhoto]);

  useEffect(() => {
    fixationPhoto && URL.revokeObjectURL(fixationPhoto);
    setFixationPhoto("");
    if (fixationData?.id) {
      fetch(APP_ENV.REACT_APP_DOMAIN_API + "traffic-cam/Speedcam/photo/detail/fixation/" + fixationData?.id, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(async (r) => {
          const blob = await r.blob();
          setFixationPhoto(URL.createObjectURL(blob));
        })
        .catch((e) => console.log(e));
    }
  }, [fixationData]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(APP_ENV.REACT_APP_DOMAIN_API + "traffic-cam/hubnotifydata", {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();

    const subscribeToData = () => {
      const filter = {
        camera_ids: String(camId),
      };

      connection
        .invoke("Subscribe", filter)
        .then(() => {
          console.log("Успешно подписан на канал SignalR.");
        })
        .catch((error) => {
          console.error("Ошибка при подписке на канал SignalR:", error);
        });
    };

    const debouncedFunc = _.debounce((data) => {
      setFixationPhoto("");
      setFixationData(data);
    }, 500);

    connection.on("fixation", (data) => {
      const { speed, overSpeed } = data;

      const diff = speed - overSpeed;

      if (filter === undefined) {
        debouncedFunc(data);
        return;
      }

      if (filter === "withViolations" && diff > overSpeedEnum.more20) {
        debouncedFunc(data);
        return;
      }

      if (filter === overSpeedEnum.more80 && diff > filter) {
        debouncedFunc(data);
        return;
      }

      if (filter === overSpeedEnum.more60 && diff > filter) {
        debouncedFunc(data);
        return;
      }

      if (filter === overSpeedEnum.more40 && diff > filter) {
        debouncedFunc(data);
        return;
      }

      if (filter === overSpeedEnum.more20 && diff > filter) {
        debouncedFunc(data);
        return;
      }

      if (filter === overSpeedEnum.less20 && diff < filter * -1) {
        debouncedFunc(data);
        return;
      }
    });

    const start = () => {
      connection
        .start()
        .then(() => {
          console.log("Соединение SignalR установлено.");
          subscribeToData();
        })
        .catch((error) => {
          console.error("Ошибка при установке соединения SignalR:", error);
        });
    };

    const stop = () => {
      connection
        .invoke("UnSubscribe")
        .then(() => {
          console.log("Отмена подписки на канал SignalR.");
          connection.stop();
        })
        .catch((error) => {
          console.error("Ошибка при отмене подписки на канал SignalR:", error);
        });
    };

    if (isSubscribe) {
      start();
    } else {
      stop();
    }

    return () => {
      stop();
    };
  }, [isSubscribe, filter]);

  return {
    isSubscribe,
    setIsSubscribe,
    fixationPhoto,
    fixationData,
    violationFilterButtons,
    fixationFilterButtons,
  };
};
