export default class RouteProperties {
  SMALL_ZOOM_WIDTH_PATH: number;

  MEDIUM_ZOOM_WIDTH_PATH: number;

  BIG_ZOOM_WIDTH_PATH: number;

  constructor() {
    this.SMALL_ZOOM_WIDTH_PATH = 7;
    this.MEDIUM_ZOOM_WIDTH_PATH = 9;
    this.BIG_ZOOM_WIDTH_PATH = 14;
  }

  get lineWidth() {
    return [
      "interpolate",
      ["exponential", 1.5],
      ["zoom"],
      5,
      this.SMALL_ZOOM_WIDTH_PATH,
      12,
      this.MEDIUM_ZOOM_WIDTH_PATH,
      18,
      this.BIG_ZOOM_WIDTH_PATH,
    ];
  }

  get lineWidthStroke() {
    return [
      "interpolate",
      ["exponential", 1.5],
      ["zoom"],
      5,
      this.SMALL_ZOOM_WIDTH_PATH * 1.3,
      12,
      this.MEDIUM_ZOOM_WIDTH_PATH * 1.3,
      18,
      this.BIG_ZOOM_WIDTH_PATH * 1.3,
    ];
  }
}
