import React, { memo, useCallback, useMemo } from "react";
import { APP_ENV } from "app-env";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { RouteCosting } from "api/router/model/router";
import { routerSlice } from "features/ctrl-route/store/slice";
import { icons, titles } from "./consts";
import { useRouterCosting } from "../../hooks";

import "./index.scss";

const baseClass = "route-transport-type";

type Props = {
  type: keyof typeof titles;
  isDisabled: boolean;
  active: boolean;
  onClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
};

const RouteTransportTypeItem: React.FC<Props> = memo(({ type, isDisabled, active, onClick }) => {
  const className = useMemo(
    () =>
      cn(`${baseClass}__item`, `${baseClass}__${type.toLowerCase()}`, {
        [`${baseClass}__item_active`]: active,
        [`${baseClass}__item_disabled`]: isDisabled,
        [`${baseClass}__item_active_disabled`]: active && isDisabled,
      }),
    [type, active, isDisabled]
  );

  return (
    <button title={titles[type]} disabled={isDisabled} className={className} onClick={onClick}>
      {icons[type] ?? <div />}
    </button>
  );
});

const useIsDIsabled = () => useSelector((state: GState) => state.router.isDisabled);

const checkIsTypeDisabled = (type: RouteCosting) => {
  switch (type) {
    case RouteCosting.Multimodal:
      return APP_ENV.REACT_APP_CONFIGURATION?.routing?.groundUrbanPassengerTransport === "disable";
    case RouteCosting.Cargo:
      return APP_ENV.REACT_APP_CONFIGURATION?.routing?.cargoVehicles === "disable";
    case RouteCosting.Template:
      return APP_ENV.REACT_APP_CONFIGURATION?.routing?.template === "disable";
    default:
      return false;
  }
};

const checkIsTypeHidden = (type: RouteCosting) => {
  switch (type) {
    case RouteCosting.Multimodal:
      return APP_ENV.REACT_APP_CONFIGURATION?.routing?.groundUrbanPassengerTransport === "hidden";
    case RouteCosting.Cargo:
      return APP_ENV.REACT_APP_CONFIGURATION?.routing?.cargoVehicles === "hidden";
    case RouteCosting.Template:
      return APP_ENV.REACT_APP_CONFIGURATION?.routing?.template === "hidden";
    default:
      return false;
  }
};

export const RouteTransportType: React.FC = () => {
  const costing = useRouterCosting();
  const isDisabled = useIsDIsabled();
  const dispatch = useDispatch();
  const setCosting = useCallback(
    (type: RouteCosting) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      if (costing === type) return;

      dispatch(routerSlice.actions.setCosting(type));
    },
    [costing, dispatch]
  );

  return (
    <div className={baseClass} data-debug="test">
      {Object.values(RouteCosting).map((type) => {
        if (checkIsTypeHidden(type)) return null;
        const isActive = costing === type;
        const onClick = setCosting(type as RouteCosting);
        const _isDisabled = isDisabled || checkIsTypeDisabled(type);
        return (
          <RouteTransportTypeItem isDisabled={_isDisabled} type={type} key={type} active={isActive} onClick={onClick} />
        );
      })}
    </div>
  );
};
