import { preciseNumber } from "./precise-number";

/**
 * Форматирует длину маршрута
 * @param length - длина маршрута
 */
export function formatLengthRoute(length: number) {
  if (length > 100) return `${length.toFixed(0)} км`;

  const precisedLength = preciseNumber(length);

  return `${precisedLength} км`;
}
