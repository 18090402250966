/* eslint-disable no-shadow */
import moment from "moment";
import { TrafficData } from "api/traffic/model/traffic";
import { TrafficAPI } from "api/traffic";
import { getScoreParamsSplit, getScoreParamsSplitCorrelation } from "../../api/detector-data/detector-data";

export async function loadData(model, correlationDay, isDetectorScore) {
  if (model && Array.isArray(model.es))
    if (!model.es.length) {
      return new Promise((resolve) =>
        resolve([
          { periodM: 5, scores: [] },
          { periodM: 15, scores: [] },
          { periodM: 30, scores: [] },
          { periodM: 60, scores: [] },
        ])
      );
    }

  const int = moment(model.day).valueOf();

  try {
    const input = { es: model.es, day: model.day, data: TrafficData.Merged, split: [5, 15, 30, 60] };

    let result = [];
    if (isDetectorScore) {
      result = correlationDay
        ? await getScoreParamsSplitCorrelation(
            { day: model.day, data: TrafficData.Merged, split: [5, 15, 30, 60] },
            correlationDay
          )
        : (await getScoreParamsSplit({ day: model.day, data: TrafficData.Merged, split: [5, 15, 30, 60] })).data;
    } else {
      result = correlationDay
        ? await TrafficAPI.scoring.citySplitCorrelation(input, correlationDay)
        : await TrafficAPI.scoring.citySplit(input);
    }
    const data = result.map((el) => ({ periodM: el.split, scores: el.scores }));
    return { data, fetchedDayTimestamp: int };
  } catch (error) {
    console.error("catch", error);
  }
}
