import styled from "styled-components";

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  align-items: center;
  border-bottom: 1px var(--separator-color-default) solid;
`;

const CardTitleBlock = styled.div`
  display: flex;
  gap: 4px;
`;

const EdgeId = styled.div`
  padding-left: 4px;
  font-weight: 600;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color-default);
`;

const CardButtonBlock = styled.div`
  display: flex;
  gap: 28px;
`;

export { Title, EdgeId, CardTitleBlock, CardButtonBlock, HeaderBlock };
