type CacheResponse<T> = T | undefined;

export class PayloadCache {
  private cache: { [key: string]: any } = {};

  public addCache = <T>(payloadKey: string, cache: T) => {
    this.cache[payloadKey] = cache;
  };

  public getCache = <T>(payloadKey: string): CacheResponse<T> => this.cache[payloadKey];

  public hasCache = (payloadKey: string): boolean => !!this.cache[payloadKey];
}
