import { TrafficAPI } from "api/traffic";
import { TrafficData, TrafficPeriod } from "api/traffic/model/traffic";
import { TrafficTileFilter } from "api/traffic/model/tiles";
import { getRoadDetectors } from "../../api/detector-data/detector-data";
import moment from "moment";
import TrafficProperties from "../../map-helpers/properties/traffic-properties";

/* eslint-disable max-lines-per-function */
const TRAFFIC_ANIMATE = "traffic-animate";

// Colors for traffic layers
const trafficColors = {};
trafficColors[-1] = "#7f7f7f";
trafficColors[1] = "#0ABE0A";
trafficColors[2] = "#FFC203";
trafficColors[3] = "#F8000A";
trafficColors[4] = "#B00000";
trafficColors[100] = "#0A820A";

function getBeforeId(map) {
  const railwayLayer = map.getLayer("railway-subway");
  const equipmentLayer = map.getLayer("equipment-data");
  if (railwayLayer) return "railway-subway";

  if (equipmentLayer) return "equipment-data";

  return null;
}

/**
 * Constructor for URL traffic tiles
 * @param {Boolean} forecast - forecast
 * @param {Object} time - time period
 * @param {Array} external - es filter
 * @param {String} roads - road filter
 * @returns {String} - URL
 */
function getTrafficUrlTiles(forecast, time, external, roads) {
  const input = { external };
  if (roads === "highway") input.filter = TrafficTileFilter.Highway;
  if (roads === "trans") input.filter = TrafficTileFilter.Transport;
  if (roads === "dedicated") input.filter = TrafficTileFilter.DedicatedLines;
  if (time) {
    input.from = time.from;
    input.to = time.to;
  } else {
    input.period = TrafficPeriod.Last;
  }
  if (forecast) {
    input.data = TrafficData.Forecast;
  } else {
    input.data = TrafficData.Historical;
  }
  return TrafficAPI.tiles.traffic(input);
}

function getDetectorUrlTiles(time, activeFiltersIds) {
  const from = moment(time.from).format("YYYY-MM-DDTHH:mm");
  const to = moment(time.to).format("YYYY-MM-DDTHH:mm");
  const params = {
    from,
    to,
  };
  if (activeFiltersIds) {
    params.vc = activeFiltersIds;
  }

  return getRoadDetectors(params);
}

/**
 * Add traffic layer to map
 * @param {String} trafficUrlTiles - url for traffic tiles
 * @param {String} trafficID - id traffic layer
 * @param {Object} map - map
 * @param {Number} index - index of layer
 * @returns {Promise} - when all tiles loaded return promise
 */

export function addTrafficLayer({ map, item, external, roads, duration }, isDetectorMode, activeFiltersIds) {
  const trafficUrlTiles = getTrafficUrlTiles(item.forecast, item, external, roads);
  const detectorUrlTiles = getDetectorUrlTiles(item, activeFiltersIds);
  const trafficID = `${TRAFFIC_ANIMATE}-${item.name}`;

  const properties = new TrafficProperties();

  const detectorTrackLayer = {
    id: trafficID,
    type: "line",
    source: trafficID,
    layout: properties.getLayout(),
    paint: {
      ...properties.getPaint(),
      "line-width": 3,
      "line-offset": properties.getLineOffsetByForward(),
      "line-opacity-transition": {
        duration,
        delay: 0,
      },
      "line-opacity": 0,
    },
  };

  const trafficLayer = {
    "id": trafficID,
    "type": "line",
    "source": trafficID,
    "source-layer": "traffic",
    "layout": {
      "line-join": "round",
      "line-cap": "round",
    },
    "paint": {
      "line-color": [
        "match",
        ["get", "traffic"],
        100,
        trafficColors[100],
        4,
        trafficColors[4],
        3,
        trafficColors[3],
        2,
        trafficColors[2],
        1,
        trafficColors[1],
        "#FFFFFF",
      ],
      "line-offset": [
        "let",
        "offsetType",
        [
          "match",
          ["get", "oneway"],
          0,
          1,
          1,
          [
            "case",
            [
              "any",
              ["==", ["get", "highway"], "motorway"],
              ["==", ["get", "highway"], "trunk"],
              ["==", ["get", "highway"], "primary"],
            ],
            2,
            0,
          ],
          0,
        ],
        [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          5,
          ["match", ["var", "offsetType"], 1, 0.5, 2, 2, 0],
          11,
          ["match", ["var", "offsetType"], 1, 0.75, 2, 1, 0],
          18,
          ["match", ["var", "offsetType"], 1, 4, 2, 0, 0],
        ],
      ],
      "line-width": [
        "let",
        /* ____________________________________________ */
        "min_zoom_big_road",
        ["match", ["get", "oneway"], 0, 1, 1, 2, 0],
        "min_zoom_mid_road",
        0,
        "min_zoom_sml_road",
        0,
        /* ____________________________________________ */
        "mid_zoom_big_road",
        ["match", ["get", "oneway"], 0, 1.5, 1, 2.5, 0],
        "mid_zoom_mid_road",
        ["match", ["get", "oneway"], 0, 1.25, 1, 2, 0],
        "mid_zoom_sml_road",
        0.5,
        /* ____________________________________________ */
        "max_zoom_big_road",
        ["match", ["get", "oneway"], 0, 12, 1, 24, 0],
        "max_zoom_mid_road",
        ["match", ["get", "oneway"], 0, 8, 1, 16, 0],
        "max_zoom_sml_road",
        ["match", ["get", "oneway"], 0, 6, 1, 10, 0],
        /* ____________________________________________ */
        [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          5,
          [
            "match",
            ["get", "highway"],
            "motorway",
            ["var", "min_zoom_big_road"],
            "motorway_link",
            ["var", "min_zoom_big_road"],
            "motorway_junction",
            ["var", "min_zoom_big_road"],
            "trunk",
            ["var", "min_zoom_big_road"],
            "trunk_link",
            ["var", "min_zoom_big_road"],
            "primary",
            ["var", "min_zoom_big_road"],
            "primary_link",
            ["var", "min_zoom_mid_road"],
            "secondary",
            ["var", "min_zoom_mid_road"],
            "secondary_link",
            ["var", "min_zoom_mid_road"],
            "tertiary",
            ["var", "min_zoom_mid_road"],
            ["var", "min_zoom_sml_road"],
          ],
          11,
          [
            "match",
            ["get", "highway"],
            "motorway",
            ["var", "mid_zoom_big_road"],
            "motorway_link",
            ["var", "mid_zoom_big_road"],
            "motorway_junction",
            ["var", "mid_zoom_big_road"],
            "trunk",
            ["var", "mid_zoom_big_road"],
            "trunk_link",
            ["var", "mid_zoom_big_road"],
            "primary",
            ["var", "mid_zoom_mid_road"],
            "primary_link",
            ["var", "mid_zoom_mid_road"],
            "secondary",
            ["var", "mid_zoom_mid_road"],
            "secondary_link",
            ["var", "mid_zoom_mid_road"],
            "tertiary",
            ["var", "mid_zoom_mid_road"],
            ["var", "mid_zoom_sml_road"],
          ],
          18,
          [
            "match",
            ["get", "highway"],
            "motorway",
            ["var", "max_zoom_big_road"],
            "motorway_link",
            ["var", "max_zoom_big_road"],
            "motorway_junction",
            ["var", "max_zoom_big_road"],
            "trunk",
            ["var", "max_zoom_big_road"],
            "trunk_link",
            ["var", "max_zoom_big_road"],
            "primary",
            ["var", "max_zoom_big_road"],
            "primary_link",
            ["var", "max_zoom_mid_road"],
            "secondary",
            ["var", "max_zoom_mid_road"],
            "secondary_link",
            ["var", "max_zoom_mid_road"],
            "tertiary",
            ["var", "max_zoom_mid_road"],
            ["var", "max_zoom_sml_road"],
          ],
        ],
      ],

      "line-opacity-transition": {
        duration,
        delay: 0,
      },
      "line-opacity": 0,
    },
  };

  const trafficDirectionArrowLayer = {
    "id": `${trafficID}-arrow`,
    "type": "symbol",
    "source": trafficID,
    "source-layer": "traffic",
    "minzoom": 15,
    "layout": {
      "icon-image": "traffic-direction-arrow",
      "symbol-placement": "line",
      "symbol-spacing": {
        stops: [
          [2, 10],
          [16, 60],
          [20, 150],
        ],
      },
      "symbol-avoid-edges": true,
      "icon-size": {
        stops: [
          [2, 0.5],
          [16, 1],
          [20, 1.5],
        ],
      },
    },
    "filter": ["==", "oneway", 1],
  };
  const beforeId = getBeforeId(map);

  if (isDetectorMode) {
    detectorUrlTiles
      .then((r) => {
        map.addSource(trafficID, {
          type: "geojson",
          data: r.data,
        });
        map.addLayer(detectorTrackLayer, beforeId);
      })
      .catch((er) => {
        console.log(er);
      });
  } else {
    map.addSource(trafficID, {
      type: "vector",
      tiles: [trafficUrlTiles],
    });
    map.addLayer(trafficDirectionArrowLayer, beforeId);
    map.addLayer(trafficLayer, beforeId);
  }

  return new Promise((resolve) => {
    const timerId = setInterval(() => {
      const isTileLoaded = map.areTilesLoaded();
      if (isTileLoaded) {
        clearInterval(timerId);
        resolve("resolved");
      }
    }, 300);
  });
}

export function showLayer({ renderedIndex, map }) {
  const id = `${TRAFFIC_ANIMATE}-${renderedIndex}`;
  if (map.getLayer(id)) map.setPaintProperty(id, "line-opacity", 1);
}
export function hideLayer({ renderedIndex, map }) {
  const id = `${TRAFFIC_ANIMATE}-${renderedIndex}`;
  if (map.getLayer(id)) map.setPaintProperty(id, "line-opacity", 0);
}

/**
 * Remove traffic layers from map
 * @param {Object} map -map
 */
export function removeAnimateTrafficLayers(map) {
  let index = 0;

  while (map.getLayer(`${TRAFFIC_ANIMATE}-${index}`)) {
    const layer = map.getLayer(`${TRAFFIC_ANIMATE}-${index}`);
    if (layer) map.removeLayer(`${TRAFFIC_ANIMATE}-${index}`);

    const arrowLayer = map.getLayer(`${TRAFFIC_ANIMATE}-${index}-arrow`);
    if (arrowLayer) map.removeLayer(`${TRAFFIC_ANIMATE}-${index}-arrow`);

    const source = map.getSource(`${TRAFFIC_ANIMATE}-${index}`);
    if (source) map.removeSource(`${TRAFFIC_ANIMATE}-${index}`);

    index++;
  }
}

/**
 * Hide/show current traffic layer
 * @param {Object} map - map
 * @param {Boolean} flag - flag
 */
export function hideCurrentTrafficLayer(map, flag) {
  if (flag) {
    const trafficLayer = map.getLayer("traffic-id");
    if (trafficLayer) map.setLayoutProperty("traffic-id", "visibility", "none");

    const arrowTrafficLayer = map.getLayer("traffic-id-arrow");
    if (arrowTrafficLayer) map.setLayoutProperty("traffic-id-arrow", "visibility", "none");
  } else {
    const trafficLayer = map.getLayer("traffic-id");
    if (trafficLayer) map.setLayoutProperty("traffic-id", "visibility", "visible");

    const arrowTrafficLayer = map.getLayer("traffic-id-arrow");
    if (arrowTrafficLayer) map.setLayoutProperty("traffic-id-arrow", "visibility", "visible");
  }
}
/**
 * Hide/show current traffic layer
 * @param {Object} map - map
 * @param {Boolean} flag - flag
 */
export function hideCurrentDetectorRoadLayer(map, flag) {
  if (flag) {
    const trafficLayer = map.getLayer("road-detector-id");
    if (trafficLayer) map.setLayoutProperty("road-detector-id", "visibility", "none");
  } else {
    const trafficLayer = map.getLayer("road-detector-id");
    if (trafficLayer) map.setLayoutProperty("road-detector-id", "visibility", "visible");
  }
}
