import { Layers } from "../../layers";
import { BaseLayer } from "../../base-layer";

export class BusStopLayer2D extends BaseLayer<any, any> {
  public readonly id = Layers.Identifiers.BUS_STOP_ID;

  constructor(map: mapboxgl.Map, tileUrl: string) {
    super(map, {
      id: Layers.Identifiers.BUS_STOP_ID,
      sourceType: "tile",
      tileUrl,
      beforeId: Layers.Utils.getBeforeId(Layers.Identifiers.BUS_STOP_ID, map),
    });

    const layerConfig: mapboxgl.SymbolLayer = {
      "id": Layers.Identifiers.BUS_STOP_ID,
      "type": "symbol",
      "source": Layers.Identifiers.BUS_STOP_ID,
      "source-layer": "stops",
      "layout": {
        "icon-image": Layers.Identifiers.BUS_STOP_MAP_IMAGE_KEY,
        "icon-size": {
          base: 1,
          stops: [
            [8, 0.05],
            [22, 1.2],
          ],
        },
        "icon-allow-overlap": true,
        "icon-rotate": ["-", ["get", "angle"], 270],
        "icon-pitch-alignment": "map",
      },
      "paint": {
        "icon-opacity": this.visibility ? 1 : 0,
      },
    };

    this.setLayer(layerConfig);
    this.addSource();
    this.addLayer();
  }

  public readonly setTileUrl = (tileUrl: string) => {
    const source = this.map.getSource(this.id) as mapboxgl.VectorSourceImpl;
    if (!source) return;
    source.tiles = [tileUrl];
  };

  public readonly setOpacityVisibility = (isVisible: boolean) => {
    const layer = this.map.getLayer(this.id);
    if (!layer) return new Error();
    this.map.setPaintProperty(this.id, "icon-opacity", isVisible ? 1 : 0);
  };
}
