import { useStore } from "effector-react";
import { store } from "./graph-compare";

const useIsVisible = () => useStore(store).isVisible;
const useGraphVersions = () => useStore(store).graphVersions;

export const hooks = {
  useIsVisible,
  useGraphVersions,
};

export type Hooks = typeof hooks;
