import React, { MouseEventHandler, memo } from "react";
import { ReactComponent as SettingsSVG } from "../../../assets/icons/settings.svg";
import { SettingPopup } from "./settings-popup";
import { useButton } from "./use-button";

import "./settings-button.scss";

type Props = {
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const SettingsButton: React.FC<Props> = memo(({ isActive = false, onClick }) => {
  const { settingsMenuRef, containerClassName, buttonClass } = useButton(isActive);

  return (
    <div className={containerClassName} ref={settingsMenuRef}>
      <button className={buttonClass} onClick={onClick}>
        <SettingsSVG />
      </button>
      <SettingPopup />
    </div>
  );
});
