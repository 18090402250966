import { shared } from "shared";
import { UnitsCountDiagramParams } from "../../../../types";

export const excludeDisabledFilterPredicate = (color: string) =>
  color !== shared.unitsCountDiagram.unitsCountColor["-1"];

export const getValues = (params: UnitsCountDiagramParams | null) => {
  if (!params) return [];

  const { max, limits } = params;
  const values = Object.values(limits);

  return [0, ...values, max];
};
