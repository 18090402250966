import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { AppThunk } from "documentations";
import { IsochronesInput } from "api/router/model/router";
import { RouterAPI } from "../../api/router";
import { IsochroneState } from "./types";
import { getHashIsochrone, hashIsochrone } from "./isochrone-hash";

export const initialState: IsochroneState = {
  selected: [5, 10, 15],
  items: [5, 10, 15, 20, 30, 45, 60, 90, 120],
  isActive: false,
  isFetching: false,
  data: null,
  dataIndex: 0,
  error: null,
  isDisabled: true,
};

export const isochroneSlice = createSlice({
  name: "isochrone",
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    endFetching(state) {
      state.isFetching = false;
    },
    fetchingFailed(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    toggleIsActive(state, { payload }: PayloadAction<boolean>) {
      state.isActive = payload;
    },
    updateSelectedIsochrone(state, { payload }: PayloadAction<Array<number>>) {
      state.selected = payload;
    },
    setData(state, { payload }: PayloadAction<Array<GeoJSON.FeatureCollection> | null>) {
      state.data = payload;
    },
    updateDataIndex(state) {
      state.dataIndex += 1;
    },
    setIsDisabled(state, { payload }: PayloadAction<boolean>) {
      state.isDisabled = payload;
    },
  },
});

export const {
  startFetching,
  fetchingFailed,
  toggleIsActive,
  updateSelectedIsochrone,
  setData,
  endFetching,
  updateDataIndex,
  setIsDisabled,
} = isochroneSlice.actions;

export const isochroneFetchData =
  (payload: IsochronesInput): AppThunk =>
  async (dispatch: Dispatch) => {
    try {
      const dataPolygons = { ...payload, polygons: true };

      const dataLines = { ...payload, polygons: false };

      const hashCode = getHashIsochrone(payload);

      /** Проверка загружали данные по этим параметрам или нет */
      if (hashIsochrone[hashCode]) {
        const hashData = hashIsochrone[hashCode];

        dispatch(setData(hashData));
        dispatch(updateDataIndex());
      } else {
        dispatch(startFetching());

        const polygons = await RouterAPI.router.isochrones(dataPolygons);
        const lines = await RouterAPI.router.isochrones(dataLines);
        const data = [polygons, lines];

        dispatch(setData(data));
        dispatch(endFetching());
        dispatch(updateDataIndex());

        hashIsochrone[hashCode] = data;
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch(fetchingFailed(err.toString()));
      }
      console.error(`${err}: Error in isochrone reducer`);
    }
  };
