import thunk from "redux-thunk";
import { cameraMiddleware, cameraSlice, cameraState } from "features/ctrl-camera";
import { detectorMiddleware, detectorSlice, detectorState } from "features/ctrl-detector";
import { dtpMiddleware, dtpSlice, dtpState } from "features/ctrl-dtp";
import { graphMiddleware, graphSlice, graphState } from "features/ctrl-graph";
import { floodingMiddleware, floodingSlice, floodingState } from "features/ctrl-flooding";
import { routerMiddleware, routerSlice, routerState } from "features/ctrl-route";
import { speedcamMiddleware, speedcamSlice, speedcamState } from "features/ctrl-speedcam";
import { telemetryMiddleware, telemetrySlice, telemetryState } from "features/ctrl-telemetry";
import { tlsMiddleware, tlsSlice, tlsState } from "features/ctrl-tls";
import { apiMiddleWare } from "api";
import { sectorAnalysisMiddleware, sectorAnalysisSlice, sectorAnalysisState } from "features/sector-analysis";
import { favoriteReducer, favoriteState } from "./user-favorite";
import { historyReducer, historyState } from "./user-history";
import { trafficReducer, trafficState } from "./traffic";
import { globalReducer, globalState } from "./global/reducer";
import { isochroneSlice, isochroneState } from "./isochrone";
import { viewReducer, viewState } from "./view";
import { weatherSlice, weatherState } from "./weather";
import { isochroneMiddleware } from "./isochrone/isochrone-middleware";
import { userMiddleware } from "./user-favorite/user-middleware";
import { roadDetectorMiddleware, roadDetectorSlice, roadDetectorState } from "../features";
import { historyTravelSlice, historyTravelState } from "../features/ctrl-history-travel";

export const initialActions = {
  module: "@oidc/update",
  project: "redux-oidc/USER_FOUND",
};

export const reducers = {
  camera: cameraSlice.reducer,
  detector: detectorSlice.reducer,
  dtp: dtpSlice.reducer,
  favorite: favoriteReducer,
  flooding: floodingSlice.reducer,
  global: globalReducer,
  graph: graphSlice.reducer,
  history: historyReducer,
  isochrone: isochroneSlice.reducer,
  router: routerSlice.reducer,
  sectorAnalysis: sectorAnalysisSlice.reducer,
  speedcam: speedcamSlice.reducer,
  telemetry: telemetrySlice.reducer,
  tls: tlsSlice.reducer,
  roadDetector: roadDetectorSlice.reducer,
  historyTravel: historyTravelSlice.reducer,
  traffic: trafficReducer,
  view: viewReducer,
  weather: weatherSlice.reducer,
};

export const states = {
  camera: cameraState,
  detector: detectorState,
  dtp: dtpState,
  favorite: favoriteState,
  flooding: floodingState,
  global: globalState,
  graph: graphState,
  history: historyState,
  isochrone: isochroneState,
  router: routerState,
  sectorAnalysis: sectorAnalysisState,
  speedcam: speedcamState,
  telemetry: telemetryState,
  tls: tlsState,
  roadDetector: roadDetectorState,
  traffic: trafficState,
  view: viewState,
  weather: weatherState,
  historyTravel: historyTravelState,
};

export const middleWares = [
  thunk,
  apiMiddleWare,
  routerMiddleware,
  cameraMiddleware,
  detectorMiddleware,
  dtpMiddleware,
  floodingMiddleware,
  graphMiddleware,
  isochroneMiddleware,
  sectorAnalysisMiddleware,
  speedcamMiddleware,
  telemetryMiddleware,
  tlsMiddleware,
  userMiddleware,
  roadDetectorMiddleware,
];
