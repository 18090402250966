/* eslint-disable no-underscore-dangle */
import { TIME_UPDATE_IS_ACTIVE } from "old-store/view/action-types";
import {
  TRAFFIC_MAKE_ACTIVE,
  TRAFFIC_SCORE_LEVEL,
  TRAFFIC_SET_FAILED_ALTERNATIVE,
  TRAFFIC_SET_FAILED_SCORE,
  TRAFFIC_SET_IS_EMPTY_PIECES,
  TRAFFIC_SET_IS_TRAFFIC_MODE_TOGGLE,
  TRAFFIC_TOGGLE_ACTIVE,
  TRAFFIC_UPDATE_ACTIVE,
  TRAFFIC_UPDATE_ALTERNATIVE,
  TRAFFIC_UPDATE_SCORE,
  TRAFFIC_UPDATE_TYPE,
} from "./action-types";

export const initialState = {
  isActive: false,
  isTrafficMode: true,
  score: {},
  alternative: null,
  type: "last",
  forceValue: null,
  isMonthActive: false,
  trafficPercent: 15,
  isEmptyPieces: true,
};

export const trafficReducer = (state = initialState, { type, payload }) => {
  if (type === TRAFFIC_SET_IS_EMPTY_PIECES) {
    return {
      ...state,
      isEmptyPieces: payload,
    };
  }

  if (type === "CORRELATION_UPDATE_IS_ACTIVE")
    return {
      ...state,
      isMonthActive: payload ? false : state.isMonthActive,
    };

  if (type === TIME_UPDATE_IS_ACTIVE)
    return {
      ...state,
      isMonthActive: false,
    };

  if (type === TRAFFIC_TOGGLE_ACTIVE)
    return {
      ...state,
      isActive: !state.isActive,
      isMonthActive: false,
    };

  if (type === TRAFFIC_UPDATE_ACTIVE)
    return {
      ...state,
      isActive: payload,
    };

  if (type === TRAFFIC_SET_FAILED_SCORE)
    return {
      ...state,
      score: {
        averageSpeed: null,
        dynamicsID: 7,
        dynamicsMessage: "Нет данных",
        jamLengthPercent: 0,
        prevAverageSpeed: null,
        prevJamLengthPercent: 0,
        score: null,
      },
    };

  if (type === TRAFFIC_UPDATE_SCORE)
    return {
      ...state,
      score: payload,
    };

  if (type === TRAFFIC_SET_FAILED_ALTERNATIVE)
    return {
      ...state,
      alternative: null,
    };

  if (type === TRAFFIC_UPDATE_ALTERNATIVE)
    return {
      ...state,
      alternative: payload,
    };

  if (type === TRAFFIC_SCORE_LEVEL)
    return {
      ...state,
      forceValue: payload,
    };

  if (type === TRAFFIC_UPDATE_TYPE)
    return {
      ...state,
      type: payload,
    };

  if (type === TRAFFIC_MAKE_ACTIVE)
    return {
      ...state,
      isActive: true,
    };

  if (type === TRAFFIC_SET_IS_TRAFFIC_MODE_TOGGLE)
    return {
      ...state,
      isTrafficMode: !state.isTrafficMode,
    };

  return state;
};
