import * as TYPES from "../types";
import { generateKey } from ".";

export const createDefaultRoutePoint = (parameters: TYPES.RoutePoint): TYPES.RoutePoint => {
  const point: TYPES.RoutePoint = {
    key: generateKey(),
    coor: null,
    address: null,
    error: null,
    isMain: true,
    isFavorite: false,
    isLoading: false,
    favoriteId: null,
    favoriteName: null,
  };

  return { ...point, ...parameters };
};
