import React, { memo, useCallback } from "react";
import Select, { Theme, Props as SelectProps } from "react-select";
import { DropdownIndicator } from "./dropdown-indicator";
import { styles } from "./styles";
import type { Value, Option } from "./dtm-select.types";

import "./styles/dtm-select.scss";

type Props = {
  value: Value;
  options: Option[];
  isMulti: SelectProps["isMulti"];
  handleChange: SelectProps["onChange"];
  isDisabled?: boolean;
};

export const DtmSelect: React.FC<Props> = memo((props) => {
  const { value, options, isMulti, isDisabled, handleChange } = props;

  const handleThemeChange = useCallback(
    (theme: Theme) => ({
      ...theme,
      borderRadius: 4,
      colors: {
        ...theme.colors,
        primary25: "#f5f5f5",
        primary: "#f0f0f0",
        neutral5: "transparent",
      },
    }),
    []
  );

  return (
    <Select
      value={value}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      isDisabled={isDisabled}
      classNamePrefix="dtm-select"
      styles={styles}
      isMulti={isMulti}
      removeSelected={false}
      components={{ DropdownIndicator }}
      theme={handleThemeChange}
    />
  );
});
