import moment from "moment";

/**
 * Проверяет попадает выбранное время в указанный диапазон или нет
 * @param from - Нижний диапазон даты
 * @param to - Верхний диапазон даты
 * @param now  - выбранное время
 */
export function checkRangeDate(from: string, to: string, now: string): boolean {
  const dateFrom = moment(from).valueOf();
  const dateTo = moment(to).valueOf();
  const dateNow = moment(now).valueOf();
  if (dateNow >= dateFrom && dateNow <= dateTo) return true;
  return false;
}
