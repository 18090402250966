import React from "react";
import { useStoreContext } from "store";

export const usePopupLogic = () => {
  const { store } = useStoreContext();
  const isPopupVisible = store.applicationSettings.hooks.useIsPopupVisible();
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = React.useCallback(
    (e: MouseEvent) => {
      if (containerRef.current?.contains(e.target as Node)) return;
      store.applicationSettings.events.handleOutsideClick();
    },
    [store.applicationSettings.events]
  );

  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return {
    containerRef,
    isPopupVisible,
    handleApplicationSettingsButtonClick: store.applicationSettings.events.handleApplicationSettingsButtonClick,
  };
};
