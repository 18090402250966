import { getRouterInstance } from "api";
import * as TYPES from "./model/models";

const historyUrl = "/user/location/history";
const favoritesUrl = "/user/location/favorites";

export const location = {
  history: {
    /** Добавить локацию в историю пользователя */
    add: async (model: TYPES.UserLocationHistoryItemInput) => {
      const path = `${historyUrl}/add`;
      const { data } = await getRouterInstance().post<TYPES.UserLocationHistoryItemOutput>(path, model);
      return data;
    },
    /** История локаций, сортируется по последнему использованию */
    get: async () => {
      const path = `${historyUrl}/get?limit=10`;
      const { data } = await getRouterInstance().get<Array<TYPES.UserLocationHistoryItemOutput>>(path);
      return data;
    },
    /** Обновить дату последнего использования локаций в истории */
    touch: async ({ id, address }: { id: number; address: string }) => {
      const params = new URLSearchParams();
      params.set("id", String(id));
      params.set("address", address);
      const path = `${historyUrl}/touch`;
      const { data } = await getRouterInstance().get<Array<TYPES.UserLocationHistoryItemOutput>>(path, {
        params,
      });
      return data;
    },
    /** Удалить локацию из истории */
    delete: async ({ id, address }: { id: number; address: string }) => {
      const params = new URLSearchParams();
      params.set("id", String(id));
      params.set("address", address);
      const path = `${historyUrl}/delete`;
      const { data } = await getRouterInstance().delete(path);
      return data;
    },
  },
  favorites: {
    /** Добавить локацию в избранное пользователя */
    add: async (model: TYPES.UserLocationFavoriteItemInput) => {
      const path = `${favoritesUrl}/add`;
      const { data } = await getRouterInstance().post<TYPES.UserLocationFavoriteItemOutput>(path, model);
      return data;
    },
    /** Избранные локации, сортируется по последнему использованию */
    get: async () => {
      const path = `${favoritesUrl}/get`;
      const { data } = await getRouterInstance().get<Array<TYPES.UserLocationFavoriteItemOutput>>(path);
      return data;
    },
    /** Обновить дату последнего использования локаций в избранном */
    touch: async ({ address, id, name }: { id: number; address: string; name: string }) => {
      const params = new URLSearchParams();
      params.set("id", String(id));
      params.set("address", address);
      params.set("name", name);
      const path = `${favoritesUrl}/touch`;
      const { data } = await getRouterInstance().get<Array<TYPES.UserLocationFavoriteItemOutput>>(path, {
        params,
      });
      return data;
    },
    /** Обновить имя для избранной локации */
    update: async (model: TYPES.UserLocationFavoriteItemUpdateInput) => {
      const path = `${favoritesUrl}/update`;
      const { data } = await getRouterInstance().put<Array<TYPES.UserLocationHistoryItemOutput>>(path, model);
      return data;
    },
    /** Удалить локацию из избранного */
    delete: async ({ id, address, name }: { id: number; address: string; name: string }) => {
      const params = new URLSearchParams();
      params.set("id", String(id));
      params.set("address", address);
      params.set("name", name);
      const path = `${favoritesUrl}/delete`;
      const { data } = await getRouterInstance().delete(path, { params });
      return data;
    },
  },
};
