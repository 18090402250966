enum TrafficCategory {
  OverMaxSpeed,
  Good,
  Medium,
  Bad,
  Stand,
}

export const getTrafficCategory = (speed: number, maxSpeed = 60) => {
  const percent = Math.round(speed) / maxSpeed;

  let trafficCategory = TrafficCategory.Good;

  if (percent > 1.1) {
    trafficCategory = TrafficCategory.OverMaxSpeed;
  }
  if (percent < 0.5) {
    trafficCategory = TrafficCategory.Medium;
  }
  if (percent < 0.3) {
    trafficCategory = TrafficCategory.Bad;
  }
  if (percent < 0.15) {
    trafficCategory = TrafficCategory.Stand;
  }

  return trafficCategory;
};
