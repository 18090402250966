import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import classnames from "classnames";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { RouteCosting } from "api/router/model/router";
import { ReactComponent as Icon } from "assets/icons/sector-analysis.svg";

import "./route-button-analysis.scss";

const baseClass = "route-button-analysis";

const usePropsFromStore = () =>
  useSelector(
    (state: GState) => ({
      isActive: state.sectorAnalysis.isActive,
      routeVariants: state.router.routeVariants,
      costing: state.router.costing,
      isRouteDisable: state.router.isDisabled,
    }),
    shallowEqual
  );

const useIsDisabled = (props: Omit<ReturnType<typeof usePropsFromStore>, "isActive">) => {
  const { isRouteDisable, costing, routeVariants } = props;
  return (
    isRouteDisable ||
    (costing !== RouteCosting.Auto && costing !== RouteCosting.Cargo && costing !== RouteCosting.Template) ||
    !routeVariants
  );
};

const RouteButtonAnalysis = () => {
  const dispatch = useDispatch();
  const { isActive, ...rest } = usePropsFromStore();
  const isDisabled = useIsDisabled(rest);

  const buttonClass = classnames(baseClass, {
    [`${baseClass}-active`]: isActive,
    [`${baseClass}-disabled`]: isDisabled,
  });

  const handleClick = () => {
    dispatch(sectorAnalysisSlice.actions.setIsActive(!isActive));
  };

  return (
    <button className={buttonClass} onClick={handleClick} title="Задайте маршрут для участка" disabled={isDisabled}>
      <span className={`${baseClass}-title`}>Анализ участка</span>
      <Icon />
    </button>
  );
};

export default RouteButtonAnalysis;
