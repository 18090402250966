/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import RouteTrafficDistribution from "../../../common-components/route-traffic-distribution/route-traffic-distribution";
import { RouteTimeVariantsItemContainer } from "./route-time-variants-item-styled";

const className = "route-time-variants-item";

class RouteTimeVariantsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, timeFrom, timeTo, width, isActive, handleClick, timeInterval, isBold } = this.props;
    const type = timeInterval === "length" ? "length" : "time";
    const timeToClass = timeInterval === "time" ? "route-time-variants-item-to-time" : "";
    let classNameMain = className;
    classNameMain = isActive ? `${className} ${className}-active` : classNameMain;
    classNameMain = isBold ? `${className} ${className}-bold` : classNameMain;
    return (
      <RouteTimeVariantsItemContainer>
        <div className={classNameMain} onClick={handleClick}>
          <div className={`${className}-time `}>{timeFrom}</div>
          <div className={`${className}-distribution`}>
            <RouteTrafficDistribution variant={data} width={width} noPoint type={type} />
          </div>
          <div className={`${className}-time ${className}-to ${timeTo ? "" : `${className}-stub`} ${timeToClass}`}>
            {timeTo}
          </div>
        </div>
      </RouteTimeVariantsItemContainer>
    );
  }
}

export default RouteTimeVariantsItem;
