import mapboxgl from "mapbox-gl";
import { TRANSPORT_HUBS } from "map-helpers/order-layers";
import { TransportHubsTypes } from "types";

import "./transport-hubs.popup.scss";

export class Popup {
  private popup: mapboxgl.Popup;
  private timeout?: number;

  constructor(private map: mapboxgl.Map) {
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "transport-hubs-popup",
      maxWidth: "500px",
    });

    this.map.on("mousemove", this.handleMousemove);
  }

  private show = (feature: TransportHubsTypes.Feature) => {
    this.popup
      .setLngLat(feature.geometry.coordinates as mapboxgl.LngLatLike)
      .setHTML(this.getHTML(feature.properties))
      .addTo(this.map);
  };

  private remove = () => {
    this.popup.remove();
  };

  private handleMousemove = (e: mapboxgl.MapMouseEvent) => {
    if (!this.map.getLayer(TRANSPORT_HUBS)) return;

    const size = 3;
    const features = this.map.queryRenderedFeatures(
      [
        [e.point.x - size / 2, e.point.y - size / 2],
        [e.point.x + size / 2, e.point.y + size / 2],
      ],
      {
        layers: [TRANSPORT_HUBS],
      }
    );

    if (features.length) {
      return (this.timeout = window.setTimeout(() => {
        // @ts-ignore
        this.show(features[0]);
        window.clearTimeout(this.timeout);
      }, 250));
    }

    this.timeout = window.setTimeout(() => {
      this.remove();
      window.clearTimeout(this.timeout);
    }, 250);
  };

  private getHTML = (props: TransportHubsTypes.FeatureProperties) => {
    const { admarea, availabletransfer, carcapacity, district, nearstation, status, tpuname, yearofcomissioning } =
      props;
    return `
      <div class="transport-hubs-popup__content">
        <div class="transport-hubs-popup__content__header">
          <img class="transport-hubs-popup__content__img" src="/img/transport-hubs.svg" />
          <span class="transport-hubs-popup__content__title">
            ${tpuname}
          </span>
        </div>
        <div>
          <span class="transport-hubs-popup__content__title">
            Округ:
          </span>
          <span class="transport-hubs-popup__content__value">
            ${admarea}
          </span>
        </div>
        <div>
          <span class="transport-hubs-popup__content__title">
            Район:
          </span><span class="transport-hubs-popup__content__value">
            ${district}
          </span>
        </div>
        <div>
          <span class="transport-hubs-popup__content__title">
            Тип пересадкки:
          </span>
          <span class="transport-hubs-popup__content__value">
            ${availabletransfer}
          </span>
        </div>
        <div>
          <span class="transport-hubs-popup__content__title">
            Ближайшая станция:
          </span><span class="transport-hubs-popup__content__value">
            ${nearstation}
          </span>
        </div>
        <div>
          <span class="transport-hubs-popup__content__title">
            Статус:
          </span><span class="transport-hubs-popup__content__value">
            ${status} ${yearofcomissioning}
          </span>
        </div>
        ${
          typeof carcapacity === "number"
            ? `
          <div>
            <span class="transport-hubs-popup__content__title">
              Вместимость:
            </span>
            <span class="transport-hubs-popup__content__value">
              ${carcapacity}
            </span>
          </div>
        `
            : ""
        }
      </div>
    `;
  };
}
