import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterUpdateValueAC } from "old-store/view/action-creators";
import { SingleButton, IconSVGName } from "components";
import { GState } from "documentations";

import "./index.scss";

const items = [
  {
    id: "city",
    name: "Весь город",
    icon: IconSVGName.city,
  },
  {
    id: "trans",
    name: "НГПТ",
    icon: IconSVGName.bus,
  },
  {
    id: "highway",
    name: "Магистрали",
    icon: IconSVGName.magistral,
  },
  {
    id: "dedicated",
    name: "Выделенные полосы",
    icon: IconSVGName.dline,
  },
];

const baseClass = "filter-roads";

export const FilterRoads = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const filter = useSelector((state: GState) => state.view.filter);
  const onMouseEnter = () => setIsOpen(true);
  const onMouseLeave = () => setIsOpen(false);
  const width = isOpen ? "180px" : "60px";

  return (
    <div style={{ width }} className={baseClass} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {items.map(({ id, icon, name }) => {
        return (
          <div key={id} className={`${baseClass}__item`}>
            {
              /** Чтобы анимация хорошо выглядела, нужно вставлять пустой div вместо кнопки */
              isOpen || id === filter ? (
                <SingleButton
                  iconSvg={icon}
                  isActive={filter === id}
                  onClick={() => dispatch(filterUpdateValueAC(id))}
                  title={name}
                  type="square"
                  buttonGroupItem
                />
              ) : (
                <div />
              )
            }
          </div>
        );
      })}
    </div>
  );
};
