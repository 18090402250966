import React, { memo } from "react";
import { ReactComponent as CompareIcon } from "assets/icons/favorite.svg";
import { AnalysisData } from "features/sector-analysis/types";
import "./sector-analysis-compare.scss";

interface Props {
  current: AnalysisData;
  compare: AnalysisData;
}

const calcDifferencePercentage = (current: number | undefined, compare: number | undefined) => {
  if (typeof current !== "number" || typeof compare !== "number") return "Нет данных";
  if (current === compare) return "без изменений";
  const val = 100 - Math.floor((compare / current) * 100);
  return `${val > 0 ? "+" : ""}${val}%`;
};

export const Compare = memo((props: Props) => {
  const speed = calcDifferencePercentage(props.compare.total?.speed, props.current.total?.speed);
  const time = calcDifferencePercentage(props.compare.total?.time, props.current.total?.time);

  return (
    <div className="sector-analysis-card sector-analysis-compare">
      <div className="sector-analysis-card-header">
        <CompareIcon />
        <span className="sector-analysis-card-title">Сравнение средних</span>
      </div>
      <div className="sector-analysis-card-row">
        <span className="sector-analysis-card-name">Средняя скорость</span>
        <span className="sector-analysis-card-value">{speed}</span>
      </div>
      <div className="sector-analysis-card-row">
        <span className="sector-analysis-card-name">Средняя время проезда</span>
        <span className="sector-analysis-card-value">{time}</span>
      </div>
    </div>
  );
});
