import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphSlice } from "features/ctrl-graph/store/slice";
import { GState } from "documentations";

export const useData = () => {
  const { isActive } = useSelector((state: GState) => ({ isActive: state.graph.isCargoActive }));
  const dispatch = useDispatch();
  const handleClick = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      dispatch(graphSlice.actions.setIsCargoActive(!isActive));
    },
    [isActive, dispatch]
  );

  return { isActive, handleClick };
};
