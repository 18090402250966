import React from "react";
import cn from "classnames";
import { SectorAnalysisContent } from "../selector-analysis-content";
import { SectorAnalysisHeader } from "../sector-analysis-header";
import { Compare } from "../sector-analysis-compare";
import { SectorAnalyseContext, valueSectorAnalysis } from "./context";
import * as hooks from "./hooks";

import "./index.scss";

export const baseClass = "sector-analysis";

export const SectorAnalysis = () => {
  const {
    isActive,
    current,
    isShowEvents,
    isShowCompare,
    compare,
    dateKeys,
    graphRange,
    period,
    routeVariants,
    routeIsLoading,
    isDisabled,
  } = hooks.useStoreProps();
  const callbacks = hooks.useCallbacks();
  hooks.useObserverSubs();

  if (isDisabled || !isActive) return null;

  const baseClassName = cn(baseClass, {
    [`${baseClass}-mode-compare`]: isShowCompare,
  });

  return (
    <div className={baseClassName}>
      <SectorAnalyseContext.Provider value={valueSectorAnalysis}>
        <SectorAnalysisHeader />
        <div className={`${baseClass}-main`}>
          <SectorAnalysisContent
            {...current}
            period={period}
            dateKeys={dateKeys}
            compareWith={compare}
            graphRange={graphRange}
            isShowEvents={isShowEvents}
            routeVariants={routeVariants}
            isShowCompare={isShowCompare}
            routeIsLoading={routeIsLoading}
            changeEs={callbacks.changeCurrentEs}
            changeDate={callbacks.changeCurrentDate}
            changeDays={callbacks.changeCurrentDays}
            changeIsShowEvent={callbacks.changeIsShowEvent}
            changeIsShowCompare={callbacks.changeIsShowCompare}
          />
          {isShowCompare && (
            <>
              <SectorAnalysisContent
                {...compare}
                period={period}
                dateKeys={dateKeys}
                compareWith={current}
                graphRange={graphRange}
                isShowCompare={isShowCompare}
                routeVariants={routeVariants}
                routeIsLoading={routeIsLoading}
                currentIsLoading={current.isLoading}
                changeEs={callbacks.changeCompareEs}
                changeDays={callbacks.changeCompareDays}
                changeDate={callbacks.changeCompareDate}
              />
              <Compare current={current} compare={compare} />
            </>
          )}
        </div>
      </SectorAnalyseContext.Provider>
    </div>
  );
};
