import React from "react";
import { AlignItem } from "./tooltip-value.types";
import type { Props } from "./tooltip-value.types";
import "./tooltip-value.scss";

const getTranslateX = (shiftInPx: number, alignItem: AlignItem) => {
  switch (alignItem) {
    case AlignItem.Min:
      return `translateX(calc(-100% + ${shiftInPx}px))`;
    case AlignItem.Max:
      return `translateX(${shiftInPx + 20}px)`;
    default:
      return "unset";
  }
};

const getStyles = (shiftInPx: number, alignItem: AlignItem): React.CSSProperties => {
  return {
    position: "absolute",
    top: "-44px",
    left: 0,
    transform: getTranslateX(shiftInPx, alignItem),
    width: "50px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: "4px",
    borderRadius: "4px",
  };
};

export const TooltipValue = (props: Props) => {
  const { alignItem, caption, shiftInPx } = props;
  const styles = React.useMemo(() => getStyles(shiftInPx, alignItem), [shiftInPx]);
  return (
    <div className="tooltip-value" data-align={alignItem} style={styles}>
      {caption}
    </div>
  );
};
