import { safeFetchJson } from "../utils";
import { APP_ENV } from "app-env";

const transportHubsUrl = `${APP_ENV.REACT_APP_DTM_TRAFFIC_API}geojson/tpu`;

export class TransportHubsAPI {
  public readonly getTransportHubsList = () => {
    return safeFetchJson(transportHubsUrl);
  };
}
