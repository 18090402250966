import React, { CSSProperties } from "react";
import { utils } from "../../utils";

type SliderLabelProps = {
  title: string;
  offsetX: number;
  labelStyle?: CSSProperties;
};

export const SliderLabel = ({ title, offsetX, labelStyle }: SliderLabelProps) => {
  const width = utils.getTextWidth(title, "bold bold 14px Roboto");

  return (
    <div
      style={{ left: `calc(${offsetX}% - ${width / 2 + 5}px)` }}
      className="sector-analysis-appearance-slider__label">
      <label style={labelStyle}>{title}</label>
    </div>
  );
};
