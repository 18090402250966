import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { rulerUpdateIsActive } from "old-store/global/actions";
import { GState } from "documentations";
import { SingleButton, BUTTON_FORM } from "components";
import MeasureDistance from "./measure-distance/measureDistance";

import "./ctrl-ruler.scss";

let map: mapboxgl.Map;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

const className = "ctrl-ruler";

export interface State {}

const mapStateToProps = (state: GState) => ({
  isActive: state.global.ruler,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateIsActive: (isActive: boolean) => dispatch(rulerUpdateIsActive(isActive)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class CtrlRuler extends Component<PropsFromRedux, State> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    const { isActive, updateIsActive } = this.props;
    updateIsActive(!isActive);
  };

  render() {
    const { isActive } = this.props;
    return (
      <>
        <div className={className}>
          <SingleButton
            isActive={isActive}
            title="Линейка"
            onClick={this.handleClick}
            type={BUTTON_FORM.round}
            iconName="dtm-ruler-icon"
            buttonGroupItem
          />
        </div>
        {isActive && <MeasureDistance map={map} />}
      </>
    );
  }
}

export default connector(CtrlRuler);
