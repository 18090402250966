import styled from "styled-components";

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const LastFixationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AllowedSpeed = styled.div`
  display: flex;
  align-items: center;
  color: rgba(3, 40, 76, 0.66);
  font-size: 12px;
  .speed {
    color: #080808;
    padding: 0 5px;
  }
`;
