import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationGroupeLayers } from "components";
import { GState } from "documentations";
import { floodingSlice } from "features/ctrl-flooding/store/slice";
import * as consts from "./flooding-button.consts";

export const FloodingButton = () => {
  const dispatch = useDispatch();

  const { isActive } = useSelector((state: GState) => ({
    isActive: state.flooding.isActive,
  }));

  const handleClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      dispatch(floodingSlice.actions.setIsActive(!isActive));
    },
    [dispatch, isActive]
  );

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};
