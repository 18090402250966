import React, { memo } from "react";
import { Checkbox } from "components/checkbox";

import "./route-traffic-ctrl.scss";

type Props = {
  checked: boolean;
  isDisabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RouteTrafficCtrl: React.FC<Props> = memo((props) => {
  const { checked, isDisabled, onChange } = props;

  return (
    <div className="route-traffic-ctrl">
      <Checkbox
        label="Маршрут с учетом изменений пробок"
        checked={checked}
        onChange={onChange}
        value="traffic"
        disabled={isDisabled}
      />
    </div>
  );
});
