import React, { Component } from "react";
import PropTypes from "prop-types";
import TrafficStatus from "./traffic-status";
import TrafficScore from "./traffic-score";

class TrafficButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      apply,
      score,
      titleScore,
      onChange,
      toggleExperiment,
      dynamicsID,
      dynamicsMessage,
      averageSpeed,
      prevAverageSpeed,
    } = this.props;
    return (
      <>
        <TrafficScore
          apply={apply}
          onChange={onChange}
          toggleExperiment={toggleExperiment}
          titleScore={titleScore}
          score={score}
        />
        <TrafficStatus
          dynamicsID={dynamicsID}
          dynamicsMessage={dynamicsMessage}
          averageSpeed={averageSpeed}
          prevAverageSpeed={prevAverageSpeed}
        />
      </>
    );
  }
}

TrafficButton.defaultProps = {
  titleScore: "",
  dynamicsID: 7,
  dynamicsMessage: "",
  averageSpeed: 0,
  prevAverageSpeed: 0,
};

TrafficButton.propTypes = {
  apply: PropTypes.bool.isRequired,
  score: PropTypes.number,
  titleScore: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  toggleExperiment: PropTypes.func,
  dynamicsMessage: PropTypes.string,
  dynamicsID: PropTypes.number,
  averageSpeed: PropTypes.number,
  prevAverageSpeed: PropTypes.number,
};

export default TrafficButton;
