import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Cookie from "js-cookie";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "../../../../../../store";
import { cookieOptions } from "../shared";

export const useValues = () => {
  return useSelector(
    (state: GState) => ({
      isShowTrafficScore: state.sectorAnalysis.isShowTrafficScore,
      isShowGlobalTrafficScore: state.sectorAnalysis.isShowGlobalTrafficScore,
    }),
    shallowEqual
  );
};

export const useCallbacks = () => {
  const dispatch = useDispatch();

  const handleIsShowTrafficScoreChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      Cookie.set("isShowTrafficScore", String(value), cookieOptions);
      dispatch(sectorAnalysisSlice.actions.setIsShowTrafficScore(value));
    },
    [dispatch]
  );

  const handleIsShowGlobalTrafficScoreChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      Cookie.set("isShowGlobalTrafficScore", String(value), cookieOptions);
      dispatch(sectorAnalysisSlice.actions.setIsShowGlobalTrafficScore(value));
    },
    [dispatch]
  );

  return {
    handleIsShowTrafficScoreChange,
    handleIsShowGlobalTrafficScoreChange,
  };
};
