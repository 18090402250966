export enum AlignItem {
  Min = "min",
  Max = "max",
}

export interface Props {
  // позиция окна в отношение значения
  alignItem: AlignItem;
  // Значение в позиций
  caption: string | number;
  // Сдвиг
  shiftInPx: number;
}
