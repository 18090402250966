import React from "react";
import { RadioGroup } from "components";
import { Heading } from "../heading";
import * as hooks from "./map-styles.hooks";
import * as consts from "./map-styles.consts";
import "./map-styles.scss";

export const MapStyles = React.memo(() => {
  const logicData = hooks.useGroupSwitchLogic();

  if (!logicData.options.length) return null;

  return (
    <div className={consts.baseClass}>
      <Heading>Стиль карты</Heading>
      <RadioGroup value={logicData.value} options={logicData.options} onChange={logicData.handleChange} />
    </div>
  );
});
