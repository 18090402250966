import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { APP_ENV } from "app-env";
import { RouterAPI } from "api/router";
import { GState } from "documentations";
import { RouteResponse } from "features/ctrl-route/types";
import moment from "moment";

export type RouteInfo = { routeType: string; routeNum?: string; routeColor?: string };

export const getArrivalTime = (time: number) => {
  return moment().add(Math.round(time), "seconds").format("HH:mm");
};
export const getTotalPedestrianTime = (variant: RouteResponse) => {
  const seconds = variant.original.trip.legs?.[0].maneuvers.reduce((acc, maneuver) => {
    if (maneuver.travel_type !== "foot") return acc;
    return acc + maneuver.time;
  }, 0);
  return Math.round(seconds / 60);
};

const toJson = (res: Response) => res.json();
const except = () => {
  console.info("bus routes fetch");
};

const fetchRouteInfo = (accessToken: string) => (route: { routeId: number; routeType: string }) => {
  const routeId = route.routeId;
  if (route.routeType === "metro") {
    return RouterAPI.router.getRouteInfoById(routeId);
  }

  return fetch(`${APP_ENV.REACT_APP_DTM_KPP_API}route/${routeId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(toJson)
    .catch(except);
};

const useAccessToken = () => useSelector((store: GState) => store.oidc.user.access_token);

export const useRoutes = (variant: RouteResponse) => {
  const accessToken = useAccessToken();
  const [routes, setRoutes] = useState<RouteInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getInfo = useMemo(() => fetchRouteInfo(accessToken), [accessToken]);

  const busRoutes = useMemo(() => {
    return variant.original.trip.legs?.[0].maneuvers
      .reduce<{ routeId: number; routeType: string }[]>((acc, maneuver) => {
        const isValid =
          (maneuver.travel_type === "bus" || maneuver.travel_type === "metro") && maneuver.transit_info.route_id;
        if (!isValid) return acc;
        const routeId = maneuver.transit_info.route_id;
        const isInCollection = acc.some((item) => item.routeId === routeId);
        if (isInCollection) return acc;
        return [...acc, { routeId, routeType: maneuver.travel_type }];
      }, [])
      .map(getInfo);
  }, [variant]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all(busRoutes).then((responses) => {
      const parsed = responses.map((response) => {
        if (response?.route === "subway") {
          return { routeType: "metro", routeNum: "", routeColor: response?.colour ?? "#000000" };
        }

        return {
          routeType: "bus",
          routeNum: String(response?.num ?? "-"),
        };
      });
      setRoutes(parsed);
      setIsLoading(false);
    });
  }, [busRoutes]);

  return { routes, isLoading };
};

type BodyRoute = {
  routeId: number;
  beginStopId: number;
  endStopId: number;
};

type Body = {
  trafficTime: string;
  trafficTypes: {
    externalSystemIDs: number[];
  };
  routes: BodyRoute[];
};

type TrafficScoreResponse = {
  score: number;
};

const trafficScoreUrl = `${APP_ENV.REACT_APP_DTM_ROUTER_API}router/get-bus-score`;
const fetchRouteTrafficScore = (accessToken: string) => (body: Body) => {
  return fetch(trafficScoreUrl, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
  }).then(toJson);
};

const useSelectedDay = () => useSelector((store: GState) => store.view.selectedDay);
const useExternalSystemsIds = () => useSelector((store: GState) => store.view.es);

const getTrafficIndex = (trafficScore: number) => {
  if (trafficScore >= 0 && trafficScore <= 3) return 0.1;
  if (trafficScore >= 4 && trafficScore < 6) return 0.3;
  if (trafficScore >= 6 && trafficScore <= 9) return 0.5;
  if (trafficScore > 9) return 0.16;

  return 0;
};

export const useScores = (variant: RouteResponse) => {
  const accessToken = useAccessToken();
  const selectedDay = useSelectedDay();
  const externalSystemsIds = useExternalSystemsIds();
  const [isLoading, setIsLoading] = useState(false);
  const [score, setScore] = useState<number | null>(null);
  const [trafficIndex, setTrafficIndex] = useState<number | null>(null);
  const getTrafficScore = useMemo(() => fetchRouteTrafficScore(accessToken), [accessToken]);

  const routeInfo = useMemo((): Body => {
    const routes = variant.original.trip.legs?.[0].maneuvers.reduce<Body["routes"]>((acc, maneuver) => {
      if (maneuver.travel_type !== "bus") return acc;

      const routeId = maneuver.transit_info.route_id;
      const stops = [...maneuver.transit_info.transit_stops];
      const beginStopId = stops.shift()?.stop_id;
      const endStopId = stops.pop()?.stop_id;

      if (typeof routeId !== "number" || typeof beginStopId !== "number" || typeof endStopId !== "number") return acc;

      return [
        ...acc,
        {
          routeId,
          beginStopId,
          endStopId,
        },
      ];
    }, []);

    return {
      trafficTime: selectedDay,
      trafficTypes: {
        externalSystemIDs: externalSystemsIds,
      },
      routes,
    };
  }, [variant, selectedDay, externalSystemsIds]);

  useEffect(() => {
    setIsLoading(true);
    getTrafficScore(routeInfo)
      .then((response: TrafficScoreResponse) => {
        if (!response) return setScore(null);
        setIsLoading(false);

        const score = Math.round(response.score);
        setScore(score);
        setTrafficIndex(getTrafficIndex(score));
      })
      .catch(() => {
        setIsLoading(false);
        setScore(null);
      });
  }, [routeInfo]);

  return { score, trafficIndex, isLoading };
};
