import React from "react";
import styled from "styled-components";
import { APP_ENV } from "app-env";

const cdn = APP_ENV.REACT_APP_DTM_MEDIA.split("integration")[0];

export const CtrlRouteContainer = styled(React.Fragment)`
  .ctrl-route {
    &-drag {
      &-handle {
        font-size: 20px;
        position: absolute;
      }
    }

    &-header {
      display: block;
      position: relative;
      height: 40px;
    }

    & &-title {
      line-height: 40px;
      color: #000;
      font-size: 14px;
      opacity: 0.54;
      padding: 0 0 0 16px;
      margin: 0;
    }

    &-container {
      position: relative;
      max-height: 25vh;

      &-scroll {
        // overflow: hidden;
        // position: relative;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      //padding: 0px 16px 15px 16px;
    }

    &-autocomplete {
      border-radius: 3px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      overflow: hidden;
      position: fixed;
      width: 240px;
    }

    &-variants {
      &-back {
        padding: 5px 15px;

        &-button {
          display: block;
          width: 100%;
          background-color: #fff;
          border: none;
          text-align: right;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }

        &-text {
          opacity: 0.54;
          font-size: 15px;
        }
      }

      &-error {
        padding: 16px;
        color: red;
      }

      &-container {
        &.loading-container {
          //padding-top: 10px;
          position: relative;
          min-height: 56px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);

          .loading {
            position: absolute;
            width: 100%;
            height: 100%;
            background: url(${cdn + "image/loader.gif"});
            background-position: 50% 15px;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-color: rgba(0, 0, 0, 0.12);

            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    &-time,
    &-distance {
      color: #000;
      opacity: 0.87;
      line-height: 18px;
      display: inline-block;
      vertical-align: baseline;
    }

    &-distance {
      font-size: 14px;
      font-weight: 600;
      margin-right: 13px;
    }

    &-time {
      font-size: 12px;
    }

    &-trafictime {
      opacity: 0.54;
      color: #000;
      line-height: 18px;
      font-size: 12px;
    }

    &-select {
      display: flex;
      width: 324px;
      border: none;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.02);
      }

      &-active {
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px solid black;
      }

      &-icon {
        display: block;
        float: left;
        width: 56px;
        height: 48px;
        line-height: 48px;

        > img {
          display: block;
          width: 18px;
          height: 20px;
          margin: 12px auto 0;
        }

        > i {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &-name {
        display: block;
        overflow: hidden;
        padding: 6px 0;
        text-align: left;
        padding-left: 5px;

        &-type {
          font-size: 14px;
          opacity: 0.87;
          display: block;
          padding-right: 12px;
        }

        &-attr {
          font-size: 12px;
          opacity: 0.54;
          clear: both;
        }
      }
    }
  }

  .path1,
  .path2,
  .path3,
  .path4,
  .path5,
  .path6,
  .path7,
  .path8,
  .path9 {
    font-family: "dtm" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 18px;
  }

  .ps__rail-y {
    display: block;
    opacity: 0.9;
    width: 11px;
  }

  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    opacity: 0.9;
  }

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    height: 6px;
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 6px;
  }

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
  }
`;
