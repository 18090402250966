import React from "react";
import { constants } from "../store.constants";
import { Attribute } from "../store.types";
import { Action } from "../store.types";

export const useInitializeState = (dispatch: React.Dispatch<Action>) =>
  React.useCallback(
    (payload: Attribute[]) =>
      dispatch({
        type: constants.INITIALIZE,
        payload,
      }),
    [dispatch]
  );
