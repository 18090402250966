import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";
import getBeforeId, { SPEEDCAM_ACTIVE_LAYER_ID } from "map-helpers/order-layers";

const getColor = (isHover: boolean): mapboxgl.Expression => [
  "case",
  ["boolean", ["==", isHover, true], true],
  "cyan",
  "blue",
];

const getOpacity = (isHover: boolean): mapboxgl.Expression => [
  "case",
  ["boolean", ["==", isHover, true], true],
  0.7,
  0.5,
];

export class SpeedcamBorder extends BaseLayer.Abstract<GeoJSON.Point, GeoJSON.GeoJsonProperties> {
  private isHover = false;
  private layerConfig: mapboxgl.CircleLayer = {
    id: SPEEDCAM_ACTIVE_LAYER_ID,
    type: "circle",
    source: SPEEDCAM_ACTIVE_LAYER_ID,
    paint: {
      "circle-stroke-color": getColor(this.isHover),
      "circle-stroke-opacity": getOpacity(this.isHover),
      "circle-stroke-width": this.getDefaultCircleStroke(),
      "circle-radius": this.getDefaultCircleRadius(),
      "circle-opacity": 0,
      "circle-pitch-alignment": "map",
    },
  };

  constructor(map: mapboxgl.Map) {
    super(map, {
      id: SPEEDCAM_ACTIVE_LAYER_ID,
      beforeId: getBeforeId(SPEEDCAM_ACTIVE_LAYER_ID, map),
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }

  public setIsHover = (isHover: boolean) => {
    this.isHover = isHover;

    if (!this.map.getLayer(this.id)) return;

    this.map.setPaintProperty(this.id, "circle-stroke-color", getColor(this.isHover));
    this.map.setPaintProperty(this.id, "circle-stroke-opacity", getOpacity(this.isHover));
  };
}
