import axios, { Canceler } from "axios";
import { LngLat } from "mapbox-gl";
import { APP_ENV } from "app-env";
import { lowerCase, trim } from "lodash";

const getRegions = (city: string) => {
  if (city === "москва") return "москва, московская область";
  if (city === "тверь") return "тверь, тверская область";
  if (city === "севастополь") return "севастополь";
  if (city === "владивосток") return "владивосток, приморский край";
  return city;
};

const geocodeApi = axios.create({
  baseURL: APP_ENV.REACT_APP_IS_NOMINATIM
    ? APP_ENV.REACT_APP_NOMINATIM_GEOCODER_API
    : APP_ENV.REACT_APP_DTM_MSC_GEOCODER_API,
});

const { CancelToken } = axios;

export type AddressResponse = {
  id: number;
  globalId: number;
  name: string;
  subCity: string;
  fullAddress: string;
  address: string;
  houseNumber: string;
  street: string;
  city: string;
  lat: number;
  lng: number;
  adminArea: number;
  adminAreaString: string;
  adminAreaShortString: string;
  district: number;
  districtString: string;
  entityString: string;
};

export type AddressAutoComplete = {
  address: string;
  coor: LngLat;
};

let addressCancel: Array<Canceler> = [];

let autocompleteCancel: Canceler | null = null;

export const geocode = {
  cancelAddress: () => {
    if (addressCancel) addressCancel.forEach((el) => el());
    addressCancel = [];
  },
  address: async (coor: Partial<LngLat>): Promise<AddressResponse | null> => {
    if (APP_ENV.REACT_APP_IS_NOMINATIM) {
      const path = `reverse?lat=${coor.lat}&lon=${coor.lng}&format=json&accept-language=ru`;

      const { data } = await geocodeApi.get(path, {
        cancelToken: new CancelToken((c) => {
          addressCancel.push(c);
        }),
      });

      const { display_name } = data;

      return display_name.split(",").slice(0, 2).reverse().join(", ");
    } else {
      const path = `near?lat=${coor.lat}&lon=${coor.lng}&limit=1&includeGeom=false`;

      const { data } = await geocodeApi.get(path, {
        cancelToken: new CancelToken((c) => {
          addressCancel.push(c);
        }),
      });

      if (data && data.length > 0) return data[0].address;

      return null;
    }
  },
  cancelAutoComplete: () => {
    if (autocompleteCancel) autocompleteCancel();
  },
  autoComplete: async (address: string) => {
    if (APP_ENV.REACT_APP_IS_NOMINATIM) {
      const path = `?q=${APP_ENV.REACT_APP_DTM_REGION?.toLowerCase()}+${address}&format=json&limit=100&addressdetails=1&accept-language=ru`;

      geocode.cancelAutoComplete();

      const { data } = await geocodeApi.get(path, {
        cancelToken: new CancelToken((c) => {
          autocompleteCancel = c;
        }),
      });

      const filterData = data.filter(
        (item: any) =>
          item.osm_type === "way" &&
          getRegions(APP_ENV.REACT_APP_DTM_REGION?.toLowerCase())
            .split(",")
            .map(trim)
            .map(lowerCase)
            .includes(lowerCase(item.address.state))
      );

      const result: Array<AddressAutoComplete> = [];

      if (filterData && filterData.length > 0) {
        filterData.forEach((item: any) => {
          const coor = new LngLat(item.lon, item.lat);

          const { display_name } = item;
          let address = display_name.split(",").slice(0, 3).join(", ");

          result.push({
            address,
            coor,
          });
        });
      }

      return result;
    } else {
      const path = `address/?address=${address}&onlystart=true`;

      geocode.cancelAutoComplete();

      const { data } = await geocodeApi.get(path, {
        cancelToken: new CancelToken((c) => {
          autocompleteCancel = c;
        }),
      });

      const result: Array<AddressAutoComplete> = [];

      if (data && data.length > 0)
        data.forEach((item: any) => {
          const coor = new LngLat(item.lng, item.lat);

          result.push({
            address: item.address,
            coor,
          });
        });

      return result;
    }
  },
};
