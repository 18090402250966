import React, { memo, useMemo } from "react";
import cn from "classnames";
import { ReactComponent as SvgIcon } from "assets/icons/close-icon.svg";

import "./close-button.scss";

interface Props {
  /** Click listener */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** Миксуемое имя css класса */
  className?: string;
}

export const CloseButton: React.FC<Props> = memo(({ onClick, className = "" }) => {
  const baseClass = useMemo(
    () =>
      cn("close-button", {
        [className]: className,
      }),
    [className]
  );

  return (
    <button onClick={onClick} type="button" className={baseClass} data-testid="close-button">
      <SvgIcon />
    </button>
  );
});
