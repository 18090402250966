import { APP_ENV } from "app-env";
import axios from "axios";
import { GraphStatisticsApiResponseType } from "./graph-statistics.types";

const graphStatisticsApi = axios.create({
  baseURL: APP_ENV.REACT_APP_DTM_ROUTER_API,
});

export const getGraphStatistics = async (
  date: string,
  isPedestrian?: boolean
): Promise<GraphStatisticsApiResponseType> => {
  const path = `graph/statistics?date=${date}${isPedestrian ? "&pedestrian=true" : ""}`;

  const { data } = await graphStatisticsApi.get(path);

  return data;
};
