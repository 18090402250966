import { ZoneStatisticsTypes } from "types";
import { State } from "./zone-statistics.types";
import moment from "moment";

const initialDate = moment();
export const initialStateFactory = (): State => ({
  es: [],
  zone: null,
  isActive: false,
  isDisabled: true,
  isLoading: false,
  statistics: null,
  dateRange: {
    from: initialDate.toISOString(),
    to: initialDate.toISOString(),
  },
  timeRange: {
    min: 0,
    max: 24,
  },
});

export const getDatePart = (date: string) => date.split("T")?.[0] ?? null;

export const getStatisticsRequestBody = (state: State): ZoneStatisticsTypes.GetStatisticsBody | undefined => {
  if (!state.zone) return;

  const fromDate = getDatePart(state.dateRange.from);
  const toDate = getDatePart(state.dateRange.to);

  if (!fromDate || !toDate) return;

  const timeFrom = `${state.timeRange.min}:00`;
  const timeTo = `${state.timeRange.max - 1}:59`;

  return {
    fromDate,
    toDate,
    timeRanges: [
      {
        from: timeFrom,
        to: timeTo,
      },
    ],
    externalSystemIDs: state.es,
    geoJson: {
      type: "FeatureCollection" as const,
      features: [
        {
          type: "Feature" as const,
          properties: {},
          geometry: {
            type: "Polygon" as const,
            coordinates: [[...Object.values(state.zone.points).map((point) => point.coordinates)]],
          },
        },
      ],
    },
  };
};
