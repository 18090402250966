import {
  GRAPH_UPDATE_IS_ACTIVE,
  HEATMAP_UPDATE_IS_ACTIVE,
  TIME_UPDATE_IS_ACTIVE,
  FILTER_UPDATE_VALUE,
  LOADED_TRANSPORT_TYPE_DICTIONARY,
  ES_UPDATE_SELECTED,
  BLOCKING_UPDATE_VIEW,
  CHANGE_SELECTED_DAY,
  CHANGE_CORRELATION_DAY,
  CHANGE_IS_CUSTOM_CORRELATION,
  CHANGE_IS_PEDESTRIAN_GRAPH,
} from "./action-types";

export function graphUpdateIsActiveAC(payload) {
  return {
    type: GRAPH_UPDATE_IS_ACTIVE,
    payload,
  };
}

export function pedestrianGraphIsActiveAC(payload) {
  return {
    type: CHANGE_IS_PEDESTRIAN_GRAPH,
    payload,
  };
}

export function heatmapUpdateIsActiveAC(payload) {
  return {
    type: HEATMAP_UPDATE_IS_ACTIVE,
    payload,
  };
}

export function timeUpdateIsActiveAC(payload) {
  return {
    type: TIME_UPDATE_IS_ACTIVE,
    payload,
  };
}

export function filterUpdateValueAC(payload) {
  return {
    type: FILTER_UPDATE_VALUE,
    payload,
  };
}

export function loadedTransportTypeDictionary(payload) {
  return {
    type: LOADED_TRANSPORT_TYPE_DICTIONARY,
    payload,
  };
}

export function esUpdateSelectedAC(payload) {
  return {
    type: ES_UPDATE_SELECTED,
    payload,
  };
}

export function blockingUpdateViewAC(payload) {
  return {
    type: BLOCKING_UPDATE_VIEW,
    payload,
  };
}

export function changeSelectedDayAC(payload) {
  return {
    type: CHANGE_SELECTED_DAY,
    payload,
  };
}

export function changeCorrelationDayAC(payload) {
  return {
    type: CHANGE_CORRELATION_DAY,
    payload,
  };
}

export function changeCorrelationIsCustomAC(payload) {
  return {
    type: CHANGE_IS_CUSTOM_CORRELATION,
    payload,
  };
}
