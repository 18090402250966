import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import moment from "moment";
import { changeSelectedDayAC } from "old-store/view/action-creators";
import "./route-time-picker.scss";

const className = "route-time-picker";

function getOptions() {
  const options = [];
  for (let i = 0; i < 24; i++) {
    const labelValue = i > 9 ? `${i}:00` : `0${i}:00`;
    options.push({
      value: i,
      label: labelValue,
    });
  }
  return options;
}

function round(date, duration, method) {
  return moment(Math[method](Number(date) / Number(duration)) * Number(duration));
}

class RouteTimePicker extends Component {
  constructor(props) {
    super(props);
    const options = getOptions();
    this.state = {
      options,
      customStyles: {
        control: (provided) => ({
          ...provided,
          borderColor: "#e2e2e2",
          minHeight: 0,
          height: "100%",
        }),
        container: (provided) => ({
          ...provided,
          border: "none",
          width: "100px",
          height: "100%",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: "5px",
          display: "none",
        }),
        option: (provided) => ({
          ...provided,
          padding: 7,
          color: "#747474",
          cursor: "pointer",
          fontSize: "12px",
        }),
        singleValue: () => ({
          color: "#747474",
          fontSize: "12px",
        }),
      },
    };
  }

  componentDidMount() {
    const { changeSelectedDay, selectedDay } = this.props;
    const newDate = moment(selectedDay).toISOString(true);
    changeSelectedDay(newDate);
  }

  handleChange = (selectedOption) => {
    const { changeSelectedDay, selectedDay } = this.props;
    const { value } = selectedOption;
    const newDate = moment(selectedDay).startOf("day").add(value, "hour").toISOString(true);
    changeSelectedDay(newDate);
  };

  handleClick = (key) => {
    const { changeSelectedDay, selectedDay } = this.props;
    let newDate;
    if (key === "add") {
      newDate = moment(selectedDay).add(30, "minutes").toISOString(true);
    }
    if (key === "subtract") {
      newDate = moment(selectedDay).subtract(30, "minutes").toISOString(true);
    }
    changeSelectedDay(newDate);
  };

  getSelectedOption = () => {
    const { selectedDay } = this.props;
    const date = moment(selectedDay);
    const roundedDate = round(date, moment.duration(5, "minutes"), "ceil");
    const newDate = roundedDate.format("HH:mm");
    return { value: "now", label: newDate };
  };

  render() {
    const { options, customStyles } = this.state;
    const selectedOption = this.getSelectedOption();
    return (
      <div className={className}>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          options={options}
          isSearchable={false}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: "4px",
            colors: {
              ...theme.colors,
              primary25: "#e2e2e2",
              primary: "#e2e2e2",
            },
          })}
        />
        <div className={`${className}-buttons`}>
          <button
            type="button"
            className={`${className}-button-left ${className}-button`}
            onClick={() => this.handleClick("subtract")}>
            <i className="dtm-arrow-down-icon" />
          </button>
          <button
            type="button"
            className={`${className}-button-right ${className}-button`}
            onClick={() => this.handleClick("add")}>
            <i className="dtm-arrow-down-icon" />
          </button>
        </div>
      </div>
    );
  }
}

RouteTimePicker.propTypes = {
  selectedDay: PropTypes.string.isRequired,
  changeSelectedDay: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedDay: state.view.selectedDay,
  }),
  (dispatch) => ({
    changeSelectedDay: (data) => dispatch(changeSelectedDayAC(data)),
  })
)(RouteTimePicker);
