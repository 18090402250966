import { createAction } from "@reduxjs/toolkit";

interface PointPayload {
  location: mapboxgl.LngLat;
  key: string;
}

export const mapClick = createAction<mapboxgl.MapboxGeoJSONFeature[]>("ROUTER_MAP_CLICK");

export const mapClickNewPoint = createAction<mapboxgl.LngLat>("ROUTER_MAP_CLICK_NEW_POINT");

export const updatePoint = createAction<PointPayload>("ROUTER_MAP_UPDATE_POINT");

export const addIntermediatePoint = createAction<PointPayload>("ROUTER_MAP_ADD_INTERMEDIATE_POINT");

export const retryFetchRoute = createAction("retryFetchRoute");
