import React from "react";
import { useSelector } from "react-redux";
import { GState } from "documentations";
import { containerClassName } from "../..";
import RouteSelectTime from "./components/route-select-time/route-select-time";
import RouteTimePicker from "./components/route-time-picker/route-time-picker";
import RouteDatePicker from "./components/route-date-picker/route-date-picker";

const useTimeState = () => useSelector((state: GState) => state.router.timeStatus);

export const RouteDateTime = () => {
  const timeStatus = useTimeState();

  return (
    <>
      <RouteSelectTime />
      {timeStatus !== "last" && (
        <div className={`${containerClassName}-time`}>
          <RouteTimePicker />
          <RouteDatePicker />
        </div>
      )}
    </>
  );
};
