import { ReactComponent as Score0 } from "./0.svg";
import { ReactComponent as Score1 } from "./1.svg";
import { ReactComponent as Score2 } from "./2.svg";
import { ReactComponent as Score3 } from "./3.svg";
import { ReactComponent as Score4 } from "./4.svg";
import { ReactComponent as Score5 } from "./5.svg";
import { ReactComponent as Score6 } from "./6.svg";
import { ReactComponent as Score7 } from "./7.svg";
import { ReactComponent as Score8 } from "./8.svg";
import { ReactComponent as Score9 } from "./9.svg";
import { ReactComponent as Score10 } from "./10.svg";
import { ReactComponent as ScoreDefault } from "./default.svg";

export const trafficScoreIcons = {
  0: <Score0 />,
  1: <Score1 />,
  2: <Score2 />,
  3: <Score3 />,
  4: <Score4 />,
  5: <Score5 />,
  6: <Score6 />,
  7: <Score7 />,
  8: <Score8 />,
  9: <Score9 />,
  10: <Score10 />,
  default: <ScoreDefault />,
};
