export type OverSpeedMessageType = {
  message: string;
  isViolations: boolean;
};

export enum overSpeedEnum {
  less20 = -10,
  more20 = 20,
  more40 = 40,
  more60 = 60,
  more80 = 80,
}

export const getOverSpeedMessage = (overSpeed: number, speed: number) => {
  const diff = speed - overSpeed;

  const speedMsg = isNaN(speed) ? "" : speed !== 0 ? `Скорость: ${speed} км/ч, ` : "";
  if (diff >= overSpeedEnum.more80) {
    return {
      message: `${speedMsg}превышение > 80 км/ч`,
      isViolations: true,
    };
  }
  if (diff >= overSpeedEnum.more60) {
    return {
      message: `${speedMsg}превышение 60 – 80 км/ч`,
      isViolations: true,
    };
  }
  if (diff >= overSpeedEnum.more40) {
    return {
      message: `${speedMsg}превышение 40 – 60 км/ч`,
      isViolations: true,
    };
  }
  if (diff >= overSpeedEnum.more20) {
    return {
      message: `${speedMsg}превышение 20 – 40 км/ч`,
      isViolations: true,
    };
  }

  if (speedMsg) {
    return {
      message: `${speedMsg} Нарушений нет`,
      isViolations: false,
    };
  }

  return {
    message: ``,
    isViolations: false,
  };
};
