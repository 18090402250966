import { memo } from "react";
import { UserComponent } from "@megapolis/react-auth";

export const UserThumb = memo(() => {
  return (
    <div className="user-auth">
      <UserComponent size={40} />
    </div>
  );
});
