import { FullPosition, RnisFullPosition } from "api/telemetry/model/models";

export function getBindUnmatchedLineData(points: any, positions: FullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  points.forEach((point: any, index: any) => {
    const feature: any = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
      properties: { lineColor: "#ff2b2b" },
    };

    if (point.type === "unmatched") {
      const previousPoint = positions[index - 1];
      if (previousPoint) feature.geometry.coordinates.push([previousPoint.longitude, previousPoint.latitude]);

      const currentPoint = positions[index];
      feature.geometry.coordinates.push([currentPoint.longitude, currentPoint.latitude]);

      const nextPoint = positions[index + 1];
      if (nextPoint)
        if (points[index + 1]) {
          if (points[index + 1].type !== "unmatched")
            feature.geometry.coordinates.push([nextPoint.longitude, nextPoint.latitude]);
        }

      features.push(feature);
    }
  });

  return features;
}

export function getRnisBindUnmatchedLineData(points: any, positions: RnisFullPosition[]) {
  const features: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  points.forEach((point: any, index: any) => {
    const feature: any = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
      properties: { lineColor: "#ff2b2b" },
    };

    if (point.type === "unmatched") {
      const previousPoint = positions[index - 1];
      if (previousPoint) feature.geometry.coordinates.push([previousPoint.lng, previousPoint.lat]);

      const currentPoint = positions[index];
      feature.geometry.coordinates.push([currentPoint.lng, currentPoint.lat]);

      const nextPoint = positions[index + 1];
      if (nextPoint)
        if (points[index + 1]) {
          if (points[index + 1].type !== "unmatched") feature.geometry.coordinates.push([nextPoint.lng, nextPoint.lat]);
        }

      features.push(feature);
    }
  });

  return features;
}
