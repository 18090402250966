import { useStore } from "effector-react";
import { store } from "./zone-statistics";

const useZone = () => useStore(store).zone;
const useIsActive = () => useStore(store).isActive;
const useIsLoading = () => useStore(store).isLoading;
const useDateRange = () => useStore(store).dateRange;
const useTimeRange = () => useStore(store).timeRange;
const useStatistics = () => useStore(store).statistics;
const useIsDisabled = () => useStore(store).isDisabled;

export const hooks = {
  useZone,
  useIsActive,
  useIsLoading,
  useDateRange,
  useTimeRange,
  useStatistics,
  useIsDisabled,
};

export type Hooks = typeof hooks;
