import React, { useMemo } from "react";
import { useStoreContext } from "store";
import { CloseButton, CustomDatePicker, Loader } from "components";
import moment from "moment";
import { helpers } from "./helper";

import "./graph-compare-body.scss";

const useGetGraphVersionsTools = () => {
  const { store } = useStoreContext();

  const graphVersions = store.graphCompare.hooks.useGraphVersions();

  const events = store.graphCompare.events;

  const diffBetweenValues = {
    vertexDiff:
      graphVersions.length > 1
        ? helpers.getDiffBetweenTwoNumbers(graphVersions.map((item) => item.vertexesCount))
        : null,
    edgesDiff:
      graphVersions.length > 1 ? helpers.getDiffBetweenTwoNumbers(graphVersions.map((item) => item.edgesCount)) : null,
    lengthRotesDiff:
      graphVersions.length > 1 ? helpers.getDiffBetweenTwoNumbers(graphVersions.map((item) => item.lengthRotes)) : null,
  };

  const versionsValidValues = graphVersions.map((item) => ({
    ...item,
    vertexesCount: item.vertexesCount?.toLocaleString(),
    edgesCount: item.edgesCount?.toLocaleString(),
    lengthRotes: (Number(item.lengthRotes) / 1000)?.toLocaleString(),
  }));

  const isStatisticsLoading = graphVersions.map((version) => version.isLoading).includes(true);

  return {
    graphVersions,
    versionsValidValues,
    diffBetweenValues,
    isStatisticsLoading,
    events,
  };
};

interface useGetVersionsStatisticArgType {
  versionsValidValues: ReturnType<typeof useGetGraphVersionsTools>["versionsValidValues"];
  diffBetweenValues: ReturnType<typeof useGetGraphVersionsTools>["diffBetweenValues"];
  isStatisticsLoading: ReturnType<typeof useGetGraphVersionsTools>["isStatisticsLoading"];
  isVersionsMoreThanOne: boolean;
}

const useMemoVersionsStatistic = ({
  versionsValidValues,
  isStatisticsLoading,
  isVersionsMoreThanOne,
  diffBetweenValues,
}: useGetVersionsStatisticArgType) =>
  useMemo(
    () =>
      versionsValidValues.map((version, index) => {
        const { vertexDiff, edgesDiff, lengthRotesDiff } = diffBetweenValues;
        const classNameDiff = `statistic-block__diff statistic-block__diff_${
          vertexDiff && vertexDiff > 0 ? "green" : "red"
        }`;
        const isShowDiff = !isStatisticsLoading && index > 0;

        return (
          <div className="statistic-block" key={version.id}>
            {isVersionsMoreThanOne && (
              <span className="statistic-block__value-date">от {moment(version.date).format("DD.MM.YYYY")}</span>
            )}
            {version.isLoading ? (
              <div className="statistic-block_loader">
                <Loader />
              </div>
            ) : (
              <>
                <div className="statistic-block__value-number">
                  {isShowDiff && <span className={classNameDiff}>{helpers.getParsDiff(Number(vertexDiff))}</span>}
                  {version.vertexesCount}
                </div>
                <div className="statistic-block__value-number">
                  {isShowDiff && <span className={classNameDiff}>{helpers.getParsDiff(Number(edgesDiff))}</span>}
                  {version.edgesCount}
                </div>
                <div className="statistic-block__value-number">
                  {isShowDiff && <span className={classNameDiff}>{helpers.getParsDiff(Number(lengthRotesDiff))}</span>}
                  {version.lengthRotes}
                </div>
              </>
            )}
          </div>
        );
      }),
    [versionsValidValues, isVersionsMoreThanOne, diffBetweenValues, isStatisticsLoading]
  );

interface useMemoDateOfVersionsArgsType {
  graphVersions: ReturnType<typeof useGetGraphVersionsTools>["graphVersions"];
  events: ReturnType<typeof useGetGraphVersionsTools>["events"];
  isVersionsMoreThanOne: boolean;
}

const useMemoDateOfVersions = ({ graphVersions, isVersionsMoreThanOne, events }: useMemoDateOfVersionsArgsType) =>
  useMemo(
    () =>
      graphVersions.map((version) => (
        <div className="version-block" key={version.id}>
          <span className="version-block__title">Версия от</span>
          <div className="version-block__datepicker-block">
            <CustomDatePicker
              type={"DATE"}
              dateFrom={moment(version.date).startOf("day").toISOString(true)}
              onChange={(date) =>
                events.setDateOfVersion({
                  date: `${date}Z` || "",
                  id: version.id,
                })
              }
            />
            {isVersionsMoreThanOne && <CloseButton onClick={() => events.deleteVersionById(version.id)} />}
          </div>
        </div>
      )),
    [graphVersions, isVersionsMoreThanOne, events]
  );

export const hooks = {
  useGetGraphVersionsTools,
  useMemoVersionsStatistic,
  useMemoDateOfVersions,
};
