import { useEffect, useState } from "react";
import { useStoreContext } from "../../../store";

export const useGraphCard = () => {
  const [isShowCard, setIsShowCard] = useState(false);
  const [edgeInfo, setEdgeInfo] = useState<Record<string, string>>({});

  const { store } = useStoreContext();

  const { handleIsVisibleChange, handleSetEdgeInfo } = store.graphCard.events;

  useEffect(() => {
    handleSetEdgeInfo(edgeInfo);
    handleIsVisibleChange(isShowCard);
  }, [edgeInfo, isShowCard]);

  return { setIsShowCard, setEdgeInfo };
};
