import { getParamsToString } from "../utils";
import { GetStatisticByIdResponseType } from "./types";
import { getInstance } from "../index";

export type GetStatisticByIdParamsType = {
  whCamId: number;
  from?: string;
  to?: string;
  intervalMinute: number;
  violations?: string;
  // 0 только без нарушений, 2 только с нарушениями
  kinds?: 0 | 2;
};

export const getStatisticById = (params: GetStatisticByIdParamsType): Promise<GetStatisticByIdResponseType> => {
  const path = `traffic-cam/Statistics/getstatisticsbycam`;
  const keysString = getParamsToString({ ...params, alignInterval: "interval" });
  return getInstance().get(path + keysString);
};
