import React, { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { GState } from "documentations";
import { decodeShape } from "utils/shape-to-geojson";
import { getManeuversArrivedTime } from "features/ctrl-route/utils/get-maneuver-arrived-time";
import { RouteManeuver } from "./components/route-maneuver";
import { removeRouteManeuver, drawRouteManeuver } from "./draw-route-maneuver";
import "./index.scss";

type Props = {};

let map: mapboxgl.Map;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

export const RouteInstructions: React.FC<Props> = () => {
  const { routeTime, timeStatus, selectedDay, activeVariant, costing } = useSelector(
    (state: GState) => ({
      selectedDay: state.view.selectedDay,
      timeStatus: state.router.timeStatus,
      routeTime: state.router.routeTime,
      activeVariant: state.router.routeVariants![state.router.activeIndexRoute],
      costing: state.router.costing,
    }),
    shallowEqual
  );

  const handleClickManeuvers = (index: number) => {
    const legs = activeVariant.original.trip.legs[0];
    const routePoints = decodeShape(legs.shape);
    const maneuverCoorIndex = legs.maneuvers[index].begin_shape_index;
    const maneuverCoor = routePoints[maneuverCoorIndex];
    const lastIndex = legs.maneuvers.length - 1;
    const typeManeuver = legs.maneuvers[index].type;
    map.flyTo({
      // @ts-ignore
      center: maneuverCoor,
      zoom: 16,
    });
    if (index === 0 || index === lastIndex) {
      removeRouteManeuver(map);
    } else {
      drawRouteManeuver(routePoints, maneuverCoor, map, typeManeuver);
    }
  };

  const getIsAbsoluteTime = useCallback(
    (type, index) => {
      if (costing === "Multimodal") return index === 0 || index === maneuvers.length - 1 || type === "bus";
      return true;
    },
    [costing]
  );

  const maneuvers = activeVariant.original.trip.legs[0].maneuvers;
  const startTime = timeStatus !== "last" ? moment(selectedDay) : moment(routeTime || undefined);
  const maneuverTimes = getManeuversArrivedTime({
    maneuvers,
    withTraffic: activeVariant.withTraffic,
    startTime,
  });

  return (
    <div className="route-instructions">
      <ul className="route-instructions-list">
        {maneuvers.map((maneuver, index) => {
          return (
            <RouteManeuver
              {...maneuver}
              isAbsoluteTime={getIsAbsoluteTime(maneuver.travel_type, index)}
              costing={costing}
              arrivedTime={maneuverTimes[index]?.format("HH:mm")}
              onClick={() => handleClickManeuvers(index)}
              key={index}
            />
          );
        })}
      </ul>
    </div>
  );
};
