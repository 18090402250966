import { createStore, sample, attach } from "effector";
import { AdministrativeDivisionTypes } from "types";
import { events } from "./administrative-division.events";
import { effects } from "./administrative-division.effects";
import * as reducers from "./administrative-division.reducers";
import { State } from "./administrative-division.types";

const initialState: State = {
  isDisabled: true,
  activeType: AdministrativeDivisionTypes.Type.unset,
};

export const store = createStore<State>(initialState);

store.on(events.setIsDisabled, reducers.handleSetIsDisabled);
store.on(events.initializeActiveType, reducers.setActiveType);
store.on(effects.loadTlsCounts.doneData, reducers.setTlsCount);
store.on(effects.loadDistricts.doneData, reducers.setDistricts);
store.on(events.handleDistrictsClick, reducers.setDistrictsActiveType);
store.on(effects.loadSpeedCamCount.doneData, reducers.setSpeedCamCount);
store.on(effects.loadRoadSignCount.doneData, reducers.setRoadSignCount);
store.on(effects.loadDistrictZones.doneData, reducers.setDistrictZones);
store.on(events.handleRestoreActiveType, reducers.setDefaultActiveType);
store.on(events.handleDistrictZonesClick, reducers.setDistrictZonesActiveType);

sample({
  source: store,
  clock: events.initializeActiveType,
  fn: (source) => {
    if (!source.districtTlsCount) {
      effects.loadTlsCounts();
      effects.loadRoadSignCount();
      effects.loadSpeedCamCount();
    }

    if (source.activeType === AdministrativeDivisionTypes.Type.districts) {
      return effects.loadDistricts();
    }

    effects.loadDistrictZones();
  },
});

// sample для первоначальной загрузки коллекции округов
sample({
  source: store,
  clock: events.handleDistrictsClick,
  fn: (source) => {
    if (source.activeType !== AdministrativeDivisionTypes.Type.districts || source.districts) {
      return source.activeType;
    }

    if (!source.districtTlsCount) {
      effects.loadTlsCounts();
      effects.loadRoadSignCount();
      effects.loadSpeedCamCount();
    }

    effects.loadDistricts();
    return source.activeType;
  },
  target: events.handleActiveTypeChange,
});

// sample для первоначальной загрузки коллекции районов
sample({
  source: store,
  clock: events.handleDistrictZonesClick,
  fn: (source) => {
    if (source.activeType !== AdministrativeDivisionTypes.Type.districtZones || source.districtsZones) {
      return source.activeType;
    }

    if (!source.districtTlsCount) {
      effects.loadTlsCounts();
      effects.loadRoadSignCount();
      effects.loadSpeedCamCount();
    }

    effects.loadDistrictZones();
    return source.activeType;
  },
  target: events.handleActiveTypeChange,
});

// sample для для сброса активного типа слоя до unset
sample({
  source: store,
  clock: events.handleRestoreActiveType,
  fn: (source) => source.activeType,
  target: events.handleActiveTypeChange,
});

// sample для начала подгрузки статистики по району/округу
sample({
  source: store,
  clock: events.handleMissingStatistic,
  fn: (...[, clock]) => clock,
  target: attach({
    source: store,
    mapParams: (area: AdministrativeDivisionTypes.MissingStatisticPayload, state) => {
      return { area, state };
    },
    effect: effects.loadStatistic,
  }),
});
