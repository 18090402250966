import * as THREE from "three";
// @ts-ignore
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { Group } from "./group";
import * as consts from "./diagram-3d.consts";

export class Grid extends Group {
  private rowsCount: number = 1;
  private readonly material = new THREE.MeshPhongMaterial({ color: 0xffffff });

  private getHorizontalGeometries = (rowsCount: number) => {
    if (!this.sizes) return [];

    const substrateStartY = -this.sizes.height / 2;
    const segmentsGapY = this.sizes.height / rowsCount;
    const startY = substrateStartY + segmentsGapY;
    const geometries: THREE.PlaneGeometry[] = [];

    for (let rowIndex = 0; rowIndex < rowsCount - 1; rowIndex++) {
      const geometry = new THREE.PlaneGeometry(this.sizes.width, consts.gridLineWidth);
      geometry.translate(0, startY + segmentsGapY * rowIndex, consts.lineDepthDelta);
      geometries.push(geometry);
    }

    return geometries;
  };

  private getVerticalGeometries = () => {
    if (!this.sizes) return [];

    const substrateStartX = -this.sizes.width / 2;
    const segmentsGapX = this.sizes.width / consts.hoursCount;
    const startX = substrateStartX + segmentsGapX;
    const geometries: THREE.PlaneGeometry[] = [];

    for (let hourIndex = 0; hourIndex < consts.hoursCount - 1; hourIndex++) {
      const geometry = new THREE.PlaneGeometry(consts.gridLineWidth, this.sizes.height - consts.linesRounding);
      geometry.translate(startX + segmentsGapX * hourIndex, 0, consts.lineDepthDelta);
      geometries.push(geometry);
    }

    return geometries;
  };

  public readonly calculate = (rowsCount: number) => {
    if (!this.sizes) throw new Error('Grid doesn\'t have sizes. Use "setSizes" method');

    this.rowsCount = rowsCount;
    const horizontalGeometries = this.getHorizontalGeometries(rowsCount);
    const verticalGeometries = this.getVerticalGeometries();
    const geometry = mergeBufferGeometries([...horizontalGeometries, ...verticalGeometries], true);
    const mesh = new THREE.Mesh(geometry, this.material);
    this.world.add(mesh);
    return this;
  };

  public readonly setIsVisible = (isVisible: boolean) => {
    if (isVisible) {
      return this.calculate(this.rowsCount);
    }
    this.world.clear();
  };
}
