import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detectorSlice, loadLastDayDetectorData } from "../../store/slice";
import { Exit, ExitFullArrows, FullArrows, GreenDetectorItem, UDSIcon } from "assets";
import { Loader } from "components/loader";
import { RoundToggleButton } from "components";
import {
  ButtonsBlock,
  FirstBlock,
  HeaderAddress,
  HeaderBlock,
  HeaderNameBlock,
  HeaderTitleBlock,
  PopupContainer,
  TitleWithIconBlock,
  ToggleButtonBlock,
} from "./detector-popup.styled";
import { TransportFlowMiniBlock } from "./components/transport-flow-mini-block";
import { TransportFlowFullBlock } from "./components/transport-flow-full-block";
import { DirectionDataType, DirectionType } from "../../types";
import { GState } from "../../../../documentations";

type Props = {
  id: string;
  num: string;
  address?: string;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement, MouseEvent>) => void;
  token?: string;
  persistentEdgeId?: string;
  detectorData?: {
    forward?: DirectionDataType | undefined;
    backward?: DirectionDataType | undefined;
    isLoad: boolean;
    direction: DirectionType;
  };
};
export const DetectorPopup: React.FC<Props> = ({
  id,
  num,
  address = "address",
  onClickClose,
  persistentEdgeId,
  detectorData,
}) => {
  const tileData = { id, num, address };
  const dispatch = useDispatch();

  const [isFullMode, setIsFullMode] = useState(false);

  const isUDSMode = Boolean(persistentEdgeId);

  const toggleIsFullMode = () => setIsFullMode(!isFullMode);
  const detectorStore = useSelector((state: GState) => state.detector.openedDetectors[id]) || {};
  const { forward, isLoad, direction = "forward", backward } = isUDSMode ? detectorData || {} : detectorStore;

  const buttonsValues: { value: typeof direction; caption: string }[] = [
    {
      value: "forward",
      caption: "Прямое напр.",
    },
    {
      value: "backward",
      caption: "Обратное напр.",
    },
  ];

  const setActiveDirection = (value: string) => {
    const directionAs = value as typeof direction;
    dispatch(detectorSlice.actions.setDirection({ detectorId: id, direction: directionAs }));
  };

  useEffect(() => {
    if (isFullMode && !isUDSMode) {
      !forward?.flowDataLastDayStat && dispatch(loadLastDayDetectorData(tileData));
    }
  }, [isFullMode, isUDSMode, forward?.flowDataLastDayStat]);

  const currentData = useMemo(() => {
    if (persistentEdgeId) {
      return [forward, backward].find((d) => d?.persistentEdgeId === persistentEdgeId);
    } else {
      return direction === "forward" ? forward : backward;
    }
  }, [persistentEdgeId, forward, backward, direction]);

  return (
    <PopupContainer isFullMode={isFullMode}>
      <HeaderBlock>
        <TitleWithIconBlock>
          {isUDSMode ? <UDSIcon /> : <GreenDetectorItem />}
          <HeaderTitleBlock>
            <HeaderNameBlock>
              <div className="num">{isUDSMode ? "Данные по участку УДС" : tileData.id}</div>
              {!isUDSMode && <div className="name">Детектор</div>}
            </HeaderNameBlock>
            <HeaderAddress>{tileData.address}</HeaderAddress>
            {isUDSMode && <HeaderAddress>Данные от: ДТ- {tileData.id}</HeaderAddress>}
          </HeaderTitleBlock>
        </TitleWithIconBlock>
        <ButtonsBlock className="not-dragable-zone">
          <FirstBlock>
            {forward ? (
              isFullMode ? (
                <ExitFullArrows onClick={toggleIsFullMode} />
              ) : (
                <FullArrows onClick={toggleIsFullMode} />
              )
            ) : (
              <div></div>
            )}
            {isLoad && <Loader />}
          </FirstBlock>
          <Exit onClick={onClickClose} />
        </ButtonsBlock>
      </HeaderBlock>
      {backward && !isUDSMode && (
        <ToggleButtonBlock className="not-dragable-zone">
          <RoundToggleButton activeValue={direction} values={buttonsValues} setActive={setActiveDirection} />
        </ToggleButtonBlock>
      )}
      {isFullMode ? (
        <TransportFlowFullBlock currentDirectionData={currentData} isUDSMode={isUDSMode} />
      ) : (
        <TransportFlowMiniBlock isLoad={isLoad} flowData={currentData?.flowData?.avgDataAllLines} />
      )}
    </PopupContainer>
  );
};
