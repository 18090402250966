import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "components/checkbox";
import { FilterIcon } from "../filter-icon/filter-icon";
import "./filter-item.scss";

const className = "system-item";

const FilterItem = ({ isActive, name, handleClick, value, disabled, rowHeight, detectorMode }) => {
  const title = name.length > 36 ? name : undefined;

  return (
    <div
      title={title}
      style={{ height: rowHeight }}
      className={`${className} ${disabled ? `${className}-disabled` : ""}`}
      onClick={handleClick}>
      {value && <FilterIcon value={value} isActive={!disabled} detectorMode={detectorMode} />}
      <span className={`${className}-name`}>{name}</span>
      <div className={`${className}-checkbox`}>
        <Checkbox checked={isActive} onChange={() => null} value={String(value)} label="" disabled={disabled} />
      </div>
    </div>
  );
};

FilterItem.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string,
  handleClick: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  rowHeight: PropTypes.number,
};

export default FilterItem;
