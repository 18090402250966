import React from "react";
import { useStoreContext } from "store";
import { shared } from "shared";

export const useGroupSwitchLogic = () => {
  const { store } = useStoreContext();
  const value = store.applicationSettings.hooks.useActiveMapStyle().id;
  const options = React.useMemo(() => shared.map.mapStyles.map((style) => ({ uid: style.id, label: style.name })), []);

  return {
    value,
    options,
    handleChange: store.applicationSettings.events.handleActiveMapStyleChange,
  };
};
