import { MapController } from "map-controller";
import { UiStore, GState } from "store";
import { AdministrativeDivisionTypes } from "types";
import { Broker } from "./broker";

export class AdministrativeDivisionBroker extends Broker {
  constructor(mapController: MapController, uiStore: UiStore) {
    super(mapController, uiStore);
    this.subscribe();
  }

  private handleActiveTypeChange = (activeType: AdministrativeDivisionTypes.Type) => {
    this.mapController.administrativeDivision?.setVisibility(activeType);
  };

  private handleDistrictsLoad = (features: GState.AdministrativeDivisionState["districts"]) => {
    this.mapController.administrativeDivision?.setDistricts(features ?? []);
  };

  private handleDistrictZonesLoad = (features: GState.AdministrativeDivisionState["districtsZones"]) => {
    this.mapController.administrativeDivision?.setDistrictZones(features ?? []);
  };

  private handleStatisticLoad = (statistic?: AdministrativeDivisionTypes.Statistic) => {
    this.mapController.administrativeDivision?.addStatistic(statistic);
    this.mapController.administrativeDivision?.openPopup(statistic?.id);
  };

  private handleMapLoad = () => {
    this.uiStore.administrativeDivision.events.setIsDisabled(false);
    this.mapController.administrativeDivision?.on(
      this.mapController.administrativeDivision.events.missingStatistic,
      this.uiStore.administrativeDivision.events.handleMissingStatistic
    );
  };

  protected readonly subscribe = () => {
    this.mapController.on(this.mapController.events.load, this.handleMapLoad);
    this.uiStore.administrativeDivision.effects.loadDistricts.doneData.watch(this.handleDistrictsLoad);
    this.uiStore.administrativeDivision.effects.loadDistrictZones.doneData.watch(this.handleDistrictZonesLoad);
    this.uiStore.administrativeDivision.effects.loadStatistic.doneData.watch(this.handleStatisticLoad);
    this.uiStore.administrativeDivision.events.handleActiveTypeChange.watch(this.handleActiveTypeChange);
  };
}
