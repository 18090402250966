import React from "react";
import { Props } from "./contact-info.types";

import "./contact-info.scss";

const contactInfoClass = "details-administrative-division__contact-info";
const contactClass = `${contactInfoClass}__contact`;

export const ContactInfo = (props: Props) => {
  return (
    <div className={contactInfoClass}>
      <div className={`${contactInfoClass}__title`}>{"Администрация/Контакты"}</div>
      <div className={`${contactInfoClass}__contacts-container`}>
        <div className={contactClass}>
          <div>{"Фамилия, имя, отчество"}</div>
          <div>{"Должность"}</div>
          <div>{"Номер телефона"}</div>
        </div>
        {props.contactsList?.map((contact) => (
          <div key={contact.fullName} className={contactClass} data-cypress={"contacts-list"}>
            <div className={`${contactInfoClass}__full-name`}>{contact.fullName}</div>
            <div className={`${contactInfoClass}__post`}>{contact.post}</div>
            <div className={`${contactInfoClass}__phone-number`}>{contact.phoneNumber}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
