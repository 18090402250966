import { useStore } from "effector-react";
import { store } from "./administrative-division";

const useFull = () => useStore(store);
const useIsDisabled = () => useStore(store).isDisabled;
const useActiveType = () => useStore(store).activeType;

export const hooks = {
  useFull,
  useIsDisabled,
  useActiveType,
};

export type Hooks = typeof hooks;
