import { createEvent } from "effector";
import { State } from "./transport-hubs.types";

const restoreIsVisible = createEvent();
const handleIsVisibleChange = createEvent<State>();
const initializeIsVisible = createEvent<boolean>();
const setIsDisabled = createEvent<State["isDisabled"]>();
const handleIsVisibleClick = createEvent<React.MouseEvent<HTMLButtonElement>>();

export const events = {
  setIsDisabled,
  restoreIsVisible,
  initializeIsVisible,
  handleIsVisibleClick,
  handleIsVisibleChange,
};

export type Events = typeof events;
