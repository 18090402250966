import { useStore, useStoreMap } from "effector-react";
import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { travelHeatmapStore } from "./travel-heatmap";
import moment from "moment";

const useTo = () => useStore(travelHeatmapStore.store).to;
const useFrom = () => useStore(travelHeatmapStore.store).from;
const useIsActive = () => useStore(travelHeatmapStore.store).isActive;
const useHoverItem = () => useStore(travelHeatmapStore.store).hoverItem;
const useActiveItem = () => useStore(travelHeatmapStore.store).activeItem;
const useIsDisabled = () => useStore(travelHeatmapStore.store).isDisabled;
const useDirectionMode = () => useStore(travelHeatmapStore.store).directionMode;
const useTransportType = () => useStore(travelHeatmapStore.store).transportType;
const useIsCorrespondenceMatrix = () => useStore(travelHeatmapStore.store).isCorrespondenceMatrix;
const useDirectionModeTitle = () => {
  const directionMode = useStore(travelHeatmapStore.store).directionMode;
  return shared.travelHeatmap.directionModeMapping[directionMode.value as TravelHeatmapTypes.DirectionMode];
};
const useTransportTypeTitle = () => {
  const transportType = useStore(travelHeatmapStore.store).transportType;
  return shared.travelHeatmap.transportTypeMapping[transportType.value as TravelHeatmapTypes.TransportType];
};
const usePrettyFrom = () => {
  const from = useStore(travelHeatmapStore.store).from;
  return moment(from).format("dd.MM.yyyy");
};
const usePrettyTo = () => {
  const to = useStore(travelHeatmapStore.store).to;
  return moment(to).format("dd.MM.yyyy");
};
const useSectorsInformation = () => useStore(travelHeatmapStore.store).sectorsInformation;
const useIsLoading = () => useStore(travelHeatmapStore.store).isLoading;
const useMaxTrips = () => useStore(travelHeatmapStore.store).maxTrips;
const useMinMaxFilter = () => useStore(travelHeatmapStore.store).minMaxFilter;
const useWeekdays = () => useStore(travelHeatmapStore.store).weekdays;
const useTimeRange = () => {
  return useStoreMap(travelHeatmapStore.store, (state) => ({ min: state.timeStart, max: state.timeEnd }));
};
const useIsCorrespondenceDisabled = () => !!!useStore(travelHeatmapStore.store).activeItem.length;
const useGraphicData = () =>
  useStore(travelHeatmapStore.store)?.graphicData ?? {
    days: [],
    hours: [],
  };

export const hooks = {
  useTo,
  useFrom,
  useMaxTrips,
  useIsActive,
  usePrettyTo,
  useWeekdays,
  useTimeRange,
  useIsLoading,
  useHoverItem,
  useIsDisabled,
  useActiveItem,
  usePrettyFrom,
  useGraphicData,
  useMinMaxFilter,
  useDirectionMode,
  useTransportType,
  useDirectionModeTitle,
  useTransportTypeTitle,
  useSectorsInformation,
  useIsCorrespondenceMatrix,
  useIsCorrespondenceDisabled,
};

export type Hooks = typeof hooks;
