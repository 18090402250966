import React, { useCallback, useState as useReactState } from "react";
import _ from "lodash";
import { Checkbox } from "components/checkbox";
import { compareArrays } from "utils/compare-two-arrays";
import { DayOfWeek } from "api/router/model/models";
import { DaySelectorProps, State } from "..";
import * as utils from "./utils";
import { ButtonKey } from "../types";

const entryList = [ButtonKey.SPLW, ButtonKey.SPLM, ButtonKey.SPLY];

type HookProps = DaySelectorProps & {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
};

export const useState = () =>
  useReactState<State>({
    keyMode: null,
    isOpen: false,
  });

export const useCallBacks = ({
  state,
  from,
  to,
  selectedDays,
  disabled,
  setState,
  changeDate,
  onChangeDays,
}: HookProps) => {
  const [localSelectedDays, setLocalSelectedDays] = useReactState(selectedDays);

  const { allOptions, options, date, baseClass } = utils;

  const changeDays = () => {
    if (!_.isEqual(selectedDays, localSelectedDays)) {
      onChangeDays(localSelectedDays);
    }
  };

  const handleButtonsClick = useCallback(
    (key: ButtonKey) => {
      const getNewDate = date.actions[key];

      if (!getNewDate) return;
      if (!entryList.includes(key)) {
        const newDate = getNewDate(date.getToday());
        if (typeof newDate !== "string") return;
        return changeDate(newDate, newDate);
      }

      const newDate = getNewDate(from, to);
      if (typeof newDate === "string") return;
      setState({ ...state, keyMode: key });
      changeDate(newDate.from, newDate.to);
    },
    [from, to, state, date, setState, changeDate]
  );

  const getIsSelected = useCallback(
    (value: DayOfWeek) => {
      return localSelectedDays.includes(value);
    },
    [localSelectedDays]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: DayOfWeek) => {
      // @ts-ignore
      if (e.target.type === "checkbox") return null;

      let newSelected: Array<DayOfWeek> = [];

      if (!localSelectedDays.length) newSelected = allOptions.filter((el) => el !== value);
      else if (localSelectedDays.includes(value)) newSelected = localSelectedDays.filter((el) => el !== value);
      else {
        const values = [...localSelectedDays, value];
        newSelected = allOptions.filter((el) => values.includes(el));
      }

      if (!newSelected.length) newSelected = [...allOptions];

      setLocalSelectedDays(newSelected);
    },
    [allOptions, localSelectedDays, setLocalSelectedDays]
  );

  const getRows = useCallback(() => {
    return options.map((el) => {
      return (
        <div
          key={`row_${el.value}`}
          data-testid={el.value}
          className={`${baseClass}-item`}
          onClick={(e) => handleClick(e, el.value)}>
          <span className={`${baseClass}-name`}>{el.label}</span>
          <div className={`${baseClass}-checkbox`}>
            <Checkbox label="" value={el.value} onChange={() => null} checked={getIsSelected(el.value)} />
          </div>
        </div>
      );
    });
  }, [options, baseClass, handleClick, getIsSelected]);

  const isAllDaysSelected = useCallback(() => {
    return compareArrays(localSelectedDays, allOptions);
  }, [localSelectedDays, allOptions]);

  const getLabel = useCallback(() => {
    if (isAllDaysSelected()) return "Все дни";
    if (utils.allOptions.length - localSelectedDays.length === 1) {
      const differenceValue = _.difference(allOptions, localSelectedDays)[0];
      const differenceShortName = options.find((el) => el.value === differenceValue)?.shortName;
      return `Кроме ${differenceShortName}`;
    }

    return localSelectedDays.map((el) => options.find((item) => item.value === el)?.shortName).join(" ");
  }, [localSelectedDays, options, allOptions, isAllDaysSelected]);

  const handleContainerMouseEnter = useCallback(() => {
    if (!disabled) setState({ ...state, isOpen: true });
  }, [disabled, state, setState]);

  const handleContainerMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const related = e?.relatedTarget as HTMLDivElement;
      if (related?.className?.includes("selector-popup")) {
        return;
      }
      setState({ ...state, isOpen: false });
      changeDays();
    },
    [state, setState, changeDays]
  );

  const handleLabelClick = useCallback(() => {
    if (!disabled) setState({ ...state, isOpen: !state.isOpen });
    changeDays();
  }, [disabled, state, setState, changeDays]);

  return {
    getRows,
    getLabel,
    handleLabelClick,
    isAllDaysSelected,
    handleButtonsClick,
    handleContainerMouseEnter,
    handleContainerMouseLeave,
  };
};
