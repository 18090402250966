import getBeforeId, { BLOCKING_POINTS_ID } from "../order-layers";
import IconsProperties from "../properties/icons-properties";
import {
  DTP_MAP_IMAGE_KEY,
  SIGN_OTHER_DANGERS_MAP_IMAGE_KEY,
  SIGN_ROAD_WORKS_MAP_IMAGE_KEY,
  SIGN_ROUGH_ROAD_MAP_IMAGE_KEY,
} from "../assets/map-svg-icons";
import Layer from "./layer";

export default class DtpLayer extends Layer {
  constructor(map) {
    super(map);
    this.map = map;
    this.layerId = BLOCKING_POINTS_ID;
    this.properties = new IconsProperties();
    this.data = {
      type: "FeatureCollection",
      features: [],
    };
  }

  addLayer() {
    const layer = {
      id: this.layerId,
      type: "symbol",
      source: this.layerId,
      layout: {
        "icon-image": "{icon}",
        "icon-size": this.properties.getIconSize(),
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
      },
    };
    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  prepareData(points) {
    const data = {
      type: "FeatureCollection",
      features: [],
    };

    points.forEach((point) => {
      const feature = {
        id: point.id,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [point.lng, point.lat],
        },
        properties: point,
      };
      data.features.push(feature);
    });
    this.data = data;
  }

  addSource() {
    this.map.addSource(this.layerId, {
      type: "geojson",
      data: this.data,
    });
  }

  addIncidentsBlocking(data) {
    const { incidentGeoJsons } = data;

    incidentGeoJsons.forEach((point) => {
      if (point.incidentInfo) {
        const lat = point.incidentInfo.incidentLatitude;

        const lng = point.incidentInfo.incidentLongitude;

        let icon;

        if (point.incidentInfo.type.id === 1) icon = SIGN_ROAD_WORKS_MAP_IMAGE_KEY;
        if (point.incidentInfo.type.id === 2) icon = SIGN_ROUGH_ROAD_MAP_IMAGE_KEY;
        if (point.incidentInfo.type.id === 3) icon = SIGN_OTHER_DANGERS_MAP_IMAGE_KEY;

        const feature = {
          id: point.id,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
          properties: {
            ...point.incidentInfo,
            icon,
          },
        };

        this.data.features.push(feature);
      }
    });

    this.updateLayerData();
  }

  addIncidentsFlooding(data) {
    const { items } = data;
    items.forEach((point) => {
      const icon = DTP_MAP_IMAGE_KEY;

      const feature = {
        id: point.id,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [point.lng, point.lat],
        },
        properties: {
          icon,
          overlapBegin: point.begin,
          description: point.description,
          type: 6,
        },
      };
      this.data.features.push(feature);
    });
    this.updateLayerData();
  }

  add() {
    if (!this.checkSource()) this.addSource();
    if (!this.checkLayer()) this.addLayer();
  }
}
