const sqr = (x: number) => {
  return x * x;
};

const dist2 = (v: Coordinates, w: Coordinates) => {
  return sqr(v[0] - w[0]) + sqr(v[1] - w[1]);
};

const distToSegmentSquared = (p: Coordinates, v: Coordinates, w: Coordinates) => {
  const l2 = dist2(v, w);
  if (l2 === 0) return dist2(p, v);
  let t = ((p[0] - v[0]) * (w[0] - v[0]) + (p[1] - v[1]) * (w[1] - v[1])) / l2;
  t = Math.max(0, Math.min(1, t));
  return dist2(p, [v[0] + t * (w[0] - v[0]), v[1] + t * (w[1] - v[1])]);
};

type Coordinates = [number, number];

/**
 * Return distance from point to line
 *
 * @param p - point coordinates
 * @param v - start line coordinates
 * @param w - end line coordinates
 */
export const distanceFromPointToLine = (p: Coordinates, v: Coordinates, w: Coordinates) => {
  return Math.sqrt(distToSegmentSquared(p, v, w));
};
