import React, { memo } from "react";
import { Checkbox } from "components/checkbox";
import CtrlIsochrone from "features/ctrl-isochrone/ctrl-isochrone";
import { Loader } from "components/loader";
import "./route-isochrone-ctrl.scss";

const className = "route-isochrone-ctrl";

type Props = {
  checked: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const RouteIsochroneCtrl: React.FC<Props> = memo((props) => {
  const { checked, isLoading, isDisabled, onChange } = props;

  return (
    <>
      <div className={`${className}`}>
        <Checkbox
          label="Показывать изохроны"
          checked={checked}
          onChange={onChange}
          value="isochrone"
          disabled={isDisabled}
        />
        {isLoading && (
          <div className={`${className}-loader`}>
            <Loader />
          </div>
        )}
      </div>
      {checked && <CtrlIsochrone />}
    </>
  );
});
