import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "features/sector-analysis/store";

const useIsUnitsCountGraph = () => useSelector((state: GState) => state.sectorAnalysis.isUnitsCountGraph);

export const useValues = () => {
  const isUnitsCountGraph = useIsUnitsCountGraph();

  return {
    isUnitsCountGraph,
  };
};

export const useCallbacks = () => {
  const dispatch = useDispatch();

  const handleIsUnitsCountGraph = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      dispatch(sectorAnalysisSlice.actions.setIsUnitsCountGraph(value));
    },
    [dispatch]
  );

  return {
    handleIsUnitsCountGraph,
  };
};
