export namespace TransportHubsTypes {
  export type FeatureProperties = {
    admarea: string;
    availabletransfer: string;
    carcapacity: number | null;
    district: string;
    globalid: number;
    nearstation: string;
    status: string;
    tpuname: string;
    yearofcomissioning: number;
  };

  export type Feature = GeoJSON.Feature<GeoJSON.Point, FeatureProperties>;
  export type FeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Point, FeatureProperties>;
}
