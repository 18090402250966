const observer: Map<string, Array<Function>> = new Map();
const onced: Map<Function, boolean> = new Map();
const repeated: Map<Function, number> = new Map();
let debug = false;
/**
 * Исполняет слушателя при срабатывания эвента
 */
export const on = (event: string, callback: Function) => {
  const listeners = observer.get(event);
  if (listeners) observer.set(event, [...listeners, callback]);
  else observer.set(event, [callback]);
};

/**
 * Прикрашает диспетчиризировать евенты для этого слушателя
 */
export const off = (event: string, callback: Function) => {
  const listeners = observer.get(event);
  if (listeners)
    observer.set(
      event,
      listeners.filter((listener) => listener !== callback)
    );
  else observer.delete(event);
};

/**
 * Исполняет слушателя только единажды
 */
export const once = (event: string, callback: Function) => {
  const listeners = observer.get(event);
  if (listeners) observer.set(event, [...listeners, callback]);
  else observer.set(event, [callback]);

  onced.set(callback, true);
};

/**
 * Исполняет слушателя указанное кол-во раз
 */
export const repeat = (event: string, repeat: number, callback: Function) => {
  const listeners = observer.get(event);
  if (listeners) observer.set(event, [...listeners, callback]);
  else observer.set(event, [callback]);

  repeated.set(callback, repeat);
};

export const debugOn = () => {
  debug = true;
};

export const debugOff = () => {
  debug = false;
};

export const dispatch = (event: string, ...args: any) => {
  if (debug) console.info({ event, args });

  const listeners = observer.get(event);
  if (listeners)
    if (onced.size === 0 && repeated.size === 0) {
      listeners.forEach((listener) => listener(...args));
    } else if (onced.size > 0) {
      observer.set(
        event,
        listeners.filter((listener) => {
          listener(...args);
          if (onced.has(listener)) {
            onced.delete(listener);
            return false;
          }
          return true;
        })
      );
    } else if (repeated.size > 0) {
      observer.set(
        event,
        listeners.filter((listener) => {
          listener(...args);
          const isRepeated = repeated.get(listener);
          if (isRepeated) {
            if (isRepeated > 1) {
              repeated.set(listener, isRepeated - 1);
              return true;
            }
            repeated.delete(listener);
            return false;
          }
          return true;
        })
      );
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
export { EVENTS } from "./events";
