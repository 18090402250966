import React from "react";
import mapboxgl, { LngLat } from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { ROAD_DETECTOR_ID } from "map-helpers/order-layers";
import { popupController } from "map-helpers/popups/popup-controller";
import { loadVCParamSelector } from "../store/selectors";
import { CreatePopupProps } from "../types";
import { RoadDetectorLayer } from "../map-layer/road-detector-layer";
import { DetectorPopup } from "../../ctrl-detector";
import { getDetectorDataById, getRoadDetectors } from "../../../api/detector-data/detector-data";
import moment from "moment/moment";
import { APP_ENV } from "../../../app-env";
import { DirectionDataType, DirectionType } from "../../ctrl-detector/types";

export class RoadDetectorController {
  private roadDetectorLayer: RoadDetectorLayer;
  private activeItems: number[] = [];
  private from: string = "";
  private to: string = "";

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.roadDetectorLayer = new RoadDetectorLayer(map, ROAD_DETECTOR_ID, ROAD_DETECTOR_ID);
    map.on("mousemove", this.roadDetectorLayer.layerId, () => {
      map.getCanvas().style.cursor = "pointer";
    });
    this.roadDetectorLayer.on("click", this.handleClick);
    this.map.on("style.load", () => {
      this.update();
    });
    this.update();
  }

  public update = () => {
    const {
      roadDetector: { isActive },
    } = this.store.getState();

    if (isActive) this.addLayer();
    else this.removeLayer();
  };

  private handleClick = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;
    const {
      geometry: {
        // @ts-ignore
        coordinates,
      },
      // @ts-ignore
      properties: { detectorId, pid, address },
    } = feature;

    const [Lng, Lat] = coordinates[0];
    if (!detectorId || !Lat || !Lng) return;
    const lngLat = new LngLat(Lng, Lat);

    this.createPopup({ detectorId, lngLat, pid, address });
    this.setActiveItems(detectorId);
  };

  private setActiveItems(id: number | null) {
    const nullFilter: mapboxgl.Expression = ["boolean", false];
    if (typeof id !== "number") {
      this.activeItems = [];
      this.roadDetectorLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
    if (this.activeItems.includes(id)) this.activeItems = this.activeItems.filter((el) => el !== id);
    else this.activeItems.push(id);

    if (!this.activeItems.length) {
      this.roadDetectorLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
  }

  private async getDetectorData() {
    const {
      traffic: { type },
      view: { from: storeFrom, to: storeTo },
    } = this.store.getState();

    const activeFilters = loadVCParamSelector(this.store.getState());

    const period = APP_ENV?.REACT_APP_CONFIGURATION?.trafficLastPeriod ?? 20;
    const currentDate = moment();
    const currentMinutes = currentDate.minutes();
    const rest = currentMinutes % 5;
    const formatedFrom = moment()
      .add(-rest - 5 - period, "minutes")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    const formatedTo = moment()
      .add(-rest - 5, "minutes")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    const fromWithoutMill = `${formatedFrom[0]}T${formatedFrom[1]}`;
    const toWithoutMill = `${formatedTo[0]}T${formatedTo[1]}`;

    if (type === "last") {
      this.from = fromWithoutMill;
      this.to = toWithoutMill;
    } else {
      const [from, to] = [storeFrom, storeTo].map((x) => moment(x).format("YYYY-MM-DDTHH:mm"));
      this.from = from;
      this.to = to;
    }

    const params: Parameters<typeof getRoadDetectors>[0] = { from: this.from, to: this.to };
    if (activeFilters) {
      params.vc = activeFilters;
    }

    const response = await getRoadDetectors(params);
    return response.data;
  }

  private async addLayer() {
    const data = await this.getDetectorData();
    this.roadDetectorLayer.add(data);
  }

  private removeLayer() {
    this.roadDetectorLayer.remove();
    popupController?.removePopupByType("roadDetector");
    this.setActiveItems(null);
  }

  private createPopup({ detectorId, lngLat, pid, address }: CreatePopupProps) {
    const containerId = `detector-popup-${detectorId}`;
    const onClickClose = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement, MouseEvent>) => {
      if (e.ctrlKey) {
        this.setActiveItems(null);
      } else {
        popupController?.removePopupById(containerId);
        this.setActiveItems(detectorId);
      }
    };

    const detectorData: {
      forward?: DirectionDataType;
      backward?: DirectionDataType;
      isLoad: boolean;
      direction: DirectionType;
    } = {
      isLoad: true,
      direction: "forward",
    };

    getDetectorDataById({
      detectorId: String(detectorId),
      from: this.from,
      to: this.to,
    }).then((r) => {
      detectorData.isLoad = false;
      const { forward, backward } = r.data;
      if (forward) {
        detectorData.forward = {
          persistentEdgeId: forward.persistentEdgeId,
          flowData: {
            avgDataAllLines: {
              avgDensity: Number(forward.traffic[0].avgDensity.toFixed(0)),
              avgOcc: forward.traffic[0].avgOcc,
              avgSpeed: forward.traffic[0].avgSpeed,
              avgVolume: forward.traffic[0].avgVolume,
              avgUtilization: forward.traffic[0].avgUtilization,
              avgFreewaySpeed: forward.traffic[0].avgFreewaySpeed,
              totalUnitsCount: forward.traffic[0].totalUnitsCount,
              to: forward.traffic[0].to,
              from: forward.traffic[0].from,
            },
            lines: forward.traffic[0].lanes,
          },
        };
      }

      if (backward) {
        detectorData.backward = {
          persistentEdgeId: backward.persistentEdgeId,
          flowData: {
            avgDataAllLines: {
              avgDensity: Number(backward.traffic[0].avgDensity.toFixed(0)),
              avgOcc: backward.traffic[0].avgOcc,
              avgSpeed: backward.traffic[0].avgSpeed,
              avgVolume: backward.traffic[0].avgVolume,
              avgUtilization: backward.traffic[0].avgUtilization,
              avgFreewaySpeed: backward.traffic[0].avgFreewaySpeed,
              totalUnitsCount: backward.traffic[0].totalUnitsCount,
              to: backward.traffic[0].to,
              from: backward.traffic[0].from,
            },
            lines: backward.traffic[0].lanes,
          },
        };
      }

      const component = (
        <DetectorPopup
          id={String(detectorId)}
          num={"num"}
          address={address}
          onClickClose={onClickClose}
          persistentEdgeId={pid}
          detectorData={detectorData}
        />
      );
      popupController?.addPopup({ component, lngLat, id: containerId, type: "roadDetector", itemId: detectorId });
    });
  }
}
