export const TRAFFIC_TOGGLE_ACTIVE = "TRAFFIC_TOGGLE_ACTIVE";
export const TRAFFIC_UPDATE_SCORE = "TRAFFIC_UPDATE_SCORE";
export const TRAFFIC_SCORE_LEVEL = "TRAFFIC_SCORE_LEVEL";
export const TRAFFIC_UPDATE_TYPE = "TRAFFIC_UPDATE_TYPE";
export const TRAFFIC_MAKE_ACTIVE = "TRAFFIC_MAKE_ACTIVE";
export const TRAFFIC_SET_FAILED_SCORE = "TRAFFIC_SET_FAILED_SCORE";
export const TRAFFIC_UPDATE_ACTIVE = "TRAFFIC_UPDATE_ACTIVE";
export const TRAFFIC_UPDATE_ALTERNATIVE = "TRAFFIC_UPDATE_ALTERNATIVE";
export const TRAFFIC_SET_FAILED_ALTERNATIVE = "TRAFFIC_SET_FAILED_ALTERNATIVE";
export const TRAFFIC_SET_IS_EMPTY_PIECES = "TRAFFIC_SET_IS_EMPTY_PIECES";
export const TRAFFIC_SET_IS_TRAFFIC_MODE_TOGGLE = "TRAFFIC_SET_IS_TRAFFIC_MODE_TOGGLE";
