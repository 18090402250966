export namespace ZoneStatisticsTypes {
  export type GetStatisticsBody = {
    fromDate: string;
    toDate: string;
    externalSystemIDs: number[];
    timeRanges: {
      from: string;
      to: string;
    }[];
    geoJson: GeoJSON.FeatureCollection<GeoJSON.Polygon, any>;
  };

  export type TimeRange = {
    min: number;
    max: number;
  };

  export type DateRange = {
    from: string;
    to: string;
  };

  export type EditPoint = {
    id: string;
    coordinates: number[];
  };

  export type PolygonFigure = {
    id: string;
    type: "Polygon" | "MultiPolygon";
    points: EditPoint[];
  };

  export type ScoreParams = {
    samplesCount: number;
    unitsCount: number;
    freeFlowAverageSpeed: number;
    averageSpeed: number;
    measurementLength: number;
    jamLength: number;
    freeFlowTime: number;
    time: number;
  };

  export type ScoreOutput = {
    score: number;
    scoreParams?: ScoreParams;
  };

  export type Statistics = {
    period?: {};
    scoreOutput: ScoreOutput;
    avgSpeed: number;
    unitsCount: number;
  };
}
