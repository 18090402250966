import { createContext } from "react";
import { transportHubsStore } from "./transport-hubs";
import { pedestrianGraphStore } from "./pedestrian-graph";
import { administrativeDivisionStore } from "./administrative-division";
import { busStopsStore } from "./bus-stops";
import { travelHeatmapStore } from "./travel-heatmap";
import { zoneStatisticsStore } from "./zone-statistics";
import { applicationSettingsStore } from "./application-settings";
import { graphCompareStore } from "./graph-compare";
import { graphCardStore } from "./graph-card";

export class UiStore {
  public readonly transportHubs = transportHubsStore;
  public readonly pedestrianGraph = pedestrianGraphStore;
  public readonly administrativeDivision = administrativeDivisionStore;
  public readonly busStops = busStopsStore;
  public readonly travelHeatmap = travelHeatmapStore;
  public readonly zoneStatistics = zoneStatisticsStore;
  public readonly applicationSettings = applicationSettingsStore;
  public readonly graphCompare = graphCompareStore;
  public readonly graphCard = graphCardStore;
}

export const storeContextValue = {
  store: new UiStore(),
};

export const StoreContext = createContext(storeContextValue);
