import React, { memo, useMemo, useCallback } from "react";
import VideoPlayer from "@megapolis/react-video-player";
import { APP_ENV } from "../../app-env";

import "./camera-video.scss";

const className = "camera-video";

type Props = {
  camId: string | number;
  camType: string;
  fileStorage: string;
  caption: string;
  description: string;
  token: string;
};

export const CameraVideo: React.FC<Props> = memo((props) => {
  const { camId, caption, description, token: access_token } = props;
  const id = useMemo(() => `${className}-camera-${camId}`, [camId]);
  const getToken = useCallback(() => access_token, [access_token]);

  return (
    <div className={className} id={id}>
      <VideoPlayer
        url={APP_ENV.REACT_APP_CAMERA_SERVICE_API}
        token={getToken}
        refresh={true}
        caption={caption}
        description={description}
        id={camId}
        draggable={true}
        autoplay={true}
      />
    </div>
  );
});
