import React, { Component } from "react";
import "./route-favorites-star.scss";

const className = "route-favorite-star";

class RouteFavoritesStar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { apply, handleClick } = this.props;
    return (
      <button type="button" onClick={handleClick} className={className}>
        {apply && <i className="dtm-favorite-apply-icon" />}
        {!apply && <i className="dtm-favorite-icon" />}
      </button>
    );
  }
}

export default RouteFavoritesStar;
