import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EVENTS, on } from "observer";
import { SingleButton } from "components";
import HeatmapLayer from "map-helpers/layers/heatmap-layer";
import RnisHeatmapLayer from "map-helpers/layers/rnis-heatmap-layer";
import { compareArrays } from "utils/compare-two-arrays";
import { heatmapUpdateIsActiveAC } from "old-store/view/action-creators";
import { APP_ENV } from "app-env";

import "./styles/ctrl-heatmap.scss";

let map;

on(EVENTS.INIT_MAP, (_map) => {
  map = _map;
});

class CtrlHeatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pointsArray !== this.props.pointsArray) {
      this.updateLayer(this.props.pointsArray);
    }
    const { type, isActive, mapInit, es, routeTimeType, changeIsActive } = this.props;
    let checkEs;
    if (Array.isArray(es) && Array.isArray(prevProps.es)) {
      checkEs = compareArrays([...es], [...prevProps.es]);
    }

    if (mapInit !== prevProps.mapInit) {
      if (Boolean(APP_ENV.REACT_APP_IS_TELEMETRY_RNIS)) {
        this.heatmapLayer = new RnisHeatmapLayer(map);
      } else {
        this.heatmapLayer = new HeatmapLayer(map);
      }

      map.on("style.load", () => this.updateLayer());
      this.updateLayer();
    }
    if (mapInit) {
      if (isActive !== prevProps.isActive) {
        if (isActive) {
          this.updateLayer();
        } else {
          this.heatmapLayer.remove();
        }
      }

      if (routeTimeType !== prevProps.routeTimeType && routeTimeType !== "last") {
        changeIsActive(false);
      }

      if (type !== prevProps.type && type !== "last") {
        changeIsActive(false);
      }

      if (!checkEs) {
        if (isActive) {
          this.updateLayer();
        }
      }
    }
  }

  updateLayer = async () => {
    if (Boolean(APP_ENV.REACT_APP_IS_TELEMETRY_RNIS)) {
      if (this.props.isActive && this.props.pointsArray.length) {
        this.heatmapLayer.setVisibility(true);
        await this.heatmapLayer.setData(this.props.pointsArray);
      }
    } else {
      const { es, isActive } = this.props;
      const data = {
        es,
      };
      if (isActive) this.heatmapLayer.update(data);
    }
  };

  handleClick = () => {
    const { isActive, changeIsActive } = this.props;
    changeIsActive(!isActive);
  };

  render() {
    const { isActive } = this.props;
    return (
      <div className="ctrl-headmap">
        <SingleButton
          isActive={isActive}
          onClick={this.handleClick}
          type="round"
          title="Разница значений"
          iconName="dtm-headmap-icon"
          buttonGroupItem
        />
      </div>
    );
  }
}

CtrlHeatmap.propTypes = {
  isActive: PropTypes.bool.isRequired,
  es: PropTypes.instanceOf(Array),
  type: PropTypes.string.isRequired,
  mapInit: PropTypes.bool.isRequired,
  routeTimeType: PropTypes.string,
  changeIsActive: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isActive: state.view.heatmap,
    es: state.view.es,
    type: state.traffic.type,
    routeTimeType: state.router.timeStatus,
    mapInit: state.router.mapIsLoaded,
    pointsArray: state.telemetry.pointsData,
  }),
  (dispatch) => ({
    changeIsActive: (data) => dispatch(heatmapUpdateIsActiveAC(data)),
  })
)(CtrlHeatmap);
