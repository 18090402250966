/* eslint-disable camelcase */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
export function decodeShape(str, precision) {
  let index = 0;
  let lat = 0;
  let lng = 0;
  const coordinates = [];
  let shift = 0;
  let result = 0;
  let byte = null;
  const factor = Math.pow(10, precision || 6);
  let latitude_change;
  let longitude_change;

  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    coordinates.push([lng / factor, lat / factor]);
  }

  return coordinates;
}

export function shapeToGeoJson(str, index) {
  const feature = {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: decodeShape(str),
    },
    properties: { index },
  };
  const geo = {
    type: "FeatureCollection",
    features: [feature],
  };
  return geo;
}
