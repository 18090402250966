import { useEffect, useState } from "react";
import { BusFrontIcon } from "../../assets";
import * as Features from "../../features";
import { roadDetectorSlice } from "../../features";
import styled from "styled-components";
import { ButtonGroup } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "../../documentations";
import { trafficModeToggleAC, trafficUpdateActiveAC } from "../../old-store/traffic/action-creators";

export const FilterGroup = () => {
  const [isShowPopupExternalSystem, setIsShowPopupExternalSystem] = useState(false);
  const [, setIsActiveExternalSystemButton] = useState(false);

  const [, setIsActiveDetectorButton] = useState(false);
  const [isShowDetectorPopup, setIsShowDetectorPopup] = useState(false);

  const dispatch = useDispatch();

  const { isActiveDetectorsView, isTrafficMode } = useSelector((state: GState) => ({
    isActiveDetectorsView: state.roadDetector.isActive,
    isTrafficMode: state.traffic.isTrafficMode,
  }));

  useEffect(() => {
    if (isActiveDetectorsView) {
      setIsActiveExternalSystemButton(false);
    }
  }, [isActiveDetectorsView]);

  return (
    <ButtonsContainer>
      <CustomButtonsGroup>
        <BusFrontIcon
          onClick={() => {
            if (!isTrafficMode) {
              dispatch(trafficModeToggleAC());
              if (isActiveDetectorsView) {
                dispatch(trafficUpdateActiveAC(true));
                dispatch(roadDetectorSlice.actions.setIsActive(false));
              }
              dispatch(roadDetectorSlice.actions.toggleIsRoadDetectorMode());
            }
          }}
          color={isTrafficMode ? "#7CBF3A" : "#747474"}
          onMouseEnter={() => {
            setIsShowPopupExternalSystem(!isShowPopupExternalSystem);
          }}
          onMouseLeave={() => {
            setIsShowPopupExternalSystem(!isShowPopupExternalSystem);
          }}
        />
        <Features.RoadDetectorButton
          onMouseEnter={() => {
            setIsShowDetectorPopup(!isShowDetectorPopup);
          }}
          onMouseLeave={() => {
            setIsShowDetectorPopup(!isShowDetectorPopup);
          }}
        />
      </CustomButtonsGroup>
      <PopupBlock>
        <Features.FilterExternalSystemNew
          setIsActive={setIsActiveExternalSystemButton}
          isShowPopup={isShowPopupExternalSystem}
          setIsShowPopup={setIsShowPopupExternalSystem}
        />
      </PopupBlock>
      <PopupBlock style={{ left: "58px" }}>
        <Features.DetectorFilterSystemNew
          toggleTitle="Все типы транспорта, в т.ч. нераспознанные"
          setIsActive={setIsActiveDetectorButton}
          isShowPopup={isShowDetectorPopup}
          setIsShowPopup={setIsShowDetectorPopup}
        />
      </PopupBlock>
    </ButtonsContainer>
  );
};

const PopupBlock = styled.div`
  position: absolute;
  top: 45px;
`;
const ButtonsContainer = styled.div`
  position: relative;
`;

const CustomButtonsGroup = styled(ButtonGroup)`
  padding-top: 10px;
  height: 40px;
  svg {
    margin-top: 2px;
    width: 45px;
  }
`;
