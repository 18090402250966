import React from "react";
import { useStoreContext } from "store";
import { Edge, Pencil } from "assets";
import { CloseButton } from "components";
import { CardButtonBlock, CardTitleBlock, EdgeId, HeaderBlock, Title } from "./graph-card-header.styled";

export const GraphCardHeader = () => {
  const {
    store: {
      graphCard: { events, hooks },
    },
  } = useStoreContext();

  const handleClose = () => {
    events.handleIsVisibleChange(false);
  };

  const handleEditCard = (isEdit: boolean) => {
    events.handleIsEditModeChange(isEdit);
  };

  const edgeInfo = hooks.useGetEdgeInfo();
  return (
    <HeaderBlock>
      <CardTitleBlock>
        <Edge />
        <EdgeId>{edgeInfo.edgeID}</EdgeId>
        <Title>{edgeInfo.type || "Ребро"}</Title>
      </CardTitleBlock>
      <CardButtonBlock>
        <Pencil onClick={() => handleEditCard(true)} />
        <CloseButton onClick={handleClose} />
      </CardButtonBlock>
    </HeaderBlock>
  );
};
