import { Chart, ChartDataSets } from "chart.js";

export class HoursChart {
  private chart?: Chart;
  private data: number[] = [];

  constructor(private canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext("2d");

    if (ctx) {
      if (Chart.defaults.global.legend) {
        Chart.defaults.global.legend.display = false;
      }

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
          ],
          datasets: [],
        },
        options: {
          elements: {
            point: {
              backgroundColor: "#4CAF50",
              radius: 5,
            },
          },
          devicePixelRatio: window.devicePixelRatio ?? 1,
        },
      });
    }
  }

  public readonly setData = (data: number[]) => {
    if (this.data === data) return;
    if (!this.chart) return;

    this.data = data;

    this.chart.data.datasets = [
      {
        data: this.data,
        fill: false,
        borderColor: "#4CAF50",
        tension: 0.1,
      } as ChartDataSets,
    ];
    this.chart.update();
  };
}
