import * as THREE from "three";
// @ts-ignore
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { Group } from "./group";
import * as utils from "./diagram-3d.utils";
import * as consts from "./diagram-3d.consts";
import { DiagramData } from "./diagram-3d.types";

const defaultGeometries = { pieces: [], text: [] };

export class Indicators extends Group {
  private readonly textMaterial = new THREE.MeshPhongMaterial({ color: 0x000000 });
  private readonly pieceMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff });
  private readonly fontLoader = new THREE.FontLoader();

  private getTextParams = (font: THREE.Font, size: number) => ({
    font,
    size,
    height: 1,
  });

  private getTextGeometry = (title: string, params: THREE.TextGeometryParameters) => {
    return new THREE.TextGeometry(title, params);
  };

  private formatLength(routeLength: number, stepLength: number, length: number) {
    if (routeLength <= 1000) return length.toFixed(0);
    if (stepLength >= 1000) return (length / 1000).toFixed(0);
    if (length === 0) return String(length);

    return (length / 1000).toFixed(1);
  }

  private addLengthTicks = (rowsCount: number, routeLength: number, font: THREE.Font) => {
    if (!this.sizes) return defaultGeometries;
    const substrateStartX = -this.sizes.width / 2;
    const substrateStartY = this.sizes.height / 2;
    const segmentsGapY = this.sizes.height / rowsCount;
    const startY = substrateStartY + segmentsGapY;
    const lineSize = 5;
    const lineDepth = 1;
    const stepLength = utils.getAxisStepByTotalLength(routeLength);
    const betweenStepCount = utils.getBetweenStepCount(stepLength);
    const intermediateLength = stepLength / betweenStepCount;
    const segmentHeight = (intermediateLength * this.sizes.height) / routeLength;
    const pieces: THREE.BoxGeometry[] = [];
    const text: THREE.TextGeometry[] = [];

    for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
      const iterationX = substrateStartX - lineSize / 2;
      const iterationY = startY - segmentsGapY * (rowIndex + 1);
      const textY = this.sizes.height / 2 - rowIndex * segmentHeight;

      const leftPiece = new THREE.BoxGeometry(lineSize, consts.gridLineWidth, lineDepth);
      const rightPiece = new THREE.BoxGeometry(lineSize, consts.gridLineWidth, lineDepth);

      leftPiece.translate(iterationX, iterationY, -lineDepth / 2);
      rightPiece.translate(-iterationX, iterationY, -lineDepth / 2);
      pieces.push(leftPiece, rightPiece);

      if ((intermediateLength * rowIndex) / routeLength < 0.99) {
        if (rowIndex % betweenStepCount === 0) {
          const value = this.formatLength(routeLength, stepLength, stepLength * (rowIndex / betweenStepCount));
          const iterationLength = `${value}`;
          const leftLengthGeometry = this.getTextGeometry(
            iterationLength,
            this.getTextParams(font, consts.tickerLabelsSize)
          );
          const rightLengthGeometry = this.getTextGeometry(
            iterationLength,
            this.getTextParams(font, consts.tickerLabelsSize)
          );

          leftLengthGeometry.translate(iterationX - 10 - 2 * iterationLength.length, textY, -1);
          rightLengthGeometry.translate(-iterationX + 5 + 2 * iterationLength.length, textY, -1);

          text.push(leftLengthGeometry, rightLengthGeometry);
        }
      }
    }
    return { pieces, text };
  };

  private addTimeTicks = (font: THREE.Font) => {
    if (!this.sizes) return defaultGeometries;
    const substrateStartX = -this.sizes.width / 2;
    const substrateStartY = this.sizes.height / 2;
    const lineSize = 5;
    const lineDepth = 1;
    const segmentsGapX = this.sizes.width / consts.hoursCount;
    const startX = substrateStartX + segmentsGapX;
    const pieces: THREE.BoxGeometry[] = [];
    const text: THREE.TextGeometry[] = [];

    for (let hourIndex = 0; hourIndex < consts.hoursCount - 1; hourIndex++) {
      const iterationX = startX + segmentsGapX * hourIndex;
      const verticalGeometry = new THREE.BoxGeometry(consts.gridLineWidth, lineSize, lineDepth);
      const lowerVerticalGeometry = new THREE.BoxGeometry(consts.gridLineWidth, lineSize, lineDepth);
      const pieceY = substrateStartY + lineSize / 2;
      const pieceZ = -lineDepth / 2;

      verticalGeometry.translate(iterationX, pieceY, pieceZ);
      lowerVerticalGeometry.translate(iterationX, -pieceY, pieceZ);
      pieces.push(verticalGeometry, lowerVerticalGeometry);

      const hourTitle = `${hourIndex + 1}:00`;
      const hourShiftX = 3;
      const hourGeometry = this.getTextGeometry(hourTitle, this.getTextParams(font, consts.tickerLabelsSize));
      const hourLowerGeometry = this.getTextGeometry(hourTitle, this.getTextParams(font, consts.tickerLabelsSize));
      const hourLabelX = iterationX - (hourTitle.length * hourShiftX) / 2;
      const hourLabelY = substrateStartY + lineSize + consts.tickerLabelsSize / 2;

      hourGeometry.translate(hourLabelX, hourLabelY, -1);
      hourLowerGeometry.translate(
        hourLabelX,
        -substrateStartY - lineSize - consts.tickerLabelsSize - consts.tickerLabelsSize / 2,
        -1
      );
      text.push(hourGeometry, hourLowerGeometry);
    }

    return { pieces, text };
  };

  private getGeometries = (rowsCount: number, routeLength: number, font: THREE.Font) => {
    const timeGeometries = this.addTimeTicks(font);
    const lengthGeometries = this.addLengthTicks(rowsCount, routeLength, font);
    return {
      pieces: [...timeGeometries.pieces, ...lengthGeometries.pieces],
      text: [...timeGeometries.text, ...lengthGeometries.text],
    };
  };

  private getTitleGeometries = (routeLength: number, font: THREE.Font) => {
    if (!this.sizes) return [];

    let routeLengthStr = "0";
    if (routeLength < 1000) {
      routeLengthStr = `${String(Math.round(routeLength))} м`;
    } else {
      routeLengthStr = `${(routeLength / 1000).toFixed(2)} км`;
    }

    const lengthShiftX = 35;
    const lengthShiftY = 15;
    const titleLeftGeometry = this.getTextGeometry(routeLengthStr, this.getTextParams(font, 10));
    const titleRightGeometry = this.getTextGeometry(routeLengthStr, this.getTextParams(font, 10));
    const startX = this.sizes.width / 2;
    const startY = -this.sizes.height / 2 - lengthShiftY;

    titleLeftGeometry.translate(-startX - lengthShiftX, startY, -1);
    titleRightGeometry.translate(startX, startY, -1);

    return [titleLeftGeometry, titleRightGeometry];
  };

  public readonly calculate = (data: DiagramData) => {
    this.fontLoader.load("/roboto_light.json", (font) => {
      const titleGeometries = this.getTitleGeometries(data.routeLength, font);
      const restGeometries = this.getGeometries(data.rowsCount, data.routeLength, font);

      const textGeometry = mergeBufferGeometries([...restGeometries.text, ...titleGeometries]);
      const piecesGeometry = mergeBufferGeometries(restGeometries.pieces);
      this.world.add(new THREE.Mesh(textGeometry, this.textMaterial));
      this.world.add(new THREE.Mesh(piecesGeometry, this.pieceMaterial));
    });

    return this;
  };
}
