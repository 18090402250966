export type CameraState = {
  isActive: boolean;
};

export enum CamType {
  codd = "codd",
  echd = "echd",
  file = "file",
}

export interface CreatePopup {
  id: number;
  num: string;
  address: string;
  lngLat: mapboxgl.LngLat;
  camType: CamType;
}
