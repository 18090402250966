import { useEffect } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { getStateByHistory } from "utils/get-state-by-history";
import {
  blockingUpdateViewAC,
  changeSelectedDayAC,
  esUpdateSelectedAC,
  filterUpdateValueAC,
  graphUpdateIsActiveAC,
  heatmapUpdateIsActiveAC,
  timeUpdateIsActiveAC,
} from "old-store/view/action-creators";
import { trafficActionCreators } from "old-store";
import { trafficMakeActiveAC } from "old-store/traffic/action-creators";
import { correlationUpdateIsActive, dtpUpdateIsActive } from "old-store/global/actions";
import { toggleIsActive } from "old-store/weather/reducer";
import { setMapIsLoaded } from "features/ctrl-route/store/slice";
import { PageEvents } from "utils";
import { useStoreContext } from "store";
import "moment-timezone";
import { APP_ENV } from "app-env";
import moment from "moment";

// @note временно
export const useSectorAnalysisIsActive = () => useSelector((state: GState) => state.sectorAnalysis.isActive);
// @note временно
export const useInitializeState = () =>
  useSelector(
    (state: GState) => ({
      oidc: state.oidc,
      blocking: state.view.blocking,
      correlation: state.global.correlation,
      dtp: state.global.dtp,
      es: state.view.es,
      filter: state.view.filter,
      graph: state.view.graph,
      heatmap: state.view.heatmap,
      meteostation: state.global.meteostation,
      sectorAnalysisIsActive: state.sectorAnalysis.isActive,
      selectedTime: state.view.selectedDay,
      time: state.view.time,
      traffic: state.traffic.isActive,
      weather: state.weather.isActive,
    }),
    shallowEqual
  );

// @note временно
const useActions = () => {
  const dispatch = useDispatch();

  const updateFilter = (data: string) => dispatch(filterUpdateValueAC(data));
  const heatmapUpdate = (data: any) => dispatch(heatmapUpdateIsActiveAC(data));
  const blockingUpdate = (data: any) => dispatch(blockingUpdateViewAC(data));
  const esUpdateSelected = (data: any) => dispatch(esUpdateSelectedAC(data));
  const updateSelectedTime = (data: any) => dispatch(changeSelectedDayAC(data));
  const trafficMakeActive = () => dispatch(trafficMakeActiveAC());
  const mapLoaded = (data: boolean) => dispatch(setMapIsLoaded(data));
  const updateTime = (data: boolean) => dispatch(timeUpdateIsActiveAC(data));
  const graphUpdate = (data: boolean) => dispatch(graphUpdateIsActiveAC(data));
  const dtpUpdate = (isActive: boolean) => dispatch(dtpUpdateIsActive(isActive));
  const weatherUpdate = (data: boolean) => dispatch(toggleIsActive(data));
  const correlationUpdate = (data: boolean) => dispatch(correlationUpdateIsActive(data));

  return {
    dtpUpdate,
    graphUpdate,
    updateFilter,
    heatmapUpdate,
    esUpdateSelected,
    trafficMakeActive,
    updateSelectedTime,
    correlationUpdate,
    blockingUpdate,
    weatherUpdate,
    updateTime,
    mapLoaded,
  };
};

let prevState: ReturnType<typeof useInitializeState> | null = null;

// @note временно
export const useParams = () => {
  const state = useInitializeState();

  useEffect(() => {
    if (prevState) return;

    prevState = state;
  }, []);

  useEffect(() => {
    const {
      es,
      traffic,
      correlation,
      filter,
      heatmap,
      graph,
      dtp,
      meteostation,
      weather,
      blocking,
      time,
      selectedTime,
    } = state;
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    if (prevState?.traffic !== traffic) {
      searchParams.set("traffic", String(Number(traffic)));
    }
    if (prevState?.correlation !== correlation) {
      searchParams.set("correlation", String(Number(correlation)));
    }
    if (prevState?.heatmap !== heatmap) {
      searchParams.set("heatmap", String(Number(heatmap)));
    }
    if (prevState?.graph !== graph) {
      searchParams.set("graph", String(Number(graph)));
    }
    if (prevState?.dtp !== dtp) {
      searchParams.set("dtp", String(Number(dtp)));
    }
    if (prevState?.meteostation !== meteostation) {
      searchParams.set("meteostation", String(Number(meteostation)));
    }
    if (prevState?.weather !== weather) {
      searchParams.set("weather", String(Number(weather)));
    }
    if (prevState?.blocking !== blocking) {
      searchParams.set("blocking", String(Number(blocking)));
    }
    if (prevState?.time !== time) {
      searchParams.set("time", String(Number(time)));
    }
    if (prevState?.filter !== filter) {
      searchParams.set("filter", filter);
    }
    if (prevState?.selectedTime !== selectedTime) {
      searchParams.set("selectedTime", selectedTime);
    }
    searchParams.delete("es");
    es.forEach((esItem) => searchParams.append("es", String(esItem)));
    const newUrl = `${window.location.origin}?${searchParams.toString()}`;
    window.history.replaceState(null, document.title, newUrl);
    prevState = { ...state };
  }, [state]);
};

// @note временно
export const useInitialize = () => {
  const { store } = useStoreContext();
  const actions = useActions();

  useEffect(() => {
    const { location } = window;
    new PageEvents().subscribe();
    const state = getStateByHistory(location);

    batch(() => {
      /** Обновление источников телематики */
      if (state.es) {
        actions.esUpdateSelected(state.es);
        store.busStops.events.setEsFromRedux(state.es);
      }

      /** Обновление статуса показа пробок */
      if (state.traffic) {
        actions.trafficMakeActive();
      }

      /** Обновление статуса показа корреляции */
      if (state.correlation) {
        actions.correlationUpdate(true);
      }

      /** Обновление статуса фильтра дорог */
      if (state.filter) {
        actions.updateFilter(state.filter);
      }

      if (state.heatmap) {
        /** Обновление статуса показа тепловой карты */
        actions.heatmapUpdate(true);
      }

      /** Обновление статуса показа графа дорог */
      if (state.graph) {
        actions.graphUpdate(true);
      }

      /** Обновление статуса показа ДТП */
      if (state.dtp) {
        actions.dtpUpdate(true);
      }

      /** Обновление статуса показа перекрытий */
      if (state.blocking) {
        actions.blockingUpdate(true);
      }

      /** Обновление статуса показа погоды */
      if (state.weather) {
        actions.weatherUpdate(true);
      }

      /** Обновление выбранного времени */
      if (state.selectedTime) {
        actions.updateSelectedTime(state.selectedTime);
      }

      /** Обновление статуса показа времени */
      if (state.time) {
        actions.updateTime(true);
      }
    });

    return () => {
      new PageEvents().unsubscribe();
    };
  }, []);
};

// @note временно
const useStatesTemporaryConnectionState = () =>
  useSelector(
    (state: GState) => ({
      es: state.view.es,
      from: state.view.from,
      to: state.view.to,
      trafficType: state.traffic.type,
    }),
    shallowEqual
  );

// @note временно
export const useStatesTemporaryConnection = () => {
  const { store } = useStoreContext();
  const dispatch = useDispatch();
  const { es, from, to, trafficType } = useStatesTemporaryConnectionState();
  const isTelematicsEmptyPieces = store.applicationSettings.hooks.useIsEmptyPieces();

  useEffect(() => {
    dispatch(trafficActionCreators.trafficIsEmptyPiecesAC(isTelematicsEmptyPieces));
  }, [isTelematicsEmptyPieces]);

  useEffect(() => {
    store.busStops.events.setEsFromRedux(es);
    store.zoneStatistics.events.setEsFromRedux(es);
  }, [es]);

  useEffect(() => {
    store.busStops.events.setFromDateFromRedux(from);
  }, [from]);

  useEffect(() => {
    store.busStops.events.setToDateFromRedux(to);
  }, [to]);

  useEffect(() => {
    store.busStops.events.setTrafficTypeFromRedux(trafficType);
  }, [trafficType]);
};

export const useSetTimeZone = () => {
  useEffect(() => {
    if (APP_ENV.REACT_APP_DTM_REGION.toLowerCase() === "владивосток") {
      moment.tz.setDefault("Asia/Vladivostok");
    }
  }, []);
};
