import React from "react";
import { useStoreContext } from "store";
import { NavigationGroupeLayers } from "components";
import * as consts from "./transport-hubs.consts";

export const TransportHubs = React.memo(() => {
  const { store } = useStoreContext();
  const isActive = store.transportHubs.hooks.useIsVisible();
  const isDisabled = store.transportHubs.hooks.useIsDisabled();

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isDisabled={isDisabled}
      isActive={isActive}
      onClick={store.transportHubs.events.handleIsVisibleClick}
    />
  );
});
