import getBeforeId, { BAD_ROADS_ID } from "../order-layers";
import Layer from "./layer";

const layerId = BAD_ROADS_ID;

export default class BadRoadsLayer extends Layer {
  constructor(map) {
    super(map);
    this.map = map;
    this.layerId = layerId;
  }

  addLayer() {
    const layer = {
      id: BAD_ROADS_ID,
      type: "line",
      source: BAD_ROADS_ID,
      paint: {
        "line-width": 1.5,
        "line-opacity": 0.6,
        "line-color": "#00F",
      },
    };
    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  prepareData(properties) {
    this.data = properties;
  }

  addSource() {
    this.map.addSource(this.layerId, {
      type: "geojson",
      data: this.data,
    });
  }

  add(properties) {
    if (properties) {
      this.prepareData(properties);
      if (!this.checkSource()) this.addSource();
      if (!this.checkLayer()) this.addLayer();
    }
  }
}
