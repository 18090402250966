import mapboxgl from "mapbox-gl";
import { BaseLayer } from "map-helpers";
import { SPEEDCAM_MAP_IMAGE_KEY } from "map-helpers/assets/map-svg-icons";
import getBeforeId, { SPEEDCAM_LAYER_ID } from "map-helpers/order-layers";

export class SpeedcamLayer extends BaseLayer.Abstract<GeoJSON.Point, GeoJSON.GeoJsonProperties> {
  private opacity = 1;
  private layerConfig: mapboxgl.SymbolLayer = {
    "id": SPEEDCAM_LAYER_ID,
    "type": "symbol",
    "source": SPEEDCAM_LAYER_ID,
    "source-layer": "speedcam",
    "layout": {
      "icon-image": SPEEDCAM_MAP_IMAGE_KEY,
      "icon-size": this.getDefaultIconSize(),
      "icon-allow-overlap": true,
      "icon-pitch-alignment": "map",
    },
    "paint": {
      "icon-opacity": this.opacity,
    },
  };

  constructor(map: mapboxgl.Map, tileUrl: string) {
    super(map, {
      id: SPEEDCAM_LAYER_ID,
      sourceType: "tile",
      tileUrl,
      beforeId: getBeforeId(SPEEDCAM_LAYER_ID, map),
    });

    this.setLayer(this.layerConfig);
    this.addSource();
    this.addLayer();
  }

  public setOpacity = (isVisible: boolean) => {
    this.opacity = isVisible ? 1 : 0;
    const layer = this.map.getLayer(this.id);
    if (!layer) return new Error();
    this.map.setPaintProperty(this.id, "icon-opacity", this.opacity);
  };
}
