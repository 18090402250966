import React from "react";
import { ButtonGroup } from "components/button-group";
import { CtrlRoute, CtrlRuler } from "features";

export const RouteGroup = () => {
  return (
    <ButtonGroup>
      <CtrlRoute.Components.RouteButton />
      <CtrlRuler />
    </ButtonGroup>
  );
};
