import React, { useCallback } from "react";
import Select from "@megapolis/select-dev";
import { ISelectIdItem } from "@megapolis/select-dev/dist/wrapper/models";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { routerSlice } from "features/ctrl-route/store/slice";
import { GState } from "documentations";
import "./route-select-template.scss";

const useFavoriteRoadsDictionary = () =>
  useSelector((store: GState) => {
    return store.router.favoriteRoadsDictionary.map(({ id, name }) => ({ id, name }));
  }, shallowEqual);

const useGetTemplateId = () => useSelector((store: GState) => [store.router.templateId], shallowEqual);

export const SelectTemplate = () => {
  const data = useFavoriteRoadsDictionary();
  const current = useGetTemplateId();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (value: null | ISelectIdItem[]) => {
      if (value === null) return;
      const [id] = value;
      if (current[0] === id) return;
      dispatch(routerSlice.actions.setTemplateId(id!));
    },
    [dispatch, current]
  );

  return (
    <div className="route-select-template rst">
      <Select customClass="rst__select" data={data} current={current} onFinish={handleChange} dropdownMaxHeight={200} />
    </div>
  );
};
