import { MouseEvent } from "react";
import { AdministrativeDivisionTypes } from "types";
import { State } from "./administrative-division.types";

export const setDistrictsActiveType = (state: State, payload: MouseEvent<HTMLButtonElement>): State => {
  payload.stopPropagation();
  let activeType = state.activeType;

  if (
    activeType === AdministrativeDivisionTypes.Type.unset ||
    activeType === AdministrativeDivisionTypes.Type.districtZones
  ) {
    return { ...state, activeType: AdministrativeDivisionTypes.Type.districts };
  }

  return { ...state, activeType: AdministrativeDivisionTypes.Type.unset };
};

export const setActiveType = (state: State, payload: State["activeType"]): State => ({ ...state, activeType: payload });

export const setDistrictZonesActiveType = (state: State, payload: MouseEvent<HTMLButtonElement>): State => {
  payload.stopPropagation();
  let activeType = state.activeType;

  if (
    activeType === AdministrativeDivisionTypes.Type.unset ||
    activeType === AdministrativeDivisionTypes.Type.districts
  ) {
    return { ...state, activeType: AdministrativeDivisionTypes.Type.districtZones };
  }

  return { ...state, activeType: AdministrativeDivisionTypes.Type.unset };
};

export const setDefaultActiveType = (state: State): State => {
  return { ...state, activeType: AdministrativeDivisionTypes.Type.unset };
};

export const setDistricts = (state: State, districts: State["districts"]): State => {
  return { ...state, districts };
};

export const setDistrictZones = (state: State, districtsZones: State["districtsZones"]): State => {
  return { ...state, districtsZones };
};

export const setTlsCount = (state: State, districtTlsCount: State["districtTlsCount"]): State => ({
  ...state,
  districtTlsCount,
});

export const setRoadSignCount = (state: State, districtRoadSignCount: State["districtRoadSignCount"]): State => ({
  ...state,
  districtRoadSignCount,
});

export const setSpeedCamCount = (state: State, districtSpeedCamCount: State["districtSpeedCamCount"]): State => ({
  ...state,
  districtSpeedCamCount,
});

export const handleSetIsDisabled = (state: State, isDisabled: State["isDisabled"]): State => ({
  ...state,
  isDisabled,
});
