import * as ROUTER_SLICE from "features/ctrl-route/store/slice";
import { deleteFavoritedHistoryAC } from "../user-history/action-creators";
import { RouterAPI } from "../../api/router";
import { FAVORITE_SET, FAVORITE_CHANGE_NAME, FAVORITE_CHANGE_ADDRESS, FAVORITE_CHANGE_LOCATION } from "./action-types";

export function setItemAC(payload) {
  return {
    type: FAVORITE_SET,
    payload,
  };
}

export function getFavoriteAC() {
  return (dispatch, getState) => {
    const state = getState();

    const {
      router: { points },
    } = state;

    let address;

    points.forEach((el) => {
      if (el.address) address = el.address;
    });

    RouterAPI.location.favorites.get().then((data) => {
      const aggregated = data.map((el) => {
        el.type = "favorite";
        return el;
      });
      const filterData = aggregated.filter((el) => el.address !== address);
      dispatch(setItemAC(filterData));
    });
  };
}

export function addFavoriteAC(payload) {
  const { options } = payload;

  return (dispatch) => {
    RouterAPI.location.favorites.add(options).then((result) => {
      dispatch(
        ROUTER_SLICE.setFavoriteForPoint({
          key: payload.key,
          status: true,
          id: result.id,
          name: result.name,
        })
      );
      dispatch(deleteFavoritedHistoryAC({ result }));
      dispatch(getFavoriteAC({ options: {} }));
    });
  };
}

export function updateFavoriteAC(payload) {
  return (dispatch) => {
    RouterAPI.location.favorites.update(payload.options).then(() => {
      dispatch(getFavoriteAC({ options: {} }));
    });
  };
}

export function touchFavoriteAC(payload) {
  const { options } = payload;

  return (dispatch) => {
    RouterAPI.location.favorites.touch(options).then(() => {
      dispatch(getFavoriteAC({ options: {} }));
    });
  };
}

export function deleteFavoriteAC(payload) {
  const { id, address, name } = payload.options;
  return (dispatch) => {
    RouterAPI.location.favorites.delete({ id, address, name }).then(() => {
      dispatch(getFavoriteAC({ options: {} }));
    });
  };
}

export function favoriteChangeNameAC(payload) {
  return {
    type: FAVORITE_CHANGE_NAME,
    payload,
  };
}

export function favoriteChangeAddressAC(payload) {
  return {
    type: FAVORITE_CHANGE_ADDRESS,
    payload,
  };
}

export function favoriteChangeLocationAC(payload) {
  return {
    type: FAVORITE_CHANGE_LOCATION,
    payload,
  };
}
