import React from "react";
import { components } from "react-select";

export const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span className="dtm-select-indicator">
          <i className="dtm-arrow-down-icon" />
        </span>
      </components.DropdownIndicator>
    )
  );
};
