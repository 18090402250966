import { IStore } from "../store.types";
import { store } from "./pedestrian-graph";
import { Events, events } from "./pedestrian-graph.events";
import { Hooks, hooks } from "./pedestrian-graph.hooks";
import { State } from "./pedestrian-graph.types";

export class Store implements IStore<State, Events, Hooks> {
  public readonly store = store;
  public readonly events = events;
  public readonly hooks = hooks;

  public readonly destroy = () => {
    console.info("destroy");
  };
}

export const pedestrianGraphStore = new Store();
