import mapboxgl from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import { TravelHeatmapTypes } from "types";
import { TravelHeatmapLayer2D } from "./travel-heatmap.layer.2d";
import { TravelHeatmapLayer3D } from "./travel-heatmap.layer.3d";
import { TravelHeatmapPopup } from "./travel-heatmap.popup";
import { Events, Handlers } from "./travel-heatmap.events";
import { Feature, FeatureCollection } from "./travel-heatmap.types";
import { H3IndexesAggregator } from "./h3-indexes-aggregator";

export class TravelHeatmap extends TypedEmitter<Handlers> {
  public readonly events = Events;
  private layer2D: TravelHeatmapLayer2D;
  private layer3D: TravelHeatmapLayer3D;
  private popup: TravelHeatmapPopup;
  private lastSector: Feature | null = null;
  private lastCursorLngLat: mapboxgl.LngLat | null = null;
  private colorPrice: number = 1;
  private isVisible: boolean = false;
  private sectorsInfo: { [key: string]: { [key: string]: TravelHeatmapTypes.SectorInformation } } = {};
  private popupTimeout?: number;
  private params = TravelHeatmapTypes.FilterParams.getDefault();
  private minMaxFilter: TravelHeatmapTypes.MinMaxFilter = [0, 1000000000];
  private sectorInfoLoadingQueue: string[] = [];
  private activeItems: FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };

  private get layerIds() {
    return [this.layer2D.layerId, this.layer2D.borderLayerId, this.layer2D.activeBorderLayerId];
  }

  private get isMapHasLayers() {
    return this.layerIds.reduce((acc, layerId) => {
      if (!acc) return acc;
      return !!this.map.getLayer(layerId);
    }, true);
  }

  constructor(private readonly map: mapboxgl.Map) {
    super();
    this.layer2D = new TravelHeatmapLayer2D(this.map);
    this.layer3D = new TravelHeatmapLayer3D(this.map);
    this.popup = new TravelHeatmapPopup(this.map);
    this.popup.setFrom(this.params.dateStart);
    this.popup.setTo(this.params.dateEnd);
    this.map.boxZoom.disable();
    this.subscribe();
  }

  private createH3Feature = (sector: Feature) => ({
    h3Index: sector.properties.H3_16X,
    properties: sector.properties,
  });

  private handleStyleLoad = () => {
    this.layer2D?.destroy();
    this.layer3D?.destroy();
    this.layer2D = new TravelHeatmapLayer2D(this.map);
    this.layer3D = new TravelHeatmapLayer3D(this.map);
    this.setMinMaxFilter(this.minMaxFilter);
    this.layer2D.setActiveItemIndex(this.activeItems);
    this.setColorPrice(this.colorPrice);
    this.handlePitchChange();
  };

  private handleMouseClick = (e: mapboxgl.MapMouseEvent) => {
    if (!this.isMapHasLayers || !this.isVisible) return;

    const sector = this.map
      .queryRenderedFeatures(e.point, {
        layers: [this.layer2D.layerId, this.layer2D.borderLayerId, this.layer2D.activeBorderLayerId],
      })
      .pop() as Feature | undefined;

    if (!sector) return;

    // @note удаление сегмента из коллекции, при нажатии на сектор второй раз
    if (this.activeItems.features.some((feature) => feature.properties.H3_16X === sector.properties.H3_16X)) {
      this.activeItems = {
        type: "FeatureCollection",
        features: this.activeItems.features.filter((feature) => feature.properties.H3_16X !== sector.properties.H3_16X),
      };
      this.layer2D.setActiveItemIndex(this.activeItems);
      return this.emit(this.events.activeItemChange, this.activeItems.features.map(this.createH3Feature));
    }

    // @note одиночный выбор сектора
    if (!e.originalEvent.shiftKey) {
      this.activeItems = {
        type: "FeatureCollection",
        features: [sector],
      };
      this.layer2D.setActiveItemIndex(this.activeItems);
      return this.emit(this.events.activeItemChange, [
        { h3Index: sector.properties.H3_16X, properties: sector.properties },
      ]);
    }

    // @note множественный выбор секторов
    const features = [...this.activeItems.features, sector];
    this.activeItems = {
      type: "FeatureCollection",
      features,
    };
    this.layer2D.setActiveItemIndex(this.activeItems);
    this.emit(this.events.activeItemChange, features.map(this.createH3Feature));
  };

  private handleMousemove = (e: mapboxgl.MapMouseEvent) => {
    if (!this.isMapHasLayers || !this.isVisible || this.layer3D.visibility) return;
    const sector = this.map
      .queryRenderedFeatures(e.point, {
        layers: this.layerIds,
      })
      .pop() as Feature | undefined;
    // @note обнуление hover элемента
    if (!sector) {
      this.layer2D.setHoverItem({
        type: "FeatureCollection",
        features: [],
      });
      window.clearTimeout(this.popupTimeout);
      this.popup.remove();
      this.popupTimeout = window.setTimeout(() => {
        this.popup.remove();
        window.clearTimeout(this.popupTimeout);
      }, 500);
      return this.emit(this.events.hoverItemChange, null);
    }
    // @note задача hover элемента
    this.layer2D.setHoverItem({
      type: "FeatureCollection",
      features: [sector],
    });
    const h3Index = sector.properties.H3_16X;
    const sectorInfo = this.sectorsInfo[this.params.toJSON()]?.[h3Index];
    if (sectorInfo) {
      this.popup.remove();
      window.clearTimeout(this.popupTimeout);
      this.popupTimeout = window.setTimeout(() => {
        // @ts-ignore
        this.popup.show(sector, e.lngLat, sectorInfo);
        window.clearTimeout(this.popupTimeout);
      }, 500);
    }
    // @note запрашиваем информацию о секторе, если ее нет уже в очереди
    if (!this.sectorInfoLoadingQueue.some((h3Index) => h3Index === sector.properties.H3_16X)) {
      this.emit(this.events.missingSectorInfo, h3Index, [sector.properties.centr_lng, sector.properties.centr_lat]);
      this.sectorInfoLoadingQueue.push(h3Index);
      this.lastSector = sector;
      this.lastCursorLngLat = e.lngLat;
    }
    this.emit(this.events.hoverItemChange, { h3Index: sector.properties.H3_16X, properties: sector.properties });
  };

  private handleMouseout = () => {
    if (!this.isVisible) return;

    this.layer2D.setHoverItem({
      type: "FeatureCollection",
      features: [],
    });
    window.clearTimeout(this.popupTimeout);
    this.popup.remove();
    this.popupTimeout = window.setTimeout(() => {
      this.popup.remove();
      window.clearTimeout(this.popupTimeout);
    }, 500);
  };

  private pitchDebounceTimer?: number;

  private handlePitchChange = () => {
    if (typeof this.pitchDebounceTimer === "number") {
      window.clearTimeout(this.pitchDebounceTimer);
    }

    if (!this.isVisible) return;

    this.pitchDebounceTimer = window.setTimeout(() => {
      const pitch = this.map.getPitch();

      if (pitch < 20) {
        window.clearTimeout(this.popupTimeout);
        this.popup.remove();
        this.layer3D.visibility = false;
        this.layer2D.setVisibility(true);
        return;
      }

      if (this.layer3D.visibility) return;

      this.layer2D.setVisibility(false);
      this.layer3D.visibility = true;
    }, 300);
  };

  private subscribe = () => {
    this.map.on("style.load", this.handleStyleLoad);
    this.map.on("click", this.handleMouseClick);
    this.map.on("mousemove", this.handleMousemove);
    this.map.on("mouseout", this.handleMouseout);
    this.map.on("pitch", this.handlePitchChange);
  };

  private unsubscribe = () => {
    this.map.off("style.load", this.handleStyleLoad);
    this.map.off("click", this.handleMouseClick);
    this.map.off("mousemove", this.handleMousemove);
    this.map.off("mouseout", this.handleMouseout);
    this.map.off("pitch", this.handlePitchChange);
  };

  public readonly setMinMaxFilter = (minMaxFilter: TravelHeatmap["minMaxFilter"]) => {
    this.minMaxFilter = minMaxFilter;
    new Promise(() => {
      this.layer2D.setMinMaxFilter(this.minMaxFilter);
    });

    this.layer3D.setMinMaxFilter(minMaxFilter);
  };

  public readonly setActiveItem = (activeItem: TravelHeatmapTypes.H3IndexesFeatureCollection) => {
    this.activeItems = H3IndexesAggregator.toFeatureCollection(activeItem);
    this.layer2D.setActiveItemIndex(this.activeItems);
  };

  public readonly setHoverItem = (hoverItem: TravelHeatmapTypes.H3IndexFeature | null) => {
    this.layer2D.setHoverItem(H3IndexesAggregator.toFeatureCollection(hoverItem ? [hoverItem] : []));
  };

  public readonly setIsVisible = (isVisible: boolean) => {
    this.isVisible = isVisible;
    if (isVisible) this.map.boxZoom.disable();
    else this.map.boxZoom.enable();
    this.layer2D.setVisibility(isVisible);
  };

  public readonly setFilterParams = (params: TravelHeatmapTypes.FilterParams) => {
    this.params = params;
    this.layer2D.setParams(params);
    this.popup.setFrom(params.dateStart);
    this.popup.setTo(params.dateEnd);
    this.popup.setTransportType(params.transportType);
    this.popup.setDirectionMode(params.directionMode === "start" ? "from" : "in");
  };

  public readonly setColorPrice = (colorPrice: number) => {
    this.colorPrice = colorPrice;
    this.layer2D.setColorPrice(colorPrice);
    this.layer3D.setColorPrice(colorPrice);
  };

  public readonly setSectorInfo = (sectorInfo: TravelHeatmapTypes.SectorInformation) => {
    if (!this.sectorsInfo[this.params.toJSON()]) {
      this.sectorsInfo[this.params.toJSON()] = {};
    }
    this.sectorsInfo[this.params.toJSON()][sectorInfo.h3Index] = sectorInfo;
    this.popup.remove();
    window.clearTimeout(this.popupTimeout);
    this.popupTimeout = window.setTimeout(() => {
      if (!this.lastCursorLngLat || !this.lastSector) return;
      // @ts-ignore
      this.popup.show(
        this.lastSector,
        this.lastCursorLngLat,
        this.sectorsInfo[this.params.toJSON()]?.[sectorInfo.h3Index]
      );
      window.clearTimeout(this.popupTimeout);
    }, 500);
  };

  public readonly destroy = () => {
    this.unsubscribe();
    this.layer2D.destroy();
    this.popup.destroy();
  };
}
