function isInteger(num: number) {
  return (num ^ 0) === num;
}

function round100(val: number) {
  return Math.round(val / 100) * 100;
}

export function formatDistanceInInstructions(distanceInKm: number) {
  const distanceInMeters = distanceInKm * 1000;

  if (distanceInMeters < 200) {
    return `${distanceInMeters} м`;
  }

  if (distanceInMeters >= 200 && distanceInMeters < 950) {
    return `${Number(distanceInMeters.toPrecision(2))} м`;
  }

  if (distanceInKm >= 0.95 && distanceInKm < 1) {
    return `${Math.round(distanceInKm)} км`;
  }

  if (distanceInKm >= 1 && distanceInKm < 50) {
    const countKm = Math.trunc(distanceInKm);
    const countM = round100(Number((distanceInKm - countKm) * 1000));
    if (isInteger(distanceInKm) || countM === 0) {
      return `${countKm} км`;
    }
    if (countM === 1000) {
      return `${Math.round(distanceInKm)} км`;
    }
    return `${countKm} км ${countM} м`;
  }

  if (distanceInKm >= 50 && distanceInKm < 1000) {
    return `${Math.round(distanceInKm)} км`;
  }

  if (distanceInKm >= 1000) {
    const countKm = Math.round(distanceInKm);
    const countThousand = Math.floor(countKm / 1000);
    const countHundreds = countKm - countThousand * 1000;
    const hundredWithZeros =
      countHundreds < 10 ? `00${countHundreds}` : countHundreds < 100 ? `0${countHundreds}` : countHundreds;
    return `${countThousand} ${hundredWithZeros} км`;
  }
}
