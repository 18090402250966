import getBeforeId, { GRAPH_ARROW_ID, GRAPH_SOURCE_ID } from "../order-layers";
import Layer from "./layer";

const layerId = GRAPH_ARROW_ID;

export default class GraphArrowLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  addLayer() {
    const layer = {
      "id": GRAPH_ARROW_ID,
      "type": "symbol",
      "source": GRAPH_SOURCE_ID,
      "source-layer": "edges",
      "minzoom": 15,
      "layout": {
        "icon-image": "traffic-direction-arrow",
        "symbol-placement": "line",
        "symbol-spacing": {
          stops: [
            [2, 10],
            [16, 60],
            [20, 150],
          ],
        },
        "symbol-avoid-edges": true,
        "icon-size": {
          stops: [
            [2, 0.5],
            [16, 1],
            [20, 1.5],
          ],
        },
      },
      "filter": ["==", "oneway", 1],
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }
}
