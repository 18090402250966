import mapboxgl from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import moment from "moment";
import { GState } from "documentations";
import {
  TELEMETRY_BIND_POINT_ID,
  TELEMETRY_BIND_TRACK_ID,
  TELEMETRY_BIND_UNMATCHED_POINT_ID,
  TELEMETRY_POINTS_ID,
} from "map-helpers/order-layers";
import { TelemetryAPI } from "api/telemetry";
import { RouterAPI } from "api/router";
import { TrafficAPI } from "api/traffic";
import { TilesInput } from "api/traffic/model/tiles";
import { TrafficPeriod } from "api/traffic/model/traffic";
import { BaseLayer } from "map-helpers";
import { roundDate } from "utils/round-date";
import * as Layers from "../map-layers";
import { togglePointsPopup } from "../map-popup/show-points-popup";
import { getBindLineData } from "../utils/getBindLineData";
import { getBindPointsData } from "../utils/getBindPointsData";
import { getBindTrackData } from "../utils/getBindTrackData";
import { getBindUnmatchedLineData } from "../utils/getBindUnmatchedLineData";
import { getBindConnectData } from "../utils/getBindConnectData";
import { getBindUnmatchedPointsData } from "../utils/getBindUnmatchedPointsData";
import { MapboxTelemetryFeatures, TelemetryFeatureProperties } from "../types";

export class TelemetryController {
  private telemetryPointsLayer?: Layers.TelemetryPointsLayer;
  private width = 2;
  private height = 2;
  private isShowingTrack = false;
  private closeMarker: mapboxgl.Marker;
  private clickTimeout?: NodeJS.Timeout;
  private telemetryMatched?: Layers.TelemetryMatchedLayer;
  private telemetryBindPointsLayer?: Layers.TelemetryBindPointsLayer;
  private telemetryBindLineLayer?: Layers.TelemetryBindLineLayer;
  private telemetryBindTrackLayer?: Layers.TelemetryBindTrackLayer;
  private telemetryBindConnectLayer?: Layers.TelemetryBindConnectLayer;
  private telemetryBindUnmatchedPointsLayer?: Layers.TelemetryBindUnmatchedPointsLayer;
  private telemetryBindUnmatchedLineLayer?: Layers.TelemetryBindUnmatchedLineLayer;

  private get isActive() {
    return this.store.getState().telemetry.isActive;
  }

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.subscribe();
    this.createLayers();
    this.closeMarker = new mapboxgl.Marker(this.getCloseIcon());
    this.update();
  }

  private getCloseIcon() {
    const button = document.createElement("button");
    button.onclick = () => {
      this.resetTrack();
      this.isShowingTrack = false;
    };
    button.className = "telemetry-map-close-icon";
    button.innerHTML = `
        <svg width="12" height="12" viewBox="0 0 32 32">
            <path fill="currentColor" d="M2.518-.5A2.91 2.91 0 00.497.316H.495L.493.318a2.752 2.752 0 00-.844 1.943l.666.004h-.666v.041c0 .771.313 1.474.82 1.98l11.578 11.631L.6 27.513a2.804 2.804 0 00-1.045 2.184A2.81 2.81 0 002.358 32.5c.868 0 1.65-.4 2.166-1.025l-.031.035 11.572-11.625 11.469 11.596c.49.523 1.192.85 1.963.85h.014a2.874 2.874 0 001.992-.814l.002-.002a2.783 2.783 0 000-3.97L20.062 15.92l11.52-11.592a2.802 2.802 0 00.002-3.982l.002-.002h-.002l-.002-.002-.586.59.582-.596a2.913 2.913 0 00-4.024 0l-.006.004-11.557 11.611L4.55.328l-.143.141.141-.143V.324l-.426.424.42-.43a2.91 2.91 0 00-2.023-.816z"/>
        </svg>`;
    return button;
  }

  public update() {
    if (this.isActive) {
      return this.showLayers();
    }

    this.hideLayers();
  }

  private showLayers() {
    this.telemetryPointsLayer?.setVisibility(true);
    togglePointsPopup(this.map, true);
  }

  private hideLayers() {
    this.telemetryPointsLayer?.setVisibility(false);
    this.isShowingTrack = false;
    togglePointsPopup(this.map, false);
    this.hideBindLayers();
  }

  private handleStyleChange = () => {
    if (!this.map.isStyleLoaded()) {
      return this.map.once("idle", this.reCreateLayers);
    }

    this.reCreateLayers();
  };

  private subscribe = () => {
    this.map.on("click", this.handleClick);
    this.map.on("dblclick", this.handleDblClick);
    this.map.on("mousemove", this.handleMouseMove);
    this.map.on("style.load", this.handleStyleChange);
  };

  private unsubscribe = () => {
    this.map.off("click", this.handleClick);
    this.map.off("dblclick", this.handleDblClick);
    this.map.off("mousemove", this.handleMouseMove);
    this.map.off("style.load", this.handleStyleChange);
  };

  private reCreateLayers = () => {
    this.isShowingTrack = false;
    togglePointsPopup(this.map, false);
    this.destroyLayers();
    this.createLayers();
  };

  public updateLayersAfterChangeHistoryDate() {
    this.destroyLayers();
    this.createLayers();
  }

  private createLayers = () => {
    this.telemetryPointsLayer = new Layers.TelemetryPointsLayer(this.map, this.getTileUrl());
    this.telemetryMatched = new Layers.TelemetryMatchedLayer(this.map);
    this.telemetryBindPointsLayer = new Layers.TelemetryBindPointsLayer(this.map);
    this.telemetryBindLineLayer = new Layers.TelemetryBindLineLayer(this.map);
    this.telemetryBindTrackLayer = new Layers.TelemetryBindTrackLayer(this.map);
    this.telemetryBindConnectLayer = new Layers.TelemetryBindConnectLayer(this.map);
    this.telemetryBindUnmatchedPointsLayer = new Layers.TelemetryBindUnmatchedPointsLayer(this.map);
    this.telemetryBindUnmatchedLineLayer = new Layers.TelemetryBindUnmatchedLineLayer(this.map);

    this.telemetryPointsLayer.setVisibility(this.isActive);
    this.telemetryMatched.setVisibility(this.isActive);
    this.telemetryBindPointsLayer.setVisibility(this.isActive);
    this.telemetryBindLineLayer.setVisibility(this.isActive);
    this.telemetryBindTrackLayer.setVisibility(this.isActive);
    this.telemetryBindConnectLayer.setVisibility(this.isActive);
    this.telemetryBindUnmatchedPointsLayer.setVisibility(this.isActive);
    this.telemetryBindUnmatchedLineLayer.setVisibility(this.isActive);
  };

  private destroyLayers = () => {
    this.telemetryMatched?.destroy();
    this.telemetryPointsLayer?.destroy();
    this.telemetryBindPointsLayer?.destroy();
    this.telemetryBindLineLayer?.destroy();
    this.telemetryBindTrackLayer?.destroy();
    this.telemetryBindConnectLayer?.destroy();
    this.telemetryBindUnmatchedPointsLayer?.destroy();
    this.telemetryBindUnmatchedLineLayer?.destroy();
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== "Escape") return;
    this.isShowingTrack = false;
    this.resetTrack();
  };

  private resetTrack = () => {
    this.hideBindLayers();
    this.update();
    this.closeMarker.remove();
  };

  private handleMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const width = 10 / 2;
    const features = this.map.queryRenderedFeatures([
      [e.point.x - width, e.point.y - width],
      [e.point.x + width, e.point.y + width],
    ]);
    const layers = features.map((el) => el.layer.id);
    if (
      layers.includes(TELEMETRY_BIND_POINT_ID) ||
      layers.includes(TELEMETRY_BIND_UNMATCHED_POINT_ID) ||
      !layers.includes(TELEMETRY_BIND_TRACK_ID)
    ) {
      return this.closeMarker.remove();
    }
    this.closeMarker.setLngLat(e.lngLat).addTo(this.map);
  };

  private hideBindLayers() {
    this.telemetryMatched?.setVisibility(false);
    this.telemetryBindLineLayer?.setVisibility(false);
    this.telemetryBindTrackLayer?.setVisibility(false);
    this.telemetryBindPointsLayer?.setVisibility(false);
    this.telemetryBindConnectLayer?.setVisibility(false);
    this.telemetryBindUnmatchedLineLayer?.setVisibility(false);
    this.telemetryBindUnmatchedPointsLayer?.setVisibility(false);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  private handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.isShowingTrack) return;
    if (this.clickTimeout) clearTimeout(this.clickTimeout);
    const features = this.getFeaturesByLayers(e, [TELEMETRY_POINTS_ID, TELEMETRY_BIND_POINT_ID]);
    if (!features.length) return;
    this.clickTimeout = setTimeout(() => {
      const feature = features[0];
      const { esid, unitid, time } = feature.properties;
      this.bindPoint({ esid, unitid, time });
    }, 300);
  };

  private handleSetVisible = (layer: BaseLayer.Abstract<any, any>) => () => {
    layer.setVisibility(true);
  };

  private async bindPoint(args: Pick<TelemetryFeatureProperties, "esid" | "unitid" | "time">) {
    const { esid, unitid, time } = args;
    const {
      oidc: {
        user: { access_token: token },
      },
    } = this.store.getState();
    const { timeFrom, timeTo } = this.getTimeRange(time);
    const filter = {
      es: esid,
      u: unitid,
      from: timeFrom,
      to: timeTo,
    };

    new Promise(async (res) => {
      const positions = await TelemetryAPI.telemetry.positions(filter, token);
      const track = positions.map((el) => ({
        time: el.dateTime,
        lat: el.latitude,
        lng: el.longitude,
      }));
      const attributes = await RouterAPI.router.mapmatch.attributes(track);
      const matchedPoints = attributes.matched_points.filter((el: { type: string }) => el.type !== "unmatched");
      const matchedPositions = positions.filter((el, index) => attributes.matched_points[index]?.type !== "unmatched");

      this.telemetryPointsLayer?.setVisibility(false);

      this.telemetryBindPointsLayer
        ?.setData(getBindPointsData(positions))
        .then(this.handleSetVisible(this.telemetryBindPointsLayer), () => {});

      this.telemetryBindLineLayer
        ?.setData(getBindLineData(positions))
        .then(this.handleSetVisible(this.telemetryBindLineLayer), () => {});

      this.telemetryBindTrackLayer
        ?.setData(getBindTrackData(attributes))
        .then(this.handleSetVisible(this.telemetryBindTrackLayer), () => {});

      this.telemetryBindUnmatchedPointsLayer
        ?.setData(getBindUnmatchedPointsData(attributes.matched_points, positions))
        .then(this.handleSetVisible(this.telemetryBindUnmatchedPointsLayer), () => {});

      this.telemetryBindUnmatchedLineLayer
        ?.setData(getBindUnmatchedLineData(attributes.matched_points, positions))
        .then(this.handleSetVisible(this.telemetryBindUnmatchedLineLayer), () => {});

      this.telemetryMatched
        ?.setData(getBindConnectData(matchedPoints, matchedPositions))
        .then(this.handleSetVisible(this.telemetryMatched), () => {});

      this.telemetryBindPointsLayer?.setData(getBindPointsData(matchedPositions));
      this.telemetryBindLineLayer?.setData(getBindLineData(matchedPositions));
      this.telemetryBindConnectLayer?.setData(getBindConnectData(attributes.matched_points, positions));

      res(true);
    })
      .then(() => {
        this.isShowingTrack = true;
        document.addEventListener("keydown", this.handleKeyDown);
      })
      .catch(console.error);
  }

  private handleDblClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.preventDefault();
    if (this.clickTimeout) clearTimeout(this.clickTimeout);
    const features = this.getFeaturesByLayers(e, [TELEMETRY_BIND_POINT_ID]);
    if (!features.length) return;
    this.hideBindLayers();
    this.update();
    this.isShowingTrack = false;
  };

  private getTimeRange(time: string) {
    const {
      view: { from, to, type },
    } = this.store.getState();
    let timeTo: string;
    let timeFrom: string;
    if (time) {
      timeTo = roundDate(moment(time), moment.duration(5, "minutes"), "floor").add(20, "minute").toISOString(true);
      timeFrom = roundDate(moment(time), moment.duration(5, "minutes"), "floor")
        .subtract(20, "minute")
        .toISOString(true);
    } else if (type === "last") {
      timeTo = roundDate(moment(), moment.duration(5, "minutes"), "floor").toISOString(true);
      timeFrom = moment(timeTo).subtract(40, "minute").toISOString(true);
    } else {
      timeFrom = from;
      timeTo = to;
    }
    return { timeFrom, timeTo };
  }

  private getFeaturesByLayers(
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
    layers: string[]
  ): MapboxTelemetryFeatures<TelemetryFeatureProperties> {
    const existLayers = layers.filter((el) => this.map.getLayer(el));
    const features = this.map.queryRenderedFeatures(
      [
        [e.point.x - this.width / 2, e.point.y - this.height / 2],
        [e.point.x + this.width / 2, e.point.y + this.height / 2],
      ],
      { layers: existLayers }
    ) as MapboxTelemetryFeatures<TelemetryFeatureProperties>;
    return features;
  }

  private getTileUrl() {
    const {
      view: { es, from, to },
      traffic: { type },
    } = this.store.getState();
    const input: TilesInput = { es };
    if (type !== "last") {
      input.from = from;
      input.to = to;
    } else {
      input.period = TrafficPeriod.Last;
    }
    return TrafficAPI.tiles.points(input);
  }

  public destroy = () => {
    this.unsubscribe();
    this.destroyLayers();
  };
}
