import React from "react";
import moment from "moment";
import "./route-destination-time.scss";
import { useSelector, shallowEqual } from "react-redux";
import { RouteResponse } from "features/ctrl-route/types";
import { GState } from "documentations";

const className = "route-destination-time";

type Props = {
  variant: RouteResponse;
};

const RouteDestinationTime: React.FC<Props> = ({ variant }) => {
  const { routeTime, timeStatus, selectedDay } = useSelector(
    (state: GState) => ({
      selectedDay: state.view.selectedDay,
      timeStatus: state.router.timeStatus,
      routeTime: state.router.routeTime,
    }),
    shallowEqual
  );

  const startTime = timeStatus !== "last" ? moment(selectedDay) : moment(routeTime || undefined);
  const itemTime = variant.withTraffic ? variant.time : variant.compareTime;
  const time = moment(startTime).add(itemTime, "s").format("HH:mm");
  return <div className={className}>{time}</div>;
};

export default RouteDestinationTime;
