import { Middleware, MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { GraphController } from "../controller";
import { graphSlice } from "./slice";

let _store: MiddlewareAPI<any, GState>;
let graphController: GraphController;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  graphController = new GraphController(map, _store);
});

export const graphMiddleware: Middleware<GState> = (store) => (next) => async (action) => {
  next(action);

  if (!_store) _store = store;

  if (graphSlice.actions.setIsCargoActive.match(action)) {
    graphController.update();
  }
};
