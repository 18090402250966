import * as THREE from "three";

export type Parent = THREE.Scene | THREE.Group;

export class ThreeObject<T extends { dispose: () => void }> {
  protected geometry?: T;
  protected material?: THREE.MeshPhongMaterial;
  protected mesh?: THREE.Mesh;

  public set opacity(value: number) {
    if (!this.material) return;
    this.material.opacity = value;
  }

  public set color(value: number) {
    if (!this.material) return;
    this.material.color = new THREE.Color(value);
  }

  public get uuid() {
    return this.mesh?.uuid ?? "";
  }

  public get translateX() {
    return this.mesh?.translateX;
  }

  public get translateY() {
    return this.mesh?.translateY;
  }

  public get translateZ() {
    return this.mesh?.translateZ;
  }

  public get rotateX() {
    return this.mesh?.rotateX;
  }

  public get rotateY() {
    return this.mesh?.rotateY;
  }

  public get rotateZ() {
    return this.mesh?.rotateZ;
  }

  constructor(protected parent: Parent) {}

  public translate = (x: number, y: number, z?: number) => {
    this.mesh?.translateX(x);
    this.mesh?.translateY(y);
    this.mesh?.translateZ(z ?? 0);
    return this;
  };

  public rotate = (x: number, y: number, z?: number) => {
    this.mesh?.rotateX(x);
    this.mesh?.rotateY(y);
    this.mesh?.rotateZ(z ?? 0);
    return this;
  };

  public add = () => {
    if (!this.mesh) return this;
    this.parent.add(this.mesh);
    return this;
  };

  public remove = () => {
    if (!this.mesh) return this;
    this.parent.remove(this.mesh);
    return this;
  };

  public destroy = () => {
    this.geometry?.dispose?.();
    this.material?.dispose?.();
    this.geometry = undefined;
    this.material = undefined;
    this.mesh = undefined;
  };
}
