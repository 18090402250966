import { IconSVGName } from "./single-button.types";
import { ReactComponent as TlsIcon } from "assets/icons/tls.svg";
import { ReactComponent as DtpIcon } from "assets/icons/dtp.svg";
import { ReactComponent as CityIcon } from "assets/icons/city.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as RouteIcon } from "assets/icons/route.svg";
import { ReactComponent as CargoIcon } from "assets/icons/cargo.svg";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { ReactComponent as SpeedcamIcon } from "assets/icons/speedcam.svg";
import { ReactComponent as DetectorIcon } from "assets/icons/detector.svg";
import { ReactComponent as BusIcon } from "assets/icons/bus-side-fill.svg";
import { ReactComponent as TelemetryIcon } from "assets/icons/telemetry.svg";
import { ReactComponent as MagistralIcon } from "assets/icons/magistral.svg";
import { ReactComponent as StationIcon } from "assets/icons/station-icon.svg";
import { ReactComponent as WeatherIcon } from "assets/icons/weather-icon.svg";
import { ReactComponent as FloodingIcon } from "assets/icons/flooding-icon.svg";
import { ReactComponent as DedicatedLineIcon } from "assets/icons/dedicated-line.svg";
import { ReactComponent as BlockingPlanIcon } from "assets/icons/blocking-plan-icon.svg";
import { ReactComponent as BlockingTemporaryIcon } from "assets/icons/blocking-temporary-icon.svg";
import { ReactComponent as CorrelationCompareIcon } from "assets/icons/correlation-compare-icon.svg";
import { ReactComponent as PedestrianIcon } from "assets/icons/pedestrian.svg";
import { ReactComponent as TransportHubsIcon } from "assets/icons/transport-hubs.svg";
import { ReactComponent as HistoryIcon } from "assets/icons/history-icon.svg";
import { HexagonIcon, SettingsIcon, ZoneIcon } from "assets";

export const Icons = {
  [IconSVGName.tls]: <TlsIcon />,
  [IconSVGName.dtp]: <DtpIcon />,
  [IconSVGName.bus]: <BusIcon />,
  [IconSVGName.city]: <CityIcon />,
  [IconSVGName.route]: <RouteIcon />,
  [IconSVGName.clock]: <ClockIcon />,
  [IconSVGName.cargo]: <CargoIcon />,
  [IconSVGName.camera]: <CameraIcon />,
  [IconSVGName.weather]: <WeatherIcon />,
  [IconSVGName.station]: <StationIcon />,
  [IconSVGName.flooding]: <FloodingIcon />,
  [IconSVGName.speedcam]: <SpeedcamIcon />,
  [IconSVGName.detector]: <DetectorIcon />,
  [IconSVGName.telemetry]: <TelemetryIcon />,
  [IconSVGName.magistral]: <MagistralIcon />,
  [IconSVGName.dline]: <DedicatedLineIcon />,
  [IconSVGName.blocking_plan]: <BlockingPlanIcon />,
  [IconSVGName.blocking_temporary]: <BlockingTemporaryIcon />,
  [IconSVGName.correlationCompareIcon]: <CorrelationCompareIcon />,
  [IconSVGName.pedestrian]: <PedestrianIcon />,
  [IconSVGName.transport_hubs]: <TransportHubsIcon />,
  [IconSVGName.travel_heatmap]: <HexagonIcon />,
  [IconSVGName.zone]: <ZoneIcon />,
  [IconSVGName.settings]: <SettingsIcon />,
  [IconSVGName.history]: <HistoryIcon />,
};
