export const GRAPH_UPDATE_IS_ACTIVE = "GRAPH_UPDATE_IS_ACTIVE";
export const HEATMAP_UPDATE_IS_ACTIVE = "HEATMAP_UPDATE_IS_ACTIVE";
export const TIME_UPDATE_IS_ACTIVE = "TIME_UPDATE_IS_ACTIVE";
export const FILTER_UPDATE_VALUE = "FILTER_UPDATE_VALUE";
export const LOADED_TRANSPORT_TYPE_DICTIONARY = "LOADED_TRANSPORT_TYPE_DICTIONARY";
export const ES_UPDATE_SELECTED = "ES_UPDATE_SELECTED";
export const BLOCKING_UPDATE_VIEW = "BLOCKING_UPDATE_VIEW";
export const CHANGE_SELECTED_DAY = "CHANGE_SELECTED_DAY";
export const CHANGE_CORRELATION_DAY = "CHANGE_CORRELATION_DAY";
export const CHANGE_IS_CUSTOM_CORRELATION = "CHANGE_IS_CUSTOM_CORRELATION";
export const CHANGE_IS_PEDESTRIAN_GRAPH = "CHANGE_IS_PEDESTRIAN_GRAPH";
