import React, { Component } from "react";
import "./route-time-tooltip.scss";

const className = "route-time-tooltip";

class RouteTimeTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { timeWitoutTraffic } = this.props;
    return (
      <div className={className}>
        <span>{`Без пробок ${timeWitoutTraffic}`}</span>
      </div>
    );
  }
}

export default RouteTimeTooltip;
