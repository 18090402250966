import { createEvent } from "effector";
import React from "react";
import { AdministrativeDivisionTypes } from "types";

const setIsDisabled = createEvent<boolean>();
const initializeActiveType = createEvent<AdministrativeDivisionTypes.Type>();
const handleActiveTypeChange = createEvent<AdministrativeDivisionTypes.Type>();
const handleDistrictsClick = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handleRestoreActiveType = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handleDistrictZonesClick = createEvent<React.MouseEvent<HTMLButtonElement>>();
const handleMissingStatistic = createEvent<AdministrativeDivisionTypes.MissingStatisticPayload>();

export const events = {
  setIsDisabled,
  handleDistrictsClick,
  initializeActiveType,
  handleActiveTypeChange,
  handleMissingStatistic,
  handleRestoreActiveType,
  handleDistrictZonesClick,
};

export type Events = typeof events;
