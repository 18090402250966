import { ReactComponent as ScoreA } from "./score-a.svg";
import { ReactComponent as ScoreB } from "./score-b.svg";
import { ReactComponent as ScoreV } from "./score-v.svg";
import { ReactComponent as ScoreG } from "./score-g.svg";
import { ReactComponent as ScoreD } from "./score-d.svg";
import { ReactComponent as DisabledScore } from "./disabled-score.svg";

export const trafficIndexIcons = {
  a: <ScoreA />,
  b: <ScoreB />,
  v: <ScoreV />,
  g: <ScoreG />,
  d: <ScoreD />,
  disabled: <DisabledScore />,
};
