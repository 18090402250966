import mapboxgl from "mapbox-gl";
import { render, unmountComponentAtNode } from "react-dom";
import { AdministrativeDivisionTypes } from "types";
import { PopupLayout } from "./popup.layout";
export class Popup {
  private popup: mapboxgl.Popup;
  private readonly container = document.createElement("div");

  constructor(private readonly map: mapboxgl.Map) {
    this.popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      className: "dtm-mapbox-popup",
      maxWidth: "680px",
    });

    this.container.className = "react-popup-container";
  }

  public readonly open = (lngLat: mapboxgl.LngLatLike, props: AdministrativeDivisionTypes.Statistic) => {
    render(<PopupLayout {...props} />, this.container);
    this.popup.setLngLat(lngLat).setDOMContent(this.container).addTo(this.map);
    return this;
  };

  public readonly close = () => {
    unmountComponentAtNode(this.container);
    this.popup.remove();
    return this;
  };
}
