import { TrafficData, TrafficPeriod, TrafficSet } from "./traffic";

export enum TrafficTileFilter {
  Highway = "h",
  Transport = "ptr",
  DedicatedLines = "dl",
}

export type TilesInput = {
  from?: string;
  to?: string;
  day?: string;
  period?: TrafficPeriod;
  es?: number[];
  esSet?: TrafficSet;
};

export type RnisTilesInput = {
  telematicDateBegin?: string;
  telematicDateEnd?: string;
  registrieCodes?: string;
};

export type TrafficTilesInput = TilesInput & {
  filter?: TrafficTileFilter;
  data?: TrafficData;
};

export type TrafficDiffTilesInput = TilesInput & { filter?: TrafficTileFilter };
