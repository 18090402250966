import React, { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { NavigationGroupeLayers } from "components";
import CtrlBlocking from "features/ctrl-blocking/ctrl-blocking";
import { FloodingButton, floodingSlice } from "features/ctrl-flooding";
import { DtpButton, dtpSlice } from "features/ctrl-dtp";
import { CtrlTemporaryBlocking } from "features/ctrl-temporary-blocking";
import { temporaryBlockingSlice } from "features/ctrl-temporary-blocking";
import { blockingUpdateViewAC } from "old-store/view/action-creators";
import * as consts from "./blocking-group.consts";
import "./blocking-group.scss";

const useIsActive = () => {
  return useSelector(
    (state: GState) =>
      state.view.blocking || state.flooding.isActive || state.dtp.isActive || state.view.blockingTemporary.isActive
  );
};

export const BlockingGroup = () => {
  const isActive = useIsActive();
  const dispatch = useDispatch();
  const handleGroupClick = useCallback(() => {
    batch(() => {
      dispatch(blockingUpdateViewAC(false));
      dispatch(floodingSlice.actions.setIsActive(false));
      dispatch(dtpSlice.actions.setIsActive(false));
      dispatch(temporaryBlockingSlice.actions.setIsActive(false));
    });
  }, [dispatch]);

  return (
    <div className="blocking-group">
      <NavigationGroupeLayers
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isActive={isActive}
        onClick={handleGroupClick}>
        <FloodingButton />
        <CtrlBlocking />
        <CtrlTemporaryBlocking />
        <DtpButton />
      </NavigationGroupeLayers>
    </div>
  );
};
