import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GState } from "documentations";
import { telemetrySlice } from "features/ctrl-telemetry/store/slice";
import { SingleButton, BUTTON_FORM, IconSVGName } from "components";

import "./ctrl-telemetry.scss";

const selector = (state: GState) => ({
  isActive: state.telemetry.isActive,
});

export const CtrlTelemetry = () => {
  const { isActive } = useSelector(selector);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(telemetrySlice.actions.setIsActive(!isActive));
  }, [dispatch, isActive]);

  return (
    <div className="ctrl-points">
      <SingleButton
        isActive={isActive}
        onClick={handleClick}
        type={BUTTON_FORM.round}
        title="Телематика"
        iconSvg={IconSVGName.telemetry}
        buttonGroupItem
      />
    </div>
  );
};
