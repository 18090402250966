import React from "react";
import { AdministrativeDivisionTypes } from "types";
import { APP_ENV } from "app-env";
import moment from "moment";

/* #region TODO:временное решение, в перспективе переделать */
type Contacts =
  | {
      fio: string;
      order: number;
      phoneNumbers: string[];
      position: string;
    }[]
  | undefined;

export const useGetContactsList = (props: AdministrativeDivisionTypes.Statistic) => {
  const { id } = props;

  const [contacts, setContacts] = React.useState<Contacts>(undefined);

  React.useEffect(() => {
    if (APP_ENV.REACT_APP_DTM_REGION === "Москва") {
      fetch(`${APP_ENV.REACT_APP_DTM_TRAFFIC_API}scoring/adminAreaInfo/${id}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          const err = new Error("Not 2xx response");
          throw err;
        })
        .then((res) => {
          setContacts(res.contacts);
        })
        .catch((err) => {
          console.log(err);
          setContacts(undefined);
        });
    }
  }, [id]);

  const contactsList =
    contacts &&
    contacts.map((item) => ({
      uid: item.order,
      fullName: item.fio,
      post: item.position,
      phoneNumber: item.phoneNumbers[0],
    }));

  return { contactsList };
};
/* #endregion */

export const useGetObjectData = (props: AdministrativeDivisionTypes.Statistic) => {
  const { carCount, tlsCount, roadSignCount, speedCamCount } = props;

  const objectData = [
    {
      uid: 1,
      name: "ТС",
      value: carCount,
    },
    {
      uid: 2,
      name: "Светофоры",
      value: tlsCount,
    },
    {
      uid: 3,
      name: "Знаки",
      value: roadSignCount,
    },
    {
      uid: 4,
      name: "КФВФ",
      value: speedCamCount,
    },
  ];

  return { objectData };
};

export const useGetIconSrc = (props: AdministrativeDivisionTypes.Statistic) => {
  const { fullName } = props;

  const iconsSrc = React.useMemo(() => {
    if (fullName.includes("округ")) return `${APP_ENV.REACT_APP_DTM_MEDIA}icons/counties_normal.svg`;

    return `${APP_ENV.REACT_APP_DTM_MEDIA}icons/district_normal.svg`;
  }, [fullName]);

  return { iconsSrc };
};

export const useGetTrafficIndex = (props: AdministrativeDivisionTypes.Statistic) => {
  const { trafficIndex: _trafficIndex } = props;

  const trafficIndex = React.useMemo(() => {
    if (typeof _trafficIndex !== "number") return;
    return Math.round(_trafficIndex * 100) / 100;
  }, [_trafficIndex]);

  return { trafficIndex };
};

export const useGetDate = () => {
  const date = React.useMemo(() => moment().format("YYYY-MM-DD (HH:mm)"), []);

  return { date };
};
