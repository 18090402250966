import { TravelHeatmapTypes } from "types";
import { State } from "./travel-heatmap.types";

export const prepareFilterPrams = (state: State) => {
  return new TravelHeatmapTypes.FilterParams(
    state.from,
    state.to,
    state.transportType.value as TravelHeatmapTypes.TransportType,
    state.directionMode.value === "from" ? "start" : "end",
    state.timeStart * 3600,
    state.timeEnd * 3600,
    state.weekdays
  );
};

export const secondsParser = (hour: number) => hour * 60 * 60;

export const createRequestStatisticPayload = (source: State) => {
  const directionMode = source.directionMode.value === "from" ? "start" : "end";

  const payload: TravelHeatmapTypes.RequestStatisticPayload = {
    h3Indexes: source.activeItem.map(({ h3Index }) => h3Index),
    dateStart: source.from,
    dateEnd: source.to,
    timeStartSecs: secondsParser(source.timeStart),
    timeEndSecs: secondsParser(source.timeEnd),
    weekdays: source.weekdays,
    transportType: source.transportType.value as TravelHeatmapTypes.TransportType,
    directionMode,
  };

  return payload;
};

export const payloadToJSON = <T>(payload: T) => {
  try {
    return JSON.stringify(payload);
  } catch {
    return "";
  }
};

export const getIsCorrespondence = (state: State) => state.isCorrespondenceMatrix;

export const getLoadPayload = (state: State) => {
  if (!state.isActive) return;

  return createRequestStatisticPayload(state);
};
