import React from "react";
import mapboxgl, { LngLat } from "mapbox-gl";
import TlsLightPhase from "@megapolis/traffic-light-phases";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { TLS_ID } from "map-helpers/order-layers";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { popupController, WarehousType } from "map-helpers/popups/popup-controller";
import { TlsPopup } from "../components/tls-popup";
import { CreatePopupProps } from "../types";
import { TlsLayer } from "../map-layer/tls-layer";
import { APP_ENV } from "app-env";

export class TlsController {
  private tlsIconLayer: TlsLayer;
  private tlsPhaseLayer: TlsLightPhase;
  private activeItems: number[] = [];
  private hoverItemId: number | null = null;

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    const {
      oidc: {
        user: { access_token },
      },
    } = store.getState();
    this.tlsIconLayer = new TlsLayer(map, TLS_ID, TLS_ID);
    this.tlsPhaseLayer = new TlsLightPhase(map, access_token, {
      baseUrl: APP_ENV.REACT_APP_DOMAIN_API || "",
      beforeId: TLS_ID,
    });
    this.tlsIconLayer.on("click", this.handleClick);
    this.map.on("style.load", () => {
      this.update();
      this.tlsPhaseLayer.reload();
    });
    on(EVENTS.MAP_POPUP_ENTER, this.handlePopupEnter);
    on(EVENTS.MAP_POPUP_LEAVE, this.handlePopupLeave);
    this.update();
  }

  public update = () => {
    const {
      tls: { isActive },
    } = this.store.getState();

    if (isActive) this.addLayer();
    else this.removeLayer();
  };

  private handleClick = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;
    const {
      // @ts-ignore
      properties: { Id, Num, address, Lat, Lng },
    } = feature;
    if (!Id || !Num || !address || !Lat || !Lng) return;
    const lngLat = new LngLat(Lng, Lat);
    this.createPopup({ id: Id, num: Num, address, lngLat });
    this.setActiveItems(Id);
  };

  private handlePopupEnter = ({ id, type }: { id: number; type: WarehousType }) => {
    if (type !== "tls") return;
    this.setHoverFilter(id);
    this.setFilterByActiveItems();
  };

  private handlePopupLeave = ({ type }: { id: number; type: WarehousType }) => {
    if (type !== "tls") return;
    this.setHoverFilter(null);
    this.setFilterByActiveItems();
  };

  private setActiveItems(id: number | null) {
    const nullFilter: mapboxgl.Expression = ["boolean", false];
    if (typeof id !== "number") {
      this.activeItems = [];
      this.tlsIconLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
    if (this.activeItems.includes(id)) this.activeItems = this.activeItems.filter((el) => el !== id);
    else this.activeItems.push(id);

    if (!this.activeItems.length) {
      this.tlsIconLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
    this.setFilterByActiveItems();
  }

  private setFilterByActiveItems() {
    const filter = ["any"];
    this.activeItems
      .filter((el) => el !== this.hoverItemId)
      // @ts-ignore
      .forEach((el) => filter.push(["==", ["get", "Id"], el]));
    this.tlsIconLayer.setFilterOnActiveLayer(["any", filter]);
  }

  private setHoverFilter(id: number | null) {
    const nullFilter: mapboxgl.Expression = ["boolean", false];
    if (typeof id !== "number") {
      this.hoverItemId = null;
      this.tlsIconLayer.setFilterOnHoverLayer(nullFilter);
      return;
    }
    this.hoverItemId = id;
    const filter: mapboxgl.Expression = ["==", ["get", "Id"], id];
    this.tlsIconLayer.setFilterOnHoverLayer(filter);
  }

  private addLayer() {
    const url = this.getTileUrl();
    this.tlsIconLayer.add(url);
    this.tlsPhaseLayer.show();
  }

  private removeLayer() {
    this.tlsIconLayer.remove();
    this.tlsPhaseLayer.hide();
    popupController?.removePopupByType("tls");
    this.setActiveItems(null);
  }

  private getTileUrl() {
    const filter =
      "include=assetstatus,assetstatusid,lat,lng,addresses,assetTypeId&filter=in(deleted,false) and deleted eq false&additionalFields=lat,lng";
    return `${APP_ENV.REACT_APP_DTM_WAREHOUSE_API}assettls/layerMVT/{z}/{x}/{y}?${filter}`;
  }

  private createPopup({ id, num, address, lngLat }: CreatePopupProps) {
    const {
      oidc: {
        user: { access_token: token },
      },
    } = this.store.getState();
    const containerId = `camera-${id}`;
    const onClickClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e.ctrlKey) {
        popupController?.removePopupByType("tls");
        this.setActiveItems(null);
        this.setHoverFilter(null);
      } else {
        popupController?.removePopupById(containerId);
        this.setActiveItems(id);
        this.setHoverFilter(null);
      }
    };
    const component = <TlsPopup id={id} num={num} address={address} token={token} onClickClose={onClickClose} />;
    popupController?.addPopup({ component, lngLat, id: containerId, type: "tls", itemId: id });
  }
}
