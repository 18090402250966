import { APP_ENV } from "app-env";

const getTransportTypes = async (requestInit?: RequestInit): Promise<API.CdnAPI.TransportType[]> => {
  try {
    const response = await fetch(`${APP_ENV.REACT_APP_DTM_MEDIA}transport-types/collection.json`, requestInit);
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
};

const getFavoriteRoadsDictionary = async (requestInit?: RequestInit): Promise<API.CdnAPI.FavoriteRoadsDictionary[]> => {
  try {
    const response = await fetch(`${APP_ENV.REACT_APP_DTM_MEDIA}data/favorite-roads.json`, requestInit);
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
};

export const CdnAPI = { getTransportTypes, getFavoriteRoadsDictionary };
