/**
 * Формирует правильный формат вывода температуры
 * @param from - Начальный диапазон температуры
 * @param to - Конечный диапазон температуры
 * @param isForecast - Прогноз или нет
 */
export const formatTemperature = (
  from: number | null | undefined,
  to: number | null | undefined,
  isForecast: boolean
): string | undefined => {
  let result;
  if (from && to)
    if (!isForecast) {
      result = from <= 0 ? `${from}°C` : `+${from}°C`;
    } else {
      const tempTo = to <= 0 ? `${to}°C` : `+${to}°C`;
      const tempFrom = from <= 0 ? `${from}°C` : `+${from}°C`;
      result = to !== from ? `${tempFrom}..${tempTo}` : `${tempFrom}`;
    }

  return result;
};
