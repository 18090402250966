import React from "react";
import { constants } from "../store.constants";
import { Action, Attribute } from "../store.types";

export const useSubmitSave = (id: string, dispatch: React.Dispatch<Action>) =>
  React.useCallback(
    (attribute: Attribute) =>
      dispatch({
        type: constants.SUBMIT_SAVE,
        payload: { id, attribute },
      }),
    [dispatch]
  );
