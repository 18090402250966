import React from "react";
import "./external-system-header.scss";
import { ExternalSystemIcon } from "../external-system-icon/external-system-icon";

const className = "external-system-header";

interface MenuItem {
  id: number;
}

interface Props {
  /** Выбранные пункты меню */
  selectedItems: Array<number>;
  /** Все пункты меню */
  items: Array<MenuItem>;
  /** Обработка клика по иконке */
  handleClick: (e: React.SyntheticEvent, item: number) => void;
  rowHeight: number;
}

const ExternalSystemHeader: React.FC<Props> = ({ selectedItems, items, handleClick, rowHeight }) => {
  const renderSelectedIcons = () => {
    return items.map((el) => (
      <ExternalSystemIcon
        key={el.id}
        value={el.id}
        isActive={selectedItems.includes(el.id)}
        handleClick={(e) => handleClick(e, el.id)}
      />
    ));
  };

  return (
    <div className={className} style={{ height: rowHeight }}>
      {renderSelectedIcons()}
    </div>
  );
};

export default ExternalSystemHeader;
