import React from "react";
import { AlarmIcon, FullScreenWhite, Pause } from "assets";
import moment from "moment";
import { Loader } from "components";
import { OverSpeedMessageType } from "../../help";
import {
  AdditionalPhotosBlock,
  AlarmMessage,
  ErrorMessage,
  FixationContainer,
  MainPhotoBlock,
  Message,
  Photo,
  PhotosBlock,
  PlayIcon,
  StatusBlock,
  Time,
  ToolsBlock,
} from "./fixation.styled";

type Props = {
  setIsSubscribe: (value: boolean) => void;
  setIsFullScreenMode: (value: boolean) => void;
  isSubscribe: boolean;
  fixationPhoto?: string;
  fixationTime?: string;
  overSpeedMessage: OverSpeedMessageType;
};

export const Fixation = ({
  setIsFullScreenMode,
  isSubscribe,
  setIsSubscribe,
  fixationPhoto,
  overSpeedMessage,
  fixationTime,
}: Props) => {
  const { message, isViolations } = overSpeedMessage;
  if (fixationPhoto === "No-Data") {
    return (
      <FixationContainer>
        <ErrorMessage>За последний час не было сделано ни одной фиксации</ErrorMessage>
      </FixationContainer>
    );
  }
  return (
    <FixationContainer isViolations={isViolations} className={"not-dragable-zone"}>
      {fixationPhoto && (
        <StatusBlock>
          <Message>
            {isViolations ? (
              <AlarmMessage>
                <AlarmIcon />
                <span>{message}</span>
              </AlarmMessage>
            ) : (
              <span>{message}</span>
            )}
          </Message>

          <Time>{moment(fixationTime).format("DD MMM YYYY, HH:mm")}</Time>
        </StatusBlock>
      )}
      <PhotosBlock>
        <MainPhotoBlock>
          {fixationPhoto ? <img src={fixationPhoto} alt="fixationPhoto" /> : <Loader />}
          {fixationPhoto && (
            <ToolsBlock>
              {isSubscribe ? (
                <div className={"iconBlock"}>
                  <Pause onClick={() => setIsSubscribe(false)} />
                </div>
              ) : (
                <div />
              )}
              <div className={"iconBlock"}>
                <FullScreenWhite onClick={() => setIsFullScreenMode(true)} />
              </div>
            </ToolsBlock>
          )}
          {!isSubscribe && <PlayIcon onClick={() => setIsSubscribe(true)} />}
        </MainPhotoBlock>
        <AdditionalPhotosBlock>
          {fixationPhoto && (
            <Photo isActive>
              {" "}
              <img src={fixationPhoto} alt="fixationPhoto" />
            </Photo>
          )}
        </AdditionalPhotosBlock>
      </PhotosBlock>
    </FixationContainer>
  );
};
