import { useCallback, useEffect, useMemo, useRef } from "react";
import { batch, useDispatch } from "react-redux";
import cn from "classnames";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { useSectorAnalyseContext } from "features/sector-analysis/components/sector-analysis/context";

const baseClass = "megapolis-settings";
const containerClassName = `${baseClass}__container`;
const getButtonClassName = (isActive: boolean) =>
  cn(`${baseClass}__button`, {
    [`${baseClass}__button_active`]: isActive,
  });

export const useButton = (isActive: boolean) => {
  const settingsMenuRef = useRef<HTMLDivElement>(null);
  const { settingSpeedColor } = useSectorAnalyseContext();
  const buttonClass = useMemo(() => getButtonClassName(isActive), [isActive]);
  const dispatch = useDispatch();

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === settingsMenuRef.current || settingsMenuRef.current?.contains(e.target as Node)) {
        return;
      }
      settingSpeedColor.update();

      batch(() => {
        dispatch(sectorAnalysisSlice.actions.setIsAppearanceMenu(false));
        dispatch(sectorAnalysisSlice.actions.setIsFiltersDisabled(false));
        dispatch(sectorAnalysisSlice.actions.setIsDiagramDisabled(false));
      });
    },
    [settingsMenuRef]
  );

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return {
    settingsMenuRef,
    buttonClass,
    containerClassName,
  };
};
