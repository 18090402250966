import React from "react";
import { APP_ENV } from "app-env";
import { CargoGraphButton } from "features/ctrl-graph/components";
import { TlsButton } from "features/ctrl-tls";
import { BusStopButton } from "features/ctrl-bus-stop";
import CtrlGraph from "features/ctrl-graph";
import { PedestrianGraph, TransportHubs } from "features";

export const useItems = () => {
  return React.useMemo(() => {
    const items: JSX.Element[] = [];

    if (APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.cargoGraph?.visibility === "on") {
      items.push(<CargoGraphButton key={items.length} />);
    }

    if (APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.tls?.visibility === "on") {
      items.push(<TlsButton key={items.length} />);
    }

    if (APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.busStop?.visibility === "on") {
      items.push(<BusStopButton key={items.length} />);
    }

    if (APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.transportHubs?.visibility === "on") {
      items.push(<TransportHubs key={items.length} />);
    }

    if (APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.transportGraph?.visibility === "on") {
      items.push(<CtrlGraph key={items.length} />);
    }

    if (APP_ENV.REACT_APP_CONFIGURATION?.layers?.roadNetwork?.pedestrianGraph?.visibility === "on") {
      items.push(<PedestrianGraph key={items.length} />);
    }

    return items;
  }, []);
};
