import mapboxgl from "mapbox-gl";
import * as THREE from "three";
import { ROUTE_VOLUMETRIC_DIAGRAM } from "map-helpers/order-layers";
import * as Types from "./route-transport-count.types";

export class RouteTransportCount implements mapboxgl.CustomLayerInterface {
  public id: string = ROUTE_VOLUMETRIC_DIAGRAM;
  public type: "custom" = "custom";
  public renderingMode?: "2d" | "3d" = "3d";
  private renderer?: THREE.WebGLRenderer;
  private scene?: THREE.Scene;
  private camera?: THREE.Camera;
  private world?: THREE.Group;
  private raycaster?: THREE.Raycaster;
  private cameraTransform?: THREE.Matrix4;
  private cameraCenter?: mapboxgl.MercatorCoordinate;
  private routePieces: Types.RoutePiece[] = [];
  private _visibility = false;
  private properties: { [key: string]: Types.RoutePiece } = {};

  public get visibility() {
    return this._visibility;
  }

  constructor(private map: mapboxgl.Map) {}

  public getProperties = (uuid: string) => {
    return this.properties[uuid];
  };

  public set visibility(visibility: boolean) {
    this._visibility = visibility;
    if (this._visibility) {
      this.fillScene();
    } else {
      this.world?.clear();
      this.properties = {};
    }
    this.map.triggerRepaint();
  }

  public setData = (data: RouteTransportCount["routePieces"]) => {
    this.routePieces = data;
    this.fillScene();
    this.map.triggerRepaint();
  };

  public raycast = (point: mapboxgl.Point) => {
    if (!this.scene || !this.camera || !this.raycaster) return;
    const mouse = new THREE.Vector2();

    // @ts-ignore
    mouse.x = (point.x / this.map.transform.width) * 2 - 1;
    // @ts-ignore
    mouse.y = 1 - (point.y / this.map.transform.height) * 2;

    const camInverseProjection = this.camera.projectionMatrix.clone().invert();
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, viewDirection);
    return this.raycaster.intersectObjects(this.scene.children, true);
  };

  private createLightning = () => {
    const skyColor = 0xb1e1ff; // light blue
    const groundColor = 0xb97a20; // brownish orange

    this.scene?.add(new THREE.AmbientLight(0xffffff, 0.25));
    this.scene?.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(-70, -70, 100).normalize();
    this.scene?.add(directionalLight);
  };

  private parseColor = (color: string) => {
    const hexadecimalString = color.replace("#", "");
    let decimalColor: number | null = null;
    try {
      decimalColor = parseInt(hexadecimalString, 16);
    } catch {}

    return decimalColor;
  };

  private fillScene = () => {
    if (!this.scene || !this.world || !this.cameraCenter) return;

    this.world.clear();
    this.properties = {};
    const scale = this.cameraCenter.meterInMercatorCoordinateUnits();

    for (let i = 0; i < this.routePieces?.length; i++) {
      const { start, color, length, unitsCount, bearing } = this.routePieces[i];
      const mercator = mapboxgl.MercatorCoordinate.fromLngLat(start);
      const width = length;
      const height = 20;
      const depth = unitsCount * 5;

      if (!Number.isNaN(width) && !Number.isNaN(depth)) {
        const geometry = new THREE.BoxGeometry(width, height, depth);
        const material = new THREE.MeshPhongMaterial({
          transparent: true,
          opacity: 0.5,
          color: this.parseColor(color) ?? 0x0262cc,
        });

        const dx = -(this.cameraCenter.x - mercator.x) / scale;
        const dy = (this.cameraCenter.y - mercator.y) / scale;

        const cube = new THREE.Mesh(geometry, material);

        const radius = width / 2;
        const lineAzimuth = Math.PI - bearing - Math.PI / 2;

        const x = dx + radius * Math.cos(lineAzimuth);
        const y = dy + radius * Math.sin(lineAzimuth);

        cube.translateX(x);
        cube.translateY(y);
        cube.translateZ(depth / 2);
        cube.rotateZ(lineAzimuth);
        this.properties[cube.uuid] = this.routePieces[i];

        this.world.add(cube);
      }
    }
  };

  public onAdd = (map: mapboxgl.Map, gl: WebGLRenderingContext) => {
    this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6);
    this.scene = new THREE.Scene();
    this.world = new THREE.Group();
    this.scene.add(this.world);

    this.cameraCenter = mapboxgl.MercatorCoordinate.fromLngLat(this.map.getCenter(), 0);

    const { x, y, z } = this.cameraCenter;
    this.world.scale.setScalar(this.cameraCenter.meterInMercatorCoordinateUnits());
    this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z ?? 0).scale(new THREE.Vector3(1, -1, 1));

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
    this.raycaster = new THREE.Raycaster();

    this.createLightning();

    if (this.routePieces.length) {
      this.fillScene();
    }
    this.map.triggerRepaint();
  };

  public render = (gl: WebGLRenderingContext, matrix: number[]) => {
    if (!this.renderer || !this.scene || !this.camera || !this.cameraTransform) return;

    this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
  };
}
