import React, { memo } from "react";
import { CloseButton } from "components";

import "./map-popup.scss";

type Props = {
  titleUrl?: string;
  title: string;
  address?: string;
  onClickClose: React.MouseEventHandler<HTMLButtonElement>;
};

const baseClass = "new-react-map-popup";
const titleClassName = `${baseClass}__title`;
const buttonsClassName = `${baseClass}__buttons`;
const addressClassName = `${baseClass}__address`;
const popupBodyClassName = `${baseClass}__body`;

export const MapPopup: React.FC<Props> = memo(({ titleUrl, title, address, children, onClickClose }) => {
  return (
    <div className={baseClass}>
      {titleUrl ? (
        <div>{title}</div>
      ) : (
        <a
          className={titleClassName}
          href={titleUrl}
          target="_blank"
          rel="noopener noreferrer"
          draggable={false}
          title={title}>
          {title}
        </a>
      )}
      <div className={buttonsClassName}>
        <CloseButton onClick={onClickClose} />
      </div>
      {address && <div className={addressClassName}>{address}</div>}
      <div className={popupBodyClassName}>{children}</div>
    </div>
  );
});
