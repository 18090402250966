import React, { useMemo } from "react";
import moment from "moment";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { AnalysisDiagramData } from "api/router/model/analysisResponse";
import { AnalysisType } from "features/sector-analysis/types";
import { CloseButton } from "components/close-button";

import "./sector-details-tooltip.scss";

interface Props {
  /** Дата начала текущего */
  currentDateFrom: string;
  /** Дата окончания текущего */
  currentDateTo: string;
  /** Дата начала сравнения */
  compareDateFrom: string;
  /** Дата окончания сравнения */
  compareDateTo: string;
  /** Идентификатор шаблона */
  templateId: string | number;
  /** Тип окна */
  type: AnalysisType;
  /** Данные в текущем окне */
  data: AnalysisDiagramData | null;
  /** Данные для сравнения */
  compare: AnalysisDiagramData | null;
  /** Ключи дат сортированные в порядке возрастания */
  dateKeys: string[];
  /** Номер строки */
  column: number;
  /** Номер колонки */
  row: number;
  /** Обработчик закрытия */
  onCloseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const baseClass = "sector-details-tooltip";

const ranges: { name: string; values: (d: number) => boolean }[] = [
  {
    name: "Восток",
    values: (d) => d >= 94 || d <= 14,
  },
  {
    name: "Запад",
    values: (d) => d >= 45 && d <= 74,
  },
  {
    name: "Север",
    values: (d) => d >= 75 && d <= 95,
  },
  {
    name: "Юг",
    values: (d) => d >= 15 && d <= 44,
  },
];

const useGetDirections = (props: Props) => {
  const { data, dateKeys, column, compare, templateId } = props;
  const columnKey = dateKeys[column];

  let jamLength = 0;
  let jamPercentage = 0;

  let jamChange = "0";

  let directions: { name: string; jamLength: number; jamChange: string }[] = [];

  if (data && compare) {
    const dataRows = templateId === 1 ? data.rows : [...data.rows].reverse();

    const rowCount = dataRows.length;
    const values = dataRows.map((r) => (r.cells ? r.cells[columnKey]?.trafficCategory : 0));
    const jamsCount = values.filter((v) => v === 3 || v === 4).length;
    jamLength = Math.round(jamsCount / 2);
    jamPercentage = Math.round((jamsCount * 100) / rowCount);

    const compareRows = templateId === 1 ? compare.rows : [...compare.rows].reverse();

    const compareValues = compareRows.map((r) => (r.cells ? r.cells[columnKey]?.trafficCategory : 0));
    const compareJamsCount = compareValues.filter((v) => v === 3 || v === 4).length;
    const compareJamPercentage = Math.round((compareJamsCount * 100) / rowCount);

    const change = jamPercentage - compareJamPercentage;
    jamChange = change > 0 ? `+${change}` : `${change}`;

    directions = ranges.map((range) => {
      const vals = dataRows
        .filter((r, index) => range.values(index / 2))
        .map((r) => (r.cells ? r.cells[columnKey]?.trafficCategory : 0));
      const jc = vals.filter((v) => v === 3 || v === 4).length;
      const jl = Math.round(jc / 2);
      const jp = Math.round((jc * 100) / rowCount);

      const cvals = compareRows
        .filter((r, index) => range.values(index / 2))
        .map((r) => (r.cells ? r.cells[columnKey]?.trafficCategory : 0));
      const cjc = cvals.filter((v) => v === 3 || v === 4).length;
      const cjp = Math.round((cjc * 100) / rowCount);

      const change = jp - cjp;
      const jamChange = change > 0 ? `+${change}` : `${change}`;

      return {
        name: range.name,
        jamLength: jl,
        jamChange,
      };
    });
  }

  return { directions, jamPercentage, jamLength, jamChange };
};

const useGetDateTimePeriod = (props: Props) => {
  const { dateKeys, column } = props;

  return useMemo(() => {
    if (!dateKeys) return "н/д";

    let dateFrom = dateKeys[column];
    let dateTo = dateKeys[column + 1] ? dateKeys[column + 1] : dateKeys[0];

    if (column === dateKeys.length) {
      dateFrom = dateKeys[0];
      dateTo = dateKeys[1];
    }

    if (!dateFrom || !dateTo) return "н/д";

    return `${moment(dateFrom).format("HH:mm")} - ${moment(dateTo).format("HH:mm")}`;
  }, [dateKeys, column]);
};

const getDateToView = (from: string, to: string) => {
  const fromStr = moment(from).format("DD.MM.YYYY");
  const toStr = moment(to).subtract(1, "day").format("DD.MM.YYYY");
  if (fromStr === toStr) return fromStr;
  return `${fromStr} - ${toStr}`;
};

const useGetDateToView = (props: Props) => {
  const { currentDateFrom, currentDateTo } = props;
  const { compareDateFrom, compareDateTo } = props;
  return {
    currentDate: getDateToView(currentDateFrom, currentDateTo),
    compareDate: getDateToView(compareDateFrom, compareDateTo),
  };
};

export const DetailsTooltip = (props: Props) => {
  const { onCloseClick, templateId } = props;
  const dateTimePeriod = useGetDateTimePeriod(props);
  const { directions, jamPercentage, jamLength, jamChange } = useGetDirections(props);
  const { currentDate, compareDate } = useGetDateToView(props);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__header`}>
        <div className={`${baseClass}__icon`}>
          <TimeIcon className={`${baseClass}__time-icon`} />
        </div>
        <span className={`${baseClass}__title`}>{dateTimePeriod}</span>
        <CloseButton className={`${baseClass}__close`} onClick={onCloseClick} />
      </div>

      <div className={`${baseClass}__period`}>
        <div className={`${baseClass}__row`}>
          <span className={`${baseClass}__name`}>просмотр</span>
          <span className={`${baseClass}__value`}>{currentDate}</span>
        </div>
        <div className={`${baseClass}__row`}>
          <span className={`${baseClass}__name`}>отклонение от</span>
          <span className={`${baseClass}__value`}>{compareDate}</span>
        </div>
      </div>

      <div className={`${baseClass}__info`}>
        <div className={`${baseClass}__template`}>МКАД {templateId === 1 ? "внутренняя" : "внешняя"} сторона</div>
        <div className={`${baseClass}__row`}>
          <span className={`${baseClass}__name`}>Общая длина</span>
          <span className={`${baseClass}__value`}>108,9 км</span>
        </div>
      </div>

      <div className={`${baseClass}__statistic`}>
        <div className={`${baseClass}__item ${baseClass}__item_full`}>
          <span className={`${baseClass}__label`}>Длина заторов</span>
          <span className={`${baseClass}__total`}>{jamPercentage}%, </span>
          <span className={`${baseClass}__length`}>{jamLength} км</span>
          <span className={`${baseClass}__percentage`}>({jamChange}%)</span>
        </div>
        {directions.map((dir, index) => (
          <div key={index} className={`${baseClass}__item ${baseClass}__item`}>
            <span className={`${baseClass}__label ${baseClass}__name`}>{dir.name}</span>
            <span className={`${baseClass}__total`} />
            <span className={`${baseClass}__length`}>{dir.jamLength} км</span>
            <span className={`${baseClass}__percentage`}>({dir.jamChange}%)</span>
          </div>
        ))}
      </div>
    </div>
  );
};
