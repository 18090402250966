import React from "react";
import type { Props } from "./item.types";
import "./item.scss";

export const Item = React.memo<Props>((props) => {
  const url = props.isActive ? props.iconActiveUrl : props.iconNormalUrl;

  return (
    <button disabled={props.isDisabled} title={props.title} onClick={props.onClick} className="item">
      <img src={url} alt={props.title} />
    </button>
  );
});
