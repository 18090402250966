import { RouteResponse } from "features/ctrl-route/types";

export function getDifferenceTime(variant: RouteResponse, routeVariants: Array<RouteResponse>) {
  const itemTime =
    variant.original.trip.summary.time > variant.original.trip.summary.compare_time
      ? variant.original.trip.summary.time
      : variant.original.trip.summary.compare_time;

  const compareVariant = routeVariants.find(
    (el) => el.original.trip.summary.time !== variant.original.trip.summary.time
  );

  if (compareVariant) {
    const compareTime =
      compareVariant.original.trip.summary.time > compareVariant.original.trip.summary.compare_time
        ? compareVariant.original.trip.summary.time
        : compareVariant.original.trip.summary.compare_time;

    const differenceTime = compareTime - itemTime;

    return differenceTime;
  }

  return 0;
}
