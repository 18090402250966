import { GraphRender } from "./graph-render";
import { AnalysisDiagramOptions, DiagramEvent } from "features/sector-analysis/types";
import { Dispatcher } from "../dispatcher";
import * as consts from "../utils/consts";

export class GraphController extends GraphRender {
  private dispatcher = new Dispatcher();

  constructor(canvas: HTMLCanvasElement, options: AnalysisDiagramOptions) {
    super(canvas, options);

    this.initActions();
  }

  private handleMouseMove = (e: MouseEvent) => {
    const { x } = this.getXY(e);

    this.draw();
    this.drawCrosshair(x);
    this.dispatcher.fire(DiagramEvent.SYNC_GRAPH_CROSSHAIR, this.options.type, x);
  };

  private syncCrosshair = (type: string, x: number) => {
    if (this.options.type === type) return;

    this.draw();
    this.drawCrosshair(x);
  };

  private handleClick = (e: MouseEvent) => {
    const { x } = this.getXY(e);
    const { column } = this.getColumn(x);
    const rectangleX = this.bodyColumnWidth * column + this.offset + consts.bodyLeft;
    this.dispatcher.fire(DiagramEvent.GRAPH_CLICK, { rectangleX, column });
  };

  private handleResize = () => {
    this.initCtx(this.canvas);
    this.draw();
  };

  private getColumn = (x: number) => {
    const { dateKeys } = this.options;
    const column = Math.floor(((x - this.bodyLeftWithOffset) / this.scaledBodyWidth) * dateKeys.length);
    return { column };
  };

  private handleMouseLeave = () => {
    this.draw();
    this.dispatcher.fire(DiagramEvent.LEAVE_GRAPH, this.options.type);
  };

  private handleClearCrosshair = (type: string) => {
    if (this.options.type === type) return;

    this.draw();
  };

  private initActions = () => {
    this.canvas.addEventListener("resize", this.handleResize);
    this.canvas.addEventListener("mousemove", this.handleMouseMove);
    this.canvas.addEventListener("mouseleave", this.draw);
    this.canvas.addEventListener("click", this.handleClick);
    this.canvas.addEventListener("mouseleave", this.handleMouseLeave);
    this.dispatcher.on(DiagramEvent.SYNC_GRAPH_CROSSHAIR, this.syncCrosshair);
    this.dispatcher.on(DiagramEvent.LEAVE_GRAPH, this.handleClearCrosshair);
  };

  public readonly destroy = () => {
    this.canvas.removeEventListener("resize", this.handleResize);
    this.canvas.removeEventListener("mousemove", this.handleMouseMove);
    this.canvas.removeEventListener("mouseleave", this.draw);
    this.canvas.removeEventListener("click", this.handleClick);
    this.dispatcher.off(DiagramEvent.SYNC_GRAPH_CROSSHAIR, this.syncCrosshair);
    this.dispatcher.off(DiagramEvent.LEAVE_GRAPH, this.handleClearCrosshair);
  };
}
