import { memo, useCallback, useMemo, useRef } from "react";
import { Range } from "rc-slider";
import * as hooks from "./time-range.hooks";
import * as consts from "./time-slider.consts";
import "./time-slider.scss";

type Props = {
  isDisabled?: boolean;
  value: {
    min: number;
    max: number;
  };
  onChange: (value: Props["value"]) => void;
};

export const TimeSlider = memo<Props>(({ value, onChange, isDisabled = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const width = hooks.useWidth(containerRef);
  const _value = useMemo(() => [value.min, value.max], [value]);
  const labels = hooks.useLabels(_value, width, isDisabled);

  const handleChange = useCallback(
    (value: number[]) => {
      onChange({
        min: value[0],
        max: value[1],
      });
    },
    [onChange]
  );

  return (
    <div ref={containerRef} className={consts.baseClass}>
      <Range
        disabled={isDisabled}
        value={_value}
        min={consts.min}
        max={consts.max}
        step={consts.step}
        allowCross={false}
        dots={true}
        onChange={handleChange}
      />
      {width && labels}
    </div>
  );
});
