import { createEffect } from "effector";
import { shared } from "shared";
import { AdministrativeDivisionAPI } from "api";
import { AdministrativeDivisionTypes } from "types";
import { State } from "./administrative-division.types";

const loadDistricts = createEffect(async () => {
  if (!shared.global.accessToken.value) return;

  const api = new AdministrativeDivisionAPI();
  const districtsFeatureCollection = await api.getDistricts();
  return districtsFeatureCollection?.features;
});

const loadDistrictZones = createEffect(async () => {
  if (!shared.global.accessToken.value) return;

  const api = new AdministrativeDivisionAPI();
  const districtZonesFeatureCollection = await api.getDistrictsZones();
  return districtZonesFeatureCollection?.features;
});

const loadTlsCounts = createEffect(async () => {
  if (!shared.global.accessToken.value) return;
  return new AdministrativeDivisionAPI().getDistrictAssetTlsCount();
});

const loadRoadSignCount = createEffect(async () => {
  if (!shared.global.accessToken.value) return;
  return new AdministrativeDivisionAPI().getDistrictAssetRoadSignCount();
});

const loadSpeedCamCount = createEffect(async () => {
  if (!shared.global.accessToken.value) return;
  return new AdministrativeDivisionAPI().getDistrictAssetSpeedCamCount();
});

const searchPredicate =
  (name: string) =>
  ({ districtName }: AdministrativeDivisionTypes.DistrictAssetCount) => {
    return districtName === name;
  };

const loadStatistic = createEffect(
  async (params: { area: AdministrativeDivisionTypes.MissingStatisticPayload; state: State }) => {
    if (!shared.global.accessToken.value) return;

    const { area, state } = params;
    const { id, name, fullName } = area;
    const { districtTlsCount, districtRoadSignCount, districtSpeedCamCount } = state;
    const api = new AdministrativeDivisionAPI();
    const roadsInfo = await api.getRoads(id);
    const stats = await api.getStatistics(id);
    const tlsCount = districtTlsCount?.find(searchPredicate(name))?.assetCount ?? 0;
    const roadSignCount = districtRoadSignCount?.find(searchPredicate(name))?.assetCount ?? 0;
    const speedCamCount = districtSpeedCamCount?.find(searchPredicate(name))?.assetCount ?? 0;

    const statistic: AdministrativeDivisionTypes.Statistic = {
      id,
      name,
      fullName,
      ...roadsInfo,
      trafficScore: stats?.score?.score,
      trafficIndex: stats?.alternativeScore?.score,
      carCount: stats?.score?.scoreParams?.unitsCount,
      tlsCount,
      roadSignCount,
      speedCamCount,
    };

    return statistic;
  }
);

export const effects = {
  loadDistricts,
  loadDistrictZones,
  loadStatistic,
  loadTlsCounts,
  loadRoadSignCount,
  loadSpeedCamCount,
};

export type Effects = typeof effects;
