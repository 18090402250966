import { includeParams } from "api/utils";

export const paths = {
  districts: "tiles/adminareas_geometry",
  districtsZonesPath: "tiles/districts_geometry",
  statistic: "scoring/adminAreaScore",
  roads: "tiles/streets",
};

export const getStatisticParams = (osmId: number) => [
  { key: "adminAreaOsmId", value: String(osmId) },
  { key: "period", value: "last" },
];

export const getRoadsParams = (osmId: number) => [{ key: "areaOsmId", value: String(osmId) }];
export const getStatisticsPath = (osmId: number) => includeParams(paths.statistic)(getStatisticParams(osmId));
export const getRoadsPath = (osmId: number) => includeParams(paths.roads)(getRoadsParams(osmId));
