import * as observer from "../observer";

export class PageEvents {
  private static instance?: PageEvents;
  private static prevWidth?: number;

  constructor() {
    if (PageEvents.instance) return PageEvents.instance;
    PageEvents.instance = this;
  }

  private handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== "Escape") return;

    observer.dispatch(observer.EVENTS.RESTORE_DIAGRAM_ZOOM);
  };

  private handleResize = () => {
    const { innerWidth } = window;

    if (PageEvents.prevWidth === innerWidth) return;

    PageEvents.prevWidth = innerWidth;
    observer.dispatch(observer.EVENTS.WINDOW_RESIZE, innerWidth);
  };

  public subscribe = () => {
    document.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("resize", this.handleResize);
  };

  public unsubscribe = () => {
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("resize", this.handleResize);
  };
}
