/* eslint-disable no-useless-escape */
function isInteger(number: number) {
  return (number ^ 0) === number;
}

// function cutZeros(number: number) {
//   const string = String(number);
//   const lastChar = string[string.length];
//   let zeroCounts = string.length;
//   while (lastChar === '0') zeroCounts--;
//   const result = string.substring(0, zeroCounts);
//   return Number(result);
// }
//

function firstSignificantDigits(value: number) {
  return value.toExponential().replace(/^([0-9]+)\.?([0-9]+)?e[\+\-0-9]*$/g, "$1$2")[0];
}

function getBasePrecision(number: number) {
  const abs = number.toFixed(0);
  return String(abs).length;
}

export function preciseNumber(number: number, precision?: number) {
  let _precision = 2;

  if (firstSignificantDigits(number) === "1") _precision = 3;

  if (isInteger(Number(number.toPrecision(_precision)))) _precision = getBasePrecision(number);

  if (typeof precision === "number") _precision = precision;

  const result = number.toPrecision(_precision);

  return Number(result);
}
