import React from "react";
import * as consts from "./heading.consts";
import "./heading.scss";

export const Heading = React.memo((props) => {
  return (
    <div className={consts.baseClass}>
      <h2 className={consts.headingClass}>{props.children}</h2>
    </div>
  );
});
