export const transformCardDataForEdgeInfo = (data: Record<string, string>, groupLabel: string) => {
  const group: { groupLabel: string; items: { label: string; value: string }[] } = {
    groupLabel,
    items: [],
  };
  for (const dataKey in data) {
    if (dataKey === "osmWayID") group.items.push({ label: "OSM Way ID", value: data["osmWayID"] });
    if (dataKey === "osmWayVersion") group.items.push({ label: "OSM Way version", value: data["osmWayVersion"] });
    if (dataKey === "edgeID") group.items.push({ label: "ID ребра", value: data["edgeID"] });
    if (dataKey === "persistentEdgeId") group.items.push({ label: "ID ребра 2", value: data["persistentEdgeId"] });
    if (dataKey === "fromNode") group.items.push({ label: "Из узла", value: data["fromNode"] });
    if (dataKey === "toNode") group.items.push({ label: "В узел", value: data["toNode"] });
    if (dataKey === "toNode") group.items.push({ label: "Длина, м", value: data["length"] });
  }

  return group;
};
