/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { List } from "react-virtualized";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import { RouterAPI } from "api/router";
import { formatTimeDuration } from "utils/format-time-duration";
import { formatLengthRoute } from "utils/format-length-route";
import { RouteCosting, TrafficUse } from "api/router/model/router";
import {
  getRouteDistributionWidth,
  getDistributionByLength,
} from "../route-variants/helpers/get-route-distribution-width";
import RouteTimeVariantsTabs from "./route-time-variants-tabs/route-time-variants-tabs";
import RouteTimeVariantsSelect from "./route-time-variants-select/route-time-variants-select";
import RouteTimeVariantsItem from "./route-time-variants-item/route-time-variants-item";
import "./route-time-variants.scss";

const className = "route-time-variants";

function round(date, duration, method) {
  return moment(Math[method](Number(date) / Number(duration)) * Number(duration));
}

class RouteTimeVariants extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      variants: [],
    };
  }

  componentDidMount() {
    this.getVariants();
  }

  componentDidUpdate(prevProps) {
    const { isRouteTimeVariantsOpen, period, selectedTime, timeStatus, timeInterval, activeIndexRoute } = this.props;
    if (isRouteTimeVariantsOpen) {
      if (period !== prevProps.period) {
        this.getVariants();
        if (this.list) {
          this.list.forceUpdateGrid();
        }
      }
      if (timeStatus !== prevProps.timeStatus) {
        this.getVariants();
        if (this.list) {
          this.list.forceUpdateGrid();
        }
      }
      if (timeInterval !== prevProps.timeInterval) {
        this.updateTimeTo();
        this.updateWidth();
        if (this.list) {
          this.list.forceUpdateGrid();
        }
      }
      if (activeIndexRoute !== prevProps.activeIndexRoute && this.list) {
        this.list.forceUpdateGrid();
      }
    }
    if (selectedTime !== prevProps.selectedTime) {
      this.getVariants();
      if (this.list) {
        this.list.forceUpdateGrid();
      }
    }
  }

  getVariants = () => {
    const { period, selectedTime, timeStatus } = this.props;
    let startTime = round(moment(), moment.duration(5, "minutes"), "ceil");
    if (timeStatus !== "last") {
      startTime = selectedTime;
    }

    const variants = [];
    let start = moment(startTime).subtract(20 * period, "minute");
    const startFrom = moment(startTime).subtract(20 * period, "minute");
    for (let i = 0; i < 40; i++) {
      const timeFrom = moment(startFrom)
        .add(period * i, "minute")
        .format("HH:mm");
      const timeTo = null;
      const from = moment(start).toISOString(true);
      const to = moment(start).add(period, "minute").toISOString(true);
      variants.push({
        timeTo,
        timeFrom,
        data: null,
        width: 1,
        isActive: false,
        id: i,
        from,
        to,
        isFetching: false,
      });
      start = to;
    }
    this.setState({ variants }, () => {
      if (this.list) {
        this.list.forceUpdateGrid();
      }
    });
  };

  getWidth(result) {
    const { variants } = this.state;
    const { timeInterval } = this.props;
    let width = 1;

    const dataVariants = variants
      .map((el) => {
        return el.data;
      })
      .filter((el) => el);

    if (result) {
      if (timeInterval === "length") {
        width = getDistributionByLength(result, dataVariants);
      } else {
        width = getRouteDistributionWidth(result, dataVariants);
      }
    }
    return width;
  }

  getTimeTo(result, from) {
    let timeTo;
    if (result) {
      const { timeInterval } = this.props;
      if (result) {
        const length = result.length;
        const seconds = result.time;
        if (timeInterval === "time") {
          timeTo = formatTimeDuration(seconds);
        } else if (timeInterval === "arrival") {
          timeTo = moment(from).add(seconds, "second").format("HH:mm");
        } else if (timeInterval === "length") {
          timeTo = formatLengthRoute(length);
        }
      }
    }
    return timeTo;
  }

  updateTimeTo = () => {
    const { variants } = this.state;
    const newVariants = variants.map((el) => {
      el.timeTo = this.getTimeTo(el.data, el.from);
      return el;
    });
    this.setState({ variants: newVariants }, () => {
      if (this.list) {
        this.list.forceUpdateGrid();
      }
    });
  };

  updateWidth = () => {
    const { variants } = this.state;
    const newVariants = variants.map((el) => {
      el.width = this.getWidth(el.data);
      return el;
    });
    this.setState({ variants: newVariants }, () => {
      if (this.list) {
        this.list.forceUpdateGrid();
      }
    });
  };

  getRoute = (from, to, id) => {
    const { es, activeIndexRoute, routeVariants, costing } = this.props;
    const { variants } = this.state;

    const activeRoute = routeVariants[activeIndexRoute];

    if (!activeRoute || !to) return;

    const data = {
      routeID: activeRoute.routeID,
      trafficTime: to,
      trafficTypes: {
        externalSystemIDs: es,
      },
      trafficUse: TrafficUse.Routing,
      costing: costing === RouteCosting.Multimodal ? costing : RouteCosting.Auto,
    };

    this.setState({
      variants: variants.map((el) => {
        if (el.id === id) {
          el.isFetching = true;
        }
        return el;
      }),
    });

    RouterAPI.router.time(data).then((result) => {
      /** Реверсим направления слоев */
      result.paintedPath.features.reverse();
      this.setState(
        {
          variants: variants.map((el) => {
            if (el.id === id) {
              el.data = result;
              el.timeTo = this.getTimeTo(result, el.from);
              el.width = this.getWidth(result);
            }
            return el;
          }),
        },
        () => {
          if (this.list) {
            this.list.forceUpdateGrid();
          }
        }
      );
    });
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    const { variants } = this.state;
    const { timeInterval } = this.props;
    const item = variants[index];
    const { from, to, id, data, isFetching } = item;

    if (!data && !isFetching) {
      this.getRoute(from, to, id);
    } else {
      this.setState({
        variants: variants.map((el) => {
          if (el.id === id) {
            el.timeTo = this.getTimeTo(data, from);
            el.width = this.getWidth(data);
          }
          return el;
        }),
      });
    }

    return (
      <div key={key} style={style}>
        <RouteTimeVariantsItem
          key={index}
          data={item.data}
          timeFrom={item.timeFrom}
          timeTo={item.timeTo}
          width={item.width}
          isActive={item.isActive}
          handleClick={() => this.changeActive(item.id)}
          timeInterval={timeInterval}
          isBold={index === 20}
        />
      </div>
    );
  };

  toggleOpen = () => {
    const { isRouteTimeVariantsOpen, setVariant } = this.props;
    const { variants } = this.state;
    if (isRouteTimeVariantsOpen) setVariant(null);
    if (!isRouteTimeVariantsOpen) this.props.fetchRouteWithSaving();

    this.setState({
      variants: variants.map((el) => {
        el.isActive = false;
        return el;
      }),
    });

    this.props.setIsRouteTimeVariantsOpen(!isRouteTimeVariantsOpen);
  };

  changeActive = (id) => {
    const { variants } = this.state;
    const { setVariant } = this.props;
    let data = null;
    const newVariants = variants.map((el) => {
      if (el.id === id) {
        data = el.data;
        el.isActive = true;
      } else {
        el.isActive = false;
      }

      return el;
    });
    this.setState({ variants: newVariants }, () => {
      if (this.list) {
        this.list.forceUpdateGrid();
      }
    });
    setVariant(data);
  };

  render() {
    const { variants } = this.state;
    const { isRouteTimeVariantsOpen, timeInterval, changeTimeInterval, isDisabled, isRouteLoading } = this.props;

    return (
      <>
        {!isDisabled && (
          <div className={className}>
            {!isRouteTimeVariantsOpen && (
              <div className={`${className}-header`} onClick={this.toggleOpen}>
                <button className={`${className}-button`} type="button">
                  <span className={`${className}-label`}>Варианты времени отправления</span>
                  <i className="dtm-arrow-down-icon" />
                </button>
              </div>
            )}
            {!isRouteLoading && isRouteTimeVariantsOpen && (
              <div className={`${className}-body`}>
                <div className={`${className}-body-header`}>
                  <div className={`${className}-interval`}>
                    <span>Интервал:</span>
                    <RouteTimeVariantsSelect />
                  </div>
                  <button type="button" className={`${className}-button`} onClick={this.toggleOpen}>
                    <span className={`${className}-label`}>Свернуть</span>
                  </button>
                </div>
                <RouteTimeVariantsTabs type={timeInterval} handleChangeValue={changeTimeInterval} />
                <List
                  overscanRowCount={0}
                  width={325}
                  height={288}
                  rowCount={variants.length}
                  scrollToIndex={30}
                  rowHeight={24}
                  rowRenderer={this.rowRenderer}
                  ref={(node) => {
                    this.list = node;
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    timeInterval: state.router.typeVariant,
    es: state.view.es,
    points: state.router.points,
    period: state.router.period,
    selectedTime: state.view.selectedDay,
    timeStatus: state.router.timeStatus,
    routeVariants: state.router.routeVariants,
    activeIndexRoute: state.router.activeIndexRoute,
    costing: state.router.costing,
    isDisabled: state.router.isDisabled,
    isRouteLoading: state.router.routeIsLoading,
    isRouteTimeVariantsOpen: state.router.isRouteTimeVariantsOpen,
  }),
  (dispatch) => ({
    changeTimeInterval: (data) => dispatch(ROUTE_SLICE.setTypeVariant(data)),
    setVariant: (data) => dispatch(ROUTE_SLICE.setRouteTimeVariant(data)),
    fetchRouteWithSaving: () => dispatch(ROUTE_SLICE.fetchRouteWithSaving()),
    setIsRouteTimeVariantsOpen: (value) => dispatch(ROUTE_SLICE.setIsRouteTimeVariantsOpen(value)),
  })
)(RouteTimeVariants);
