import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { RouteResponse } from "features/ctrl-route/types";
import { ReactComponent as PedestrianIcon } from "assets/icons/pedestrian.svg";
import { formatLengthRoute } from "utils/format-length-route";
import { getTimeRoute } from "utils/route-helpers";
import RouteDestinationTime from "../../../common-components/route-destination-time/route-destination-time";
import { routerSlice } from "features/ctrl-route/store/slice";

import "./route-variant-pedestrian.scss";

type Props = {
  variant: RouteResponse;
};

const baseClass = "route-variant-pedestrian";

export const RouteVariantPedestrian = memo<Props>(({ variant }) => {
  const dispatch = useDispatch();
  const length = useMemo(() => formatLengthRoute(variant.length), [variant.length]);
  const timeRoute = useMemo(() => getTimeRoute(variant), [variant]);
  const handleClickManeuvers = () => {
    dispatch(routerSlice.actions.setIsShowManeuvers(true));
  };
  return (
    <div className={baseClass}>
      <div className={`${baseClass}__icon`}>
        <PedestrianIcon />
      </div>
      <div className={`${baseClass}__info-container`}>
        <div className={`${baseClass}__row`}>
          <div className={`${baseClass}__row__length-time`}>
            <span className={`${baseClass}__length`}>{length}</span>
            <span className={`${baseClass}__time`}>{timeRoute.withTraffic}</span>
          </div>
          <RouteDestinationTime variant={variant} />
        </div>
        <div className={`${baseClass}__row`}>
          <button className={`${baseClass}__maneuvers`} type="button" onClick={handleClickManeuvers}>
            Посмотреть по маневрам
          </button>
        </div>
      </div>
    </div>
  );
});
