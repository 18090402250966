/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */

import { Middleware, MiddlewareAPI } from "redux";
import moment from "moment";
import { createAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { GState } from "documentations";
import { CHANGE_SELECTED_DAY, ES_UPDATE_SELECTED } from "old-store/view/action-types";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import SectorAnalysisMapController from "../controller/sector-analysis-map-controller";
import { sectorAnalysisSlice } from "./sector-analysis-slice";
import { fetchCurrentData, fetchCompareData } from "./sector-analysis-utils";

export const SECTOR_ANALYSIS_GET_CURRENT_DATA = createAction("SECTOR_ANALYSIS_GET_CURRENT_DATA");
export const SECTOR_ANALYSIS_GET_COMPARE_DATA = createAction("SECTOR_ANALYSIS_GET_COMPARE_DATA");
export const SECTOR_ANALYSIS_MAP_DRAW_PATH = createAction<number>("SECTOR_ANALYSIS_MAP_DRAW_PATH");
export const SECTOR_ANALYSIS_MAP_REMOVE_PATH = createAction("SECTOR_ANALYSIS_MAP_REMOVE_PATH");

let sectorAnalysisMapController: SectorAnalysisMapController | null;

let _store: MiddlewareAPI<any, GState>;

on(EVENTS.INIT_MAP, (map: mapboxgl.Map) => {
  sectorAnalysisMapController = new SectorAnalysisMapController(map, _store);
});

export const sectorAnalysisMiddleware: Middleware<any, GState> = (store) => (next) => async (action) => {
  const result = next(action);

  if (!_store) _store = store;

  const syncDate = (selectedDay: string) => {
    const {
      sectorAnalysis: { isShowCompare },
      router: { timeStatus },
    }: GState = store.getState();

    if (timeStatus !== "last")
      batch(() => {
        store.dispatch(
          sectorAnalysisSlice.actions.setCurrentRangeDate({
            dateFrom: moment(selectedDay).startOf("day").toISOString(true),
            dateTo: moment(selectedDay).add(1, "day").startOf("day").toISOString(true),
          })
        );
        if (!isShowCompare)
          store.dispatch(
            sectorAnalysisSlice.actions.setCompareRangeDate({
              dateFrom: moment(selectedDay).startOf("day").toISOString(true),
              dateTo: moment(selectedDay).add(1, "day").startOf("day").toISOString(true),
            })
          );
      });
  };

  if (ROUTE_SLICE.setRouteIsLoading.match(action)) {
    if (action.payload) {
      store.dispatch(sectorAnalysisSlice.actions.setIsShowEvents(false));
    }
  }

  if (action.type === ES_UPDATE_SELECTED) {
    const {
      sectorAnalysis: { isActive },
    } = store.getState();

    if (!isActive)
      batch(() => {
        store.dispatch(sectorAnalysisSlice.actions.setCompareEs(action.payload));
        store.dispatch(sectorAnalysisSlice.actions.setCurrentEs(action.payload));
        store.dispatch(sectorAnalysisSlice.actions.setDefaultCurrentData());
        store.dispatch(sectorAnalysisSlice.actions.setDefaultCompareData());
      });
  }

  if (ROUTE_SLICE.setIsActive.match(action))
    if (!action.payload) store.dispatch(sectorAnalysisSlice.actions.setIsActive(false));

  if (SECTOR_ANALYSIS_GET_CURRENT_DATA.match(action)) fetchCurrentData(store);

  if (SECTOR_ANALYSIS_GET_COMPARE_DATA.match(action)) fetchCompareData(store);

  if (sectorAnalysisSlice.actions.setCurrentRangeDate.match(action)) fetchCurrentData(store);

  if (sectorAnalysisSlice.actions.setCompareRangeDate.match(action)) fetchCompareData(store);

  if (sectorAnalysisSlice.actions.setCurrentDays.match(action)) fetchCurrentData(store);

  if (sectorAnalysisSlice.actions.setCompareDays.match(action)) fetchCompareData(store);

  if (sectorAnalysisSlice.actions.setCurrentEs.match(action)) fetchCurrentData(store);

  if (sectorAnalysisSlice.actions.setCompareEs.match(action)) fetchCompareData(store);

  if (ROUTE_SLICE.setRouteVariants.match(action)) {
    const {
      sectorAnalysis: { isActive },
    } = store.getState();

    if (!isActive)
      batch(() => {
        store.dispatch(sectorAnalysisSlice.actions.setDefaultCurrentData());
        store.dispatch(sectorAnalysisSlice.actions.setDefaultCompareData());
      });

    fetchCurrentData(store);
    fetchCompareData(store);
  }

  if (ROUTE_SLICE.setActiveIndexRoute.match(action)) {
    const {
      sectorAnalysis: { isActive },
    } = store.getState();

    if (!isActive)
      batch(() => {
        store.dispatch(sectorAnalysisSlice.actions.setDefaultCurrentData());
        store.dispatch(sectorAnalysisSlice.actions.setDefaultCompareData());
      });

    fetchCurrentData(store);
    fetchCompareData(store);
  }

  if (action.type === CHANGE_SELECTED_DAY) syncDate(action.payload);

  return result;
};
