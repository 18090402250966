import styled from "styled-components";
import { APP_ENV } from "app-env";
import React from "react";

const cdn = APP_ENV.REACT_APP_DTM_MEDIA.split("integration")[0];

export const PopupStyledContainer = styled(React.Fragment)`
  .tls-popup {
    width: 300px;

    &__title {
      color: var(--link-color-default);
      font-size: 15px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 270px;
      display: block;
      width: fit-content;
    }

    &__description {
      color: var(--text-color-main);
      font-size: 13px;
      padding: 5px 0;
    }

    &-button {
      background-color: var(--background-color-active);
      color: var(--background-color-default);
      cursor: pointer;
      line-height: 32px;
      border: none;
      border-radius: 4px;
      padding-left: 7px;
      padding-right: 7px;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      &-active {
        opacity: 1;
      }
    }

    &-tabs {
      padding-bottom: 10px;
    }

    &-placeholder {
      height: 250px;
      background: url(${cdn + "image/loader.gif"});
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: black;
    }

    &-no-camera {
      background-color: black;
      height: 250px;
      font-size: 18px;
      color: white;
      text-align: center;
      padding-top: 100px;
    }
  }
`;
