import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sectorAnalysisSlice } from "features/sector-analysis/store";
import { GState } from "documentations";
import * as consts from "./segments-opacity-slider.consts";

export const useValues = () => {
  const segmentsOpacity = useSelector((state: GState) => state.sectorAnalysis.segmentsOpacity);

  return useMemo(() => [0, segmentsOpacity, 1], [segmentsOpacity]);
};

export const useHandleChange = () => {
  const dispatch = useDispatch();

  return useCallback(
    (values: number[]) => {
      const [, opacity] = values;
      dispatch(sectorAnalysisSlice.actions.setSegmentsOpacity(opacity));
    },
    [dispatch]
  );
};

export const useLabels = (values: number[]) => {
  return useMemo(
    () =>
      values.reduce((acc, value, index) => {
        const emptyLabel = { ...acc };
        const defaultLabel = {
          ...acc,
          [value]: <div className={consts.labelFontClass}>{Math.round(value * 100)}%</div>,
        };

        if (index === 0 || index === values.length - 1) return defaultLabel;

        if (Math.abs(values[index + 1] - value) <= consts.intersectionDelta) return emptyLabel;
        if (Math.abs(values[index - 1] - value) <= consts.intersectionDelta) return emptyLabel;

        return defaultLabel;
      }, {}),
    [values]
  );
};
