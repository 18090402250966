import { LngLat, LngLatLike } from "mapbox-gl";
import { TrafficSet } from "api/traffic/model/traffic";
import { DayOfWeek } from "./dBDayOfWeek";

export type TrafficInput = {
  externalSystemIDs?: number[];
  externalSystemSet?: TrafficSet;
};

export enum TrafficUse {
  /** не использовать трафик при построении пути */
  No = "no",
  /** использовать трафик при поиске пути */
  Routing = "routing",
  /** использовать трафик для сравнения времени пути */
  Compare = "compare",
  /** использовать "слоеный" трафик при поиске пути */
  Layers = "layers",
}

export enum RouteCosting {
  Auto = "Auto",
  Multimodal = "Multimodal",
  Cargo = "Cargo",
  Pedestrian = "Pedestrian",
  Template = "Template",
}

export type RouteInput = {
  /** Локации, через которые должен пройти маршрут */
  locations: LngLatLike[];
  /** Время, с которого будет начинаться период трафика стандартной длины */
  trafficTime?: string;
  zoom: number;
  trafficTypes?: TrafficInput;
  vehicleClassTypes?: {
    vehicleClassIDs: number[];
  };
  trafficUse?: TrafficUse;
  /** С учетом ДТП и перекрытий */
  withIncidents?: boolean;
  costing: RouteCosting;
  useTruckGraph?: boolean;
  /** Сохранять ли маршрут для анализа участка */
  saveRoute?: boolean;
  /**
   * Тип транспорта в маршруте
   * 0 - строит маршрут с метро и автобусами
   * 1 - только автобусы
   * 2 - только метро
   */
  transitMode?: number;
  showComparison?: boolean;
};

enum PeriodAliasRaw {
  None = "none",
  Last = "last",
  Today = "today",
  Yesterday = "yesterday",
}

type PeriodInput = {
  from?: string;
  to?: string;
  day?: string;
  periodAliasRaw?: PeriodAliasRaw;
};

export type AnalysisInput = {
  /** ID ранее построенного маршрута */
  routeID: string;
  trafficPeriod: PeriodInput;
  trafficTypes?: TrafficInput;
  vehicleClassTypes?: {
    vehicleClassIDs: number[];
  };
  /** Фильтр по дням недели */
  days?: Array<DayOfWeek>;
  /** Кол-во строк диаграммы (50 по умолчанию) */
  rowsCount?: number;
};

export type IsochronesInput = {
  /** Локация, с которой считать изохроны */
  location?: LngLat;
  /** Массив значений, в минутах, длинны изохрон */
  contours?: Array<number>;
  /** Считать ли путь от локации (false), или до локации (true) */
  reverse?: boolean;
  /** Использовать полигоны вместо linestring для изохрон, только если они формируют замкнутый круг */
  polygons?: boolean;
  /** Генерализировать ли получившиеся контуры */
  generalize?: boolean;
  trafficTime?: string;
  trafficTypes: TrafficInput;
  trafficUse: TrafficUse;
};

export type MatchPointOutput = {
  shape: Array<{ lat: number; lng: number }>;
};

export type RouteTimeInput = {
  /** ID ранее построенного маршрута */
  routeID: string;
  trafficTime?: string;
  trafficTypes: TrafficInput;
  trafficUse: TrafficUse;
};
