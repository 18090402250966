export const tlsSvgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 7 12">
        <g>
            <path
                d="M108.525 0h-5.25a.866.866 0 0 0-.875.857v10.286a.866.866 0 0 0 .875.857h5.25a.866.866 0 0 0 .875-.857V.857a.866.866 0 0 0-.875-.857zM105.9 11.143a1.286 1.286 0 1 1 1.313-1.286 1.3 1.3 0 0 1-1.313 1.286zm0-3.857A1.286 1.286 0 1 1 107.212 6a1.3 1.3 0 0 1-1.312 1.286zm0-3.857a1.3 1.3 0 0 1-1.312-1.286 1.313 1.313 0 0 1 2.625 0 1.3 1.3 0 0 1-1.313 1.286z"
                transform="translate(-102.4)" style="fill:#747474;" />
        </g>
    </svg>
`;

export const tlsSelectedSvgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 7 12">
        <g>
            <path
                d="M108.525 0h-5.25a.866.866 0 0 0-.875.857v10.286a.866.866 0 0 0 .875.857h5.25a.866.866 0 0 0 .875-.857V.857a.866.866 0 0 0-.875-.857zM105.9 11.143a1.286 1.286 0 1 1 1.313-1.286 1.3 1.3 0 0 1-1.313 1.286zm0-3.857A1.286 1.286 0 1 1 107.212 6a1.3 1.3 0 0 1-1.312 1.286zm0-3.857a1.3 1.3 0 0 1-1.312-1.286 1.313 1.313 0 0 1 2.625 0 1.3 1.3 0 0 1-1.313 1.286z"
                transform="translate(-102.4)" style="fill:#747474;" />
        </g>
    </svg>
`;

export const starIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" fill="#0000ff" /></svg>';
