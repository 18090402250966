import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import { DtmSelect } from "components/dtm-select";
import "./route-time-variants-select.scss";

const className = "route-time-variants-select";

class RouteTimeVariantsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        { value: 15, label: "15 мин" },
        { value: 30, label: "30 мин" },
        { value: 60, label: "1 час" },
      ],
    };
  }

  handleChange = (selectedOption) => {
    const { changePeriod } = this.props;
    changePeriod(selectedOption.value);
  };

  getSelectedOption = () => {
    const { period } = this.props;
    const { options } = this.state;
    let selectedOption = null;
    options.forEach((el) => {
      if (el.value === period) {
        selectedOption = el;
      }
    });
    return selectedOption;
  };

  getFilterOptions = () => {
    const { period } = this.props;
    const { options } = this.state;
    const filterOptions = options.filter((el) => el.value !== period);
    return filterOptions;
  };

  render() {
    const selectedOption = this.getSelectedOption();
    const filterOptions = this.getFilterOptions();
    return (
      <div className={className}>
        <DtmSelect options={filterOptions} value={selectedOption} handleChange={this.handleChange} />
      </div>
    );
  }
}

RouteTimeVariantsSelect.propTypes = {
  period: PropTypes.number.isRequired,
  changePeriod: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    period: state.router.period,
  }),
  (dispatch) => ({
    changePeriod: (data) => dispatch(ROUTE_SLICE.setPeriod(data)),
  })
)(RouteTimeVariantsSelect);
