import React, { useState, useCallback } from "react";
import Autosuggest from "react-autosuggest";
import cn from "classnames";
import { CloseButton } from "components";
import { Loader } from "components";

import "./input-autosuggest.scss";

type Props = {
  suggestions: any[];
  mainPointsLength: number;
  value: string;
  onChange: Function;
  onSuggestionsFetchRequested: any;
  onSuggestionSelected: any;
  placeholder: string;
  failed: boolean;
  handleClearButton: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading: boolean;
  alwaysRenderSuggestions: boolean;
};

const baseClass = "input-autosuggest";

export const InputAutosuggest: React.FC<Props> = ({
  onSuggestionsFetchRequested,
  alwaysRenderSuggestions,
  onSuggestionSelected,
  handleClearButton,
  mainPointsLength,
  suggestions,
  placeholder,
  isLoading,
  onChange,
  failed,
  value,
}) => {
  const [isShowClearButton, setIsShowClearButton] = useState(false);

  const getSuggestionValue = useCallback((suggestion: any) => {
    if (suggestion.name && suggestion.name.length > 0) return suggestion.name;
    return suggestion.address;
  }, []);

  const renderSuggestion = useCallback((suggestion: any) => {
    if (suggestion.type === "favorite") {
      return (
        <div className={`${baseClass}-item`} title={suggestion.name}>
          <i className="dtm-favorite-icon" />
          {suggestion.name}
        </div>
      );
    }
    if (suggestion.type === "history") {
      return (
        <div className={`${baseClass}-item`} title={suggestion.address}>
          <i className="dtm-last-address-icon" />
          {suggestion.address}
        </div>
      );
    }
    return (
      <div className={`${baseClass}-item`} title={suggestion.address}>
        <i className="dtm-location-icon" />
        {suggestion.address}
      </div>
    );
  }, []);

  const onSuggestionsClearRequested = useCallback(() => {}, []);

  const shouldRenderSuggestions = useCallback(() => {
    return true;
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (mainPointsLength > 2) {
      setIsShowClearButton(true);
    } else if (value && value.length > 0) {
      setIsShowClearButton(true);
    }
  }, [mainPointsLength, value]);

  const handleMouseLeave = useCallback(() => {
    setIsShowClearButton(false);
  }, []);

  const handleOnChange = useCallback(
    (event: any, data: any) => {
      onChange(event, data);
      if (data.newValue.length > 0) {
        setIsShowClearButton(true);
      } else {
        setIsShowClearButton(false);
      }
    },
    [onChange]
  );

  const inputProps = {
    placeholder,
    value,
    onChange: handleOnChange,
  };

  const renderInputComponent = useCallback(
    (inputProps: any) => (
      <>
        <input {...inputProps} />
        {isShowClearButton && (
          <div className={`${baseClass}-clear-button`}>
            <CloseButton onClick={handleClearButton} />
          </div>
        )}
      </>
    ),
    [handleClearButton, isShowClearButton]
  );

  return (
    <div
      className={cn(baseClass, { [`${baseClass}-failed`]: failed })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        renderInputComponent={renderInputComponent}
      />
      {isLoading && <Loader />}
    </div>
  );
};
