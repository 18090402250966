/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useCallback, memo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import mapboxgl, { LngLat } from "mapbox-gl";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import {
  addFavoriteAC,
  updateFavoriteAC,
  deleteFavoriteAC,
  touchFavoriteAC,
  getFavoriteAC,
} from "old-store/user-favorite/action-creators";
import { touchHistoryAC, addHistoryAC, getHistoryAC } from "old-store/user-history/action-creators";
import { FavoriteButton } from "components/favorite-button";
import { GState } from "documentations";
import { RoutePoint } from "features/ctrl-route/types";
import { RouteFavoriteInfo } from "../../../../common-components/route-favorite-info";
import { RouteAddress } from "../../../../common-components/route-address/route-address";
import RouteAddressPoint from "./route-address-point/route-address-point";

import "./route-input-row.scss";

const className = "route-input-row";

type Props = {
  item: RoutePoint;
  deleteAddressPoint: any;
  index: number;
  provided: any;
  isDisabled: boolean;
};

type FavoriteModel = {
  key: string;
  options: {
    id?: number;
    name: string;
    address: string;
    location?: mapboxgl.LngLatLike | null;
  };
};

const usePoints = () => useSelector((state: GState) => state.router.points, shallowEqual);

export const RouteInputRow: React.FC<Props> = memo(({ item, deleteAddressPoint, isDisabled, index, provided }) => {
  const dispatch = useDispatch();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const [isShowDragHandle, setIsShowDragHandle] = useState(false);
  const [openFavoritePopup, setOpenFavoritePopup] = useState(false);
  const [alwaysRenderSuggestions, setAlwaysRenderSuggestions] = useState(false);
  const points = usePoints();
  const { address, favoriteName, isFavorite, isLoading } = item;
  const placeholder = index === 0 ? "Откуда" : "Куда";
  const mainPoints = points.filter((point) => point.isMain);
  const value = isFavorite && favoriteName ? favoriteName : address;

  const handleStarButton = () => {
    if (isDisabled) return;
    dispatch(getFavoriteAC());
    const handleClick = () => {
      setAlwaysRenderSuggestions(false);
      document.removeEventListener("click", handleClick);
    };

    if (!value && !alwaysRenderSuggestions) {
      setAlwaysRenderSuggestions(true);
      document.addEventListener("click", handleClick);
    } else if (value) {
      setOpenFavoritePopup(!openFavoritePopup);
    }
  };

  const onSuggestionSelected = useCallback(
    (event: any, { suggestion }: any) => {
      if (isDisabled) return;
      const { address, location, id, name, type } = suggestion;
      const isFavorite = type === "favorite";
      const coor = new LngLat(location.lng, location.lat);

      dispatch(
        ROUTE_SLICE.updateByFavorite({
          key: item.key,
          address,
          coor,
          favoriteId: id,
          isFavorite,
          favoriteName: name,
        })
      );

      const options = {
        id,
        name,
        address,
      };

      if (type === "history") {
        dispatch(touchHistoryAC({ options }));
      } else if (type === "favorite") {
        dispatch(touchFavoriteAC({ options }));
      } else {
        dispatch(
          addHistoryAC({
            options: { location, address },
          })
        );
      }
    },
    [isDisabled, dispatch, item.key]
  );

  const onChangeAddress = useCallback(
    (event, { newValue }: any) => {
      const { favoriteName, isFavorite } = item;
      if (isDisabled) return;
      if (isFavorite && favoriteName && favoriteName?.length > 0) {
        dispatch(
          ROUTE_SLICE.setPointName({
            key: item.key,
            name: newValue,
          })
        );
        dispatch(
          ROUTE_SLICE.setPointAddress({
            key: item.key,
            address: newValue,
          })
        );
      } else {
        dispatch(
          ROUTE_SLICE.setPointAddress({
            key: item.key,
            address: newValue,
          })
        );
      }
    },
    [isDisabled, dispatch, item]
  );

  const handleCloseButton = useCallback(() => {
    if (isDisabled) return;
    setOpenFavoritePopup(false);
  }, [isDisabled]);

  const handleConfirmButton = useCallback(
    ({ address, name }: any) => {
      if (isDisabled) return;
      const { favoriteId, isFavorite, key } = item;
      const data: FavoriteModel = {
        options: {
          name,
          location: item.coor,
          address,
        },
        key,
      };
      if (isFavorite) {
        if (favoriteId) data.options.id = favoriteId;
        dispatch(updateFavoriteAC(data));
      } else {
        dispatch(addFavoriteAC(data));
      }
      handleCloseButton();
    },
    [isDisabled, dispatch, handleCloseButton, item]
  );

  const handleClearButton = useCallback(() => {
    if (isDisabled) return;
    deleteAddressPoint();
    dispatch(getFavoriteAC());
    dispatch(getHistoryAC());
  }, [isDisabled, deleteAddressPoint, dispatch]);

  const handleCancelButton = useCallback(() => {
    const { address, favoriteName, favoriteId, isFavorite, key } = item;
    const data = {
      key,
      options: {
        id: favoriteId,
        name: favoriteName,
        address,
      },
    };
    if (isFavorite) {
      dispatch(deleteFavoriteAC(data));
      handleClearButton();
    } else {
      handleClearButton();
    }
    handleCloseButton();
  }, [dispatch, handleClearButton, handleCloseButton, item]);

  const handleClearName = useCallback(() => {
    if (isDisabled) return;
    dispatch(ROUTE_SLICE.setPointName({ key: item.key, name: null }));
  }, [dispatch, item.key]);

  const handleClearAddress = useCallback(() => {
    if (isDisabled) return;
    dispatch(ROUTE_SLICE.setPointAddress({ key: item.key, address: null }));
  }, [isDisabled, dispatch, item.key]);

  const handleMouseEnter = () => {
    if (isDisabled) return;
    if (mainPoints.length > 2) setIsShowDragHandle(true);
  };

  const handleMouseLeave = () => {
    if (isDisabled) return;
    setIsShowDragHandle(false);
  };

  return (
    <div ref={itemRef} className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={`${className}-point`} {...provided.dragHandleProps}>
        {isShowDragHandle && (
          <div className={`${className}-drag`}>
            <i className="dtm-drag-n-drop-icon" />
          </div>
        )}
        <RouteAddressPoint index={index} points={points} item={item} />
      </div>
      <div className={`${className}-address`}>
        <RouteAddress
          failed={false}
          value={value || ""}
          keyPoint={item.key}
          noSuggestion={false}
          isLoading={!!isLoading}
          placeholder={placeholder}
          noFavoritesSuggestion={false}
          mainPointsLength={mainPoints.length}
          alwaysRenderSuggestions={alwaysRenderSuggestions}
          onChange={onChangeAddress}
          handleClearButton={handleClearButton}
          onSuggestionSelected={onSuggestionSelected}
        />
      </div>
      <div className={`${className}-favorite`}>
        <FavoriteButton isActive={!!isFavorite} onClick={handleStarButton} />
      </div>
      {openFavoritePopup && (
        <RouteFavoriteInfo
          parent={itemRef.current}
          name={favoriteName ?? ""}
          address={address ?? ""}
          isFavorite={!!isFavorite}
          handleCloseButton={handleCloseButton}
          handleConfirmButton={handleConfirmButton}
          handleCancelButton={handleCancelButton}
          onSuggestionSelected={onSuggestionSelected}
          handleClearName={handleClearName}
          handleClearAddress={handleClearAddress}
        />
      )}
    </div>
  );
});
