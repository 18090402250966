interface StateBySearch {
  es?: Array<number>;
  traffic?: boolean;
  correlation?: boolean;
  filter?: "city" | "trans" | "highway" | "dedicated";
  points?: boolean;
  heatmap?: boolean;
  graph?: boolean;
  dtp?: boolean;
  blocking?: boolean;
  weather?: boolean;
  time?: boolean;
  selectedTime?: string;
}

type StateItem = "traffic" | "correlation" | "points" | "heatmap" | "graph" | "dtp" | "blocking" | "weather" | "time";

export const getStateByHistory = (location: Location): StateBySearch => {
  const { search } = location;

  const state: StateBySearch = {};

  const searchParams = new URLSearchParams(search);

  /** Добавление источников телематики */
  const es = searchParams.getAll("es").map((el) => Number(el));
  if (es.length > 0) state.es = es;

  /** Проверка включенных режимов */
  const items: Array<StateItem> = [
    "traffic",
    "correlation",
    "points",
    "heatmap",
    "graph",
    "dtp",
    "blocking",
    "weather",
    "time",
  ];

  items.forEach((item) => {
    const value = Number(searchParams.get(item));
    if (value === 1) state[item] = true;
  });

  /** Добавление фильтра дорог */
  const filter = searchParams.get("filter");
  if (filter === "city" || filter === "trans" || filter === "highway" || filter === "dedicated") state.filter = filter;

  /** Добавление указанного времени */
  const selectedTime = searchParams.get("selectedTime");
  if (selectedTime) state.selectedTime = selectedTime;

  return state;
};
