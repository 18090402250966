import React, { FC } from "react";
import { CloseButton } from "components";
import { useStoreContext } from "store";
import { ArrowBigDown } from "assets";

import "./graph-compare-header.scss";

interface GraphCompareHeaderPropsType {
  isGraphOpen: boolean;
  setIsGraphOpen: (value: boolean) => void;
}

export const GraphCompareHeader: FC<GraphCompareHeaderPropsType> = (props) => {
  const { store } = useStoreContext();
  const handleClose = () => {
    store.graphCompare.events.getInit();
    store.graphCompare.events.handleIsVisibleChange(false);
  };
  const handleShowGraph = () => props.setIsGraphOpen(!props.isGraphOpen);

  return (
    <div className="header-block">
      <div className="header-block__title">Версии графа</div>
      <div className="buttons-block">
        <ArrowBigDown onClick={handleShowGraph} className={`buttons-block__${props.isGraphOpen ? "hidden" : "show"}`} />
        <CloseButton onClick={handleClose} />
      </div>
    </div>
  );
};
