import { memo, MouseEventHandler } from "react";
import { FullScreenIcon, ExitFullScreenIcon } from "assets";
import "./full-screen-button.scss";

type Props = {
  isFullscreen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const FullscreenButton = memo<Props>((props) => {
  return (
    <button className="full-screen-button" onClick={props.onClick}>
      {!props.isFullscreen && <FullScreenIcon />}
      {props.isFullscreen && <ExitFullScreenIcon />}
    </button>
  );
});
