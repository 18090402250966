import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialStateFromUrl } from "utils/get-initial-state-from-url";
import { DtpState } from "../types";

export const initialState: DtpState = {
  isActive: getInitialStateFromUrl("dtp"),
};

export const dtpSlice = createSlice({
  name: "dtp",
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
  },
});
