import React, { memo, useCallback } from "react";
import { AnalysisTotal } from "api/router/model/analysisResponse";
import { Checkbox } from "components";
import { defaultSettings } from "../sector-analysis";
import * as Components from "./components";

import "./sector-analysis-appearance-menu.scss";

type DiagramSettings = {
  total: AnalysisTotal | null;
  isShowLegend: boolean;
  isUnitsCountDiagram: boolean;
  isShowEvents: boolean;
  isVolumetric: boolean;
  appearance?: DiagramAppearance;
};

type Props = {
  settings: DiagramSettings;
  handleRangeChange: (value: number[]) => void;
  handleIsLegendChange: (value: boolean) => void;
  handleRestoreAppearance: Components.ButtonProps["onClick"];
  handleIsShowEventChange: () => void;
};

const baseClass = "sector-analysis-appearance-menu";
const rowClass = `${baseClass}__row`;
const viewModsContainerClass = `${baseClass}__view-mods`;
const legendRowClass = `${rowClass} ${rowClass}-thin ${rowClass}-distanced ${baseClass}__label`;
const headerClass = `${baseClass}__header`;
const headingClass = `${baseClass}__heading`;
const legendClass = `${baseClass}__legend`;
const restoreClass = `${baseClass}__restore`;
const graphSettingsClass = `${baseClass}__graph-settings`;

const slidersTitle = {
  speed: "Цветовая индикация скорости (% от средней скорости без пробок)",
  unitsCount: "Цветовая индикация кол-ва транспорта",
};

const useIsRestoreDisable = (appearance?: DiagramAppearance) => {
  return !appearance?.reduce((...[acc, , index]) => {
    if (acc) return acc;
    if (appearance[index].value !== defaultSettings.diagramAppearance[index].value) {
      acc = true;
    }
    return acc;
  }, false);
};

export const SectorAnalysisAppearanceMenu = memo<Props>((props) => {
  const { settings, handleRangeChange, handleIsLegendChange, handleRestoreAppearance, handleIsShowEventChange } = props;
  const { appearance, isShowLegend, total, isShowEvents, isUnitsCountDiagram, isVolumetric } = settings;
  const isAppearanceRestoreButtonDisable = useIsRestoreDisable(appearance);

  const handleIsShowLegendChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleIsLegendChange(e.target.checked);
    },
    [handleIsLegendChange]
  );

  return (
    <div className={baseClass}>
      <div className={headerClass}>
        <h2 className={headingClass}>Режимы отображения данных</h2>
      </div>
      <div className={viewModsContainerClass}>
        <Components.DiagramViewModeToggle />
        <Components.DiagramModeToggle />
      </div>
      {isVolumetric && (
        <>
          <div className={headerClass}>
            <h2 className={headingClass}>Настройка прозрачности элементов</h2>
          </div>
          <div className={rowClass}>
            <Components.SegmentsOpacitySlider />
          </div>
        </>
      )}
      {isVolumetric && (
        <>
          <div className={headerClass}>
            <h2 className={headingClass}>Фильтрация количества ТС</h2>
          </div>
          <div className={rowClass}>
            <Components.SegmentsUnitsCountSlider />
          </div>
        </>
      )}
      {!(!isVolumetric && isUnitsCountDiagram) && (
        <>
          <div className={headerClass}>
            <h2 className={headingClass}>
              {settings.isUnitsCountDiagram ? slidersTitle.unitsCount : slidersTitle.speed}
            </h2>
          </div>
          <div className={rowClass}>
            <Components.SectorAnalysisAppearanceSlider
              total={total}
              appearance={appearance}
              handleRangeChange={handleRangeChange}
            />
          </div>
        </>
      )}
      {!isVolumetric && isUnitsCountDiagram && (
        <div className={rowClass}>
          <Components.UnitsCountAppearanceSlider />
        </div>
      )}
      <div className={legendRowClass}>
        <div className={legendClass}>
          <Checkbox
            label="Показывать легенду"
            value=""
            title="Показывать легенду"
            checked={isShowLegend}
            onChange={handleIsShowLegendChange}
          />
        </div>
        {!isUnitsCountDiagram && (
          <div className={restoreClass}>
            <Components.SectorAnalysisAppearanceButton
              disabled={isAppearanceRestoreButtonDisable}
              title="Сбросить настройки"
              onClick={handleRestoreAppearance}
            />
          </div>
        )}
      </div>
      <div className={legendRowClass}>
        <Checkbox value="" onChange={handleIsShowEventChange} checked={isShowEvents} label="События" title="События" />
      </div>
      <div className={graphSettingsClass}>
        <Components.GraphSettings isUnitsCountDiagram={isUnitsCountDiagram} />
      </div>
    </div>
  );
});
