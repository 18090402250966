import { useStore } from "effector-react";
import { store } from "./application-settings";

const useIsPopupVisible = () => useStore(store).isPopupVisible;
const useIsEmptyPieces = () => useStore(store).telematics.isEmptyPieces;
const useActiveMapStyle = () => useStore(store).activeMapStyle;

export const hooks = {
  useIsPopupVisible,
  useIsEmptyPieces,
  useActiveMapStyle,
};

export type Hooks = typeof hooks;
