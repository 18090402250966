import React from "react";
import { Props } from "./header.types";

import "./header.scss";

const headerClass = `details-administrative-division__header-container`;
const heading = `${headerClass}__heading`;

export const Header = (props: Props) => {
  return (
    <div className={headerClass}>
      <div className={heading}>
        <img src={props.administrativeDivisionIcon} alt={""} />
        <span className={`${heading}__title`}>{props.administrativeDivisionName}</span>
      </div>
    </div>
  );
};
