import React from "react";
import { Icons } from "./icons";
import { useStore, Attribute } from "./store";
import "./edges-additional-attr.scss";

interface AttrItemProps {
  attribute: Attribute;
  actions: {
    handleCreateAttribute: () => void;
    handleDeleteAttribute: (guid: string) => void;
    handleSubmitSave: (attribute: Attribute) => void;
  };
}

const useAttrItemState = (props: AttrItemProps) => {
  const { attribute } = props;
  const didMount = React.useRef(false);
  const [state, setState] = React.useState(attribute);
  const [isEdit, setEdit] = React.useState(attribute.isNew);

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      setState(attribute);
      setEdit(false);
    }
  }, [attribute, didMount]);

  const handleChangeLabel = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const label = event.target.value;
    setState((prev) => ({ ...prev, label }));
  }, []);

  const handleChangeValue = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setState((prev) => ({ ...prev, value }));
  }, []);

  const handleEditChange = React.useCallback(() => setEdit(true), []);

  return { state, isEdit, handleChangeLabel, handleChangeValue, handleEditChange };
};

const AttrItem = (props: AttrItemProps) => {
  const { attribute, actions } = props;
  const { guid } = attribute;
  const { handleDeleteAttribute, handleSubmitSave } = actions;
  const { state, isEdit, handleChangeLabel, handleChangeValue, handleEditChange } = useAttrItemState(props);

  const onSubmitSave = React.useCallback(() => handleSubmitSave(state), [handleSubmitSave, state]);
  const onDeleteAttribute = React.useCallback(() => handleDeleteAttribute(guid), [handleDeleteAttribute, guid]);

  return (
    <div className="eat__attribute">
      <input
        className="eat__label"
        placeholder="Название"
        disabled={!isEdit}
        onChange={handleChangeLabel}
        value={state.label}
      />
      <input
        className="eat__value"
        placeholder="Значение"
        disabled={!isEdit}
        onChange={handleChangeValue}
        value={state.value}
      />
      <div className="eat__actions">
        {isEdit ? (
          <>
            <button className="eat__btn eat__btn-remove" title="Удалить" onClick={onDeleteAttribute}>
              <Icons.Remove className="eat__btn__icon" />
            </button>
            <button className="eat__btn eat__btn-save" title="Сохранить" onClick={onSubmitSave}>
              <Icons.Save className="eat__btn__icon" />
            </button>
          </>
        ) : (
          <>
            <button className="eat__btn eat__btn-remove" title="Удалить" onClick={onDeleteAttribute}>
              <Icons.Remove className="eat__btn__icon" />
            </button>
            <button className="eat__btn eat__btn-edit" title="Редактировать" onClick={handleEditChange}>
              <Icons.Edit className="eat__btn__icon" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

/* #region EdgesAdditionalAttr */
interface EdgesAdditionalAttrProps {
  persistentEdgeId: string;
}

export const EdgesAdditionalAttr = (props: EdgesAdditionalAttrProps) => {
  const { state, actions } = useStore(props.persistentEdgeId);

  if (!state) {
    return (
      <div className="eat" style={{ textAlign: "center" }}>
        <img
          src="https://dev-cdn.megapolis-it.ru/image/loader.gif"
          width="24"
          height="24"
          alt="Дополнительные атрибуты ребра"
        />
      </div>
    );
  }

  return (
    <div className="eat">
      <div className="eat__head">
        <h1 className="eat__title">Дополнительные атрибуты</h1>
        <div className="eat__actions">
          <button className="eat__btn eat__btn-add" title="Создать аттрибут" onClick={actions.handleCreateAttribute}>
            <Icons.Add className="eat__btn__icon" />
          </button>
        </div>
      </div>

      <div className="eat__body">
        {state?.map((attribute) => {
          return <AttrItem key={attribute.guid} attribute={attribute} actions={actions} />;
        })}
      </div>
    </div>
  );
};
/* #endregion */
