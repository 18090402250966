import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import { trafficUpdateTypeAC } from "old-store/traffic/action-creators";
import { changeSelectedDayAC } from "old-store/view/action-creators";
import { DtmSelect } from "components/dtm-select";
import "./route-select-time.scss";

const className = "route-select-time";

class RouteSelectTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        { value: "last", label: "Отправление сейчас" },
        { value: "departure", label: "Отправление в" },
        // { value: "arrival", label: "Прибытие в" }
      ],
    };
  }

  componentWillUnmount() {
    const { updateTrafficType } = this.props;
    updateTrafficType("last");
  }

  handleChange = (selectedOption) => {
    const { changeTimeStatus, updateTrafficType, changeSelectedDay } = this.props;
    changeTimeStatus(selectedOption.value);
    updateTrafficType(selectedOption.value);
    if (selectedOption.value === "last") {
      const now = moment().toISOString(true);
      changeSelectedDay(now);
    }
  };

  getSelectedOption = () => {
    const { timeStatus } = this.props;
    const { options } = this.state;
    let selectedOption = null;
    options.forEach((el) => {
      if (el.value === timeStatus) {
        selectedOption = el;
      }
    });
    return selectedOption;
  };

  getFilterOptions = () => {
    const { timeStatus } = this.props;
    const { options } = this.state;
    const filterOptions = options.filter((el) => el.value !== timeStatus);
    return filterOptions;
  };

  render() {
    const { isDisabled } = this.props;
    const selectedOption = this.getSelectedOption();
    const filterOptions = this.getFilterOptions();
    return (
      <div className={className}>
        <DtmSelect
          isDisabled={isDisabled}
          value={selectedOption}
          options={filterOptions}
          handleChange={this.handleChange}
        />
      </div>
    );
  }
}

RouteSelectTime.propTypes = {
  timeStatus: PropTypes.string.isRequired,
  changeTimeStatus: PropTypes.func.isRequired,
  updateTrafficType: PropTypes.func.isRequired,
  changeSelectedDay: PropTypes.func,
};

export default connect(
  (state) => ({
    timeStatus: state.router.timeStatus,
    isDisabled: state.router.isDisabled || (state.router.isCompareWithYandex && state.router.costing === "Template"),
  }),
  (dispatch) => ({
    changeTimeStatus: (data) => dispatch(ROUTE_SLICE.setTimeStatus(data)),
    updateTrafficType: (data) => dispatch(trafficUpdateTypeAC(data)),
    changeSelectedDay: (data) => dispatch(changeSelectedDayAC(data)),
  })
)(RouteSelectTime);
