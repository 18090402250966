import mapboxgl from "mapbox-gl";
import * as THREE from "three";
import * as turf from "@turf/turf";
import { CORRELATION_VOLUMETRIC } from "map-helpers/order-layers";
import * as Types from "./volumetric-correlation.types";

export class VolumetricCorrelation implements mapboxgl.CustomLayerInterface {
  public id = CORRELATION_VOLUMETRIC;
  public type = "custom" as const;
  public renderingMode = "3d" as const;
  private renderer?: THREE.WebGLRenderer;
  private scene?: THREE.Scene;
  private camera?: THREE.Camera;
  private world?: THREE.Group;
  private raycaster?: THREE.Raycaster;
  private cameraTransform?: THREE.Matrix4;
  private cameraCenter?: mapboxgl.MercatorCoordinate;
  private fillSceneTimeout?: number;
  private features: mapboxgl.MapboxGeoJSONFeature[] = [];
  private properties: {
    [key: string]: Types.FeatureProperties;
  } = {};
  private _visibility = false;

  public set isVisible(isVisible: boolean) {
    this._visibility = isVisible;
    if (!this._visibility) {
      this.features = [];
      this.clearScene();
    } else {
      this.handleViewportChange();
    }
    this.map.triggerRepaint();
  }

  public get visibility() {
    return this._visibility;
  }

  constructor(private map: mapboxgl.Map) {}

  private createLightning = () => {
    const skyColor = 0xb1e1ff; // light blue
    const groundColor = 0xb97a20; // brownish orange

    this.scene?.add(new THREE.AmbientLight(0xffffff, 0.25));
    this.scene?.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(-70, -70, 100).normalize();
    this.scene?.add(directionalLight);
  };

  private toRadians = (degrees: number) => degrees * (Math.PI / 180);

  private getSegments = (coordinates: [number, number][], scale: number) => {
    return coordinates.reduce<Types.FeatureSegment[]>((acc, item, j) => {
      if (!this.cameraCenter) return acc;
      if (j === coordinates.length - 1) return acc;
      const endPoint = coordinates[j + 1];
      if (!endPoint) return acc;

      const points = [item, endPoint];
      const features = turf.points(points);
      const line = turf.lineString(points);
      const centerFeature = turf.center(features);
      const mercator = mapboxgl.MercatorCoordinate.fromLngLat(centerFeature.geometry.coordinates as [number, number]);
      const center = {
        x: -(this.cameraCenter.x - mercator.x) / scale,
        y: (this.cameraCenter.y - mercator.y) / scale,
      };
      const length = turf.length(line) * 1000;
      const azimuth = this.toRadians(turf.bearing(item, endPoint) ?? 0);

      return [
        ...acc,
        {
          center,
          length,
          azimuth,
        },
      ];
    }, []);
  };

  private addSegment = (options: Types.AddSegmentOptions) => (segment: Types.FeatureSegment) => {
    if (!this.world) return;
    const { center, length, azimuth } = segment;
    const { width, depth, historicalColor, forecastColor, properties } = options;

    const createBox = (color: number) => {
      const geometry = new THREE.BoxGeometry(width, length, depth);
      const material = new THREE.MeshPhongMaterial({
        transparent: true,
        color,
      });
      return new THREE.Mesh(geometry, material);
    };

    const setBox = (box: THREE.Mesh, z: number) => {
      box.translateX(center.x);
      box.translateY(center.y);
      box.translateZ(z);
      box.rotateZ(Math.PI - azimuth);
      this.world?.add(box);
    };

    const historicalBox = createBox(historicalColor);
    const forecastBox = createBox(forecastColor);

    setBox(historicalBox, 1.5);
    setBox(forecastBox, 5);

    this.properties[historicalBox.uuid] = properties as Types.FeatureProperties;
    this.properties[forecastBox.uuid] = properties as Types.FeatureProperties;
  };

  private clearScene = () => {
    if (!this.world) return;

    while (this.world.children.length) {
      const item = this.world.children[this.world.children.length - 1];
      const mesh = item as THREE.Mesh;
      if (!Array.isArray(mesh.material)) {
        mesh.material?.dispose?.();
      }
      mesh.geometry?.dispose?.();
      this.world.remove(item);
    }
    this.world.clear();
  };

  private fillScene = () => {
    if (!this.world || !this.cameraCenter) return;

    this.clearScene();
    const scale = this.cameraCenter.meterInMercatorCoordinateUnits();

    for (let i = 0; i < this.features.length; i++) {
      const {
        // @ts-ignore
        geometry: { coordinates },
        properties,
      } = this.features[i];

      if (!properties) return;

      const hexHistoricalTrafficColor = properties.historicalTrafficColor.replace("#", "");
      const hexForecastTrafficColor = properties.forecastTrafficColor.replace("#", "");
      const historicalColor = parseInt(hexHistoricalTrafficColor, 16);
      const forecastColor = parseInt(hexForecastTrafficColor, 16);

      const { oneway } = properties;
      const width = oneway ? 2.5 : 1.25;
      const depth = 3;
      const featureSegments = this.getSegments(coordinates, scale);

      const addSegment = this.addSegment({
        width,
        depth,
        historicalColor,
        forecastColor,
        properties,
      });

      for (let j = 0; j < featureSegments.length; j++) {
        addSegment(featureSegments[j]);
      }
    }
  };

  private handleViewportChange = () => {
    if (!this._visibility) {
      this.features = [];
      this.properties = {};
      return this.clearScene();
    }

    window.clearTimeout(this.fillSceneTimeout);
    this.fillSceneTimeout = window.setTimeout(() => {
      this.features = this.map.querySourceFeatures("correlation-traffic", { sourceLayer: "diff" });
      this.fillScene();
    }, 300);
  };

  public getProperties = (uuid: string) => {
    return this.properties[uuid];
  };

  public raycast = (point: mapboxgl.Point) => {
    if (!this.scene || !this.camera || !this.raycaster) return;
    const mouse = new THREE.Vector2();

    // Координаты мыши относительно экрана переводятся в координаты в пределах от -1 до 1
    // @ts-ignore
    mouse.x = (point.x / (this.map.transform.width ?? 1)) * 2 - 1;
    // @ts-ignore
    mouse.y = 1 - (point.y / (this.map.transform.height ?? 1)) * 2;

    const camInverseProjection = this.camera.projectionMatrix.clone().invert();
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, viewDirection);
    return this.raycaster.intersectObjects(this.scene.children, true);
  };

  public onAdd = (map: mapboxgl.Map, gl: WebGLRenderingContext) => {
    this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6);
    this.scene = new THREE.Scene();
    this.world = new THREE.Group();
    this.scene.add(this.world);

    this.cameraCenter = mapboxgl.MercatorCoordinate.fromLngLat(this.map.getCenter(), 0);

    const { x, y, z } = this.cameraCenter;
    this.world.scale.setScalar(this.cameraCenter.meterInMercatorCoordinateUnits());
    this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z ?? 0).scale(new THREE.Vector3(1, -1, 1));

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
    this.raycaster = new THREE.Raycaster();

    this.map.on("pitchend", this.handleViewportChange);
    this.map.on("rotatestart", this.handleViewportChange);
    this.map.on("rotateend", this.handleViewportChange);
    this.map.on("dragend", this.handleViewportChange);
    this.map.on("zoomend", this.handleViewportChange);

    this.createLightning();
    this.handleViewportChange();
    this.map.triggerRepaint();
  };

  public onRemove = () => {
    this.map.off("pitchend", this.handleViewportChange);
    this.map.off("rotatestart", this.handleViewportChange);
    this.map.off("rotateend", this.handleViewportChange);
    this.map.off("dragend", this.handleViewportChange);
    this.map.off("zoomend", this.handleViewportChange);
  };

  public render = (gl: WebGLRenderingContext, matrix: number[]) => {
    if (!this.renderer || !this.scene || !this.camera || !this.cameraTransform) return;

    this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
  };
}
