import { Reducer } from "react";
import { constants, initialState } from "./store.constants";
import * as Types from "./store.types";

// @ts-ignore
export const reducer: Reducer<Types.Attribute[] | null, Action> = (state = initialState, action: Action) => {
  const _state = state ?? [];

  if (action.type === constants.INITIALIZE) {
    return (action as Types.InitializeState).payload;
  }

  if (action.type === constants.CREATE) {
    return [..._state, { ...(action as Types.CreateAttribute).payload }];
  }

  if (action.type === constants.DELETE) {
    const nextState = _state.filter((attribute) => attribute.guid !== (action as Types.DeleteAttribute).payload.guid);

    sessionStorage.setItem(`dtm_edge_${(action as Types.SubmitSave).payload.id}`, JSON.stringify(nextState));

    return nextState;
  }

  if (action.type === constants.SUBMIT_SAVE) {
    const nextState = _state.map((attribute) => {
      if (attribute.guid !== (action as Types.SubmitSave).payload.attribute.guid) return attribute;
      return {
        ...(action as Types.SubmitSave).payload.attribute,
        isNew: false,
      };
    });

    sessionStorage.setItem(`dtm_edge_${(action as Types.SubmitSave).payload.id}`, JSON.stringify(nextState));

    return nextState;
  }

  return state;
};
