import React from "react";
import { ReactComponent as SpeedIcon } from "assets/icons/speed.svg";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { ReactComponent as BusIcon } from "assets/icons/bus.svg";
import { ReactComponent as CompareIcon } from "assets/icons/favorite.svg";
import { preciseNumber } from "utils/precise-number";
import "./index.scss";

export interface SectorAnalysisCardProps {
  type: "time" | "speed" | "route" | "compare";
  freeFlowSpeed?: number;
  speed?: number;
  freeFlowTime?: number;
  time?: number;
  routes?: Array<number>;
}

const baseClass = "sector-analysis-card";

export const SectorAnalysisCard: React.SFC<SectorAnalysisCardProps> = ({
  type,
  freeFlowSpeed,
  speed,
  freeFlowTime,
  time,
  routes,
}) => {
  const getTitle = () => {
    switch (type) {
      case "speed":
        return "Скорость";
      case "route":
        return "Маршруты НГПТ";
      case "time":
        return "Время проезда";
      case "compare":
        return "Сравнение средних";
      default:
        return "";
    }
  };

  const getIcon = () => {
    switch (type) {
      case "speed":
        return <SpeedIcon />;
      case "route":
        return <BusIcon />;
      case "time":
        return <TimeIcon />;
      case "compare":
        return <CompareIcon />;
      default:
        return "";
    }
  };

  const formatSpeed = (value?: number) => {
    if (typeof value !== "number") return "н/д";
    const speed = preciseNumber(value);
    return `${speed} км/ч`;
  };

  const formatTime = (value?: number) => {
    if (typeof value !== "number") return "н/д";
    const time = value / 60;
    const precisedTime = preciseNumber(time);
    if (Number(precisedTime) === 0) return "н/д";
    return `${precisedTime} мин`;
  };

  const speedPercent = () => {
    if (
      typeof speed === "number" &&
      typeof freeFlowSpeed === "number" &&
      speed > 0 &&
      freeFlowSpeed > 0 &&
      preciseNumber(speed) !== preciseNumber(freeFlowSpeed)
    ) {
      const precisedSpeed = preciseNumber(speed);
      const precisedFreeFlowSpeed = preciseNumber(freeFlowSpeed);
      const percent = (precisedSpeed / precisedFreeFlowSpeed) * 100;
      const precisionPercent = preciseNumber(Number(percent));
      return `(${precisionPercent}%)`;
    }
  };

  const timePercent = () => {
    if (
      typeof time === "number" &&
      typeof freeFlowTime === "number" &&
      time > 0 &&
      freeFlowTime > 0 &&
      preciseNumber(freeFlowTime / 60) !== preciseNumber(time / 60)
    ) {
      const precicedTime = preciseNumber(time / 60);
      const precisedFreeFlowTime = preciseNumber(freeFlowTime / 60);
      const percent = (100 * (precicedTime - precisedFreeFlowTime)) / precisedFreeFlowTime;
      const precisionPercent = preciseNumber(Number(percent));
      const result = Number(precisionPercent) >= 0 ? `(+${precisionPercent}%)` : `(${precisionPercent}%)`;
      return result;
    }
  };

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-header`}>
        {getIcon()}
        <span className={`${baseClass}-title`}>{getTitle()}</span>
      </div>
      {type === "route" ? (
        <div className={`${baseClass}-routes`}>
          {routes &&
            routes.map((el, index) => (
              <div className={`${baseClass}-route`} key={index}>
                {el}
              </div>
            ))}
        </div>
      ) : type === "speed" ? (
        <>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Без пробок</span>
            <span className={`${baseClass}-value`}>{formatSpeed(freeFlowSpeed)}</span>
          </div>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Средняя</span>
            <div className={`${baseClass}-value`}>
              <div>
                <span className={`${baseClass}-average`}>{formatSpeed(speed)}</span>
                <span className={`${baseClass}-deviation`}>{speedPercent()}</span>
              </div>
              {/* <span className={`${baseClass}-range`}>({range})</span> */}
            </div>
          </div>
        </>
      ) : type === "compare" ? (
        <>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Средняя скорость</span>
            <span className={`${baseClass}-value`}>{speed}%</span>
          </div>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Средняя время проезда</span>
            <span className={`${baseClass}-value`}>{time}%</span>
          </div>
        </>
      ) : (
        <>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Без пробок</span>
            <span className={`${baseClass}-value`}>{formatTime(freeFlowTime)}</span>
          </div>
          <div className={`${baseClass}-row`}>
            <span className={`${baseClass}-name`}>Среднее</span>
            <div className={`${baseClass}-value`}>
              <div>
                <span className={`${baseClass}-average`}>{formatTime(time)}</span>
                <span className={`${baseClass}-deviation`}>{timePercent()}</span>
              </div>
              {/* <span className={`${baseClass}-range`}>({range})</span> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
