import React, { memo } from "react";
import cn from "classnames";
import AlertBox, { Position } from "@megapolis/alerts";
import * as Features from "features";
import { UserThumb } from "components";
import * as hooks from "./app.hooks";
import { APP_ENV } from "app-env";

export const App: React.FC = memo(() => {
  const isSectorAnalysisActive = hooks.useSectorAnalysisIsActive();
  const className = cn("app", isSectorAnalysisActive && "app-mode-sector-analysis");
  hooks.useInitialize();
  hooks.useParams();
  hooks.useStatesTemporaryConnection();
  hooks.useSetTimeZone();

  const isShowTravelHeatmap = !Boolean(APP_ENV.REACT_APP_CONFIGURATION?.rnisServices?.analytics === "hidden");

  const travelHeatmap = isShowTravelHeatmap ? (
    <>
      <Features.TravelHeatmapLegend />
      <Features.TravelHeatmapCard />
      <Features.TravelHeatmapSettings />
      <Features.ZoneStatisticsCard />
    </>
  ) : null;

  return (
    <div className={className} id="app">
      <Features.MapView />
      <Features.RightPanel />
      <Features.TopPanel />
      <Features.SectorAnalysis />
      {travelHeatmap}
      <Features.CtrlRoute.Components.Router />
      <Features.GraphCompare />
      <Features.GraphCard />
      <Features.HistoryTravel />
      <UserThumb />
      <AlertBox position={Position.BOTTOM_RIGHT} />
    </div>
  );
});
