/* eslint-disable no-case-declarations */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react/no-did-update-set-state */
import * as ReactDOM from "react-dom";
import * as React from "react";
import * as mapboxgl from "mapbox-gl";
import moment from "moment";
import Select from "react-select";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { setdetailedIsOpen, setSelectedOption } from "old-store/weather/reducer";
import { WeatherSelectOption } from "old-store/weather/types";
import { GState } from "../../documentations";
import { Forecast } from "../../api/weather/weather-api-types";
import { customStyles, themeSelect } from "./ctrl-weather.utils";
import { checkRangeDate } from "./utils/check-range-date";
import { districts } from "./utils/districts-list";
import WeatherRow from "./weather-row/weater-row";
import WeatherHeader from "./weather-header/weather-header";
import "rc-tooltip/assets/bootstrap.css";
import "./ctrl-weather.scss";

const imagesWeather = new Array(17).fill(1).map((v, index) => require(`./assets/weather-${index + 1}.svg`));
const container = document.createElement("div");

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setOption: (option: WeatherSelectOption) => dispatch(setSelectedOption(option)),
  setDetailView: (view: boolean) => dispatch(setdetailedIsOpen(view)),
});

const mapStateToProps = ({
  view: { to, selectedDay },
  weather: { forecast, timeWeatherOptions, selectedOption, detailedIsOpen },
}: GState) => ({
  to,
  forecast,
  selectedDay,
  timeWeatherOptions,
  selectedOption,
  detailedIsOpen,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  map: mapboxgl.Map;
  lat: number;
  lng: number;
  id: number;
  clearPopup: () => void;
};
class WeatherPopup extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.popupOptions = {
      maxWidth: "600px",
      offset: 15,
      className: "dtm-popup",
      closeButton: true,
      closeOnClick: false,
    };

    this.container = React.createRef();
  }

  componentDidMount() {
    const { map, lat, lng } = this.props;
    this.popupMapBoxGL = new mapboxgl.Popup(this.popupOptions);
    this.popupMapBoxGL.setDOMContent(container).setLngLat({ lat, lng }).addTo(map).on("close", this.props.clearPopup);

    map.keyboard.disable();

    document.addEventListener("keydown", this.keyboardListener);
  }

  componentWillUnmount() {
    const { map } = this.props;

    if (this.popupMapBoxGL) this.popupMapBoxGL.remove();

    map.keyboard.enable();

    document.removeEventListener("keydown", this.keyboardListener);
  }

  container: React.RefObject<HTMLDivElement>;

  popupMapBoxGL: mapboxgl.Popup | undefined;

  popupOptions: mapboxgl.PopupOptions;

  keyboardListener = (event: KeyboardEvent) => {
    const {
      setOption,
      timeWeatherOptions,
      selectedOption: { value },
    } = this.props;

    const currentIndex = timeWeatherOptions.findIndex((el) => el.value === value);

    if (event.code === "ArrowDown") {
      if (timeWeatherOptions[currentIndex + 1]) setOption(timeWeatherOptions[currentIndex + 1]);
    } else if (event.code === "ArrowUp")
      if (timeWeatherOptions[currentIndex - 1]) setOption(timeWeatherOptions[currentIndex - 1]);
  };

  /** Данные для строки сравнения */
  getComparisionWeather = (): Forecast | null => {
    const {
      selectedOption: { value },
    } = this.props;
    const { forecast, selectedDay } = this.props;

    switch (value) {
      /** Прогноз на ближайшие 3 часа */
      case 0:
        if (forecast.hour3 && forecast.hour3.length > 0) return forecast.hour3[0];
        return null;

      /** Прогноз по дням */
      case 5:
      case 4:
      case 3:
      case 2:
      case 1:
        const back1day = moment(selectedDay).subtract(value, "day").get("D");
        if (forecast.day5 && forecast.day5.length > 0) {
          const back2dayForecasts = forecast.day5.filter((el) => moment(el.generateDate).get("D") === back1day);
          const back2dayForecast = back2dayForecasts.find((el) => moment(el.toDate).get("D") === back1day + value);
          if (back2dayForecast) return back2dayForecast;
        }
        return null;

      /** Прогноз на 36 часов текущий день */
      case 10:
        if (forecast.hour36 && forecast.hour36.length > 0) {
          const todayForecast = forecast.hour36.find((el) => checkRangeDate(el.fromDate, el.toDate, selectedDay));
          if (todayForecast) return todayForecast;
        }
        return null;

      /** Прогноз на 36 часов предыдущий день */
      case 20:
        if (forecast.hour36 && forecast.hour36.length > 0) {
          const yesterdayForecast = forecast.hour36.filter((el) => checkRangeDate(el.fromDate, el.toDate, selectedDay));
          const lastElement = yesterdayForecast[yesterdayForecast.length - 1];
          if (yesterdayForecast) return lastElement;
        }
        return null;

      default:
        return null;
    }
  };

  /** Строка с пустыми данными */
  renderDefaultRow = (title: string) => {
    return (
      <div className="cwp-data-fact">
        <WeatherRow title={title} imagesWeather={imagesWeather} isForecast />
      </div>
    );
  };

  render() {
    const { id, to, forecast, selectedOption, timeWeatherOptions, setOption, detailedIsOpen, setDetailView } =
      this.props;

    const fact = forecast.fact && forecast.fact.find((data) => data.spatialTypeId === id);
    const from = this.getComparisionWeather();
    const isForecast = moment().valueOf() - moment(to).valueOf() < 0;
    const titleFact = isForecast ? "Прогноз погоды" : "Фактическая";
    const district = districts.find((el) => el.id === id);

    return ReactDOM.createPortal(
      <div className="ctrl-weather-popup" ref={this.container}>
        <WeatherHeader district={district} />

        {fact && (
          <div className="cwp-data-fact">
            <WeatherRow
              title={titleFact}
              weatherType={fact.weatherType}
              imagesWeather={imagesWeather}
              isForecast={isForecast}
              temperatureFrom={fact.temperatureFrom}
              temperatureTo={fact.temperatureTo}
              pressure={fact.pressure}
              windDirection={fact.windDirection}
              avgWindSpeed={fact.avgWindSpeed}
              windGusts={fact.gust}
            />
          </div>
        )}

        {!fact && this.renderDefaultRow(titleFact)}

        {!detailedIsOpen && !isForecast && (
          <div className="cwp-data-show">
            <span onClick={() => setDetailView(!detailedIsOpen)}>
              <span className="cwp-data-show-text">Сравнить с прогнозом погоды</span>
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{
                  transform: detailedIsOpen ? "rotate(180deg)" : undefined,
                  verticalAlign: "middle",
                }}>
                <g opacity="0.87">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M0 0l6 4-6 4z" opacity="0.87" transform="rotate(90 3.5 12.5)" />
                </g>
              </svg>
            </span>
          </div>
        )}

        {detailedIsOpen && !isForecast && (
          <>
            {from && (
              <div className="cwp-data-fact">
                <WeatherRow
                  title="Прогноз за"
                  weatherType={from.weatherType}
                  imagesWeather={imagesWeather}
                  isForecast
                  temperatureFrom={from.temperatureFrom}
                  temperatureTo={from.temperatureTo}
                  pressure={from.pressure}
                  windDirection={from.windDirection}
                  avgWindSpeed={from.avgWindSpeed}
                  windGusts={from.gust}
                />
              </div>
            )}

            {!from && this.renderDefaultRow("Прогноз за")}

            <Select
              options={timeWeatherOptions}
              value={selectedOption}
              classNamePrefix="cwp-select"
              // @ts-ignore
              onChange={setOption}
              isSearchable={false}
              theme={themeSelect}
              styles={customStyles}
            />
          </>
        )}
      </div>,
      container
    );
  }
}

export default connector(WeatherPopup);
