import React from "react";
import { ButtonGroup } from "components/button-group";
import { CtrlHeatmap, CtrlTelemetry, TravelHeatmap } from "features";
import { useStoreContext } from "store";
import { APP_ENV } from "app-env";

export const PointsGroup = () => {
  const { store } = useStoreContext();
  const isTravelHeatmap = store.travelHeatmap.hooks.useIsActive();
  const isShowTravelHeatmap = !Boolean(APP_ENV.REACT_APP_CONFIGURATION?.rnisServices?.analytics === "hidden");

  if (isShowTravelHeatmap) {
    return (
      <ButtonGroup canBeOpen={isTravelHeatmap} className="points-group">
        <CtrlTelemetry />
        <TravelHeatmap />
        <CtrlHeatmap />
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup canBeOpen={isTravelHeatmap} className="points-group">
      <CtrlTelemetry />
      <CtrlHeatmap />
    </ButtonGroup>
  );
};
