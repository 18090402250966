import React, { memo } from "react";
import cn from "classnames";
import DatePicker, { ISHOWS, ITYPES } from "@megapolis/datepicker-dev";
import moment from "moment";
//@ts-ignore
import localization from "moment/locale/ru";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";

moment.updateLocale("ru", localization);

const baseClass = "sector-analysis-filter";

export interface DatePickerProps {
  dateFrom: string;
  dateTo?: string;
  onChange: (from: string | null, to?: string | null) => void;
  limitPeriod?: number | null;
  disabled?: boolean;
  type: "DATE" | "PERIOD";
}

export const CustomDatePicker = memo((props: DatePickerProps) => {
  const { dateFrom, dateTo, limitPeriod, disabled = false, onChange, type } = props;
  const className = cn(`${baseClass}__date`, { [`${baseClass}__date_disabled`]: disabled });
  return (
    <div className={className}>
      <DatePicker
        disabled={disabled}
        customClass={`${baseClass}__datepicker`}
        show={ISHOWS.DAYS}
        type={ITYPES[type]}
        from={dateFrom}
        to={dateTo && moment(dateTo).subtract(1, "s").toISOString()}
        maxDate={moment().toISOString()}
        limitPeriod={limitPeriod}
        format="DD.MM.YYYY"
        beforeViewDecorator
        beforeRenderDecorator={() => <CalendarIcon />}
        onChange={onChange}
        closeAfterChange
      />
    </div>
  );
});
