import mapboxgl from "mapbox-gl";
import moment from "moment";
import Popup from "../popup";
import { Incident } from "api/incident/incident-api-types";
import "./index.scss";

type Props = {
  map: mapboxgl.Map;
  layerId: string;
  iconUrl: string;
};

export class CityEventsPopup extends Popup {
  private popup: mapboxgl.Popup;
  private layers: string[];
  private iconUrl: string;

  constructor({ map, layerId, iconUrl }: Props) {
    super(map, { layerId, isClickOpen: true });
    this.popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      maxWidth: "600px",
    });
    this.iconUrl = iconUrl;
    this.layers = [layerId];
    this.add();
  }

  private get iconStyle() {
    return `
            background-image: url(${this.iconUrl});
            background-repeat: no-repeat;
            background-position: center, center;
        `;
  }

  private getInfoRow = (title: string, value: string) => {
    return `
            <div class="city-events-popup__info-row">
                <span class="city-events-popup__label">${title}:</span>
                <span class="city-events-popup__text">${value}</span>
            </div>
        `;
  };

  private getPopupHeader = (type: string, id: number) => {
    return `
            <header class="city-events-popup__header">
                <div class="city-events-popup__icon" style="${this.iconStyle}"></div>
                <span class="city-events-popup__title">${type} №${id}</span>
            </header>
        `;
  };

  private getFromJSON = (json: string) => {
    try {
      return JSON.parse(json);
    } catch {
      return {};
    }
  };

  private getPopupContent = (props: Incident) => {
    const { creationTime, address, description } = props;
    if (!creationTime || !address || !description) return;

    return `
            <main class="city-events-popup__content">
                ${this.getInfoRow("Дата и время", moment(creationTime).format("DD.MM.YYYY HH:MM"))}
                ${this.getInfoRow("Адрес", address)}
                <p class="city-events-popup__description">${description}</p>
            </main>
        `;
  };

  public readonly getPopupHTML = (props: Incident) => {
    const type = this.getFromJSON(props.type).name || "Неизвестное событие";
    return `
            <div class="city-events-popup">
                ${this.getPopupHeader(type, props.id)}
                ${this.getPopupContent(props)}
            </div>
        `;
  };
}
