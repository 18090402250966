import React, { memo, useMemo } from "react";
import cn from "classnames";
import { trafficIndexIcons } from "assets";

const getIcon = (score?: number, type = "city") => {
  if (typeof score !== "number") return trafficIndexIcons.disabled;

  if (type === "route") {
    if (score < 0.2) return trafficIndexIcons.a;
    if (0.2 <= score && score < 0.4) return trafficIndexIcons.b;
    if (0.4 <= score && score < 0.6) return trafficIndexIcons.v;
    if (0.6 <= score && score < 0.8) return trafficIndexIcons.g;
    if (0.8 <= score) return trafficIndexIcons.d;
  } else {
    if (score < 0.09) return trafficIndexIcons.a;
    if (0.09 <= score && score < 0.125) return trafficIndexIcons.b;
    if (0.125 <= score && score < 0.16) return trafficIndexIcons.v;
    if (0.16 <= score && score < 0.2) return trafficIndexIcons.g;
    if (0.2 <= score) return trafficIndexIcons.d;
  }

  return trafficIndexIcons.disabled;
};

type Props = {
  score: number;
  customClass?: string;
  type?: string;
};

export const TrafficIndexIcon = memo<Props>(({ score, customClass, type }) => {
  const className = useMemo(() => cn("ctrl-idz__value", customClass), [customClass]);

  return (
    <div
      className={className}
      data-cypress="route-idz"
      title={`Индекс дорожных заторов ${Math.round(score * 10000) / 100}%`}>
      {getIcon(score, type)}
    </div>
  );
});
