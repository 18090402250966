import { RectangleParams } from "../popup-controller";
import { getRectangleBounds } from "./get-rectangle-bounds";
import { distanceFromPointToLine } from "./distance-from-point-to-line";

interface GetDistanceFromPopupToPoint {
  rectParams: RectangleParams;
  width: number;
  height: number;
  x: number;
  y: number;
}

/**
 * Return distance from point to line
 */
export function getDistanceFromRectToPoint({ rectParams, width, height, x, y }: GetDistanceFromPopupToPoint) {
  const {
    coordinates: { translateY, translateX },
  } = rectParams;
  const { left, right, top, bottom } = getRectangleBounds({ x: translateX, y: translateY, width, height });
  const distanceToLeft = distanceFromPointToLine([x - width / 2, y], [left, top], [left, bottom]);
  const distanceToRight = distanceFromPointToLine([x + width / 2, y], [right, top], [right, bottom]);
  return Math.min(distanceToLeft, distanceToRight);
}
