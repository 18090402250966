import { CSSProperties } from "react";

const handleStyle: CSSProperties = {
  width: "20px",
  height: "20px",
  border: "4px solid #ffffff",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.161)",
  backgroundColor: "#000000",
};

export const baseClass = "segments-opacity-slider";
export const labelClass = `${baseClass}__label`;
export const labelFontClass = `${labelClass}-font`;
export const rangeMin = 0;
export const rangeMax = 1;
export const rangeStep = 0.01;
export const intersectionDelta = 0.035;
export const handlesStyle = [handleStyle, handleStyle, handleStyle];
