import { DirectionDataType, TransportFlowDataType } from "features/ctrl-detector/types";
import { useMemo } from "react";
import moment from "moment/moment";

export const useGetArrayLines = (lines?: TransportFlowDataType["lines"]) =>
  useMemo(() => {
    const avgSpeed: number[] = [];
    const avgFreewaySpeed: number[] = [];
    const avgOcc: number[] = [];
    const avgVolume: number[] = [];
    const avgUtilization: number[] = [];
    const avgDensity: string[] = [];
    const totalUnitsCount: number[] = [];

    lines?.forEach((line) => {
      avgSpeed.push(line.avgSpeed);
      avgFreewaySpeed.push(line.avgFreewaySpeed);
      avgOcc.push(line.avgOcc);
      avgVolume.push(line.avgVolume);
      avgUtilization.push(line.avgUtilization);
      totalUnitsCount.push(line.totalUnitsCount);
      avgDensity.push(line?.avgDensity.toFixed(0));
    });

    return {
      avgSpeed,
      avgFreewaySpeed,
      avgOcc,
      avgVolume,
      avgUtilization,
      totalUnitsCount,
      avgDensity,
    };
  }, [lines]);

type LineDataType = {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  pointRadius: number;
  pointStyle: string;
};

export const useGetStatisticData = (lastDayData: DirectionDataType["flowDataLastDayStat"]) =>
  useMemo(() => {
    if (!lastDayData) {
      return null;
    }

    const time: string[] = [];

    const freewaySpeedLine: LineDataType = {
      label: "Скорость своб. дороги, км/ч",
      data: [],
      fill: false,
      borderColor: "#C074E6",
      pointRadius: 3,
      pointStyle: "circle",
    };
    const speedLine: LineDataType = {
      label: "Средняя скорость, км/ч",
      data: [],
      fill: false,
      borderColor: "#3595FF",
      pointRadius: 3,
      pointStyle: "circle",
    };

    const occLine: LineDataType = {
      label: "Занятость дороги, %",
      data: [],
      fill: false,
      borderColor: "#3595FF",
      pointRadius: 3,
      pointStyle: "circle",
    };

    const utilizationLine: LineDataType = {
      label: "Загруженноость, %",
      data: [],
      fill: false,
      borderColor: "#3595FF",
      pointRadius: 3,
      pointStyle: "circle",
    };

    const densityLine: LineDataType = {
      label: "Плотность потока, ед/км",
      data: [],
      fill: false,
      borderColor: "#3595FF",
      pointRadius: 3,
      pointStyle: "circle",
    };

    const volumeLine: LineDataType = {
      label: "Интенсивность потока, ед/ч",
      data: [],
      fill: false,
      borderColor: "#3595FF",
      pointRadius: 3,
      pointStyle: "circle",
    };

    const totalUnitsCountLine: LineDataType = {
      label: "Количество ТС, ед",
      data: [],
      fill: false,
      borderColor: "#3595FF",
      pointRadius: 3,
      pointStyle: "circle",
    };

    lastDayData?.forEach((t, i) => {
      time.push(i === lastDayData?.length - 1 ? "" : moment(t.avgDataAllLines.from).format("HH:mm"));
      freewaySpeedLine.data.push(t.avgDataAllLines.avgFreewaySpeed);
      speedLine.data.push(t.avgDataAllLines.avgSpeed);
      occLine.data.push(t.avgDataAllLines.avgOcc);
      utilizationLine.data.push(t.avgDataAllLines.avgUtilization);
      densityLine.data.push(t.avgDataAllLines.avgDensity);
      volumeLine.data.push(t.avgDataAllLines.avgVolume);
      totalUnitsCountLine.data.push(t.avgDataAllLines.totalUnitsCount);
    });

    const speedDatasets: LineDataType[] = [freewaySpeedLine, speedLine];
    const volumeDatasets: LineDataType[] = [volumeLine];
    const densityDatasets: LineDataType[] = [densityLine];
    const utilizationDatasets: LineDataType[] = [utilizationLine];
    const occDatasets: LineDataType[] = [occLine];
    const totalUnitsCountDatasets: LineDataType[] = [totalUnitsCountLine];

    const speed = {
      labels: time,
      datasets: speedDatasets,
    };
    const volume = {
      labels: time,
      datasets: volumeDatasets,
    };
    const density = {
      labels: time,
      datasets: densityDatasets,
    };
    const utilization = {
      labels: time,
      datasets: utilizationDatasets,
    };
    const occ = {
      labels: time,
      datasets: occDatasets,
    };
    const totalUnitsCount = {
      labels: time,
      datasets: totalUnitsCountDatasets,
    };

    return {
      speed,
      volume,
      density,
      utilization,
      occ,
      totalUnitsCount,
    };
  }, [lastDayData]);
