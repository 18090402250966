export const MAP_CHANGE_STYLE = "MAP_CHANGE_STYLE";
export const RULER_UPDATE_IS_ACTIVE = "RULER_UPDATE_IS_ACTIVE";
export const DTP_UPDATE_IS_ACTIVE = "DTP_UPDATE_IS_ACTIVE";
export const CORRELATION_UPDATE_IS_ACTIVE = "CORRELATION_UPDATE_IS_ACTIVE";
export const METEOSTATION_UPDATE_IS_ACTIVE = "METEOSTATION_UPDATE_IS_ACTIVE";
export const CHANGE_IS_LOCAL_DISTORTIONS = "CHANGE_IS_LOCAL_DISTORTIONS";

export interface ChangeMapStyle {
  type: typeof MAP_CHANGE_STYLE;
  payload: string;
}

export interface RulerUpdateIsActive {
  type: typeof RULER_UPDATE_IS_ACTIVE;
  payload: boolean;
}

export interface DtpUpdateIsActive {
  type: typeof DTP_UPDATE_IS_ACTIVE;
  payload: boolean;
}

export interface CorrelationUpdateIsActive {
  type: typeof CORRELATION_UPDATE_IS_ACTIVE;
  payload: boolean;
}

export interface MeteostationUpdateIsActive {
  type: typeof METEOSTATION_UPDATE_IS_ACTIVE;
  payload: boolean;
}

export interface GlobalState {
  activeStyle: string;
  ruler: boolean;
  dtp: boolean;
  /** Флаг корреляции */
  correlation: boolean;
  meteostation: boolean;
  isLocalDistortions: boolean;
}
