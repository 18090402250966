import styled, { css } from "styled-components";

export const FullScreenContainer = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: initial;
`;

export const FullScreenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 24px;
  font-size: 14px;
  letter-spacing: 2px;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 26px;
`;

export const PhotoBlock = styled.div`
  height: 70vh;
  border-radius: 6px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
`;

export const MessageBlock = styled.div<{ isViolation?: boolean }>`
  user-select: initial;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%);
  width: 386px;
  padding: 16px;
  background: rgba(8, 8, 8, 0.8);
  ${(props) =>
    props.isViolation &&
    css`
      background: rgba(204, 46, 37, 0.9);
    `}
  border-radius: 6px;
  color: white;
  .bold {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.006em;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MiniPhotosBlock = styled.div`
  display: flex;
  gap: 5px;
  padding-top: 16px;
`;

export const MiniPhoto = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  width: 100px;
  height: 72px;
  ${(props) =>
    props.isActive &&
    css`
      border: 3px solid white;
    `}
  img {
    width: 98px;
    height: 68px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
