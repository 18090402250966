import styled, { css } from "styled-components";
import { ArrowBigDown } from "assets";

const GroupBlock = styled.div`
  ${(props: { borderTop?: boolean }) =>
    props.borderTop &&
    css`
      padding-top: 13px;
      border-top: 1px solid #e0e0e0;
    `}
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const CustomArrow = styled(ArrowBigDown)`
  width: 10px;
  cursor: pointer;
  ${(props: { isRight?: boolean }) =>
    props.isRight &&
    css`
      transform: rotate(-90deg);
    `}
`;

const GroupHeader = styled.div`
  span {
    font-weight: 600;
    font-size: 12px;
  }
  display: flex;
  gap: 9px;
  align-items: center;
`;

const GroupBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export { GroupBody, GroupBlock, GroupHeader, CustomArrow };
