import { RectangleBounds } from "./get-rectangle-bounds";

/**
 * Checks if the rectangle intersect the borders of the document or not
 */
export function checkIntersectDocument(rect: RectangleBounds) {
  const documentTop = 70;
  const documentBottom = document.documentElement.clientHeight;
  const documentLeft = 0;
  const documentRight = document.documentElement.clientWidth - 70;
  return (
    rect.top < documentTop || rect.bottom > documentBottom || rect.left < documentLeft || rect.right > documentRight
  );
}
