import { useStoreContext } from "store";

export const useIsEmptyPiecesLogic = () => {
  const { store } = useStoreContext();
  const value = store.applicationSettings.hooks.useIsEmptyPieces();

  return {
    value,
    handleChange: store.applicationSettings.events.handleTelematicsIsEmptyPieces,
  };
};
