import { memo } from "react";
import * as Layout from "layout";
import * as Features from "features";

export const TopPanel = memo(() => {
  return (
    <div className="app-controls-wrapper">
      <div className="app-controls-wrapper-group__route">
        <Layout.RouteGroup />
      </div>
      <div className="app-controls-wrapper__side app-controls-wrapper__side_group app-controls-wrapper-group">
        <div className="app-controls-wrapper-group__roads">
          <Layout.FilterGroup />
          <div className="app-controls-wrapper-group__roads__ctrl">
            <Layout.TrafficGroup />
          </div>
        </div>
      </div>
      <div className="app-controls-wrapper-group__filter-roads app-controls-wrapper-group__roads__ctrl">
        <Features.FilterRoads />
      </div>
      <div className="app-controls-wrapper__side app-controls-wrapper__side_points">
        <Layout.PointsGroup />
      </div>
      <Layout.ZoneStatisticsGroup />
      <Features.ApplicationSettings />
    </div>
  );
});
