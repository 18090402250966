import { memo } from "react";
import { APP_ENV } from "app-env";
import { SingleButton, BUTTON_FORM, IconSVGName } from "components";
import { useStoreContext } from "store";
import "./zone-statistics.scss";

export const ZoneStatistics = memo(() => {
  const { store } = useStoreContext();
  const isActive = store.zoneStatistics.hooks.useIsActive();
  const isDisabled = store.zoneStatistics.hooks.useIsDisabled();

  if (APP_ENV.REACT_APP_CONFIGURATION?.transportStatisticsInSelectedArea !== "on") return null;

  return (
    <div className="zone-statistics">
      <SingleButton
        disabled={isDisabled}
        isActive={isActive}
        onClick={store.zoneStatistics.events.handleZoneStatisticsClick}
        type={BUTTON_FORM.round}
        title="Анализ транспортной статистики в выделенной области"
        iconSvg={IconSVGName.zone}
      />
    </div>
  );
});
