import React, { useCallback } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { GState } from "documentations";
import { dispatch as observerDispatch, EVENTS } from "observer";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { CloseButton } from "components/close-button";
import { SettingsButton } from "utils/components/settings-button";
import { useSectorAnalyseContext } from "../sector-analysis";
import { useGetTitle } from "./hooks";

import "./index.scss";

const className = "sector-analysis-header";
const routeTitleBase = `${className}__route`;

const getCnPredicate = (className: string, isShowCompare: boolean) => {
  return {
    [`${className}-compare`]: isShowCompare,
  };
};

export const SectorAnalysisHeader = () => {
  const names = useGetTitle();
  const { settingSpeedColor } = useSectorAnalyseContext();
  const dispatch = useDispatch();
  const isAppearanceMenu = useSelector((state: GState) => state.sectorAnalysis.isAppearanceMenu, shallowEqual);
  const isShowCompare = useSelector((store: GState) => store.sectorAnalysis.isShowCompare);
  const handleSettingsButtonClick = useCallback(() => {
    settingSpeedColor.update();

    batch(() => {
      dispatch(sectorAnalysisSlice.actions.setIsAppearanceMenu(!isAppearanceMenu));
      dispatch(sectorAnalysisSlice.actions.setIsFiltersDisabled(!isAppearanceMenu));
      dispatch(sectorAnalysisSlice.actions.setIsDiagramDisabled(!isAppearanceMenu));
    });
  }, [isAppearanceMenu, dispatch]);
  const handleClickClose = useCallback(() => {
    observerDispatch(EVENTS.DIAGRAM_LEAVE);
    batch(() => {
      dispatch(sectorAnalysisSlice.actions.setIsAppearanceMenu(false));
      dispatch(sectorAnalysisSlice.actions.setIsActive(false));
    });
  }, [dispatch]);

  const baseClassName = cn(className, getCnPredicate(className, isShowCompare));
  const routeTitleClassName = cn(routeTitleBase, getCnPredicate(routeTitleBase, isShowCompare));

  return (
    <div className={baseClassName}>
      <div className={`${className}__header-info`}>
        <span className={`${className}__title`}>Анализ участка</span>
        <span className={routeTitleClassName} title={names}>
          {names}
        </span>
      </div>
      <div className={`${className}__controls`}>
        <SettingsButton isActive={isAppearanceMenu} onClick={handleSettingsButtonClick} />
        <CloseButton className={`${className}__close-button`} onClick={handleClickClose} />
      </div>
    </div>
  );
};
