import { createStore, sample } from "effector";
import { events } from "./zone-statistics.events";
import { effects } from "./zone-statistics.effects";
import * as reducers from "./zone-statistics.reducers";
import { initialStateFactory } from "./zone-statistics.utils";
import { State } from "./zone-statistics.types";

const initialState: State = initialStateFactory();

export const store = createStore(initialState);
store.on(events.setZone, reducers.handleSetZone);
store.on(events.setIsLoading, reducers.handleSetIsLoading);
store.on(events.setDateRange, reducers.handleSetDateRange);
store.on(events.setTimeRange, reducers.handleSetTimeRange);
store.on(events.setIsDisabled, reducers.handleSetIsDisabled);
store.on(events.setEsFromRedux, reducers.handleSetEsFromRedux);
store.on(events.handleZoneStatisticsClick, reducers.setNextIsVisible);
store.on(events.handleZoneStatisticsCloseClick, reducers.handleZoneStatisticsClose);
store.on(effects.startStatisticsLoading.doneData, reducers.handleSetStatistic);

sample({
  source: store,
  clock: events.handleZoneStatisticsClick,
  target: events.handleIsActiveChange,
});

// ------------– Загрузка после смены параметров ------------–

sample({
  clock: effects.loadStatistics.pending,
  target: events.setIsLoading,
});

sample({
  source: store,
  clock: events.setZone,
  target: effects.startStatisticsLoading,
});

sample({
  source: store,
  clock: events.setDateRange,
  target: effects.startStatisticsLoading,
});

sample({
  source: store,
  clock: events.setTimeRange,
  target: effects.startStatisticsLoading,
});
