import mapboxgl from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import moment from "moment";
import { CancelTokenSource } from "axios";
import { GState } from "documentations";
import { DTP_ID } from "map-helpers/order-layers";
import IncidentAPI from "api/incident/incident-api";
import { Incident } from "api/incident/model/incident";
import { DtpLayer } from "../map-layer/dtl-layer";
import { CityEventsPopup } from "map-helpers/popups/city-events-popup/city-events-popup";

export class DtpController {
  private dtpLayer: DtpLayer;
  private cancelToken?: CancelTokenSource = undefined;
  private dtpPopup?: CityEventsPopup;
  private token;

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.dtpLayer = new DtpLayer(map, DTP_ID, DTP_ID);
    this.map.on("style.load", this.update);
    this.update();
    this.dtpPopup = new CityEventsPopup({
      map: this.map,
      layerId: DTP_ID,
      iconUrl: "/img/dtp/popup-icon.svg",
    });

    this.token = store.getState().oidc.user.access_token;
  }

  public update = () => {
    const {
      dtp: { isActive },
    } = this.store.getState();

    this.cancelToken?.cancel();

    if (isActive) this.addLayer();
    else this.removeLayer();
  };

  private async addLayer() {
    const incidents = await this.fetchData();
    this.dtpLayer.add(incidents);
  }

  private removeLayer() {
    this.dtpLayer.remove();
  }

  private async fetchData() {
    const model = {
      CreateFrom: moment().startOf("day").format("YYYY-MM-DD"),
      Type: {
        id: 6,
      },
      Status: {
        id: 10,
      },
      PageSize: -1,
      token: this.token,
    };

    const { request, cancelToken } = await IncidentAPI.getIncidentList<{ items: Incident[] }>(model);
    this.cancelToken = cancelToken;

    const { data } = await request;
    const incidents = data?.items || [];
    return incidents;
  }
}
