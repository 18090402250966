import React, { useEffect, useState } from "react";
import { Exit, SpeedcamIcon } from "../../../../assets";
import { SpeedcamLastFixation } from "./components/speedcam-last-fixation";
import { SpeedcamStatistic } from "./components/speedcam-statistic";
import {
  ButtonsBlock,
  HeaderAddress,
  HeaderBlock,
  HeaderName,
  HeaderTitleBlock,
  PopupBody,
  PopupContainer,
  TitleWithIconBlock,
} from "./speedcam-popup.styled";

type Props = {
  id: number;
  num: string;
  address: string;
  overspeed?: number;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleFullScreenMode: (e: boolean) => void;
};

const getValidAddress = (str: string) => {
  let v = str.toLowerCase().split("республика")[1] || str;

  if (v.charAt(0) === ",") {
    v = v.slice(1);
  }

  return v;
};
export const SpeedcamPopup: React.FC<Props> = ({ handleFullScreenMode, id, address, overspeed, onClickClose }) => {
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);

  const addressWithoutState = getValidAddress(address);

  useEffect(() => {
    handleFullScreenMode(isFullScreenMode);
  }, [isFullScreenMode]);

  return (
    <PopupContainer className={isFullScreenMode ? "not-dragable-zone" : ""}>
      <HeaderBlock>
        <TitleWithIconBlock>
          <SpeedcamIcon />
          <HeaderTitleBlock>
            <HeaderName>{id}</HeaderName>
            <HeaderAddress>{addressWithoutState}</HeaderAddress>
          </HeaderTitleBlock>
        </TitleWithIconBlock>
        <ButtonsBlock className="not-dragable-zone">
          <Exit onClick={(e: any) => onClickClose(e)} />
        </ButtonsBlock>
      </HeaderBlock>
      <PopupBody>
        <SpeedcamLastFixation
          setIsFullScreenMode={setIsFullScreenMode}
          isFullScreenMode={isFullScreenMode}
          overspeed={overspeed}
          camId={id}
        />
        <SpeedcamStatistic camId={id} />
      </PopupBody>
    </PopupContainer>
  );
};
