import React from "react";
import { shared } from "shared";
import { State } from "./application-settings.types";

export const activeMapStyleReducer: Reducer<State, string | number> = (state, payload) => {
  const newActiveMapStyle = shared.map.mapStyles.find((mapStyle) => mapStyle.id === payload);
  if (!newActiveMapStyle) return state;

  return {
    ...state,
    activeMapStyle: newActiveMapStyle,
  };
};

export const isTelematicsEmptyPiecesReducer: Reducer<State, React.SyntheticEvent<HTMLInputElement>> = (
  state,
  payload
) => {
  return {
    ...state,
    telematics: {
      ...state.telematics,
      // @ts-ignore
      isEmptyPieces: payload.target.checked,
    },
  };
};

export const isPopupVisibleNextReducer: Reducer<State, React.MouseEventHandler<HTMLButtonElement>> = (state) => {
  return {
    ...state,
    isPopupVisible: !state.isPopupVisible,
  };
};

export const isPopupVisibleOutsideClickReducer: Reducer<State> = (state) => {
  return {
    ...state,
    isPopupVisible: false,
  };
};
