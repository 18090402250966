import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as ROUTE_ACTIONS from "features/ctrl-route/store/slice";
import { InputAutosuggest } from "components";
import { GeocoderAPI } from "api/geocoder";
import { GState } from "documentations";

type Props = {
  value: string;
  failed: boolean;
  handleClearButton: any;
  isLoading: boolean;
  mainPointsLength: number;
  onChange: any;
  onSuggestionSelected: any;
  noSuggestion: boolean;
  keyPoint: string;
  placeholder: string;
  noFavoritesSuggestion: boolean;
  alwaysRenderSuggestions: boolean;
};

export const RouteAddress: React.FC<Props> = (props) => {
  const {
    value,
    failed,
    handleClearButton,
    isLoading,
    mainPointsLength,
    onChange,
    onSuggestionSelected,
    noSuggestion,
    keyPoint,
    placeholder,
    noFavoritesSuggestion,
    alwaysRenderSuggestions,
  } = props;
  const dispatch = useDispatch();

  const { favorite, history } = useSelector(
    (state: GState) => ({
      favorite: state.favorite.items,
      history: state.history.items,
    }),
    shallowEqual
  );

  const [geocoder, setGeocoder] = useState<Array<any>>([]);
  const [user, setUser] = useState<Array<any>>([...favorite]);

  useEffect(() => {
    if (!Array.isArray(favorite)) return;
    setUser(favorite);
  }, [favorite]);

  const getSuggestions = (value: string) => {
    const inputValue = new RegExp(`${value.toLowerCase()}`);

    const suggestionFavorite = favorite.filter((fav: any) => {
      if (fav.name.toLowerCase().match(inputValue)) {
        return true;
      }
      if (fav.address.toLowerCase().match(inputValue)) {
        return true;
      }
      return false;
    });

    const suggestionHistory = history.filter((his: any) => {
      if (his.address.toLowerCase().match(inputValue)) {
        return true;
      }
      return false;
    });

    const user = [...suggestionFavorite, ...suggestionHistory];

    setUser(user);
  };

  const onSuggestionsFetchRequested = ({ value }: any) => {
    if (keyPoint && value.length === 0) {
      dispatch(ROUTE_ACTIONS.routerSlice.actions.setActivePointKey(keyPoint));
    }

    getSuggestions(value);

    if (!noSuggestion && value.length > 2) {
      GeocoderAPI.geocode.autoComplete(value).then((result) => {
        const geocoder = result.map((el) => ({
          address: el.address,
          location: el.coor,
          id: null,
          name: null,
          type: null,
        }));
        setGeocoder(geocoder);
      });
    }
  };

  let suggestions = !noSuggestion ? [...user, ...geocoder] : [];
  suggestions = noFavoritesSuggestion ? [...geocoder] : suggestions;
  suggestions = alwaysRenderSuggestions && !suggestions.length ? favorite : suggestions;

  return (
    <InputAutosuggest
      value={value || ""}
      failed={failed}
      handleClearButton={handleClearButton}
      isLoading={isLoading}
      mainPointsLength={mainPointsLength}
      onChange={onChange}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      placeholder={placeholder}
      suggestions={suggestions}
      alwaysRenderSuggestions={alwaysRenderSuggestions}
    />
  );
};
