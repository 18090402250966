import React, { Component } from "react";
import getImage from "./getImage";

class RouteAddressPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { index, points, item } = this.props;
    return (
      <div className="ctrl-route-drag-icon">
        <img src={getImage.generateImage(index, points, "point")} alt={item.key} />
      </div>
    );
  }
}

export default RouteAddressPoint;
