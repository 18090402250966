/* eslint-disable max-lines-per-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LngLat } from "mapbox-gl";
import * as ROUTE_SLICE from "features/ctrl-route/store/slice";
import { deleteHistoryAC, touchHistoryAC } from "old-store/user-history/action-creators";
import "./route-history-item.scss";

const className = "route-history-item";

class RouteHistoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }

  getKey() {
    const { points, activePoint } = this.props;
    let key = null;

    if (activePoint) {
      key = activePoint;
    } else {
      const point = points.find((el) => !el.address);
      if (point) {
        key = point.key;
      }
    }
    return key;
  }

  handleDeleteButton = (e) => {
    e.stopPropagation();
    const { id, address, deleteHistory } = this.props;
    const data = {
      options: {
        address,
        id,
      },
    };
    deleteHistory(data);
  };

  handleClick = () => {
    const { touchHistory, setNewAddress, address, id, location } = this.props;
    const coor = new LngLat(location.lng, location.lat);

    const key = this.getKey();
    if (key) {
      setNewAddress({
        key,
        address,
        coor,
        favoriteId: null,
        isFavorite: false,
        favoriteName: null,
      });

      const options = {
        address,
        id,
      };
      touchHistory({ options });
    }
  };

  render() {
    const { address } = this.props;
    const { isHover } = this.state;
    return (
      <div
        className={className}
        onClick={(e) => this.handleClick(e)}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}>
        <div className={`${className}-left`}>
          <div className={`${className}-last-icon`}>
            <i className="dtm-last-address-icon" />
          </div>
          <div className={`${className}-label`}>
            <div className={`${className}-address`} title={address}>
              {address}
            </div>
          </div>
        </div>
        <div className={`${className}-edit`}>
          {isHover && (
            <button type="button" onClick={(e) => this.handleDeleteButton(e)} className={`${className}-delete-button`}>
              <i className="dtm-close-icon" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

RouteHistoryItem.propTypes = {
  points: PropTypes.array,
  activePoint: PropTypes.string,
  deleteHistory: PropTypes.func,
  setNewAddress: PropTypes.func,
  touchHistory: PropTypes.func,
  address: PropTypes.string,
  id: PropTypes.number,
  location: PropTypes.any,
};

export default connect(
  (state) => ({
    points: state.router.points,
    activePoint: state.router.activePointKey,
  }),
  (dispatch) => ({
    deleteHistory: (data) => dispatch(deleteHistoryAC(data)),
    setNewAddress: (data) => dispatch(ROUTE_SLICE.updateByFavorite(data)),
    touchHistory: (data) => dispatch(touchHistoryAC(data)),
  })
)(RouteHistoryItem);
