import React from "react";
import { useStoreContext } from "store";
import styled from "styled-components";
import { GraphCardBody, GraphCardHeader } from "./components";

export const GraphCard = () => {
  const { store } = useStoreContext();
  const isVisible = store.graphCard.hooks.useIsVisible();

  if (!isVisible) {
    return null;
  }

  return (
    <GraphCardContainer>
      <GraphCardHeader />
      <GraphCardBody />
    </GraphCardContainer>
  );
};

const GraphCardContainer = styled.div`
  position: absolute;
  top: 75px;
  width: 350px;
  background-color: var(--background-color-default);
  left: 16px !important;
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
`;
