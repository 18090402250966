/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EVENTS, on } from "observer";
import { hideCurrentTrafficLayer } from "features/view-data/map-helpers";
import { correlationUpdateIsActive } from "old-store/global/actions";
import { BUTTON_FORM, SingleButton } from "components";
import { GState } from "documentations";
import CorrelationLayer from "map-helpers/layers/correlation-layer";
import { VolumetricCorrelation } from "./volumetric-correlation";
import CorrelationPopup from "map-helpers/popups/correlation-traffic-popup";

import "./ctrl-correlation.scss";

const className = "ctrl-correlation";

let correlationlayer: CorrelationLayer;
let volumetricCorrelation: VolumetricCorrelation;
let correlationPopup: CorrelationPopup;
let correlationPopupTimeout: number | undefined = undefined;
let map: mapboxgl.Map;

const minZoom = 17;
const maxPitch = 20;

const getIsVolumetric = () => map?.getZoom() >= minZoom && map?.getPitch() >= maxPitch;

const handleMapChange = () => {
  if (!volumetricCorrelation) return;
  const isVolumetric = getIsVolumetric();
  correlationlayer.setOpacity(!isVolumetric);
  volumetricCorrelation.isVisible = isVolumetric;
};

const handleMousemove = (event: mapboxgl.MapMouseEvent) => {
  if (!getIsVolumetric()) return;
  const hovered = volumetricCorrelation.raycast(event.point)?.[0];
  const canvas = map.getCanvas();
  if (!hovered) {
    canvas.style.cursor = "grab";
    window.clearTimeout(correlationPopupTimeout);
    return (correlationPopupTimeout = window.setTimeout(correlationPopup.hide, 250));
  }
  const properties = volumetricCorrelation.getProperties(hovered?.object.uuid);
  if (!properties) return;

  canvas.style.cursor = "pointer";
  window.clearTimeout(correlationPopupTimeout);
  correlationPopupTimeout = window.setTimeout(() => correlationPopup.show(event.lngLat, properties), 250);
};

const handleDrag = () => map && (map.getCanvas().style.cursor = "grabbing");
const handleMouseup = () => map && (map.getCanvas().style.cursor = "grab");

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

export const CtrlCorrelation = () => {
  const dispatch = useDispatch();

  const dataStore = useSelector(
    (state: GState) => ({
      type: state.traffic.type,
      filter: state.view.filter,
      es: state.view.es,
      from: state.view.from,
      to: state.view.to,
      mapInit: state.router.mapIsLoaded,
      selectedDay: state.view.selectedDay,
      correlationView: state.global.correlation,
      correlationDay: state.view.correlationDay,
      isCustomCorrelation: state.view.isCustomCorrelation,
      isLocalDistortions: state.global.isLocalDistortions,
      isRoadDetectorMode: state.roadDetector.isRoadDetectorMode,
    }),
    shallowEqual
  );

  const { type, filter, es, mapInit } = dataStore;
  const { selectedDay, from, to } = dataStore;
  const { correlationDay, correlationView, isCustomCorrelation, isLocalDistortions, isRoadDetectorMode } = dataStore;

  useEffect(() => {
    if (correlationlayer?.isLocalDistortions === isLocalDistortions) return;
    correlationlayer?.setIsLocalDistortions(isLocalDistortions);
  }, [isLocalDistortions]);

  useEffect(() => {
    if (!map || isRoadDetectorMode) return;
    hideCurrentTrafficLayer(map, correlationView);
    return () => {
      hideCurrentTrafficLayer(map, false);
    };
  }, [correlationView, isCustomCorrelation, isRoadDetectorMode]);

  const getDate = useCallback(
    (correlationDay) => {
      let _from: string | null = null;
      let _to: string | null = null;
      if (correlationView && isCustomCorrelation) {
        _from = `${moment(correlationDay).format("YYYY-MM-DD")}T${moment(from).format("HH:mm:ss")}`;
        _to = `${moment(correlationDay).format("YYYY-MM-DD")}T${moment(to).format("HH:mm:ss")}`;
      }

      return { from: _from, to: _to };
    },
    [selectedDay, from, to, correlationView, isCustomCorrelation]
  );

  useEffect(() => {
    const { from, to } = getDate(correlationDay);
    const addLayer = () => {
      if (correlationView) {
        correlationlayer.update({ filter, es, from, to });

        correlationPopup.add();
      }
    };

    if (mapInit && !isRoadDetectorMode) {
      correlationlayer = new CorrelationLayer(map);
      volumetricCorrelation = new VolumetricCorrelation(map);
      correlationPopup = new CorrelationPopup(map);

      if (!map.getLayer(volumetricCorrelation.id)) {
        map.addLayer(volumetricCorrelation);
      }

      if (correlationView) {
        if (getIsVolumetric()) {
          volumetricCorrelation.isVisible = true;
        } else {
          addLayer();
        }
      }

      map.on("style.load", addLayer);
      map.on("pitch", handleMapChange);
      map.on("zoom", handleMapChange);
      map.on("mousemove", handleMousemove);
      map.on("drag", handleDrag);
      map.on("mouseup", handleMouseup);
    }

    return () => {
      if (correlationPopup) correlationPopup.remove();

      map?.off("style.load", addLayer);
      map?.off("pitch", handleMapChange);
      map?.off("zoom", handleMapChange);
      map?.off("mousemove", handleMousemove);
      map?.off("drag", handleDrag);
      map?.off("mouseup", handleMouseup);

      if (map?.getLayer(volumetricCorrelation?.id)) {
        map.removeLayer(volumetricCorrelation.id);
      }
    };
  }, [isRoadDetectorMode, map, mapInit, filter, es, from, to, correlationDay, correlationView, isCustomCorrelation]);

  useEffect(() => {
    const { from, to } = getDate(correlationDay);
    if (mapInit && correlationView && !isRoadDetectorMode) correlationlayer.update({ from, to, filter, es });
  }, [isRoadDetectorMode, es, from, to, filter, mapInit, correlationView, isCustomCorrelation, correlationDay]);

  useEffect(() => {
    const { from, to } = getDate(correlationDay);
    const addLayer = () => {
      if (correlationView) {
        correlationlayer.update({ filter, es, from, to });

        correlationPopup.add();
      }
    };

    const removeLayer = () => {
      correlationlayer?.remove();

      correlationPopup?.remove();
    };

    if (correlationView && mapInit && !isRoadDetectorMode) addLayer();

    if (!correlationView && mapInit) removeLayer();
  }, [isRoadDetectorMode, correlationView, isCustomCorrelation, correlationDay]);

  useEffect(() => {
    if (type === "forecast") dispatch(correlationUpdateIsActive(false));
  }, [type]);

  const handleClick = useCallback(() => {
    const newValue = !correlationView;
    dispatch(correlationUpdateIsActive(newValue));
    if (!map?.getLayer(volumetricCorrelation?.id) && correlationView) {
      volumetricCorrelation = new VolumetricCorrelation(map);
      map.addLayer(volumetricCorrelation);
    }

    volumetricCorrelation.isVisible = newValue && getIsVolumetric();
  }, [correlationView, dispatch]);

  useEffect(() => {
    if (isRoadDetectorMode) {
      correlationlayer?.remove();
    }
  }, [isRoadDetectorMode, correlationView, isCustomCorrelation]);

  return (
    <div className={`${className}`}>
      <SingleButton
        isActive={correlationView}
        onClick={handleClick}
        disabled={type === "forecast"}
        title="Корреляция"
        type={BUTTON_FORM.round}
        iconName="dtm-correlation-icon"
        buttonGroupItem
      />
    </div>
  );
};
