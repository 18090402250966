import styled, { css } from "styled-components";
import { Play } from "assets";

export const FixationContainer = styled.div<{ isViolations?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: rgba(3, 40, 76, 0.1);
  min-height: 260px;
  ${(props) =>
    props.isViolations &&
    css`
      background-color: rgba(255, 222, 220, 0.6);
    `}
  border-radius: 8px;
`;

export const ErrorMessage = styled.div`
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(3, 40, 76, 0.66);
  text-align: center;
  height: 197px;
`;

export const StatusBlock = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(3, 40, 76, 0.66);
  font-size: 12px;
  width: 100%;
`;

export const Message = styled.div`
  width: 200px;
`;

export const Time = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlarmMessage = styled.div`
  color: #cc2e25;
  display: flex;
  gap: 12px;
`;

export const PhotosBlock = styled.div`
  display: flex;
  gap: 6px;
`;

export const MainPhotoBlock = styled.div`
  width: 280px;
  height: 197px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 197px;
    border-radius: 4px;
  }
`;

export const PlayIcon = styled(Play)`
  align-self: center;
  justify-self: center;
  cursor: pointer;
  z-index: 1;
`;

export const ToolsBlock = styled.div`
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 272px;
  height: 32px;
  background: rgba(47, 68, 85, 0.6);
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 6px;
  .iconBlock {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const AdditionalPhotosBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Photo = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 64px;
    height: 64px;
    border-radius: 2px;
  }

  ${(props) =>
    props.isActive &&
    css`
      img {
        width: 60px;
        height: 60px;
      }
      width: 64px;
      height: 64px;
      border: 3px solid #027bff;
    `}}
`;
