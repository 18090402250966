import * as Types from "../schemes.types";
import * as GraphConsts from "../../../utils/consts";

export class Bar {
  private segmentHeight = 0;

  constructor(private ctx: CanvasRenderingContext2D, private options: Types.Options) {
    this.calculateSegmentHeight();
  }

  private calculateSegmentHeight = () => {
    this.segmentHeight = Math.abs(GraphConsts.graphBodyBottom - GraphConsts.graphBodyTop) / 9; // @note body height;
  };

  public updateScores = (scores: Types.Options["scores"]) => {
    this.options.scores = scores;
    this.draw();
  };

  public readonly draw = (scale: number = 1, offset: number = 0) => {
    const startY = GraphConsts.graphBodyBottom;
    // 7 это стандартная ширина 15ти минутного интервала на графике при scale = 1
    const segmentWidth = 7 * scale;
    const delta = 2;
    const paddingSegmentWidth = segmentWidth - delta;
    const sidePadding = delta / 2;

    const drawColumn = (score: number, index: number) => {
      this.ctx.fillStyle = this.options.getColor(score);
      // 2px потому что при балле 0 нужно , чтобы высота столбика была 2px
      const height = score === 0 ? 2 : this.segmentHeight * score;

      const startX = 0.5 + delta * index + paddingSegmentWidth * index + offset;

      this.ctx.beginPath();
      this.ctx.moveTo(startX, 0);
      this.ctx.lineTo(startX, -height);
      this.ctx.lineTo(startX + paddingSegmentWidth + sidePadding, -height);
      this.ctx.lineTo(startX + paddingSegmentWidth + sidePadding, 0);
      this.ctx.fill();
      this.ctx.closePath();
    };

    this.ctx.save();
    this.ctx.translate(GraphConsts.bodyLeft, startY);
    this.ctx.globalAlpha = 0.5;

    Object.keys(this.options.scores)
      .sort((a, b) => {
        return new Date(a).valueOf() - new Date(b).valueOf();
      })
      .forEach((key, index) => {
        const score = this.options.scores[key].routeScore;
        drawColumn(score, index);
      });

    this.ctx.restore();
  };

  public readonly destroy = () => {};
}
