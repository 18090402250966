import React, { useEffect, useState } from "react";
import { GreenArrow, SpeedBlue } from "assets";
import { Fixation } from "./components/fixation";
import { useSubscribeFixations } from "./hooks";
import { FullScreen } from "./components/full-screen";
import { TitleBlock } from "../../speedcam-popup.styled";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "../../../../../../documentations";
import { speedcamSlice } from "../../../../store/slice";
import { AllowedSpeed, ButtonsBlock, LastFixationContainer } from "./speedcam-last-fixation.styled";
import { getOverSpeedMessage } from "./help";

type Props = {
  camId: number;
  overspeed?: number;
  setIsFullScreenMode: (e: boolean) => void;
  isFullScreenMode: boolean;
};

export const SpeedcamLastFixation = (props: Props) => {
  const { setIsSubscribe, isSubscribe, fixationPhoto, fixationData, fixationFilterButtons, violationFilterButtons } =
    useSubscribeFixations(props.camId);

  const dispatch = useDispatch();

  useEffect(() => {
    const overSpeed = Number(props.overspeed || fixationData?.overSpeed);
    dispatch(speedcamSlice.actions.setOverSpeed({ id: props.camId, overSpeed }));
  }, [props.overspeed, fixationData?.overSpeed]);

  const overSpeed = useSelector((state: GState) => state.speedcam.speedcams?.[props.camId]?.overSpeed);
  const overSpeedMessage = getOverSpeedMessage(overSpeed, Number(fixationData?.speed));
  const [isShowLastFixation, setIsShowLastFixation] = useState(true);

  const currentMode = useSelector((state: GState) => state.speedcam.speedcams?.[props.camId]?.currentMode);

  useEffect(() => {
    if (currentMode === "fixations") {
      dispatch(speedcamSlice.actions.setFilter({ id: props.camId, filter: undefined }));
    }
    if (currentMode === "violations") {
      dispatch(speedcamSlice.actions.setFilter({ id: props.camId, filter: "withViolations" }));
    }
  }, [currentMode]);

  return (
    <div>
      <TitleBlock>
        <div className="title">Последняя фиксация</div>
        <div onClick={() => setIsShowLastFixation(!isShowLastFixation)} className="not-dragable-zone">
          {isShowLastFixation ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
        </div>
      </TitleBlock>
      {isShowLastFixation && (
        <LastFixationContainer>
          <Fixation
            isSubscribe={isSubscribe}
            setIsSubscribe={setIsSubscribe}
            fixationPhoto={fixationPhoto}
            fixationTime={fixationData?.fixationTime}
            overSpeedMessage={overSpeedMessage}
            setIsFullScreenMode={props.setIsFullScreenMode}
          />

          <AllowedSpeed>
            <SpeedBlue /> Разрешенная скорость{" "}
            <span className={"speed"}>{isNaN(overSpeed) || overSpeed === 0 ? "н/д" : `${overSpeed} км/ч`}</span>
          </AllowedSpeed>
          <ButtonsBlock className="not-dragable-zone">
            {currentMode === "fixations" && fixationFilterButtons}
            {currentMode === "violations" && violationFilterButtons}
          </ButtonsBlock>
          <FullScreen
            setIsSubscribe={setIsSubscribe}
            isSubscribe={isSubscribe}
            overSpeedMessage={overSpeedMessage}
            isFullScreenMode={props.isFullScreenMode}
            setIsFullScreenMode={props.setIsFullScreenMode}
            fixationNumber={fixationData?.id}
            fixationPhoto={fixationPhoto}
            fixationTime={fixationData?.fixationTime}
          />
        </LastFixationContainer>
      )}
    </div>
  );
};
