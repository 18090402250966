import { AnyLayer } from "mapbox-gl";
import { Incident } from "api/incident/model/incident";
import { FLOODING_MAP_IMAGE_KEY } from "map-helpers/assets/map-svg-icons";
import { Events, MapLayer } from "map-helpers/new-layer/map-layer";
import getBeforeId from "map-helpers/order-layers";

export class FloodingLayer extends MapLayer {
  private data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: [],
  };

  public addLayer() {
    const layer: AnyLayer = {
      id: this.layerId,
      type: "symbol",
      source: this.sourceId,
      layout: {
        "icon-image": FLOODING_MAP_IMAGE_KEY,
        "icon-size": this.getDefaultIconSize(),
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
      },
    };
    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  public prepareData(incidents: Incident[]) {
    this.data.features = incidents.map((incident) => ({
      id: incident.id,
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [incident.lng!, incident.lat!],
      },
      properties: incident,
    }));
  }

  public add(incidents: Incident[]) {
    this.remove();
    this.prepareData(incidents);
    this.addSource();
    this.addLayer();
    this.dispatch(Events.addLayer);
  }

  protected addSource() {
    this.map.addSource(this.layerId, {
      type: "geojson",
      data: this.data,
    });
  }
}
