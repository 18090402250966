import mapboxgl from "mapbox-gl";
import * as THREE from "three";
import { Cylinder } from "./cylinder";
import { DETECTOR_ID, DETECTOR_VOLUMETRIC, ND_DETECTOR_ID } from "map-helpers/order-layers";

const detectorIconUrl = "img/textures/detector.png";
const borderDefaultColor = 0x0000ff;
const borderDefaultOPacity = 0.5;
const volume0Color = 0x59b200;
const volume1Color = 0x0262cc;

type DetectorProperties = { Id: number; volume0: number; volume1: number; azimuth: number; scale: number };

export class DetectorVolumetricLayer implements mapboxgl.CustomLayerInterface {
  public id = DETECTOR_VOLUMETRIC;
  public type: "custom" = "custom";
  public renderingMode: "2d" | "3d" = "3d";
  private renderer?: THREE.WebGLRenderer;
  private scene?: THREE.Scene;
  private camera?: THREE.Camera;
  private world?: THREE.Group;
  private raycaster?: THREE.Raycaster;
  private cameraTransform?: THREE.Matrix4;
  private cameraCenter?: mapboxgl.MercatorCoordinate;
  private iconMaterial?: THREE.MeshBasicMaterial;
  private fillSceneTimeout?: number;
  private isInitialization = true;
  private features: GeoJSON.Feature<GeoJSON.Point, any>[] = [];
  private properties: {
    [key: string]: {
      fixationsNum?: number;
    };
  } = {};
  private _selected: { [key: string]: THREE.Mesh } = {};
  private _visibility = false;

  public set isVisible(isVisible: boolean) {
    this._visibility = isVisible;
    this.isInitialization = this._visibility;

    if (!this._visibility) {
      this._selected = {};
      this.features = [];
      this.properties = {};
      this.clearScene();
    } else {
      this.handleViewportChange();
    }
  }

  public get visibility() {
    return this._visibility;
  }

  constructor(private map: mapboxgl.Map) {}

  private createBorder = (x: number, y: number) => {
    const innerRadius = 4;
    const outerRadius = 7;
    const thetaSegments = 18;
    const borderGeometry = new THREE.RingGeometry(innerRadius, outerRadius, thetaSegments);
    const borderMaterial = new THREE.MeshPhongMaterial({
      transparent: true,
      opacity: borderDefaultOPacity,
      color: borderDefaultColor,
    });
    const border = new THREE.Mesh(borderGeometry, borderMaterial);

    border.translateX(x);
    border.translateY(y);
    border.translateZ(0.05);
    return border;
  };

  private toRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
  };

  private renderDetector = (coordinates: [number, number], properties: DetectorProperties) => {
    if (!this.world || !this.cameraCenter) return;

    const { scale, azimuth } = properties;
    const mercator = mapboxgl.MercatorCoordinate.fromLngLat(coordinates as [number, number]);
    const dx = -(this.cameraCenter.x - mercator.x) / scale;
    const dy = (this.cameraCenter.y - mercator.y) / scale;
    const radius = 4;
    const volume0 = properties.volume0 ?? 0;
    const volume1 = properties.volume1 ?? 0;
    const height = 0.1 * volume0;
    const height1 = 0.1 * volume1;
    const iconHeight = 0.1;
    const z = height / 2 + iconHeight;
    const z1 = height1 / 2 + iconHeight;
    const radialSegments = 25;
    const radiusDelta = 0.04;

    const cylinder = new Cylinder(this.world, {
      radius: radius + (height <= height1 ? radiusDelta : 0),
      height,
      color: volume0Color,
      opacity: volume0 ? 0.5 : 0,
    })
      .translate(dx, dy, z)
      .rotate(Math.PI / 2, azimuth)
      .add();

    const cylinder1 = new Cylinder(this.world, {
      radius: radius + (height1 < height ? radiusDelta : 0),
      height: height1,
      color: volume1Color,
      opacity: volume1 ? 0.5 : 0,
    })
      .translate(dx, dy, z1)
      .rotate(Math.PI / 2, azimuth)
      .add();

    const iconGeometry = new THREE.CylinderGeometry(radius, radius, iconHeight, radialSegments);
    const icon = new THREE.Mesh(iconGeometry, this.iconMaterial);

    icon.translateX(dx);
    icon.translateY(dy);
    icon.translateZ(0.05);
    icon.rotateX(Math.PI / 2);
    icon.rotateY(azimuth);
    this.world.add(icon);

    if (cylinder.uuid) {
      this.properties[cylinder.uuid] = {
        fixationsNum: volume0,
      };
      this.properties[cylinder1.uuid] = {
        fixationsNum: volume1,
      };
    }
  };

  private clearScene = () => {
    if (!this.world) return;

    while (this.world.children.length) {
      const item = this.world.children[this.world.children.length - 1];
      const mesh = item as THREE.Mesh;
      if (!Array.isArray(mesh.material)) {
        mesh.material?.dispose?.();
      }
      mesh.geometry?.dispose?.();
      this.world.remove(item);
    }
    this.world.clear();
  };

  private fillScene = () => {
    if (!this.world || !this.cameraCenter || !this.iconMaterial) return;

    this.clearScene();

    const scale = this.cameraCenter.meterInMercatorCoordinateUnits();
    const bearing = this.toRadians(this.map.getBearing());
    const azimuth = Math.PI - bearing - Math.PI / 2;

    Object.values(this._selected).forEach((border) => this.world?.add(border));

    for (let i = 0; i < this.features.length; i++) {
      const { geometry, properties } = this.features[i];

      if (!properties) return;

      this.renderDetector(geometry.coordinates as [number, number], {
        ...properties,
        scale,
        azimuth,
      });
    }
  };

  private createLightning = () => {
    const skyColor = 0xb1e1ff; // light blue
    const groundColor = 0xb97a20; // brownish orange

    this.scene?.add(new THREE.AmbientLight(0xffffff, 0.25));
    this.scene?.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(-70, -70, 100).normalize();
    this.scene?.add(directionalLight);
  };

  private handleViewportChange = () => {
    if (!this._visibility) {
      this.features = [];
      this.properties = {};
      return this.clearScene();
    }

    window.clearTimeout(this.fillSceneTimeout);
    this.fillSceneTimeout = window.setTimeout(() => {
      this.features = this.map
        .querySourceFeatures(DETECTOR_ID, { sourceLayer: "detector" })
        .concat(this.map.querySourceFeatures(ND_DETECTOR_ID, { sourceLayer: "nd-detector" })) as GeoJSON.Feature<
        GeoJSON.Point,
        any
      >[];
      this.fillScene();
    }, 300);
  };

  public isSelected = (uuid: string) => {
    return !!this._selected[uuid];
  };

  public getProperties = (uuid: string) => {
    return this.properties[uuid];
  };

  public select = (id: string, { x, y }: { x: number; y: number }) => {
    if (!this.world) return;

    const border = this.createBorder(x, y);
    this.world.add(border);
    this._selected[id] = border;
  };

  public removeSelection = (id: string) => {
    if (!this.world) return;
    if (!this._selected[id]) return;
    this.world.remove(this._selected[id]);
    delete this._selected[id];
  };

  public raycast = (point: mapboxgl.Point) => {
    if (!this.scene || !this.camera || !this.raycaster) return;
    const mouse = new THREE.Vector2();

    // Координаты мыши относительно экрана переводятся в координаты в пределах от -1 до 1
    // @ts-ignore
    mouse.x = (point.x / (this.map.transform.width ?? 1)) * 2 - 1;
    // @ts-ignore
    mouse.y = 1 - (point.y / (this.map.transform.height ?? 1)) * 2;

    const camInverseProjection = this.camera.projectionMatrix.clone().invert();
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, viewDirection);
    return this.raycaster.intersectObjects(this.scene.children, true);
  };

  public onAdd = (map: mapboxgl.Map, gl: WebGLRenderingContext) => {
    this.camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 0.1, 1e6);
    this.scene = new THREE.Scene();
    this.world = new THREE.Group();
    this.scene.add(this.world);

    this.cameraCenter = mapboxgl.MercatorCoordinate.fromLngLat(this.map.getCenter(), 0);

    const { x, y, z } = this.cameraCenter;
    this.world.scale.setScalar(this.cameraCenter.meterInMercatorCoordinateUnits());
    this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z ?? 0).scale(new THREE.Vector3(1, -1, 1));

    const loader = new THREE.TextureLoader();
    const iconTexture = loader.load(detectorIconUrl);
    this.iconMaterial = new THREE.MeshBasicMaterial({
      map: iconTexture,
      side: THREE.FrontSide,
    });

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
    this.raycaster = new THREE.Raycaster();

    this.map.on("rotatestart", this.handleViewportChange);
    this.map.on("rotateend", this.handleViewportChange);
    this.map.on("dragstart", this.handleViewportChange);
    this.map.on("dragend", this.handleViewportChange);
    this.map.on("zoom", this.handleViewportChange);

    this.createLightning();
    this.handleViewportChange();
    this.map.triggerRepaint();
  };

  public onRemove = () => {
    this.map.off("rotatestart", this.handleViewportChange);
    this.map.off("rotateend", this.handleViewportChange);
    this.map.off("dragstart", this.handleViewportChange);
    this.map.off("dragend", this.handleViewportChange);
    this.map.off("zoom", this.handleViewportChange);
  };

  public prerender = () => {
    if (!this._visibility || !this.isInitialization) return;

    if (this.features.length) {
      return (this.isInitialization = false);
    }

    this.features = this.map
      .querySourceFeatures(DETECTOR_ID, { sourceLayer: "detector" })
      .concat(this.map.querySourceFeatures(ND_DETECTOR_ID, { sourceLayer: "nd-detector" })) as GeoJSON.Feature<
      GeoJSON.Point,
      any
    >[];

    this.handleViewportChange();
  };

  public render = (gl: WebGLRenderingContext, matrix: number[]) => {
    if (!this._visibility) return;
    if (!this.renderer || !this.scene || !this.camera || !this.cameraTransform) return;

    this.camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix).multiply(this.cameraTransform);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
  };
}
