import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { GState } from "documentations";
import { routerSlice } from "../../store";

import "./route-diagram-button.scss";

const base = "route-diagram__button";

const useIsRouteDiagram = () => useSelector((state: GState) => state.router.isRouteDiagram);
const useIsDisabled = () =>
  useSelector(
    (state: GState) => !!!state.router.routeVariants?.[state.router.activeIndexRoute].paintedPath?.features.length
  );

export const RouteDiagramButton: React.FC = () => {
  const isRouteDiagram = useIsRouteDiagram();
  const isDisabled = useIsDisabled();
  const className = useMemo(
    () => cn(base, { [`${base}_active`]: isRouteDiagram, [`${base}_disabled`]: isDisabled }),
    [isRouteDiagram, isDisabled]
  );
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(routerSlice.actions.setIsRouteDiagram(!isRouteDiagram));
  }, [isRouteDiagram, dispatch]);

  return (
    <button disabled={isDisabled} className={className} onClick={handleClick}>
      3D
    </button>
  );
};
