import { ToggleButtonType } from "components";

export const baseClass = "diagram-view-mode-toggle";
export const buttons: [ToggleButtonType, ToggleButtonType] = [
  {
    id: "0",
    text: "2D",
  },
  {
    id: "1",
    text: "3D",
  },
];
