export namespace TravelHeatmapTypes {
  export type DirectionMode = "from" | "in";
  export type TransportType = "carsharing" | "taxi";
  export enum DirectionModeEnum {
    from = "from",
    in = "in",
  }
  export enum TransportTypeEnum {
    carsharing = "carsharing",
    taxi = "taxi",
  }

  export type H3IndexFeature = {
    h3Index: string;
    properties: H3IndexFeatureProperties;
  };

  export type H3IndexesFeatureCollection = H3IndexFeature[];

  export type H3IndexFeatureProperties = {
    H3_16X: string;
    trips?: number;
    color?: string;
    Value?: number;
    address?: string;
    averageTrips?: number;
    centr_lng: number;
    centr_lat: number;
  };

  export type SelectOption = {
    label: string;
    value: string;
  };

  export type SectorInformation = {
    h3Index: string;
    address: string;
    adminAreaString: string;
    adminAreaShortString: string;
  };

  export type PeriodChangePayload = { from: string; to: string };

  export type LoadSectorInfoPayload = {
    h3Index: string;
    coordinates: number[] | [number, number];
  };

  // @deprecated type
  export type RequestStatisticPayload = {
    dateStart: string;
    dateEnd: string;
    h3Indexes: string[];
    weekdays: number[];
    transportType: TravelHeatmapTypes.TransportType;
    directionMode: "start" | "end";
    timeStartSecs: number;
    timeEndSecs: number;
    maxTrips?: number;
  };

  export class FilterParams {
    private static defaultStartDate = new Date("2021-09-07").toJSON();
    private static defaultEndDate = new Date("2021-09-16").toJSON();
    private static defaultTransportType = "taxi" as const;
    private static defaultDirectionMode = "start" as const;
    private static defaultTimeStartSecs = 0;
    private static defaultTimeEndSecs = 24 * 3600;
    private static defaultWeekDays = [1, 2, 3, 4, 5, 6, 7];

    constructor(
      public dateStart: string,
      public dateEnd: string,
      public transportType: TravelHeatmapTypes.TransportType,
      public directionMode: "start" | "end",
      public timeStartSecs: number,
      public timeEndSecs: number,
      public weekdays: number[]
    ) {}

    private parseWeekdays = (weekdays: number[]) => {
      return weekdays.reduce((acc, weekday, index) => {
        if (index === weekdays.length - 1) return acc.concat(String(weekday));
        return acc.concat(`${weekday},`);
      }, "");
    };

    public toString() {
      const params = new URLSearchParams();

      params.set("DateStart", this.dateStart);
      params.set("DateEnd", this.dateEnd);
      params.set("TypeTS", this.transportType);
      params.set("DirectionTrip", this.directionMode);
      params.set("TimeStartInsideDay", String(this.timeStartSecs));
      params.set("TimeEndInsideDay", String(this.timeEndSecs));

      if (this.weekdays.length) {
        const weekdaysString = this.parseWeekdays(this.weekdays);
        params.set("WeekDays", weekdaysString);
      }

      return params.toString();
    }

    public toJSON() {
      try {
        return JSON.stringify({
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          transportType: this.transportType,
          directionMode: this.directionMode,
          timeStartSecs: this.timeStartSecs,
          timeEndSecs: this.timeEndSecs,
          weekdays: this.weekdays,
        });
      } catch {
        return "";
      }
    }

    public static getDefault = () =>
      new TravelHeatmapTypes.FilterParams(
        this.defaultStartDate,
        this.defaultEndDate,
        this.defaultTransportType,
        this.defaultDirectionMode,
        this.defaultTimeStartSecs,
        this.defaultTimeEndSecs,
        this.defaultWeekDays
      );
  }

  export type MinMaxFilter = [number, number];
}
