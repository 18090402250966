import { createAsyncThunk } from "@reduxjs/toolkit";
import { CdnAPI } from "api";

// Загрузка справочника избранных маршрутов
export const fetchFavoriteRoadsDictionary = createAsyncThunk(
  "router/fetchFavoriteRoadsDictionary",
  async (_: any, thunkAPI) => {
    return await CdnAPI.getFavoriteRoadsDictionary({ signal: thunkAPI.signal });
  }
);
