import React from "react";
import { useStoreContext } from "store";
import { DaysChart } from "./days-chart";
import { HoursChart } from "./hours-chart";

export const useGraphic = () => {
  const daysCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const hoursCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const hoursChart = React.useRef<HoursChart | undefined>();
  const daysChart = React.useRef<DaysChart | undefined>();
  const { store } = useStoreContext();
  const { days, hours } = store.travelHeatmap.hooks.useGraphicData();

  React.useEffect(() => {
    if (!hoursCanvasRef.current || !hours.length) return;
    if (!hoursChart.current) {
      hoursChart.current = new HoursChart(hoursCanvasRef.current);
    }

    hoursChart.current.setData(hours);
  }, [hoursCanvasRef, hoursChart, hours]);

  React.useEffect(() => {
    if (!daysCanvasRef.current || !days.length) return;
    if (!daysChart.current) {
      daysChart.current = new DaysChart(daysCanvasRef.current);
    }

    daysChart.current.setData(days);
  }, [daysCanvasRef, daysChart, days]);

  return {
    daysCanvasRef,
    hoursCanvasRef,
  };
};
