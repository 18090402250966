import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { sectorAnalysisSlice } from "../../../../store";

export const useIsUnitsCountDiagram = () => useSelector((state: GState) => state.sectorAnalysis.isUnitsCountDiagram);

export const useHandleChange = () => {
  const isUnitsCountDiagram = useIsUnitsCountDiagram();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (id: string) => {
      if (!id) return;
      dispatch(sectorAnalysisSlice.actions.setIsUnitsCountDiagram(!!parseInt(id)));
    },
    [isUnitsCountDiagram, dispatch]
  );

  return handleChange;
};
