import { AnalysisCell } from "./diagram-3d.types";

type Quarter = 1 | 2 | 3 | 4;
export type CachedCell = {
  x: number;
  y: number;
  z: number;
  rowIndex: number;
  columnIndex: number;
  box: {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
  };
  cell: AnalysisCell;
};
type Cache = { [key in Quarter]: CachedCell[] };

const getCleanCache = (): Cache => ({
  1: [],
  2: [],
  3: [],
  4: [],
});

export class CellsCache {
  private cache: Cache = getCleanCache();

  public get firstQuarter() {
    return this.cache[1];
  }

  public get secondQuarter() {
    return this.cache[2];
  }

  public get thirdQuarter() {
    return this.cache[3];
  }

  public get fourthQuarter() {
    return this.cache[4];
  }

  private addToFirstQuarter = (cell: CachedCell) => {
    this.firstQuarter.push(cell);
  };

  private addToSecondQuarter = (cell: CachedCell) => {
    this.secondQuarter.push(cell);
  };

  private addToThirdQuarter = (cell: CachedCell) => {
    this.thirdQuarter.push(cell);
  };

  private addToFourthQuarter = (cell: CachedCell) => {
    this.fourthQuarter.push(cell);
  };

  private getSearchPredicate = (x: number, y: number) => (cell: CachedCell) => {
    const { box } = cell;
    return x >= box.startX && x <= box.endX && y <= box.startY && y >= box.endY;
  };

  public readonly getFromFirstQuarter = (x: number, y: number) => {
    return this.firstQuarter.find(this.getSearchPredicate(x, y));
  };

  public readonly getFromSecondQuarter = (x: number, y: number) => {
    return this.secondQuarter.find(this.getSearchPredicate(x, y));
  };

  public readonly getFromThirdQuarter = (x: number, y: number) => {
    return this.thirdQuarter.find(this.getSearchPredicate(x, y));
  };

  public readonly getFromFourthQuarter = (x: number, y: number) => {
    return this.fourthQuarter.find(this.getSearchPredicate(x, y));
  };

  public readonly add = (cell: CachedCell) => {
    const { x, y } = cell;
    const isPositiveX = x >= 0;
    const isPositiveY = y >= 0;
    const isNegativeX = x <= 0;
    const isNegativeY = y <= 0;

    if (isPositiveX && isPositiveY) return this.addToFirstQuarter(cell);
    if (isNegativeX && isPositiveY) return this.addToSecondQuarter(cell);
    if (isNegativeX && isNegativeY) return this.addToThirdQuarter(cell);
    if (isPositiveX && isNegativeY) return this.addToFourthQuarter(cell);
  };

  public readonly getCell = (point: { x: number; y: number }) => {
    const { x, y } = point;
    const isPositiveX = x >= 0;
    const isPositiveY = y >= 0;
    const isNegativeX = x <= 0;
    const isNegativeY = y <= 0;

    if (isPositiveX && isPositiveY) return this.getFromFirstQuarter(x, y);
    if (isNegativeX && isPositiveY) return this.getFromSecondQuarter(x, y);
    if (isNegativeX && isNegativeY) return this.getFromThirdQuarter(x, y);
    if (isPositiveX && isNegativeY) return this.getFromFourthQuarter(x, y);
  };

  public readonly clear = () => {
    this.cache = getCleanCache();
  };
}
