import * as consts from "./consts";

export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) return 0;

  ctx.font = font;
  return ctx.measureText(text).width;
};

export const getDefaultValue = () => consts.defaultHandlesValue.map(({ value }) => value);
