import React from "react";
import moment from "moment";
import { DayOfWeek } from "api/router/model/models";
import { ButtonKey } from "../types";

type HandleButtonClick = (key: ButtonKey) => void;

export const baseClass = "multi-day-selector";

export const allOptions = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export const buttonsSource = [
  { key: ButtonKey.TODAY, label: "Сегодня" },
  { key: ButtonKey.YESTERDAY, label: "Вчера" },
  { key: ButtonKey.BEFORE_YESTERDAY, label: "Позавчера" },
  { key: ButtonKey.SPLW, label: "АППН" },
  { key: ButtonKey.SPLM, label: "АППМ" },
  { key: ButtonKey.SPLY, label: "АППГ" },
];

export const options = [
  { value: DayOfWeek.Monday, label: "Пн", shortName: "пн" },
  { value: DayOfWeek.Tuesday, label: "Вт", shortName: "вт" },
  { value: DayOfWeek.Wednesday, label: "Ср", shortName: "ср" },
  { value: DayOfWeek.Thursday, label: "Чт", shortName: "чт" },
  { value: DayOfWeek.Friday, label: "Пт", shortName: "пт" },
  { value: DayOfWeek.Saturday, label: "Сб", shortName: "сб" },
  { value: DayOfWeek.Sunday, label: "Вс", shortName: "вс" },
];

export const getButtons = (handleClick?: HandleButtonClick) => {
  return buttonsSource.map((source) => {
    return (
      <button key={`${source.key}_button`} onClick={() => handleClick?.(source.key)}>
        {source.label}
      </button>
    );
  });
};

const next = (date: string) => moment(date).add(1, "day").toISOString(true);
const prev = (date: string) => moment(date).subtract(1, "day").toISOString(false);
const getToday = () => moment().toISOString(true);
const getYesterday = () => prev(getToday());
const getBeforeYesterday = () => prev(prev(getToday()));
const getSamePeriodLastWeek = (from: string, to?: string) => {
  return {
    from: moment(from).subtract(1, "week").toISOString(false),
    to: moment(to).subtract(1, "week").subtract(1, "day").toISOString(false),
  };
};
const getSamePeriodLastMonth = (from: string, to?: string) => {
  return {
    from: moment(from).subtract(1, "month").toISOString(false),
    to: moment(to).subtract(1, "month").subtract(1, "day").toISOString(false),
  };
};
const getSamePeriodLastYear = (from: string, to?: string) => {
  return {
    from: moment(from).subtract(1, "year").toISOString(false),
    to: moment(to).subtract(1, "year").toISOString(false),
  };
};

const dateActions: { [key: string]: (from: string, to?: string) => string | { from: string; to: string } } = {
  [ButtonKey.TODAY]: getToday,
  [ButtonKey.YESTERDAY]: getYesterday,
  [ButtonKey.BEFORE_YESTERDAY]: getBeforeYesterday,
  [ButtonKey.SPLW as string]: getSamePeriodLastWeek,
  [ButtonKey.SPLM as string]: getSamePeriodLastMonth,
  [ButtonKey.SPLY as string]: getSamePeriodLastYear,
};

export const date = {
  next,
  prev,
  getToday,
  getYesterday,
  getBeforeYesterday,
  getSamePeriodLastWeek,
  getSamePeriodLastMonth,
  getSamePeriodLastYear,
  actions: dateActions,
};
