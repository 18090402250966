import React, { useMemo } from "react";
import { RouteManeuver as RouteManeuverType } from "features/ctrl-route/types";
import { formatDistanceInInstructions } from "features/ctrl-route/utils/format-distance-in-instructions";
import "./index.scss";

const baseClass = "route-maneuver";

type Props = RouteManeuverType & {
  /** Route type */
  costing: string;
  /** What time to render absolute or minutes */
  isAbsoluteTime: boolean;
  /** Maneuver end time */
  arrivedTime: string;
  /** Click listener */
  onClick: () => void;
};

export const RouteManeuver: React.FC<Props> = ({
  costing,
  isAbsoluteTime,
  type,
  time,
  instruction,
  length,
  arrivedTime,
  onClick,
}) => {
  const distance = formatDistanceInInstructions(length);
  const spentTime = useMemo(() => Math.round(time / 60), [time]);
  return (
    <li className={baseClass}>
      <button className={`${baseClass}__button`} onClick={onClick}>
        <img
          className={`${baseClass}__img`}
          src={`/img/route/route-${type}.svg`}
          width="20"
          height="20"
          alt={instruction}
        />
        <span className={`${baseClass}__body`}>
          <span className={`${baseClass}__instruction`}>{instruction}</span>
          <span className={`${baseClass}__length`}>{distance}</span>
        </span>
        {costing === "Multimodal" && !isAbsoluteTime && (
          <span className={`${baseClass}__arrived-time`}>{spentTime || 1}м</span>
        )}
        {isAbsoluteTime && <span className={`${baseClass}__arrived-time`}>{arrivedTime}</span>}
      </button>
    </li>
  );
};
