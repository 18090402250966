export type RectangleBounds = {
  left: number;
  bottom: number;
  right: number;
  top: number;
};

interface GetRectangeBounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * Return rectangle bounds
 */
export function getRectangleBounds({ x, y, width, height }: GetRectangeBounds): RectangleBounds {
  return { left: x, top: y, bottom: y + height, right: x + width };
}
