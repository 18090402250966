import mapboxgl from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import Cookies from "js-cookie";
import { APP_ENV } from "app-env";
import { Utils } from "./map-controller.utils";
import {
  TransportHubs,
  PedestrianGraph,
  AdministrativeDivision,
  TravelHeatmap,
  BusStops,
  ZoneStatistics,
} from "./modules";
import { Layers } from "./layers";
import { Events, Handlers } from "./map-controller.events";

// @note поправить api, перенести в utils
import { icons } from "map-helpers/assets/map-svg-icons";

mapboxgl.accessToken = APP_ENV.REACT_APP_MAP_ACCESS_TOKEN;

type Options = {
  accessToken: string;
};

export class MapController extends TypedEmitter<Handlers> {
  public events = Events;
  public busStops?: BusStops;
  public transportHubs?: TransportHubs;
  public pedestrianGraph?: PedestrianGraph;
  public administrativeDivision?: AdministrativeDivision;
  public travelHeatmap?: TravelHeatmap;
  public zoneStatistics?: ZoneStatistics;
  private map?: mapboxgl.Map;

  public set accessToken(value: string) {
    this.options.accessToken = value;
  }

  constructor(private options: Options) {
    super();
    this.createMap();
    this.subscribe();
  }

  private createMap = () => {
    const activeStyleId = Cookies.get("map-style");

    this.map = new mapboxgl.Map({
      container: "map",
      style: Utils.getStyleById(activeStyleId),
      zoom: 10,
      center: APP_ENV.REACT_APP_MAP_CENTER,
      fadeDuration: 0,
      maxZoom: 21,
      antialias: true,
      transformRequest: (url) => {
        if (url.match(/mt0.google.com/)) return { url };

        return {
          url,
          headers: { Authorization: `Bearer ${this.options.accessToken}` },
        };
      },
    });
  };

  private addMissingImage = (e: { id: string }) => {
    const key = e.id as keyof typeof icons;
    const svgString = icons[key];
    const src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
    const newImage = new Image();
    newImage.width = 38;
    newImage.height = 38;
    newImage.setAttribute("src", src);
    newImage.onload = () => {
      if (this.map?.hasImage(key)) return;
      this.map?.addImage(key, newImage);
    };
  };

  private handleCursorMousemove = (e: mapboxgl.MapMouseEvent) => {
    if (!this.map) return;

    const features = this.map.queryRenderedFeatures(Utils.getFeatureBox(e.point));
    const clearCursor = () => this.map && (this.map.getCanvas().style.cursor = "");

    if (features.length <= 0) return clearCursor();

    let topLayer = features[0].layer.id;
    const intermediatePoint = features.find((el) => el.layer.id.match(/route-intermediate-point-\w/i));

    if (Layers.helpers.includes(topLayer) && features.length > 1) topLayer = features[1].layer.id;
    if (!Layers.cursorOrder.includes(topLayer) && !intermediatePoint) return clearCursor();

    this.map.getCanvas().style.cursor = "pointer";
  };

  public createTravelHeatmap() {
    if (!this.map) return;

    this.travelHeatmap = new TravelHeatmap(this.map);
  }

  private handleMapLoad = () => {
    if (!this.map) return;

    this.busStops = new BusStops(this.map);
    this.transportHubs = new TransportHubs(this.map);
    this.pedestrianGraph = new PedestrianGraph(this.map);
    this.administrativeDivision = new AdministrativeDivision(this.map);
    this.zoneStatistics = new ZoneStatistics(this.map);

    this.emit(this.events.load, this.map);
  };

  private handleMapRemove = () => {
    this.emit(this.events.remove);
  };

  private handleZoomEnd = () => {
    if (!this.map) return;

    const bounds = this.getBounds();
    if (!bounds) return;

    this.emit(this.events.viewBoxChange, bounds.nw, bounds.se);
  };

  private subscribe = () => {
    this.map?.on("load", this.handleMapLoad);
    this.map?.on("remove", this.handleMapRemove);
    this.map?.on("styleimagemissing", this.addMissingImage);
    this.map?.on("mousemove", this.handleCursorMousemove);
    this.map?.on("zoomend", this.handleZoomEnd);
    this.map?.on("dragend", this.handleZoomEnd);
    this.map?.on("pitchend", this.handleZoomEnd);
  };

  private unsubscribe = () => {
    this.map?.off("load", this.handleMapLoad);
    this.map?.off("remove", this.handleMapRemove);
    this.map?.off("styleimagemissing", this.addMissingImage);
    this.map?.off("mousemove", this.handleCursorMousemove);
    this.map?.off("zoomend", this.handleZoomEnd);
    this.map?.off("dragend", this.handleZoomEnd);
    this.map?.off("pitchend", this.handleZoomEnd);
  };

  public getBounds = () => {
    if (!this.map) return;
    const bounds = this.map.getBounds();

    const nw = bounds.getNorthWest();
    const se = bounds.getSouthEast();
    return { nw, se };
  };

  public readonly setMapStyle = (mapStyle: MapStyle) => {
    if (!mapStyle.style) return;

    this.map?.setStyle(mapStyle.style);
  };

  public readonly destroy = () => {
    this.unsubscribe();
    this.busStops?.destroy();
    this.transportHubs?.destroy();
    this.pedestrianGraph?.destroy();
    this.administrativeDivision?.destroy();
    this.travelHeatmap?.destroy();
    this.zoneStatistics?.destroy();
  };
}
