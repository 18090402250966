import TrafficProperties from "map-helpers/properties/traffic-properties";
import RouteProperties from "map-helpers/properties/route-properties";
import getBeforeId, { ROUTE_PATH } from "../order-layers";
import Layer from "./layer";

const layerId = ROUTE_PATH;

export default class RouteLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    /** @type {mapboxgl.Map} */
    this.map = map;
    this.trafficProperties = new TrafficProperties();
    this.routeProperties = new RouteProperties();
  }

  addLayer() {
    const layer = {
      id: layerId,
      type: "line",
      source: layerId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": this.trafficProperties.getLineColorStandart(false),
        "line-width": this.routeProperties.lineWidth,
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  addSource() {
    if (this.data)
      this.map.addSource(layerId, {
        type: "geojson",
        data: this.data,
        tolerance: 0.15,
      });
  }

  setIsUnitsCountVisibility = (isUnitsCountVisibility) => {
    if (!this.map.getLayer(this.layerId)) return;
    this.map.setPaintProperty(
      this.layerId,
      "line-color",
      this.trafficProperties.getLineColorStandart(isUnitsCountVisibility)
    );
  };
}
