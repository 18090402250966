import mapboxgl from "mapbox-gl";
import { shared } from "shared";
import { TravelHeatmapTypes } from "types";
import { Layers } from "../../layers";
import { Feature, FeatureCollection } from "./travel-heatmap.types";
import { APP_ENV } from "app-env";

const before = "place_label_other";

export class TravelHeatmapLayer2D {
  public readonly layerId = Layers.Identifiers.TRAVEL_HEATMAP_ID;
  public readonly borderLayerId = Layers.Identifiers.TRAVEL_HEATMAP_BORDER_ID;
  public readonly activeBorderLayerId = Layers.Identifiers.TRAVEL_HEATMAP_ACTIVE_ID;
  public readonly hoverBorderLayerId = Layers.Identifiers.TRAVEL_HEATMAP_HOVER_ID;
  public activeItemIndex: Feature[] = [];
  private colorPrice = 0;
  private params = TravelHeatmapTypes.FilterParams.getDefault();
  private isVisible = false;
  private minMaxFilter: TravelHeatmapTypes.MinMaxFilter = [0, 1000000000];

  constructor(private readonly map: mapboxgl.Map) {
    this.addGeoJsonSource(this.activeBorderLayerId);
    this.addGeoJsonSource(this.hoverBorderLayerId);
    this.map.addSource(this.layerId, {
      type: "vector",
      tiles: [this.getTileUrl(this.params)],
    });

    this.map.addLayer(
      {
        "id": this.layerId,
        "type": "fill",
        "source": this.layerId,
        "source-layer": "h3",
        "paint": {
          "fill-color": this.getSectorColorExpression(),
          "fill-opacity": this.isVisible ? 0.7 : 0,
          "fill-outline-color": "transparent",
        },
        "filter": this.getFilterExpression(),
      },
      before
    );

    this.map.addLayer(
      {
        "id": this.borderLayerId,
        "type": "line",
        "source": this.layerId,
        "source-layer": "h3",
        "minzoom": 7,
        "layout": {
          visibility: this.isVisible ? "visible" : "none",
        },
        "paint": {
          "line-color": "#ffffff",
          "line-width": ["interpolate", ["linear"], ["zoom"], 5, 0, 10, 1, 12, 1.5, 14, 2],
        },
        "filter": this.getFilterExpression(),
      },
      before
    );

    this.map.addLayer({
      id: this.activeBorderLayerId,
      type: "line",
      source: this.activeBorderLayerId,
      layout: {
        visibility: this.isVisible ? "visible" : "none",
      },
      paint: {
        "line-color": "#4caf50",
        "line-width": this.getSelectionLineWidthExpression(),
      },
    });

    this.map.addLayer({
      id: this.hoverBorderLayerId,
      type: "line",
      source: this.hoverBorderLayerId,
      layout: {
        visibility: this.isVisible ? "visible" : "none",
      },
      paint: {
        "line-color": "#ffea99",
        "line-width": this.getSelectionLineWidthExpression(),
      },
    });
  }

  private getTileUrl = (params: TravelHeatmapTypes.FilterParams) => {
    return APP_ENV.REACT_APP_DTM_RNIS_API + "external/v1/h3/statistics/layerMVT/{z}/{x}/{y}/?" + params;
  };

  private getSelectionLineWidthExpression = (): mapboxgl.Expression => [
    "interpolate",
    ["linear"],
    ["zoom"],
    5,
    0.5,
    6,
    0.6,
    7,
    0.7,
    8,
    0.8,
    9,
    1,
    10,
    2,
    12,
    3.5,
    14,
    4,
  ];

  private addGeoJsonSource = (id: string) => {
    this.map.addSource(id, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  };

  private removeSource = (id: string) => {
    const source = this.map.getSource(id);
    if (!source) return;
    this.map.removeSource(id);
  };

  private getFilterExpression = (): mapboxgl.Expression => [
    "all",
    [">=", ["get", "Value"], this.minMaxFilter[0]],
    ["<=", ["get", "Value"], this.minMaxFilter[1]],
  ];

  private getSectorColorExpression = (): mapboxgl.Expression => {
    return [
      "case",
      ["all", [">=", ["get", "Value"], 0], ["<", ["get", "Value"], this.colorPrice]],
      shared.travelHeatmap.colorPalette[1],
      ["all", [">=", ["get", "Value"], this.colorPrice], ["<", ["get", "Value"], this.colorPrice * 2]],
      shared.travelHeatmap.colorPalette[2],
      ["all", [">=", ["get", "Value"], this.colorPrice * 2], ["<", ["get", "Value"], this.colorPrice * 3]],
      shared.travelHeatmap.colorPalette[3],
      ["all", [">=", ["get", "Value"], this.colorPrice * 3], ["<", ["get", "Value"], this.colorPrice * 4]],
      shared.travelHeatmap.colorPalette[4],
      ["all", [">=", ["get", "Value"], this.colorPrice * 4], ["<", ["get", "Value"], this.colorPrice * 5]],
      shared.travelHeatmap.colorPalette[5],
      ["all", [">=", ["get", "Value"], this.colorPrice * 5], ["<", ["get", "Value"], this.colorPrice * 6]],
      shared.travelHeatmap.colorPalette[6],
      ["all", [">=", ["get", "Value"], this.colorPrice * 6], ["<", ["get", "Value"], this.colorPrice * 7]],
      shared.travelHeatmap.colorPalette[7],
      ["all", [">=", ["get", "Value"], this.colorPrice * 7], ["<", ["get", "Value"], this.colorPrice * 8]],
      shared.travelHeatmap.colorPalette[8],
      ["all", [">=", ["get", "Value"], this.colorPrice * 8], ["<", ["get", "Value"], this.colorPrice * 9]],
      shared.travelHeatmap.colorPalette[9],
      shared.travelHeatmap.colorPalette[0],
    ];
  };

  private removeLayer = (id: string) => {
    const polygonLayer = this.map.getLayer(id);
    if (!polygonLayer) return;
    this.map.removeLayer(id);
  };

  public readonly setMinMaxFilter = (minMaxFilter: TravelHeatmapLayer2D["minMaxFilter"]) => {
    this.minMaxFilter = minMaxFilter;
    this.map.setFilter(this.layerId, this.getFilterExpression());
    this.map.setFilter(this.borderLayerId, this.getFilterExpression());
  };

  public readonly setVisibility = (isVisible: boolean) => {
    this.isVisible = isVisible;
    const layer = this.map.getLayer(this.layerId);
    const borderLayer = this.map.getLayer(this.borderLayerId);
    const activeBorderLayer = this.map.getLayer(this.activeBorderLayerId);
    const hoverBorderLayer = this.map.getLayer(this.hoverBorderLayerId);
    if (!layer || !borderLayer || !activeBorderLayer || !hoverBorderLayer) return;
    const visibility = this.isVisible ? "visible" : "none";
    this.map.setPaintProperty(this.layerId, "fill-opacity", this.isVisible ? 0.7 : 0);
    this.map.setLayoutProperty(this.borderLayerId, "visibility", visibility);
    this.map.setLayoutProperty(this.activeBorderLayerId, "visibility", visibility);
    this.map.setLayoutProperty(this.hoverBorderLayerId, "visibility", visibility);
  };

  public readonly setActiveItemIndex = (activeItems: FeatureCollection) => {
    const source = this.map.getSource(this.activeBorderLayerId) as mapboxgl.GeoJSONSource | undefined;
    source?.setData(activeItems);
  };

  public readonly setHoverItem = (hoverItem: FeatureCollection) => {
    const source = this.map.getSource(this.hoverBorderLayerId) as mapboxgl.GeoJSONSource | undefined;
    source?.setData(hoverItem);
  };

  public readonly setColorPrice = (colorPrice: number) => {
    this.colorPrice = colorPrice;
    const layer = this.map.getLayer(this.layerId);
    if (!layer) return;
    this.map.setPaintProperty(this.layerId, "fill-color", this.getSectorColorExpression());
  };

  public readonly setParams = (params: TravelHeatmapTypes.FilterParams) => {
    this.params = params;
    const source = this.map.getSource(this.layerId) as mapboxgl.VectorSourceImpl;
    if (!source) return;
    source.setTiles([this.getTileUrl(this.params)]);
  };

  public readonly destroy = () => {
    this.removeLayer(this.layerId);
    this.removeLayer(this.borderLayerId);
    this.removeLayer(this.activeBorderLayerId);
    this.removeLayer(this.hoverBorderLayerId);
    this.removeSource(this.layerId);
    this.removeSource(this.activeBorderLayerId);
    this.removeSource(this.hoverBorderLayerId);
  };
}
