import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TemporaryBlockingState = {
  isActive: boolean;
};

export const initialState: TemporaryBlockingState = {
  isActive: false,
};

export const temporaryBlockingSlice = createSlice({
  name: "temporaryBlocking",
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
  },
});
