import { VectorTileLayer } from "map-helpers/new-layer/vector-tile-layer";
import { CAMERA_MAP_IMAGE_KEY } from "map-helpers/assets/map-svg-icons";
import getBeforeId, { CAMERA_CODD_LAYER_ID } from "map-helpers/order-layers";

export class CameraLayer extends VectorTileLayer {
  protected addLayer() {
    const sourceLayer = this.layerId === CAMERA_CODD_LAYER_ID ? "camera" : "echdcam";
    const layer: mapboxgl.AnyLayer = {
      "id": this.layerId,
      "type": "symbol",
      "source": this.sourceId,
      "source-layer": sourceLayer,
      "layout": {
        "icon-image": CAMERA_MAP_IMAGE_KEY,
        "icon-size": this.getDefaultIconSize(),
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
      },
    };
    const beforeId = getBeforeId(this.layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  protected addSource(url: string) {
    this.map.addSource(this.sourceId, {
      type: "vector",
      tiles: [url],
    });
  }

  protected addActiveLayer() {
    const sourceLayer = this.layerId === CAMERA_CODD_LAYER_ID ? "camera" : "echdcam";
    const layer: mapboxgl.AnyLayer = {
      "id": this.activeLayerId,
      "type": "circle",
      "source": this.sourceId,
      "source-layer": sourceLayer,
      "paint": {
        "circle-stroke-color": "blue",
        "circle-stroke-opacity": 0.5,
        "circle-stroke-width": this.getDefaultCircleStroke(),
        "circle-radius": this.getDefaultCircleRadius(),
        "circle-opacity": 0,
        "circle-pitch-alignment": "map",
      },
      "filter": ["boolean", false],
    };
    const beforeId = getBeforeId(this.activeLayerId, this.map);
    this.map.addLayer(layer, beforeId);
  }

  protected addHoverLayer() {
    const sourceLayer = this.layerId === CAMERA_CODD_LAYER_ID ? "camera" : "echdcam";
    const layer: mapboxgl.AnyLayer = {
      "id": this.hoverLayerId,
      "type": "circle",
      "source": this.sourceId,
      "source-layer": sourceLayer,
      "paint": {
        "circle-stroke-color": "cyan",
        "circle-stroke-opacity": 0.7,
        "circle-stroke-width": this.getDefaultCircleStroke(),
        "circle-radius": this.getDefaultCircleRadius(),
        "circle-opacity": 0,
        "circle-pitch-alignment": "map",
      },
      "filter": ["boolean", false],
    };
    const beforeId = getBeforeId(this.hoverLayerId, this.map);
    this.map.addLayer(layer, beforeId);
  }
}
